import React from 'react';
import PropTypes from 'prop-types';

import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

import { Block, Field, Column, Row } from 'rides/components';

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('yyyy-mm-dd');

const dobMask = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];

const NameDobFormFields = ({ criteria, handleChange }) => (
  <Block>
    <Row>
      <Column xs={12}>
        <Field
          type="text"
          name="dateOfBirth"
          textMask={dobMask}
          pipe={autoCorrectedDatePipe}
          value={criteria.dateOfBirth || ''}
          onChange={e => handleChange('dateOfBirth', e.target.value)}
          placeholder="Date of Birth (yyyy-mm-dd)"
          pattern="^\d{4}-\d{2}-\d{2}$"
          required
        />
      </Column>

      <Column xs={12} sm={6}>
        <Field
          type="text"
          name="firstName"
          value={criteria.firstName || ''}
          onChange={e => handleChange('firstName', e.target.value)}
          placeholder="First Name"
          // required
        />
      </Column>

      <Column xs={12} sm={6}>
        <Field
          type="text"
          name="lastName"
          value={criteria.lastName || ''}
          onChange={e => handleChange('lastName', e.target.value)}
          placeholder="Last Name"
          // required
        />
      </Column>
    </Row>
  </Block>
);

NameDobFormFields.propTypes = {
  criteria: PropTypes.object,
  handleChange: PropTypes.func,
};

export default NameDobFormFields;
