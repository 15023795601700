/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'EncounterAlertXlIcon',
  path: (
    <g fill="currentColor">
      <g fill-rule="evenodd" clip-path="url(#clip0)" clip-rule="evenodd">
        <path d="M3.83 6.23c0 .264.215.478.479.478h7.66a.48.48 0 00.479-.479V2.396a.48.48 0 00-.48-.48H10.49C10.309.924 9.556 0 8.139 0c-1.417 0-2.17.923-2.35 1.917h-1.48a.48.48 0 00-.479.479v3.833zM10.532 12.937a.48.48 0 00-.479-.479H5.266a.48.48 0 000 .958h4.787a.48.48 0 00.48-.479zM13.406 4.791h.479a.48.48 0 01.479.48v7.327l1.109-2.22c.168-.338.462-.58.805-.702V3.354a.481.481 0 00-.478-.48h-2.394v1.917z" />
        <path d="M11.081 19.166H2.394a.48.48 0 01-.479-.479V5.27a.48.48 0 01.479-.479h.479V2.875H.479A.48.48 0 000 3.354v15.333a2.398 2.398 0 002.394 2.396h7.73l.957-1.917z" />
        <path d="M5.266 14.375a.48.48 0 000 .958h2.872a.48.48 0 000-.958H5.266zM10.053 8.625H5.266a.48.48 0 000 .958h4.787a.48.48 0 000-.958zM10.053 10.542H5.266a.48.48 0 000 .958h4.787a.48.48 0 000-.958z" />
        <path d="M16.758 20.952a.522.522 0 110-1.045.522.522 0 010 1.045zm-.48-5.228a.48.48 0 11.957 0v3.136a.479.479 0 11-.957 0v-3.135zm6.6 6.477l-5.693-11.395c-.162-.325-.693-.325-.856 0l-5.745 11.5a.478.478 0 00.428.694h11.51a.48.48 0 00.356-.8z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h23v23H0z" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 23 23',
});
