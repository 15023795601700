import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useQueryString } from 'rides/hooks/use-query-string';

import { fromAuth } from 'rides/store/selectors';
import { LogoMark } from 'rides/evo/templates/StandardLayout/Logo';

const mapStateToProps = (state, props) => ({
  isAuthenticated: fromAuth.isAuthenticated(state, props),
});

function AuthLoginPage({ isAuthenticated }) {
  const { query } = useQueryString();

  const returnTo = encodeURIComponent(query.returnTo ?? '/');

  const urlBase = () => new URL(`/api/v1/auth/login_redirect`, window.location.origin);

  // const oktaLink = urlBase();
  // oktaLink.searchParams.set('provider', 'okta');
  // oktaLink.searchParams.set('returnTo', returnTo);

  const cognitoLink = urlBase();
  cognitoLink.searchParams.set('provider', 'cognito');
  cognitoLink.searchParams.set('returnTo', returnTo);

  useEffect(() => {
    if (isAuthenticated) {
      history.replace(returnTo);
    }
  }, [isAuthenticated, returnTo]);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        gap: '6px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <LogoMark />
        {/*
        <a href={oktaLink} className="btn btn-primary margin-x-xs">
          Log in with Okta
        </a>
        */}
        <a href={cognitoLink} className="btn btn-primary margin-x-xs">
          Log in
        </a>
      </div>
    </div>
  );
}

AuthLoginPage.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(AuthLoginPage);
