import React, { useState } from 'react';
import { Pane, Text, Button } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Position } from '@blueprintjs/core';
import { DateInput, TimePicker } from '@blueprintjs/datetime';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/core/lib/css/blueprint.css';

import {
  convertDateTimeToJSDate,
  convertJSDateToDateTime,
  convertJSDateToReadableDate,
  convertReadableDateToJSDate,
  readableDate,
  readableTime,
} from 'rides/utils/dateTime';
import { FormikField, Label } from 'rides/components';
import { Box, Flex } from 'rides/nui';
import DateTime from 'rides/nui/DateTime';
import CurrentTimezone from 'rides/nui/containers/CurrentTimezone';

function getMaxDate() {
  const maxDate = new Date();
  maxDate.setFullYear(2030, 11, 31);
  maxDate.setHours(23, 59, 59);
  return maxDate;
}

const dateInputProps = {
  className: 'occurred-at-field--date-input',
  canClearSelection: false,
  highlightCurrentDay: true,
  popoverProps: { position: Position.BOTTOM, boundary: 'window' },
  // vv-- Date format and parsing --vv
  formatDate: convertJSDateToReadableDate,
  parseDate: convertReadableDateToJSDate,
  placeholder: readableDate,
  maxDate: getMaxDate(),
};

const timeInputProps = {
  className: 'occurred-at-field--time-input',
  selectAllOnFocus: true,
  useAmPm: true,
};

function DateTimePicker({ form, field }) {
  const { name, value } = field;
  const { setFieldValue } = form;

  const jsDateValue = convertDateTimeToJSDate(value);

  function handleDateChange(newValue) {
    const updateDate = new Date(jsDateValue).setFullYear(
      newValue.getFullYear(),
      newValue.getMonth(),
      newValue.getDate(),
    );
    const newFieldValue = convertJSDateToDateTime(updateDate);
    setFieldValue(name, newFieldValue);
  }

  function handleTimeChange(newValue) {
    const updateDate = new Date(jsDateValue).setHours(
      newValue.getHours(),
      newValue.getMinutes(),
    );
    const newFieldValue = convertJSDateToDateTime(updateDate);
    setFieldValue(name, newFieldValue);
  }

  return (
    <Flex alignItems="center">
      <Box>
        <DateInput {...dateInputProps} value={jsDateValue} onChange={handleDateChange} />
      </Box>
      <Text size={400} paddingX="0.5em">
        at
      </Text>
      <Box>
        <TimePicker {...timeInputProps} value={jsDateValue} onChange={handleTimeChange} />
      </Box>
    </Flex>
  );
}

DateTimePicker.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
};

const ModeButton = ({ icon, text, ...props }) => (
  <Button height={20} marginLeft={4} {...props} iconBefore={icon}>
    {text}
  </Button>
);

ModeButton.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
};

ModeButton.defaultProps = {
  appearance: 'minimal',
};

const OccuredAtDateTime = ({ datetime }) => {
  return (
    <Text size={600} paddingTop={6}>
      <DateTime datetime={datetime} format={readableDate} /> at{' '}
      <DateTime datetime={datetime} format={readableTime} />
    </Text>
  );
};

OccuredAtDateTime.propTypes = {
  datetime: PropTypes.string,
};

function OccurredAtField({ updateMode, ...props }) {
  const { name, value } = props.field;
  const { setFieldValue } = props.form;

  const [showEdit, setShowEdit] = useState(false);
  const [preAutoValue, setPreAutoValue] = useState(value);

  function handleShowEdit() {
    setShowEdit(true);
  }

  function handleHideEdit() {
    setShowEdit(false);
  }

  function handleAutoOn() {
    setPreAutoValue(value);
    setShowEdit(false);
    setFieldValue(name, null);
  }

  function handleAutoOff() {
    setPreAutoValue(value);
    setShowEdit(true);
    setFieldValue(name, preAutoValue);
  }

  const showDateTimePicker = showEdit;
  const showDateTimeText = updateMode && !showDateTimePicker;
  const showDateTimeAuto = !updateMode && !showDateTimePicker;

  return (
    <>
      <Label>Occurred At</Label>
      <Flex flexWrap="nowrap" justifyContent="flex-start" alignItems="flex-start">
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          css={{
            '.occurred-at-field--date-input input': {
              width: '7em',
              textAlign: 'center',
            },
          }}
        >
          <Flex alignItems="flex-start">
            <>
              {showDateTimePicker && (
                <Field {...props} type={FormikField} component={DateTimePicker} />
              )}

              {showDateTimeText && <OccuredAtDateTime datetime={props.field.value} />}

              {showDateTimeAuto && (
                <Text size={600} paddingTop={6}>
                  Automatic
                </Text>
              )}
            </>

            {updateMode && !showEdit && (
              <ModeButton onClick={handleShowEdit} icon="edit" text="edit" />
            )}

            {!updateMode &&
              (showEdit ? (
                <ModeButton
                  onClick={handleAutoOn}
                  icon="automatic-updates"
                  text="use automatic"
                />
              ) : (
                <ModeButton onClick={handleAutoOff} icon="edit" text="set custom" />
              ))}
          </Flex>
          <Text size={300} color="muted">
            Times are local to <CurrentTimezone />
          </Text>
        </Flex>
      </Flex>

      <Pane />

      <Pane />
    </>
  );
}

OccurredAtField.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  updateMode: PropTypes.bool,
};

export default OccurredAtField;
