import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import { tenantIdType } from 'rides/types';
import { fromApp, fromAuth } from 'rides/store/selectors';

const mapStateToProps = (state, props) => ({
  currentTenantId: fromAuth.currentTenantId(state, props),
  hideForHumana: fromApp.ui.hideForHumana(state, props),
});

function TenantAutoRedirect({ currentTenantId, location }) {
  const isRootTenantPath = location.pathname === '/t/';
  const appendPathname = isRootTenantPath ? '' : location.pathname;
  const tenantUrl = `/t/${currentTenantId}${appendPathname}`;
  return <Redirect to={{ ...location, pathname: tenantUrl }} />;
}

TenantAutoRedirect.propTypes = {
  currentTenantId: tenantIdType,
  location: PropTypes.object,
};

export const TenantAutoRedirectRoute = R.compose(
  withRouter,
  connect(mapStateToProps),
)(TenantAutoRedirect);

export default TenantAutoRedirectRoute;
