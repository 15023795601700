/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'IncomingCallIcon',
  path: (
    <g fill="currentColor">
      <g fill-rule="evenodd" clip-path="url(#clip0)" clip-rule="evenodd">
        <path d="M11.45 10.739a1.654 1.654 0 00-1.179-.488c-.445 0-.863.173-1.178.488l-.257.257a31.312 31.312 0 01-3.86-3.86l.257-.257c.65-.65.65-1.707 0-2.357L3.819 3.108A1.656 1.656 0 002.64 2.62c-.445 0-.864.173-1.179.488l-.775.776A2.34 2.34 0 00.391 6.82a31.298 31.298 0 008.762 8.762 2.334 2.334 0 002.936-.296l.776-.776a1.67 1.67 0 000-2.357l-1.415-1.414zM8.003-.031a.334.334 0 000 .666c4.044 0 7.334 3.29 7.334 7.334a.334.334 0 00.667 0c0-4.41-3.59-8-8-8z" />
        <path d="M8.003 3.302A4.673 4.673 0 0112.67 7.97a.333.333 0 00.667 0 5.34 5.34 0 00-5.334-5.334.334.334 0 000 .667zM8.003 5.97c1.103 0 2 .896 2 2a.334.334 0 00.667 0 2.67 2.67 0 00-2.667-2.668.334.334 0 000 .667z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 16 16',
});
