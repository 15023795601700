/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'EncountersLgIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M16.666 15.805h-2.084a.416.416 0 01-.416-.417v-3.75a.417.417 0 01.833 0v3.333h1.667a.417.417 0 010 .834zm-2.083-6.667a5.423 5.423 0 00-5.417 5.417 5.423 5.423 0 005.417 5.416 5.423 5.423 0 005.416-5.416 5.423 5.423 0 00-5.416-5.417zM4.583 12.5a.417.417 0 000 .833h2.5a.417.417 0 000-.833h-2.5zM9.166 11.25a.417.417 0 00-.417-.417H4.583a.417.417 0 000 .833h4.166c.23 0 .417-.186.417-.416zM11.666 4.167h.417c.23 0 .416.186.416.416v4.114a6.201 6.201 0 011.666-.342l.001-5.438a.421.421 0 00-.122-.295.42.42 0 00-.295-.122h-2.083v1.667zM4.583 10h4.166a.417.417 0 000-.834H4.583a.417.417 0 000 .834z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M8.698 16.666H2.084a.417.417 0 01-.417-.416V4.583c0-.23.186-.416.417-.416h.417V2.5H.417A.417.417 0 000 2.917V16.25c0 1.148.935 2.083 2.084 2.083h7.512a6.207 6.207 0 01-.898-1.667z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M3.334 5.417c0 .23.187.416.417.416h6.666c.23 0 .417-.186.417-.416V2.083a.417.417 0 00-.417-.416H9.13C8.972.802 8.317 0 7.084 0 5.85 0 5.196.802 5.038 1.667H3.751a.417.417 0 00-.417.416v3.334zM4.583 8.333h4.166a.417.417 0 000-.833H4.583a.417.417 0 000 .833z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
});
