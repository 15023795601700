import React from 'react';
import PropTypes from 'prop-types';
import { isFunc } from 'rides/utils/data';
import { Flex, Text } from 'rides/nui';
import Icon from 'rides/nui/Icon';

// TODO refactor this into more standard component OR just move it
//      into the TripList component folder
const Detail = ({
  icon,
  text,
  height,
  lineHeight = '16px',
  renderText,
  bold,
  black,
  nowrap,
  flex,
  capitalize,
  textColor,
  postfix,
  accentColor,
  iconCss,
  ...props
}) => {
  const flexStyle = { flex };
  if (height) flexStyle.height = `${height}px`;
  if (nowrap) flexStyle.whiteSpace = 'nowrap';

  const textStyle = {
    color: 'mediumGray',
    fontSize: 12,
  };
  if (black) textStyle.color = 'black';

  const textProps = { lineHeight };
  // if (bold) textProps.fontWeight = 600;
  if (bold) textProps.fontWeight = 'bold';
  if (textColor) textProps.color = textColor;

  let textValue = text;
  if (capitalize) {
    textValue = String(text).toLowerCase();
    textProps.textTransform = 'capitalize';
  }

  const cleanedText = textValue && String(textValue).trim();
  const hasEmptyTextValue = !cleanedText;
  const hasRenderTextFn = isFunc(renderText);
  if (hasEmptyTextValue && !hasRenderTextFn) {
    return null;
  }

  const renderedText = hasRenderTextFn ? renderText(textValue) : textValue;

  return (
    <Flex
      alignItems="flex-start"
      {...textStyle}
      {...props}
      style={flexStyle}
      data-item="detail-component"
    >
      {icon && <Icon name={icon} fontSize="1.2em" pr={2} css={iconCss} />}
      <Flex background={accentColor}>
        <Text {...textProps}>{renderedText}</Text>
        {postfix && (
          <Text
            paddingTop={1}
            paddingLeft={1}
            fontSize={8}
            color="gray"
            css={{
              '&:hover': { cursor: 'default' },
            }}
          >
            {postfix}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

Detail.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  height: PropTypes.number,
  lineHeight: PropTypes.string,
  flex: PropTypes.string,
  textColor: PropTypes.string,
  bold: PropTypes.bool,
  black: PropTypes.bool,
  nowrap: PropTypes.bool,
  capitalize: PropTypes.bool,
  renderText: PropTypes.func,
  postfix: PropTypes.node,
  accentColor: PropTypes.string,
  iconCss: PropTypes.style,
};

export default Detail;
