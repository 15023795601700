/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'AgentIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M12.917 7.444v-.298c0-.747-.608-1.354-1.354-1.354h-.014A4.065 4.065 0 007.5 2a4.065 4.065 0 00-4.049 3.792h-.014c-.746 0-1.354.607-1.354 1.354v.298a1.355 1.355 0 000 2.654v.298c0 .746.608 1.354 1.354 1.354h1.084c.15 0 .27-.12.27-.27V6.061a.27.27 0 00-.27-.27h-.528A3.524 3.524 0 017.5 2.542a3.524 3.524 0 013.507 3.25h-.528c-.15 0-.27.121-.27.27v5.417c0 .15.12.271.27.271h1.084v.27c0 .897-.73 1.626-1.626 1.626h-.85a1.082 1.082 0 00-1.045-.813 1.085 1.085 0 000 2.167c.503 0 .924-.346 1.045-.813h.85a2.169 2.169 0 002.167-2.166v-.386c.477-.209.813-.685.813-1.24v-.297a1.355 1.355 0 000-2.654z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
