import React from 'react';
import PropTypes from 'prop-types';

import { datetimeType } from 'rides/types';
import {
  Block,
  Atom,
  FormikField,
  Input,
  Link,
  Glyphicon,
  DateTime,
} from 'rides/components';
import { Field } from 'formik';

const FieldWithAppointmentTime = ({
  appointmentTime,
  children,
  handleSuggest,
  hideWillCall,
}) => {
  const showSuggestTime = !!appointmentTime;
  return (
    <Block>
      <Block
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '12px',
          // justifyContent: 'space-between',
          // width: '100%',
        }}
      >
        <Atom className="input-group" style={{ flexGrow: '1' }}>
          {children}

          {showSuggestTime && (
            <Atom className="input-group-addon">
              <Link to="#" onClick={e => handleSuggest()}>
                <Glyphicon icon="time" /> Suggest Time
              </Link>
            </Atom>
          )}
        </Atom>

        {!hideWillCall && (
          <Field
            name="isWillCall"
            label="Will Call"
            type="checkbox"
            component={FormikField}
          />
        )}
      </Block>

      <Atom>
        For an appointment time of{' '}
        <DateTime showDate={false} showTime datetime={appointmentTime} />
      </Atom>
    </Block>
  );
};

FieldWithAppointmentTime.propTypes = {
  hideWillCall: PropTypes.bool,
  appointmentTime: datetimeType,
  handleSuggest: PropTypes.func.isRequired,
  children: PropTypes.object,
};

const TripWizardPickupTimeInput = ({
  value,
  appointmentTime,
  hideWillCall,
  handleChange,
  handleFocusChange,
  handleSuggest,
}) => (
  <FieldWithAppointmentTime
    appointmentTime={appointmentTime}
    handleSuggest={handleSuggest}
    hideWillCall={hideWillCall}
  >
    <Input
      id="pickupTime"
      value={value}
      onChange={e => handleChange(e.target.value)}
      className="form-control"
      placeholder="h:mm am"
      onFocus={() => handleFocusChange(true)}
      onBlur={() => handleFocusChange(false)}
      data-test-handle="pickup time input"
    />
  </FieldWithAppointmentTime>
);

TripWizardPickupTimeInput.propTypes = {
  value: PropTypes.string.isRequired,
  hideWillCall: PropTypes.bool,
  appointmentTime: datetimeType,
  handleChange: PropTypes.func.isRequired,
  handleFocusChange: PropTypes.func.isRequired,
  handleSuggest: PropTypes.func.isRequired,
};

export default TripWizardPickupTimeInput;
