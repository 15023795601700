// NOTE based on Priceline's design system
//  - https://pricelinelabs.github.io/design-system/Motion/#standards
//
// Want more info about UX and Motion?
//  - https://medium.com/ux-in-motion/creating-usability-with-motion-the-ux-in-motion-manifesto-a87a4584ddc
//  - https://uxdesign.cc/the-ultimate-guide-to-proper-use-of-animation-in-ux-10bd98614fa9

/**
 * Motion Styles
 * --
 * 1. Move  - Position change
 * 2. Scale - Scale change
 * 3. Fade  - Opacity change
 * 4. Spin  - Rotation
 * 5. Color - Color change
 * 6. Float - Z-space change
 * 7. Morph - Scale & Position
 * 8. Slide -  Position & Opacity
 * 9. Grow  - Scale & Opacity
 */
// TODO

/**
 * Animation Duration
 * --
 * For any given distance an object must move, the time it takes to achieve the
 * transition determines how fast its movement is. Bucketing these durations
 * allows us to assign them to components, creating a timing standard.
 */
// Used in tiny animations like button presses
const durationTiny = '150ms';

// Used in Small animations like popovers and tooltips
const durationSmall = '300ms';

// Used for Large components like modals
const durationLarge = '450ms';

// Used in the largest animations like page transitions
const durationExtraLarge = '600ms';

/**
 * Animation Easing (easing curve functions)
 * --
 * As an object moves a given distance at a given time, its displacement is
 * influenced by the spacing influence between its origin and destination. By
 * creating easing buckets, we can standardize the physics of our motion and
 * define its use relative to the context of the movement.
 */
// EaseInOut - The primary easing curve used for any core animation NOT
//             entering/exiting the screen or a component.
const easeInOut = 'cubic-bezier(0.5, 0, 0.25, 1)';

// EaseOut - Used when an element is appearing on-screen or within a component
const easeOut = 'cubic-bezier(0, 0, 0.25, 1)';

// EaseIn - Used when an element is removed from the screen or within a component
const easeIn = 'cubic-bezier(0.5, 0, 1, 1)';

/**
 * Delay (offset)
 * --
 * Defines object relationships and hierarchies when introducing new elements
 * and scenes with a delay/offset. (Follow Through and Overlapping Action)
 */
// Used in animations within components
const delayTiny = '60ms';

// Used in animations of smaller components
const delaySmall = '100ms';

// Used in animations of larger components
const delayLarge = '140ms';

// Used in animations involving multiple components
const delayExtraLarge = '180ms';

/**
 * Movement (distance)
 * --
 * For elements that are changing position, we have defined some set distances
 * they can travel.
 */
// Used in animations involving parts of a component
const moveTiny = '8px';

// Used in animations of smaller components
const moveSmall = '16px';

// Used in animations of larger components
const moveLarge = '24px';

// Used in animations involving the largest components
const moveExtraLarge = '32px';

export default {
  delays: {
    xs: delayTiny,
    sm: delaySmall,
    lg: delayLarge,
    xl: delayExtraLarge,
  },
  duration: {
    xs: durationTiny,
    sm: durationSmall,
    lg: durationLarge,
    xl: durationExtraLarge,
  },
  move: {
    xs: moveTiny,
    sm: moveSmall,
    lg: moveLarge,
    xl: moveExtraLarge,
  },
  timingFunctions: {
    easeInOut,
    easeOut,
    easeIn,
  },
};
