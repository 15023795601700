import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import isReact from 'is-react';

const Input = ({ setFieldValue, setValues, textMask, updateValues, ...props }) => {
  if (props.type === 'textarea') {
    return <textarea {...props} />;
  }

  if (props.type === 'select') {
    return <select {...props} />;
  }

  if (isReact.component(props.type) || typeof props.type === 'function') {
    return (
      <props.type
        {...props}
        setFieldValue={setFieldValue}
        setValues={setValues}
        updateValues={updateValues}
      />
    );
  }

  if (textMask) {
    return <MaskedInput mask={textMask} {...props} />;
  }

  return <input {...props} />;
};

Input.propTypes = {
  setFieldValue: PropTypes.func,
  setValues: PropTypes.func,
  textMask: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  updateValues: PropTypes.func,
};

Input.defaultProps = {
  type: 'text',
};

export default Input;
