import React from 'react';
import PropTypes from 'prop-types';

const MenubarCategory = ({ children, title, ...props }) => (
  <li {...props} className="site-menu-category">
    {title || children}
  </li>
);

MenubarCategory.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default MenubarCategory;
