import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { decamelize } from 'humps';

import { tripType } from 'rides/types';
import { ucwords } from 'rides/utils/format';
import { Block, Small } from 'rides/components';
import { getProviderDetailText } from 'rides/evo/utils/providers';

const DetailLine = ({ label, children }) => (
  <Block>
    <Small textMuted>{label}:</Small>
    <br />
    {children}
  </Block>
);

DetailLine.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

const Vendor = ({ trip }) => {
  const { vendor } = trip;
  const vendorName = getProviderDetailText(vendor);

  const prettifyContactField = field => {
    const separate = str => decamelize(str, { separator: ' ' });
    return R.compose(ucwords, separate)(field);
  };

  return (
    <Block>
      {vendorName && <DetailLine label="Name">{vendorName}</DetailLine>}
      {vendor.contactData && Object.keys(vendor.contactData).map(field => (
        <DetailLine key={field} label={prettifyContactField(field)}>
          {vendor.contactData[field]}
        </DetailLine>
      ))}
    </Block>
  );
};

Vendor.propTypes = {
  trip: tripType,
};

export default Vendor;
