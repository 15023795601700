import PropTypes from 'prop-types';
import React from 'react';
import { Flex, Box } from 'rides/nui';
import { isFunc } from 'rides/utils/data';

export const TripListColumn = ({ width, ...props }) => {
  return <Box {...props} width={width} maxWidth={width} minWidth={width} />;
};

TripListColumn.propTypes = {
  width: PropTypes.number.isRequired,
};

TripListColumn.defaultProps = {
  width: 'auto',
  flex: '0 1 0',
  mx: 2,
  p: 0,
};

// 103+167+340+91+51+140+24+(7*16)
const TripListColumnTripId = props => <TripListColumn {...props} width={103} />;
const TripListColumnMember = props => <TripListColumn {...props} width={167} />;
const TripListColumnTripLeg = props => <TripListColumn {...props} width={340} />;
const TripListColumnTripType = props => <TripListColumn {...props} width={91} />;
const TripListColumnTags = props => <TripListColumn {...props} width={51} />;
const TripListColumnStatus = props => <TripListColumn {...props} width={140} />;
const TripListColumnMenu = props => <TripListColumn {...props} width={24} />;

TripListColumn.TripId = TripListColumnTripId;
TripListColumn.Member = TripListColumnMember;
TripListColumn.TripLeg = TripListColumnTripLeg;
TripListColumn.TripType = TripListColumnTripType;
TripListColumn.Tags = TripListColumnTags;
TripListColumn.Status = TripListColumnStatus;
TripListColumn.Menu = TripListColumnMenu;

const TripListRow = ({
  colTripId,
  colMember,
  colTripLeg,
  colTripType,
  colTags,
  colStatus,
  colMenu,
  renderColumns,
  ...props
}) => {
  const tripId = <TripListColumnTripId>{colTripId}</TripListColumnTripId>;
  const member = <TripListColumnMember>{colMember}</TripListColumnMember>;
  const tripLeg = <TripListColumnTripLeg>{colTripLeg}</TripListColumnTripLeg>;
  const tripType = <TripListColumnTripType>{colTripType}</TripListColumnTripType>;
  const tags = <TripListColumnTags>{colTags}</TripListColumnTags>;
  const status = <TripListColumnStatus>{colStatus}</TripListColumnStatus>;
  const menu = <TripListColumnMenu>{colMenu}</TripListColumnMenu>;

  return (
    <Flex {...props}>
      {isFunc(renderColumns) ? (
        renderColumns({
          columns: { tripId, member, tripLeg, tripType, tags, status, menu },
        })
      ) : (
        <React.Fragment>
          {tripId}
          {member}
          {tripLeg}
          {tripType}
          {tags}
          {status}
          {menu}
        </React.Fragment>
      )}
    </Flex>
  );
};

TripListRow.defaultProps = {
  /* Row Container (Flex) Props */
  alignContent: 'flex-start',
  alignItems: 'flex-start',
  pl: 2,
  pr: 2,

  /* Column Content Props */
  colTripId: null,
  colMember: null,
  colTripLeg: null,
  colTripType: null,
  colTags: null,
  colStatus: null,
  colMenu: null,
};

TripListRow.propTypes = {
  /* Column Content Props */
  colTripId: PropTypes.node,
  colMember: PropTypes.node,
  colTripLeg: PropTypes.node,
  colTripType: PropTypes.node,
  colTags: PropTypes.node,
  colStatus: PropTypes.node,
  colMenu: PropTypes.node,
  renderColumns: PropTypes.func,
};

export default TripListRow;
