import React from 'react';
import PropTypes from 'prop-types';

import { Block } from 'rides/components';

const LocationAutocompleteResult = ({ results, onResultClick }) =>
  results && (
    <Block className="autocomplete-results-container">
      {results.map(result => {
        return (
          <Block
            key={result.placeId}
            onClick={() => onResultClick(result)}
            className="autocomplete-result"
          >
            {result.address}
          </Block>
        );
      })}
    </Block>
  );

LocationAutocompleteResult.propTypes = {
  autocompleteResults: PropTypes.array,
  onResultClick: PropTypes.func.isRequired,
};

export default LocationAutocompleteResult;
