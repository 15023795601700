import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouteLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  // Core
  space,
  color,
  width,
  fontSize,

  // Typography
  fontFamily,
  textAlign,
  lineHeight,
  fontWeight,
  fontStyle,
  letterSpacing,

  // Layout
  display,
  maxWidth,
  minWidth,
  height,
  maxHeight,
  minHeight,
  size,
  verticalAlign,

  // Flex Container
  alignItems,
  alignContent,
  justifyContent,
  flexWrap,
  flexBasis,
  flexDirection,

  // Flex Item
  flex,
  order,
  alignSelf,
  justifySelf,

  // Background
  background,
  backgroundImage,
  backgroundPosition,
  backgroundRepeat,
  backgroundSize,

  // Border
  borderRadius,
  borderColor,
  borders,
  boxShadow,
  opacity,
  overflow,

  // Position
  position,
  zIndex,
  top,
  right,
  bottom,
  left,

  // Variant Styles
  textStyle,
  colorStyle,
  buttonStyle,

  // Utils
  style,
  // variant,
} from 'styled-system';
import Tag from 'clean-tag';
import { Icon } from '@carecar/react-icons';
import * as ridesTypes from 'rides/types';
import { noop, preventDefault } from 'rides/utils/data';

// copied from https://github.com/rebassjs/rebass/blob/118ab94fe87229bbb251c5cae2940c19dfeb34c9/src/index.js#L34
const themed = key => props => props.theme[key];

// --
// -- Custom Style Utilities
// --
// NOTE: These work the same as ones styled-system provides (e.g. space, color)

const textTransform = style({
  prop: 'textTransform',
});

// --
// -- Components
// --

export const Box = styled(Tag)(
  {
    boxSizing: 'border-box',
  },
  // Core
  space,
  color,
  width,
  fontSize,

  // Typography
  fontFamily,
  textAlign,
  lineHeight,
  fontWeight,
  fontStyle,
  letterSpacing,

  // Layout
  display,
  maxWidth,
  minWidth,
  height,
  maxHeight,
  minHeight,
  size,
  verticalAlign,

  // Flex Item
  flex,
  order,
  alignSelf,
  justifySelf,

  // Background
  background,
  backgroundImage,
  backgroundPosition,
  backgroundRepeat,
  backgroundSize,

  // Border
  borderRadius,
  borderColor,
  borders,
  boxShadow,
  opacity,
  overflow,

  // Variant Styles
  textStyle,
  colorStyle,
  buttonStyle,

  themed('Box'),
);

Box.propTypes = {
  // Core
  ...space.propTypes,
  ...color.propTypes,
  ...width.propTypes,
  ...fontSize.propTypes,

  // Typography
  ...fontFamily.propTypes,
  ...textAlign.propTypes,
  ...lineHeight.propTypes,
  ...fontWeight.propTypes,
  ...fontStyle.propTypes,
  ...letterSpacing.propTypes,

  // Layout
  ...display.propTypes,
  ...maxWidth.propTypes,
  ...minWidth.propTypes,
  ...height.propTypes,
  ...maxHeight.propTypes,
  ...minHeight.propTypes,
  ...size.propTypes,
  ...verticalAlign.propTypes,

  // Flex Item
  ...flex.propTypes,
  ...order.propTypes,
  ...alignSelf.propTypes,
  ...justifySelf.propTypes,

  // Background
  ...background.propTypes,
  ...backgroundImage.propTypes,
  ...backgroundPosition.propTypes,
  ...backgroundRepeat.propTypes,
  ...backgroundSize.propTypes,

  // Border
  ...borderRadius.propTypes,
  ...borderColor.propTypes,
  ...borders.propTypes,
  ...boxShadow.propTypes,
  ...opacity.propTypes,
  ...overflow.propTypes,

  // Variant Styles
  ...textStyle.propTypes,
  ...colorStyle.propTypes,
  ...buttonStyle.propTypes,
};

Box.defaultProps = {
  // TODO try moving this to Text
  blacklist: [
    // clean-tag blacklist to prevent html attr pass thru
    ...Tag.defaultProps.blacklist,
    'textTransform',
  ],
};

export const Position = styled(Box)(position, zIndex, top, right, bottom, left);

export const Relative = styled(Position)([]);

Relative.defaultProps = {
  position: 'relative',
};

export const Absolute = styled(Position)([]);

Absolute.defaultProps = {
  position: 'absolute',
};

export const Fixed = styled(Position)([]);

Fixed.defaultProps = {
  position: 'fixed',
};

export const Sticky = styled(Position)([]);

Sticky.defaultProps = {
  position: 'sticky',
};

export const Flex = styled(Box)(
  {
    display: 'flex',
  },
  alignItems,
  alignContent,
  justifyContent,
  flexWrap,
  flexBasis,
  flexDirection,
);

Flex.propTypes = {
  ...alignItems.propTypes,
  ...alignContent.propTypes,
  ...justifyContent.propTypes,
  ...flexWrap.propTypes,
  ...flexBasis.propTypes,
  ...flexDirection.propTypes,
};

export const InlineFlex = styled(Flex)(
  {
    display: 'inline-flex',
  },
  themed('InlineFlex'),
);

export const Text = styled(Box)`
  ${textTransform};
`;

const BaseLink = styled(InlineFlex)(
  // Reset to prevent bootstrap link styling
  css`
    && {
      border-bottom: 1px dashed;

      &,
      &:active,
      &:focus,
      &:hover,
      &:visited {
        color: currentColor;
        text-decoration: none;
      }
    }
  `,
  themed('Link'),
);

BaseLink.defaultProps = {
  as: 'a',
  // borderBottom: '1px dashed',
};

export const Link = ({
  /* eslint-disable no-unused-vars, react/prop-types */
  borderBottom,
  isActive,
  /* eslint-enable  */
  ...props
}) => {
  // Use react-router Link
  if (props.to) return <BaseLink as={RouteLink} {...props} />;

  return (
    <BaseLink
      {...props}
      //  vv-- https://html.spec.whatwg.org/multipage/links.html#link-type-noreferrer
      rel="noreferrer"
      onClick={props.disabled ? preventDefault : noop}

    />
  );
};

Link.propTypes = {
  target: PropTypes.string,
  disabled: PropTypes.bool,
  to: ridesTypes.linkToType,
};

Link.defaultProps = {
  disabled: false,
};

export const BaseButton = styled(Box)(
  // TODO consider refactoring to SFC ButtonContent
  {
    appearance: 'none',
    display: 'inline-block',
  },
  fontWeight,
  borders,
  borderColor,
  borderRadius,
  themed('Button'),
  buttonStyle,
);

BaseButton.propTypes = {
  ...fontWeight.propTypes,
  ...borders.propTypes,
  ...borderColor.propTypes,
  ...borderRadius.propTypes,
  ...buttonStyle.propTypes,
};

BaseButton.defaultProps = {
  as: 'button',
  textAlign: 'center',
  lineHeight: 'inherit',
  textDecoration: 'none',
  py: '4px',
  px: '18px',
  lineHeight: '20px',
  fontWeight: 'bold',
  borderRadius: '3px',
  border: '1px solid',
};

export const Button = ({ icon, children, ...props }) => (
  <BaseButton {...props}>
    <Flex alignItems="center" justifyContent="center">
      {icon && <Icon className="icon" icon={icon} />}
      {children && <Box mx={1}>{children}</Box>}
    </Flex>
  </BaseButton>
);

Button.propTypes = {
  icon: PropTypes.string,
  iconProps: PropTypes.object,
  variant: PropTypes.oneOf(['primary', 'outline', 'link']),
  children: PropTypes.node,
};

Button.defaultProps = {
  variant: 'outline',
  px: 3,
  py: 1,
  m: 1,
};
