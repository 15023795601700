import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FieldHelpBlock = ({ children, className, help, ...props }) => {
  const classes = classNames(className, 'help-block');
  return (
    <span className={classes} {...props}>
      {children || help}
    </span>
  );
};

FieldHelpBlock.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  help: PropTypes.node,
};

export default FieldHelpBlock;
