/**
 * INITIALIZE
 **/
export const TRIP_WIZARD_INIT_NEW_TRIP = 'TRIP_WIZARD_INIT_NEW_TRIP';
export const TRIP_WIZARD_INIT_NEW_TRIP_LEG = 'TRIP_WIZARD_INIT_NEW_TRIP_LEG';
export const TRIP_WIZARD_INIT_EDIT_TRIP = 'TRIP_WIZARD_INIT_EDIT_TRIP';
export const TRIP_WIZARD_INIT_CANCEL_TRIP = 'TRIP_WIZARD_INIT_CANCEL_TRIP';

export const tripWizardInitNewTrip = memberId => ({
  type: TRIP_WIZARD_INIT_NEW_TRIP,
  payload: { memberId },
});

export const tripWizardInitNewTripLeg = (memberId, tripGroupId) => ({
  type: TRIP_WIZARD_INIT_NEW_TRIP_LEG,
  payload: { memberId, tripGroupId },
});

export const tripWizardInitEditTrip = (memberId, tripId) => ({
  type: TRIP_WIZARD_INIT_EDIT_TRIP,
  payload: { memberId, tripId },
});

export const tripWizardInitCancelTrip = ({ memberId, tripId }) => ({
  type: TRIP_WIZARD_INIT_CANCEL_TRIP,
  payload: { memberId, tripId },
});

/**
 * CHANGES
 **/

export const TRIP_WIZARD_SET_FORM_DISABLED = 'TRIP_WIZARD_SET_FORM_DISABLED';
export const TRIP_WIZARD_SET_FORM_NOT_DISABLED = 'TRIP_WIZARD_SET_FORM_NOT_DISABLED';
export const TRIP_WIZARD_SET_STEP_ERRORS = 'TRIP_WIZARD_SET_STEP_ERRORS';

export const TRIP_WIZARD_CLOSE = 'TRIP_WIZARD_CLOSE';
export const TRIP_WIZARD_GO_TO_NEXT_STEP = 'TRIP_WIZARD_GO_TO_NEXT_STEP';
export const TRIP_WIZARD_GO_TO_PREV_STEP = 'TRIP_WIZARD_GO_TO_PREV_STEP';
export const TRIP_WIZARD_SET_CURRENT_STEP = 'TRIP_WIZARD_SET_CURRENT_STEP';
export const TRIP_WIZARD_SET_MEMBER = 'TRIP_WIZARD_SET_MEMBER';
export const TRIP_WIZARD_UPDATE_VALUES = 'TRIP_WIZARD_UPDATE_VALUES';

export const tripWizardClose = () => ({
  type: TRIP_WIZARD_CLOSE,
});

export const tripWizardDisableForm = () => ({
  type: TRIP_WIZARD_SET_FORM_DISABLED,
});

export const tripWizardEnableForm = () => ({
  type: TRIP_WIZARD_SET_FORM_NOT_DISABLED,
});

export const tripWizardGoToNextStep = (values = {}) => ({
  type: TRIP_WIZARD_GO_TO_NEXT_STEP,
  payload: { values },
});

export const tripWizardGoToPrevStep = (values = {}) => ({
  type: TRIP_WIZARD_GO_TO_PREV_STEP,
  payload: { values },
});

export const tripWizardSetCurrentStep = step => ({
  type: TRIP_WIZARD_SET_CURRENT_STEP,
  payload: { step },
});

export const tripWizardSetStepErrors = stepErrors => ({
  type: TRIP_WIZARD_SET_STEP_ERRORS,
  payload: { stepErrors },
});

export const tripWizardSetMember = member => ({
  type: TRIP_WIZARD_SET_MEMBER,
  payload: { member },
});

export const tripWizardUpdateValues = values => ({
  type: TRIP_WIZARD_UPDATE_VALUES,
  payload: { values },
});
