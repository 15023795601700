import React from 'react';
import PropTypes from 'prop-types';
import { globalCss } from '@carecar-evo/core-ui';

import {
  Confirm,
  CreateMemberModal,
  EditMemberModal,
  TripWizardModal,
} from 'rides/containers';
import { Flex, Grid, GridItem } from '@chakra-ui/react';
import NavLeft from 'rides/evo/NavLeft';
import NavTop from 'rides/evo/NavTop';
import { EvoDetailPanel } from 'rides/evo/components/DetailPanel';

import Logo from './Logo';

const LegacyModals = () => (
  <div>
    <CreateMemberModal />
    <EditMemberModal />
    <TripWizardModal />
    <Confirm />
  </div>
);

const EvoPage = ({ children, navTop, navLeft, logo, isVisible }) => {
  const headerHeight = 72;
  const navLeftWidth = 170;
  const contentWidth = 1270;

  return (
    <>
      <Grid
        position="fixed"
        top="0"
        left="0"
        right="0"
        templateRows={`${headerHeight}px calc(100vh - ${headerHeight}px)`}
        templateColumns={`${navLeftWidth}px auto`}
        templateAreas={`"logo header" "nav  content"`}
        display={!isVisible ? 'none' : 'grid'}
        sx={{
          '@media print': {
            position: 'absolute',
          },
        }}
      >
        <GridItem
          gridArea="logo"
          borderColor="lightGrey.100"
          borderWidth="1px"
          borderBottomWidth="0"
        >
          {logo}
        </GridItem>
        <GridItem
          gridArea="header"
          borderColor="lightGrey.100"
          borderWidth="1px"
          borderLeftWidth="0"
        >
          <Flex position="relative" width="1270px" height="69px" bgColor="general.white">
            {navTop}
          </Flex>
        </GridItem>
        <GridItem
          gridArea="nav"
          borderColor="lightGrey.100"
          borderWidth="1px"
          borderTopWidth="0"
          pt="20px"
          overflow="auto"
        >
          {navLeft}
        </GridItem>
        <GridItem
          gridArea="content"
          overflow="auto"
          sx={{
            '@media print': {
              overflow: 'visible',
            },
          }}
        >
          <Flex
            flexDirection="column"
            minWidth="fit-content"
            maxWidth={contentWidth}
            minHeight="100%"
            bgColor="#fbfbfb"
            paddingY="21px"
            paddingLeft="33px"
          >
            {children}
            <EvoDetailPanel />
          </Flex>
        </GridItem>
      </Grid>
    </>
  );
};

EvoPage.propTypes = {
  children: PropTypes.node,
  logo: PropTypes.node,
  navLeft: PropTypes.node,
  navTop: PropTypes.node,
  isVisible: PropTypes.bool,
};

const globalStyles = globalCss({
  ':root': {
    fontSize: '1rem',
  },
});

const EvoStandardLayout = ({ children, navTop, navLeft, logo, isVisible = true }) => {
  // this is required for stitches CSS-in-JS global styles to work
  globalStyles();

  return (
    <EvoPage navTop={navTop} navLeft={navLeft} logo={logo} isVisible={isVisible}>
      {children}
      <LegacyModals />
    </EvoPage>
  );
};

EvoStandardLayout.propTypes = {
  children: PropTypes.node,
  logo: PropTypes.node,
  navLeft: PropTypes.node,
  navTop: PropTypes.node,
};

EvoStandardLayout.defaultProps = {
  logo: <Logo />,
  navLeft: <NavLeft />,
  navTop: <NavTop />,
};

export default EvoStandardLayout;
