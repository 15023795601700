import { useSelector } from 'react-redux';

import { fromApp } from 'rides/store/selectors';

/**
 * Current User show admin UI boolean
 */
export function useShowAdminUI() {
  const showAdminUI = useSelector(fromApp.ui.showAdminUI);
  return showAdminUI;
}

/**
 * Current User show staff UI boolean
 */
export function useShowStaffUI() {
  const showStaffUI = useSelector(fromApp.ui.showStaffUI);
  return showStaffUI;
}
