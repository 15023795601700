/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'CloseXIcon',
  path: (
    <g fill="currentColor">
      <g fill-rule="evenodd" clip-path="url(#clip0)" clip-rule="evenodd">
        <path d="M3.05 3.05a1 1 0 011.414 0l8.486 8.486a1 1 0 11-1.414 1.414L3.05 4.464a1 1 0 010-1.414z" />
        <path d="M12.95 3.05a1 1 0 010 1.414L4.464 12.95a1 1 0 01-1.414-1.414l8.486-8.486a1 1 0 011.414 0z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 16 16',
});
