// LOAD MEMBER DETAIL
export const MEMBER_LOAD_DETAIL_REQUEST = 'MEMBER_LOAD_DETAIL_REQUEST';

export const memberLoadDetail = memberId => ({
  type: MEMBER_LOAD_DETAIL_REQUEST,
  payload: { memberId },
  meta: { thunk: true },
});

// LOAD MEMBER LIST
export const MEMBER_LOAD_LIST_REQUEST = 'MEMBER_LOAD_LIST_REQUEST';

export const memberLoadList = () => ({
  type: MEMBER_LOAD_LIST_REQUEST,
  meta: { thunk: true },
});
