import React from 'react';
import PropTypes from 'prop-types';

import EvoStandardLayout from 'rides/evo/templates/StandardLayout';
import TripList from './TripList';
import Pagination from 'rides/nui/pages/TripListPage/TripListPagination';
import TripListPageSearchBox from 'rides/nui/pages/TripListPage/SearchBox';
import { Heading, Stack, Box, Spinner } from '@chakra-ui/react';
import {
  useTripAssignees,
  useTripSearchAutoParams,
  useTripTypeList,
} from 'rides/evo/utils/trips';
import { useChangeSearchParams } from 'rides/hooks/use-router';
import { useShowStaffUI } from 'rides/hooks/use-auth';
import { useTripStatusList } from 'rides/hooks/use-trip-list';

const AltTripListPage = props => (
  <EvoStandardLayout>
    <EvoTripListPage {...props} />
  </EvoStandardLayout>
);

const tripPickupUrbanicityList = [
  { id: 'urban', name: 'Urban' },
  { id: 'suburban', name: 'Suburban' },
  { id: 'rural', name: 'Rural' },
  { id: 'unknown', name: 'Unknown' },
];

/**
 * Group Status to Status Mapping
 **/

const EvoTripListPage = ({ hideForHumana }) => {
  const showStaffUI = useShowStaffUI();
  const { tripTypes, isLoading: isLoadingTripTypes } = useTripTypeList();
  const tripTypeList = tripTypes || [];

  const { tripAssignees, isLoading: isLoadingAssignees } = useTripAssignees();

  const changeSearchParams = useChangeSearchParams();
  const {
    trips: tripList,
    isLoading,
    isFetching: isTripSearchLoading,
    _queryParams,
    pagination,

    // Fix for dates and other params not being set after
    // loading page with preset search params in the URL
    isFetchedAfterMount: hasLoadedOnce,
  } = useTripSearchAutoParams();

  const tripStatusList = useTripStatusList(_queryParams?.status_group);

  const handlePageChange = page => {
    changeSearchParams({ page });
  };

  const showLoading = isTripSearchLoading || isLoadingTripTypes || isLoadingAssignees;

  return (
    <React.Fragment>
      <Stack direction="column" spacing="10px">
        <Heading as="h1" textStyle="h1">
          Trips
        </Heading>

        <Box width="min-content">
          <TripListPageSearchBox
            searchOptions={_queryParams}
            keywords={_queryParams.keywords}
            changeSearchParams={changeSearchParams}
            showStaffUI={showStaffUI}
            assigneeList={tripAssignees}
            tripTypeList={tripTypeList}
            tripPickupUrbanicityList={tripPickupUrbanicityList}
            tripStatusList={tripStatusList}
            hasLoadedOnce={hasLoadedOnce}
          />
        </Box>

        <Box
          width="748px"
          padding="3px"
          sx={{ overflow: 'hidden', opacity: showLoading ? 1 : 0 }}
        >
          <Spinner thickness="3px" emptyColor="gray.200" color="blue.500" size="sm" />
        </Box>

        <Box>
          <TripList
            tripList={tripList}
            isLoading={isLoading}
            searchOptions={_queryParams}
            hideForHumana={hideForHumana}
            showStaffUI={showStaffUI}
            showLoading={showLoading}
          />
        </Box>

        <Box>
          <Pagination {...pagination} onChange={handlePageChange} disabled={isLoading} />
        </Box>
      </Stack>
    </React.Fragment>
  );
};

EvoTripListPage.propTypes = {
  hideForHumana: PropTypes.bool.isRequired,
};

// export default EvoTripListPage;
export default AltTripListPage;
