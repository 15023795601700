import { combineReducers } from 'redux';

import tripReducer from './trip/reducer';
import tripWizardReducer from './tripWizard/reducer';

// root reducer
const rootReducer = combineReducers({
  trip: tripReducer,
  tripWizard: tripWizardReducer,
});

export default rootReducer;
