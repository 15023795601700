/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'DateRetrievalIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M15.467 14.133c0 .736-.598 1.334-1.334 1.334H1.867a1.335 1.335 0 01-1.334-1.334v-8.8h14.934v8.8zM14.133 1.6h-2.4V.267a.267.267 0 00-.533 0v2.666a.267.267 0 01-.533 0V1.6H4.8V.267a.267.267 0 00-.533 0v2.666a.267.267 0 01-.534 0V1.6H1.867C.837 1.6 0 2.437 0 3.467v10.666C0 15.163.837 16 1.867 16h12.266c1.03 0 1.867-.837 1.867-1.867V3.467c0-1.03-.837-1.867-1.867-1.867z"
        clip-rule="evenodd"
      />
      <path stroke-linecap="round" stroke-linejoin="round" d="M4.414 8.414v2.829h2.829" />
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M12.192 10.536a5 5 0 00-7.07 0l-.708.707"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
