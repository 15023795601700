/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'ExtendIcon',
  path: (
    <g fill="currentColor">
      <g fill-rule="evenodd" clip-path="url(#clip0)" clip-rule="evenodd">
        <path d="M13 7H3.412l2.295-2.313a.999.999 0 10-1.414-1.414L.291 7.293a1.005 1.005 0 000 1.415l4.002 3.999a.997.997 0 001.414 0 .999.999 0 000-1.414L3.412 9H13a1 1 0 100-2z" />
        <path d="M3 9h9.588l-2.295 2.274a.999.999 0 101.414 1.414l4.002-3.981a1.005 1.005 0 000-1.415l-4.002-3.999a.997.997 0 00-1.414 0 .999.999 0 000 1.414L12.588 7H3a1 1 0 100 2z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 16 16',
});
