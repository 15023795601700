import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { tripType } from 'rides/types';
import { fromApp } from 'rides/store/selectors';
import { TripStatus } from 'rides/components';

const mapStateToProps = (state, props) => ({
  showStaffUI: fromApp.ui.showStaffUI(state, props),
});

const TripStatusContainer = ({ trip, showStaffUI }) => (
  <TripStatus {...{ trip, showStaffUI }} />
);

TripStatusContainer.propTypes = {
  trip: tripType.isRequired,
  showStaffUI: PropTypes.bool.isRequired,
};

export default R.compose(withRouter, connect(mapStateToProps))(TripStatusContainer);
