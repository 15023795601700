import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import EntityListLoader from 'rides/components/utils/EntityListLoader';
import { Block, Input, FormFeedbackBox } from 'rides/components';

const sortPurposeList = R.sortWith([R.ascend(R.prop('name'))]);

const TripPurposeSelectField = props => (
  <EntityListLoader
    resource="trip/purpose"
    entityType="tripPurpose"
    renderNotAsked={() => null}
    renderLoading={() => (
      <Input {...props} type="select">
        <option value=""> loading... </option>
      </Input>
    )}
    renderError={(/*error*/) => <FormFeedbackBox error="Failed to load purposes" />}
    renderSuccess={purposeList => (
      <Input {...props} type="select">
        {!props.value && <option value=""> -- Select One -- </option>}
        {sortPurposeList(purposeList).map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </Input>
    )}
    loadOnMount
  >
    {(View, loadData) => (
      <Block>
        {View}
        {/*
         * NOTE: We can add a reload button like this:
         * <span onClick={loadData}>Load Data</span>
         * */}
      </Block>
    )}
  </EntityListLoader>
);

TripPurposeSelectField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default TripPurposeSelectField;
