import React from 'react';
import * as R from 'ramda';
import { consoleErrorRecovery, safeSaga } from 'rides/store/helpers';
import { select, takeLatest, put, putResolve, call } from 'redux-saga/effects';

import * as action from './actions';
import { Paragraph, Strong } from 'rides/components';
import { confirmSaga } from 'rides/store/confirm/sagas';
import { fromApp } from 'rides/store/selectors';
import {
  modalHide,
  modalShow,
  resourceCreateRequest,
  resourceDetailReadRequest,
  resourceListReadRequest,
} from 'rides/store/actions';

/**
 * Task Sagas
 **/

// CANCEL TRIP FOR MEMBER
export function* cancelTripForMemberOpen() {
  yield put(modalShow('trip-cancel'));
}

export function* cancelTripForMemberClose() {
  yield put(modalHide('trip-cancel'));
}

export function* cancelTripForMemberSubmit(api, cancelReason) {
  try {
    const memberId = yield select(fromApp.trip.cancelFormMemberId);
    const tripId = yield select(fromApp.trip.cancelFormTripId);
    const resource = yield select(fromApp.trip.memberTripCancelResource, {
      memberId,
      tripId,
    });

    yield putResolve(resourceCreateRequest(resource, { trip: { cancelReason } }, 'trip'));

    yield call(cancelTripForMemberClose);
  } catch (error) {
    // yield call(handleApiErrorResponse, error);
    console.log('Oops!', { error });
  }
}

// LOAD TRIP GROUP DETAIL FOR MEMBER
export function* loadTripGroupDetailForMember(memberId, tripGroupId) {
  try {
    const resource = yield select(fromApp.trip.memberTripGroupResource, { memberId });
    yield putResolve(resourceDetailReadRequest(resource, tripGroupId, 'tripGroup'));
  } catch (error) {
    console.log('loadTripGroupDetailForMember::Not Handled!', error);
  }
}

// LOAD TRIP GROUP LIST FOR MEMBER
export function* loadTripGroupListForMember(memberId) {
  try {
    const resource = yield select(fromApp.trip.memberTripGroupResource, { memberId });
    yield putResolve(resourceListReadRequest(resource, { page_size: 100 }, 'tripGroup'));
  } catch (error) {
    console.log('loadTripGroupListForMember::Not Handled!', error);
  }
}

export function* handleApiErrorResponse(apiError) {
  const httpStatus = R.path(['response', 'status'], apiError);
  const error = R.path(['response', 'data', 'error'], apiError);
  const errorMap = R.path(['response', 'data', 'errors'], apiError);
  console.log('handleApiErrorResponse', { httpStatus, apiError, error, errorMap });

  if (httpStatus == 422) {
    if (error === 'no_change_period') {
      yield call(confirmSaga, {
        message: (
          <Paragraph>
            Failed to save because trip is in <Strong>No Change Period</Strong>
          </Paragraph>
        ),
        yesButtonText: 'Okay',
        hideNoButton: true,
        danger: true,
      });
    }
  } else {
    yield call(confirmSaga, {
      message: 'Failed to save for unknown reason.',
      yesButtonText: 'Okay',
      hideNoButton: true,
      danger: true,
    });
  }

  return { httpStatus, error, errorMap };
}

/**
 * Watcher Sagas
 **/

// Cancel Trip for Member
export function* watchTripCancelTripForMemberClose() {
  yield call(cancelTripForMemberClose);
}
export function* watchTripCancelTripForMemberInit(api, { payload }) {
  const { memberId, tripId } = payload;
  yield call(cancelTripForMemberOpen, memberId, tripId);
}
export function* watchTripCancelTripForMemberSubmit(api, { payload }) {
  const { cancelReason } = payload;
  yield call(cancelTripForMemberSubmit, api, cancelReason);
}

// Load Trip Group Detail for Member
export function* watchTripGroupLoadDetailForMember(api, { payload }) {
  const { memberId, tripGroupId } = payload;
  yield call(loadTripGroupDetailForMember, memberId, tripGroupId);
}

// Load Trip Group List for Member
export function* watchTripGroupLoadListForMember(api, { payload }) {
  const { memberId } = payload;
  yield call(loadTripGroupListForMember, memberId);
}

/**
 * Root Sagas
 **/

export default function*({ api }) {
  const safe = safeSaga(consoleErrorRecovery);

  // Trip Group DETAIL Load for Member
  yield takeLatest(
    action.TRIP_GROUP_LOAD_DETAIL_FOR_MEMBER_REQUEST,
    safe(watchTripGroupLoadDetailForMember, api),
  );

  // Trip Group LIST Load for Member
  yield takeLatest(
    action.TRIP_GROUP_LOAD_LIST_FOR_MEMBER_REQUEST,
    safe(watchTripGroupLoadListForMember, api),
  );

  // Trip Cancel for Member
  yield takeLatest(
    action.TRIP_CANCEL_TRIP_FOR_MEMBER_CLOSE,
    safe(watchTripCancelTripForMemberClose, api),
  );
  yield takeLatest(
    action.TRIP_CANCEL_TRIP_FOR_MEMBER_INIT,
    safe(watchTripCancelTripForMemberInit, api),
  );
  yield takeLatest(
    action.TRIP_CANCEL_TRIP_FOR_MEMBER_SUBMIT,
    safe(watchTripCancelTripForMemberSubmit, api),
  );
}
