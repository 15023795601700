import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Component from '@reach/component-component';

import * as ridesTypes from 'rides/types';
import TripAssignAvatar from 'rides/nui/TripAssignAvatar';
import { resourceCreateRequest, resourceDetailReadRequest } from 'rides/store/actions';
import {
  useInvalidateTrip,
  useTrip,
  // useTrip,
  // useLoadTrip,
} from 'rides/evo/utils/trips';

const mapDispatchToProps = {
  loadAssignAgentSuggestions: tripId =>
    resourceDetailReadRequest(`trip/${tripId}`, 'assign'),
  assignAgent: (tripId, userId) =>
    resourceCreateRequest(`trip/${tripId}/assign`, { assignee_id: userId }, 'trip'),
  unassignAgent: (tripId, userId) =>
    resourceCreateRequest(`trip/${tripId}/unassign`, { assignee_id: userId }, 'trip'),
};

const TripAssignAvatarContainer = ({
  loadAssignAgentSuggestions,
  assignAgent,
  unassignAgent,
  ...props
}) => {
  const invalidateTrip = useInvalidateTrip();
  // const loadTrip = useLoadTrip();
  // const { trip, refetch: refetchTrip } = useTrip(tripId, { enabled: false });

  const doLoadSuggestions = async tripId => {
    const resp = await loadAssignAgentSuggestions(tripId);
    const { languagesSpoken, suggestedAssignees } = resp.data;
    return {
      languagesSpoken: languagesSpoken,
      suggestedAssigneeList: suggestedAssignees,
    };
  };

  return (
    <Component
      initialState={{
        isLoading: false,
        languagesSpoken: '',
        suggestedAssigneeList: undefined,
      }}
    >
      {({ state, setState }) => (
        <TripAssignAvatar
          {...props}
          isLoading={state.isLoading}
          languagesSpoken={state.languagesSpoken}
          suggestedAssigneeList={state.suggestedAssigneeList}
          assignAgent={async (tripId, userId) => {
            const resp = await assignAgent(tripId, userId);
            // Invalidate the react query based trip data so it will automatically refetch
            invalidateTrip(tripId, false);
            // loadTrip(tripId);
            const { languagesSpoken, suggestedAssigneeList } = await doLoadSuggestions(
              tripId,
            );
            setState({ languagesSpoken, suggestedAssigneeList });
            return resp;
          }}
          unassignAgent={async (tripId, userId) => {
            const resp = await unassignAgent(tripId, userId);
            // Invalidate the react query based trip data so it will automatically refetch
            invalidateTrip(tripId, false);
            // loadTrip(tripId);
            const { languagesSpoken, suggestedAssigneeList } = await doLoadSuggestions(
              tripId,
            );
            setState({ languagesSpoken, suggestedAssigneeList });
            return resp;
          }}
          loadSuggestions={async () => {
            setState({ isLoading: true });
            const tripId = R.path(['trip', 'id'], props);
            const resp = await loadAssignAgentSuggestions(tripId);
            const { languagesSpoken, suggestedAssignees } = resp.data;
            setState({
              isLoading: false,
              languagesSpoken: languagesSpoken,
              suggestedAssigneeList: suggestedAssignees,
            });
          }}
        />
      )}
    </Component>
  );
};

TripAssignAvatarContainer.propTypes = {
  size: PropTypes.number,
  trip: ridesTypes.tripType.isRequired,
  assignAgent: PropTypes.func.isRequired,
  unassignAgent: PropTypes.func.isRequired,
  loadAssignAgentSuggestions: PropTypes.func.isRequired,
};

export default R.compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(TripAssignAvatarContainer);
