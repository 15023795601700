import * as R from 'ramda';
import { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { client } from 'rides/evo/utils/api-client';
import { useShowStaffUI } from 'rides/hooks/use-auth';
import { useRouter } from 'rides/hooks/use-router';
import { tripWizardInitEditTrip } from 'rides/store/actions';
import { fromEntities } from 'rides/store/selectors';
import * as queryString from 'rides/utils/queryString';
import { useSyncToReduxEntities } from './redux-interop'

// --
// -- Trip & Edit Trip Redux Inter-Op
// --

const tripSelector = tripId => state => fromEntities.getDetail(state, 'trip', tripId);

function useTripEditDisabled(tripId) {
  const trip = useSelector(tripSelector(tripId));

  const canEditLocation = trip?.permissions?.canEditLocation;
  const canEditTime = trip?.permissions?.canEditTime;
  const editDisabled = !canEditLocation && !canEditTime;

  return editDisabled;
}

function useEditTripWizard(memberId, tripId) {
  const dispatch = useDispatch();
  return function onEditTrip(event) {
    if (event) event.preventDefault();
    dispatch(tripWizardInitEditTrip(memberId, tripId));
  };
}

// ---------------------------------------------
// Trip Data Extract & Format Functions
// ---------------------------------------------

function getTripId(trip) {
  return trip?.id
}

function getTripGroupId(trip) {
  return trip?.group_id;
}

function getTripGroupReferenceNumber(trip) {
  if (!trip?.group_reference_number) {
    return '';
  }
  return trip?.group_reference_number;
}

function getTripMember(trip) {
  return trip?.member
}

function getTripMemberId(trip) {
  return getTripMember(trip)?.id
}

function getTripRootId(trip) {
  const rootId = trip?.root_id;
  return `${rootId}`;
}

function tripGroupDepthAlpha(tripGroupDepth) {
  const asciiStart = 65; // => ascii code for 'A'
  return String.fromCharCode(asciiStart + tripGroupDepth);
};

function getTripLegAlpha(trip) {
  const groupDepth = trip?.group_depth;
  return tripGroupDepthAlpha(groupDepth)
}

// ---------------------------------------------
// React Query Functions & Hooks
// ---------------------------------------------

const tripDetailKey = tripId => ['trip', tripId];

function setQueryDataForTrip(trip, queryClient) {
  const queryKey = tripDetailKey(trip.id);
  queryClient.setQueryData(queryKey, trip);
}

function invalidateQueryDataForTrip(tripId, queryClient) {
  const queryKey = tripDetailKey(tripId);
  queryClient.invalidateQueries(queryKey);
}

function useInvalidateTrip() {
  const queryClient = useQueryClient();
  return (tripId, refetchActive = true) =>
    invalidateQueryDataForTrip(tripId, queryClient, refetchActive);
}

// --
// -- Trip Assignees
// --

const getDataTripAssignees = data => data?.data ?? [];

function useTripAssignees() {
  const showStaffUI = useShowStaffUI();
  const isEnabled = !!showStaffUI;

  const { data, ...result } = useQuery({
    queryKey: 'tripAssignees',
    queryFn: () =>
      client(`trip/assignee`)
        .then(resp => resp.data)
        .then(data => {
          const tripAssignees = getDataTripAssignees(data);
          return tripAssignees;
        }),
    enabled: isEnabled,
    notifyOnChangeProps: ['data', 'error'],
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
  });

  return { ...result, tripAssignees: data }
}


// --
// -- Trip Search
// --

const getDataTrips = data => data?.data ?? [];
const getDataPagination = data => {
  return {
    currentPage: ~~data?.page_number,
    totalPages: ~~data?.total_pages,
    pageSize: ~~data?.page_size,
    totalEntries: ~~data?.total_entries,
  };
};

function useTripSearch({ params = {} }) {
  const {
    keywords,
    page = 1,
    page_size = 20,
    status_group = 'completed',
    status = [],
    assigned_to = [],
    type,
    pickup_urbanicity,
    from_date,
    to_date,
  } = params;

  const include_unassigned =  R.contains('include_unassigned', assigned_to)

  const queryParams = {
    keywords,
    page,
    page_size,
    status_group,
    status,
    include_unassigned,
    assigned_to,
    type,
    pickup_urbanicity,
    to_date,
    from_date,
  };

  const syncToReduxEntities = useSyncToReduxEntities();
  const queryClient = useQueryClient();

  const { data, ...result } = useQuery({
    queryKey: ['trip-search', { ...queryParams }], // { keywords, status }],
    queryFn: async () => {
      return client(`trip`, { params: queryParams }).then(resp => resp.data);
    },
    onSuccess(data) {
      const trips = getDataTrips(data);
      for (const trip of trips) {
        setQueryDataForTrip(trip, queryClient);
        syncToReduxEntities('trip', trip);
      }
    },
    keepPreviousData: false,
  });

  return {
    ...result,
    trips: getDataTrips(data),
    _queryParams: { ...queryParams },
    pagination: getDataPagination(data),
  };
}

// --
// -- Trip
// --

function useTrip(tripId, { enabled, ...useQueryArgs }) {
  // const queryClient = useQueryClient();
  const syncToReduxEntities = useSyncToReduxEntities();
  const queryKey = tripDetailKey(tripId);

  const { data, ...result } = useQuery({
    queryKey: queryKey,
    queryFn: () =>
      client(`trip/${tripId}`)
        .then(resp => resp.data)
        .then(data => {
          const trip = data?.data;
          syncToReduxEntities('trip', trip);
          return trip;
        }),
    // notifyOnChangeProps: 'tracked',
    notifyOnChangeProps: ['data', 'error'],
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
    enabled,
    ...useQueryArgs,
  });

  return { ...result, trip: data }; //trip: data?.data };
}

// --
// -- Trip Group
// --

const getDataTripGroup = data => data?.data ?? {};
const getDataTripGroupTrips = data => data?.data?.trips ?? [];

function useTripGroup(tripGroupId) {
  const queryClient = useQueryClient();
  const syncToReduxEntities = useSyncToReduxEntities();

  const { data, ...result } = useQuery({
    queryKey: ['tripGroup', tripGroupId],
    queryFn: () =>
      client(`trip_group_v2/${tripGroupId}`)
        .then(resp => resp.data)
        .then(data => {
          const trips = getDataTripGroupTrips(data);
          trips.map(trip => {
            setQueryDataForTrip(trip, queryClient);
            syncToReduxEntities('trip', trip);
          });
          return data;
        }),
    enabled: false,
    notifyOnChangeProps: ['data', 'error'],
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
  });

  return { ...result, tripGroup: getDataTripGroup(data) };
}

// --
// -- Trip Status Transitions
// --

const tripStatusTransitionListKey = tripId => ['tripStatusTransitionList', tripId];
const tripStatusTransitionDetailKey = tripStatusTransitionId => [
  'tripStatusTransition',
  tripStatusTransitionId,
];

const getDataTripStatusTransitions = data => data?.data ?? [];

// -- Trip Status Transition Detail

function setQueryDataForTripStatusTransition(transition, queryClient) {
  const queryKey = tripStatusTransitionDetailKey(transition.id);
  queryClient.setQueryData(queryKey, transition);
}

// -- Trip Status Transitions List

function invalidateQueryDataForTripStatusTransitionList(tripId, queryClient) {
  const queryKey = tripStatusTransitionListKey(tripId);
  queryClient.invalidateQueries(queryKey);
}

function useInvalidateTripStatusTransitionList() {
  const queryClient = useQueryClient();
  return (tripId) =>
    invalidateQueryDataForTripStatusTransitionList(tripId, queryClient);
}


function useTripStatusTransitionList(tripId, { enabled, ...useQueryArgs }) {
  const queryClient = useQueryClient();
  const syncToReduxEntities = useSyncToReduxEntities();
  const queryKey = tripStatusTransitionListKey(tripId);

  const { data, ...result } = useQuery({
    queryKey,
    queryFn: () => client(`trip/${tripId}/status_transition`).then(resp => resp.data),
    onSuccess(data) {
      const transitionList = getDataTripStatusTransitions(data);
      for (const transition of transitionList) {
        setQueryDataForTripStatusTransition(transition, queryClient);
        syncToReduxEntities('tripStatusTransition', transition);
      }
    },
    notifyOnChangeProps: ['data', 'error'],
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
    enabled,
    ...useQueryArgs,
  });

  return { ...result, transitions: getDataTripStatusTransitions(data) };
}

function useTripStatusTransition(
  tripId,
  transitionId,
  { enabled, ...useQueryArgs } = {},
) {
  const queryClient = useQueryClient();
  const syncToReduxEntities = useSyncToReduxEntities();
  const queryKey = tripStatusTransitionDetailKey(transitionId);

  const { data, ...result } = useQuery({
    queryKey,
    queryFn: () =>
      client(`trip/${tripId}/status_transition`)
        .then(resp => resp.data)
        .then(data => {
          const transitionList = getDataTripStatusTransitions(data);
          const transition = transitionList.find(
            transition => transition.id === transitionId,
          );
          return transition;
        }),
    onSuccess(data) {
      const transitionList = getDataTripStatusTransitions(data);
      for (const transition of transitionList) {
        setQueryDataForTripStatusTransition(transition, queryClient);
        syncToReduxEntities('tripStatusTransition', transition);
      }
    },
    notifyOnChangeProps: ['data', 'error'],
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
    enabled,
    ...useQueryArgs,
  });

  return { ...result, transition: data }; //trip: data?.data };
}

function useTripSearchAutoParams() {
  const { location } = useRouter();
  const search = queryString.parse(location.search);

  return useTripSearch({ params: search });
}

function useTripTypeList() {
  const [tripTypes, setTripTypes] = useState();

  const queryResult = useQuery({
    queryKey: 'trip/type',
    queryFn: () => client(`trip/type`).then(resp => resp.data),
    onSuccess(data) {
      setTripTypes(data.data)
    },
    enabled: !tripTypes,
  });

  return { tripTypes, ...queryResult }
}

export {
  getTripGroupId,
  getTripGroupReferenceNumber,
  getTripId,
  getTripLegAlpha,
  getTripMember,
  getTripMemberId,
  getTripRootId,
  invalidateQueryDataForTrip,
  invalidateQueryDataForTripStatusTransitionList,
  setQueryDataForTrip,
  setQueryDataForTripStatusTransition,
  useEditTripWizard,
  useInvalidateTrip,
  useInvalidateTripStatusTransitionList,
  useTrip,
  useTripAssignees,
  useTripEditDisabled,
  useTripGroup,
  useTripSearch,
  useTripSearchAutoParams,
  useTripStatusTransition,
  useTripStatusTransitionList,
  useTripTypeList,
};
