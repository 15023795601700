import React from 'react';
import PropTypes from 'prop-types';

const spinnerImg = <img src="/assets/images/spinner.gif" />;

const Spinner = ({ inline }) =>
  inline ? spinnerImg : <div className="text-center">{spinnerImg}</div>;

Spinner.propTypes = {
  inline: PropTypes.bool,
};

export default Spinner;
