import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { ucwords } from 'rides/utils/format';
import { resourceListReadRequest } from 'rides/store/actions';
import EntityList from 'rides/components/utils/EntityList';
import { TripGroupListPage } from 'rides/components';

const mapDispatchToProps = dispatch => ({
  fetchTripGroupList: options =>
    dispatch(resourceListReadRequest('trip_group_v2', options, 'tripGroup')),
});

const statusParam = R.path(['location', 'query', 'status']);

const getQueryParams = R.pathOr({}, ['location', 'query']);

const getQueryParamOptions = R.compose(
  R.reject(R.isNil),
  R.pickAll(['page', 'page_size', 'status']),
  getQueryParams,
);

const defaultQueryOptions = {
  page_size: 100,
};

const maxPageSize = 500;
const isPageSizeTooLarge = R.gt(R.__, maxPageSize);
const limitPageSize = R.when(
  R.propSatisfies(isPageSizeTooLarge, 'page_size'),
  R.assoc('page_size', maxPageSize),
);

const sanatizeQueryOptions = R.compose(limitPageSize);

class TripGroupListPageContainer extends React.Component {
  state = {
    keywords: '',
    pagination: null,
    loading: false,
    tripGroupIds: null,
  };

  componentDidMount() {
    this.fetchTrips();
  }

  componentWillReceiveProps(nextProps) {
    const oldParams = getQueryParamOptions(this.props);
    const newParams = getQueryParamOptions(nextProps);

    if (oldParams.status !== newParams.status) {
      this.setState({ keywords: '' });
      this.fetchTrips({ keywords: '', ...newParams });
    }
  }

  fetchTrips(queryOptions = {}) {
    this.setState({ loading: true });

    const options = this.getQueryOptions(queryOptions);

    this.props
      .fetchTripGroupList(options)
      .then(this.handleFetchSuccess, this.handleFetchFailure);
  }

  getQueryOptions = queryOptions => {
    const options = {
      ...defaultQueryOptions,
      ...getQueryParamOptions(this.props),
      ...queryOptions,
    };

    return sanatizeQueryOptions(options);
  };

  handleFetchFailure = error => {
    this.setState({ loading: false, error });
  };

  handleFetchSuccess = resp => {
    const tripGroupIds = resp.data;
    const currentPage = resp.pageNumber;
    const { totalPages, totalEntries, pageSize } = resp;

    this.setState({
      loading: false,
      tripGroupIds,
      pagination: {
        currentPage,
        totalPages,
        totalEntries,
        pageSize,
      },
    });
  };

  handleSearch = keywords => {
    this.setState({ keywords });
    this.fetchTrips({ keywords });
  };

  handlePageChange = page => {
    const { keywords } = this.state;
    this.fetchTrips({ keywords, page });
  };

  render() {
    const { loading, keywords, tripGroupIds } = this.state;

    const status = statusParam(this.props);
    const pageTitle = status ? `${ucwords(status)} Trips` : 'Trips';

    return (
      <EntityList entityType="tripGroup" entityIds={tripGroupIds}>
        {tripGroupList => (
          <TripGroupListPage
            pageTitle={pageTitle}
            tripGroupList={tripGroupList}
            keywords={keywords}
            onSubmitSearch={this.handleSearch}
            pagination={{
              currentPage: 0,
              totalPages: 0,
              ...this.state.pagination,
              onChange: this.handlePageChange,
            }}
            isLoading={loading}
          />
        )}
      </EntityList>
    );
  }
}

TripGroupListPageContainer.propTypes = {
  fetchTripGroupList: PropTypes.func.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps,
  ),
)(TripGroupListPageContainer);
