/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'ConversationOutlineIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M5.516 11.54l.667 1.676L8.52 11.54H13c.276 0 .5-.225.5-.502V3.008a.501.501 0 00-.5-.502H3c-.276 0-.5.225-.5.502v8.03a.5.5 0 00.5.502h2.516zM9 13.046h4c1.105 0 2-.899 2-2.007V3.008A2.004 2.004 0 0013 1H3c-1.105 0-2 .899-2 2.008v8.03c0 1.11.895 2.008 2 2.008h1.5l.527 1.323a.998.998 0 001.51.444L9 13.046zm-5.25-8.03c0-.416.336-.753.75-.753h7a.751.751 0 010 1.506h-7a.751.751 0 01-.75-.754zm.75 2.56a.751.751 0 00-.75.752c0 .416.336.753.75.753h5c.414 0 .75-.337.75-.753a.751.751 0 00-.75-.753h-5z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
