import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pending, fulfilled, rejected } from 'redux-saga-thunk';
import { lifecycle, withHandlers, withProps } from 'recompose';

import { locationType, datetimeType } from 'rides/types';
import { fromResource } from 'rides/store/selectors';
import { resourceCreateRequest } from 'rides/store/actions';
import {
  FormFeedbackBox,
  TripWizardPickupTimeSuggestion,
  TripWizardPickupTimeInput,
} from 'rides/components';
import {
  spinnerWhileIsLoading,
  nothingUntilIsReady,
  showWhilePropTruthy,
} from 'rides/components/utils/showWhile';

const PickupEstimateFailed = props => (
  <React.Fragment>
    <FormFeedbackBox danger>Failed to estimate suggested pick-up time.</FormFeedbackBox>
    <TripWizardPickupTimeInput {...props} />
  </React.Fragment>
);

const resource = 'trip/estimate';

function mapStateToProps(state) {
  const thunkId = `${resource}Create`;
  const isPendingEstimate = pending(state, thunkId);
  const isDoneEstimate = fulfilled(state, thunkId);
  const estimateFailed = rejected(state, thunkId);

  return {
    isPendingEstimate,
    isDoneEstimate,
    estimateFailed,
    // TODO: replace the 0 with the reducer this resource will actually be located in once @orther fixes it
    estimate: R.prop(0, fromResource.getList(state, resource)),
  };
}

const mapDispatchToProps = {
  fetchSuggestion: (pickup, dropoff, appointmentTime) => {
    const estimate = { pickup, dropoff, appointmentTime };
    return resourceCreateRequest(resource, { estimate });
  },
};

const SuggestedPickupTimeContainer = ({ appointmentTime, estimate, onClick }) => (
  <TripWizardPickupTimeSuggestion
    appointmentTime={appointmentTime}
    estimate={estimate}
    onClick={onClick}
  />
);

SuggestedPickupTimeContainer.propTypes = {
  onClick: PropTypes.func.isRequired,
  pickup: locationType.isRequired,
  dropoff: locationType.isRequired,
  appointmentTime: datetimeType.isRequired,
  fetchSuggestion: PropTypes.func.isRequired,
  estimate: PropTypes.object,
};

export default R.compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withProps(props => ({
    isReady: props.isDoneEstimate && props.estimate,
    isLoading: props.isPendingEstimate,
  })),
  withHandlers({
    handleSuggest: props => () => {
      props.fetchSuggestion(props.pickup, props.dropoff, props.appointmentTime);
    },
  }),
  lifecycle({
    componentWillMount() {
      // trigger suggest estimate fetch on initial load
      this.props.handleSuggest();
    },
  }),
  showWhilePropTruthy(PickupEstimateFailed, 'estimateFailed'),
  spinnerWhileIsLoading,
  nothingUntilIsReady,
)(SuggestedPickupTimeContainer);
