/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'HideIcon',
  path: (
    <g fill="currentColor">
      <g fill-rule="evenodd" clip-path="url(#clip0)" clip-rule="evenodd">
        <path d="M15.941 7.613c-.02-.04-.52-1.027-1.893-2.036a.714.714 0 00-.938.09.567.567 0 00.098.85c.686.504 1.104.992 1.322 1.293-.8.879-3.354 3.38-6.53 3.38a6.5 6.5 0 01-.899-.065c-.363-.053-.704.174-.761.505-.056.33.193.64.556.69.37.053.74.08 1.104.08 4.524 0 7.74-4.015 7.874-4.186a.56.56 0 00.067-.6zM1.916 10.121a.713.713 0 00.938-.086.568.568 0 00-.096-.851 6 6 0 01-1.29-1.27c.8-.878 3.355-3.38 6.532-3.38.205 0 .414.01.624.031.366.038.695-.202.735-.535.04-.332-.224-.63-.59-.667A8.038 8.038 0 008 3.325C3.475 3.325.26 7.338.124 7.51a.557.557 0 00-.066.597c.02.041.505 1.013 1.858 2.014z" />
        <path d="M8.39 10.054c1.47 0 2.666-1.085 2.666-2.42 0-.252-.043-.494-.123-.723L7.592 9.944c.251.072.52.11.797.11zM5.723 7.635c0 .18.023.355.064.524l3.18-2.885a2.961 2.961 0 00-.578-.06c-1.47 0-2.666 1.086-2.666 2.42zM15.367 1.177a.715.715 0 00-.93-.01l-.013.01-13 11.796c-.235.17-.286.599 0 .856l.003.002a.734.734 0 00.94-.002l13-11.796a.568.568 0 000-.856z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h16v13H0z" transform="translate(0 1)" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 16 16',
});
