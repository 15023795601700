import React, { useEffect } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Pane, Button, SearchInput, IconButton, Icon } from 'evergreen-ui';

import * as ridesTypes from 'rides/types';
import { isArrayAndNotEmpty } from 'rides/utils/data'
import { Box, Flex, Text } from 'rides/nui';
import TripAssignFilter from 'rides/nui/containers/TripAssignFilter';
import DatePicker from './DatePicker';

const SetParamsOnInitialLoad = ({ hasLoadedOnce, afterInitLoadFn }) => {
  useEffect(() => {
    if (hasLoadedOnce) {
      afterInitLoadFn();
    }
  }, [afterInitLoadFn, hasLoadedOnce]); // Only re-run the effect if count changes

  return null;
};

SetParamsOnInitialLoad.propTypes = {
  hasLoadedOnce: PropTypes.bool,
  afterInitLoadFn: PropTypes.func,
};

export const formatDateStr = date => {
  if (!date) {
    return null;
  }

  const d = new Date(date);
  const year = d.getFullYear();
  const month = `${d.getMonth() + 1}`.padStart(2, '0');
  const day = `${d.getDate()}`.padStart(2, '0');

  const dateString = [year, month, day].join('-');
  return dateString;
};

/**
 * Parse date string to date object. If non-string value passed or date parsing
 * throws error `null` is returned
 *
 * @param dateStr {string}
 */
const parseDateStr = dateStr => {
  try {
    if (typeof dateStr == 'string') {
      const dateStrFix = dateStr.replaceAll('-', '/'); // needed or 1-day early
      const date = new Date(dateStrFix);
      return date;
    }
  } catch (e) {
    console.error(`Failed to parse from_date param: (${typeof dateStr}) ${dateStr}`);
    throw e;
  }

  return null;
};

/**
 * @param d1 {Date | null}
 * @param d2 {Date | null}
 */
const isDateDiff = (d1, d2) => {
  if (!d1 || !d2) {
    // when falsey value we can check strict equality
    return d1 !== d2;
  }

  // date objects need to be tested for date only
  return formatDateStr(d1) !== formatDateStr(d2);
};

class TripListPageSearchBox extends React.Component {
  state = {};

  doSearch = searchParams => {
    const {
      keywords,
      assigned_to,
      status,
      type,
      pickup_urbanicity,
      to_date,
      from_date,
    } = this.state;

    this.props.changeSearchParams({
      keywords,
      assigned_to,
      status,
      type,
      pickup_urbanicity,
      page: 1,
      to_date: formatDateStr(to_date),
      from_date: formatDateStr(from_date),
      ...searchParams,
    });
    this.setState({ assigned_to });
  };

  getSearchOptionAssignedTo() {
    const { searchOptions } = this.props;
    const includeUnassigned = R.pipe(
      R.path(['include_unassigned']),
      R.ifElse(R.equals(true), R.always(['include_unassigned']), R.always([])),
    )(searchOptions);

    const assignedTo = R.pipe(R.path(['assigned_to']), R.defaultTo([]))(searchOptions);

    return R.uniq([...includeUnassigned, ...assignedTo]);
  }

  getSearchOptionType = () => {
    const type = R.pipe(
      R.pathOr([], ['searchOptions', 'type']),
      R.map(type => ~~type),
    )(this.props);
    return type;
  };

  getSearchOptionStatus = () => {
    const status = R.pipe(
      R.pathOr([], ['searchOptions', 'status']),
      // R.map(status => status),
    )(this.props);
    return status;
  };

  getSearchOptionPickupUrbanicity = () => {
    const pickupUrbanicity = R.pipe(
      R.pathOr([], ['searchOptions', 'pickup_urbanicity']),
      // R.map(pickupUrbanicity => ~~pickupUrbanicity),
    )(this.props);
    return pickupUrbanicity;
  };

  getSearchOptionDateRange = () => {
    const dateFromRaw = R.pathOr(null, ['searchOptions', 'from_date'], this.props);
    const dateToRaw = R.pathOr(null, ['searchOptions', 'to_date'], this.props);

    let from_date = parseDateStr(dateFromRaw);
    let to_date = parseDateStr(dateToRaw);

    return { from_date, to_date };
  };

  getSearchTerm = () => {
    const searchTermProp = R.pathOr('', ['searchOptions', 'keywords'], this.props);
    const { keywords } = this.state;
    return R.is(String, keywords) ? keywords : searchTermProp;
  };

  handleChange = event => {
    this.setSearchParams({ keywords: `${event.target.value}` });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.doSearch();
  };

  setParamAssignedTo = selected => {
    this.setState({ assigned_to: selected });
  };

  setParamStatus = selected => {
    this.setState({ status: selected });
  };

  setParamType = selected => {
    this.setState({ type: selected });
  };

  setParamPickupUrbanicity = selected => {
    this.setState({ pickup_urbanicity: selected });
  };

  /**
   * @param {date} A Date object or `null`
   */
  setParamDateFrom = date => {
    this.setState({ from_date: date });
  };

  clearParamDateFrom = () => {
    this.setState({ from_date: null });
  };

  /**
   * @param {date} A Date object or `null`
   */
  setParamDateTo = date => {
    this.setState({ to_date: date });
  };

  clearParamDateTo = () => {
    this.setState({ to_date: null });
  };

  setParamPickupUrbanicity = selected => {
    this.setState({ pickup_urbanicity: selected });
  };

  setSearchParams = params => {
    this.setState(({ searchParams }) => ({
      ...searchParams,
      ...params,
    }));
  };

  setParamsAfterInitLoad = () => {
    const assigned_to = this.getSearchOptionAssignedTo();
    const type = this.getSearchOptionType();
    const status = this.getSearchOptionStatus();
    const pickup_urbanicity = this.getSearchOptionPickupUrbanicity();
    const { from_date, to_date } = this.getSearchOptionDateRange();
    this.setState({
      assigned_to,
      status,
      type,
      pickup_urbanicity,
      from_date,
      to_date,
    });
  };

  resultsDateRangeText = () => {
    const { from_date, to_date } = this.getSearchOptionDateRange();
    if (from_date && to_date) {
      return `${formatDateStr(from_date)} to ${formatDateStr(to_date)}`;
    } else if (from_date) {
      return `${formatDateStr(from_date)} to --`;
    } else if (to_date) {
      return `-- to ${formatDateStr(to_date)}`;
    }

    return '';
  };

  /**
   * This strips values that aren't allowed for current status_group
   **/
  getTripStatusValues(statusValue) {
    const status = isArrayAndNotEmpty(statusValue) ? statusValue : [];
    const tripStatusList = this.props.tripStatusList ?? [];

    const hasStatusList = isArrayAndNotEmpty(tripStatusList);
    if (!hasStatusList) {
      // nothing to filter by so return entire
      return status;
    }

    const allowedStatusValues = tripStatusList.map(item => item.id);
    const filteredStatus = status.filter(item => {
      const isAllowed = R.contains(item, allowedStatusValues);
      return isAllowed;
    });

    return filteredStatus;
  }

  render() {
    const {
      keywords,
      searchOptions,
      assigneeList,
      tripStatusList,
      tripTypeList,
      tripPickupUrbanicityList,
      showStaffUI,
      hasLoadedOnce,
    } = this.props;
    const {
      assigned_to,
      status,
      type,
      pickup_urbanicity,
      from_date,
      to_date,
    } = this.state;

    const assignedTo = assigned_to;
    const tripType = type;
    const pickupUrbanicity = pickup_urbanicity;
    const dateFrom = from_date;
    const dateTo = to_date;

    const tripStatus = this.getTripStatusValues(status);

    const dateRangeCurrentParams = this.getSearchOptionDateRange();
    const isFromDateChanged = isDateDiff(dateFrom, dateRangeCurrentParams.from_date);
    const isToDateChanged = isDateDiff(dateTo, dateRangeCurrentParams.to_date);
    const isDateRangeChanged = isFromDateChanged || isToDateChanged;

    return (
      <Pane
        padding={4}
        marginBottom={14}
        borderRadius={3}
        tint="blueTint"
        elevation={1}
        background="tint1"
        minWidth="fit-content"
      >
        <SetParamsOnInitialLoad
          hasLoadedOnce={hasLoadedOnce}
          afterInitLoadFn={this.setParamsAfterInitLoad}
        />
        <Box px="2px" className=".block-focus-outline-fix">
          <Pane
            display="flex"
            flexWrap="wrap"
            padding={16}
            background="tint2"
            borderRadius={3}
          >
            <Pane display="flex">
              <Pane alignItems="center" display="flex">
                <form onSubmit={this.handleSubmit}>
                  <SearchInput
                    placeholder="Search"
                    width={600}
                    height={40}
                    value={this.getSearchTerm()}
                    onChange={this.handleChange}
                  />
                </form>
              </Pane>
              <Pane marginLeft={8} display="flex" alignItems="center">
                <Button
                  appearance="primary"
                  height={36}
                  marginLeft={8}
                  type="submit"
                  onClick={this.handleSubmit}
                >
                  Search
                </Button>
              </Pane>
            </Pane>
            <Pane width="100%">
              <TripAssignFilter
                searchOptions={searchOptions}
                assignedTo={assignedTo}
                status={tripStatus}
                type={tripType}
                pickupUrbanicity={pickupUrbanicity}
                setSelected={this.setParamAssignedTo}
                setParamStatus={this.setParamStatus}
                setParamType={this.setParamType}
                setParamPickupUrbanicity={this.setParamPickupUrbanicity}
                onSearch={this.doSearch}
                assigneeList={assigneeList}
                tripStatusList={tripStatusList}
                tripTypeList={tripTypeList}
                tripPickupUrbanicityList={tripPickupUrbanicityList}
                showStaffUI={showStaffUI}
                isDateRangeChanged={isDateRangeChanged}
              />
              <Flex
                width="fit-content"
                borderRadius="3px"
                alignItems="baseline"
                justifyContent="stretch"
                color="mediumGray"
                marginTop="8px"
              >
                <DatePicker
                  selectedDate={dateFrom}
                  onChange={this.setParamDateFrom}
                  onClear={this.clearParamDateFrom}
                  isClearable={true}
                />
                <Text
                  lineHeight="15px"
                  fontSize={13}
                  style={{
                    minHeight: 23,
                    padding: '0 8px',
                    marginLeft: '-12px',
                  }}
                >
                  to
                </Text>
                <DatePicker
                  selectedDate={dateTo}
                  onChange={this.setParamDateTo}
                  onClear={this.clearParamDateTo}
                  isClearable={true}
                />
              </Flex>
            </Pane>
          </Pane>
          <Flex
            width="800px"
            borderRadius="3px"
            alignItems="center"
            justifyContent="stretch"
            color="mediumGray"
          />
          <Pane paddingY={4} paddingLeft={15} borderRadius={3} tint="blueTint">
            <Text lineHeight="15px" fontSize={13} style={{ minHeight: 23 }}>
              Search results for {keywords && `"${keywords}”`}{' '}
              {this.resultsDateRangeText()}
            </Text>
            <Pane>
              <TripAssignFilter
                assignedTo={assignedTo}
                status={tripStatus}
                type={tripType}
                pickupUrbanicity={pickupUrbanicity}
                searchOptions={searchOptions}
                setSelected={this.setParamAssignedTo}
                setParamStatus={this.setParamStatus}
                setParamType={this.setParamType}
                setParamPickupUrbanicity={this.setParamPickupUrbanicity}
                onSearch={this.doSearch}
                assigneeList={assigneeList}
                tripStatusList={tripStatusList}
                tripTypeList={tripTypeList}
                tripPickupUrbanicityList={tripPickupUrbanicityList}
                showStaffUI={showStaffUI}
                renderTags
              />
            </Pane>
          </Pane>
          {/*<Box m={2}>{filterTags}</Box>}*/}
        </Box>
      </Pane>
    );
  }
}

TripListPageSearchBox.propTypes = {
  keywords: PropTypes.string,
  changeSearchParams: PropTypes.func.isRequired,
  searchOptions: PropTypes.object,
  showStaffUI: PropTypes.bool.isRequired,
  assigneeList: ridesTypes.tripAssigneeListType,
  tripStatusList: ridesTypes.tripStatusListStatus,
  tripTypeList: ridesTypes.tripTypeListType,
  tripPickupUrbanicityList: ridesTypes.tripPickupUrbanicityOptionListType,
};

export default TripListPageSearchBox;
