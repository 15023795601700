/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'VehicleIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M12.8 11.36c-.882 0-1.6-.7-1.6-1.56 0-.86.718-1.56 1.6-1.56.882 0 1.6.7 1.6 1.56 0 .86-.718 1.56-1.6 1.56zM9.866 9.28H6.133a.263.263 0 01-.267-.26c0-.143.12-.26.267-.26h3.733c.147 0 .267.117.267.26 0 .144-.12.26-.267.26zm0 1.04H6.133a.264.264 0 01-.267-.26c0-.144.12-.26.267-.26h3.733c.147 0 .267.116.267.26 0 .143-.12.26-.267.26zM3.2 11.36c-.883 0-1.6-.7-1.6-1.56 0-.86.717-1.56 1.6-1.56.882 0 1.6.7 1.6 1.56 0 .86-.718 1.56-1.6 1.56zm.397-8.366a.798.798 0 01.736-.474h7.334c.32 0 .61.186.736.474l1.615 3.686H1.982l1.615-3.686zm11.033 3.76l-1.736-3.965A1.335 1.335 0 0011.667 2H4.333c-.535 0-1.016.31-1.226.79L1.37 6.753C.582 6.968 0 7.665 0 8.5v3.12c0 .43.359.78.8.78h.267v1.82c0 .43.359.78.8.78h1.6c.44 0 .8-.35.8-.78V12.4h7.466v1.82c0 .43.36.78.8.78h1.6c.441 0 .8-.35.8-.78V12.4h.267c.441 0 .8-.35.8-.78V8.5c0-.835-.583-1.532-1.37-1.746z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
