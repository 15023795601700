const shadows = {
  sm: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
  md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
  '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
  outline: '0 0 0 3px rgba(66, 153, 225, 0.6)',
  inner: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
  none: '0px 0px 0px rgba(23, 31, 70, 0.5)',

  /* CareCar Drop Shadow */

  // CareCar Detail Panel shadow
  herDP: '-4px 4px 16px rgba(23, 31, 70, 0.25)',

  // CareCar Figma HER Drop Shaddow
  herMd: '-4px 4px 16px rgba(23, 31, 70, 0.25)',
  herMdx: '-2px 2px 8px rgba(23, 31, 70, 0.25)',

  // CareCar Brandon layered HER Drop Shaddow
  // NOTE css shadow technique from: https://tobiasahlin.com/blog/layered-smooth-box-shadows/
  // md: `-2 1px 1px rgba(0,0,0,0.11),
  //      -2 2px 2px rgba(0,0,0,0.11),
  //      -2 4px 4px rgba(0,0,0,0.11),
  //      -2 6px 8px rgba(0,0,0,0.11),
  //      -2 8px 16px rgba(0,0,0,0.11)`,
  // lg: `-4 1px 2px rgba(0,0,0,0.07),
  //      -4 2px 4px rgba(0,0,0,0.07),
  //      -4 4px 8px rgba(0,0,0,0.07),
  //      -4 8px 16px rgba(0,0,0,0.07),
  //      -4 16px 32px rgba(0,0,0,0.07),
  //      -4 32px 64px rgba(0,0,0,0.07)`,
  // xl: `0 2px 1px rgba(0,0,0,0.09),
  //      0 4px 2px rgba(0,0,0,0.09),
  //      0 8px 4px rgba(0,0,0,0.09),
  //      0 16px 8px rgba(0,0,0,0.09),
  //      0 32px 16px rgba(0,0,0,0.09)`,
};

export default shadows;
