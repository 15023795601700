// CANCEL TRIP
export const TRIP_CANCEL_TRIP_FOR_MEMBER_INIT = 'TRIP_CANCEL_TRIP_FOR_MEMBER_INIT';
export const TRIP_CANCEL_TRIP_FOR_MEMBER_CLOSE = 'TRIP_CANCEL_TRIP_FOR_MEMBER_CLOSE';
export const TRIP_CANCEL_TRIP_FOR_MEMBER_SUBMIT = 'TRIP_CANCEL_TRIP_FOR_MEMBER_SUBMIT';

export const tripCancelTripForMemberInit = (memberId, tripId) => ({
  type: TRIP_CANCEL_TRIP_FOR_MEMBER_INIT,
  payload: { memberId, tripId },
});

export const tripCancelTripForMemberClose = () => ({
  type: TRIP_CANCEL_TRIP_FOR_MEMBER_CLOSE,
});

export const tripCancelTripForMemberSubmit = cancelReason => ({
  type: TRIP_CANCEL_TRIP_FOR_MEMBER_SUBMIT,
  payload: { cancelReason },
});

// LOAD TRIP DETAIL
export const TRIP_LOAD_DETAIL_FOR_MEMBER_REQUEST = 'TRIP_LOAD_DETAIL_FOR_MEMBER_REQUEST';

export const tripLoadDetailForMember = (memberId, tripId) => ({
  type: TRIP_LOAD_DETAIL_FOR_MEMBER_REQUEST,
  payload: { memberId, tripId },
  meta: { thunk: true },
});

// LOAD TRIP LIST
export const TRIP_LOAD_LIST_FOR_MEMBER_REQUEST = 'TRIP_LOAD_LIST_FOR_MEMBER_REQUEST';

export const tripLoadListForMember = (memberId, tripId) => ({
  type: TRIP_LOAD_LIST_FOR_MEMBER_REQUEST,
  payload: { memberId, tripId },
  meta: { thunk: true },
});

/**
 * Trip Group
 */

// LOAD TRIP GROUP DETAIL
export const TRIP_GROUP_LOAD_DETAIL_FOR_MEMBER_REQUEST =
  'TRIP_GROUP_LOAD_DETAIL_FOR_MEMBER_REQUEST';

export const tripGroupLoadDetailForMember = (memberId, tripGroupId) => ({
  type: TRIP_GROUP_LOAD_DETAIL_FOR_MEMBER_REQUEST,
  payload: { memberId, tripGroupId },
  meta: { thunk: true },
});

// LOAD TRIP GROUP LIST
export const TRIP_GROUP_LOAD_LIST_FOR_MEMBER_REQUEST =
  'TRIP_GROUP_LOAD_LIST_FOR_MEMBER_REQUEST';

export const tripGroupLoadListForMember = memberId => ({
  type: TRIP_GROUP_LOAD_LIST_FOR_MEMBER_REQUEST,
  payload: { memberId },
  meta: { thunk: true },
});
