import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Caption } from 'rides/components';

const Table = ({ className, bordered, caption, head, foot, children, ...props }) => {
  const classes = classNames(className, 'table', {
    'table-bordered': bordered,
  });

  return (
    <table {...props} className={classes}>
      {caption && <Caption>{caption}</Caption>}
      {head && <thead>{head}</thead>}
      {foot && <tfoot>{foot}</tfoot>}
      <tbody>{children}</tbody>
    </table>
  );
};

Table.propTypes = {
  className: PropTypes.string,
  bordered: PropTypes.bool,
  caption: PropTypes.string,
  head: PropTypes.node,
  foot: PropTypes.node,
  children: PropTypes.any,
};

export default Table;
