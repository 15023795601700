import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import { tripType } from 'rides/types';
import { Block, TripLeg } from 'rides/components';

const safeProps = R.omit(['rootTripId', 'tripGroup', 'dispatch']);

const TripLegList = ({ tripList, ...props }) => (
  <Block {...safeProps(props)}>
    {tripList && tripList.map(trip => <TripLeg key={trip.id} trip={trip} />)}
  </Block>
);

TripLegList.propTypes = {
  tripList: PropTypes.arrayOf(tripType),
};

export default TripLegList;
