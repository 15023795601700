import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Pane, toaster } from 'evergreen-ui';

import { tenantType } from 'rides/types';
import { fromApp } from 'rides/store/selectors';
import { removeEmpty } from 'rides/utils/data';
import { resourceListReadRequest } from 'rides/store/actions';
import useSearchParams from 'rides/admin-ui/hooks/use-search-params';
import { useSearchPanelSelectFilter } from 'rides/admin-ui/shared-ui/search-panel';
import SearchPanel from 'rides/admin-ui/shared-ui/search-panel';
import AdminUserListTable from 'rides/admin-ui/pages/user-list/table';

const parseKeywords = R.pipe(
  R.defaultTo(''),
  R.split(/\,|\s/g),
  removeEmpty,
);

function formatSearchParams(params) {
  const hasUserFlag = flagKey => {
    if (params.userFlags && R.contains(flagKey, params.userFlags)) return true;
    return undefined;
  };

  const hasDisabledFlag = () => {
    if (params.disabledFlags) {
      const isDisabled = R.contains('disabled', params.disabledFlags);
      const isNotDisabled = R.contains('notDisabled', params.disabledFlags);
      if (isDisabled && isNotDisabled) return null;
      if (isDisabled) return true;
      if (isNotDisabled) return false;
    }
    return null;
  };

  return {
    // pagination
    page: ~~params.pageIndex + 1,
    page_size: ~~params.pageSize,

    // search text
    keywords: parseKeywords(params.keywords),

    // filters
    is_admin: hasUserFlag('isAdmin'),
    is_developer: hasUserFlag('isDeveloper'),
    is_disabled: hasDisabledFlag(),
    roles: params.roles,
    tenants: params.tenants,
  };
}

const mapStateToProps = (state, props) => ({
  tenantList: fromApp.tenant.allTenants(state, props),
});

const mapDispatchToProps = dispatch => ({
  fetchUserList: (params = {}) => {
    const safeParams = formatSearchParams(params);
    return dispatch(resourceListReadRequest('manage/user', safeParams));
  },
});

function UserListPage({ fetchUserList, tenantList }) {
  const tenantOptions = React.useMemo(
    () => tenantList.map(({ id, name }) => ({ label: name, value: id })),
    [tenantList],
  );

  // Search Params
  const [
    [params, setParams],
    [nextParams, setNextParams, applyNextParams],
  ] = useSearchParams({
    initParams: {
      pageIndex: 0,
      pageSize: 20,
      userFlags: [],
      roles: [],
      tenants: [],
    },
  });

  const paramsProps = React.useMemo(
    () => ({ params, nextParams, setNextParams, applyNextParams }),
    [params, nextParams, setNextParams, applyNextParams],
  );

  // Filters
  const userFlagFilterProps = React.useMemo(
    () => ({
      ...paramsProps,
      label: 'User Flags',
      paramKey: 'userFlags',
      options: [
        { label: 'Admin', value: 'isAdmin' },
        { label: 'Developer', value: 'isDeveloper' },
      ],
    }),
    [paramsProps],
  );

  const roleFilterProps = React.useMemo(
    () => ({
      ...paramsProps,
      label: 'Roles',
      paramKey: 'roles',
      options: [
        { label: 'Staff', value: 'staff' },
        { label: 'Call Center', value: 'call_center' },
        { label: 'Call Center Read-Only', value: 'call_center_readonly' },
      ],
      tagProps: {
        color: 'neutral',
        icon: 'id-number',
        pendingOpacity: 0.5,
      },
    }),
    [paramsProps],
  );

  const tenantFilterProps = React.useMemo(
    () => ({
      ...paramsProps,
      label: 'Tenants',
      paramKey: 'tenants',
      options: tenantOptions,
      tagProps: {
        color: 'purple',
        icon: 'office',
        pendingOpacity: 0.5,
      },
    }),
    [paramsProps, tenantOptions],
  );

  const disabledFilterProps = React.useMemo(
    () => ({
      ...paramsProps,
      label: 'Disabled',
      paramKey: 'disabledFlags',
      options: [
        { label: 'Disabled', value: 'disabled' },
        { label: 'Not Disabled', value: 'notDisabled' },
      ],
      tagProps: {
        icon: 'disable',
        pendingOpacity: 0.5,
      },
    }),
    [paramsProps],
  );

  const userFlagFilter = useSearchPanelSelectFilter(userFlagFilterProps);
  const roleFilter = useSearchPanelSelectFilter(roleFilterProps);
  const tenantFilter = useSearchPanelSelectFilter(tenantFilterProps);
  const disabledFilter = useSearchPanelSelectFilter(disabledFilterProps);

  const { isFetching, error, ...restOut } = useQuery(
    ['adminUserList', params], //
    () => fetchUserList(params),
    {
      refetchOnWindowFocus: false,
      retry: false,
      // reqct-query v3
      keepPreviousData: true,
      onError: err => {
        toaster.danger('Something went wrong trying to load data.', {
          description: 'If this persists try refreshing the browser.',
        });
      },
    },
  );

  const { data = [], totalPages, totalEntries } = restOut.data || {};

  return (
    <div>
      <h1>ADMIN TOOLS</h1>
      <h2>USER LIST</h2>

      <Pane backgroundColor="white">
        <SearchPanel
          {...paramsProps}
          selectFilters={[userFlagFilter, roleFilter, tenantFilter, disabledFilter]}
          disabled={!!error}
        />
        <AdminUserListTable
          data={data}
          totalPages={totalPages}
          totalEntries={totalEntries}
          controlledPageIndex={params.pageIndex}
          controlledPageSize={params.pageSize}
          fetchData={({ pageIndex, pageSize }) => setParams({ pageIndex, pageSize })}
          isFetching={isFetching}
          disabled={!!error}
        />
      </Pane>
    </div>
  );
}
UserListPage.propTypes = {
  fetchUserList: PropTypes.func.isRequired,
  tenantList: PropTypes.arrayOf(tenantType).isRequired,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(UserListPage);
