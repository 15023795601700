import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Block = ({
  children,
  className,
  clearfix,
  pullLeft,
  pullRight,
  textCenter,
  textLeft,
  textRight,
  textMuted,
  ...props
}) => {
  const classes = classNames(className, {
    clearfix: clearfix,
    'pull-left': pullLeft,
    'pull-right': pullRight,
    'text-center': textCenter,
    'text-left': textLeft,
    'text-right': textRight,
    'text-muted': textMuted,
  });

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};

Block.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  clearfix: PropTypes.bool,
  pullLeft: PropTypes.bool,
  pullRight: PropTypes.bool,
  textCenter: PropTypes.bool,
  textLeft: PropTypes.bool,
  textRight: PropTypes.bool,
  textMuted: PropTypes.bool,
};

export default Block;
