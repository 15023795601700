import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';

import {
  Block,
  Column,
  FormFeedbackBox,
  FormikField,
  Row,
  TripWizardFooter,
  TripWizardStepTitle,
} from 'rides/components';

const TripWizardStepCancelTrip = props => (
  <Formik
    initialValues={props.values}
    validationSchema={Yup.object().shape({
      cancelReason: Yup.string(),
      // .required('Cancel Reason is required.')
      // .matches(/^\d{1,2}:\d{2} a|pm{1}$/, 'Must be (hh:mm am) format.'),
    })}
    onSubmit={values => {
      props.goToNextStep(values);
    }}
    render={formikProps => {
      const disabled = !!formikProps.isSubmitting || props.formDisabled;

      return (
        <Form>
          <Row>
            <Column xs={12} sm={10} smOffset={1}>
              <TripWizardStepTitle>Cancel Trip</TripWizardStepTitle>

              {!disabled && props.stepErrors.cancelTrip && (
                <FormFeedbackBox danger>{props.stepErrors.cancelTrip}</FormFeedbackBox>
              )}

              <Row>
                <Column xs={12} sm={6} smOffset={3}>
                  <Field
                    name="cancelReason"
                    label="Cancel Reason"
                    component={FormikField}
                    disabled={disabled}
                  />
                </Column>
              </Row>
            </Column>
          </Row>

          <TripWizardFooter
            {...props}
            values={formikProps.values}
            disabled={disabled}
            showCancelTrip
          />
        </Form>
      );
    }}
  />
);

TripWizardStepCancelTrip.propTypes = {
  currentStep: PropTypes.string,
  formDisabled: PropTypes.bool,
  goToNextStep: PropTypes.func.isRequired,
  goToPrevStep: PropTypes.func.isRequired,
  member: PropTypes.object.isRequired,
  stepErrors: PropTypes.object.isRequired,
  values: PropTypes.object,
};

export default TripWizardStepCancelTrip;
