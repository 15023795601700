import React from 'react';
import { Pane, Badge } from 'evergreen-ui';

export const WillCallBadge = () => (
  <Pane paddingY="0.5em">
    <Badge
      color="blue"
      isSolid
      paddingY={3}
      marginRight={8}
      height="unset"
      display="block"
      textTransform="none"
    >
      Will Call
    </Badge>
  </Pane>
);

export default WillCallBadge
