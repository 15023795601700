/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'MemberXlIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M5 13.526c0 1.71.672 2.732 2 3.05V23h8v-6.423c1.326-.32 1.998-1.34 2-3.05V8H5v5.526zM11.001 6.345c1.839 0 3.334-1.425 3.334-3.173S12.84 0 11 0C9.163 0 7.668 1.424 7.668 3.172c0 1.748 1.495 3.173 3.333 3.173z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 23 23',
});
