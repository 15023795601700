/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'RetrivalIcon',
  path: (
    <g fill="currentColor">
      <mask id="a">
        <path
          fill-rule="evenodd"
          d="M8.794 12H2.93C1.312 12 0 10.807 0 9.336V2.664C0 1.193 1.312 0 2.931 0H13.07C14.687 0 16 1.193 16 2.664v2.799"
          clip-rule="evenodd"
        />
      </mask>
      <path
        d="M8.794 14a2 2 0 100-4v4zM14 5.463a2 2 0 104 0h-4zM8.794 10H2.93v4h5.863v-4zM2.93 10c-.317 0-.569-.116-.727-.26C2.048 9.598 2 9.454 2 9.336h-4C-2 12.087.392 14 2.931 14v-4zM2 9.336V2.664h-4v6.672h4zm0-6.672c0-.118.048-.262.204-.404.158-.144.41-.26.727-.26v-4C.391-2-2-.087-2 2.664h4zM2.931 2H13.07v-4H2.93v4zM13.07 2c.317 0 .569.116.727.26.156.142.204.286.204.404h4C18-.087 15.608-2 13.069-2v4zm.931.664v2.799h4V2.664h-4z"
        mask="url(#a)"
      />
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M7 14l4-3-4-3"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
