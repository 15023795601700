import { useEffect, useRef, useState } from 'react';

/**
 * Prevent setting state when component is unmounted by detecting if component
 * is mounted before setting state
 */
function useSafeState(initialState) {
  const [state, setState] = useState({ ...initialState });

  const mountedRef = useRef(false);
  useEffect(() => {
    mountedRef.current = true;
    return () => (mountedRef.current = false);
  }, []);

  const safeSetState = (...args) => mountedRef.current && setState(...args);

  return [state, safeSetState];
}

export default useSafeState;
