import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fromEntities } from 'rides/store/selectors';

const mapStateToProps = (state, { entityIds, entityType }) => {
  const entityList = fromEntities.getList(state, entityType, entityIds);
  return { entityList };
};

const EntityList = ({ children, entityList, ...props }) => children(entityList);

EntityList.propTypes = {
  children: PropTypes.func,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  entityType: PropTypes.string.isRequired,
  entityList: PropTypes.arrayOf(PropTypes.object),
};

export default R.compose(withRouter, connect(mapStateToProps))(EntityList);
