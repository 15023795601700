// https://github.com/diegohaz/arc/wiki/Selectors
const R = require('ramda');

const authSelectors = require('./auth/selectors');
const confirmSelectors = require('./confirm/selectors');
const entitiesSelectors = require('./entities/selectors');
const modalSelectors = require('./modal/selectors');
const resourceSelectors = require('./resource/selectors');

function wrapSelectors(selectorsMap, storePath) {
  const wrappedSelectors = {};
  const getState = (state = {}) => R.pathOr({}, storePath, state);
  Object.keys(selectorsMap).forEach(name => {
    const selectorFn = selectorsMap[name];
    if (typeof selectorFn === 'function') {
      wrappedSelectors[name] = (state, ...args) => selectorFn(getState(state), ...args);
    }
  });

  return wrappedSelectors;
}

export const fromAuth = wrapSelectors(authSelectors, ['auth']);
export const fromConfirm = wrapSelectors(confirmSelectors, ['confirm']);
export const fromEntities = wrapSelectors(entitiesSelectors, ['entities']);
export const fromModal = wrapSelectors(modalSelectors, ['modal']);
export const fromResource = wrapSelectors(resourceSelectors, ['resource']);

export const fromApp = {
  member: require('./app/member/selectors'),
  tenant: require('./app/tenant/selectors'),
  trip: require('./app/trip/selectors'),
  tripWizard: require('./app/tripWizard/selectors'),
  ui: require('./app/ui/selectors'),
};
