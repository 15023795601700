import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';

import { tenantUseTimezone } from 'rides/store/actions';
import { fromApp, fromAuth } from 'rides/store/selectors';
import { tenantIdType } from 'rides/types';

// import { UpdateTimezoneToCurrentTenant } from 'rides/containers';
// // NOTE removing this import causes the app to crash
import 'rides/containers';

export function hasAnyRole(userRoles = [], allowedRoles = []) {
  if (!userRoles) return false;

  return R.any(R.contains(R.__, allowedRoles))(userRoles);
}

const mapDispatchToProps = {
  setTimezoneToTenant: tenantUseTimezone,
};

const mapStateToProps = (state, props) => ({
  isAuthenticated: fromAuth.isAuthenticated(state, props),
  roles: fromAuth.roles(state, props),
  currentTenantId: fromAuth.currentTenantId(state, props),
  currentTenantTimezone: fromApp.tenant.currentTenantTimezone(state, props),
});

class RenderWrapper extends React.Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    component: PropTypes.func,
    render: PropTypes.func,
    renderProps: PropTypes.object,
    meetsRoleRequirements: PropTypes.bool.isRequired,
  };

  render() {
    if (!this.props.isAuthenticated) return null;

    if (!this.props.meetsRoleRequirements) {
      // TODO show not authorized page
      return null;
    }

    const C = this.props.component;
    return this.props.render ? (
      this.props.render(this.props.renderProps)
    ) : (
      <C {...this.props.renderProps} />
    );
  }
}

const roleType = PropTypes.string;
const roleListType = PropTypes.arrayOf(roleType);

class SecureOnRolesRoute extends React.Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    auth: PropTypes.object,
    authorizedRoles: roleListType.isRequired,
    blockedRoles: roleListType.isRequired,
    component: PropTypes.func,
    path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    render: PropTypes.elementType,
    renderProps: PropTypes.object,
    roles: roleListType,
    setTimezoneToTenant: PropTypes.func.isRequired,
    currentTenantId: tenantIdType,
    currentTenantTimezone: PropTypes.string,
  };

  static defaultProps = {
    authorizedRoles: [],
    blockedRoles: [],
  };

  constructor(props) {
    super(props);
    this.createRenderWrapper = this.createRenderWrapper.bind(this);
  }

  meetsRoleRequirements() {
    const { authorizedRoles, blockedRoles, roles } = this.props;

    if (this.props.blockedRoles && !R.isEmpty(blockedRoles)) {
      if (hasAnyRole(roles, blockedRoles)) {
        return false;
      }
    }

    // NOTE allows role `admin` access to everything!
    return hasAnyRole(roles, ['admin', ...authorizedRoles]);
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentTenantTimezone !== prevProps.currentTenantTimezone) {
      // console.log('UpdateTimezoneToCurrentTenant:cDU', { props: this.props, prevProps });
      this.updateTimezoneToCurrentTenant();
    }
  }

  updateTimezoneToCurrentTenant() {
    const { setTimezoneToTenant, currentTenantId } = this.props;
    setTimezoneToTenant(currentTenantId);
  }

  createRenderWrapper(renderProps) {
    return (
      <RenderWrapper
        isAuthenticated={this.props.isAuthenticated}
        login={() => {}}
        component={this.props.component}
        render={this.props.render}
        renderProps={renderProps}
        meetsRoleRequirements={this.meetsRoleRequirements()}
      />
    );
  }

  render() {
    return <Route path={this.props.path} render={this.createRenderWrapper} />;
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SecureOnRolesRoute);
