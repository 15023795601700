import { useCallback } from 'react';
import useSafeState from 'rides/admin-ui/hooks/use-safe-state';

function useSearchParams({ initParams }) {
  const [params, resetParams] = useSafeState(initParams);
  const [nextParams, resetNextParams] = useSafeState(params);

  const setParams = useCallback(
    newState => resetParams(prevState => ({ ...prevState, ...newState })),
    [resetParams],
  );

  const setNextParams = useCallback(
    newState => resetNextParams(prevState => ({ ...prevState, ...newState })),
    [resetNextParams],
  );

  /**
   * Update current params with pending params (nextParams)
   */
  const applyNextParams = useCallback(
    newState => {
      const newParams = { ...params, ...nextParams, ...newState };
      resetNextParams(newParams);
      resetParams(newParams);
    },
    [resetParams, resetNextParams, params, nextParams],
  );

  return [
    // current params
    [params, setParams],
    // pending params
    [nextParams, setNextParams, applyNextParams],
  ];
}

export default useSearchParams;
