import React from 'react';
import PropTypes from 'prop-types';

import EntityListLoader from 'rides/components/utils/EntityListLoader';
import { ActivityLog, Block, Button, FormFeedbackBox, Heading } from 'rides/components';

const TripListLoader = ({ resourcePath, title, ...props }) => (
  <EntityListLoader
    resource={resourcePath}
    entityType="activityLog"
    renderNotAsked={() => null}
    renderLoading={() => <FormFeedbackBox error="Loading..." warning />}
    renderError={(/*error*/) => <FormFeedbackBox error="Failed to load purposes" />}
    renderSuccess={items => <ActivityLog logList={items} />}
  >
    {(View, loadData) => (
      <Block>
        <Heading level={3}>{title}</Heading>
        {View}
        <Button onClick={loadData} success>
          Load Data
        </Button>
      </Block>
    )}
  </EntityListLoader>
);

TripListLoader.propTypes = {
  title: PropTypes.node.isRequired,
  resourcePath: PropTypes.string.isRequired,
};

export default TripListLoader;
