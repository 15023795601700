import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Glyphicon = ({ className, style, icon, small, textInfo, ...props }) => {
  const madProps = {
    ...props,
    className: classNames(className, 'glyphicon', `glyphicon-${icon}`, {
      'text-info': textInfo,
    }),
  };

  if (small) {
    return <small style={style} {...madProps} />;
  } else {
    return <i style={style} {...madProps} />;
  }
};

Glyphicon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  small: PropTypes.bool,
  style: PropTypes.object,
  textInfo: PropTypes.bool,
};

Glyphicon.defaultProps = {
  style: {},
};

export default Glyphicon;
