import * as R from 'ramda';
import { createSelector } from 'reselect';

// https://github.com/diegohaz/arc/wiki/Selectors
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
export const initialState = {
  isAuthAttempted: false,
  isAuthenticated: false,
  isAuthenticating: false,
  didAuthFail: false,
  needNewPassword: false,

  authErrors: null,
  user: null,
  currentTenantId: null,
};

/** On app boot, this is false and is set to true once the session has attempted
 *  to have been restored, whether it succeeded or fails. */
export const isAuthAttempted = (state = initialState) => state.isAuthAttempted;

export const isAuthenticated = (state = initialState) => state.isAuthenticated;

export const isAuthenticating = (state = initialState) => state.isAuthenticating;

export const didAuthFail = (state = initialState) => state.didAuthFail;

export const needNewPassword = (state = initialState) => state.needNewPassword;

export const user = (state = initialState) => state.user;

export const currentTenantId = (state = initialState) => state.currentTenantId;

// user based
export const metadata = createSelector([user], R.propOr({}, 'metadata'));

export const roles = createSelector([user], R.propOr([], 'roles'));

export const userId = createSelector([user], R.propOr(null, 'user_id'));

// metadata based
export const expired = createSelector([metadata], R.propOr(null, 'expired'));

export const name = createSelector([metadata], R.propOr(null, 'name'));

export const tenantIds = createSelector([metadata], R.propOr(null, 'tenant_id'));

export const username = createSelector([metadata], R.propOr(null, 'username'));

// tenantIds based
export const hasMultipleTenantIds = createSelector(
  [tenantIds],
  R.compose(R.lte(2), R.length),
);

// composite metadata
export const userDisplayName = createSelector(
  [name, username],
  (name, username) => name || username || '--',
);
