import React from 'react';
import PropTypes from 'prop-types';

const Nav = ({ children, ...props }) => <nav {...props}>{children}</nav>;

Nav.propTypes = {
  children: PropTypes.node,
};

export default Nav;
