import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { LocationAutocomplete } from 'rides/containers';
import { Field } from 'rides/components';

class TripWizardLocationAutocompleteFieldContainer extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    type: PropTypes.func.isRequired,
    updateValues: PropTypes.func.isRequired,
  };

  handlePlaceClick = (place, details) => {
    const { updateValues } = this.props;

    const getValue = field => {
      const value = R.propOr('', field, details);
      return value === null ? '' : value;
    };

    updateValues({
      location_autocomplete: place.address,
      location_address1: getValue('address1'),
      location_address2: getValue('address2'),
      location_city: getValue('city'),
      location_state: getValue('state'),
      location_postalCode: getValue('postalCode'),
    });
  };

  render() {
    const { type, ...fieldProps } = this.props;
    const { value } = this.props;

    return (
      <LocationAutocomplete value={value} onPlaceClick={this.handlePlaceClick}>
        <Field
          {...fieldProps}
          placeholder="Search for a business or address"
          autoComplete="off"
        />
      </LocationAutocomplete>
    );
  }
}

export default TripWizardLocationAutocompleteFieldContainer;
