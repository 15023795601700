/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'NotesTabXlIcon',
  path: (
    <g fill="currentColor">
      <g clip-path="url(#clip0)">
        <g clip-path="url(#clip1)">
          <path
            fill-rule="evenodd"
            d="M10.252 2.609H1.465C.659 2.609 0 3.32 0 4.189v11.068h1.465V4.19h8.787V2.609zm2.196 3.162H4.393c-.805 0-1.464.711-1.464 1.58V18.42c0 .87.659 1.581 1.464 1.581h8.055c.806 0 1.465-.712 1.465-1.581V7.352c0-.87-.66-1.581-1.465-1.581zm0 12.648H4.395V7.352h8.055v11.067z"
            clip-rule="evenodd"
          />
        </g>
        <rect
          width="13.214"
          height="13.214"
          x="10.143"
          y="10.143"
          stroke-width="1.714"
          rx="6.607"
        />
        <path d="M16.193 19l-1.203-3.92h-.031c.043.797.065 1.33.065 1.596V19h-.947v-4.997h1.443l1.182 3.821h.02l1.255-3.821h1.442V19h-.987v-2.365c0-.112 0-.24.003-.387.005-.145.02-.533.048-1.162h-.03L17.162 19h-.97z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h23v23H0z" />
        </clipPath>
        <clipPath id="clip1">
          <path d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 23 23',
});
