import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import { locationType } from 'rides/types';
import { Atom, Strong } from 'rides/components';

const removeEmpty = R.reject(R.either(R.isEmpty, R.isNil));

const Address = ({ boldStreet, breakOnStreet, location, ...props }) => {
  const safeLocation = removeEmpty(location || {});
  const { address1, address2, city, state, postalCode } = safeLocation;

  const addressStreet = (
    <Atom className="address-street">
      {address1}
      {address2 && ` ${address2}`}
    </Atom>
  );

  return (
    <Atom {...props}>
      {boldStreet ? <Strong>{addressStreet}</Strong> : addressStreet}
      {breakOnStreet ? <br /> : ','}
      {city && ` ${city},`}
      {state && ` ${state}`}
      {postalCode && ` ${postalCode}`}
    </Atom>
  );
};

Address.propTypes = {
  boldStreet: PropTypes.bool,
  breakOnStreet: PropTypes.bool,
  location: locationType,
};

export default Address;
