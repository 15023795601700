import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { Pill } from 'evergreen-ui';

import * as ridesTypes from 'rides/types';
import { isValidIsoDateTime } from 'rides/utils/dateTime';
import { isStr, metersToMiles } from 'rides/utils/data';
import { Box, Button, Flex, Link, Text } from 'rides/nui';

import Icon from 'rides/nui/Icon';
import Detail from 'rides/nui/Detail';
import PhoneNumber from 'rides/nui/PhoneNumber';
import DateTime from 'rides/nui/DateTime';
import TripStatusDropdown from 'rides/nui/containers/TripStatusDropdown';
// import { VendorNotConfirmedNotice } from 'rides/nui/TripStatusDropdown';
import TripRequestPickupButton from 'rides/nui/containers/TripRequestPickupButton';
import TripTransitionsTable from 'rides/nui/containers/TripTransitionsTable';
import { useTenantUrl } from 'rides/hooks/use-tenant';

// TODO move to nui/TripList/..
import TripListTripMenu from 'rides/nui/containers/TripListTripMenu';
import TripGroupId from './TripGroupId';
import TripListRow, { TripListColumn } from './TripListRow';
import { tripStatus } from 'rides/utils/trip';
import WillCallBadge from './WillCallBadge';
import { getProviderDetailText } from 'rides/evo/utils/providers';

const removeEmpty = R.reject(R.either(R.isEmpty, R.isNil));

function getTripTookanMapUrl(trip) {
  const doPickupLink =
    trip.status === tripStatus.SCHEDULED ||
    trip.status === tripStatus.VENDOR_ASSIGNED ||
    trip.status === tripStatus.DRIVER_ASSIGNED ||
    trip.status === tripStatus.DRIVER_EN_ROUTE ||
    trip.status === tripStatus.DRIVER_ARRIVED;

  const doDeliveryLink =
    trip.status === tripStatus.IN_TRANSIT || trip.status === tripStatus.COMPLETED;

  // status that doesn't use tookan map link
  if (!doPickupLink && !doDeliveryLink) return null;

  const taskData = getTripTookanTaskData(trip);
  if (!taskData) return null;

  if (doPickupLink) {
    return taskData.pickupTrackingLink;
  }

  if (doDeliveryLink) {
    return taskData.deliveryTrackingLink;
  }

  return null;
}

const TripLegPickupDropoffGapWrapper = styled(Flex)`
  width: 100%;
  margin-top: 6px;
  margin-bottom: 5px;

  svg {
    line {
      stroke-width: 1px;
      stroke: currentColor;
    }
    rect {
      fill: currentColor;
      x: ${props => props.width - 6}px;
    }
    circle {
      fill: currentColor;
    }
  }
`;

const TripLegPickupDropoffGap = ({ width, ...props }) => (
  <TripLegPickupDropoffGapWrapper {...props}>
    <svg width={width} height={6}>
      <g shapeRendering="crispEdges">
        <circle cx="3" cy="3" r="3" />
        <line x1="0" y1="3" x2="100%" y2="3" />
      </g>
    </svg>
    <svg width={6} height={6}>
      <g shapeRendering="crispEdges">
        <rect width="6" height="6" />
      </g>
    </svg>
  </TripLegPickupDropoffGapWrapper>
);

TripLegPickupDropoffGap.propTypes = {
  width: PropTypes.number.isRequired,
};

const TripLegPickupDropoffAddress = ({ location, width, ...props }) => (
  <Flex width={width} justifyContent="stretch" pt="6px" {...props}>
    <Detail
      renderText={() => {
        const safePickup = removeEmpty(location || {});
        return (
          <React.Fragment>
            <Text fontWeight="600">{safePickup.name}</Text>
            <Box>{safePickup.address1}</Box>
            {safePickup.address2 && <Box>{safePickup.address2}</Box>}
            <Box>
              {safePickup.city && ` ${safePickup.city},`}
              {safePickup.state && ` ${safePickup.state}`}
              {safePickup.postalCode && ` ${safePickup.postalCode}`}
            </Box>
            {safePickup.phoneNumber && (
              <PhoneNumber
                phoneNumber={safePickup.phoneNumber}
                render={({ phoneNumber, iconName }) => (
                  <Flex pt={1}>
                    <Icon name={iconName} fontSize="1.3em" py="2px" />
                    <Text>{phoneNumber}</Text>
                  </Flex>
                )}
              />
            )}
          </React.Fragment>
        );
      }}
      flex="1"
    />
  </Flex>
);

TripLegPickupDropoffAddress.propTypes = {
  location: ridesTypes.locationType.isRequired,
  width: PropTypes.number.isRequired,
};

const TripListTripVendor = ({ trip, ...props }) => {
  // const vendorName = R.pathOr('--', ['vendor', 'name'], trip);
  const vendorName = getProviderDetailText(trip?.vendor)
  return (
    <Box {...props}>
      VENDOR
      <Detail text={vendorName} mb={2} mt={2} textColor="black" />
    </Box>
  );
};

TripListTripVendor.propTypes = {
  trip: ridesTypes.tripType.isRequired,
};

const TripListTripVehicleDescription = ({ trip, ...props }) => {
  const vehicleDescription = R.pathOr('--', ['customDriverCarDescription'], trip);
  return (
    <Box {...props}>
      VEHICLE DESCRIPTION
      <Detail text={vehicleDescription} my={2} textColor="black" />
    </Box>
  );
};

TripListTripVehicleDescription.propTypes = {
  trip: ridesTypes.tripType.isRequired,
};

const TripListTripDriver = ({ trip, ...props }) => {
  const driverName = R.pathOr('--', ['customDriverName'], trip);
  const driverPhoneNumber = R.pathOr('--', ['customDriverPhone'], trip);

  return (
    <Box {...props}>
      DRIVER
      <Detail icon="driver" text={driverName} my={2} textColor="black" />
      <PhoneNumber
        phoneNumber={driverPhoneNumber}
        render={({ phoneNumber, iconName }) => (
          <Detail icon={iconName} text={phoneNumber} mb={2} textColor="black" />
        )}
      />
    </Box>
  );
};

TripListTripDriver.propTypes = {
  trip: ridesTypes.tripType.isRequired,
};

const TripListTripCompanion = ({ trip, ...props }) => {
  const name = R.pathOr('--', ['companion', 'name'], trip);
  const phoneNumber = R.pathOr('--', ['companion', 'phoneNumber'], trip);

  return (
    <Box {...props}>
      COMPANION
      <Detail icon="companion" text={name} my={2} textColor="darkGray" />
      <PhoneNumber
        phoneNumber={phoneNumber}
        render={({ phoneNumber, iconName }) => (
          <Detail icon={iconName} text={phoneNumber} mb={2} textColor="darkGray" />
        )}
      />
    </Box>
  );
};

TripListTripCompanion.propTypes = {
  trip: ridesTypes.tripType.isRequired,
};

const TripListTripCompanionRelation = ({ trip, ...props }) => {
  const relationship = R.pathOr('--', ['companion', 'relationship'], trip);
  const isPrimaryContact = R.pathOr('--', ['companion', 'primaryContact'], trip);

  return (
    <Box {...props}>
      RELATION
      <Box width={1 / 2}>
        <Detail text={relationship} my={2} textColor="darkGray" />
      </Box>
      <Box width={1 / 2}>
        {isPrimaryContact && <Detail text="Primary" my={2} color="gray" />}
      </Box>
    </Box>
  );
};

TripListTripCompanionRelation.propTypes = {
  trip: ridesTypes.tripType.isRequired,
};

const TripListTripPickupUrbanicity = ({ trip, ...props }) => {
  const pickupUrbanicity = R.pathOr('--', ['pickupUrbanicity'], trip);
  return (
    <Box {...props}>
      PICKUP URBANICITY
      <Detail
        /* icon="train" */
        text={pickupUrbanicity}
        my={2}
        textColor="darkGray"
        capitalize
      />
    </Box>
  );
};

TripListTripPickupUrbanicity.propTypes = {
  trip: ridesTypes.tripType.isRequired,
};

const TookanTaskLink = ({ jobId, trackingLink, color, ...props }) => (
  <Box>
    <Pill color={color}>{jobId}</Pill>
  </Box>
);
// return (
//   <Box>
//     <Link href={trackingLink} target="_blank">
//       {jobId}
//     </Link>
//   </Box>
// );
TookanTaskLink.propTypes = {
  jobId: PropTypes.number,
  trackingLink: PropTypes.string,
};

const getTripTookanTaskData = trip => {
  const tookanTask = R.path(['tookanTask'], trip);
  if (!tookanTask) return null;

  const deliveryJobId = R.path(['deliveryJobId'], tookanTask);
  const deliveryTrackingLink = R.path(['deliveryTrackingLink'], tookanTask);
  const pickupJobId = R.path(['pickupJobId'], tookanTask);
  const pickupTrackingLink = R.path(['pickupTrackingLink'], tookanTask);

  return {
    deliveryJobId,
    deliveryTrackingLink,
    pickupJobId,
    pickupTrackingLink,
  };
};

export const TripListTripTookanTasks = ({ trip, ...props }) => {
  const taskData = getTripTookanTaskData(trip);
  if (!taskData) return null;

  const {
    deliveryJobId,
    deliveryTrackingLink,
    pickupJobId,
    pickupTrackingLink,
  } = taskData;

  return (
    <Box {...props} zIndex={10} fontSize={12}>
      {'CareCar Tasks: '}
      {deliveryJobId && (
        <TookanTaskLink jobId={deliveryJobId} trackingLink={deliveryTrackingLink} />
      )}
      {pickupJobId && (
        <TookanTaskLink jobId={pickupJobId} trackingLink={pickupTrackingLink} />
      )}
    </Box>
  );
};

TripListTripTookanTasks.propTypes = {
  trip: ridesTypes.tripType.isRequired,
};

const TripListTrip = React.memo(
  ({ trip, isExpanded, isConnectedTrip, hideForHumana, showStatusTransitionUI }) => {
    const member = R.pathOr({}, ['member'], trip);
    const purpose = R.path(['purpose', 'name'], trip);

    const appointmentTime = R.path(['appointmentTime'], trip);

    const pickupColWidth = 208;
    const dropoffColWidth = 132;
    const pickupTime = R.path(['pickupTime'], trip);
    const driverArrivedAt = R.path(['driverArrivedAt'], trip);
    const dropoffTime = R.path(['dropoffTime'], trip);
    const completedAt = R.path(['completedAt'], trip);

    let pickupAt;
    let pickupAtIsActual;
    if (isValidIsoDateTime(pickupTime)) {
      pickupAt = pickupTime;
      pickupAtIsActual = false;
    }
    if (isValidIsoDateTime(driverArrivedAt)) {
      pickupAt = driverArrivedAt;
      pickupAtIsActual = true;
    }

    let dropoffAt;
    let dropoffAtIsActual;
    if (isValidIsoDateTime(dropoffTime)) {
      dropoffAt = dropoffTime;
      dropoffAtIsActual = false;
    }
    if (isValidIsoDateTime(completedAt)) {
      dropoffAt = completedAt;
      dropoffAtIsActual = true;
    }

    const tookanMapUrl = getTripTookanMapUrl(trip);
    const tripMapUrl = R.prop('mapUrl', trip);
    const mapUrl = tookanMapUrl || tripMapUrl;
    const safeMapUrl = `${isStr(mapUrl) ? mapUrl : ''}`.trim();
    const linkToMember = useTenantUrl(`/member/${member.id}`);
    const showWillCall = !!trip?.isWillCall;

    return (
      <React.Fragment>
        <TripListRow
          colTripId={
            <>
              <TripGroupId trip={trip} isLink={!hideForHumana} />
              {showWillCall && <WillCallBadge />}
            </>
          }
          colMember={
            <Flex flexDirection="column" alignItems="flex-start">
              <Link href={linkToMember}>
                <Detail
                  text={`${member.firstName} ${member.lastName}`.trim()}
                  fontSize={14}
                  lineHeight="20px"
                  bold
                  black
                  capitalize
                />
              </Link>
              <Flex paddingTop={1} />
              <PhoneNumber
                phoneNumber={member.phoneNumber}
                render={({ phoneNumber, iconName }) => (
                  <Detail
                    icon={iconName}
                    text={phoneNumber}
                    mt={2}
                    postfix={<Text title="Main Phone Number">main</Text>}
                  />
                )}
              />
              <PhoneNumber
                phoneNumber={member.phoneNumberAlternate}
                render={({ phoneNumber, iconName }) => (
                  <Detail
                    iconCss={{ visibility: 'hidden' }}
                    icon={iconName}
                    text={phoneNumber}
                    my={2}
                    postfix={<Text title="Alternate Phone Number">alt</Text>}
                  />
                )}
              />
            </Flex>
          }
          colTripLeg={
            <Flex alignItems="flex-start">
              <DateTime
                datetime={pickupTime}
                format="MMM D, ddd"
                render={datetime => (
                  <Detail
                    text={datetime}
                    height={31}
                    lineHeight="16px"
                    width={80}
                    mr={5}
                    nowrap
                  />
                )}
              />
              {isValidIsoDateTime(appointmentTime) && (
                <DateTime
                  datetime={appointmentTime}
                  format="hh:mm A"
                  render={time => (
                    <Detail
                      text={`Appointment Time ${time}`}
                      lineHeight="16px"
                      height={31}
                      nowrap
                    />
                  )}
                />
              )}
            </Flex>
          }
          colTripType={<Detail text={R.path(['type', 'name'], trip)} />}
          colTags={R.path(['member', 'notes'], trip) ? <Detail text="Notes" /> : ' '}
          colStatus={
            <>
              <TripStatusDropdown tripId={trip.id} />
              <TripRequestPickupButton tripId={trip.id} />
            </>
          }
          colMenu={<TripListTripMenu memberId={member.id} tripId={trip.id} />}
        />

        {/* ROW 2 */}
        <TripListRow
          colMember={
            <Flex flexDirection="column">
              <Detail icon="language" text={member.languagesSpoken} mt={2} capitalize />
              {isExpanded && (
                <React.Fragment>
                  <Detail icon="type-of-visit" text={purpose} mb={2} />
                </React.Fragment>
              )}
            </Flex>
          }
          colTripLeg={
            <Flex alignItems="flex-start">
              <Flex alignItems="flex-start" flexWrap="wrap">
                <Flex width={pickupColWidth} alignItems="flex-start" flexWrap="wrap">
                  <Detail text="Pickup" black bold mr={2} />
                  <Detail text={pickupAtIsActual ? 'Actual' : 'Scheduled'} />
                </Flex>

                {isValidIsoDateTime(dropoffAt) && (
                  <Flex width={dropoffColWidth} alignItems="flex-start" flexWrap="wrap">
                    <Detail text="Dropoff" black bold mr={2} />
                    <Detail text={dropoffAtIsActual ? 'Actual' : 'Scheduled'} />
                  </Flex>
                )}

                <TripLegPickupDropoffGap
                  width={pickupColWidth}
                  color="mediumGray"
                  alignItems="flex-start"
                  flexWrap="wrap"
                />

                <Flex width={pickupColWidth} justifyContent="stretch">
                  <DateTime
                    datetime={pickupAt}
                    format="h:mm A"
                    render={datetime => (
                      <Detail
                        text={datetime}
                        lineHeight="16px"
                        fontSize={14}
                        mr={2}
                        black
                        bold
                      />
                    )}
                  />
                  {!!trip.estimate && (
                    <Detail
                      text={`${metersToMiles(trip.distance)} Miles`}
                      justifyContent="center"
                      flex="1 1 auto"
                    />
                  )}
                </Flex>

                <Flex width={dropoffColWidth} alignItems="flex-start" flexWrap="wrap">
                  {isValidIsoDateTime(dropoffAt) && (
                    <DateTime
                      datetime={dropoffAt}
                      format="h:mm A"
                      render={datetime => (
                        <Detail
                          text={datetime}
                          lineHeight="16px"
                          fontSize={14}
                          mr={2}
                          flex="1 1 auto"
                          black
                          bold
                        />
                      )}
                    />
                  )}
                </Flex>
                {isExpanded && (
                  <React.Fragment>
                    <TripLegPickupDropoffAddress
                      width={pickupColWidth}
                      location={trip.pickup}
                    />

                    <TripLegPickupDropoffAddress
                      width={dropoffColWidth}
                      location={trip.dropoff}
                    />
                  </React.Fragment>
                )}
              </Flex>
            </Flex>
          }
          colStatus={<TripListTripTookanTasks trip={trip} />}
        />

        {isExpanded && (
          <TripListRow
            mt={8}
            colMember={<TripListTripDriver trip={trip} />}
            colTripLeg={
              <Flex>
                <TripListTripVendor trip={trip} width={1 / 2} />
                <TripListTripVehicleDescription trip={trip} width={1 / 2} />
              </Flex>
            }
            colStatus={
              <Button
                as={Link}
                variant="outline"
                href={safeMapUrl}
                target="_blank"
                disabled={!safeMapUrl}
              >
                TRIP MAP LINK
              </Button>
            }
          />
        )}

        {isExpanded && (
          <TripListRow
            mt={8}
            colMember={<TripListTripCompanion trip={trip} />}
            colTripLeg={
              <Flex>
                <TripListTripCompanionRelation trip={trip} width={1 / 2} />
                <TripListTripPickupUrbanicity trip={trip} width={1 / 2} />
              </Flex>
            }
          />
        )}

        {isExpanded && (
          <TripListRow
            mt={8}
            renderColumns={({ columns }) => (
              <React.Fragment>
                {columns.tripId}
                <TripListColumn flex="0 0 auto" width={420} mx={2}>
                  <Detail icon="notes" text="NOTES" fontSize={14} />
                  <Detail text={trip.notes} my={2} textColor="black" />
                </TripListColumn>
                <TripListColumn flex="0 0 auto" width={420} mx={2}>
                  <Detail icon="notes" text="MEMBER NOTES" fontSize={14} />
                  <Detail text={trip.member.notes} my={2} textColor="black" />
                </TripListColumn>
              </React.Fragment>
            )}
          />
        )}

        {showStatusTransitionUI && isExpanded && (
          <TripListRow
            mt={8}
            renderColumns={({ columns }) => (
              <React.Fragment>
                {columns.tripId}
                <Box css={{ flex: '1 0' }}>
                  <Detail text="TRANSITIONS" fontSize={14} />
                  <TripListColumn flex="1 1 auto" mt={2}>
                    <TripTransitionsTable tripId={trip.id} />
                  </TripListColumn>
                </Box>
              </React.Fragment>
            )}
          />
        )}
      </React.Fragment>
    );
  },
);

TripListTrip.propTypes = {
  trip: ridesTypes.tripType.isRequired,
  isExpanded: PropTypes.bool,
  showStatusTransitionUI: PropTypes.bool.isRequired,
};

TripListTrip.defaultProps = {
  isExpanded: false,
};

export default TripListTrip;
