/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'ChevronDownIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        stroke-width=".5"
        d="M7.48 11.52a.735.735 0 001.04 0l6.264-6.262a.736.736 0 10-1.042-1.042L8 9.958 2.258 4.216a.736.736 0 10-1.042 1.042L7.48 11.52z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
