import React from 'react';
import PropTypes from 'prop-types';

import { tripIdType } from 'rides/types';
import { Column, Row, TripWizardSteps } from 'rides/components';
import { Modal } from 'rides/containers';

class TripWizardModal extends React.Component {
  onClose = () => {
    this.props.tripWizardClose();
    return false;
  };

  render() {
    const {
      currentStep,
      formDisabled,
      stepErrors,
      memberId,
      member,
      tripWizardGoToNextStep,
      tripWizardGoToPrevStep,
      tripId,
      tripGroupId,
      values,
      ...props
    } = this.props;
    // TODO replace this with FP badassness
    let tripWizardMode = 'CREATE';
    if (currentStep === 'cancel-trip') {
      tripWizardMode = 'CANCEL_TRIP';
    } else if (tripId) {
      tripWizardMode = 'EDIT';
    } else if (tripGroupId) {
      tripWizardMode = 'ADD_LEG';
    }

    const stepProps = {
      currentStep,
      formDisabled,
      stepErrors,
      goToNextStep: tripWizardGoToNextStep,
      goToPrevStep: tripWizardGoToPrevStep,
      memberId,
      member,
      tripId,
      tripGroupId,
      createMode: tripWizardMode === 'CREATE',
      editMode: tripWizardMode === 'EDIT',
      addLegMode: tripWizardMode === 'ADD_LEG',
      cancelTripMode: tripWizardMode === 'CANCEL_TRIP',
      values,
    };

    let modalTitle = 'New Trip Information';
    if (tripWizardMode === 'EDIT') {
      modalTitle = 'Edit Trip';
    } else if (tripWizardMode === 'ADD_LEG') {
      modalTitle = 'Add Trip Leg';
    } else if (tripWizardMode === 'CANCEL_TRIP') {
      modalTitle = 'Cancel Trip';
    }

    return (
      <Modal
        {...props}
        title={modalTitle}
        name="trip-wizard"
        onClose={this.onClose}
        closeable
      >
        <Row>
          <Column xs={12}>{<TripWizardSteps {...stepProps} />}</Column>
        </Row>
      </Modal>
    );
  }
}

TripWizardModal.propTypes = {
  currentStep: PropTypes.string,
  formDisabled: PropTypes.bool,
  stepErrors: PropTypes.object.isRequired,
  member: PropTypes.object,
  memberId: PropTypes.string,
  tripWizardClose: PropTypes.func.isRequired,
  tripWizardGoToNextStep: PropTypes.func.isRequired,
  tripWizardGoToPrevStep: PropTypes.func.isRequired,
  tripId: tripIdType,
  tripGroupId: tripIdType,
  values: PropTypes.object.isRequired,
};

export default TripWizardModal;
