import * as Sentry from '@sentry/react';
import { apiRootPath } from 'rides/config';

export const monitorSentryStructuredMetadata = response => {
  const transaction = Sentry.getCurrentHub()
    .getScope()
    .getTransaction();

  if (transaction) {
    const {
      data, // - Object - this is probably the thing you're after.
      status, // - Number - the HTTP response code
      // headers, // - Object - the HTTP response headers
      config, // - Object - the `axios` config object used to make the request
      duration, // - Number - the number of milliseconds it took to run this request
    } = response;

    const url = response?.config?.url;
    const method = response?.config?.method;
    const [pathLabel, pathParams] = extractAllPatterns(url);

    const spanDescEndpoint = `${apiRootPath}${pathLabel}`;
    const spanTagBaseUrl = `${config.baseURL}`.replace(apiRootPath, '');
    const spanTagEndpoint = `${apiRootPath}${url}`;

    const span = transaction.startChild({
      op: 'api.call',
      description: `${method} ${spanDescEndpoint}`,
    });

    try {
      span.setTag('baseUrl', spanTagBaseUrl);
      span.setTag('endpoint', spanTagEndpoint);
      const dataParams = config?.params;
      const dataPathParams = pathParams;
      const dataData = data && JSON.parse(data);
      dataParams && span.setData('params', dataParams);
      dataPathParams && span.setData('pathParams', dataPathParams);
      dataData && span.setData('data', dataParams);
      duration && span.setData('duration', duration);
      status && span.setData(status, status);
    } catch (error) {
      console.log('monitorSentryStructuredMetadata::error', error);
    } finally {
      span.finish();
    }
  }
};

const extractPatterns = {
  memberId: [/member\/([a-f0-9-]+)/, 'member/:member_id'],
  planId: [/plan\/(\d+)/, 'plan/:plan_id'],
  statusTransitionId: [
    /status_transition\/(\d+)/,
    'status_transition/:status_transition_id',
  ],
  tripGroupIdRoot: [/trip_group\/(\d+)/, 'trip_group/:root_id'],
  tripGroupIdV2: [/trip_group_v2\/(\d+)/, 'trip_group_v2/:trip_group_id'],
  tripId: [/trip\/(\d+)/, 'trip/:trip_id'],
  tripTypeId: [/trip\/type\/(\d+)/, 'trip_type/:trip_type_id'],
  uberRequestSyncApiKey: [/uber\/request_sync\/(\d+)/, 'uber/request_sync/:api_key'],
  userId: [/user\/(\d+)/, 'user/:user_id'],
  vendorDriverId: [/vendor_driver\/(\d+)/, 'vendor_driver/:vendor_driver_id'],
  vendorId: [/vendor\/(\d+)/, 'vendor/:vendor_id'],
};

function extractAllPatterns(str) {
  let mutatedStr = str;
  const pathParams = {};
  for (const [pathParamKey, [regex, pathPartLabel]] of Object.entries(extractPatterns)) {
    const execOut = regex.exec(str);
    if (execOut) {
      const findStr = execOut[0];
      const pathParamValue = execOut[1];

      if (pathParamValue) {
        pathParams[`${pathParamKey}`] = pathParamValue;
        mutatedStr = mutatedStr.replace(findStr, pathPartLabel);
      }
    }
  }

  return [mutatedStr, pathParams];
}
