/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'InboxNumberXlIcon',
  path: (
    <g fill="currentColor">
      <g clip-path="url(#clip0)">
        <path
          fill-rule="evenodd"
          d="M17.625 18.5a1.877 1.877 0 01-1.875-1.875V13.25c0-2.73-1.68-5.213-4.202-6.244A2.607 2.607 0 009 5a2.644 2.644 0 00-2.55 2.006 6.777 6.777 0 00-4.2 6.244v3.375A1.877 1.877 0 01.375 18.5a.375.375 0 000 .75h17.25a.375.375 0 100-.75zM11.25 20h-4.5a.375.375 0 00-.375.375A2.628 2.628 0 009 23a2.628 2.628 0 002.625-2.625.375.375 0 00-.375-.375z"
          clip-rule="evenodd"
        />
        <path
          fill-rule="evenodd"
          d="M17.625 18.5a1.877 1.877 0 01-1.875-1.875V13.25c0-2.73-1.68-5.213-4.202-6.244A2.607 2.607 0 009 5a2.644 2.644 0 00-2.55 2.006 6.777 6.777 0 00-4.2 6.244v3.375A1.877 1.877 0 01.375 18.5a.375.375 0 000 .75h17.25a.375.375 0 100-.75zM11.25 20h-4.5a.375.375 0 00-.375.375A2.628 2.628 0 009 23a2.628 2.628 0 002.625-2.625.375.375 0 00-.375-.375z"
          clip-rule="evenodd"
        />
        <rect
          width="13.214"
          height="13.214"
          x="10.143"
          y="-.857"
          stroke-width="1.714"
          rx="6.607"
        />
        <path d="M18.33 4.12c0 .313-.095.578-.285.797-.189.219-.454.37-.796.451v.02c.403.05.709.174.916.37.207.194.311.456.311.786 0 .48-.174.856-.523 1.124-.348.267-.846.4-1.494.4-.542 0-1.023-.09-1.442-.27V6.9c.194.098.407.178.64.24.232.061.462.092.69.092.348 0 .606-.06.772-.178.166-.118.25-.309.25-.57 0-.235-.096-.402-.288-.5-.19-.098-.496-.147-.915-.147h-.38v-.81h.386c.388 0 .67-.05.848-.15.18-.103.27-.277.27-.523 0-.378-.237-.567-.71-.567-.165 0-.332.027-.503.082a2.46 2.46 0 00-.564.283l-.49-.728c.457-.328 1-.492 1.631-.492.518 0 .925.105 1.224.315.3.21.451.5.451.875z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h23v23H0z" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 23 23',
});
