/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'TasksIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M12.106 3.333v-2H10.05A1.698 1.698 0 008.401 0C7.604 0 6.906.575 6.75 1.333H4.695v2h7.41z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M14.463 1.333H12.78V14H4.02V1.333H2.337A.335.335 0 002 1.667v14c0 .184.15.333.337.333h12.126c.186 0 .337-.15.337-.333v-14a.335.335 0 00-.337-.334z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M11.094 5.333H7.052a.335.335 0 00-.337.334c0 .184.15.333.337.333h4.042c.186 0 .337-.15.337-.333a.335.335 0 00-.337-.334zM11.094 7.333H7.052a.335.335 0 00-.337.334c0 .184.15.333.337.333h4.042c.186 0 .337-.15.337-.333a.335.335 0 00-.337-.334zM11.094 9.333H7.052a.335.335 0 00-.337.334c0 .184.15.333.337.333h4.042c.186 0 .337-.15.337-.333a.335.335 0 00-.337-.334zM11.094 11.333H7.052a.335.335 0 00-.337.334c0 .184.15.333.337.333h4.042c.186 0 .337-.15.337-.333a.335.335 0 00-.337-.334zM5.705 5.333h-.337a.335.335 0 00-.337.334c0 .184.151.333.337.333h.337c.186 0 .337-.15.337-.333a.335.335 0 00-.337-.334zM5.705 7.333h-.337a.335.335 0 00-.337.334c0 .184.151.333.337.333h.337c.186 0 .337-.15.337-.333a.335.335 0 00-.337-.334zM5.705 9.333h-.337a.335.335 0 00-.337.334c0 .184.151.333.337.333h.337c.186 0 .337-.15.337-.333a.335.335 0 00-.337-.334zM5.705 11.333h-.337a.335.335 0 00-.337.334c0 .184.151.333.337.333h.337c.186 0 .337-.15.337-.333a.335.335 0 00-.337-.334z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
