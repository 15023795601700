import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
// import deepEqual from 'fast-deep-equal';
import { Pane, SelectMenu, Badge, Button, Icon } from 'evergreen-ui';
import { Box, Flex, Text as NuiText } from 'rides/nui';
import * as ridesTypes from 'rides/types';

const userToOption = R.map(user => ({ label: user.name, value: user.id }));
const tripTypeToOption = R.map(tripType => ({
  label: tripType.name,
  value: tripType.id,
}));
const tripTypeListToOptions = R.pipe(R.defaultTo([]), tripTypeToOption);

const tripStatusToOption = R.map(tripStatus => ({
  label: tripStatus.name,
  value: tripStatus.id,
}));
const tripStatusListToOptions = R.pipe(R.defaultTo([]), tripStatusToOption);

export const TripAssignFilterTag = ({ pending, content, icon, ...props }) => {
  const postIcon = pending ? 'dot' : 'cross';
  return (
    <Badge
      display="inline-flex"
      alignItems="center"
      textTransform="none"
      size={400}
      fontWeight={400}
      paddingY={10}
      margin={4}
      isInteractive
      {...props}
    >
      {icon && <Icon icon={icon} size={11} marginRight={4} />} {content}{' '}
      <Icon icon={postIcon} color="muted" size={10} marginLeft={4} />
    </Badge>
  );
};

TripAssignFilterTag.propTypes = {
  icon: PropTypes.string,
  content: PropTypes.node,
  pending: PropTypes.bool,
};

const TripFilterTagGroup = ({ children, title, hasTags = false }) => {
  const bg = hasTags ? 'white' : undefined;

  return (
    hasTags && (
      <>
        <Flex
          bg={bg}
          style={{
            borderTopLeftRadius: 3,
            borderBottomLeftRadius: 3,
          }}
        >
          <Flex
            style={{
              height: 28,
              alignItems: 'center',
            }}
          >
            {hasTags && (
              <NuiText lineHeight="1" fontSize={12} color="gray" pl={2}>
                {title}:
              </NuiText>
            )}
          </Flex>
        </Flex>
        <Box
          bg={bg}
          style={{
            borderTopRightRadius: 3,
            borderBottomRightRadius: 3,
          }}
        >
          {children}
        </Box>
      </>
    )
  );
};
TripFilterTagGroup.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  hasTags: PropTypes.bool,
};

class TripAssignFilter extends React.PureComponent {
  componentDidMount() {
    this.resetSelected();
    this.resetParamType();
  }

  // -- vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv --
  // -- HACKY TRIP STATUS FILTER METHODS --
  // -- vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv --

  setParamStatus = value => {
    this.props.setParamStatus(value);
  };

  resetParamStatus = () => {
    const { status } = this.props;
    const values = this.getParamStatusValues();
    const selected = status || values;
    this.setParamStatus(selected);
  };

  addParamStatus = key => {
    const selected = this.props.status;
    this.setParamStatus([...selected, key]);
  };

  removeParamStatus = key => {
    const selected = R.defaultTo([], this.props.status);
    const newSelected = R.without([key], selected);
    this.setParamStatus(newSelected);
  };

  getParamStatusOptions() {
    const { tripStatusList } = this.props;
    return tripStatusListToOptions(tripStatusList);
  }

  getParamStatusActiveFilterValues = () => {
    const { status } = this.props;
    return R.defaultTo([], status);
  };

  getParamStatusValues() {
    const { searchOptions } = this.props;
    const tripStatus = R.pipe(
      R.path(['status']),
      R.defaultTo([]),
      // R.map(statusId => ~~statusId),
    )(searchOptions);

    return tripStatus;
  }

  getParamStatusPendingValues = () => {
    const activeValues = this.getParamStatusActiveFilterValues();
    const selectedValues = this.getParamStatusValues();
    return R.without(selectedValues, activeValues);
  };

  getParamStatusUnselectedValues = () => {
    const activeValues = this.getParamStatusActiveFilterValues();
    const selectedValues = this.getParamStatusValues();
    return R.without(activeValues, selectedValues);
  };

  getParamStatusOptionForValue = value => {
    const options = this.getParamStatusOptions();
    return R.find(item => item.value === value)(options);
  };

  getParamStatusLabelForValue = value => {
    const option = this.getParamStatusOptionForValue(value);
    return `${R.path(['label'], option)}`;
  };

  // -- ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ --
  // -- HACKY TRIP STATUS FILTER METHODS --
  // -- ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ --

  // -- vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv --
  // -- HACKY TRIP TYPE FILTER METHODS --
  // -- vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv --

  setParamType = value => {
    this.props.setParamType(value);
  };

  resetParamType = () => {
    const { type } = this.props;
    const values = this.getParamTypeValues();
    const selected = type || values;
    this.setParamType(selected);
  };

  addParamType = key => {
    const selected = this.props.type;
    this.setParamType([...selected, key]);
  };

  removeParamType = key => {
    const selected = R.defaultTo([], this.props.type);
    const newSelected = R.without([key], selected);
    this.setParamType(newSelected);
  };

  getParamTypeOptions() {
    const { tripTypeList } = this.props;
    return tripTypeListToOptions(tripTypeList);
  }

  getParamTypeActiveFilterValues = () => {
    const { type } = this.props;
    return R.defaultTo([], type);
  };

  getParamTypeValues() {
    const { searchOptions } = this.props;
    const tripType = R.pipe(
      R.path(['type']),
      R.defaultTo([]),
      R.map(typeId => ~~typeId),
    )(searchOptions);

    return tripType;
  }

  getParamTypePendingValues = () => {
    const activeValues = this.getParamTypeActiveFilterValues();
    const selectedValues = this.getParamTypeValues();
    return R.without(selectedValues, activeValues);
  };

  getParamTypeUnselectedValues = () => {
    const activeValues = this.getParamTypeActiveFilterValues();
    const selectedValues = this.getParamTypeValues();
    return R.without(activeValues, selectedValues);
  };

  getParamTypeOptionForValue = value => {
    const options = this.getParamTypeOptions();
    return R.find(item => item.value === value)(options);
  };

  getParamTypeLabelForValue = value => {
    const option = this.getParamTypeOptionForValue(value);
    return `${R.path(['label'], option)}`;
  };

  // -- ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ --
  // -- HACKY TRIP TYPE FILTER METHODS --
  // -- ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ --

  // -- vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv --
  // -- HACKY TRIP PICKUP URBANICITY FILTER METHODS --
  // -- vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv --

  setParamPickupUrbanicity = value => {
    this.props.setParamPickupUrbanicity(value);
  };

  resetParamPickupUrbanicity = () => {
    const { pickupUrbanicity } = this.props;
    const values = this.getParamPickupUrbanicityValues();
    const selected = pickupUrbanicity || values;
    this.setParamPickupUrbanicity(selected);
  };

  addParamPickupUrbanicity = key => {
    const selected = this.props.pickupUrbanicity;
    this.setParamPickupUrbanicity([...selected, key]);
  };

  removeParamPickupUrbanicity = key => {
    const selected = R.defaultTo([], this.props.pickupUrbanicity);
    const newSelected = R.without([key], selected);
    this.setParamPickupUrbanicity(newSelected);
  };

  getParamPickupUrbanicityOptions() {
    const { tripPickupUrbanicityList } = this.props;
    return tripTypeListToOptions(tripPickupUrbanicityList);
  }

  getParamPickupUrbanicityActiveFilterValues = () => {
    const { pickupUrbanicity } = this.props;
    return R.defaultTo([], pickupUrbanicity);
  };

  getParamPickupUrbanicityValues() {
    const { searchOptions } = this.props;
    const tripPickupUrbanicity = R.pipe(
      R.path(['pickup_urbanicity']),
      R.defaultTo([]),
    )(searchOptions);

    return tripPickupUrbanicity;
  }

  getParamPickupUrbanicityPendingValues = () => {
    const activeValues = this.getParamPickupUrbanicityActiveFilterValues();
    const selectedValues = this.getParamPickupUrbanicityValues();
    return R.without(selectedValues, activeValues);
  };

  getParamPickupUrbanicityUnselectedValues = () => {
    const activeValues = this.getParamPickupUrbanicityActiveFilterValues();
    const selectedValues = this.getParamPickupUrbanicityValues();
    return R.without(activeValues, selectedValues);
  };

  getParamPickupUrbanicityOptionForValue = value => {
    const options = this.getParamPickupUrbanicityOptions();
    return R.find(item => item.value === value)(options);
  };

  getParamPickupUrbanicityLabelForValue = value => {
    const option = this.getParamPickupUrbanicityOptionForValue(value);
    return `${R.path(['label'], option)}`;
  };

  // -- ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ --
  // -- HACKY TRIP PICKUP URBANICITY FILTER METHODS --
  // -- ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ --

  setSelected = value => {
    this.props.setSelected(value);
  };

  resetSelected = () => {
    const { assignedTo } = this.props;
    const selected = assignedTo || this.getSelectedValues();
    this.setSelected(selected);
  };

  addSelected = key => {
    const selected = this.props.assignedTo;
    this.setSelected([...selected, key]);
  };

  removeSelected = key => {
    const selected = R.defaultTo([], this.props.assignedTo);
    const newSelected = R.without([key], selected);
    this.setSelected(newSelected);
  };

  getOptions() {
    const { currentUserId, assigneeList } = this.props;

    const agentOptions = R.pipe(
      R.defaultTo([]),
      R.reject(R.propEq('id', currentUserId)),
      userToOption,
    )(assigneeList);

    return [
      { label: 'Unassigned Trips', value: 'include_unassigned' },
      { label: 'My Trips', value: currentUserId },
      ...agentOptions,
    ];
  }

  getActiveFilterValues = () => {
    const { assignedTo } = this.props;
    return R.defaultTo([], assignedTo);
  };

  getSelectedValues() {
    const { searchOptions } = this.props;
    const includeUnassigned = R.pipe(
      R.path(['include_unassigned']),
      R.ifElse(R.equals(true), R.always(['include_unassigned']), R.always([])),
    )(searchOptions);

    const assignedTo = R.pipe(R.path(['assigned_to']), R.defaultTo([]))(searchOptions);

    return R.uniq([...includeUnassigned, ...assignedTo]);
  }

  getPendingValues = () => {
    const activeValues = this.getActiveFilterValues();
    const selectedValues = this.getSelectedValues();
    return R.without(selectedValues, activeValues);
  };

  getUnselectedValues = () => {
    const activeValues = this.getActiveFilterValues();
    const selectedValues = this.getSelectedValues();
    return R.without(activeValues, selectedValues);
  };

  getOptionForValue = value => {
    const options = this.getOptions();
    return R.find(item => item.value === value)(options);
  };

  getLabelForValue = value => {
    const option = this.getOptionForValue(value);
    return R.path(['label'], option);
  };

  renderTag = (selected, unselected, isPending = false) => value => {
    const { currentUserId } = this.props;

    const isUnselected = R.contains(value, unselected);
    const label = this.getLabelForValue(value);
    const onClickRemove = () => {
      const assigned_to = R.without([value], selected);
      if (!isPending) this.props.onSearch({ assigned_to });
      this.removeSelected(value);
    };

    let tagProps = {};
    if (value === 'include_unassigned') {
      tagProps.color = 'blue';
      tagProps.content = 'Unassigned';
      tagProps.icon = 'inbox';
    }

    if (value === currentUserId) {
      tagProps.color = 'purple';
    }

    if (isPending) {
      tagProps.color = 'yellow';
      tagProps.opacity = 0.3;
    } else {
      if (isUnselected) {
        tagProps.opacity = 0.5;
        tagProps.color = 'red';
        tagProps.textDecoration = 'line-through';
      }
    }

    return (
      <TripAssignFilterTag
        key={value}
        content={label}
        color={'neutral'}
        icon={'user'}
        onClick={onClickRemove}
        pending={isPending}
        {...tagProps}
      />
    );
  };

  renderTripTypeTag = (selected, unselected, isPending = false) => value => {
    const isUnselected = R.contains(value, unselected);
    const label = this.getParamTypeLabelForValue(value);
    const onClickRemove = () => {
      const type = R.without([value], selected);
      if (!isPending) this.props.onSearch({ type });
      this.removeParamType(value);
    };

    let tagProps = {};
    if (isPending) {
      tagProps.color = 'yellow';
      tagProps.opacity = 0.3;
    } else {
      if (isUnselected) {
        tagProps.opacity = 0.5;
        tagProps.color = 'red';
        tagProps.textDecoration = 'line-through';
      }
    }

    return (
      <TripAssignFilterTag
        key={value}
        content={label}
        color="teal"
        icon="flag"
        onClick={onClickRemove}
        pending={isPending}
        {...tagProps}
      />
    );
  };

  renderTripStatusTag = (selected, unselected, isPending = false) => value => {
    const isUnselected = R.contains(value, unselected);
    const label = this.getParamStatusLabelForValue(value);
    const onClickRemove = () => {
      const status = R.without([value], selected);
      if (!isPending) this.props.onSearch({ status });
      this.removeParamStatus(value);
    };

    let tagProps = {};
    if (isPending) {
      tagProps.color = 'yellow';
      tagProps.opacity = 0.3;
    } else {
      if (isUnselected) {
        tagProps.opacity = 0.5;
        tagProps.color = 'red';
        tagProps.textDecoration = 'line-through';
      }
    }

    return (
      <TripAssignFilterTag
        key={value}
        content={label}
        color="teal"
        icon="flag"
        onClick={onClickRemove}
        pending={isPending}
        {...tagProps}
      />
    );
  };

  renderTripPickupUrbanicityTag = (selected, unselected, isPending = false) => value => {
    const isUnselected = R.contains(value, unselected);
    const label = this.getParamPickupUrbanicityLabelForValue(value);
    const onClickRemove = () => {
      const pickup_urbanicity = R.without([value], selected);
      if (!isPending) this.props.onSearch({ pickup_urbanicity });
      this.removeParamPickupUrbanicity(value);
    };

    let tagProps = {};
    if (isPending) {
      tagProps.color = 'yellow';
      tagProps.opacity = 0.3;
    } else {
      if (isUnselected) {
        tagProps.opacity = 0.5;
        tagProps.color = 'red';
        tagProps.textDecoration = 'line-through';
      }
    }

    return (
      <TripAssignFilterTag
        key={value}
        content={label}
        color="green"
        icon="train"
        onClick={onClickRemove}
        pending={isPending}
        {...tagProps}
      />
    );
  };

  render() {
    const {
      renderTags,
      currentUserId,
      assignedTo,
      status,
      type,
      pickupUrbanicity,
      isDateRangeChanged,
    } = this.props;
    const tripStatus = status;
    const tripType = type;
    const tripPickupUrbanicity = pickupUrbanicity;

    const options = this.getOptions();
    const tripTypeOptions = this.getParamTypeOptions();
    const tripPickupUrbanicityOptions = this.getParamPickupUrbanicityOptions();
    const tripStatusOptions = this.getParamStatusOptions();

    const selectedTags = this.getSelectedValues();
    const pendingTags = this.getPendingValues();
    const unselectedTags = this.getUnselectedValues();
    const hasPendingFilters = pendingTags.length > 0;
    const hasUnselectedFilters = unselectedTags.length > 0;
    const hasFilterChanges =
      hasPendingFilters || hasUnselectedFilters || isDateRangeChanged;

    // trip type filter
    const selectedParamTypeTags = this.getParamTypeValues();
    const pendingParamTypeTags = this.getParamTypePendingValues();
    const unselectedParamTypeTags = this.getParamTypeUnselectedValues();
    const hasParamTypePendingFilters = pendingParamTypeTags.length > 0;
    const hasParamTypeUnselectedFilters = unselectedParamTypeTags.length > 0;
    const hasParamTypeFilterChanges =
      hasParamTypePendingFilters || hasParamTypeUnselectedFilters;

    // trip pickupUrbanicity filter
    const selectedParamPickupUrbanicityTags = this.getParamPickupUrbanicityValues();
    const pendingParamPickupUrbanicityTags = this.getParamPickupUrbanicityPendingValues();
    const unselectedParamPickupUrbanicityTags = this.getParamPickupUrbanicityUnselectedValues();
    const hasParamPickupUrbanicityPendingFilters =
      pendingParamPickupUrbanicityTags.length > 0;
    const hasParamPickupUrbanicityUnselectedFilters =
      unselectedParamPickupUrbanicityTags.length > 0;
    const hasParamPickupUrbanicityFilterChanges =
      hasParamPickupUrbanicityPendingFilters || hasParamPickupUrbanicityUnselectedFilters;

    // trip status filter
    const selectedParamStatusTags = this.getParamStatusValues();
    const pendingParamStatusTags = this.getParamStatusPendingValues();
    const unselectedParamStatusTags = this.getParamStatusUnselectedValues();
    const hasParamStatusPendingFilters = pendingParamStatusTags.length > 0;
    const hasParamStatusUnselectedFilters = unselectedParamStatusTags.length > 0;
    const hasParamStatusFilterChanges =
      hasParamStatusPendingFilters || hasParamStatusUnselectedFilters;

    if (renderTags) {
      const sortTags = R.sortWith([
        R.descend(R.equals('include_unassigned')),
        R.descend(R.equals(currentUserId)),
      ]);

      const sortedSelected = sortTags(selectedTags);
      const sortedPending = sortTags(pendingTags);
      const hasAssigneeTags = sortedSelected.length + sortedPending.length > 0;

      const sortedSelectedType = sortTags(selectedParamTypeTags);
      const sortedPendingType = sortTags(pendingParamTypeTags);
      const sortedUnselectedType = unselectedParamTypeTags;
      const hasTripTypeTags = sortedSelectedType.length + sortedPendingType.length > 0;

      const sortedSelectedPickupUrbanicity = sortTags(selectedParamPickupUrbanicityTags);
      const sortedPendingPickupUrbanicity = sortTags(pendingParamPickupUrbanicityTags);
      const sortedUnselectedPickupUrbanicity = unselectedParamPickupUrbanicityTags;
      const hasTripPickupUrbanicityTags =
        sortedSelectedPickupUrbanicity.length + sortedPendingPickupUrbanicity.length > 0;

      const sortedSelectedStatus = sortTags(selectedParamStatusTags);
      const sortedPendingStatus = sortTags(pendingParamStatusTags);
      const sortedUnselectedStatus = unselectedParamStatusTags;
      const hasTripStatusTags =
        sortedSelectedStatus.length + sortedPendingStatus.length > 0;

      return (
        <React.Fragment>
          <Box
            style={{
              display: 'grid',
              gridTemplateColumns: `max-content 1fr`,
              gridRowGap: '4px',
            }}
          >
            <TripFilterTagGroup title="Assignees" hasTags={hasAssigneeTags}>
              {sortedSelected.map(this.renderTag(sortedSelected, unselectedTags))}
              {sortedPending.map(this.renderTag(sortedSelected, unselectedTags, true))}
            </TripFilterTagGroup>
            <TripFilterTagGroup title="Trip Types" hasTags={hasTripTypeTags}>
              {/* HACKY Trip Type filter tags below */}
              {sortedSelectedType.map(
                this.renderTripTypeTag(sortedSelectedType, sortedUnselectedType),
              )}
              {sortedPendingType.map(
                this.renderTripTypeTag(sortedSelectedType, sortedUnselectedType, true),
              )}
            </TripFilterTagGroup>
            <TripFilterTagGroup
              title="Pickup Urbanacities"
              hasTags={hasTripPickupUrbanicityTags}
            >
              {/* HACKY Trip PickupUrbanicity filter tags below */}
              {sortedSelectedPickupUrbanicity.map(
                this.renderTripPickupUrbanicityTag(
                  sortedSelectedPickupUrbanicity,
                  sortedUnselectedPickupUrbanicity,
                ),
              )}
              {sortedPendingPickupUrbanicity.map(
                this.renderTripPickupUrbanicityTag(
                  sortedSelectedPickupUrbanicity,
                  sortedUnselectedPickupUrbanicity,
                  true,
                ),
              )}
            </TripFilterTagGroup>
            <TripFilterTagGroup title="Trip Status" hasTags={hasTripStatusTags}>
              {/* HACKY Trip Status filter tags below */}
              {sortedSelectedStatus.map(
                this.renderTripStatusTag(sortedSelectedStatus, sortedUnselectedStatus),
              )}
              {sortedPendingStatus.map(
                this.renderTripStatusTag(
                  sortedSelectedStatus,
                  sortedUnselectedStatus,
                  true,
                ),
              )}
            </TripFilterTagGroup>
          </Box>
        </React.Fragment>
      );
    }

    return (
      <Pane display="flex" alignItems="center">
        <Pane>
          <SelectMenu
            options={options}
            selected={assignedTo}
            onOpen={() => {
              this.resetSelected();
            }}
            //onClose={this.updateSearchParams}
            onSelect={item => this.addSelected(item.value)}
            onDeselect={item => this.removeSelected(item.value)}
            hasTitle={false}
            isMultiSelect={true}
            marginRight={4}
          >
            <Button height={28} marginTop={8} iconAfter="caret-down">
              Filter Assignee
            </Button>
          </SelectMenu>
        </Pane>
        <Box width={6} />
        <Pane>
          <SelectMenu
            options={tripTypeOptions}
            selected={tripType}
            onOpen={() => this.resetParamType()}
            //onClose={this.updateSearchParams}
            onSelect={item => this.addParamType(item.value)}
            onDeselect={item => this.removeParamType(item.value)}
            hasTitle={false}
            isMultiSelect={true}
            marginRight={4}
          >
            <Button height={28} marginTop={8} iconAfter="caret-down">
              Filter Trip Type
            </Button>
          </SelectMenu>
        </Pane>
        <Box width={6} />
        <Pane>
          <SelectMenu
            options={tripPickupUrbanicityOptions}
            selected={tripPickupUrbanicity}
            onOpen={() => this.resetParamPickupUrbanicity()}
            //onClose={this.updateSearchParams}
            onSelect={item => this.addParamPickupUrbanicity(item.value)}
            onDeselect={item => this.removeParamPickupUrbanicity(item.value)}
            hasTitle={false}
            isMultiSelect={true}
            marginRight={4}
          >
            <Button height={28} marginTop={8} iconAfter="caret-down">
              Filter Pickup Urbanicity
            </Button>
          </SelectMenu>
        </Pane>
        <Box width={6} />
        <Pane>
          <SelectMenu
            options={tripStatusOptions}
            selected={tripStatus}
            onOpen={() => this.resetParamStatus()}
            //onClose={this.updateSearchParams}
            onSelect={item => this.addParamStatus(item.value)}
            onDeselect={item => this.removeParamStatus(item.value)}
            hasTitle={false}
            isMultiSelect={true}
            marginRight={4}
          >
            <Button height={28} marginTop={8} iconAfter="caret-down">
              Filter Trip Status
            </Button>
          </SelectMenu>
        </Pane>
        <Pane>
          <Button
            marginTop={8}
            paddingX={8}
            marginX={4}
            height={24}
            appearance="primary"
            onClick={event => {
              event.preventDefault();
              this.props.onSearch();
            }}
            disabled={
              !hasFilterChanges &&
              !hasParamTypeFilterChanges &&
              !hasParamPickupUrbanicityFilterChanges &&
              !hasParamStatusFilterChanges
            }
          >
            Apply
          </Button>
        </Pane>
      </Pane>
    );
  }
}

TripAssignFilter.propTypes = {
  currentUserId: ridesTypes.userIdType.isRequired,
  changeSearchParams: PropTypes.func,
  searchOptions: PropTypes.object,
  assignedTo: PropTypes.arrayOf(ridesTypes.userIdType),
  assigneeList: ridesTypes.tripAssigneeListType,

  tripTypeList: ridesTypes.tripTypeListType,
  tripPickupUrbanicityList: ridesTypes.tripPickupUrbanicityOptionListType,
  onSearch: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  renderTags: PropTypes.bool,
  isDateRangeChanged: PropTypes.bool,
};

export default TripAssignFilter;
