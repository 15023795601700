import { camelizeKeys } from 'humps';
import { useDispatch } from 'react-redux';
import { normalize } from 'normalizr';
import { useCallback } from 'react';

import { entitiesReceiveFromReactQuerySync } from 'rides/store/entities/actions';
import * as schemas from 'rides/store/entities/schemas';

// --

function useSyncToReduxEntities() {
  const dispatch = useDispatch();
  const entityUpdateFn = useCallback(
    (entityType, data) => {
      const schema = schemas[entityType]; // eslint-disable-line import/namespace
      const camelizedData = camelizeKeys(data);
      const { entities } = normalize(
        camelizedData,
        Array.isArray(data) ? [schema] : schema,
      );
      return dispatch(entitiesReceiveFromReactQuerySync(entities));
    },
    [dispatch],
  );
  return entityUpdateFn;
}


export {
  useSyncToReduxEntities
}
