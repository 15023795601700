import * as React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { resourceCreateRequest } from 'rides/store/actions';
import { tripStatus, tripActions } from 'rides/utils/trip';
import TripStatusTransitionModal from 'rides/components/organisms/TripStatusTransitionModal';

const mapDispatchToProps = {
  assignDriver: (tripId, payload) =>
    resourceCreateRequest(`trip/${tripId}/assign_driver`, payload, 'trip'),
  assignVendor: (tripId, payload) =>
    resourceCreateRequest(`trip/${tripId}/assign_vendor`, payload, 'trip'),
  cancelTrip: (tripId, payload) =>
    resourceCreateRequest(`trip/${tripId}/cancel`, payload, 'trip'),
  completeTrip: (tripId, payload) =>
    resourceCreateRequest(`trip/${tripId}/complete`, payload, 'trip'),
  confirmVendor: (tripId, payload) =>
    resourceCreateRequest(`trip/${tripId}/confirm_vendor`, payload, 'trip'),
  driverArrived: (tripId, payload) =>
    resourceCreateRequest(`trip/${tripId}/set_driver_arrived`, payload, 'trip'),
  driverEnRoute: (tripId, payload) =>
    resourceCreateRequest(`trip/${tripId}/set_driver_en_route`, payload, 'trip'),
  driverFailed: (tripId, payload) =>
    resourceCreateRequest(`trip/${tripId}/set_driver_failed`, payload, 'trip'),
  inTransit: (tripId, payload) =>
    resourceCreateRequest(`trip/${tripId}/set_in_transit`, payload, 'trip'),
  resetStatus: (tripId, payload) =>
    resourceCreateRequest(`trip/${tripId}/reset_status`, payload, 'trip'),
  markBillable: (tripId, payload) =>
    resourceCreateRequest(`trip/${tripId}/mark_billable`, payload, 'trip'),
  markNonBillable: (tripId, payload) =>
    resourceCreateRequest(`trip/${tripId}/mark_non_billable`, payload, 'trip'),
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { tripId } = ownProps;

  return {
    ...ownProps,
    ...stateProps,
    onSubmit: (values, onSuccess, onError) => {
      switch (ownProps.status) {
        case tripStatus.VENDOR_ASSIGNED:
          return dispatchProps
            .assignVendor(tripId, values)
            .then(() => onSuccess(), error => onError(error.message));
        case tripStatus.VENDOR_CONFIRMED:
          return dispatchProps
            .confirmVendor(tripId, values)
            .then(() => onSuccess(), error => onError(error.message));
        case tripStatus.DRIVER_ASSIGNED:
          return dispatchProps
            .assignDriver(tripId, values)
            .then(() => onSuccess(), error => onError(error.message));
        case tripStatus.DRIVER_FAILED:
          return dispatchProps
            .driverFailed(tripId, values)
            .then(() => onSuccess(), error => onError(error.message));
        case tripStatus.DRIVER_EN_ROUTE:
          return dispatchProps
            .driverEnRoute(tripId, values)
            .then(() => onSuccess(), error => onError(error.message));
        case tripStatus.DRIVER_ARRIVED:
          return dispatchProps
            .driverArrived(tripId, values)
            .then(() => onSuccess(), error => onError(error.message));
        case tripStatus.IN_TRANSIT:
          return dispatchProps
            .inTransit(tripId, values)
            .then(() => onSuccess(), error => onError(error.message));
        case tripStatus.COMPLETED:
          return dispatchProps
            .completeTrip(tripId, values)
            .then(() => onSuccess(), error => onError(error.message));
        case tripStatus.CANCELLED:
          return dispatchProps
            .cancelTrip(tripId, values)
            .then(() => onSuccess(), error => onError(error.message));
        case 'reset status':
          return dispatchProps
            .resetStatus(tripId, values)
            .then(() => onSuccess(), error => onError(error.message));
        case 'billable':
          return dispatchProps
            .markBillable(tripId, values)
            .then(() => onSuccess(), error => onError(error.message));
        case 'non-billable':
          return dispatchProps
            .markNonBillable(tripId, values)
            .then(() => onSuccess(), error => onError(error.message));
        default:
          return onError('Unknown status');
      }
    },
  };
};

function defaultTransition(status) {
  const transition = {
    tripStatusTransition: {
      // The actual value the API expects. When auto is true, the date/time properties will be used to set this.
      occurredAt: new Date().toISOString(),
      // Boolean to track whether the UI will automatically timestamp the event
      occurredAtAuto: true,
      // Date portion to be used when auto is false
      occurredAtDate: '',
      // Time portion to be used when auto is false
      occurredAtTime: '',
      reasonId: '',
      notes: '',
    },
  };

  switch (status) {
    case tripStatus.VENDOR_ASSIGNED:
      transition.trip = { vendorId: '' };
      break;
    case tripStatus.DRIVER_ASSIGNED:
      transition.trip = {
        customDriverName: '',
        customDriverPhone: '',
        customDriverCarDescription: '',
        customDriverCarLicense: '',
      };
      break;
    default:
      transition.trip = {};
  }

  return transition;
}

const CreateTripStatusTransitionModal = ({ status, ...props }) => {
  const transition = defaultTransition(status);
  const dialogTitle = R.pathOr('', [status, 'label'], tripActions);

  return (
    <TripStatusTransitionModal
      {...props}
      status={status}
      transition={transition}
      dialogTitle={dialogTitle}
      confirmLabel="Confirm"
    />
  );
};

CreateTripStatusTransitionModal.propTypes = {
  status: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

CreateTripStatusTransitionModal.defaultProps = {
  status: null, // Used when the dialog is closed
};

export default R.compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps,
    mergeProps,
  ),
)(CreateTripStatusTransitionModal);
