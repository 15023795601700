/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'GuerneyIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M11.616 12.043a.177.177 0 01-.084-.018L3.299 8.2c-.11-.048-.162-.19-.116-.31.046-.125.172-.182.284-.13l8.233 3.825c.11.053.162.191.116.315a.215.215 0 01-.2.143z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M3.383 12.043a.215.215 0 01-.2-.143c-.046-.124.006-.263.116-.315l8.233-3.824c.11-.048.238.004.284.129.046.12-.006.262-.116.31l-8.233 3.825a.178.178 0 01-.084.018zM13.783 4.873H1.217c-.12 0-.217.11-.217.24v1.91c0 .135.097.24.217.24h12.566c.12 0 .217-.105.217-.24v-1.91c0-.13-.097-.24-.217-.24zM12.669 2.01c-1.072.177-2.866.865-3.41 2.036a.261.261 0 00.007.234c.04.072.11.115.185.115h4.333c.12 0 .217-.105.217-.24V3.2c0-.722-.759-1.281-1.332-1.19zM12.267 11.088c-.478 0-.867.43-.867.955 0 .532.39.957.867.957.478 0 .867-.425.867-.957 0-.525-.389-.955-.867-.955z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M2.734 11.088c-.478 0-.867.43-.867.955 0 .532.389.957.867.957s.867-.425.867-.957c0-.525-.39-.955-.867-.955z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
