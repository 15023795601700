/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'EncounterDetailsXlIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M16.291 3.354a.48.48 0 00-.48-.479h-2.395v7.543a7.126 7.126 0 012.875-.81V3.354zM2.875 18.208V2.875H.479a.48.48 0 00-.479.48v15.332a2.4 2.4 0 002.396 2.396h8.638a7.153 7.153 0 01-1.306-2.875H2.875z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M10.062 8.625H5.27a.48.48 0 000 .958h4.792a.48.48 0 000-.958zM10.062 10.542H5.27a.48.48 0 000 .958h4.792a.48.48 0 000-.958zM10.541 12.937a.48.48 0 00-.48-.479h-4.79a.48.48 0 000 .958h4.79a.48.48 0 00.48-.479zM5.27 14.375a.48.48 0 000 .958h2.875a.48.48 0 000-.958H5.27zM4.313 6.708h7.667a.48.48 0 00.479-.479V2.396a.48.48 0 00-.48-.48H10.5C10.29.742 9.347 0 8.146 0 6.962 0 6.011.72 5.794 1.917h-1.48a.48.48 0 00-.48.479v3.833c0 .265.215.48.48.48zM20.26 15.525l-3.997 4.38-2.968-2.966a.478.478 0 11.677-.678l2.26 2.258 3.32-3.64a.48.48 0 01.709.646zm-3.49-4.983a6.235 6.235 0 00-6.229 6.229A6.236 6.236 0 0016.771 23a6.236 6.236 0 006.228-6.23 6.236 6.236 0 00-6.229-6.228z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 23 23',
});
