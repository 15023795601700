/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'LandlineIcon',
  path: (
    <g fill="currentColor">
      <g fill-rule="evenodd" clip-path="url(#clip0)" clip-rule="evenodd">
        <path d="M14.067 1.643a30.46 30.46 0 00-12.134 0C.85 1.865 0 2.886 0 3.969v1c0 .919.747 1.666 1.667 1.667h2c.918 0 1.666-.748 1.666-1.667v-.363a30.58 30.58 0 015.334 0v.363c0 .442.174.859.491 1.176a1.65 1.65 0 001.175.491h2A1.67 1.67 0 0016 4.97v-1c0-1.083-.85-2.104-1.933-2.326z" />
        <path d="M8 13.636a2.67 2.67 0 01-2.666-2.666 2.67 2.67 0 012.667-2.667 2.67 2.67 0 012.666 2.667 2.67 2.67 0 01-2.666 2.666zm1.313-6.624V5.97a.333.333 0 10-.666 0v1H7.334v-1a.333.333 0 00-.667 0v1.016c-2.969.174-5.333 2.638-5.333 5.65v2c0 .184.15.334.333.334h12.667c.184 0 .333-.15.333-.334v-2c-.02-3.004-2.02-5.671-5.354-5.624z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 16 16',
});
