/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'FilterIcon',
  path: (
    <g fill="currentColor">
      <g fill-rule="evenodd" clip-path="url(#clip0)" clip-rule="evenodd">
        <path d="M0 3c0-.552.512-1 1.143-1h13.714C15.488 2 16 2.448 16 3s-.512 1-1.143 1H1.143C.512 4 0 3.552 0 3zm0 5c0-.552.512-1 1.143-1h13.714C15.488 7 16 7.448 16 8s-.512 1-1.143 1H1.143C.512 9 0 8.552 0 8zm1.143 4C.512 12 0 12.448 0 13s.512 1 1.143 1h13.714c.631 0 1.143-.448 1.143-1s-.512-1-1.143-1H1.143z" />
        <path d="M12 6c-.552 0-1-.413-1-.923V.923c0-.51.448-.923 1-.923s1 .413 1 .923v4.154c0 .51-.448.923-1 .923zM9 16c-.552 0-1-.413-1-.923v-4.154c0-.51.448-.923 1-.923s1 .413 1 .923v4.154c0 .51-.448.923-1 .923zM5 11c-.552 0-1-.413-1-.923V5.923C4 5.413 4.448 5 5 5s1 .413 1 .923v4.154c0 .51-.448.923-1 .923z" />
        <path d="M10 6c-.552 0-1-.413-1-.923V.923C9 .413 9.448 0 10 0s1 .413 1 .923v4.154c0 .51-.448.923-1 .923zM7 16c-.552 0-1-.758-1-1.692V6.692C6 5.758 6.448 5 7 5s1 .758 1 1.692v7.616C8 15.242 7.552 16 7 16z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 16 16',
});
