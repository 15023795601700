import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton, Text, Pane, Heading } from 'evergreen-ui';

import * as ridesTypes from 'rides/types';
import DateTime from 'rides/nui/DateTime';
import { getEligibilityStatus, getEligibilityStatusColor } from './utils';
import MemberEligibilityIcon from './Icon';

// --
// -- Status Last Updated Component
// --

export const EligibilityLastUpdated = ({ eligibility }) => {
  const lastUpdateAt = eligibility && eligibility.insertedAt;

  return (
    <Pane>
      <Text color="grey">
        LAST UPDATED —{' '}
        {lastUpdateAt ? (
          <DateTime
            format="MMM DD, YYYY"
            datetime={lastUpdateAt}
            render={date => date.toUpperCase()}
          />
        ) : (
          'NEVER'
        )}
      </Text>
    </Pane>
  );
};

EligibilityLastUpdated.propTypes = {
  eligibility: ridesTypes.memberEligibilityType.isRequired,
};

// --
// -- Status Message Component
// --

const getStatusMessage = status => {
  const statusMessageTextMap = {
    unchecked: {
      text: 'Unknown',
      info: 'no eligibility information exists for this member',
    },
    notfound: {
      text: 'Member Not Found',
      info:
        'we attempted to retrieve eligibility info, but the member was not found. Could be mis-typed ID, member name, etc',
    },
    ineligible: {
      text: 'Member Ineligible for Transportation Benefit',
      info: 'we found the member, but they are not eligible for our service',
    },
    eligible: {
      text: 'Member Eligible',
      info: 'we found the member and they are eligible',
    },
  };

  // default to unchecked status if no matching status exists
  const message = statusMessageTextMap[status] || statusMessageTextMap['unchecked'];

  if (!message) {
    return null;
  }

  return {
    text: message.text,
    info: message.info,
  };
};

export const StatusMessage = ({ status }) => {
  const msg = getStatusMessage(status);
  const color = getEligibilityStatusColor(status);

  return (
    <Pane>
      <Text size={500} color={color} fontWeight={500}>
        {msg.text}
      </Text>
      <Text size={500} color="grey">
        {' '}
        ({msg.info})
      </Text>
    </Pane>
  );
};

StatusMessage.propTypes = {
  status: ridesTypes.memberEligibilityStatusType.isRequired,
};

// --
// -- CheckEligibilityButton Component
// --

export const CheckEligibilityButton = ({ status, checkEligibility, isLoading }) => {
  return (
    <Button
      paddingBottom={0}
      paddingTop={0}
      isLoading={isLoading}
      onClick={() => checkEligibility()}
      appearance="primary"
    >
      CHECK ELIGIBILITY{status !== 'unchecked' && ' AGAIN'}
    </Button>
  );
};

CheckEligibilityButton.propTypes = {
  status: ridesTypes.memberEligibilityStatusType.isRequired,
  checkEligibility: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

// --
// -- StatusPanel Component
// --

const NuiMemberEligibilityStatusPanel = ({
  member,
  close,
  checkEligibility,
  isChecking,
  showStaffUI,
  showMemberEligibilityCheckUI,
}) => {
  const status = getEligibilityStatus(member.currentEligibility);
  const isEligible = status === 'eligible';
  const showCheckEligibilityButton =
    showStaffUI && showMemberEligibilityCheckUI;

  return (
    <Pane
      width={330}
      display="flex"
      alignItems="center"
      flexDirection="column"
      padding={4}
    >
      {/* PANEL HEADER & CLOSE BUTTON */}
      <Pane display="flex" width="100%">
        <Pane flex={1} alignItems="center" display="flex" paddingLeft={8}>
          <Heading size={600}>Member Eligibility</Heading>
        </Pane>
        <IconButton appearance="minimal" icon="cross" onClick={close} />
      </Pane>

      <Pane padding={8} width="100%">
        {/* STATUS LAST UPDATE  */}
        <Pane marginBottom={24}>
          <EligibilityLastUpdated eligibility={member.currentEligibility} />
        </Pane>

        {/* STATUS MESSAGE */}
        <Pane display="flex" width="100%">
          <Pane paddingRight={8}>
            <MemberEligibilityIcon
              iconProps={{ size: 20 }}
              eligibility={member.currentEligibility}
            />
          </Pane>
          <Pane display="flex" width="100%" flexDirection="column">
            <StatusMessage status={status} />
            {showCheckEligibilityButton && (
              <Pane flexShrink={0} paddingTop={30}>
                <CheckEligibilityButton
                  status={status}
                  checkEligibility={checkEligibility}
                  isLoading={isChecking}
                />
              </Pane>
            )}
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
};

NuiMemberEligibilityStatusPanel.propTypes = {
  member: ridesTypes.memberType.isRequired,
  close: PropTypes.func.isRequired,
  checkEligibility: PropTypes.func.isRequired,
  isChecking: PropTypes.bool.isRequired,
  showStaffUI: PropTypes.bool.isRequired,
  showMemberEligibilityCheckUI: PropTypes.bool.isRequired,
};

export default NuiMemberEligibilityStatusPanel;
