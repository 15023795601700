import React from 'react';
import { IconButton } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { Position } from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import {
  convertDateTimeToJSDate,
  convertJSDateToBrowserTime,
  convertJSDateToReadableDate,
  convertReadableDateToJSDate,
  readableDate,
} from 'rides/utils/dateTime';
import { Flex } from 'rides/nui';

function getMaxDate() {
  const maxDate = new Date();
  maxDate.setFullYear(2030, 11, 31);
  maxDate.setHours(23, 59, 59);
  return maxDate;
}

const dateInputProps = {
  className: 'from-date--date-input',
  canClearSelection: true,
  highlightCurrentDay: true,
  popoverProps: { position: Position.BOTTOM, boundary: 'window' },
  // vv-- Date format and parsing --vv
  formatDate: convertJSDateToReadableDate,
  parseDate: convertReadableDateToJSDate,
  placeholder: readableDate,
  maxDate: getMaxDate(),
};

function formatDateValue(value) {
  if (!value) {
    return null;
  }

  const localTimeDate = convertJSDateToBrowserTime(value);
  return convertDateTimeToJSDate(localTimeDate);
}

export function DatePicker({ selectedDate, onChange, onClear }) {
  const noValue = !selectedDate;
  const jsDateValue = formatDateValue(selectedDate);

  return (
    <Flex alignItems="center" style={{ maxWidth: '11rem' }}>
      <DateInput {...dateInputProps} value={jsDateValue} onChange={onChange} />
      <IconButton
        height={16}
        width={12}
        left={-16}
        alignSelf="center"
        appearance="minimal"
        intent="warning"
        icon="cross"
        onClick={onClear}
        disabled={noValue}
        visibility={noValue ? 'hidden' : 'visible'}
        /* style={{ opacity: '0.5' }} */
      />
    </Flex>
  );
}

DatePicker.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  isClearable: PropTypes.bool,
  showPopperArrow: PropTypes.bool,
};

export default DatePicker;
