/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'Vitals2Icon',
  path: (
    <g fill="currentColor">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M1 6.478h2.435l1.217-1.826L5.87 6.478l1.217-2.74 1.826 5.783 1.522-7.304 1.217 4.261H15"
      />
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M15 13.783A1.22 1.22 0 0113.783 15H2.217A1.22 1.22 0 011 13.783V2.217C1 1.547 1.547 1 2.217 1h11.566C14.453 1 15 1.547 15 2.217v11.566z"
        clip-rule="evenodd"
      />
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M1 11.957h1.522l.608-.61 1.218 1.218.609-.608h1.826l.608-.61 1.218 1.218.608-.608h1.826l.61-.61 1.217 1.218.608-.608H15"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
