import React from 'react';
import * as R from 'ramda';

import { memberType } from 'rides/types';
import {
  Address,
  Block,
  Column,
  Member,
  MemberDetailItem,
  Paragraph,
  Row,
} from 'rides/components';
import {
  fieldLabelTypoFixes
} from 'rides/components/organisms/MemberHealthAttributesFormFields'

function safeListToString(arr, seperator = ', ') {
  if (typeof arr?.join !== 'function') {
    return '';
  }

  return `${arr.join(seperator)}`;
}

const MemberDetails = ({ member, ...props }) => {
  const memberAddress = (
    <Block>
      <Address location={member.location} breakOnStreet />
    </Block>
  );

  const healthAttributes = R.pathOr({}, ['healthAttributes', 'data'], member);
  const haAmbulatoryStatus = R.pathOr('', ['ambulatoryStatus'], healthAttributes);
  const haDevicesAndEquipment = R.pathOr([], ['devicesAndEquipment'], healthAttributes);
  const haManualWheelchairSize = R.pathOr('', ['manualWheelchairSize'], healthAttributes);
  const haPowerWheelchairSize = R.pathOr('', ['powerWheelchairSize'], healthAttributes);
  const haVitalsHeightFeet = R.pathOr('', ['vitalsHeightFeet'], healthAttributes);
  const haVitalsHeightInches = R.pathOr('', ['vitalsHeightInches'], healthAttributes);
  const haVitalsWeight = R.pathOr('', ['vitalsWeight'], healthAttributes);
  const haSystemLimitations = R.pathOr([], ['systemLimitations'], healthAttributes);

  const haCognitionBehavior = R.pathOr([], ['cognitionBehavior'], healthAttributes);
  const haCognitionBehaviorFixed = haCognitionBehavior.map(fieldLabelTypoFixes);

  const hasManualWheelChair = R.contains('Manual Wheelchair', haDevicesAndEquipment);
  const manualWheelchairSize = hasManualWheelChair ? haManualWheelchairSize : undefined;

  const hasPowerWheelChair = R.contains('Power Wheelchair', haDevicesAndEquipment);
  const powerWheelchairSize = hasPowerWheelChair ? haPowerWheelchairSize : undefined;

  return (
    <Block {...props}>
      <Row>
        <Column xs={12} sm={6}>
          <MemberDetailItem label="Name" value={<Member member={member} />} />
          <MemberDetailItem label="Member ID" value={member.externalId || ' -- '} />
          <MemberDetailItem label="Date of Birth" value={member.dateOfBirth} />
          <MemberDetailItem label="Gender" value={member.gender} />
          <MemberDetailItem label="Address" value={memberAddress} />
          <MemberDetailItem label="Contact Number" value={member.phoneNumber} />
          <MemberDetailItem
            label="Contact Number (Alternate)"
            value={member.phoneNumberAlternate}
          />
          <MemberDetailItem label="Email Address" value={member.email} />
          <MemberDetailItem label="Languages Spoken" value={member.languagesSpoken} />
        </Column>

        <Column xs={12} sm={6}>
          <Block>Health Attributes</Block>
          <MemberDetailItem label="Ambulatory Status" value={`${haAmbulatoryStatus}`} />
          <MemberDetailItem
            label="Devices &amp; Equipment"
            value={`${safeListToString(haDevicesAndEquipment)}`}
          />
          <MemberDetailItem label="Manual Wheelchair Size" value={manualWheelchairSize} />
          <MemberDetailItem label="Power Wheelchair Size" value={powerWheelchairSize} />

          <Block style={{ marginTop: 20 }}>Vitals</Block>
          <MemberDetailItem
            label="Height"
            value={`${haVitalsHeightFeet} ${haVitalsHeightInches}`}
          />
          <MemberDetailItem label="Weight" value={`${haVitalsWeight}`} />

          <Block style={{ marginTop: 20 }} />
          <MemberDetailItem
            label="System Limitations"
            value={`${safeListToString(haSystemLimitations)}`}
          />
          <MemberDetailItem
            label="Cognition & Behavior"
            value={`${safeListToString(haCognitionBehaviorFixed)}`}
          />
        </Column>
      </Row>
      <Row>
        <Column xs={12}>
          <MemberDetailItem label="Notes" value={<Paragraph>{member.notes}</Paragraph>} />
        </Column>
      </Row>
      <Block style={{ marginTop: 20 }} />
    </Block>
  );
};

MemberDetails.propTypes = {
  member: memberType,
};

export default MemberDetails;
