const ReduxThunk = require('redux-thunk').default;
const { middleware: ReduxSagaThunk, middleware } = require('redux-saga-thunk');
const { routerMiddleware } = require('connected-react-router');

const entitiesMiddleware = require('./entities/middleware').default;

const history = require('rides/browserHistory').default;

const middlewares = [];

middlewares.push(entitiesMiddleware);
middlewares.push(ReduxThunk);
middlewares.push(ReduxSagaThunk);
middlewares.push(routerMiddleware(history));

module.exports = middlewares;
