/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'EllipsisHorizontalIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M1 7.5a1.5 1.5 0 103 0 1.5 1.5 0 00-3 0zm5.5 0a1.5 1.5 0 103 0 1.5 1.5 0 00-3 0zm7 1.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
