/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'ProvidersLgIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M13.786 10.833a.477.477 0 01-.163-.029.42.42 0 01-.26-.552c.158-.383.291-.778.395-1.172a.436.436 0 01.271-.292.482.482 0 01.411.033c.148.01.483-.435.483-1.26 0-.292-.045-.573-.13-.812a.391.391 0 01-.012-.226c.097-.415.142-.763.142-1.096 0-.236.204-.427.454-.427.252 0 .455.191.455.427 0 .365-.044.738-.138 1.167.09.297.138.629.138.967 0 1.188-.558 2.09-1.308 2.132-.091.292-.196.583-.313.868a.455.455 0 01-.425.272zM6.225 11.667a.445.445 0 01-.41-.26 10.042 10.042 0 01-.371-1c-.731-.041-1.276-.921-1.276-2.078 0-.33.047-.653.136-.942a5.207 5.207 0 01-.136-1.138c0-.23.198-.416.443-.416s.443.187.443.416c0 .325.044.664.14 1.068a.382.382 0 01-.013.22 2.403 2.403 0 00-.127.792c0 .805.333 1.247.443 1.247a.503.503 0 01.416-.041c.13.05.242.145.277.275.113.431.263.863.446 1.284a.408.408 0 01-.245.542.464.464 0 01-.166.03zM16.363 14.074l-2.144-.719a.43.43 0 00-.443.108c-1.01 1.063-2.393 2.519-3.776 2.519-1.382 0-2.765-1.456-3.775-2.519a.431.431 0 00-.444-.108l-2.143.719C1.462 14.804 0 16.786 0 19.008v.59A.41.41 0 00.417 20h19.166c.231 0 .417-.18.417-.402v-.59c0-2.222-1.46-4.204-3.637-4.934z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M15.418 6.25c.23 0 .417-.187.417-.417A5.84 5.84 0 0010 0C6.783 0 4.165 2.617 4.168 5.883l.14 1.16c.026.209.203.366.414.366.472 0 1.042.085 1.048.086a.413.413 0 00.478-.377l.277-3.33a1.413 1.413 0 011.4-1.288c.262 0 .518.14.758.42.36.413.89.413 1.317.413.399 0 .784-.152 1.114-.441.29-.253.71-.392 1.188-.392a1.12 1.12 0 011.124 1.034c.124 1.508.327 3.59.327 3.59a.416.416 0 00.449.375l1.11-.09a.411.411 0 00.015-.82l.042-.35c.017.003.032.01.049.01z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M7.498 7.5a.835.835 0 00-.833.833.834.834 0 10.833-.833zm0 2.5c-.918 0-1.666-.747-1.666-1.667 0-.919.748-1.666 1.667-1.666s1.666.747 1.666 1.666C9.165 9.253 8.418 10 7.5 10zM11.666 7.5a.835.835 0 00-.833.833.834.834 0 10.833-.833zm0 2.5C10.749 10 10 9.253 10 8.333c0-.919.748-1.666 1.667-1.666s1.666.747 1.666 1.666c0 .92-.747 1.667-1.666 1.667z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M6.333 9.167c-.085 0-.17-.05-.235-.146l-1-1.5a.691.691 0 010-.708c.13-.195.34-.195.471 0l1 1.5c.13.196.13.512 0 .708-.065.097-.15.146-.236.146zM13.665 9.167c-.085 0-.17-.05-.235-.146a.69.69 0 010-.708l1-1.5c.13-.195.341-.195.471 0 .13.196.13.512 0 .708l-1 1.5c-.065.097-.15.146-.236.146zM10.416 9.167c-.135 0-.267-.087-.348-.248-.002-.005-.17-.308-.486-.308-.318 0-.485.306-.487.308-.127.255-.386.323-.577.154-.192-.17-.244-.515-.116-.77.15-.299.566-.803 1.18-.803.615 0 1.03.504 1.18.803.128.255.076.6-.116.77a.345.345 0 01-.23.094zM14.016 10.37a.385.385 0 00-.471-.022l-.261.19-.062.064c-.62.648-.62.648-1.254.648-.22 0-.395-.18-.662-.474-.314-.346-.704-.776-1.336-.776-.631 0-1.021.43-1.335.776-.267.294-.442.474-.663.474-.633 0-.633 0-1.253-.648l-.062-.064a.396.396 0 00-.31-.12l-.144.01a.396.396 0 00-.31.195.431.431 0 00-.032.377c.75 1.934 2.225 4 4.11 4 1.938 0 3.423-2.147 4.168-4.155a.429.429 0 00-.123-.475z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M10.418 12.5h-.833a.417.417 0 010-.833h.833a.416.416 0 110 .833z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
});
