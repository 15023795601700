import React from 'react';
import PropTypes from 'prop-types';

import { tripType } from 'rides/types';
import { useTenantUrl } from 'rides/hooks/use-tenant';
import EntityDetail from 'rides/components/utils/EntityDetail';
import {
  Block,
  DateTime,
  Heading,
  Member,
  Table,
  TableCell,
  TableRow,
  Widget,
  TripLeg,
  Button,
  Glyphicon,
  SearchField,
  Row,
  Column,
} from 'rides/components';
import { StandardLayout } from 'rides/containers';

function ViewTripLinkButton({ trip }) {
  const linkTo = useTenantUrl(
    `/member/${trip.member.id}/trip-group/${trip.groupId}#leg-${trip.id}`,
  );

  return (
    <Button to={linkTo} primary sm>
      <Glyphicon icon="search" small /> View
    </Button>
  );
}
ViewTripLinkButton.propTypes = {
  trip: tripType,
};

const ActiveTripListPage = ({ tripList, onSubmitSearch, keywords }) => (
  <StandardLayout>
    <Row>
      <Column sm={4}>
        <Heading level={2}>Active Trips</Heading>
      </Column>
      <Column sm={4}>
        <Block style={{ width: '100%', marginTop: '10px' }}>
          <SearchField
            value={keywords}
            onSubmit={onSubmitSearch}
            inputProps={{ placeholder: 'Search for a trip' }}
          />
        </Block>
      </Column>
    </Row>

    <Widget>
      <Table
        data-test-handle="active trip list table"
        head={
          <TableRow>
            <TableCell heading xs={1} textCenter>
              Trip Id
            </TableCell>
            <TableCell heading xs={2}>
              Member
            </TableCell>
            <TableCell heading xs={2}>
              Start Time
            </TableCell>
            <TableCell heading xs={5}>
              Legs
            </TableCell>
            <TableCell heading xs={1}>
              Language
            </TableCell>
            <TableCell heading xs={1} />
          </TableRow>
        }
      >
        {tripList &&
          tripList.length > 0 &&
          tripList.map(trip => (
            <EntityDetail entityType="trip" entityId={trip.id} key={trip.id}>
              {trip => (
                <TableRow>
                  <TableCell className="text-center">{trip.id}</TableCell>
                  <TableCell>
                    <Member member={trip.member} />
                  </TableCell>
                  <TableCell>
                    <DateTime datetime={trip.pickupTime} />
                  </TableCell>
                  <TableCell>
                    <TripLeg trip={trip} />
                  </TableCell>
                  <TableCell>{trip.member.languagesSpoken}</TableCell>
                  <TableCell textRight>
                    <ViewTripLinkButton trip={trip} />
                  </TableCell>
                </TableRow>
              )}
            </EntityDetail>
          ))}

        {(!tripList || !tripList.length) && (
          <TableRow>
            <TableCell colSpan={5} className="text-center">
              No trips matching the selected criteria.
            </TableCell>
          </TableRow>
        )}
      </Table>
    </Widget>
  </StandardLayout>
);

ActiveTripListPage.propTypes = {
  tripList: PropTypes.arrayOf(tripType),
  onSubmitSearch: PropTypes.func,
  keywords: PropTypes.string,
};

export default ActiveTripListPage;
