// @flow

import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pending, fulfilled } from 'redux-saga-thunk';
import { withRouter } from 'react-router-dom';
import { fromEntities, fromResource } from 'rides/store/selectors';
import { resourceListReadRequest } from 'rides/store/actions';
import SelectField from 'rides/components/molecules/SelectField';

type Props = {
  fetchPlans: Function,
  loading: boolean,
  ready: boolean,
  plans: [],
};

const mapDispatchToProps = {
  fetchPlans: () => resourceListReadRequest(`plan`, null, 'plan'),
};

const plansThunkId = 'planListRead';

const mapStateToProps = state => ({
  loading: pending(state, plansThunkId),
  ready: fulfilled(state, plansThunkId),
  plans: fromEntities.getList(state, 'plan', fromResource.getList(state, 'plan')),
});

class PlanSelectFieldContainer extends React.Component<Props> {
  componentWillMount() {
    this.props.fetchPlans();
  }

  render() {
    const { loading, ready, plans } = this.props;
    const options = plans.map(({ id, name }) => [id, name]);

    return <SelectField disabled={loading || !ready} {...this.props} options={options} />;
  }
}

export default R.compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(
  PlanSelectFieldContainer,
);
