import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'evergreen-ui';

import * as ridesTypes from 'rides/types';
import { getEligibilityColor, getEligibilityIconName } from './utils';

const NuiMemberEligibilityIcon = ({ eligibility, iconProps }) => {
  const color = getEligibilityColor(eligibility);
  const icon = getEligibilityIconName(eligibility);

  return <Icon icon={icon} color={color} {...iconProps} />;
};

NuiMemberEligibilityIcon.propTypes = {
  eligibility: ridesTypes.memberEligibilityType,
  iconProps: PropTypes.object,
};

export default NuiMemberEligibilityIcon;
