import * as React from 'react';
import { camelizeKeys } from 'humps';
import { TripStatusTransitionLog } from '@carecar-evo/core-ui';
import { VStack } from '@chakra-ui/react';
import { useTripStatusTransitionList } from 'rides/evo/utils/trips';
import { PaneSectionHeader } from './PaneSection';
import TripTransitionActionsMenu from 'rides/nui/TripTransitionsTable/TripTransitionActionsMenu';

function StatusTransitionPane({ tripId, timezone, isTripLoaded }) {
  const {
    // refetch: refetchTransitions,
    transitions,
    error,
    isLoading,
    // isError,
    // isSuccess,
  } = useTripStatusTransitionList(tripId, {
    enabled: isTripLoaded,
  });

  const isLoaded = !isLoading;

  if (error) {
    console.error('Error loading trip for detail panel.', {
      transitions,
      error,
    });

    return null;
  }

  return (
    <>
      <VStack
        px="24px"
        py="16px"
        borderTopWidth="1px"
        borderTopColor="lightGrey.100"
        borderBottomWidth="1px"
        borderBottomColor="lightGrey.100"
        spacing="5px"
        alignItems="stretch"
      >
        <PaneSectionHeader title="Transition Log" isOpen={true} hideCollapseToggle />
        <TripStatusTransitionLog
          statusTransitions={transitions}
          tz={timezone}
          renderEditCell={transition => {
            const camelizedTransition = camelizeKeys(transition);

            return (
              <TripTransitionActionsMenu
                tripId={transition.trip_id}
                transition={camelizedTransition}
              />
            );
          }}
        />
      </VStack>
    </>
  );
}

export { StatusTransitionPane };
