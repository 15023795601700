/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'NotesActiveLgIcon',
  path: <g fill="currentColor"><g clip-path="url(#clip0_8892_32873)"><path fill-rule="evenodd" d="M13.295 2.609H4.508c-.806 0-1.465.711-1.465 1.58v11.068h1.465V4.19h8.787V2.609zM15.49 5.77H7.437c-.806 0-1.465.711-1.465 1.58V18.42c0 .87.659 1.581 1.464 1.581h8.055c.806 0 1.465-.711 1.465-1.581V7.352c0-.87-.66-1.581-1.465-1.581zm0 12.648H7.438V7.352h8.055v11.067z" clip-rule="evenodd"></path></g><defs><clipPath id="clip0_8892_32873"><path d="M0 0h20v20H0z"></path></clipPath></defs></g>,
  viewBox: '0 0 20 20',
});
