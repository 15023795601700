/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'MedicalLgIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M7.574 3.767h4.824V2.211a.52.52 0 00-.517-.52h-3.79a.52.52 0 00-.517.52v1.556zm5.169.692H7.229a.344.344 0 01-.344-.346V2.211C6.885 1.547 7.425 1 8.09 1h3.79c.665 0 1.206.546 1.206 1.21v1.903a.344.344 0 01-.344.346z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M4.128 11.377c0-3.051 2.473-5.535 5.513-5.535s5.513 2.484 5.513 5.535c0 3.05-2.473 5.534-5.513 5.534s-5.513-2.484-5.513-5.534zm15.527-7.61H.345c-.191 0-.345.16-.345.346v14.541c0 .194.154.346.345.346h19.31c.191 0 .345-.152.345-.346V4.113a.348.348 0 00-.345-.346z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M12.742 12.76h-1.723v1.73a.344.344 0 01-.345.345H8.607a.344.344 0 01-.345-.345v-1.73H6.54a.344.344 0 01-.344-.346V10.34c0-.187.154-.346.344-.346h1.723v-1.73c0-.186.155-.345.345-.345h2.067c.19 0 .345.159.345.346v1.73h1.723c.19 0 .345.158.345.345v2.075a.344.344 0 01-.345.346zM9.641 6.534c-2.66 0-4.825 2.172-4.825 4.843 0 2.67 2.165 4.842 4.825 4.842s4.824-2.172 4.824-4.842c0-2.67-2.165-4.843-4.824-4.843z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
});
