import React from 'react';
import PropTypes from 'prop-types';

import { Block, Button, Column, Glyphicon, HorizontalRule, Row } from 'rides/components';

class TripWizardFooter extends React.Component {
  onClickPrevStep = () => {
    const { goToPrevStep, values } = this.props;
    goToPrevStep(values);
  };

  render() {
    const {
      addLegMode,
      createMode,
      editMode,
      disabled,
      showCancelTrip,
      showPrev,

      showNext,
      showSubmit,
    } = this.props;

    return (
      <Row>
        <Column xs={12}>
          <Block style={{ marginTop: 50 }} />
          <HorizontalRule />

          <Block clearfix>
            {showPrev && (
              <Button onClick={this.onClickPrevStep} disabled={disabled} warning pullLeft>
                <Glyphicon icon="chevron-left" small /> Back
              </Button>
            )}

            {showNext && (
              <Button type="submit" disabled={disabled} success pullRight>
                Next <Glyphicon icon="chevron-right" small />
              </Button>
            )}

            {showSubmit && (
              <Block>
                {createMode && (
                  <Button
                    type="submit"
                    disabled={disabled}
                    success
                    pullRight
                    data-test-handle="create trip submit"
                  >
                    Create Trip <Glyphicon icon="chevron-right" small />
                  </Button>
                )}

                {editMode && (
                  <Button type="submit" disabled={disabled} primary pullRight>
                    Save Trip
                  </Button>
                )}

                {addLegMode && (
                  <Button type="submit" disabled={disabled} primary pullRight>
                    Create Trip Leg
                  </Button>
                )}

                {showCancelTrip && (
                  <Button type="submit" disabled={disabled} danger pullRight>
                    Cancel Trip
                  </Button>
                )}
              </Block>
            )}
          </Block>
        </Column>
      </Row>
    );
  }
}

TripWizardFooter.propTypes = {
  addLegMode: PropTypes.bool,
  createMode: PropTypes.bool,
  editMode: PropTypes.bool,
  disabled: PropTypes.bool,
  goToNextStep: PropTypes.func.isRequired,
  goToPrevStep: PropTypes.func.isRequired,
  showNext: PropTypes.bool,
  showPrev: PropTypes.bool,
  showSubmit: PropTypes.bool,
  showCancelTrip: PropTypes.bool,
  values: PropTypes.object.isRequired,
};

export default TripWizardFooter;
