import * as React from 'react';
import {
  Box,
  Fade,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import Icon from 'rides/evo/components/Icon/Icon';
import { useActiveTripId } from 'rides/evo/store/tripList';
import { getTripId, getTripMemberId, useTrip } from 'rides/evo/utils/trips';
import { getTimezone } from 'rides/utils/dateTime';
import { EncounterPane } from './EncounterPane';
import { MemberPane } from './MemberPane';
import { NotesPane } from './NotesPane';
import { StatusTransitionPane } from './StatusTransitionPane';
import { PanelTab, PanelTabList, PanelTabPanel, PanelTabPanels, PanelTabs } from './Tabs';
import { getMemberName, useMember } from 'rides/evo/utils/members';

function PanelTitleCap({ ...props }) {
  return <Box width="30px" height="50px" pt="11px" px="7px" pb="23px" {...props} />;
}

function PanelTitle({ ...props }) {
  return (
    <Flex
      {...props}
      py="5px"
      px="16px"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    />
  );
}

function PanelHeader({ ...props }) {
  return <Box height="116px" {...props} />;
}

const closeIcon = <Icon w="16px" h="16px" name="close-x" color="general.darkBlue" />;

function EvoDetailPanel({ ...props }) {
  const timezone = getTimezone();
  const [activeTripId, setActiveTripId] = useActiveTripId();
  const hasActiveTripParam = Boolean(activeTripId);

  const { trip, error, isLoading: isLoadingTrip } = useTrip(activeTripId, {
    enabled: hasActiveTripParam,
  });
  const isLoaded = hasActiveTripParam && !isLoadingTrip;

  function handleCloseActiveTrip() {
    if (isLoaded) {
      // unset active trip
      setActiveTripId(null);
    }
  }

  if (error) {
    console.error('Error loading trip for detail panel.', {
      trip,
      error,
      hasActiveTripParam,
      activeTripId,
    });
  }

  const tripId = getTripId(trip);

  const memberId = getTripMemberId(trip);
  const { member } = useMember(memberId, { enabled: !!memberId });
  const titleText = getMemberName(member);

  if (!tripId || !member) {
    return null;
  }

  return (
    <Fade in={hasActiveTripParam} unmountOnExit>
      <Box
        bg="general.white"
        position="fixed"
        left="979px"
        top="72px"
        width="463px"
        height="calc(100% - 72px)"
        boxShadow="herMd"
        overflowY="overlay"
        overflowX="clip"
        {...props}
      >
        <PanelHeader>
          <PanelTitle>
            <PanelTitleCap data-id="dp-left-title-cap" />
            <Skeleton isLoaded={isLoaded}>
              <VStack spacing="0">
                <Text textStyle="detailPanel.headerTitleText">
                  {isLoaded ? titleText : 'Text For Skeleton Size'}
                </Text>
                <HStack spacing="3px">
                  <Text textStyle="detailPanel.headerSubTitleText">Trip ID</Text>
                  <Text textStyle="detailPanel.headerSubTitleTextBolder">
                    {isLoaded ? tripId : '4Skeleton'}
                  </Text>
                </HStack>
              </VStack>
            </Skeleton>
            <PanelTitleCap data-id="dp-right-title-cap">
              <IconButton
                onClick={handleCloseActiveTrip}
                aria-label="Close Detail Panel"
                icon={closeIcon}
                variant="ghost"
                disabled={!isLoaded}
              />
            </PanelTitleCap>
          </PanelTitle>

          {/* Detail Panel Tabs  */}
          <PanelTabs>
            <PanelTabList isLoaded={isLoaded}>
              <PanelTab label="Encounters" icon="encounter-details-xl" />
              <PanelTab label="Member Detail" icon="members-lg" />
              <PanelTab label="Service Requests" icon="service-requests-xl" />
              <PanelTab label="Notes Encounter" icon="notes-active-lg" />
              {/* <PanelTab label="Activity Log" icon="activity-log-xl" isDisabled /> */}
            </PanelTabList>
            <PanelTabPanels>
              <PanelTabPanel>
                <EncounterPane trip={trip} isLoaded={isLoaded} />
              </PanelTabPanel>
              <PanelTabPanel>
                <MemberPane member={member} isLoaded={isLoaded} />
              </PanelTabPanel>
              <PanelTabPanel>
                <StatusTransitionPane
                  tripId={tripId}
                  isTripLoaded={isLoaded}
                  timezone={timezone}
                />
              </PanelTabPanel>
              <PanelTabPanel>
                <NotesPane member={member} trip={trip} isLoaded={isLoaded} />
              </PanelTabPanel>
            </PanelTabPanels>
          </PanelTabs>
        </PanelHeader>
      </Box>
    </Fade>
  );
}

export { EvoDetailPanel };
