import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'rides/nui/Icon';
import { tenantType } from 'rides/types';
import {
  Block,
  Column,
  Glyphicon,
  LogoutButton,
  MenubarCategory,
  MenubarItem,
  MenubarTenant,
  Row,
  Strong,
  WebIcon,
} from 'rides/components';

const MenubarHeader = ({ userDisplayName }) => (
  <MenubarCategory>
    <Row>
      <Column xs={10}>
        <Block className="username">
          <Strong>{userDisplayName}</Strong>
        </Block>
      </Column>

      <Column xs={2}>
        <LogoutButton />
      </Column>
    </Row>
  </MenubarCategory>
);

MenubarHeader.propTypes = {
  userDisplayName: PropTypes.string,
};

const NuiMenuIcon = ({ ...props }) => (
  <Icon
    fontSize={16}
    pr={2}
    className="site-menu-icon wb-dashboard"
    css={{
      '&:before': { content: 'none' },
      svg: { margin: '0 auto', display: 'inline' },
      '.site-menubar-fold &&': {
        fontSize: '28px !important',
        paddingRight: 0,
        display: 'inline-block',
      },
    }}
    {...props}
  />
);

const Menubar = ({
  onChangeTenant,
  canChangeTenant,
  tenant,
  userDisplayName,
  showAdminUI,
  showStaffUI,
  hideForHumana,
  hideTripList,
}) => {
  const showOldSidebarItems = showAdminUI || showStaffUI;
  const showNewTodayTripListItem = showAdminUI || showStaffUI;
  const showLegacyUI = true;


  // tenant required for the urls
  if (!tenant) return null;

  const tenantLink = to => `/t/${tenant.id}${to}`;

  return (
    <Block
      className="site-menubar"
      style={{
        minHeight: 'fit-content',
        overflow: 'hidden',
      }}
    >
      <Block className="site-menubar-body">
        <Block>
          <ul className="site-menu">
            <MenubarHeader userDisplayName={userDisplayName} />

            <MenubarTenant
              tenant={tenant}
              canChangeTenant={canChangeTenant}
              onChangeTenant={onChangeTenant}
            />

            {!hideTripList && (
              <MenubarItem
                title="Trips"
                linkTo={tenantLink('/evo/trips?status=')}
                icon={<NuiMenuIcon name="trips" />}
              />
            )}

            {showNewTodayTripListItem && (
              <MenubarItem
                title="Today"
                linkTo={tenantLink('/evo/today-trips-assigned-to-me')}
                icon={<NuiMenuIcon name="appointment-date" />}
              />
            )}

            {!hideForHumana && (
              <MenubarItem
                title="Members"
                linkTo={tenantLink('/search')}
                icon={<NuiMenuIcon name="members" />}
              />
            )}

            {showAdminUI && (
              <React.Fragment>
                <MenubarCategory title="Admin Tools" />
                <MenubarItem
                  title="Users"
                  linkTo={tenantLink('/admin/user')}
                  icon={<Glyphicon icon="user" className="site-menu-icon" />}
                />
              </React.Fragment>
            )}

            {showLegacyUI && (
              <React.Fragment>
                <MenubarCategory title="Legacy" />
                {!hideTripList && (
                  <MenubarItem
                    title="Trips"
                    linkTo={tenantLink('/nui/trips?status=active')}
                    icon={<NuiMenuIcon name="trips" />}
                  />
                )}
                {showNewTodayTripListItem && (
                  <MenubarItem
                    title="Today"
                    linkTo={tenantLink('/today-trips-assigned-to-me')}
                    icon={<NuiMenuIcon name="appointment-date" />}
                  />
                )}
              </React.Fragment>
            )}

          </ul>
        </Block>
      </Block>
    </Block>
  );
};

Menubar.propTypes = {
  canChangeTenant: PropTypes.bool,
  onChangeTenant: PropTypes.func.isRequired,
  tenant: tenantType,
  userDisplayName: PropTypes.string.isRequired,
  showAdminUI: PropTypes.bool.isRequired,
  showStaffUI: PropTypes.bool.isRequired,
  hideForHumana: PropTypes.bool.isRequired,
  hideTripList: PropTypes.bool.isRequired,
};

export default Menubar;
