/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'ExpandTwoIcon',
  path: (
    <g fill="currentColor">
      <g stroke-linecap="round" stroke-linejoin="round" clip-path="url(#clip0)">
        <path d="M13-3.5h6.5V3M-3.5 13v6.5H3" />
        <path
          stroke-width="1.5"
          d="M14 2L9.043 6.957M6.957 9.043L2 14M10.61 2H14v3.391M2 10.609v3.39h3.391"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h16v16H0z" transform="rotate(-180 8 8)" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 16 16',
});
