import { useQuery } from 'react-query';
import * as R from 'ramda';

import { client } from 'rides/evo/utils/api-client';

const extractVendorIdNameMap = R.pipe(
  R.indexBy(R.prop('id')),
  R.pluck('name'),
);

function useVendorNames() {
  return useQuery(['vendor/id-name-map'], () =>
    client('vendor').then(resp => {
      const vendorList = R.path(['data', 'data'], resp);
      const vendorIdNameMap = extractVendorIdNameMap(vendorList);
      return vendorIdNameMap;
    }),
  );
}

export { useVendorNames };
