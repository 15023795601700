/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'DragAndDropIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M2.58 1A1.58 1.58 0 001 2.58v6.323c0 .873.708 1.58 1.58 1.58h.96c.374 0 .67-.302.67-.677a.671.671 0 00-.67-.677h-.96a.226.226 0 01-.225-.226V2.581c0-.125.1-.226.226-.226H8.903c.125 0 .226.1.226.226V3.54c0 .374.303.67.677.67a.671.671 0 00.678-.67V2.581A1.58 1.58 0 008.904 1H2.58zm4.517 4.516a1.58 1.58 0 00-1.58 1.58v6.323c0 .873.707 1.581 1.58 1.581h6.322A1.58 1.58 0 0015 13.42V7.096a1.58 1.58 0 00-1.58-1.58H7.096zm-.226 1.58c0-.124.101-.225.226-.225h6.322c.125 0 .226.101.226.226v6.322a.226.226 0 01-.226.226H7.097a.226.226 0 01-.226-.226V7.097z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
