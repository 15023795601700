import React, { Component } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import { MemberSearch } from 'rides/components';

function nullIfEmpty(value) {
  const trimmedValue = `${value}`.trim();
  const isValueEmpty = trimmedValue === '';
  const safeValue = isValueEmpty ? null : value;

  return safeValue;
}

class MemberSearchContainer extends Component {
  static propTypes = {
    search: PropTypes.func,
  };

  state = {
    criteria: {},
  };

  setCriteria = (field, value) => {
    this.setState(prevState => ({
      criteria: {
        ...prevState.criteria,
        [field]: value,
      },
    }));
  };

  handleCriteriaChange = (field, value) => {
    const safeValue = nullIfEmpty(value);
    this.setState(prevState => R.assocPath(['criteria', field], safeValue, prevState));
  };

  handleSubmitSearchID = event => {
    event.preventDefault();
    const { external_id } = this.state.criteria;
    this.props.search({ external_id });
  };

  handleSubmitSearchDOB = event => {
    event.preventDefault();
    const { dateOfBirth, firstName, lastName } = this.state.criteria;
    this.props.search({ dateOfBirth, firstName, lastName });
  };

  render() {
    const { criteria } = this.state;

    return (
      <MemberSearch
        criteria={criteria}
        onCriteriaChange={this.handleCriteriaChange}
        onSubmitSearchDOB={this.handleSubmitSearchDOB}
        onSubmitSearchID={this.handleSubmitSearchID}
      />
    );
  }
}

export default MemberSearchContainer;
