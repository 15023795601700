import { push as routerPush, replace as routerReplace } from 'connected-react-router';
import { Spinner } from 'evergreen-ui';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useGetTenantUrl } from 'rides/hooks/use-tenant';
import { Flex, Text } from 'rides/nui';
import Card from 'rides/nui/Card';
import { resourceDetailReadRequest } from 'rides/store/actions';
import { fromApp } from 'rides/store/selectors';
import * as ridesTypes from 'rides/types';

const mapDispatchToProps = dispatch => ({
  fetchTrip: tripId => dispatch(resourceDetailReadRequest(`trip`, tripId, 'trip')),
  routerPush: (...args) => dispatch(routerPush(...args)),
  routerReplace: (...args) => dispatch(routerReplace(...args)),
});

const mapStateToProps = (state, props) => ({
  memberId: fromApp.member.currentMemberId(state, props),
  tripId: fromApp.trip.currentTripId(state, props),
  trip: fromApp.trip.trip(state, props),
});

const NuiTripRedirectToTripGroupPage = ({
  fetchTrip,
  memberId,
  tripId,
  routerReplace,
  trip,
}) => {
  const getTenantUrl = useGetTenantUrl();
  const [isDelayDone, setDelayDone] = React.useState(false);
  const delayMs = 1500;

  React.useEffect(() => {
    // load trip on mount
    fetchTrip(tripId);

    // show page for a min amt of time => prefent page flash
    setDelayDone(false);
    setTimeout(() => setDelayDone(true), delayMs);
  }, [tripId]);

  React.useEffect(() => {
    if (isDelayDone && trip) {
      // console.log('trip loaded', { trip });
      const tripId = trip.id;
      const tripGroupId = trip.groupId;
      const tripGroupUrl = getTenantUrl(
        `/member/${memberId}/trip-group/${tripGroupId}#leg-${tripId}`,
      );

      // console.log(`redirecting to: ${tripGroupUrl}`);
      routerReplace(tripGroupUrl);
    }
  }, [isDelayDone, trip]);

  return (
    <Flex alignItems="center" justifyContent="center">
      <Card alignItems="center" padding={40}>
        <Flex alignItems="center" justifyContent="center">
          <Spinner />
          <Text textAlign="center" fontSize={3} color="mediumGray" pl={4}>
            Redirecting to trip...
          </Text>
        </Flex>
      </Card>
    </Flex>
  );
};

NuiTripRedirectToTripGroupPage.propTypes = {
  memberId: ridesTypes.memberIdType.isRequired,
  tripId: ridesTypes.tripIdType.isRequired,
  fetchTrip: PropTypes.func.isRequired,
  routerReplace: PropTypes.func.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(NuiTripRedirectToTripGroupPage);
