import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import * as ridesTypes from 'rides/types';

import CurrentTimezone from 'rides/nui/containers/CurrentTimezone';
import {
  Column,
  Block,
  DatePicker,
  FormFeedbackBox,
  FormikField,
  HorizontalRule,
  Row,
  TripWizardFooter,
  TripWizardLocationFields,
  TripWizardStepTitle,
} from 'rides/components';
import { TripPurposeSelectField, TripTypeSelectField } from 'rides/containers';

const TripWizardStepDestination = props => (
  <Formik
    initialValues={props.values}
    validationSchema={Yup.object().shape({
      destination_isHome: Yup.boolean(),
      destination_isRoundTrip: Yup.boolean(),
      destination_name: Yup.string(),
      destination_phoneNumber: Yup.string(),
      destination_address1: Yup.string().when(['destination_isHome'], {
        is: isHome => !isHome,
        then: Yup.string().required('Address is required.'),
      }),
      destination_address2: Yup.string(),
      destination_city: Yup.string().when(['destination_isHome'], {
        is: isHome => !isHome,
        then: Yup.string().required('City required.'),
      }),
      destination_state: Yup.string().when(['destination_isHome'], {
        is: isHome => !isHome,
        then: Yup.string().required('State required.'),
      }),
      destination_postalCode: Yup.string().when(['destination_isHome'], {
        is: isHome => !isHome,
        then: Yup.string()
          // .required('Zip Code is required.')
          .matches(/^\d{5}(?:[-\s]\d{4})?$/, 'Must be 5 or 9 digits'),
      }),
      reservationDate: Yup.string().required('Date is required.'),
      appointmentTime: Yup.string()
        // .required('Appointment Time is required.')
        .matches(/^\d{1,2}:\d{2} a|pm{1}$/, 'Must be (hh:mm am) format.'),
      purposeId: Yup.string().required('Purpose is required.'),
      typeId: Yup.string().required('Type is required.'),
    })}
    onSubmit={(values, formikBag) => {
      // formikBag.setStatus({ error: transformErrorToMessage(error) });
      formikBag.setSubmitting(false);
      props.goToNextStep(values);
    }}
    render={formikProps => {
      const disabled = !!formikProps.isSubmitting || props.formDisabled;

      return (
        <Form>
          <Row>
            <Column xs={12} sm={10} smOffset={1}>
              <TripWizardStepTitle>Destination Location</TripWizardStepTitle>

              {!disabled && props.stepErrors.dropoff && (
                <FormFeedbackBox danger>{props.stepErrors.dropoff}</FormFeedbackBox>
              )}

              <TripWizardLocationFields
                fieldPrefix="destination_"
                disabled={disabled}
                maskMemberHome={formikProps.values.destination_isHome}
                member={props.member}
                showHome
              />

              <HorizontalRule style={{ marginBottom: 30, marginTop: 30 }} />

              <TripWizardStepTitle>Date and Time</TripWizardStepTitle>
              <Row>
                <Column xs={8} xsOffset={2}>
                  <Row>
                    <Column xs={12} sm={6}>
                      <Field
                        name="reservationDate"
                        label="Reservation Date"
                        component={FormikField}
                        disabled={disabled}
                        type={DatePicker}
                      />
                    </Column>

                    <Column xs={12} sm={6}>
                      <Field
                        name="appointmentTime"
                        label="Appt. Time (if applicable)"
                        component={FormikField}
                        disabled={disabled}
                        placeholder="hh:mm am"
                        // type="time"
                      />
                    </Column>
                  </Row>
                </Column>
              </Row>

              <Row>
                <Column xs={12} sm={6} smOffset={3}>
                  <Block textCenter>
                    Times are local to time zone{' '}
                    <i>
                      <CurrentTimezone />
                    </i>
                  </Block>
                </Column>
              </Row>

              <HorizontalRule style={{ marginBottom: 30, marginTop: 30 }} />

              <TripWizardStepTitle>Trip Purpose and Type</TripWizardStepTitle>
              <Row>
                <Column xs={6}>
                  <Field
                    name="purposeId"
                    label="Purpose"
                    component={FormikField}
                    type={TripPurposeSelectField}
                    disabled={disabled}
                  />
                </Column>

                <Column xs={6}>
                  <Field
                    name="typeId"
                    label="Type"
                    component={FormikField}
                    type={TripTypeSelectField}
                    disabled={disabled}
                  />
                </Column>
              </Row>
            </Column>
          </Row>

          <TripWizardFooter
            {...props}
            values={formikProps.values}
            disabled={disabled}
            showNext
            showPrev
          />
        </Form>
      );
    }}
  />
);

TripWizardStepDestination.propTypes = {
  currentStep: PropTypes.string,
  formDisabled: PropTypes.bool,
  stepErrors: PropTypes.object.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  goToPrevStep: PropTypes.func.isRequired,
  member: ridesTypes.memberType,
  values: PropTypes.object,
};

export default TripWizardStepDestination;
