import { LicensePlate, Link, PhoneNumber } from '@carecar-evo/core-ui';
import { useDisclosure, VStack } from '@chakra-ui/react';
import * as R from 'ramda';
import * as React from 'react';
import { removeEmpty } from 'rides/evo/utils/data';
import { getProviderDetailText } from 'rides/evo/utils/providers';
import {
  getTripId,
  useTripGroup,
  getTripLegAlpha,
  getTripGroupId,
  getTripGroupReferenceNumber,
} from 'rides/evo/utils/trips';
import { isStr } from 'rides/utils/data';
import { tripStatus } from 'rides/utils/trip';
import DateTime from '../../pages/TripListPage/DateTime';
import { PaneSectionHeader, PaneSectionTable } from './PaneSection';

const getTripTookanTaskData = trip => {
  const tookanTask = R.path(['tookan_task'], trip);
  if (!tookanTask) return null;

  const deliveryJobId = R.path(['delivery_job_id'], tookanTask);
  const deliveryTrackingLink = R.path(['delivery_tracking_link'], tookanTask);
  const pickupJobId = R.path(['pickup_job_id'], tookanTask);
  const pickupTrackingLink = R.path(['pickup_tracking_link'], tookanTask);

  return {
    deliveryJobId,
    deliveryTrackingLink,
    pickupJobId,
    pickupTrackingLink,
  };
};

function getTripTookanMapUrl(trip) {
  const doPickupLink =
    trip?.status === tripStatus.SCHEDULED ||
    trip?.status === tripStatus.VENDOR_ASSIGNED ||
    trip?.status === tripStatus.DRIVER_ASSIGNED ||
    trip?.status === tripStatus.DRIVER_EN_ROUTE ||
    trip?.status === tripStatus.DRIVER_ARRIVED;

  const doDeliveryLink =
    trip?.status === tripStatus.IN_TRANSIT || trip?.status === tripStatus.COMPLETED;

  // status that doesn't use tookan map link
  if (!doPickupLink && !doDeliveryLink) return null;

  const taskData = getTripTookanTaskData(trip);
  if (!taskData) return null;

  if (doPickupLink) {
    return taskData.pickupTrackingLink;
  }

  if (doDeliveryLink) {
    return taskData.deliveryTrackingLink;
  }

  return null;
}

function EncounterPane({ trip, isLoaded, ...props }) {
  const tripId = getTripId(trip);
  const tripGroupId = getTripGroupId(trip)
  const tripGroupRefNum = getTripGroupReferenceNumber(trip)

  const {
    refetch: refetchTripGroup,
    tripGroup,
    // error,
    isLoading: isLoadingTripGroup,
    // isError,
    // isSuccess,
  } = useTripGroup(tripGroupId);

  const tripLegAlpha = getTripLegAlpha(trip);
  const tripType = trip?.type?.name;

  const summarySection = useDisclosure({ defaultIsOpen: true });
  const mapSection = useDisclosure({ defaultIsOpen: true });
  const tripDetailsSection = useDisclosure({ defaultIsOpen: true });

  const driverName = R.pathOr('', ['custom_driver_name'], trip);
  const driverPhone = R.pathOr('', ['custom_driver_phone'], trip);
  const driverCarLicense = R.pathOr('', ['custom_driver_car_license'], trip);
  const driverCarDescription = R.pathOr('', ['custom_driver_car_description'], trip);
  const vendorName = getProviderDetailText(trip?.vendor);

  // MAP URL LINK
  // const member = R.pathOr({}, ['member'], trip);
  const tookanMapUrl = getTripTookanMapUrl(trip);
  const tripMapUrl = R.prop('map_url', trip);
  const mapUrl = tookanMapUrl || tripMapUrl;
  const safeMapUrl = `${isStr(mapUrl) ? mapUrl : ''}`.trim();

  // Trip Pickup
  const pickupTime = R.path(['pickup_time'], trip);
  const tripPickup = R.pathOr({}, ['pickup'], trip);
  const safePickup = removeEmpty(tripPickup || {});

  const pickupName = R.path(['pickup', 'name'], trip);
  const pickupAddress = R.compose(
    R.join(', '),
    removeEmpty,
  )([
    safePickup.address1,
    safePickup.address2,
    safePickup.city,
    `${safePickup.state} ${safePickup.postal_code}`,
  ]);

  // Trip Dropoff
  const appointmentTime = R.path(['appointment_time'], trip);
  // const hasAppointmentTime = isValidIsoDateTime(appointmentTime);
  // const dropoffTime = R.path(['appointment_time'], trip);
  // const hasDropoffTime = isValidIsoDateTime(dropoffTime);
  const tripDropoff = R.pathOr({}, ['dropoff'], trip);
  const safeDropoff = removeEmpty(tripDropoff || {});

  const dropoffName = R.path(['dropoff', 'name'], trip);
  const dropoffPhone = R.path(['dropoff', 'phone_number'], trip);

  const dropoffAddress = R.compose(
    R.join(', '),
    removeEmpty,
  )([
    safeDropoff.address1,
    safeDropoff.address2,
    safeDropoff.city,
    `${safeDropoff.state} ${safeDropoff.postal_code}`,
  ]);

  // Trip Details
  const purpose = R.path(['purpose', 'name'], trip);

  // Companion Details
  // const companionId = R.path(['companion', 'id'], trip);
  const companionName = R.path(['companion', 'name'], trip);
  const companionPhone = R.path(['companion', 'phone_number'], trip);
  const companionPhoneType = R.path(['companion', 'phone_number_type'], trip);
  const companionRelationship = R.path(['companion', 'relationship'], trip);

  return (
    <>
      <VStack
        px="24px"
        py="16px"
        borderTopWidth="1px"
        borderTopColor="lightGrey.100"
        borderBottomWidth="1px"
        borderBottomColor="lightGrey.100"
        spacing="5px"
        alignItems="stretch"
      >
        <PaneSectionHeader
          title="Summary"
          isOpen={summarySection.isOpen}
          onOpen={summarySection.onOpen}
          onClose={summarySection.onClose}
        />
        <PaneSectionTable
          isOpen={summarySection.isOpen}
          rowData={[
            ['TRIP ID', `${tripId}`, !isLoaded],
            ['LEG', `${tripLegAlpha}`, !isLoaded],
            ['REFERENCE NUMBER', `${tripGroupRefNum}`, !isLoaded],
            ['SERVICE LEVEL', tripType, !isLoaded],
            ['VENDOR', vendorName, !isLoaded],
            ['SERVICE PROVIDER', driverName, !isLoaded],
            [
              'SERVICE PROVIDER PHONE NUMBER ',
              <PhoneNumber phoneNumber={driverPhone} />,
              !isLoaded,
            ],
            ['SERVICE PROVIDER CAR DESCRIPTION ', driverCarDescription, !isLoaded],
            [
              'SERVICE PROVIDER CAR LICENSE PlATE ',
              <LicensePlate plateNumber={driverCarLicense} />,
              !isLoaded,
            ],
          ]}
        />
      </VStack>

      <VStack
        spacing="0px"
        // borderTopWidth="1px"
        // borderTopColor="lightGrey.100"
        px="24px"
        py="16px"
        borderBottomWidth="1px"
        borderBottomColor="lightGrey.100"
        alignItems="stretch"
      >
        <PaneSectionHeader
          title="Map"
          isOpen={mapSection.isOpen}
          onOpen={mapSection.onOpen}
          onClose={mapSection.onClose}
          hideCollapseToggle={true}
        />
        <PaneSectionTable
          isOpen={mapSection.isOpen}
          rowData={[
            [
              !!safeMapUrl ? (
                <Link href={safeMapUrl} target="_blank" disabled={!safeMapUrl}>
                  TRIP MAP LINK
                </Link>
              ) : (
                '--'
              ),
            ],
          ]}
        />
      </VStack>

      <VStack
        spacing="0px"
        // borderTopWidth="1px"
        // borderTopColor="lightGrey.100"
        px="24px"
        py="16px"
        borderBottomWidth="1px"
        borderBottomColor="lightGrey.100"
        alignItems="stretch"
      >
        <PaneSectionHeader
          title="Trip Details"
          isOpen={tripDetailsSection.isOpen}
          onOpen={tripDetailsSection.onOpen}
          onClose={tripDetailsSection.onClose}
        />
        <PaneSectionTable
          isOpen={tripDetailsSection.isOpen}
          rowData={[
            ['PICK UP LOCATION NAME', pickupName, !isLoaded],
            ['PICK UP LOCATION', pickupAddress, !isLoaded],
            [
              'PICK UP TIME',
              <DateTime datetime={pickupTime} format="h:mm A" />,
              !isLoaded,
            ],
            ['DESTINATION NAME', dropoffName, , !isLoaded],
            ['MEDICAL SPECIALTY / TYPE', purpose, !isLoaded],
            ['DESTINATION', dropoffAddress, !isLoaded],
            ['APPT TIME', appointmentTime, !isLoaded],
            ['DESTINATION CONTACT', dropoffPhone, !isLoaded],
            ['COMPANION', companionName, !isLoaded],
            ['COMPANION RELATIONSHIP', companionRelationship, !isLoaded],
            [
              'COMPANION CONTACT ',
              `${companionPhoneType ?? ''} ${companionPhone ?? ''}`,
              !isLoaded,
            ],
          ]}
        />
      </VStack>
    </>
  );
}

export { EncounterPane };
