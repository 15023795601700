import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { datetimeType, tripEstimateType } from 'rides/types';
import { Well, Block, Button, DateTime, Distance, Strong } from 'rides/components';
import { convertToReadableTime } from 'rides/utils/dateTime';

const PickupTimeText = ({ dateTime }) => (
  <Block>
    <Block style={{ fontSize: '14pt' }}>The suggested pickup time is</Block>
    <Block style={{ fontSize: '32pt' }} data-test-handle="suggested pickup time">
      {convertToReadableTime(dateTime)}
    </Block>
  </Block>
);
PickupTimeText.propTypes = { dateTime: datetimeType.isRequired };

const DistanceText = ({ distance }) => {
  return (
    <Block>
      based on a distance of{' '}
      <Strong data-test-handle="estimated distance">
        <Distance distance={distance} />
      </Strong>
    </Block>
  );
};
DistanceText.propTypes = { distance: PropTypes.number.isRequired };

const TimeText = ({ timeInSeconds }) => (
  <Block>
    with a travel time of{' '}
    <Strong data-test-handle="travel time">
      {`${Math.ceil(timeInSeconds / 60)} minutes`}
    </Strong>
  </Block>
);
TimeText.propTypes = { timeInSeconds: PropTypes.number.isRequired };

const AppointmentTimeText = ({ appointmentTime }) => (
  <Block>
    for an appointment time of{' '}
    <Strong data-test-handle="appointment time">
      <DateTime showDate={false} showTime datetime={appointmentTime} />
    </Strong>
  </Block>
);
AppointmentTimeText.propTypes = { appointmentTime: datetimeType.isRequired };

class TripWizardPickupTimeSuggestion extends Component {
  static propTypes = {
    appointmentTime: datetimeType.isRequired,
    estimate: tripEstimateType.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  handleClick = () => {
    const { onClick, estimate } = this.props;
    onClick(convertToReadableTime(estimate.pickupTime));
  };

  render() {
    const { estimate, appointmentTime } = this.props;

    return (
      <Well className="text-center">
        <Block>
          <PickupTimeText dateTime={estimate.pickupTime} />
          <DistanceText distance={estimate.distance} />
          <TimeText timeInSeconds={estimate.estimate} />
          <AppointmentTimeText appointmentTime={appointmentTime} />
        </Block>

        <Block style={{ marginTop: '10px' }}>
          <Button primary onClick={this.handleClick}>
            Enter Another Time
          </Button>
          <Button success onClick={this.handleClick}>
            Use This Time
          </Button>
        </Block>
      </Well>
    );
  }
}

export default TripWizardPickupTimeSuggestion;
