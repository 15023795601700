/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'ArrowCircleRightIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M14.75 8a6.75 6.75 0 11-13.5 0 6.75 6.75 0 0113.5 0zM16 8A8 8 0 110 8a8 8 0 0116 0zM7.47 5.53l1.72 1.72H4v1.5h5.19l-1.72 1.72 1.06 1.06 3-3a.75.75 0 000-1.06l-3-3-1.06 1.06z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
