import React from 'react';

import { AuthPageTemplate, Block, Button, Link, Paragraph } from 'rides/components';

const loginForAccess = () => (
  <Block>
    <Paragraph textCenter className="lead">
      Please log in below to access site.
    </Paragraph>

    <Block textCenter>
      <Button to="/logout" primary large>
        Log In
      </Button>
    </Block>
  </Block>
);

const AuthForbiddenPage = () => <AuthPageTemplate>{loginForAccess()}</AuthPageTemplate>;

export default AuthForbiddenPage;
