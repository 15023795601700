/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'AttachmentIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M14.146 1.828C13.572 1.27 12.72 1 11.88 1c-.84 0-1.692.271-2.266.828l-6.49 6.29C2.37 8.85 2 9.939 2 11.015c0 1.077.37 2.165 1.124 2.896.755.732 1.877 1.09 2.988 1.09 1.11 0 2.232-.358 2.987-1.09l5.408-5.242a.622.622 0 000-.899.67.67 0 00-.927 0l-5.408 5.242c-.508.492-1.278.746-2.06.746-.783 0-1.553-.254-2.06-.745-.508-.492-.77-1.24-.77-1.998s.262-1.505.77-1.997l6.489-6.29c.327-.318.827-.483 1.339-.483s1.012.165 1.34.482c.326.317.497.802.497 1.299 0 .496-.17.98-.498 1.298l-5.768 5.591c-.147.143-.377.221-.618.221-.242 0-.472-.078-.619-.22a.837.837 0 01-.227-.6c0-.234.08-.456.228-.599L10.9 5.173a.622.622 0 000-.899.67.67 0 00-.927 0L5.287 8.818c-.394.382-.583.945-.583 1.497 0 .553.19 1.116.583 1.498.394.382.976.566 1.546.566.57 0 1.15-.184 1.545-.566l5.768-5.592C14.72 5.665 15 4.84 15 4.025c0-.815-.28-1.64-.854-2.197z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
