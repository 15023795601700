/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'EditIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M11.598 1.228a.778.778 0 011.1 0l2.074 2.074a.778.778 0 010 1.1l-10.37 10.37a.778.778 0 01-.55.228H1.778A.778.778 0 011 14.222v-2.074c0-.206.082-.404.228-.55l10.37-10.37zm-.943 3.142l.975.974 1.492-1.492-.974-.974-1.492 1.492zm-.125 2.074l-.974-.974-7 7v.974h.974l7-7z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
