/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'SettingsLgIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M13.334 10c0 1.774-1.495 3.214-3.333 3.214-1.839 0-3.334-1.44-3.334-3.214 0-1.768 1.495-3.214 3.334-3.214 1.838 0 3.333 1.446 3.333 3.214zm6.585-1.215c-.124-.926-1.122-1.562-2.088-1.215-.422.148-.93-.07-1.12-.482a6.964 6.964 0 00-.74-1.24c-.287-.387-.232-.894.128-1.177.366-.296.57-.72.561-1.177a1.496 1.496 0 00-.623-1.176 10.144 10.144 0 00-2.156-1.202A1.685 1.685 0 0013.265 1c-.762 0-1.406.52-1.53 1.24-.076.444-.495.746-.998.695a7.06 7.06 0 00-1.474 0c-.499.051-.922-.25-.998-.694C8.14 1.52 7.497 1 6.735 1c-.212 0-.419.039-.616.116-.766.315-1.492.713-2.156 1.202a1.496 1.496 0 00-.623 1.176c-.01.457.195.887.561 1.177.36.283.415.79.128 1.176a6.96 6.96 0 00-.74 1.24.901.901 0 01-1.129.477 1.567 1.567 0 00-.541-.097C.843 7.467.18 8.04.08 8.791a9.015 9.015 0 000 2.418c.124.925 1.124 1.562 2.088 1.215.422-.148.93.077 1.12.482.202.437.452.855.74 1.24.287.386.232.894-.128 1.177-.366.296-.57.72-.561 1.176.01.463.237.894.623 1.177.664.488 1.39.887 2.156 1.202.197.077.404.122.615.122.763 0 1.407-.527 1.53-1.247.077-.444.496-.74 1-.694.484.051.988.051 1.473 0 .498-.045.922.25.998.694.124.72.768 1.247 1.53 1.247.212 0 .419-.045.616-.122a10.143 10.143 0 002.156-1.202c.386-.283.613-.714.623-1.177a1.456 1.456 0 00-.561-1.176c-.36-.283-.415-.79-.128-1.177.288-.385.537-.803.74-1.24a.908.908 0 011.129-.476c.175.064.357.096.541.096.777 0 1.438-.572 1.538-1.317a9.263 9.263 0 000-2.424z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
});
