import PropTypes from 'prop-types';
import React from 'react';

import { useTenantUrl } from 'rides/hooks/use-tenant';
import * as ridesTypes from 'rides/types';
import { InlineFlex, Link, Text } from 'rides/nui';

export const TripGroupDepth = ({ trip }) => {
  const asciiStart = 65; // => ascii code for 'A'
  return String.fromCharCode(asciiStart + trip.groupDepth);
};

TripGroupDepth.propTypes = {
  trip: ridesTypes.tripType.isRequired,
};

export const TripGroupRootId = ({ trip }) => `${trip.rootId}`;

TripGroupRootId.propTypes = {
  trip: ridesTypes.tripType.isRequired,
};

export const TripGroupIdText = ({ trip }) => (
  <InlineFlex as={Text} lineHeight="20px">
    <TripGroupRootId trip={trip} />
    <Text pl={1} color="black">
      <TripGroupDepth trip={trip} />
    </Text>
  </InlineFlex>
);

TripGroupIdText.propTypes = {
  trip: ridesTypes.tripType.isRequired,
};

const TripGroupIdLink = ({ trip }) => {
  let linkTo = useTenantUrl(
    `/member/${trip.member.id}/trip-group/${trip.groupId}#leg-${trip.id}`,
  );

  return (
    <Link to={linkTo}>
      <TripGroupIdText trip={trip} />
    </Link>
  );
};

TripGroupIdLink.propTypes = {
  trip: ridesTypes.tripType.isRequired,
};

const TripGroupId = ({ trip, isLink = false }) => {
  if (isLink) return <TripGroupIdLink trip={trip} />;
  return <TripGroupIdText trip={trip} />;
};

TripGroupId.propTypes = {
  trip: ridesTypes.tripType.isRequired,
  isLink: PropTypes.bool,
};

export default TripGroupId;
