import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Small = ({ children, className, textMuted, ...props }) => {
  const classes = classNames(className, {
    'text-muted': textMuted,
  });
  return (
    <small {...props} className={classes}>
      {children}
    </small>
  );
};

Small.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  textMuted: PropTypes.bool,
};

export default Small;
