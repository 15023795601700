import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { coerceToInt } from 'rides/utils/data';
import SecureOnRolesRoute from 'rides/components/utils/SecureOnRolesRoute';
import TenantRoutes from 'rides/components/utils/TenantRoutes';
import TenantAutoRedirectRoutes from 'rides/components/utils/TenantAutoRedirectRoute';

import { NotFoundPage, ServerErrorPage, UnauthorizedPage } from 'rides/components';
import {
  ActiveTripListPage,
  AuthLogOutPage,
  MemberDetailPage,
  MemberSearchPage,
  TripGroupDetailPage,
  TripGroupListPage,
} from 'rides/containers';
import TripListPage from 'rides/nui/containers/TripListPage';
import EvoTripListPage from 'rides/evo/pages/TripListPage';

import TripRedirectToTripGroupPage from 'rides/nui/containers/TripRedirectToTripGroupPage';
import TodayRedirectToTripList from 'rides/nui/containers/TodayRedirectToTripList';
import AdminRoutes from 'rides/admin-ui/routes';
import AuthLoginPage from './components/pages/AuthLoginPage';
import AuthLoginErrorPage from './components/pages/AuthLoginErrorPage';

const defaultSecuredRoles = ['call_center'];

const SecureRoute = props => (
  <SecureOnRolesRoute authorizedRoles={defaultSecuredRoles} {...props} />
);

const SecureAdminRoute = props => <SecureRoute {...props} authorizedRoles={[]} />;

const startPageUrl = '/evo/trips?status_group=active';

export function redirectToRemixApp() {
  const fromUri = encodeURIComponent(window.location.pathname + window.location.search);
  window.location.href = `/redirect?from=${fromUri}`;
}

function RootRedirectPage() {
  useEffect(() => {
    redirectToRemixApp();
  }, []);

  return null;
}

const routes = (
  <Switch>
    <Route path="/login" component={AuthLoginPage} exact />
    <Route path="/logout" component={AuthLogOutPage} exact />
    <Route path="/login-error" component={AuthLoginErrorPage} exact />
    <Route path="/" component={RootRedirectPage} exact />
    <SecureRoute
      path="/t/:tenantId"
      render={({
        match: {
          path,
          params: { tenantId },
        },
      }) => (
        <TenantRoutes
          tenantId={coerceToInt(tenantId)}
          render={({ hideForHumana, hideTripList }) => (
            <>
              {!hideTripList && (
                // only render __OLD__ Trip List page route when user hide_trip_list flag is false
                <SecureRoute path={`${path}/nui/trips`} component={TripListPage} exact />
              )}

              {!hideTripList && (
                // only render __EVO__ Trip List page route when user hide_trip_list flag is false
                <SecureRoute
                  path={`${path}/evo/trips`}
                  component={EvoTripListPage}
                  hideForHumana={hideForHumana}
                  exact
                />
              )}
              <Switch>
                {hideTripList && (
                  <Route
                    path={[
                      // trip list routes
                      `${path}/evo/trips`,
                      `${path}/nui/trips`,
                      `${path}/trips`,
                      `${path}/active-trips`,
                    ]}
                  >
                    <Redirect to={`/t/${tenantId}/search`} />
                  </Route>
                )}

                {/* Redirect /t/12345/ to /t/12345/evo/trips?status_group */}
                <Redirect from={path} exact to={`${path}${startPageUrl}`} />

                {hideForHumana && (
                  // This renders a Not Found page when these routes are
                  // visited by non-staff/admin user in Humana tenant
                  <Route
                    path={[
                      `${path}/search`,
                      `${path}/member`,
                      `${path}/trips`,
                      `${path}/active-trips`,
                    ]}
                    render={() => <NotFoundPage />}
                  />
                )}

                <Switch>
                  <SecureRoute
                    path={`${path}/search`}
                    component={MemberSearchPage}
                    exact
                  />

                  <SecureRoute
                    path={`${path}/today-trips-assigned-to-me`}
                    component={TodayRedirectToTripList}
                    exact
                  />

                  <SecureRoute
                    path={`${path}/evo/today-trips-assigned-to-me`}
                    component={TodayRedirectToTripList}
                    exact
                  />

                  <SecureRoute
                    path={`${path}/member/:memberId`}
                    component={MemberDetailPage}
                    exact
                  />
                  <SecureRoute
                    path={`${path}/member/:memberId/trip-group/:tripGroupId`}
                    component={TripGroupDetailPage}
                    exact
                  />
                  <SecureRoute
                    path={`${path}/trips`}
                    component={TripGroupListPage}
                    exact
                  />
                  <SecureRoute
                    path={`${path}/active-trips`}
                    component={ActiveTripListPage}
                    exact
                  />

                  <SecureRoute
                    path={`${path}/member/:memberId/trip/:tripId`}
                    component={TripRedirectToTripGroupPage}
                    exact
                  />

                  <SecureAdminRoute path={`${path}/admin`} component={AdminRoutes} />
                </Switch>

                <Route component={NotFoundPage} />
              </Switch>
            </>
          )}
        />
      )}
    />
    <SecureRoute
      path={['/t/', '/evo', '/nui', '/search', '/member', '/trips', '/active-trips']}
      render={({ location }) => <TenantAutoRedirectRoutes location={location} />}
    />
    <Route path="/unauthorized" component={UnauthorizedPage} exact />
    <Route path="/server-error" component={ServerErrorPage} exact />
    <Route component={NotFoundPage} />
  </Switch>
);

export default routes;
