import { put, race, take } from 'redux-saga/effects';

import * as actions from './actions';

/**
 * Task Sagas
 **/
export function* confirmSaga({
  title,
  message,
  noButtonText,
  yesButtonText,
  danger,
  hideNoButton,
}) {
  // show confirm
  yield put(
    actions.confirmShow({
      title,
      message,
      danger,
      noButtonText,
      yesButtonText,
      hideNoButton,
    }),
  );

  // wait for confirm UI events before proceeding
  const { yes } = yield race({
    dismiss: take(actions.CONFIRM_DISMISS),
    no: take(actions.CONFIRM_ANSWER_NO),
    yes: take(actions.CONFIRM_ANSWER_YES),
  });

  // hide confirm
  yield put(actions.confirmHide());

  return !!yes;
}

/**
 * Watcher Sagas
 **/

/* NONE */

/**
 * Root Sagas
 **/

export default function*() {
  // NOTE: empty default export required for redux-modules autoloading sagas file
}
