import React from 'react';
// import PropTypes from 'prop-types';

import { Address, PageTemplate, Heading, Block, Strong, Widget } from 'rides/components';
import { ActivityLog } from 'rides/containers';

const StyleGuidePage = () => {
  return (
    <PageTemplate>
      <Block>
        <Heading level={2}>Style Guide</Heading>
      </Block>

      <Widget title="Activity Log">
        <ActivityLog />
      </Widget>

      <Widget title="Address">
        <Address
          location={{
            address1: '406 Borden Circle',
            address2: 'Apt #33',
            city: 'San Marcos',
            postalCode: '92069',
            state: 'CA',
          }}
        />
        <Strong>Do things</Strong>
      </Widget>
    </PageTemplate>
  );
};

export default StyleGuidePage;
