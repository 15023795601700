/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'CalendarLgIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M7.38 16a.339.339 0 00-.046-.004 1.335 1.335 0 01-1.333-1.333.333.333 0 10-.667 0c0 1.084.867 1.969 1.944 1.999a.306.306 0 00.056.005c1.095 0 1.987-.88 2-1.967v-.034a1.944 1.944 0 00-.889-1.623c.536-.366.889-.992.889-1.712a2 2 0 00-4 0 .333.333 0 00.667 0C6 10.597 6.599 10 7.334 10a1.34 1.34 0 011.333 1.36c0 .738-.61 1.36-1.333 1.36h-.833a.333.333 0 000 .667h.833c.745 0 1.33.569 1.333 1.296a1.33 1.33 0 01-1.287 1.316zM11.667 16a.333.333 0 000 .667h2.667a.333.333 0 000-.667h-1V9.667a.333.333 0 00-.569-.236l-1.333 1.333a.332.332 0 10.47.471l.765-.764V16h-1z"
        clip-rule="evenodd"
      />
      <mask id="a">
        <path
          fill-rule="evenodd"
          d="M19.333 17.667c0 .919-.747 1.666-1.666 1.666H2.333a1.669 1.669 0 01-1.666-1.666v-11h18.666v11zM17.667 2h-3V.333a.334.334 0 00-.667 0v3.334a.333.333 0 01-.667 0V2H6V.333a.333.333 0 00-.667 0v3.334a.333.333 0 01-.666 0V2H2.333A2.336 2.336 0 000 4.333v13.334A2.336 2.336 0 002.333 20h15.334A2.336 2.336 0 0020 17.667V4.333A2.336 2.336 0 0017.667 2z"
          clip-rule="evenodd"
        />
      </mask>
      <path
        fill-rule="evenodd"
        d="M19.333 17.667c0 .919-.747 1.666-1.666 1.666H2.333a1.669 1.669 0 01-1.666-1.666v-11h18.666v11zM17.667 2h-3V.333a.334.334 0 00-.667 0v3.334a.333.333 0 01-.667 0V2H6V.333a.333.333 0 00-.667 0v3.334a.333.333 0 01-.666 0V2H2.333A2.336 2.336 0 000 4.333v13.334A2.336 2.336 0 002.333 20h15.334A2.336 2.336 0 0020 17.667V4.333A2.336 2.336 0 0017.667 2z"
        clip-rule="evenodd"
      />
      <path
        d="M.667 6.667v-1h-1v1h1zm18.666 0h1v-1h-1v1zM14.667 2h-1v1h1V2zm-1.334 0h1V1h-1v1zM6 2H5v1h1V2zM4.667 2h1V1h-1v1zm13.666 15.667c0 .367-.3.666-.666.666v2a2.668 2.668 0 002.666-2.666h-2zm-.666.666H2.333v2h15.334v-2zm-15.334 0a.669.669 0 01-.666-.666h-2a2.669 2.669 0 002.666 2.666v-2zm-.666-.666v-11h-2v11h2zm-1-10h18.666v-2H.667v2zm17.666-1v11h2v-11h-2zM17.667 1h-3v2h3V1zm-2 1V.333h-2V2h2zm0-1.667C15.667-.403 15.07-1 14.333-1v2a.666.666 0 01-.666-.667h2zM14.333-1C13.597-1 13-.403 13 .333h2a.667.667 0 01-.667.667v-2zM13 .333V2h2V.333h-2zM13 2v1.667h2V2h-2zm0 1.667c0-.369.298-.667.667-.667v2C14.403 5 15 4.403 15 3.667h-2zM13.667 3c.368 0 .666.298.666.667h-2c0 .736.597 1.333 1.334 1.333V3zm.666.667V2h-2v1.667h2zm-1-2.667H6v2h7.333V1zM7 2V.333H5V2h2zM7 .333C7-.403 6.403-1 5.667-1v2A.667.667 0 015 .333h2zM5.667-1C4.93-1 4.333-.403 4.333.333h2A.667.667 0 015.667 1v-2zM4.333.333V2h2V.333h-2zm0 1.667v1.667h2V2h-2zm0 1.667C4.333 3.298 4.632 3 5 3v2c.736 0 1.333-.597 1.333-1.333h-2zM5 3c.368 0 .667.298.667.667h-2C3.667 4.403 4.264 5 5 5V3zm.667.667V2h-2v1.667h2zM4.667 1H2.333v2h2.334V1zM2.333 1A3.336 3.336 0 00-1 4.333h2C1 3.6 1.599 3 2.333 3V1zM-1 4.333v13.334h2V4.333h-2zm0 13.334A3.336 3.336 0 002.333 21v-2A1.336 1.336 0 011 17.667h-2zM2.333 21h15.334v-2H2.333v2zm15.334 0A3.336 3.336 0 0021 17.667h-2C19 18.4 18.401 19 17.667 19v2zM21 17.667V4.333h-2v13.334h2zm0-13.334A3.336 3.336 0 0017.667 1v2C18.4 3 19 3.599 19 4.333h2z"
        mask="url(#a)"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
});
