import * as R from 'ramda';

import { removeEmpty } from 'rides/utils/data';
import { initialState } from './selectors';
import * as action from './actions';

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case action.TRIP_INIT_ACTION_FORMS: {
      const actionForms = R.merge(initialState.actionForms, removeEmpty(payload));
      return R.merge(initialState, { actionForms });
    }

    case action.TRIP_CANCEL_TRIP_FOR_MEMBER_INIT: {
      return {
        ...initialState,
        cancelForm: {
          ...initialState.cancelForm,
          memberId: payload.memberId,
          tripId: payload.tripId,
        },
      };
    }

    default:
      return state;
  }
};
