// https://github.com/diegohaz/arc/wiki/Actions
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#entities
export const ENTITIES_RECEIVE = 'ENTITIES_RECEIVE';
export const ENTITIES_EMPTY_TYPES = 'ENTITIES_EMPTY_TYPES';
export const ENTITIES_EMPTY_ALL_TYPES = 'ENTITIES_EMPTY_ALL_TYPES';

export const entitiesReceive = (entities, syncToReactQueryCache = true) => ({
  type: ENTITIES_RECEIVE,
  payload: entities,
  meta: {
    syncToReactQueryCache,
  },
});

/**
 * Entities recieved from ReactQuery so we need to PREVENT the default behavior
 * of pushing new entity values to ReactQuery's query cache.
 */
export const entitiesReceiveFromReactQuerySync = entities =>
  entitiesReceive(entities, false);

/**
 * Remove all entities for one or more entity types.
 *
 * @arg {array} entityTypeList List of entity types (string) names
 */
export const entitiesEmptyTypes = (entityTypeList = []) => ({
  type: ENTITIES_EMPTY_TYPES,
  payload: {
    entityTypeList,
  },
});

/**
 * Remove entities for all entity types. An list of entity types that shouldn't
 * be emptied can be (optionally) passed.
 *
 * @arg {array} keepEntityTypeList List of entity types (string) names
 */
export const entitiesEmptyAllTypes = (keepEntityTypeList = null) => ({
  type: ENTITIES_EMPTY_ALL_TYPES,
  payload: {
    keepEntityTypeList,
  },
});
