import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { memberIdType, tripIdType, tripType } from 'rides/types';
import { tripStatus } from 'rides/utils/trip';
import { fromEntities, fromApp } from 'rides/store/selectors';
import TripStatusDropdown from 'rides/nui/TripStatusDropdown';

const mapStateToProps = (state, props) => {
  const trip = fromEntities.getDetail(state, 'trip', props.tripId);
  const memberId = R.path(['member', 'id'], trip);

  return {
    trip,
    memberId,

    // NOTE: below copied from old trip leg action buttons
    disableAssignDriver: !R.path(['permissions', 'canAssignDriver'], trip),
    disableAssignVendor: !R.path(['permissions', 'canAssignVendor'], trip),
    disableCancel: !R.path(['permissions', 'canCancel'], trip),
    disableComplete: !R.path(['permissions', 'canComplete'], trip),
    disableEdit: R.and(
      !R.path(['permissions', 'canEditLocation'], trip),
      !R.path(['permissions', 'canEditTime'], trip),
    ),
    disableConfirmVendor: R.or(
      R.path(['isVendorConfirmed'], trip),
      !R.path(['permissions', 'canConfirmVendor'], trip),
    ),
    disableDriverArrived: !R.path(['permissions', 'canSetDriverArrived'], trip),
    disableDriverEnRoute: !R.path(['permissions', 'canSetDriverEnRoute'], trip),
    disableDriverFailed: !R.path(['permissions', 'canSetDriverFailed'], trip),
    disableInTransit: !R.path(['permissions', 'canSetInTransit'], trip),
    disableResetStatus: !R.path(['permissions', 'canResetStatus'], trip),
    disableMarkBillable: !R.path(['permissions', 'canMarkBillable'], trip),
    disableMarkNonBillable: !R.path(['permissions', 'canMarkNonBillable'], trip),
    showStaffUI: fromApp.ui.showStaffUI(state, props),
  };
};

function filterTripActions({
  disableAssignDriver,
  disableAssignVendor,
  disableCancel,
  disableComplete,
  disableConfirmVendor,
  disableDriverArrived,
  disableDriverEnRoute,
  disableDriverFailed,
  disableInTransit,
  disableResetStatus,
  disableMarkBillable,
  disableMarkNonBillable,
  showStaffUI,
}) {
  const options = [
    // Billing Options
    showStaffUI && !disableMarkBillable && 'billable',
    showStaffUI && !disableMarkNonBillable && 'non-billable',

    // Scheduled Options
    showStaffUI && !disableAssignDriver && tripStatus.DRIVER_ASSIGNED,
    showStaffUI && !disableAssignVendor && tripStatus.VENDOR_ASSIGNED,
    showStaffUI && !disableConfirmVendor && tripStatus.VENDOR_CONFIRMED,

    // Active Options
    showStaffUI && !disableDriverEnRoute && tripStatus.DRIVER_EN_ROUTE,
    showStaffUI && !disableDriverArrived && tripStatus.DRIVER_ARRIVED,
    showStaffUI && !disableInTransit && tripStatus.IN_TRANSIT,
    showStaffUI && !disableDriverFailed && tripStatus.DRIVER_FAILED,

    // Reset/Finalize Options
    showStaffUI && !disableResetStatus && 'reset status',
    !disableCancel && tripStatus.CANCELLED,
    showStaffUI && !disableComplete && tripStatus.COMPLETED,
  ].filter(i => !!i);

  return options;
}

function NuiTripStatusDropdownContainer({
  memberId,
  tripId,
  trip,
  hideVendorNotConfirmed = false,
  disabled = false,
  ...props
}) {
  const [activeDialog, setActiveDialog] = useState(null);

  const handleChange = action => {
    console.log('setting action to:', action);
    setActiveDialog(action);
  };

  if (!trip) return null;

  return (
    <>
      <TripStatusDropdown
        memberId={memberId}
        tripId={tripId}
        trip={trip}
        onChange={handleChange}
        actionOptions={filterTripActions(props)}
        activeDialog={activeDialog}
        hideVendorNotConfirmed={hideVendorNotConfirmed}
        disabled={disabled}
      />
    </>
  );
}

NuiTripStatusDropdownContainer.propTypes = {
  // passed props
  tripId: tripIdType.isRequired,

  // connected props
  trip: tripType,
  memberId: memberIdType.isRequired,
  disableAssignDriver: PropTypes.bool,
  disableAssignVendor: PropTypes.bool,
  disableConfirmVendor: PropTypes.bool,
  disableCancel: PropTypes.bool,
  disableComplete: PropTypes.bool,
  disableDriverArrived: PropTypes.bool,
  disableDriverEnRoute: PropTypes.bool,
  disableDriverFailed: PropTypes.bool,
  disableInTransit: PropTypes.bool,
  disableResetStatus: PropTypes.bool,
  disableEdit: PropTypes.bool,
  disableMarkBillable: PropTypes.bool,
  disableMarkNonBillable: PropTypes.bool,
  showStaffUI: PropTypes.bool.isRequired,
  hideVendorNotConfirmed: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(NuiTripStatusDropdownContainer);
