/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'ConversationIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M1 2.875C1 1.84 1.895 1 3 1h10c1.105 0 2 .84 2 1.875v7.502c0 1.036-.895 1.875-2 1.875H9L6.735 14.68c-.527.565-1.512.35-1.718-.376l-.584-2.05H3c-1.105 0-2-.84-2-1.876V2.875zm3 2.11c0-.388.336-.703.75-.703h6.5c.414 0 .75.315.75.703 0 .389-.336.703-.75.703h-6.5c-.414 0-.75-.314-.75-.703zm0 3.282c0-.388.336-.703.75-.703h3.5c.414 0 .75.315.75.703 0 .388-.336.703-.75.703h-3.5c-.414 0-.75-.315-.75-.703z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
