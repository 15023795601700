import React from 'react';

import { ErrorPageTemplate, Atom, Paragraph, Link } from 'rides/components';

const NotFoundPage = () => (
  <ErrorPageTemplate heading={<Atom className="wb-warning" />}>
    <Paragraph>
      An error occurred while processing your request.<br />
      Please go back and try again or return to <Link to="/">the dashboard</Link>.
    </Paragraph>
  </ErrorPageTemplate>
);

export default NotFoundPage;
