import { useEffect } from 'react';
import { useQueryString } from 'rides/hooks/use-query-string';

function AuthLogOutPage() {
  const { query } = useQueryString();

  const returnTo = encodeURIComponent(query.returnTo ?? '/');

  useEffect(() => {
    window.location.href = `/api/v1/auth/logout?returnTo=${returnTo}`;
  }, [returnTo]);

  return null;
}

export default AuthLogOutPage;
