import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withProps } from 'recompose';
import { push as routerPush } from 'connected-react-router';

import { modalHide, resourceCreateRequest } from 'rides/store/actions';
import { CreateMemberModal } from 'rides/components';

const mapDispatchToProps = {
  createMember: member => resourceCreateRequest('member', { member }, 'member'),
  hideModal: () => modalHide('create-member'),
  redirectToMember: memberId => routerPush(`/member/${memberId}`),
};

const CreateMemberModalContainer = props => <CreateMemberModal {...props} />;

export default R.compose(
  withRouter,
  connect(null, mapDispatchToProps),
  withProps(props => ({
    onSubmitCallback: (member, formikBag) => {
      props.createMember(member).then(
        ({ data: createdMemberId }) => {
          props.hideModal();
          props.redirectToMember(createdMemberId);
        },
        error => {
          formikBag.setStatus({ error: error.message });
          formikBag.setSubmitting(false);
        },
      );
    },
  })),
)(CreateMemberModalContainer);
