import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { locationType, datetimeType } from 'rides/types';
import { fromApp } from 'rides/store/selectors';
import { TripWizardPickupTimeInput } from 'rides/components';
import { TripEstimate } from 'rides/containers';

const mapStateToProps = (state, props) => ({
  pickup: fromApp.tripWizard.apiTripData(state, props).pickup,
  dropoff: fromApp.tripWizard.apiTripData(state, props).dropoff,
  appointmentTime: fromApp.tripWizard.apiTripData(state, props).appointmentTime,
  isWillCall: fromApp.tripWizard.apiTripData(state, props).isWillCall,
  hideWillCall: fromApp.tripWizard.hideWillCall(state, props),
});

class TripWizardPickupTimePicker extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    pickup: locationType.isRequired,
    dropoff: locationType.isRequired,
    appointmentTime: datetimeType,
    isWillCall: PropTypes.boolean,
    hideWillCall: PropTypes.boolean,
  };

  state = {
    inputFocused: false,
  };

  handleInputFocusChange = inputFocused => this.setState({ inputFocused });

  handleChange = value => {
    const { name, setFieldValue } = this.props;
    setFieldValue(name, value);
  };

  handleSuggest = () => {
    this.handleChange('');
  };

  shouldRenderEstimate() {
    const { inputFocused } = this.state;
    const { pickup, dropoff, appointmentTime, value } = this.props;

    // Pickup time is already set, no need to get an estimate
    if (value) return false;
    // User might be typing a time in, don't hide the field yet
    if (inputFocused) return false;
    // These should be filled to access this page but will break the component if they aren't
    if (!pickup || !dropoff) return false;
    // An appointment time isn't required; if it isn't set, there is nothing to estimate!
    if (!appointmentTime) return false;

    return true;
  }

  renderEstimate() {
    const { pickup, dropoff, appointmentTime, value } = this.props;

    return (
      <TripEstimate
        pickup={pickup}
        dropoff={dropoff}
        appointmentTime={appointmentTime}
        onClick={this.handleChange}
        value={value}
        handleChange={this.handleChange}
        handleFocusChange={this.handleInputFocusChange}
      />
    );
  }

  renderTimeInput() {
    const { value, appointmentTime, hideWillCall } = this.props;

    return (
      <TripWizardPickupTimeInput
        value={value}
        appointmentTime={appointmentTime}
        handleChange={this.handleChange}
        handleFocusChange={this.handleInputFocusChange}
        handleSuggest={this.handleSuggest}
        hideWillCall={hideWillCall}
      />
    );
  }

  render() {
    if (this.shouldRenderEstimate()) {
      return this.renderEstimate();
    }

    return this.renderTimeInput();
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(TripWizardPickupTimePicker);
