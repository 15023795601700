/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'NotesEncounterXlIcon',
  path: (
    <g fill="currentColor">
      <g clip-path="url(#clip0)">
        <path
          fill-rule="evenodd"
          d="M11.79 0H1.684C.758 0 0 .818 0 1.818v12.728h1.684V1.818H11.79V0zm2.526 3.636H5.053c-.927 0-1.685.819-1.685 1.819v12.727c0 1 .758 1.818 1.685 1.818h9.263c.926 0 1.684-.818 1.684-1.818V5.455c0-1-.758-1.819-1.684-1.819zm0 14.546H5.053V5.455h9.263v12.727z"
          clip-rule="evenodd"
        />
        <rect
          width="13.214"
          height="13.214"
          x="10.143"
          y="10.143"
          stroke-width="1.714"
          rx="6.607"
        />
        <path d="M16.193 19l-1.203-3.92h-.031c.043.797.065 1.33.065 1.596V19h-.947v-4.997h1.443l1.182 3.821h.02l1.255-3.821h1.442V19h-.987v-2.365c0-.112 0-.24.003-.387.005-.145.02-.533.048-1.162h-.03L17.162 19h-.97z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h23v23H0z" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 23 23',
});
