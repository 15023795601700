import React from 'react';
import * as R from 'ramda';

import { activityLogType } from 'rides/types';
import { ucwords } from 'rides/utils/format';
import { DateTime, Small, Atom, Block, Link, Strong } from 'rides/components';
import { getTenantUrl } from 'rides/hooks/use-tenant';

export const ActionIdLink = ({ log }) => {
  const logType = R.path(['type'], log);
  const memberId = R.path(['member', 'id'], log);
  const tenantId = R.path(['tenantId'], log);

  if (logType === 'member') {
    const { member } = log;
    const hasMemberName = member.firstName && member.lastName;
    const memberExternalId = member.externalId;
    const memberName = `${member.firstName} ${member.lastName}`;

    return (
      <Link to={getTenantUrl(tenantId, `/member/${memberId}`)}>
        {hasMemberName ? `${memberName} (${memberExternalId})` : memberExternalId}
      </Link>
    );
  }

  const tripTypes = ['trip', 'trip_status_transition'];
  if (R.contains(logType, tripTypes)) {
    return (
      <Link to={getTenantUrl(tenantId, `/member/${memberId}/trip/${log.tripId}`)}>
        <Atom>{log.tripId}</Atom>
      </Link>
    );
  }

  return null;
};

export const actionName = (action = '') => {
  const name = action.split('.')[0];
  if (!name) return null;
  return R.pipe(
    R.replace(/\_/g, ' '),
    ucwords,
  )(name);
};

export const actionTarget = (action = '') => {
  const target = action.split('.')[1];
  if (!target) return null;
  return target.replace(/_/g, ' ');
};

const ActionText = ({ log }) => {
  return (
    <Atom>
      {ucwords(log.type)} <ActionIdLink log={log} /> {actionName(log.action)}{' '}
      <Strong>{actionTarget(log.action)}</Strong>
    </Atom>
  );
};

ActionText.propTypes = {
  log: activityLogType.isRequired,
};

function DetailText({ label, value, convertDateTime }) {
  const hasValue = !!value;
  if (!hasValue) return null;

  return (
    <Small>
      {` (${label}: `}
      {convertDateTime && <DateTime datetime={value} />}
      {!convertDateTime && value}
      {' )'}
    </Small>
  );
}

function AppointmentReminderLogItem({ log }) {
  // Appointment Reminder Log Data
  // const arId = R.path(['data', 'appointmentReminderId'], log);
  const arError = R.path(['data', 'error'], log);
  const arDate = R.path(['data', 'reminderDate'], log);
  const arRecipientPhone = R.path(['data', 'recipientPhoneNumber'], log);
  const arSentAt = R.path(['data', 'sentAt'], log);

  // const arData = R.path(['data'], log);
  // console.log('arData', arData);

  return (
    <Block>
      <ActionText log={log} />
      <DetailText label="Error" value={arError} />
      <DetailText label="Reminder Date" value={arDate} />
      <DetailText label="Recipient" value={arRecipientPhone} />
      <DetailText label="Sent At" value={arSentAt} convertDateTime />
    </Block>
  );
}

export default AppointmentReminderLogItem;
