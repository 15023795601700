import { rgba } from 'polished';

/**
 * CareCar DS Colors
 */
export const ccdsColors = {
  // --
  // -- CORE
  // --
  general: {
    activeOrange: '#fe7c4b',
    darkBlue: '#171f46',
    blue: '#b5b7c4',
    white: '#ffffff',
    blueDormant: '#b0b4ba',
  },
  main: {
    default: '#0b69ff',
    pressed: '#0055dc',
    hover: '#0058e3',
    hoverLight: rgba('#0b69ff', 0.1),
  },
  grey: {
    100: '#7e858e',
    60: rgba('#7e858e', 0.6),
    40: rgba('#7e858e', 0.4),
    4: rgba('#7e858e', 0.04),
    bg: '#f6f6f6',
  },
  lightGrey: {
    100: '#d7dfe9',
    50: rgba('#d7dfe9', 0.5),
    40: rgba('#d7dfe9', 0.4),
    24: rgba('#d7dfe9', 0.24),
  },

  // --
  // -- STATES
  // --
  success: {
    default: '#00d166',
    pressed: '#1db05f',
    hover: '#26bf6a',
    bg: rgba(0, 209, 102, 0.1),
  },
  inProgress: {
    default: '#ffd428',
    pressed: '#f6c605',
    hover: '#f2ae00',
    bg: '#FFF8E6',
  },
  error: {
    default: '#ff0b37',
    pressed: '#d7042a',
    hover: '#ef0d36',
    bg: 'rgba(255, 11, 55, 0.05)',
  },

  // -- SPECIAL
  tier1: {
    teal: '#b3f4e1',
    orange: '#ffc28a',
    blue: '#a4e3fe',
    purple: '#c3aeff',
    pink: '#ff9de4',
    yellow: '#ffd89d',
    red: '#ff9d9d',
    green: '#a9eca3',
    lavander: '#afc1ff',
    golden: '#ddc196',
    royalBlue: '#1f80c6',
  },

  tier2: {
    blue1: '#b0cdfa',
    blue2: '#0051ca',
    orange1: '#ffdcbb',
    orange2: '#d98304',
    purple1: '#d5bbff',
    purple2: '#4d00ca',
    red1: '#ffbbd3',
    red2: '#f13838',
    teal1: '#b3f4e1',
    teal2: '#00b2ca',
    yellow1: '#fff1bb',
    yellow2: '#ca9f00',
  },
  teal: {
    900: '004650',
  },
};

/**
 * Chakra UI Colors
 */
// note: these are copied directly from Chakra's source i.e. theme/colors.js
export const chakraColors = {
  transparent: 'transparent',
  current: 'currentColor',
  black: '#000',
  white: '#fff',

  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },

  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)',
  },

  gray: {
    50: '#F7FAFC',
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: '#CBD5E0',
    400: '#A0AEC0',
    500: '#718096',
    600: '#4A5568',
    700: '#2D3748',
    800: '#1A202C',
    900: '#171923',
  },

  red: {
    50: '#fff5f5',
    100: '#fed7d7',
    200: '#feb2b2',
    300: '#fc8181',
    400: '#f56565',
    500: '#e53e3e',
    600: '#c53030',
    700: '#9b2c2c',
    800: '#822727',
    900: '#63171b',
  },

  orange: {
    50: '#FFFAF0',
    100: '#FEEBC8',
    200: '#FBD38D',
    300: '#F6AD55',
    400: '#ED8936',
    500: '#DD6B20',
    600: '#C05621',
    700: '#9C4221',
    800: '#7B341E',
    900: '#652B19',
  },

  yellow: {
    50: '#fffff0',
    100: '#fefcbf',
    200: '#faf089',
    300: '#f6e05e',
    400: '#ecc94b',
    500: '#d69e2e',
    600: '#b7791f',
    700: '#975a16',
    800: '#744210',
    900: '#5F370E',
  },

  green: {
    50: '#f0fff4',
    100: '#c6f6d5',
    200: '#9ae6b4',
    300: '#68d391',
    400: '#48bb78',
    500: '#38a169',
    600: '#2f855a',
    700: '#276749',
    800: '#22543d',
    900: '#1C4532',
  },

  teal: {
    50: '#E6FFFA',
    100: '#B2F5EA',
    200: '#81E6D9',
    300: '#4FD1C5',
    400: '#38B2AC',
    500: '#319795',
    600: '#2C7A7B',
    700: '#285E61',
    800: '#234E52',
    900: '#1D4044',
  },

  blue: {
    50: '#ebf8ff',
    100: '#ceedff',
    200: '#90cdf4',
    300: '#63b3ed',
    400: '#4299e1',
    500: '#3182ce',
    600: '#2a69ac',
    700: '#1e4e8c',
    800: '#153e75',
    900: '#1a365d',
  },

  cyan: {
    50: '#EDFDFD',
    100: '#C4F1F9',
    200: '#9DECF9',
    300: '#76E4F7',
    400: '#0BC5EA',
    500: '#00B5D8',
    600: '#00A3C4',
    700: '#0987A0',
    800: '#086F83',
    900: '#065666',
  },

  purple: {
    50: '#faf5ff',
    100: '#e9d8fd',
    200: '#d6bcfa',
    300: '#b794f4',
    400: '#9f7aea',
    500: '#805ad5',
    600: '#6b46c1',
    700: '#553c9a',
    800: '#44337a',
    900: '#322659',
  },

  pink: {
    50: '#fff5f7',
    100: '#fed7e2',
    200: '#fbb6ce',
    300: '#f687b3',
    400: '#ed64a6',
    500: '#d53f8c',
    600: '#b83280',
    700: '#97266d',
    800: '#702459',
    900: '#521B41',
  },

  linkedin: {
    50: '#E8F4F9',
    100: '#CFEDFB',
    200: '#9BDAF3',
    300: '#68C7EC',
    400: '#34B3E4',
    500: '#00A0DC',
    600: '#008CC9',
    700: '#0077B5',
    800: '#005E93',
    900: '#004471',
  },

  facebook: {
    50: '#E8F4F9',
    100: '#D9DEE9',
    200: '#B7C2DA',
    300: '#6482C0',
    400: '#4267B2',
    500: '#385898',
    600: '#314E89',
    700: '#29487D',
    800: '#223B67',
    900: '#1E355B',
  },

  messenger: {
    50: '#D0E6FF',
    100: '#B9DAFF',
    200: '#A2CDFF',
    300: '#7AB8FF',
    400: '#2E90FF',
    500: '#0078FF',
    600: '#0063D1',
    700: '#0052AC',
    800: '#003C7E',
    900: '#002C5C',
  },

  whatsapp: {
    50: '#e2f7f4',
    100: '#c3f0e9',
    200: '#a0e7dc',
    300: '#76dccd',
    400: '#43cfba',
    500: '#00BFA5',
    600: '#00ac92',
    700: '#009780',
    800: '#007d6a',
    900: '#005a4c',
  },

  twitter: {
    50: '#e5f4fd',
    100: '#c8e9fb',
    200: '#a8dcfa',
    300: '#83cdf7',
    400: '#57bbf5',
    500: '#1DA1F2',
    600: '#1a94da',
    700: '#1681bf',
    800: '#136b9e',
    900: '#0d4d71',
  },

  telegram: {
    50: '#e3f2f9',
    100: '#c5e4f3',
    200: '#a2d4ec',
    300: '#7ac1e4',
    400: '#47a9da',
    500: '#0088CC',
    600: '#007ab8',
    700: '#006ba1',
    800: '#005885',
    900: '#003f5e',
  },
};

export const colors = {
  // ...chakraColors,
  ...ccdsColors,
};

// export default colors;
export default ccdsColors;
