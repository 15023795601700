import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as ridesTypes from 'rides/types';
import { fromApp } from 'rides/store/selectors';
import { resourceUpdateRequest } from 'rides/store/actions';
import NuiTripListTripMenu from 'rides/nui/TripListTripMenu';

const mapDispatchToProps = {
  requestUpdateTrip: (memberId, tripId, trip) =>
    resourceUpdateRequest(`member/${memberId}/trip`, tripId, { trip }, 'trip'),
};

const mapStateToProps = (state, props) => ({
  showStaffUI: fromApp.ui.showStaffUI(state, props),
  trip: fromApp.trip.trip(state, props),
});

const NuiTripListTripMenuContainer = ({
  trip,
  requestUpdateTrip,
  showStaffUI,
  disabled = false,
}) => {
  if (!showStaffUI) {
    return null;
  }
  if (!trip) {
    return null;
  }

  const tripId = trip.id;
  const memberId = trip.member.id;
  const updateTrip = trip => requestUpdateTrip(memberId, tripId, trip);

  return (
    <NuiTripListTripMenu
      trip={trip}
      updateTrip={updateTrip}
      showStaffUI={showStaffUI}
      disabled={disabled}
    />
  );
};

NuiTripListTripMenuContainer.propTypes = {
  showStaffUI: PropTypes.bool.isRequired,
  trip: ridesTypes.tripType.isRequired,
  requestUpdateTrip: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(NuiTripListTripMenuContainer);
