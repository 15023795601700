/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'VanIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M11.391 11.556a.88.88 0 01-.86-.743.883.883 0 01-.015-.138v-.017a.89.89 0 01.875-.898c.06 0 .12.004.176.018.399.084.7.449.7.88v.004c0 .062-.006.12-.02.182a.883.883 0 01-.856.712zM9.45 8.889h-.433a.218.218 0 01-.217-.222c0-.12.097-.223.217-.223h.433c.12 0 .217.103.217.223a.218.218 0 01-.217.222zM7.5 7.333V5.556c0-.12.097-.223.217-.223s.216.103.216.223V7.11h2.817c.12 0 .217.102.217.222a.218.218 0 01-.217.223H7.717a.218.218 0 01-.217-.223zm-1.517-1.11H2.95A.218.218 0 012.733 6c0-.12.097-.222.217-.222h3.033c.12 0 .217.102.217.222a.218.218 0 01-.217.222zm0 1.777H2.95a.218.218 0 01-.217-.222c0-.12.097-.222.217-.222h3.033c.12 0 .217.102.217.222A.218.218 0 015.983 8zM3.59 11.547a.868.868 0 01-.838-.671c-.001-.023-.007-.04-.014-.063a.88.88 0 01.852-1.044c.477 0 .866.4.866.889v.004a.874.874 0 01-.866.884zm9.83-4.134a2.327 2.327 0 01-1.075-.6L9.882 4.378A1.302 1.302 0 008.96 4H2.31A1.31 1.31 0 001 5.302v4.32c0 .702.556 1.49 1.3 1.49h.074c.182.51.656.88 1.217.88.56 0 1.035-.37 1.217-.88h5.355c.183.52.662.888 1.228.888.565 0 1.044-.369 1.227-.889h.082c.717 0 1.3-.582 1.3-1.302V8.15a.76.76 0 00-.58-.738z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
