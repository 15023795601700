import React from 'react';
import R from 'ramda';
import PropTypes from 'prop-types';
import * as ReactUltimatePagination from 'react-ultimate-pagination';

import { Block, Button } from 'rides/components';

function Page(props) {
  return (
    <Button primary={props.isActive} onClick={props.onClick}>
      {props.value}
    </Button>
  );
}

function Ellipsis(props) {
  return <Button onClick={props.onClick}>...</Button>;
}

function FirstPageLink(props) {
  return <Button onClick={props.onClick}>First</Button>;
}

function PreviousPageLink(props) {
  return <Button onClick={props.onClick}>Previous</Button>;
}

function NextPageLink(props) {
  return <Button onClick={props.onClick}>Next</Button>;
}
function LastPageLink(props) {
  return <Button onClick={props.onClick}>Last</Button>;
}

const ultimatePaginationItemPropTypes = {
  value: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
};

Page.propTypes = ultimatePaginationItemPropTypes;
Ellipsis.propTypes = ultimatePaginationItemPropTypes;
FirstPageLink.propTypes = ultimatePaginationItemPropTypes;
PreviousPageLink.propTypes = ultimatePaginationItemPropTypes;
NextPageLink.propTypes = ultimatePaginationItemPropTypes;
LastPageLink.propTypes = ultimatePaginationItemPropTypes;

function Wrapper(props) {
  return <Block className="pagination">{props.children}</Block>;
}

Wrapper.propTypes = {
  children: PropTypes.node,
};

var itemTypeToComponent = {
  PAGE: Page,
  ELLIPSIS: Ellipsis,
  FIRST_PAGE_LINK: FirstPageLink,
  PREVIOUS_PAGE_LINK: PreviousPageLink,
  NEXT_PAGE_LINK: NextPageLink,
  LAST_PAGE_LINK: LastPageLink,
};

const UltimatePagination = ReactUltimatePagination.createUltimatePagination({
  itemTypeToComponent: itemTypeToComponent,
  WrapperComponent: Wrapper,
});

export default UltimatePagination;
