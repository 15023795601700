/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'NotesNumberXlIcon',
  path: (
    <g fill="currentColor">
      <g clip-path="url(#clip0)">
        <path
          fill-rule="evenodd"
          d="M11.79 3H1.684C.758 3 0 3.818 0 4.818v12.728h1.684V4.818H11.79V3zm2.526 3.636H5.053c-.927 0-1.685.819-1.685 1.819v12.727c0 1 .758 1.818 1.685 1.818h9.263c.926 0 1.684-.818 1.684-1.818V8.455c0-1-.758-1.819-1.684-1.819zm0 14.546H5.053V8.455h9.263v12.727z"
          clip-rule="evenodd"
        />
        <rect
          width="13.214"
          height="13.214"
          x="10.143"
          y="-.857"
          stroke-width="1.714"
          rx="6.607"
        />
        <path d="M18.33 4.12c0 .313-.095.578-.285.797-.189.219-.454.37-.796.451v.02c.403.05.709.174.916.37.207.194.311.456.311.786 0 .48-.174.856-.523 1.124-.348.267-.846.4-1.494.4-.542 0-1.023-.09-1.442-.27V6.9c.194.098.407.178.64.24.232.061.462.092.69.092.348 0 .606-.06.772-.178.166-.118.25-.309.25-.57 0-.235-.096-.402-.288-.5-.19-.098-.496-.147-.915-.147h-.38v-.81h.386c.388 0 .67-.05.848-.15.18-.103.27-.277.27-.523 0-.378-.237-.567-.71-.567-.165 0-.332.027-.503.082a2.46 2.46 0 00-.564.283l-.49-.728c.457-.328 1-.492 1.631-.492.518 0 .925.105 1.224.315.3.21.451.5.451.875z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h23v23H0z" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 23 23',
});
