/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'TaskIcon',
  path: (
    <g fill="currentColor">
      <path stroke-width="1.5" d="M.75.75h14.5v14.5H.75z" />
    </g>
  ),
  viewBox: '0 0 16 16',
});
