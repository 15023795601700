import React from 'react';
import PropTypes from 'prop-types';

import { memberType, memberIdType } from 'rides/types';
import useEntityListLoaderPagination from 'rides/hooks/use-entity-list-loader-pagination';
import EntityListLoader from 'rides/components/utils/EntityListLoader';
import {
  Block,
  Button,
  FormFeedbackBox,
  Glyphicon,
  Heading,
  HorizontalRule,
  MemberDetails,
  MemberTripBenefits,
  TripGroupListTable,
  Widget,
  Pagination,
} from 'rides/components';
import { MemberActivityLog, StandardLayout } from 'rides/containers';
import { Flex } from 'rides/nui';
import NuiMemberEligibilityBadge from 'rides/nui/containers/MemberEligibilityBadge';

const PagedTripGroupListTable = ({ memberId, renderAbove }) => {
  const [page, pageSize, getPaginationProps] = useEntityListLoaderPagination();

  return (
    <EntityListLoader
      loadOnMount
      resource={`member/${memberId}/trip_group_v2`}
      entityType="tripGroup"
      requestParams={{ order: 'desc', page_size: pageSize, page }}
      renderNotAsked={() => null}
      renderLoading={() => <FormFeedbackBox error="Loading..." warning />}
      renderError={() => <FormFeedbackBox error="Failed to load activity log" />}
      renderSuccess={items => {
        // drop undefined trips to deal with missing entities (needed for tenant switching)
        // const tripGroupList = items.filter(trip => trip !== undefined);
        const tripGroupList = items;
        return <TripGroupListTable tripGroupList={tripGroupList} />;
      }}
    >
      {(View, loadData, { pagination, isLoading }) => {
        const paginationProps = getPaginationProps(pagination, isLoading);

        return (
          <>
            {renderAbove(
              <Button sm success onClick={loadData}>
                <Glyphicon icon="refresh" />
              </Button>,
            )}
            <Pagination {...paginationProps} />
            {View}
            <Pagination {...paginationProps} />
          </>
        );
      }}
    </EntityListLoader>
  );
};

PagedTripGroupListTable.propTypes = {
  renderAbove: PropTypes.func.isRequired,
  memberId: memberIdType,
};

const MemberDetailPage = ({ member, onCreateTrip, onEditMember }) => (
  <StandardLayout>
    <Block>
      <Heading level={2}>Member Information</Heading>
    </Block>

    <Widget>
      <Block>
        <Block pullRight>
          <Button onClick={onEditMember} default data-test-id="edit-member-button">
            <Glyphicon icon="pencil" />
          </Button>
        </Block>

        <Heading level={3} className="widget-title">
          <Flex alignItems="baseline">
            <Block>Member Profile</Block>
            <NuiMemberEligibilityBadge memberId={member.id} member={member} />
          </Flex>
        </Heading>

        <MemberDetails member={member} />

        <HorizontalRule />

        <MemberTripBenefits member={member} />
      </Block>
    </Widget>

    <Widget>
      <PagedTripGroupListTable
        memberId={member.id}
        renderAbove={refreshButton => (
          <>
            <Block pullRight>
              <Button onClick={onCreateTrip} success data-test-id="create-trip-button">
                Create Trip
              </Button>
            </Block>

            <Heading level={3} className="widget-title">
              Trips {refreshButton}
            </Heading>
          </>
        )}
      />
    </Widget>

    <Widget>
      <MemberActivityLog memberId={member.id} />
    </Widget>
  </StandardLayout>
);

MemberDetailPage.propTypes = {
  onCreateTrip: PropTypes.func.isRequired,
  onEditMember: PropTypes.func.isRequired,
  member: memberType,
};

export default MemberDetailPage;
