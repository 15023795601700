import React from 'react';
import { Field } from 'formik';
import { PropTypes } from 'prop-types';
import { Alert, Pane, majorScale } from 'evergreen-ui';

import { tripStatusTransitionReasonListType } from 'rides/types';
import { tripStatus } from 'rides/utils/trip';
import { FormikField } from 'rides/components';
import { VendorSelectField } from 'rides/containers';
import TripTransitionForm from 'rides/components/organisms/TripTransitionForm';

const PrimaryForm = ({ status, disabled }) => {
  switch (status) {
    case tripStatus.DRIVER_ASSIGNED:
      return (
        <>
          <Pane display="flex">
            <Pane flex={1} paddingRight={majorScale(1)}>
              <Field
                name="trip.customDriverName"
                label="Driver Name"
                component={FormikField}
                disabled={disabled}
              />
            </Pane>
            <Pane flex={1} paddingLeft={majorScale(1)}>
              <Field
                name="trip.customDriverPhone"
                label="Driver Phone Number"
                component={FormikField}
                disabled={disabled}
              />
            </Pane>
          </Pane>

          <Field
            name="trip.customDriverCarLicense"
            label="Driver Car License"
            component={FormikField}
            disabled={disabled}
          />
          <Field
            name="trip.customDriverCarDescription"
            label="Driver Car Description"
            component={FormikField}
            disabled={disabled}
            type="textarea"
          />
        </>
      );

    case tripStatus.VENDOR_ASSIGNED:
      return (
        <Field
          name="trip.vendorId"
          label="Vendors"
          component={FormikField}
          disabled={disabled}
          type={VendorSelectField}
        />
      );

    default:
      return null;
  }
};

PrimaryForm.propTypes = {
  status: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

PrimaryForm.defaultProps = {
  status: null,
};

function FormFields({
  status,
  disabled,
  errorMessage,
  reasonList,
  showReason,
  showUpdateForm,
}) {
  return (
    <>
      {errorMessage && !disabled && (
        <Alert intent="danger" title={errorMessage} style={{ marginBottom: '10px' }} />
      )}

      <TripTransitionForm
        disabled={disabled}
        reasonList={reasonList}
        showReason={showReason}
        showUpdateForm={showUpdateForm}
      >
        <PrimaryForm status={status} disabled={disabled} />
      </TripTransitionForm>
    </>
  );
}

FormFields.propTypes = {
  status: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  reasonList: tripStatusTransitionReasonListType.isRequired,
  showReason: PropTypes.bool,
  showUpdateForm: PropTypes.bool,
};

FormFields.defaultProps = {
  status: null,
  errorMessage: null,
};

export default FormFields;
