import styled from 'styled-components';
import { Box } from 'rides/nui';

const Card = styled(Box)``;

Card.defaultProps = {
  boxShadow: 1,
  bg: 'white',
};

export default Card;
