import React from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { appTimezone } from 'rides/config';
import { fromApp } from 'rides/store/selectors';

const mapStateToProps = (state, props) => ({
  currentTimezone: fromApp.tenant.currentTenantTimezone(state, props),
});

const CurrentTimezone = ({ currentTimezone, format }) => {
  const timezone = currentTimezone || appTimezone;
  const prettyTimezone = timezone.replace('_', ' ').replace('/', ' / ');

  if (format === 'short') {
    return `${prettyTimezone}`.replace('America / ', '');
  }
  return `${prettyTimezone}`;
};

CurrentTimezone.propTypes = {
  format: PropTypes.oneOf(['short', 'long']).isRequired,
};
CurrentTimezone.defaultProps = {
  format: 'long',
};

export default R.compose(withRouter, connect(mapStateToProps))(CurrentTimezone);
