import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as ridesTypes from 'rides/types';
import { fromApp } from 'rides/store/selectors';
import { resourceDetailReadRequest } from 'rides/store/actions';
import NuiMemberEligibilityBadge from 'rides/nui/MemberEligibility/Badge';

const mapDispatchToProps = {
  checkMemberEligibility: memberId =>
    resourceDetailReadRequest(`member/${memberId}`, 'change_healthcare_eligibility', 'member'),
  loadMemberResource: memberId => resourceDetailReadRequest(`member`, memberId, 'member'),
};

const mapStateToProps = (state, props) => ({
  member: fromApp.member.currentMember(state, props),
  showMemberEligibilityUI: fromApp.ui.showMemberEligibilityUI(state, props),
  showMemberEligibilityCheckUI: fromApp.ui.showMemberEligibilityCheckUI(state, props),
  showStaffUI: fromApp.ui.showStaffUI(state, props),
});

const NuiMemberEligibilityBadgeContainer = ({
  memberId,
  member,
  checkMemberEligibility,
  loadMemberResource,
  showMemberEligibilityUI,
  showMemberEligibilityCheckUI,
  showStaffUI,
}) => {
  if (!showMemberEligibilityUI) return null;

  const [isLoadingMember, setLoadingMember] = React.useState(false);
  async function loadMember() {
    setLoadingMember(true);
    await loadMemberResource(memberId);
    setLoadingMember(false);
  }

  const [isChecking, setChecking] = React.useState(false);
  async function checkEligibility() {
    setChecking(true);
    try {
      await checkMemberEligibility(memberId);
    } catch (err) {
      console.error('Member Eligibility check failed with error:', err);
    }
    setChecking(false);
  }

  // On mount and prop update load member
  React.useEffect(() => {
    if (!member || member.id != memberId) {
      loadMember(memberId);
    }
  }, [memberId]);

  return isLoadingMember ? null : (
    <NuiMemberEligibilityBadge
      member={member}
      checkEligibility={checkEligibility}
      isChecking={isChecking}
      showStaffUI={showStaffUI}
      showMemberEligibilityCheckUI={showMemberEligibilityCheckUI}
    />
  );
};

NuiMemberEligibilityBadgeContainer.propTypes = {
  memberId: ridesTypes.memberIdType.isRequired,
  member: ridesTypes.memberType,
  checkMemberEligibility: PropTypes.func.isRequired,
  loadMemberResource: PropTypes.func.isRequired,
  showMemberEligibilityCheckUI: PropTypes.bool.isRequired,
  showMemberEligibilityUI: PropTypes.bool.isRequired,
  showStaffUI: PropTypes.bool.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(NuiMemberEligibilityBadgeContainer);
