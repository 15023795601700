import React from 'react';
import PropTypes from 'prop-types';

import EntityListLoader from 'rides/components/utils/EntityListLoader';
import { ActivityLog, Block, Button, FormFeedbackBox, Heading } from 'rides/components';

const ActivityLogListLoader = ({ resourcePath, title, ...props }) => (
  <EntityListLoader
    resource={resourcePath}
    entityType="activityLog"
    renderNotAsked={() => null}
    renderLoading={() => <FormFeedbackBox error="Loading..." warning />}
    renderError={(/*error*/) => <FormFeedbackBox error="Failed to load purposes" />}
    renderSuccess={items => <ActivityLog logList={items} />}
  >
    {(View, loadData) => (
      <Block>
        <Heading level={3}>{title}</Heading>
        {View}
        <Button onClick={loadData} xs success>
          Load Data
        </Button>
      </Block>
    )}
  </EntityListLoader>
);

ActivityLogListLoader.propTypes = {
  title: PropTypes.node.isRequired,
  resourcePath: PropTypes.string.isRequired,
};

const ActivityLogContainer = props => (
  <Block>
    <ActivityLogListLoader title="Global Activity Log" resourcePath="activity_log" />
    <ActivityLogListLoader
      title="Member Activity Log"
      resourcePath="member/0329e871-04cf-4904-8216-5958c4ccb5e1/activity_log"
    />
    <ActivityLogListLoader
      title="Trip Activity Log"
      resourcePath="trip/56/activity_log"
    />
  </Block>
);

// ActivityLogContainer.propTypes = {
//   value: PropTypes.string,
// };

export default ActivityLogContainer;
