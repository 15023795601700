/**
 * LOGIN
 **/
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';

export const authLogin = ({ email, password }) => ({
  type: AUTH_LOGIN,
  payload: { email, password },
  meta: { thunk: true },
});

export const authLoginSuccess = userData => ({
  type: AUTH_LOGIN_SUCCESS,
  payload: { user: userData },
});

export const authLoginFailure = error => ({
  type: AUTH_LOGIN_FAILURE,
  error: true,
  payload: error,
});

/**
 * LOGIN REQUIRE DATA
 **/
export const AUTH_LOGIN_REQUIRE_NEW_PASSWORD = 'AUTH_LOGIN_REQUIRE_NEW_PASSWORD';

export const authLoginRequireNewPassword = () => ({
  type: AUTH_LOGIN_REQUIRE_NEW_PASSWORD,
});

/**
 * LOG OUT
 **/
export const AUTH_LOG_OUT = 'AUTH_LOG_OUT';
export const AUTH_LOG_OUT_SUCCESS = 'AUTH_LOG_OUT_SUCCESS';
export const AUTH_LOG_OUT_FAILURE = 'AUTH_LOG_OUT_FAILURE';

export const authLogOut = (showConfirm = true) => ({
  type: AUTH_LOG_OUT,
  payload: { showConfirm },
  meta: { thunk: true },
});

export const authLogOutSuccess = thunk => ({
  type: AUTH_LOG_OUT_SUCCESS,
  meta: { thunk },
});

export const authLogOutFailure = (error, thunk) => ({
  type: AUTH_LOG_OUT_FAILURE,
  error: true,
  payload: error,
  meta: { thunk },
});

/**
 * SET CURRENT TENANT IS
 **/
export const AUTH_SET_CURRENT_TENANT_ID = 'AUTH_SET_CURRENT_TENANT_ID';
export const AUTH_SET_CURRENT_TENANT_ID_SUCCESS = 'AUTH_SET_CURRENT_TENANT_ID_SUCCESS';

export const authSetCurrentTenantId = tenantId => ({
  type: AUTH_SET_CURRENT_TENANT_ID,
  payload: { tenantId },
});

export const authSetCurrentTenantIdSuccess = tenantId => ({
  type: AUTH_SET_CURRENT_TENANT_ID_SUCCESS,
  payload: { tenantId },
});
