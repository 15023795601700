import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { authLogOut, tenantChange } from 'rides/store/actions';
import { fromApp, fromAuth } from 'rides/store/selectors';
import { Menubar } from 'rides/components';

const mapDispatchToProps = {
  logOut: authLogOut,
  onChangeTenant: e => {
    e.preventDefault();
    return tenantChange();
  },
};

const mapStateToProps = (state, props) => ({
  canChangeTenant: fromAuth.hasMultipleTenantIds(state, props),
  tenant: fromApp.tenant.currentTenant(state, props),
  userDisplayName: fromAuth.userDisplayName(state, props),
  showAdminUI: fromApp.ui.showAdminUI(state, props),
  showStaffUI: fromApp.ui.showStaffUI(state, props),
  hideForHumana: fromApp.ui.hideForHumana(state, props),
  hideTripList: fromApp.ui.hideTripList(state, props),
});

const MenubarContainer = props => <Menubar {...props} />;

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(MenubarContainer);
