import React from 'react';
import PropTypes from 'prop-types';

const List = ({ children, ordered, ...props }) => {
  if (ordered) {
    return <ol {...props}>{children}</ol>;
  }

  return <ul {...props}>{children}</ul>;
};

List.propTypes = {
  children: PropTypes.node,
  ordered: PropTypes.bool,
};

List.defaultProps = {
  ordered: false,
};

export default List;
