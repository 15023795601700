// @flow

import React from 'react';

type Props = {
  options: [],
  disabled: boolean,
  className?: string,
  id?: string,
  value?: string | number,
  onChange: Function,
};

const SelectField = ({ disabled, options, id, value, onChange, className }: Props) => {
  return (
    <select
      id={id}
      value={value}
      onChange={onChange}
      className={className}
      disabled={disabled}
    >
      <option value="" />
      {options.map(([value, name]) => (
        <option key={value} value={value}>
          {name}
        </option>
      ))}
    </select>
  );
};

export default SelectField;
