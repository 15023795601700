import * as React from 'react';
import PropTypes from 'prop-types';
import EvoStandardLayout from 'rides/evo/templates/StandardLayout';

const LegacyStandardLayout = ({ children, renderChildren }) => {
  return (
    <EvoStandardLayout>
      {renderChildren(children)}
    </EvoStandardLayout>
  )
};

LegacyStandardLayout.propTypes = {
  children: PropTypes.node,
  renderChildren: PropTypes.func,
};

LegacyStandardLayout.defaultProps = {
  renderChildren: children => children,
};

export default LegacyStandardLayout;
