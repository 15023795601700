import { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { pending, fulfilled } from 'redux-saga-thunk';
import PropTypes from 'prop-types';

import { tripStatusTransitionReasonListType } from 'rides/types';
import { fromEntities } from 'rides/store/selectors';
import { resourceListReadRequest } from 'rides/store/actions';

const thunkId = 'trip/status_transition_reasonListRead';

const mapStateToProps = state => {
  const reasonsEntity = fromEntities.getEntity(state, 'tripStatusTransitionReason');
  const reasonList = Object.values(reasonsEntity);

  return {
    isLoading: pending(state, thunkId),
    isFulfilled: fulfilled(state, thunkId),
    reasonList,
  };
};

const mapDispatchToProps = {
  fetchReasons: () =>
    resourceListReadRequest(
      'trip/status_transition_reason',
      null,
      'tripStatusTransitionReason',
    ),
};

class TripStatusTransitionReasonList extends Component {
  componentDidMount() {
    const { isLoading, isFulfilled, fetchReasons } = this.props;
    if (!isFulfilled && !isLoading) {
      fetchReasons();
    }
  }

  render() {
    const { children, isLoading, reasonList } = this.props;
    return children({ isLoading, reasonList });
  }
}

TripStatusTransitionReasonList.propTypes = {
  children: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isFulfilled: PropTypes.bool.isRequired,
  fetchReasons: PropTypes.func.isRequired,
  reasonList: tripStatusTransitionReasonListType.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(TripStatusTransitionReasonList);
