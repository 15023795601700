import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import {
  Block,
  Column,
  FormikField,
  Heading,
  HorizontalRule,
  Row,
  SelectField,
  MemberHealthAttributesFormFields,
} from 'rides/components';
import { PlanSelectField, MemberLocationAutocompleteField } from 'rides/containers';

const SectionTitle = ({ title }) => (
  <Heading level={3} textMuted textCenter>
    {title}
  </Heading>
);
SectionTitle.propTypes = {
  title: PropTypes.string,
};

const MemberFormFields = ({ externalIdHelpText, disabled, ...props }) => {
  return (
    <Block>
      <SectionTitle title="Plan" />

      <Row>
        <Column md={6}>
          <Field
            name="externalId"
            label="Member ID"
            component={FormikField}
            disabled={disabled}
          />
          <p className="help-block">{externalIdHelpText}</p>
        </Column>

        <Column md={6}>
          <Field
            name="planId"
            label="Plan"
            component={FormikField}
            type={PlanSelectField}
            disabled={disabled}
          />
        </Column>
      </Row>

      <HorizontalRule />

      <SectionTitle title="Member Details" />

      <Row>
        <Column md={4}>
          <Field
            name="firstName"
            label="First Name"
            component={FormikField}
            disabled={disabled}
          />
        </Column>

        <Column md={4}>
          <Field
            name="lastName"
            label="Last Name"
            component={FormikField}
            disabled={disabled}
          />
        </Column>

        <Column md={4}>
          <Field
            name="middleName"
            label="MI"
            component={FormikField}
            disabled={disabled}
          />
        </Column>
      </Row>

      <Row>
        <Column md={4}>
          <Field
            name="dateOfBirth"
            label="Date of Birth"
            component={FormikField}
            disabled={disabled}
            placeholder="YYYY-MM-DD"
          />
        </Column>

        <Column md={4}>
          <Field
            name="gender"
            label="Gender"
            component={FormikField}
            type={SelectField}
            disabled={disabled}
            options={[['male', 'Male'], ['female', 'Female'], ['other', 'Other']]}
          />
        </Column>

        <Column md={4}>
          <Field
            name="languagesSpoken"
            label="Languages Spoken"
            component={FormikField}
            disabled={disabled}
          />
        </Column>
      </Row>

      <HorizontalRule />

      <SectionTitle title="Contact Information" />

      <Row>
        <Column md={4}>
          <Field
            name="phoneNumber"
            label="Contact Number"
            component={FormikField}
            disabled={disabled}
          />
        </Column>

        <Column md={4}>
          <Field
            name="phoneNumberAlternate"
            label="Contact Number (Alternate)"
            component={FormikField}
            disabled={disabled}
          />
        </Column>

        <Column md={4}>
          <Field
            name="email"
            label="Email Address"
            component={FormikField}
            disabled={disabled}
          />
        </Column>
      </Row>

      <HorizontalRule />

      <SectionTitle title="Home Address" />

      <Field
        name="location_autocomplete"
        component={FormikField}
        type={MemberLocationAutocompleteField}
        disabled={disabled}
      />

      <Row>
        <Column md={6}>
          <Field
            name="location_address1"
            label="Address Line 1"
            component={FormikField}
            disabled={disabled}
          />
        </Column>

        <Column md={6}>
          <Field
            name="location_address2"
            label="Address Line 2"
            component={FormikField}
            disabled={disabled}
          />
        </Column>
      </Row>

      <Row>
        <Column md={6}>
          <Field
            name="location_city"
            label="City"
            component={FormikField}
            disabled={disabled}
          />
        </Column>

        <Column md={3}>
          <Field
            name="location_state"
            label="State"
            component={FormikField}
            disabled={disabled}
          />
        </Column>

        <Column md={3}>
          <Field
            name="location_postalCode"
            label="Zip Code"
            component={FormikField}
            disabled={disabled}
          />
        </Column>
      </Row>

      <HorizontalRule />

      <SectionTitle title="Health Attributes" />
      <MemberHealthAttributesFormFields {...props} disabled={disabled} />

      <HorizontalRule />

      <SectionTitle title="Notes" />
      <Field type="textarea" name="notes" component={FormikField} disabled={disabled} />
    </Block>
  );
};

MemberFormFields.propTypes = {
  disabled: PropTypes.bool,
};

export default MemberFormFields;
