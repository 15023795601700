// @flow
import * as React from 'react';

type Props = {
  className?: string,
  children?: React.Node,
  footer?: React.Node,
  title?: React.Node,

  // contexts
  primary?: boolean,
  success?: boolean,
  info?: boolean,
  warning?: boolean,
  danger?: boolean,
};

export const BSPanel = ({
  className,
  children,
  footer,
  title,

  // contexts
  primary,
  success,
  info,
  warning,
  danger,

  ...props
}: Props) => {
  let cssClasses = ['panel', 'panel-default'];

  // contexts
  if (primary) cssClasses[1] = 'panel-primary';
  if (success) cssClasses[1] = 'panel-success';
  if (info) cssClasses[1] = 'panel-info';
  if (warning) cssClasses[1] = 'panel-warning';
  if (danger) cssClasses[1] = 'panel-danger';

  if (className) cssClasses.push(className);

  const cssClassNames = cssClasses.join(' ');

  return (
    <div className={cssClassNames} {...props}>
      {title && (
        <div className="panel-heading">
          <h3 className="panel-title">{title}</h3>
        </div>
      )}
      {children && <div className="panel-body">{children}</div>}
      {footer && <div className="panel-footer">{footer}</div>}
    </div>
  );
};

export default BSPanel;
