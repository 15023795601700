/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'FilesIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M2 1a1 1 0 00-1 1v12a1 1 0 001 1h12a1 1 0 001-1V5.414a1 1 0 00-.293-.707l-3.414-3.414A1 1 0 0010.586 1H2zm2.75 3.5a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5zm0 3a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5zM4 11.25a.75.75 0 01.75-.75h6.5a.75.75 0 010 1.5h-6.5a.75.75 0 01-.75-.75z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
