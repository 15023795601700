import * as React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { push as routerPush } from 'connected-react-router';
import { withRouter } from 'react-router-dom';
import { withProps } from 'recompose';

import { resourceCreateRequest } from 'rides/store/actions';
import { MemberForm } from 'rides/containers';

const mapDispatchToProps = {
  createMember: member => resourceCreateRequest(`member`, { member }, 'member'),
  redirectToMemberPage: memberId => routerPush(`/member/${memberId}`),
};

const CreateMemberFormContainer = props => <MemberForm {...props} />;

export default R.compose(
  withRouter,
  connect(null, mapDispatchToProps),
  withProps(props => ({
    onSubmitCallback: (member, formikBag) => {
      props.createMember(member).then(
        ({ data: memberId }) => {
          formikBag.resetForm();
          props.redirectToMemberPage(memberId);
        },
        error => {
          formikBag.setStatus({ error: error.message });
          formikBag.setSubmitting(false);
        },
      );
    },
  })),
)(CreateMemberFormContainer);
