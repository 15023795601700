import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Text,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuCheckboxItem,
  DropdownMenuItemIndicator,
  DropdownMenuLabel,
  DropdownMenuArrow,
  DropdownMenuIconCheck,
} from '@carecar-evo/core-ui';
import Icon from 'rides/evo/components/Icon/Icon';
import CurrentTimezone from 'rides/nui/containers/CurrentTimezone';
import { tenantType } from 'rides/types';
import {
  useChangeTenantFn,
  useCurrentTenantId,
  useTenantList,
} from 'rides/hooks/use-tenant';

const TenantMenuItemList = ({ canChangeTenant }) => {
  const activeTenantId = useCurrentTenantId();
  const tenantList = useTenantList();

  const changeTenantFn = useChangeTenantFn()
  const handleChangeTenant = useCallback(tenantId => event => {
    if (canChangeTenant) {
      changeTenantFn(tenantId);
    }
  }, [])

  return (
    <>
      {tenantList.map(tenant => (
        <DropdownMenuCheckboxItem
          key={tenant.id}
          checked={tenant.id === activeTenantId}
          onSelect={handleChangeTenant(tenant.id)}
        >
          <DropdownMenuItemIndicator>
            <DropdownMenuIconCheck />
          </DropdownMenuItemIndicator>
          {tenant.name}
        </DropdownMenuCheckboxItem>
      ))}
    </>
  )
}

const TenantDetails = ({ tenant, canChangeTenant }) => {
  return (
    <Box
      display="flex"
      css={{
        padding: '1rem',
        gap: '.5rem',
        alignItems: 'center',
        // width: '370px',
        height: '40px',
        borderColor: '$ccLineBlue100',
        borderWidth: '1px',
        borderRadius: '.25rem',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Box display="flex" css={{ flexDirection: 'row', alignItems: 'center', padding: '.35rem .1rem' }}>
            <Text weight="semibold" uppercase>Tenant:</Text>
            <Box css={{ width: '.4rem' }} />
            <Text>{tenant?.name}</Text>
          </Box>
        </DropdownMenuTrigger>

          <DropdownMenuContent sideOffset={5} className="focus-visible">
            <DropdownMenuLabel css={{ color: '$ccBlue700' }}>Tenants</DropdownMenuLabel>
            <TenantMenuItemList canChangeTenant={canChangeTenant} />
            <DropdownMenuArrow />
          </DropdownMenuContent>
        <Box display="flex">
          <Box
            display="flex"
            css={{ flexDirection: 'row', alignItems: 'center', height: '1.875rem', gap: '6px' }}
          >
            <Icon
              name="time-lg"
              css={{ color: '$ccBlue700', height: '20px', width: '20px' }}
            />
            <Text css={{ m: '0', color: '$ccGray500' }}>
              <CurrentTimezone format="short" />
            </Text>
          </Box>
        </Box>
      </DropdownMenu>
    </Box>
  );
};

TenantDetails.propTypes = {
  canChangeTenant: PropTypes.bool,
  onChangeTenant: PropTypes.func.isRequired,
  tenant: tenantType,
};

export default TenantDetails;
