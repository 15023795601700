/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'BadgeIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M4.916 3.843l.812-1.469 1.604.493a2.5 2.5 0 001.426.012l1.614-.464.786 1.483a2.5 2.5 0 001 1.017l1.469.812-.493 1.605a2.5 2.5 0 00-.013 1.426l.465 1.613-1.483.786a2.5 2.5 0 00-1.017 1l-.812 1.47-1.605-.494a2.5 2.5 0 00-1.426-.012l-1.613.465-.787-1.484a2.5 2.5 0 00-1-1.017l-1.468-.812.492-1.605a2.5 2.5 0 00.013-1.426L2.415 5.63l1.483-.786a2.5 2.5 0 001.018-1zm-.305-2.55A1 1 0 015.78.821l1.993.612a1 1 0 00.57.005l2.003-.577a1 1 0 011.16.492l.977 1.842a1 1 0 00.4.407l1.825 1.009a1 1 0 01.472 1.168l-.612 1.993a1 1 0 00-.005.57l.577 2.004a1 1 0 01-.493 1.16l-1.841.977a1 1 0 00-.407.4l-1.009 1.824a1 1 0 01-1.169.472l-1.992-.612a1 1 0 00-.57-.005l-2.004.578a1 1 0 01-1.16-.493l-.977-1.842a1 1 0 00-.4-.407L1.294 11.39a1 1 0 01-.472-1.169l.612-1.993a1 1 0 00.005-.57L.86 5.654a1 1 0 01.493-1.16l1.842-.976a1 1 0 00.407-.4L4.61 1.293zm3.308 9.212l3.637-4-1.11-1.01-3.089 3.398L6.05 7.489 4.952 8.51l1.864 2 .555.597.548-.604z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
