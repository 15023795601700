export const CONFIRM_ANSWER_NO = 'CONFIRM_ANSWER_NO';
export const CONFIRM_ANSWER_YES = 'CONFIRM_ANSWER_YES';
export const CONFIRM_DISMISS = 'CONFIRM_DISMISS';
export const CONFIRM_HIDE = 'CONFIRM_HIDE';
export const CONFIRM_SHOW = 'CONFIRM_SHOW';

export const confirmAnswerNo = () => ({
  type: CONFIRM_ANSWER_NO,
});

export const confirmAnswerYes = () => ({
  type: CONFIRM_ANSWER_YES,
});

export const confirmDismiss = () => ({
  type: CONFIRM_DISMISS,
});

export const confirmShow = ({ title, message, noButtonText, yesButtonText, danger, hideNoButton }) => ({
  type: CONFIRM_SHOW,
  payload: { title, message, noButtonText, yesButtonText, danger, hideNoButton },
});

export const confirmHide = () => ({
  type: CONFIRM_HIDE,
});
