import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Pane } from 'evergreen-ui';
import { Route } from 'react-router-dom';

import * as ridesTypes from 'rides/types';
import * as queryString from 'rides/utils/queryString';
import { useTripStatusList } from 'rides/hooks/use-trip-list';
import EntityList from 'rides/components/utils/EntityList';
import { StandardLayout } from 'rides/containers';
import { Flex, Link } from 'rides/nui';
import TripList from 'rides/nui/TripList';
import Pagination from './TripListPagination';
import TripListPageSearchBox from './SearchBox';

// TODO: Replace this with our Box styled-system
//       Explained here: https://github.com/rebassjs/rebass/issues/544
//       Move to:
//         https://github.com/jxnblk/superbox & https://jxnblk.com/styled-system/
const TabBarLink = styled(Link)`
  ${({ theme, isActive, ...props }) => {
    const highlightWidth = 2;
    const highlightColor = theme.colors.blue;
    const color = isActive ? highlightColor : theme.colors.black;
    return css`
      && {
        &:active,
        &:focus,
        &:hover,
        &:visited {
          color: ${color};
          text-decoration: none;
        }
        &:hover {
          color: ${highlightColor};
        }

        & {
          font-size: 26px;
          line-height: 32px;

          padding: 28px;
          padding-top: 4px;
          padding-bottom: 12px;

          color: ${color};
          font-weight: ${theme.fontWeights.bold};
          border-bottom: ${isActive ? highlightWidth : 0}px solid ${color};
          margin-bottom: ${isActive ? -1 : 0}px;
          paddin-bottom: ${isActive ? 11 : 12}px;
        }
      }
    `;
  }};
`;

const TripListPageSearchTypeTabBar = ({ changeSearchParams, showStaffUI }) => {
  const tabs = [
    { status_group: 'active', text: 'Active' },
    { status_group: 'scheduled', text: 'Upcoming' },
    { status_group: 'completed', text: 'Completed' },
    { status_group: '', text: 'All' },
    { status_group: 'active_or_scheduled', text: 'Active & Upcoming', staffOnly: true },
  ];

  return (
    <Flex
      style={{
        // TODO move this to use theme
        borderBottom: '1px solid #77797a4d',
      }}
    >
      <Route>
        {({ location }) => {
          const search = queryString.parse(location.search);

          return (
            <React.Fragment>
              {tabs.map(({ status_group, text, staffOnly }) => {
                const hideForNonStaff = staffOnly && !showStaffUI;
                if (hideForNonStaff) return null;

                const isActive = search.status_group === status_group;
                const toSearch = queryString.stringify({
                  ...search,
                  ...{ status_group, status: undefined, page: 1 },
                });
                const to = { ...location, search: toSearch };

                return (
                  <TabBarLink
                    key={status_group}
                    to={to}
                    isActive={isActive}
                    color="black"
                  >
                    {text}
                  </TabBarLink>
                );
              })}
            </React.Fragment>
          );
        }}
      </Route>
    </Flex>
  );
};

TripListPageSearchTypeTabBar.propTypes = {
  changeSearchParams: PropTypes.func.isRequired,
};

const TripListPage = ({
  tripIdList,
  isLoading,
  hasLoadedOnce,
  searchOptions,
  pagination,
  changeSearchParams,
  hideForHumana,
  showStaffUI,
  assigneeList,
  tripTypeList,
  tripPickupUrbanicityList,
}) => {

  const tripStatusList = useTripStatusList(searchOptions?.status_group);

  return (
    <StandardLayout
      renderChildren={children => (
        <Pane marginLeft="-16px" marginRight="32px">
          {children}
        </Pane>
      )}
    >
      <EntityList entityType="trip" entityIds={tripIdList}>
        {rawTripList => {
          // drop undefined trips to deal with missing entities (needed for tenant switching)
          const tripList = rawTripList.filter(trip => trip !== undefined);

          return (
            <React.Fragment>
              <TripListPageSearchBox
                searchOptions={searchOptions}
                keywords={searchOptions.keywords}
                changeSearchParams={changeSearchParams}
                showStaffUI={showStaffUI}
                assigneeList={assigneeList}
                tripTypeList={tripTypeList}
                tripPickupUrbanicityList={tripPickupUrbanicityList}
                tripStatusList={tripStatusList}
                hasLoadedOnce={hasLoadedOnce}
              />
              <TripListPageSearchTypeTabBar
                changeSearchParams={changeSearchParams}
                showStaffUI={showStaffUI}
              />
              <Pagination {...pagination} disabled={isLoading} />
              <TripList
                tripList={tripList}
                isLoading={isLoading}
                searchOptions={searchOptions}
                hideForHumana={hideForHumana}
                showStaffUI={showStaffUI}
              />
              <Pagination {...pagination} disabled={isLoading} />
            </React.Fragment>
          );
        }}
      </EntityList>
    </StandardLayout>
  );
};

TripListPage.propTypes = {
  tripIdList: PropTypes.arrayOf(ridesTypes.tripIdType),
  isLoading: PropTypes.bool,
  changeSearchParams: PropTypes.func.isRequired,
  hideForHumana: PropTypes.bool.isRequired,
  showStaffUI: PropTypes.bool.isRequired,
  hasLoadedOnce: PropTypes.bool,
  searchOptions: PropTypes.object,
  pagination: PropTypes.object,
  assigneeList: ridesTypes.tripAssigneeListType,
  tripTypeList: ridesTypes.tripTypeListType,
  tripPickupUrbanicityList: ridesTypes.tripPickupUrbanicityOptionListType,
};

export default TripListPage;
