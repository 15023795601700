const R = require('ramda');

const isDevEnv = process.env.NODE_ENV !== 'production';

const configTemplates = {
  all: {
    isDevEnv,
    env: process.env.NODE_ENV || 'development',
    basename: process.env.PUBLIC_PATH,
    isBrowser: typeof window !== 'undefined',

    deployBranch: process.env.HQ_ENVIRONMENT || 'local',
    isDeployLocalDev: false,
    isDeployAcceptance: false,
    isDeploySandbox: false,
    isDeployProd: false,

    appTimezone: process.env.APP_TIMEZONE || 'America/Los_Angeles',

    apiRootPath: process.env.API_ROOT_PATH || '/api/v1',
    apiRootUrl: process.env.API_URL || window?.location?.origin,

    devTools: !isDevEnv
      ? {}
      : {
          reactotron: isDevEnv && process.env.npm_config_reactotron === 'true',
          authTokenMakeStale:
            isDevEnv && process.env.npm_config_authTokenMakeStale === 'true',
        },

    sentry: {
      dataSourceName:
        'https://706f09fbf7b3499a8f38f1947e459406@o351790.ingest.sentry.io/2443340',
    },
  },

  // -- envs
  envs: {
    test: {},
    development: {
      // NOTE this is used for testing a token going stale by calling authTokenMakeStale()
      //      in browser console for orther's local dev
      staleAuthToken:
        // Token From Jan 3rd 2019
        'eyJraWQiOiJrbVVMemo5SzlnZ3hkWDNUeExkRk1TS3dwd1JOWFVpZjVxb1hJM1BYbUEwIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULkFYVTJkVnJEQ21fdUFuT3pzMlh4emxDTkdqME8tX0IxNFJta0N3MUItanMiLCJpc3MiOiJodHRwczovL2NhcmVjYXIub2t0YS5jb20iLCJhdWQiOiJodHRwczovL2NhcmVjYXIub2t0YS5jb20iLCJzdWIiOiJicmFuZG9uQHVwdHJlbmQudGVjaCIsImlhdCI6MTU0NjU3NDk4MywiZXhwIjoxNTQ2NTc4NTgzLCJjaWQiOiIwb2F5dHVjcWlmbG5wWjJGczI5NiIsInVpZCI6IjAwdXl0eDVpZEZib3IxeUxUMjk2Iiwic2NwIjpbIm9wZW5pZCIsInByb2ZpbGUiLCJlbWFpbCJdfQ.e2NZ3gZ514UKvr-QZykLx9lZRddZ0HHy6B01iSDVC9145rFVSg54vLx2CZth9gnZhDLqrmcug_9A3MxcCFiZ3GYHwVHklElUgaT6fpAgwnnDAL6nVwOkBpwMd9sFc9L2qDSY-ZO2TAo8ApfQUv7EMNkBadyilA-W9uuCrBCGNo94ziHcEETyU6h2uqA-whJdoBhya9ugpoOAhTIV8GR0HUCEOH5M6u4JZqwcwU_YYR6M1ovOaAJQ6i9iNO4FDj8t5S3QbE7L9lC9pUW6BV7Q2vCa51sGx1lIilv2uiXNSWXb-jzPtaBS0I7wSzSRxO4fhaNTfxr7m-hD9HoI-4hEwg',
    },
    production: {},
  },

  deployBranch: {
    local: {
      isDeployLocalDev: true,
    },
    develop: {
      // NOTE develop branch deploys to acceptance.healtherides.com
      isDeployAcceptance: true,
    },
    sandbox: {
      isDeploySandbox: true,
    },
    production: {
      isDeployProd: true,
    },
  },
};

const baseConfig = R.mergeAll([
  configTemplates.all,
  configTemplates.deployBranch[configTemplates.all.deployBranch],
  configTemplates.envs[configTemplates.all.env],
]);

module.exports = R.merge(baseConfig, {
  apiUrl: `${baseConfig.apiRootUrl}${baseConfig.apiRootPath}`,
});
