import * as React from 'react';
import {
  Collapse,
  IconButton,
  Skeleton,
  VStack,
  Text,
} from '@chakra-ui/react';
import { Box } from '@carecar-evo/core-ui';
import Icon from 'rides/evo/components/Icon/Icon';
import { noop } from 'rides/evo/utils/data';


const expandPaneSectionIcon = (
  <Icon w="16px" h="16px" name="chevron-down" color="general.darkBlue" />
);

const collapsePaneSectionIcon = (
  <Icon w="16px" h="16px" name="chevron-up" color="general.darkBlue" />
);

/**
 * Detail Panel Pane Section Header (title | collapse ^ arrow)
 */
function PaneSectionHeader({
  title,
  // collapseButton = collapsePaneSectionIcon,
  isOpen,
  // onToggle = noop,
  onClose = noop,
  onOpen = noop,
  hideCollapseToggle = false,
  ...props
}) {
  return (
    <PaneSectionTableRow
      leftContent={<Text textStyle="detailPanel.paneSectionTitle">{title}</Text>}
      rightContent={
        !hideCollapseToggle && (
          <Box>
            {isOpen && (
              <IconButton
                onClick={onClose}
                aria-label={`Collapse ${title} section`}
                icon={collapsePaneSectionIcon}
                variant="ghost"
                width="calc(100% - 5px)"
              />
            )}
            {!isOpen && (
              <IconButton
                onClick={onOpen}
                aria-label={`Expand ${title} section`}
                icon={expandPaneSectionIcon}
                variant="ghost"
                width="calc(100% - 5px)"
              />
            )}
          </Box>
        )
      }
      {...props}
    />
  );
}

const PaneSectionTableColLeft = ({ text, ...props }) => (
  <Text {...props} textStyle="detailPanel.paneSectionTableField">
    {text}
  </Text>
);

const PaneSectionTableColRight = ({ text, ...props }) => (
  <Text {...props} textStyle="detailPanel.paneSectionTableValue">
    {text}
  </Text>
);

function PaneSectionTableRow({
  leftContent,
  leftText,
  rightText,
  rightContent,
  rightCss,
  showRowBg,
  ...props
}) {
  const left = !leftText ? leftContent : <PaneSectionTableColLeft text={leftText} />;
  const right = !rightText ? rightContent : <PaneSectionTableColRight text={rightText} />;

  return (
    <Box
      {...props}
      display='flex'
      width="100%"
      css={{
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: '$sm',
      }}
      className="pane-section-table-row"
    >
      <Box css={{ whiteSpace: 'nowrap', marginRight: '1rem' }}>{left}</Box>
      <Box css={rightCss}>{right}</Box>
    </Box>
  );
}
PaneSectionTableRow.toString = () => '.pane-section-table-row'

/**
 * Detail Panel Pane Section Table
 */
function PaneSectionTable({ rowData, isOpen, showRowBg = false, uppercaseField = true, ...props }) {
  if (!rowData) {
    return null;
  }

  const textFieldStyle = uppercaseField ? 'paneSectionTableField' : 'paneSectionTableFieldNoUpper';

  return (
    <Box
      css={{
        width: '100%',
        [`& ${PaneSectionTableRow}`]: {
          padding: '0.1rem 0.5rem',
          margin: '0 -0.5rem',
          borderRadius: 'var(--evo-radii-xs)',
          '&:nth-child(even)': showRowBg && {
            backgroundColor: 'var(--evo-colors-ccGray100)',
            padding: '0 .5rem',
          }
        },
      }}
    >
      <Collapse in={isOpen} animateOpacity>
        <VStack spacing={0} align="stretch" {...props}>
          {rowData.map((row, i) => {
            const key = i;
            const [field, value, showSkeleton = false, wrapFieldText = true] = row;

            const showLoading = !value || showSkeleton;

            const leftContent = wrapFieldText ? <Text textStyle={`detailPanel.${textFieldStyle}`}>{field}</Text> : field;

            return (
              <PaneSectionTableRow
                key={key}
                leftContent={
                  leftContent
                  // <Text textStyle={`detailPanel.${textFieldStyle}`}>{field}</Text>
                }
                rightContent={
                  <Skeleton isLoaded={!showLoading}>
                    <Text textStyle="detailPanel.paneSectionTableValue">{value}</Text>
                  </Skeleton>
                }
                {...props}
                // showRowBg={showRowBg}
              />
            );
          })}
        </VStack>
      </Collapse>
    </Box>
  );
}

/**
 * Detail Panel Pane Section Value List
 */
function PaneSectionTableValueList({ values }) {
  const hasItems = Array.isArray(values) && values.length > 0
  if (!hasItems) return null;

  return (
    <Box
      as="ul"
    >
      {values.map((item, i) => (
        <Box
          as="li"
          key={i}
          display="list-item"
          css={{
            display: 'list-item',
            listStyle: 'square',
          }}
        >
          {item}
        </Box>
      ))}
    </Box>
  )
}

/**
 * Detail Panel Pane Section Stack
 */
function PaneSectionStack({ isOpen, ...props }) {
  return (
    <Box css={{ width: '100%' }} >
      <Collapse in={isOpen} animateOpacity>
        <VStack spacing={0} align="stretch" {...props} />
      </Collapse>
    </Box>
  );
}

export {
  PaneSectionHeader,
  PaneSectionStack,
  PaneSectionTable,
  PaneSectionTableColLeft,
  PaneSectionTableColRight,
  PaneSectionTableRow,
  PaneSectionTableValueList,
};
