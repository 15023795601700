import React from 'react';
import PropTypes from 'prop-types';

import { Block, Heading } from 'rides/components';

const ErrorPageTemplate = ({ heading, children }) => (
  <Block className="page-content text-center">
    <Heading level={1}>{heading}</Heading>
    {children}
  </Block>
);

ErrorPageTemplate.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
};

export default ErrorPageTemplate;
