// --
// -- Elgibility Functions
// --

export function getEligibilityColor(eligibility) {
  const status = getEligibilityStatus(eligibility);
  const color = getEligibilityStatusColor(status);
  return color;
}

export function getEligibilityIconName(eligibility) {
  const status = getEligibilityStatus(eligibility);
  const iconName = getEligibilityStatusIconName(status);
  return iconName;
}

// --
// -- Elgibility Status Functions
// --

export function getEligibilityStatus(eligibility) {
  if (eligibility) {
    const { isMemberKnown, isPlanKnown } = eligibility;
    if (!isMemberKnown) return 'notfound';
    if (isMemberKnown && !isPlanKnown) return 'ineligible';
    if (isMemberKnown && isPlanKnown) return 'eligible';
  }
  return 'unchecked';
}

export function getEligibilityStatusColor(eligibilityStatus) {
  if (eligibilityStatus === 'eligible') return 'success';
  return 'warning';
}

export function getEligibilityStatusIconName(eligibilityStatus) {
  if (eligibilityStatus === 'eligible') return 'endorsed';
  if (eligibilityStatus === 'unchecked') return 'help';
  return 'error';
}
