import React from 'react';
import PropTypes from 'prop-types';
import * as queryString from 'rides/utils/queryString';
import { useRouter } from 'rides/hooks/use-router';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { authLogOut, tenantChange } from 'rides/store/actions';
import { fromApp, fromAuth } from 'rides/store/selectors';
// import { Box, Stack, Text } from '@chakra-ui/react';
// import { Menubar } from 'rides/components';

// import {SideNavMenu} from './SideNav';
import { tenantType } from 'rides/types';

import { SideNavMenu, SideNavMenuSection, SideNavMenuItem } from './Menu';
// import Icon from 'rides/evo/components/Icon/Icon';

const mapDispatchToProps = {
  logOut: authLogOut,
  onChangeTenant: e => {
    e.preventDefault();
    return tenantChange();
  },
};

const mapStateToProps = (state, props) => ({
  canChangeTenant: fromAuth.hasMultipleTenantIds(state, props),
  tenant: fromApp.tenant.currentTenant(state, props),
  userDisplayName: fromAuth.userDisplayName(state, props),
  showEvoUI: true,
  showAdminUI: fromApp.ui.showAdminUI(state, props),
  showStaffUI: fromApp.ui.showStaffUI(state, props),
  hideForHumana: fromApp.ui.hideForHumana(state, props),
  hideTripList: fromApp.ui.hideTripList(state, props),
});

function NavLeft(
  props,
  // {
  //   onChangeTenant,
  //   canChangeTenant,
  //   tenant,
  //   userDisplayName,
  //   showAdminUI,
  //   showStaffUI,
  //   hideForHumana,
  //   hideTripList,
  // },
) {
  const tenant = props.tenant;
  const { location } = useRouter();
  const search = queryString.parse(location.search);

  /**
   * Generate to function that updates path and search params if needed
   */
  const linkToSearch = ({ path, searchParams }) => {
    const newSearchParams = searchParams ?? {}
    const currentSearchParams = queryString.parse(location.search);
    const search = queryString.stringify({
      ...currentSearchParams,
      ...newSearchParams,
      // explicitly removed status_group param from link
      status: undefined,
    });

    const to = {
      pathname: path ?? location.pathname,
      search,
      // hash: "#the-hash",
      // state: { fromDashboard: true }
    }

    return to;
  }

  if (!tenant) return null;
  const tenantLink = to => `/t/${tenant.id}${to}`;

  const routePath = `${location.pathname}`;
  const isTripListPage = routePath.startsWith(tenantLink('/evo/trips'));
  const isMemberPage =
    routePath.startsWith(tenantLink('/member')) ||
    routePath.startsWith(tenantLink('/search'));
  const isAdminUserPage = routePath.startsWith(tenantLink('/admin'));
  const isLegacyTripsPage = routePath.startsWith(tenantLink('/nui'));


  return (
    <SideNavMenu {...props}>
      <SideNavMenuSection
        title="Trips"
        icon="encounters-lg"
        isActive={isTripListPage}
        linkTo={tenantLink('/evo/trips?status_group=')}
      >
        <SideNavMenuItem
          title="Today"
          linkTo={tenantLink('/evo/today-trips-assigned-to-me')}
        />
        <SideNavMenuItem
          title="All"
          linkTo={linkToSearch({ searchParams: { status_group: '' } })}
          isActive={search.status_group === ''}
        />
        <SideNavMenuItem
          title="In Progress"
          linkTo={linkToSearch({ searchParams: { status_group: 'active' } })}
          isActive={search.status_group === 'active'}
        />
        <SideNavMenuItem
          title="Upcoming"
          linkTo={linkToSearch({ searchParams: { status_group: 'scheduled' } })}
          isActive={search.status_group === 'scheduled'}
        />
        <SideNavMenuItem
          title="Completed"
          linkTo={linkToSearch({ searchParams: { status_group: 'completed' } })}
          isActive={search.status_group === 'completed'}
        />
        <SideNavMenuItem
          title="Active & Upcoming"
          isActive={search.status_group === 'active_or_scheduled'}
          linkTo={linkToSearch({ searchParams: { status_group: 'active_or_scheduled' } })}
        />
      </SideNavMenuSection>

      {!props.hideForHumana && (
        <SideNavMenuSection
          title="Members"
          icon="users-lg"
          isActive={isMemberPage}
          linkTo={tenantLink('/search')}
        />
      )}
      {props.showAdminUI && (
        <SideNavMenuSection
          title="Admin Tools"
          icon="settings-lg"
          isActive={isAdminUserPage}
          linkTo={tenantLink('/admin/user')}
        >
          <SideNavMenuItem
            title="Users"
            linkTo={tenantLink('/admin/user')}
            isActive={isAdminUserPage}
          />
        </SideNavMenuSection>
      )}

        <SideNavMenuSection
          title="Legacy Trips"
          // icon="settings-lg"
          icon="vehicle"
          isActive={isLegacyTripsPage}
          linkTo={tenantLink('/nui/trips?status_group=active')}
        />
    </SideNavMenu>
  );
}

NavLeft.propTypes = {
  canChangeTenant: PropTypes.bool,
  onChangeTenant: PropTypes.func.isRequired,
  tenant: tenantType,
  userDisplayName: PropTypes.string.isRequired,
  showEvoUI: PropTypes.bool.isRequired,
  showAdminUI: PropTypes.bool.isRequired,
  showStaffUI: PropTypes.bool.isRequired,
  hideForHumana: PropTypes.bool.isRequired,
  hideTripList: PropTypes.bool.isRequired,
};

const NavLeftContainer = props => <NavLeft {...props} />;

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(NavLeftContainer);
