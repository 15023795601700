import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Text, Strong, Heading, Pane, Table } from 'evergreen-ui';

import * as ridesTypes from 'rides/types';
import { tripStatuses } from 'rides/utils/trip';
import { readableDate, readableTime } from 'rides/utils/dateTime';
import { Box } from 'rides/nui';
import DateTime from 'rides/nui/DateTime';
import TripStatusTransitionReason from 'rides/containers/TripStatusTransitionReason';
import TripTransitionActionsMenu from './TripTransitionActionsMenu';

const statusLabel = status => R.pathOr(status, [status, 'label'], tripStatuses);

function TransitionFromTo({ transition }) {
  const statusFromLabel = statusLabel(transition.statusFrom);
  const statusToLabel = statusLabel(transition.statusTo);
  const toText = transition.statusFrom ? ' to ' : 'To ';

  return (
    <Text size={300}>
      {transition.statusFrom && (
        <>
          From <Strong size={300}>{statusFromLabel}</Strong>
        </>
      )}
      {transition.statusTo && (
        <>
          {toText} <Strong size={300}>{statusToLabel}</Strong>
        </>
      )}
    </Text>
  );
}

TransitionFromTo.propTypes = {
  transition: ridesTypes.tripStatusTransitionType.isRequired,
};

function TripTransitionsTable({ tripId, transitionList }) {
  return (
    <Table>
      <Table.Head>
        <Table.TextHeaderCell>Timestamp</Table.TextHeaderCell>
        <Table.TextHeaderCell flexShrink={1} flexGrow={1}>
          Transition
        </Table.TextHeaderCell>
        <Table.TextHeaderCell flexShrink={1} flexGrow={1}>
          Details
        </Table.TextHeaderCell>
        <Table.HeaderCell flexBasis={64} flexShrink={0} flexGrow={0} />
      </Table.Head>

      <Table.Body>
        {!transitionList.length && (
          <Table.Row>
            <Table.TextCell>
              This trip leg doesn&apos;t have any transitions.
            </Table.TextCell>
          </Table.Row>
        )}

        {transitionList.map(t => (
          <Table.Row key={t.id} height="auto" paddingY={12}>
            <Table.TextCell alignItems="top" flexBasis={100} flexShrink={0} flexGrow={0}>
              <Box>
                <DateTime datetime={t.occurredAt} format={readableDate} />
              </Box>
              <DateTime datetime={t.occurredAt} format={readableTime} />
            </Table.TextCell>
            <Table.TextCell flexShrink={1} flexGrow={1} alignItems="top">
              <TransitionFromTo transition={t} />
            </Table.TextCell>
            <Table.TextCell alignItems="top">
              <Pane flex={1} justifyContent="left">
                {t.reasonId && (
                  <Pane marginBottom={10}>
                    <TripStatusTransitionReason reasonId={t.reasonId}>
                      {reason => (
                        <>
                          <Heading size={300}>Reason</Heading>
                          <Text marginBottom={6}>{reason.name}</Text>
                        </>
                      )}
                    </TripStatusTransitionReason>
                  </Pane>
                )}
                {t.notes && (
                  <Pane marginBottom={10}>
                    <Heading size={300}>Notes</Heading>
                    <Text marginBottom={6}>{t.notes}</Text>
                  </Pane>
                )}
                {t.updatedBecause && (
                  <Pane marginBottom={10}>
                    <Heading size={300}>Updated Because</Heading>
                    <Text marginBottom={6}>{t.updatedBecause}</Text>
                  </Pane>
                )}
              </Pane>
            </Table.TextCell>
            <Table.TextCell
              flexBasis={64}
              flexShrink={0}
              flexGrow={0}
              alignItems="top"
              justifyContent="center"
            >
              <TripTransitionActionsMenu tripId={tripId} transition={t} />
            </Table.TextCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

TripTransitionsTable.propTypes = {
  tripId: ridesTypes.tripIdType.isRequired,
  transitionList: PropTypes.arrayOf(ridesTypes.tripStatusTransitionType).isRequired,
};

export default TripTransitionsTable;
