import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import { tripType } from 'rides/types';
import { Block, Small } from 'rides/components';

const TripType = ({ trip, hideHeading, ...props }) => {
  if (!trip.type) return null;
  const type = R.path(['type', 'name'], trip);

  return (
    <Block {...props}>
      {!hideHeading && (
        <Block>
          <Small className="text-muted">Type:</Small>
        </Block>
      )}
      {type}
    </Block>
  );
};

TripType.propTypes = {
  trip: tripType,
  hideHeading: PropTypes.bool,
};

export default TripType;
