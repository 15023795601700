import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Block, DateTime } from 'rides/components';
import { TripStatusTransitionReason } from 'rides/containers';

const OldValueContainer = styled.span`
  font-style: italic;
`;

function OldValue({ children }) {
  if (!children) return null;
  return (
    <>
      from <OldValueContainer>{children}</OldValueContainer>
    </>
  );
}

const NewValueContainer = styled.span`
  font-style: italic;
`;

function NewValue({ children }) {
  if (!children) return null;
  return (
    <>
      to <NewValueContainer>{children}</NewValueContainer>
    </>
  );
}

function FieldsUpdated({ changes }) {
  const { reasonId, occurredAt, notes } = changes;

  const oldReasonId = R.path(['old'], reasonId);
  const newReasonId = R.path(['new'], reasonId);

  return (
    <>
      {reasonId && (oldReasonId || newReasonId) && (
        <Block>
          Reason{' '}
          <TripStatusTransitionReason reasonId={changes.reasonId.old}>
            {reason => <OldValue>{reason.name}</OldValue>}
          </TripStatusTransitionReason>
          {oldReasonId && newReasonId && ' '}
          <TripStatusTransitionReason reasonId={changes.reasonId.new}>
            {reason => <NewValue>{reason.name}</NewValue>}
          </TripStatusTransitionReason>
        </Block>
      )}

      {occurredAt && (
        <Block>
          Occurred at{' '}
          {occurredAt.old && (
            <OldValue>
              <DateTime datetime={occurredAt.old} />
            </OldValue>
          )}
          {occurredAt.old && occurredAt.new && ' '}
          {occurredAt.new && (
            <NewValue>
              <DateTime datetime={occurredAt.new} />
            </NewValue>
          )}
        </Block>
      )}

      {notes && (
        <Block>
          Notes <OldValue>{notes.old}</OldValue>
          {notes.old && notes.new && ' '}
          <NewValue>{notes.new}</NewValue>
        </Block>
      )}
    </>
  );
}

FieldsUpdated.propTypes = {
  changes: PropTypes.objectOf(
    PropTypes.shape({
      old: PropTypes.any,
      new: PropTypes.any,
    }),
  ),
};

export default FieldsUpdated;
