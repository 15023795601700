import React from 'react';
import { Link } from 'rides/components';

const CopyrightFooter = () => (
  <div className="login-footer text-center">
    &copy; 2017{' '}
    <Link to="https://www.aecspro.com" target="_blank">
      AE Care Support
    </Link>
    <br />
    <span>ALL RIGHTS RESERVED.</span>
  </div>
);

export default CopyrightFooter;
