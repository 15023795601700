/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'ChevronRightIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M12.52 8.52a.735.735 0 000-1.04L6.259 1.215a.736.736 0 10-1.042 1.042L10.958 8l-5.742 5.742a.736.736 0 101.042 1.042L12.52 8.52z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
