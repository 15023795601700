export const initialState = {
  isShowing: false,
  title: null,
  message: null,
  noButtonText: null,
  yesButtonText: null,
  hideNoButton: null,
  danger: null,
};

export const isShowing = (state = initialState) => state.isShowing;
export const title = (state = initialState) => state.title;
export const message = (state = initialState) => state.message;
export const noButtonText = (state = initialState) => state.noButtonText;
export const yesButtonText = (state = initialState) => state.yesButtonText;
export const hideNoButton = (state = initialState) => state.hideNoButton;
export const danger = (state = initialState) => state.danger;
