import { useState } from 'react';

const useEntityListLoaderPagination = (defaultPage = 0, defaultPageSize = 20) => {
  const [page, setPage] = useState(defaultPage);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const handlePageChange = page => {
    setPage(page);
  };

  const getPaginationProps = (pagination, isLoading) => {
    return {
      currentPage: 0,
      totalPages: 0,
      onChange: handlePageChange,
      disabled: isLoading,
      ...pagination,
    };
  };

  return [page, pageSize, getPaginationProps];
};

export default useEntityListLoaderPagination;
