import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Block, Paragraph } from 'rides/components';

const FormFeedbackBox = ({
  children,
  className,

  error,
  textLeft,
  textCenter,
  textRight,

  danger,
  primary,
  success,
  warning,
}) => {
  const classes = classNames(className, {
    alert: true,
    'alert-danger': danger,
    'alert-primary': primary,
    'alert-success': success,
    'alert-warning': warning,

    'text-left': textLeft,
    'text-center': textCenter,
    'text-right': textRight,
  });

  return (
    <Block className={classes}>
      <Paragraph>
        <strong>{error}</strong>
      </Paragraph>
      {children}
    </Block>
  );
};

FormFeedbackBox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  error: PropTypes.node,

  textLeft: PropTypes.bool,
  textCenter: PropTypes.bool,
  textRight: PropTypes.bool,

  danger: PropTypes.bool,
  primary: PropTypes.bool,
  success: PropTypes.bool,
  warning: PropTypes.bool,
};

export default FormFeedbackBox;
