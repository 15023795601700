import React from 'react';
import PropTypes from 'prop-types';

const Strong = ({ children, ...props }) => <strong {...props}>{children}</strong>;

Strong.propTypes = {
  children: PropTypes.node,
};

export default Strong;
