import React from 'react';
import icons from 'rides/evo/theme/figmaImports/icons';
import PropTypes from 'prop-types';

const Icon = ({ name, ...props }) => {
  const IconComponent = icons[name];
  return <IconComponent {...props} />;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Icon;
