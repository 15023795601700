import React from 'react';

import {
  Atom,
  Block,
  Button,
  Emphasis,
  Link,
  List,
  ListItem,
  Nav,
} from 'rides/components';

const TopNavbar = () => (
  <Nav
    className="site-navbar navbar navbar-default navbar-fixed-top navbar-mega"
    role="navigation"
  >
    <Block className="navbar-header">
      <Button
        className="navbar-toggle hamburger hamburger-close navbar-toggle-left hided"
        data-toggle="menubar"
      >
        <Atom className="sr-only">Toggle navigation</Atom>
        <Atom className="hamburger-bar" />
      </Button>

      <Block
        className="navbar-brand navbar-brand-center site-gridmenu-toggle"
        data-toggle="gridmenu"
      >
        <Atom className="navbar-brand-icon hidden-xs"></Atom>
        <Atom className="navbar-brand-text">CareCar</Atom>
      </Block>
    </Block>

    <Block className="navbar-container container-fluid">
      <Block
        className="collapse navbar-collapse navbar-collapse-toolbar"
        id="site-navbar-collapse"
      >
        <List className="nav navbar-toolbar">
          <ListItem className="hidden-float" id="toggleMenubar">
            <Link to="" data-toggle="menubar" role="button">
              <Emphasis className="icon hamburger hamburger-arrow-left">
                <Atom className="sr-only">Toggle menubar</Atom>
                <Atom className="hamburger-bar" />
              </Emphasis>
            </Link>
          </ListItem>
        </List>
      </Block>
    </Block>
  </Nav>
);

export default TopNavbar;
