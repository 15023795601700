import React from 'react';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withProps } from 'recompose';

import { modalHide, resourceUpdateRequest } from 'rides/store/actions';
import { fromApp } from 'rides/store/selectors/';
import { EditMemberModal } from 'rides/components';
import { nothingUntilIsReady } from 'rides/components/utils/showWhile';

const mapStateToProps = (state, props) => ({
  // memberId: fromApp.member.currentMemberId(state, props),
  member: fromApp.member.currentMember(state, props),
});

const mapDispatchToProps = {
  updateMember: (memberId, member) =>
    resourceUpdateRequest('member', memberId, { member }, 'member'),
  hideModal: () => modalHide('edit-member'),
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  updateMember: member => dispatchProps.updateMember(stateProps.member.id, member),
});

const EditMemberModalContainer = props => <EditMemberModal {...props} />;

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withProps(props => ({
    isReady: !R.either(RA.isNull, RA.isUndefined)(props.member),
    onSubmitCallback: (member, formikBag) => {
      props.updateMember(member).then(
        // eslint-disable-next-line no-unused-vars
        resp => {
          // member is updated automatically in the store
          props.hideModal();
        },
        error => {
          formikBag.setStatus({ error: error.message });
          formikBag.setSubmitting(false);
        },
      );
    },
  })),
  // member is undefined on first render so need to do this thing:
  nothingUntilIsReady,
)(EditMemberModalContainer);
