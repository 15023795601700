import * as React from 'react';
import { useChangeSearchParams, useSearchParams } from 'rides/hooks/use-router';

function useActiveTripId() {
  const searchParams = useSearchParams()
  const changeSearchParams = useChangeSearchParams();
  const tripIdRaw = searchParams?.trip_id
  const activeTripId = tripIdRaw ? ~~tripIdRaw : null

  const setActiveTripId = tripId => {
    changeSearchParams({ trip_id: tripId });
  };

  return [activeTripId, setActiveTripId]
}

function useIsTripActive(tripId) {
  const [activeTripId, setActiveTripId] = useActiveTripId()
  const isActive = activeTripId === tripId

  const setTripActive = React.useCallback(() => {
    // NOTE: check not already active required to prevent looping
    if (!isActive) setActiveTripId(tripId);
  }, [isActive]);

  const resetActiveTripId = React.useCallback(() => {
    setActiveTripId(undefined);
  }, []);

  return [isActive, setTripActive, resetActiveTripId];
}

export {
  useActiveTripId,
  useIsTripActive,
};
