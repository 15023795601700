import React from 'react';
import PropTypes from 'prop-types';

const Atom = ({ children, ...props }) => <span {...props}>{children}</span>;

Atom.propTypes = {
  children: PropTypes.node,
};

export default Atom;
