import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import {
  Block,
  Heading,
  Row,
  Column,
  Button,
  Spinner,
  Widget,
  Small,
  Strong,
  Link,
} from 'rides/components';
import { memberType, locationType } from 'rides/types';
import { useTenantUrl } from 'rides/hooks/use-tenant';

const FormattedMemberName = ({ member }) => {
  const fullName = [
    R.prop('firstName', member),
    R.prop('middleName', member),
    R.prop('lastName', member),
  ]
    .filter(val => !!val)
    .join(' ');

  return <Block>{fullName}</Block>;
};

FormattedMemberName.propTypes = {
  member: memberType,
};

const FormattedMemberAddress = ({ location }) =>
  location ? (
    <Block>
      <Block>
        <Strong>{location.address1}</Strong>
      </Block>
      {location.address2 && <Block>{location.address2}</Block>}
      <Block>
        {location.city}, {location.state} {location.postalCode}
      </Block>
    </Block>
  ) : (
    <Block>--</Block>
  );

FormattedMemberAddress.propTypes = {
  location: locationType,
};

const FormattedMemberPhone = ({ member }) => {
  const phoneNumber = [R.prop('phoneNumber', member)].filter(val => !!val).join('');
  const phoneNumberAlt = [R.prop('phoneNumberAlternate', member)]
    .filter(val => !!val)
    .join('');

  return (
    <Block>
      <Block>{phoneNumber}</Block>
      <Block>{phoneNumberAlt}</Block>
    </Block>
  );
};

FormattedMemberName.propTypes = {
  member: memberType,
};

const ResultItem = ({ member = {} }) => {
  const memberUrl = useTenantUrl(`/member/${member?.id}`);

  return (
    <Column md={6}>
      <Widget>
        <Row>
          <Column md={6}>
            <Block>
              <Block textMuted>
                <Small>Name:</Small>
              </Block>
              <FormattedMemberName member={member} />
            </Block>
          </Column>

          <Column md={6}>
            <Block textMuted>
              <Small>Phone:</Small>
            </Block>
            <FormattedMemberPhone member={member} />
          </Column>

          <Column md={6}>
            <Block>
              <Block textMuted>
                <Small>Member ID:</Small>
              </Block>
              <Strong>{member && member.externalId}</Strong>
            </Block>
          </Column>

          <Column md={6}>
            <Block textMuted>
              <Small>Address:</Small>
            </Block>
            <FormattedMemberAddress location={member.location} />
          </Column>
        </Row>

        <Block style={{ marginTop: '10px' }} />

        <Row>
          <Column md={6} mdOffset={3}>
            <Block textCenter>
              <Link to={memberUrl} className="btn btn-primary">
                Select Member
              </Link>
            </Block>
          </Column>
        </Row>
      </Widget>
    </Column>
  );
}

ResultItem.propTypes = {
  member: memberType,
};

const MemberSearchResults = ({
  isSearchDone,
  isSearchFailed,
  isSearching,
  memberList,
  showCreateModal,
}) => {
  if (isSearching) return <Spinner />;
  if (isSearchFailed) return <div>Search Failed!</div>;
  if (!isSearchDone) return null;

  return (
    <Block>
      <Heading level={3}>Search Results</Heading>
      {memberList.length === 0 && (
        <Widget>
          <Block textCenter style={{ marginBottom: 0 }}>
            No members found matching the entered criteria.
            <br />
            Try again, or{' '}
            <Button link onClick={showCreateModal}>
              create a provisional member account
            </Button>
            .
          </Block>
        </Widget>
      )}

      <Row>
        {memberList.map((member, i) => (
          <ResultItem key={member.id} member={member} index={i} />
        ))}
      </Row>
    </Block>
  );
};

MemberSearchResults.propTypes = {
  isSearchDone: PropTypes.bool,
  isSearchFailed: PropTypes.bool,
  isSearching: PropTypes.bool,
  memberList: PropTypes.arrayOf(memberType),
  showCreateModal: PropTypes.func,
};

export default MemberSearchResults;
