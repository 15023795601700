import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fromApp } from 'rides/store/selectors';
import {
  tripCancelTripForMemberClose,
  tripCancelTripForMemberSubmit,
} from 'rides/store/actions';

import { TripCancelModal } from 'rides/components';

const mapDispatchToProps = {
  cancelTrip: tripCancelTripForMemberSubmit,
  onClose: tripCancelTripForMemberClose,
};

const mapStateToProps = (state, props) => ({
  formDisabled: fromApp.trip.cancelFormDisabled(state, props),
  errorMessage: fromApp.trip.cancelFormError(state, props),
  values: fromApp.trip.cancelFormValues(state, props),
});

const TripCancelModalContainer = props => {
  return <TripCancelModal {...props} />;
};

export default R.compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(
  TripCancelModalContainer,
);
