import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';
import 'focus-visible'; // polyfill for proposed CSS :focus-visible pseudo-selector
import { ChakraProvider } from '@chakra-ui/react';
import Inspect from 'inspx';

import { isDevEnv } from 'rides/config';
import routes from 'rides/routes';
import ErrorDebugger from 'rides/components/utils/ErrorDebugger';
import theme, { GlobalStyle } from 'rides/nui/theme';
import chakraTheme from 'rides/evo/theme';
import { EvoGlobalStyles } from 'rides/evo/theme/global';
import { QueryProvider } from 'rides/hooks/use-query';
import { useQueryClient } from 'react-query';
import configureStore from 'rides/store/configure';
import { services } from 'rides/services';
import { Provider, ReactReduxContext } from 'react-redux';
import browserHistory from 'rides/browserHistory';
import RestoreSession from './utils/RestoreSession';

function useStore() {
  const queryClient = useQueryClient();
  const [store] = React.useState(() => configureStore({}, { ...services, queryClient }));
  return store;
}

function AppWrapper() {
  return (
    <ChakraProvider theme={chakraTheme}>
      <QueryProvider>
        <App />
      </QueryProvider>
    </ChakraProvider>
  );
}

const App = () => {
  const store = useStore();

  return (
    <Provider store={store} context={ReactReduxContext}>
      <ConnectedRouter history={browserHistory} context={ReactReduxContext}>
        <ThemeProvider theme={theme}>
          <>
            <RestoreSession>
              <Inspect disabled={!isDevEnv}>{routes}</Inspect>
              <ErrorDebugger />
            </RestoreSession>
            <GlobalStyle />
            <EvoGlobalStyles />
          </>
          <ReactQueryDevtools />
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>
  );
};

export default AppWrapper;
