/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'DownloadIcon',
  path: (
    <g fill="currentColor">
      <g clip-path="url(#clip0)">
        <path
          fill-rule="evenodd"
          d="M.774 11.164a.774.774 0 00-.774.774v2.28C0 15.202.809 16 1.806 16h12.387c.998 0 1.807-.798 1.807-1.782v-2.28a.774.774 0 00-1.548 0v2.28c0 .14-.116.255-.258.255H1.806a.256.256 0 01-.258-.255v-2.28a.774.774 0 00-.774-.774zM11.611 5.93L8.774 8.449V0H7.226v8.449L4.389 5.931 3.353 7.066l4.13 3.665a.78.78 0 001.035 0l4.129-3.665L11.61 5.93z"
          clip-rule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 16 16',
});
