/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'ArrowDownIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M8.707 13.293a1 1 0 01-1.414 0L3 9l.975-.975 3.336 3.337V2h1.378v9.362l3.336-3.337L13 9l-4.293 4.293z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
