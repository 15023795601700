/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'SearchIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M10.401 6.576a3.826 3.826 0 11-7.651 0 3.826 3.826 0 017.651 0zm-.55 4.512a5.576 5.576 0 111.237-1.237L15 13.763 13.763 15 9.85 11.088z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
