import { initialState } from './selectors';
import {
  AUTH_LOGIN,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGIN_REQUIRE_NEW_PASSWORD,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOG_OUT_SUCCESS,
  AUTH_LOG_OUT_FAILURE,
  AUTH_SET_CURRENT_TENANT_ID_SUCCESS,
} from './actions';

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case AUTH_LOGIN:
      return {
        ...state,
        user: null,
        isAuthAttempted: false,
        isAuthenticated: false,
        isAuthenticating: true,
        didAuthFail: false,
      };

    case AUTH_LOGIN_FAILURE:
      return {
        ...state,
        // user: null,
        // isAuthenticated: false,
        isAuthAttempted: true,
        isAuthenticating: false,
        didAuthFail: true,
      };

    case AUTH_LOGIN_REQUIRE_NEW_PASSWORD:
      return {
        ...state,
        didAuthFail: false,
        isAuthenticated: false,
        isAuthenticating: false,
        needNewPassword: true,
      };

    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        user: payload.user,
        isAuthAttempted: true,
        isAuthenticated: true,
        isAuthenticating: false,
        didAuthFail: false,
        needNewPassword: false,
      };

    case AUTH_LOG_OUT_SUCCESS:
    case AUTH_LOG_OUT_FAILURE:
      return { ...initialState };

    case AUTH_SET_CURRENT_TENANT_ID_SUCCESS:
      return { ...state, currentTenantId: payload.tenantId };

    default:
      return state;
  }
};
