import React from 'react';
import PropTypes from 'prop-types';

import EntityListLoader from 'rides/components/utils/EntityListLoader';
import { Block, Input, FormFeedbackBox } from 'rides/components';

const TripTypeSelectField = props => (
  <EntityListLoader
    resource="trip/type"
    entityType="tripType"
    renderNotAsked={() => <Block className="text-muted">Not Loaded</Block>}
    renderLoading={() => (
      <Input {...props} type="select" data-test-id="loading---trip-type-select">
        <option value=""> loading... </option>
      </Input>
    )}
    renderError={(/*error*/) => <FormFeedbackBox error="Failed to load purposes" />}
    renderSuccess={data => (
      <Input {...props} type="select" data-test-id="trip-type-select">
        {!props.value && <option value=""> -- Select One -- </option>}
        {data.map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </Input>
    )}
    loadOnMount
  >
    {(View, loadData) => (
      <Block>
        {View}
        {/*
          * NOTE: We can add a reload button like this:
          * <span onClick={loadData}>Load Data</span>
          * */}
      </Block>
    )}
  </EntityListLoader>
);

TripTypeSelectField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default TripTypeSelectField;
