import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Dialog } from 'evergreen-ui';
import * as yup from 'yup';

import * as ridesTypes from 'rides/types';
import { tripStatus } from 'rides/utils/trip';
import { TripStatusTransitionReasonListLoader } from 'rides/containers';
import FormFields from './FormFields';

function validationSchema(status) {
  const schema = {};

  switch (status) {
    case tripStatus.VENDOR_ASSIGNED:
      schema.trip = yup.object({
        vendorId: yup.string().required('You must select a vendor'),
      });
      break;
  }

  return yup.object(schema);
}

function TripStatusTransitionModal({
  status,
  transition,
  onSubmit,
  onClose,
  dialogTitle,
  confirmLabel,
  showUpdateForm,
}) {
  const formik = useRef(null);

  // Reset the form values when the status changes. Required to set default fields to the new status for formik.
  useEffect(() => {
    formik.current.resetForm();
  }, [status]);

  async function handleSubmit(values) {
    formik.current.setSubmitting(true);
    await onSubmit(values, handleClose, onError);
    formik?.current?.setSubmitting(false);
  }

  function handleClose() {
    formik.current.resetForm();
    onClose();
  }

  function onError(error) {
    formik.current.setStatus({ error });
    formik.current.setSubmitting(false);
  }

  return (
    <Formik
      ref={f => (formik.current = f)}
      initialValues={transition}
      validationSchema={validationSchema(status)}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, handleSubmit: handleConfirm, error }) => {
        return (
          <Form>
            <Dialog
              title={dialogTitle}
              confirmLabel={confirmLabel}
              isShown={!!status}
              onConfirm={handleConfirm}
              onCancel={handleClose}
              onCloseComplete={handleClose}
              isConfirmLoading={isSubmitting}
              isConfirmDisabled={isSubmitting}
              isCancelDisabled={isSubmitting}
              shouldCloseOnOverlayClick={false}
              shouldCloseOnEscapePress={false}
            >
              <TripStatusTransitionReasonListLoader>
                {({ reasonList }) => {
                  const hasReasonForStatus = !!reasonList.find(r => r.status === status);
                  return (
                    <FormFields
                      status={status}
                      disabled={isSubmitting}
                      errorMessage={error}
                      showReason={hasReasonForStatus}
                      reasonList={reasonList}
                      showUpdateForm={showUpdateForm}
                    />
                  );
                }}
              </TripStatusTransitionReasonListLoader>
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
}

TripStatusTransitionModal.propTypes = {
  status: PropTypes.string, // the type of modal to show - should prob be an enum
  transition: ridesTypes.tripStatusTransitionType.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired, // Function to set the action back to null (to close the modal)
  showUpdateForm: PropTypes.bool.isRequired,
};

TripStatusTransitionModal.defaultProps = {
  status: null,
  showUpdateForm: false,
};

export default TripStatusTransitionModal;
