/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'CalendarIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M5.904 12.8a.271.271 0 00-.036-.003A1.068 1.068 0 014.8 11.73a.266.266 0 10-.533 0c0 .867.693 1.575 1.555 1.6l.045.003c.875 0 1.589-.704 1.6-1.573v-.027a1.555 1.555 0 00-.711-1.298 1.65 1.65 0 00.71-1.37 1.6 1.6 0 00-3.2 0 .267.267 0 00.534 0C4.8 8.478 5.279 8 5.868 8c.588 0 1.066.478 1.066 1.088 0 .59-.488 1.089-1.066 1.089H5.2a.267.267 0 000 .533h.667c.596 0 1.063.455 1.066 1.036a1.064 1.064 0 01-1.03 1.053zM9.333 12.8a.267.267 0 000 .533h2.133a.267.267 0 000-.533h-.8V7.733a.267.267 0 00-.455-.188L9.145 8.61a.266.266 0 10.376.377l.612-.61V12.8h-.8z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M15.467 14.133c0 .736-.598 1.334-1.334 1.334H1.867a1.335 1.335 0 01-1.334-1.334v-8.8h14.934v8.8zM14.133 1.6h-2.4V.267a.267.267 0 00-.533 0v2.666a.267.267 0 01-.533 0V1.6H4.8V.267a.267.267 0 00-.533 0v2.666a.267.267 0 01-.534 0V1.6H1.867C.837 1.6 0 2.437 0 3.467v10.666C0 15.163.837 16 1.867 16h12.266c1.03 0 1.867-.837 1.867-1.867V3.467c0-1.03-.837-1.867-1.867-1.867z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
