import React from 'react';
import * as R from 'ramda';

import { memberType } from 'rides/types';
import { Atom } from 'rides/components';

const removeEmpty = R.reject(R.either(R.isEmpty, R.isNil));

const Member = ({ member, ...props }) => {
  const { firstName, lastName } = removeEmpty(member);
  const fullName = `${firstName} ${lastName}`.trim();

  return <Atom {...props}>{fullName}</Atom>;
};

Member.propTypes = {
  member: memberType,
};

export default Member;
