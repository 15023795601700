/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'SpinnerLgIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M10 5.143a.86.86 0 01-.857-.857V.857A.86.86 0 0110 0a.86.86 0 01.857.857v3.429a.86.86 0 01-.857.857z"
        clip-rule="evenodd"
        opacity=".65"
      />
      <path
        fill-rule="evenodd"
        d="M12.428 5.794a.86.86 0 01-.314-1.171l1.714-2.97A.86.86 0 0115 1.34a.86.86 0 01.314 1.17l-1.714 2.97a.86.86 0 01-1.171.314z"
        clip-rule="evenodd"
        opacity=".75"
      />
      <path
        fill-rule="evenodd"
        d="M14.206 7.571a.86.86 0 01.314-1.17l2.969-1.715a.86.86 0 011.17.314.86.86 0 01-.313 1.17l-2.97 1.715a.86.86 0 01-1.17-.314z"
        clip-rule="evenodd"
        opacity=".85"
      />
      <path
        fill-rule="evenodd"
        d="M14.857 10a.86.86 0 01.858-.857h3.428A.86.86 0 0120 10a.86.86 0 01-.857.857h-3.428a.86.86 0 01-.858-.857z"
        clip-rule="evenodd"
        opacity=".9"
      />
      <path
        fill-rule="evenodd"
        d="M14.206 12.429a.86.86 0 011.17-.314l2.97 1.714A.86.86 0 0118.66 15a.86.86 0 01-1.171.314l-2.97-1.714a.86.86 0 01-.313-1.171z"
        clip-rule="evenodd"
        opacity=".2"
      />
      <path
        fill-rule="evenodd"
        d="M12.428 14.206a.86.86 0 011.17.314l1.715 2.97a.86.86 0 01-.314 1.17.86.86 0 01-1.17-.314l-1.715-2.969a.86.86 0 01.314-1.17z"
        clip-rule="evenodd"
        opacity=".25"
      />
      <path
        fill-rule="evenodd"
        d="M10 14.857a.86.86 0 01.857.857v3.429A.86.86 0 0110 20a.86.86 0 01-.857-.857v-3.429a.86.86 0 01.857-.857z"
        clip-rule="evenodd"
        opacity=".3"
      />
      <path
        fill-rule="evenodd"
        d="M7.572 14.206a.86.86 0 01.313 1.171l-1.714 2.97a.86.86 0 01-1.17.313.86.86 0 01-.315-1.17l1.715-2.97a.86.86 0 011.17-.314z"
        clip-rule="evenodd"
        opacity=".35"
      />
      <path
        fill-rule="evenodd"
        d="M5.793 12.429a.86.86 0 01-.314 1.17l-2.97 1.715A.86.86 0 011.34 15a.86.86 0 01.314-1.17l2.969-1.715a.86.86 0 011.17.314z"
        clip-rule="evenodd"
        opacity=".4"
      />
      <path
        fill-rule="evenodd"
        d="M5.143 10a.86.86 0 01-.857.857H.857A.86.86 0 010 10a.86.86 0 01.857-.857h3.429a.86.86 0 01.857.857z"
        clip-rule="evenodd"
        opacity=".45"
      />
      <path
        fill-rule="evenodd"
        d="M5.793 7.571a.86.86 0 01-1.171.314l-2.97-1.714A.86.86 0 011.34 5a.86.86 0 011.17-.314l2.97 1.715a.86.86 0 01.314 1.17z"
        clip-rule="evenodd"
        opacity=".5"
      />
      <path
        fill-rule="evenodd"
        d="M7.572 5.794A.86.86 0 016.4 5.48L4.686 2.51A.86.86 0 015 1.34a.86.86 0 011.171.314l1.714 2.969a.86.86 0 01-.313 1.17z"
        clip-rule="evenodd"
        opacity=".55"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
});
