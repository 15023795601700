import { Spinner } from 'evergreen-ui';
import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import { useGetTenantUrl } from 'rides/hooks/use-tenant';
import { Flex, Text } from 'rides/nui';
import Card from 'rides/nui/Card';
import { fromAuth } from 'rides/store/selectors';
import { formatDateStr } from 'rides/nui/pages/TripListPage/SearchBox';
import * as ridesTypes from 'rides/types';
import * as queryString from 'rides/utils/queryString';

const mapStateToProps = (state, props) => ({
  currentUserId: fromAuth.userId(state, props),
});

const NuiTodayRedirectToTripList = ({ currentUserId }) => {
  const getTenantUrl = useGetTenantUrl();
  const [isDelayDone, setDelayDone] = React.useState(false);
  const delayMs = 1500;

  const location = useLocation();
  const isEvoURL = location.pathname.startsWith(getTenantUrl('/evo'));
  const pathPrefix = isEvoURL ? '/evo' : '/nui';

  React.useEffect(() => {
    // show page for a min amt of time => prefent page flash
    setDelayDone(false);
    setTimeout(() => setDelayDone(true), delayMs);
  }, []);

  React.useEffect(() => {
    if (isDelayDone) {
      const todayDate = formatDateStr(new Date());
      const manualToSearch = queryString.stringify({
        status_group: 'active_or_scheduled',
        assigned_to: [currentUserId],
        from_date: todayDate,
        to_date: todayDate,
        page: 1,
      });
      // const redirectTo = getTenantUrl(`/nui/trips?${manualToSearch}`);
      const redirectTo = getTenantUrl(`${pathPrefix}/trips?${manualToSearch}`);

      // Hard redirect (force browser reload) to trip list with today's trips
      // assigned to me filters set
      window.location.href = redirectTo;
    }
  }, [isDelayDone]);

  return (
    <Flex alignItems="center" justifyContent="center">
      <Card alignItems="center" padding={40}>
        <Flex alignItems="center" justifyContent="center">
          <Spinner />
          <Text textAlign="center" fontSize={3} color="mediumGray" pl={4}>
            Redirecting to your trips for today...
          </Text>
        </Flex>
      </Card>
    </Flex>
  );
};

NuiTodayRedirectToTripList.propTypes = {
  currentUserId: ridesTypes.userIdType.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(NuiTodayRedirectToTripList);
