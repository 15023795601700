import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as ridesTypes from 'rides/types';
import { resourceListReadRequest } from 'rides/store/actions';
import EntityDetail from 'rides/components/utils/EntityDetail';
import EntityList from 'rides/components/utils/EntityList';

const mapDispatchToProps = dispatch => ({
  fetchConnectedTripList: tripGroupId =>
    dispatch(resourceListReadRequest(`trip_group_v2/${tripGroupId}`, {}, 'tripGroup')),
});

const mapStateToProps = null;

class NuiTripListTripConnectedTripsContainer extends React.Component {
  state = {
    isShown: false,
    isLoading: false,
    isLoaded: false,
  };

  fetchConnectedTrips = () => {
    this.setState({ isLoading: true });
    return this.props
      .fetchConnectedTripList(this.props.trip.groupId)
      .then(this.handleFetchSuccess, this.handleFetchFailure);
  };

  handleFetchFailure = error => {
    this.setState({ error, isLoading: false });
  };

  // eslint-disable-next-line no-unused-vars
  handleFetchSuccess = resp => {
    this.setState({ isLoading: false, isLoaded: true });
  };

  hideConnectedTrips = () => {
    this.setState({ isShown: false });
  };

  showConnectedTrips = () => {
    const { isShown, isLoading, isLoaded } = this.state;
    if (!isShown) {
      this.setState({ isShown: true });
      if (!isLoaded && !isLoading) {
        this.fetchConnectedTrips();
      }
    }
  };

  onHide = event => {
    event.preventDefault();
    this.hideConnectedTrips();
  };

  onShow = event => {
    event.preventDefault();
    this.showConnectedTrips();
  };

  render() {
    const { trip } = this.props;
    const { isShown, isLoading, isLoaded } = this.state;

    const renderProps = {
      isShown,
      isLoading,
      isLoaded,
      onHide: this.onHide,
      onShow: this.onShow,
      tripList: [],
    };

    return (
      <EntityDetail entityType="tripGroup" entityId={trip.groupId}>
        {tripGroup => {
          if (!tripGroup) {
            // Trip Group hasn't loaded yet
            return this.props.render(renderProps);
          }

          return (
            <EntityList entityType="trip" entityIds={tripGroup.trips}>
              {tripList =>
                this.props.render({
                  ...renderProps,
                  tripList: tripList.filter(({ id }) => id != trip.id),
                })
              }
            </EntityList>
          );
        }}
      </EntityDetail>
    );
  }
}

NuiTripListTripConnectedTripsContainer.propTypes = {
  trip: ridesTypes.tripType.isRequired,
  render: PropTypes.func.isRequired,
  fetchConnectedTripList: PropTypes.func.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(NuiTripListTripConnectedTripsContainer);
