/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'ShareIcon',
  path: (
    <g fill="currentColor">
      <g clip-path="url(#clip0)">
        <path
          fill-rule="evenodd"
          d="M.774 9.164A.774.774 0 000 9.938v4.28C0 15.202.809 16 1.806 16h12.387c.998 0 1.807-.798 1.807-1.782v-4.28a.774.774 0 10-1.548 0v4.28c0 .14-.116.255-.258.255H1.806a.256.256 0 01-.258-.255v-4.28a.774.774 0 00-.774-.774zM11.611 5.26L8.774 2.742v8.449H7.226V2.742L4.389 5.26 3.353 4.125 7.483.46a.78.78 0 011.035 0l4.129 3.665L11.61 5.26z"
          clip-rule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 16 16',
});
