import React, { Component } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { resourceCreateRequest } from 'rides/store/actions';
import { Block, Spinner, LocationAutocompleteResults } from 'rides/components';

function debounce(fn, ms) {
  let timeout;
  return wrapper;
  function wrapper(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn(...args), ms);
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchPlaceList: searchText => {
      const autocomplete = { input: searchText };
      if (ownProps.addressOnly) autocomplete.types = 'address';

      return dispatch(resourceCreateRequest('maps/place/autocomplete', { autocomplete }));
    },
    fetchPlaceDetails: placeId =>
      dispatch(
        resourceCreateRequest(`maps/place/details`, {
          details: { place_id: placeId },
        }),
      ),
  };
};

class LocationAutocompleteContainer extends Component {
  static propTypes = {
    fetchPlaceList: PropTypes.func.isRequired,
    fetchPlaceDetails: PropTypes.func.isRequired,
    searchTermMinLength: PropTypes.number,

    value: PropTypes.any,
    onPlaceClick: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
      .isRequired,
    addressOnly: PropTypes.bool,
  };

  static defaultProps = {
    searchTermMinLength: 3,
    addressOnly: false,
  };

  state = {
    searchTerm: null,
    searchResults: null,
    searchLoading: false,
  };

  componentWillReceiveProps(nextProps) {
    if (
      this.props.value !== nextProps.value &&
      nextProps.value !== this.state.searchTerm
    ) {
      this.handleSearchTermChange(nextProps.value);
    }
  }

  setSearchTerm = searchTerm => {
    // update results for current search term ONLY i.e. ignore old async search results
    // TODO decide if we should use a counter/hash rather than search term
    if (searchTerm !== this.state.searchTerm) {
      this.setState({ searchTerm, searchLoading: true });
    }
  };

  setSearchResults = (searchTerm, searchResults) => {
    // ignore results for old terms, i.e. ignore out of order ajax results
    if (searchTerm === this.state.searchTerm) {
      this.setState({ searchResults, searchLoading: false });
    }
  };

  resetSearchState = () => {
    this.setState({
      searchTerm: null,
      searchResults: null,
      searchLoading: false,
    });
  };

  loadPlaceList = debounce(searchTerm => {
    if (searchTerm) {
      this.props
        .fetchPlaceList(searchTerm)
        .then(response => this.setSearchResults(searchTerm, response.data));
    }
  }, 500);

  handleSearchTermChange = searchTermRaw => {
    // ignore search request if search text not string
    if (!R.is(String, searchTermRaw)) return;

    // reduce search text to simplest form
    const searchTerm = R.compose(R.toLower, R.trim)(searchTermRaw);

    // emtpy result list if search term too short
    if (searchTerm.length < this.props.searchTermMinLength) {
      this.resetSearchState();
      // NOTE this stops unneeded network requests when emptying field
      this.loadPlaceList();
      return;
    }

    // ignore search request if already searching for results
    if (searchTerm !== this.state.searchTerm) {
      this.setSearchTerm(searchTerm);
      this.loadPlaceList(searchTerm);
    }
  };

  handleResultClick = place => {
    this.props.fetchPlaceDetails(place.placeId).then(resp => {
      this.setState({
        searchTerm: place.address,
        searchLoading: false,
        searchResults: null,
      });

      this.props.onPlaceClick(place, resp.data);
    });
  };

  render() {
    const { searchResults, searchLoading } = this.state;

    return (
      <Block>
        {this.props.children}

        {searchLoading && !searchResults && <Spinner />}

        {searchResults && (
          <LocationAutocompleteResults
            results={searchResults}
            onResultClick={this.handleResultClick}
          />
        )}
      </Block>
    );
  }
}

export default connect(null, mapDispatchToProps)(LocationAutocompleteContainer);
