import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Block, FieldHelpBlock, Input, Label, Paragraph } from 'rides/components';

const FieldFeedbackError = ({ name, error, ...props }) => (
  <Block className="text-danger" role="alert" id={`${name}Error`} {...props}>
    {error}
  </Block>
);

FieldFeedbackError.propTypes = {
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
};

// eslint-disable-next-line no-unused-vars
const FormHelpBlock = ({ children, name }) => (
  <Paragraph className="help-block">{children || ''}</Paragraph>
);

FormHelpBlock.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
};

const FieldFeedback = ({ error, name }) => (
  <FormHelpBlock name={name}>{error}</FormHelpBlock>
);

FieldFeedback.propTypes = {
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
};

const FormGroup = ({ className, invalid, checkbox, type, ...props }) => {
  const classes = classNames(className, {
    'form-group': !checkbox,
    checkbox: checkbox,
    'has-error': invalid,
  });

  return <Block className={classes} {...props} />;
};

FormGroup.propTypes = {
  className: PropTypes.string,
  invalid: PropTypes.bool,
  checkbox: PropTypes.bool,
};

const Field = ({ className, error, name, invalid, label, type, large, ...props }) => {
  const isCheckbox = type === 'checkbox';
  const isInlineRadio = type === 'inline-radio';
  const isInlineStackedRadio = type === 'inline-stacked-radio';
  const renderInputFirst = type === 'radio';

  const inputProps = {
    id: name,
    name,
    type,
    'aria-describedby': `${name}Error`,
    className: classNames(className, {
      'form-control': !isCheckbox,
      'input-lg': large,
    }),
    ...props,
  };

  if (isInlineRadio || isInlineStackedRadio) {
    return (
      <FormGroup invalid={invalid} type={type}>
        {label && (
          <Block>
            <Label>{label}</Label>
          </Block>
        )}
        {Array.isArray(props.radioOptions) &&
          props.radioOptions.map(option => {
            const optionProps = R.compose(
              R.omit(['className', 'values', 'radioOptions']),
              R.merge(R.__, {
                checked: inputProps.value === option.value,
                id: `${inputProps.id}--${option.value}`,
                type: 'radio',
                value: option.value,
              }),
            )(inputProps);

            return (
              <Label
                className="radio-inline"
                htmlFor={optionProps.id}
                key={option.value}
                style={{
                  marginLeft: '20px',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                  display: isInlineStackedRadio ? 'block' : undefined,
                  width: isInlineStackedRadio ? '100%' : undefined,
                }}
              >
                <Input {...optionProps} />
                {option.label}
              </Label>
            );
          })}
        {invalid && <FieldHelpBlock help={error} />}
      </FormGroup>
    );
  }

  if (isCheckbox) {
    return (
      <FormGroup invalid={invalid} type={type} checkbox>
        <Label htmlFor={inputProps.id}>
          <Input {...inputProps} />
          {label}
        </Label>
        {invalid && <FieldHelpBlock help={error} />}
      </FormGroup>
    );
  }

  return (
    <FormGroup invalid={invalid} type={type}>
      {renderInputFirst && <Input {...inputProps} />}
      {label && (
        <Label htmlFor={inputProps.id} className="control-label">
          {label}
        </Label>
      )}
      {renderInputFirst || <Input {...inputProps} />}
      {invalid && <FieldHelpBlock help={error} />}
    </FormGroup>
  );
};

Field.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  invalid: PropTypes.bool,
  label: PropTypes.string,
  large: PropTypes.bool,
  name: PropTypes.string.isRequired,
  textMask: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  radioOptions: PropTypes.array,
};

Field.defaultProps = {
  type: 'text',
};

export default Field;
