/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'EllipsisVerticalFatIcon',
  path: (
    <g fill="currentColor">
      <g clip-path="url(#clip0)">
        <path
          fill-rule="evenodd"
          d="M6 14a2 2 0 114 0 2 2 0 01-4 0zm2-8a2 2 0 100 4 2 2 0 000-4zm0-6a2 2 0 100 4 2 2 0 000-4z"
          clip-rule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 16 16',
});
