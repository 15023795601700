import React from 'react';
import PropTypes from 'prop-types';

import { Block, Button, Widget, Row, Column } from 'rides/components';

import MemberIdFormFields from './MemberIdFormFields';
import NameDobFormFields from './NameDobFormFields';

const SearchFormTemplate = ({ children, onSubmit, searchTitle }) => (
  <Column sm={12} md={4}>
    <Widget title={searchTitle}>
      <form onSubmit={onSubmit}>
        {children}

        <Block textCenter>
          <Button type="submit" info>
            Search
          </Button>
        </Block>
      </form>
    </Widget>
  </Column>
);

SearchFormTemplate.propTypes = {
  children: PropTypes.node,
  onSubmit: PropTypes.func,
  searchTitle: PropTypes.node,
};

const MemberSearch = props => {
  return (
    <Block>
      <Row>
        <SearchFormTemplate
          searchTitle="Search by Member ID"
          onSubmit={props.onSubmitSearchID}
        >
          <MemberIdFormFields
            criteria={props.criteria}
            handleChange={props.onCriteriaChange}
          />
        </SearchFormTemplate>
        <SearchFormTemplate
          searchTitle="Search by Date of Birth"
          onSubmit={props.onSubmitSearchDOB}
        >
          <NameDobFormFields
            criteria={props.criteria}
            handleChange={props.onCriteriaChange}
          />
        </SearchFormTemplate>
      </Row>
    </Block>
  );
};

MemberSearch.propTypes = {
  // searchType: PropTypes.string,
  criteria: PropTypes.PropTypes.object,
  // onSearchTypeChange: PropTypes.func,
  onCriteriaChange: PropTypes.func,
  onSubmitSearchDOB: PropTypes.func.isRequired,
  onSubmitSearchID: PropTypes.func.isRequired,
};

export default MemberSearch;
