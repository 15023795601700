import React from 'react';
import PropTypes from 'prop-types';

import { Heading } from 'rides/components';

const TripWizardStepTitle = ({ children }) => (
  <Heading level={3} textMuted textCenter>
    {children}
  </Heading>
);

TripWizardStepTitle.propTypes = {
  children: PropTypes.node,
};

export default TripWizardStepTitle;
