/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'EncounterTabDefaultXlIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M16.21 4.792V1.917h-2.89C13.103.828 12.122 0 11 0 9.879 0 8.897.827 8.679 1.917h-2.89v2.875H16.21z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M19.526 1.917h-2.368v18.208H4.842V1.917H2.474A.477.477 0 002 2.396V22.52c0 .264.212.479.474.479h17.052a.477.477 0 00.474-.48V2.397a.477.477 0 00-.474-.48z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M14.789 7.667H9.105a.477.477 0 00-.474.479c0 .264.212.479.474.479h5.684a.477.477 0 00.473-.48.477.477 0 00-.473-.478zM14.789 10.542H9.105a.477.477 0 00-.474.479c0 .264.212.479.474.479h5.684a.477.477 0 00.473-.48.477.477 0 00-.473-.478zM14.789 13.417H9.105a.477.477 0 00-.474.479c0 .264.212.479.474.479h5.684a.477.477 0 00.473-.48.477.477 0 00-.473-.478zM14.789 16.292H9.105a.477.477 0 00-.474.479c0 .264.212.479.474.479h5.684a.477.477 0 00.473-.48.477.477 0 00-.473-.478zM7.211 7.667h-.474a.477.477 0 00-.473.479c0 .264.212.479.473.479h.474a.477.477 0 00.474-.48.477.477 0 00-.474-.478zM7.211 10.542h-.474a.477.477 0 00-.473.479c0 .264.212.479.473.479h.474a.477.477 0 00.474-.48.477.477 0 00-.474-.478zM7.211 13.417h-.474a.477.477 0 00-.473.479c0 .264.212.479.473.479h.474a.477.477 0 00.474-.48.477.477 0 00-.474-.478zM7.211 16.292h-.474a.477.477 0 00-.473.479c0 .264.212.479.473.479h.474a.477.477 0 00.474-.48.477.477 0 00-.474-.478z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 23 23',
});
