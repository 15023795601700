import React from 'react';
import PropTypes from 'prop-types';

const ErrorBox = ({ error, children, bsContext = 'danger', textAlign = 'center' }) => (
  <div className={`alert alert-${bsContext} text-${textAlign}`}>
    <p>
      <strong>{error}</strong>
    </p>
    {children}
  </div>
);

ErrorBox.propTypes = {
  children: PropTypes.node,
  error: PropTypes.node,
  bsContext: PropTypes.string,
  textAlign: PropTypes.string,
};

export default ErrorBox;
