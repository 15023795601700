import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import {
  Button,
  Block,
  Column,
  HorizontalRule,
  FormFeedbackBox,
  FormikField,
  Row,
} from 'rides/components';
import { Modal, VendorSelectField } from 'rides/containers';

const MODAL_NAME = 'trip-assign-vendor';

class TripAssignVendorModal extends React.Component {
  onClose = () => {
    this.formik.resetForm();
    this.props.modalHide(MODAL_NAME);
    return false;
  };

  render() {
    const { onSubmit, errorMessage, ...props } = this.props;

    return (
      <Modal
        {...props}
        title="Assign Vendor to Trip"
        name={MODAL_NAME}
        onClose={this.onClose}
        closeable
      >
        <Formik
          ref={input => (this.formik = input)}
          initialValues={{ vendorId: '' }}
          validationSchema={Yup.object().shape({
            vendorId: Yup.string().required('You must select a vendor'),
          })}
          onSubmit={(values, formikBag) => {
            onSubmit(values.vendorId, formikBag, this.onClose);
          }}
          render={formikProps => {
            const disabled = !!formikProps.isSubmitting;

            return (
              <Form>
                <Row>
                  <Column xs={12} sm={10} smOffset={1}>
                    {!disabled &&
                      errorMessage && (
                        <FormFeedbackBox danger>{errorMessage}</FormFeedbackBox>
                      )}

                    <Row>
                      <Column xs={12}>
                        <Row>
                          <Field
                            name="vendorId"
                            label="Vendors"
                            component={FormikField}
                            disabled={disabled}
                            type={VendorSelectField}
                          />
                        </Row>
                      </Column>
                    </Row>
                  </Column>
                </Row>

                <Row>
                  <Column xs={12}>
                    <HorizontalRule />

                    <Block clearfix>
                      <Button type="submit" disabled={disabled} success pullRight>
                        Assign
                      </Button>
                    </Block>
                  </Column>
                </Row>
              </Form>
            );
          }}
        />
      </Modal>
    );
  }
}

TripAssignVendorModal.propTypes = {
  errorMessage: PropTypes.string,
  modalHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default TripAssignVendorModal;
