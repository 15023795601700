import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { lifecycle, withProps } from 'recompose';

import { memberIdType, tripIdType, tripType } from 'rides/types';
import { fromApp } from 'rides/store/selectors';
import { TripGroupDetailPage } from 'rides/components';
import {
  nothingUntilIsReady,
  spinnerWhileIsLoading,
} from 'rides/components/utils/showWhile';
import {
  tripGroupLoadDetailForMember,
  tripWizardInitNewTripLeg,
} from 'rides/store/actions';

const mapDispatchToProps = {
  fetchTripGroup: (memberId, tripGroupId) =>
    tripGroupLoadDetailForMember(memberId, tripGroupId),
  createTripLeg: (memberId, tripGroupId) =>
    tripWizardInitNewTripLeg(memberId, tripGroupId),
};

const mapStateToProps = (state, props) => ({
  memberId: fromApp.member.currentMemberId(state, props),
  tripGroupId: fromApp.trip.currentTripGroupId(state, props),
  rootTrip: fromApp.trip.tripGroupRootTrip(state, props),
  tripList: fromApp.trip.tripGroupTripList(state, props),
  isFetchingTripGroup: fromApp.trip.isLoadingMemberTripGroupDetail(state, props),
  showStaffUI: fromApp.ui.showStaffUI(state, props),
});

const TripGroupDetailPageContainer = props => <TripGroupDetailPage {...props} />;

TripGroupDetailPageContainer.propTypes = {
  createTripLeg: PropTypes.func.isRequired,
  memberId: memberIdType.isRequired,
  tripGroupId: tripIdType.isRequired,
  rootTrip: tripType,
  tripList: PropTypes.arrayOf(tripType),
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withProps(props => ({
    isReady: props.rootTrip && !props.isFetchingTripGroup,
    isLoading: !props.rootTrip || props.isFetchingTripGroup,
    fetchData: (memberId, tripGroupId) => {
      props.fetchTripGroup(memberId, tripGroupId);
    },
    onClickCreateTripLeg: e => {
      e.preventDefault();
      const { createTripLeg, memberId, tripGroupId } = props;
      createTripLeg(memberId, tripGroupId);
    },
  })),
  lifecycle({
    componentWillMount() {
      const { fetchData, memberId, tripGroupId } = this.props;
      fetchData(memberId, tripGroupId);
    },
    componentWillReceiveProps(nextProps) {
      const { fetchData, memberId, tripId } = this.props;
      const nextMemberId = nextProps.memberId;
      const nextTripId = nextProps.tripId;

      if (nextMemberId !== memberId || nextTripId !== tripId) {
        fetchData(nextMemberId, nextTripId);
      }
    },
  }),
  nothingUntilIsReady,
  spinnerWhileIsLoading,
)(TripGroupDetailPageContainer);
