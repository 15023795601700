/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'ErrorOutlineIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M8 14.75a6.75 6.75 0 100-13.5 6.75 6.75 0 000 13.5zM8 16A8 8 0 108 0a8 8 0 000 16zM7.04 4a.96.96 0 111.92 0l-.18 4.5a.78.78 0 01-1.56 0L7.04 4zm.935 6.5a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
