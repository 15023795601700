// https://github.com/diegohaz/arc/wiki/Reducers
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#entities
import mergeWith from 'lodash/mergeWith';
import * as R from 'ramda';
import { initialState } from './selectors';
import {
  ENTITIES_EMPTY_ALL_TYPES,
  ENTITIES_EMPTY_TYPES,
  ENTITIES_RECEIVE,
} from './actions';

export default (state = initialState, { type, payload }) => {
  if (type === ENTITIES_RECEIVE) {
    return mergeWith({}, state, payload, (objValue, srcValue) => {
      if (Array.isArray(srcValue)) {
        return srcValue;
      }
      return undefined;
    });
  }

  if (type === ENTITIES_EMPTY_ALL_TYPES) {
    const { keepEntityTypeList } = payload;
    if (keepEntityTypeList === null) {
      // empty all
      return {};
    }
    if (!Array.isArray(keepEntityTypeList)) {
      // eslint-disable-next-line no-console
      console.error(
        `ERROR[reducer/entities]: keepEntityTypeList should be array but is [${typeof keepEntityTypeList}]`,
      );
    } else {
      return R.pick(keepEntityTypeList, state);
    }
  }

  if (type === ENTITIES_EMPTY_TYPES) {
    const { entityTypeList } = payload;
    if (!Array.isArray(entityTypeList)) {
      // eslint-disable-next-line no-console
      console.error(
        `ERROR[reducer/entities]: entityTypeList should be array but is [${typeof entityTypeList}]`,
      );
    } else {
      return R.omit(entityTypeList, state);
    }
  }

  return state;
};
