import React from 'react';
import PropTypes from 'prop-types';

const TableRow = ({ children, ...props }) => {
  return React.createElement('tr', props, children);
};

TableRow.propTypes = {
  children: PropTypes.any,
};

export default TableRow;
