import React from 'react';

import { memberIdType } from 'rides/types';
import useEntityListLoaderPagination from 'rides/hooks/use-entity-list-loader-pagination';
import {
  Block,
  Well,
  Pagination,
  Heading,
  FormFeedbackBox,
  ActivityLog,
  Button,
  Glyphicon,
} from 'rides/components';
import EntityListLoader from 'rides/components/utils/EntityListLoader';

const MemberActivityLogContainer = ({ memberId }) => {
  const [page, pageSize, getPaginationProps] = useEntityListLoaderPagination();

  return (
    <Block>
      <EntityListLoader
        loadOnMount
        resource={`member/${memberId}/activity_log`}
        entityType="activityLog"
        requestParams={{ page_size: pageSize, page }}
        renderNotAsked={() => null}
        renderLoading={() => <FormFeedbackBox error="Loading..." warning />}
        renderError={() => <FormFeedbackBox error="Failed to load activity log" />}
        renderSuccess={logList => <ActivityLog logList={logList} />}
      >
        {(View, loadData, { pagination, isLoading }) => {
          const paginationProps = getPaginationProps(pagination, isLoading);

          return (
            <Well>
              <Heading level={3} style={{ marginTop: 0 }}>
                Member Activity{' '}
                <Button sm success onClick={loadData}>
                  <Glyphicon icon="refresh" />
                </Button>
              </Heading>
              <Pagination {...paginationProps} />
              {View}
              <Pagination {...paginationProps} />
            </Well>
          );
        }}
      </EntityListLoader>
    </Block>
  );
};

MemberActivityLogContainer.propTypes = {
  memberId: memberIdType,
};

export default MemberActivityLogContainer;
