import React from 'react';
import * as R from 'ramda';

import { memberType } from 'rides/types';
import { convertToReadableMonthDay } from 'rides/utils/dateTime';
import { Block, MemberDetailItem } from 'rides/components';

const MemberTripBenefits = ({ member, ...props }) => {
  const hasChronicCondition = R.path(['hasChronicCondition'], member);
  const tripsAllowed = ~~R.pathOr(0, ['plan', 'tripsAllowed'], member);
  const tripsCompleted = ~~R.pathOr(0, ['completedTrips'], member);
  const tripsScheduled = ~~R.pathOr(0, ['scheduledTrips'], member);
  const planName = R.path(['plan', 'name'], member);
  const benefitStartDate = R.compose(
    convertToReadableMonthDay,
    R.path(['plan', 'startTime']),
  )(member);

  return (
    <Block {...props}>
      <MemberDetailItem label="Plan name" value={planName} />
      <MemberDetailItem label="Benefit period start date" value={benefitStartDate} />
      <MemberDetailItem label="Total trips this period (one-way)" value={tripsAllowed} />
      <MemberDetailItem label="Trips used so far (one-way)" value={tripsCompleted} />
      <MemberDetailItem label="Trips scheduled (one-way)" value={tripsScheduled} />
      <MemberDetailItem label="Member has SSBCI-eligible chronic condition(s)" value={hasChronicCondition ? 'Yes' : 'No'} />
    </Block>
  );
};

MemberTripBenefits.propTypes = {
  member: memberType,
};

export default MemberTripBenefits;
