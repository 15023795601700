/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'ArrowLeftIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M14 7H4.412l3.293-3.293a.999.999 0 10-1.414-1.414l-5 5a1.005 1.005 0 000 1.415l5 5a.997.997 0 001.414 0 .999.999 0 000-1.415L4.412 9H14a1 1 0 100-2z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
