/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'ArrowRightIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M2 7h9.588L8.295 3.707a.999.999 0 111.414-1.414l5 5a1.005 1.005 0 010 1.415l-5 5a.997.997 0 01-1.414 0 .999.999 0 010-1.415L11.588 9H2a1 1 0 110-2z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
