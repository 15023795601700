import React, { useState } from 'react';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';

import { tripType } from 'rides/types';
import {
  Address,
  Atom,
  Block,
  Button,
  Column,
  Companion,
  DateTime,
  Distance,
  Driver,
  Heading,
  PreformattedText,
  Row,
  Small,
  Strong,
  TripType,
  TripPurpose,
  Widget,
  Vendor,
} from 'rides/components';
import { TripLegActionButtons } from 'rides/containers';
import TripStatusDropdown from 'rides/nui/containers/TripStatusDropdown';
import TripTransitionsTable from 'rides/nui/containers/TripTransitionsTable';
import Glyphicon from 'rides/components/atoms/Glyphicon';
import WillCallBadge from 'rides/nui/TripList/WillCallBadge';
import TripRequestPickupButton from 'rides/nui/containers/TripRequestPickupButton';

const StatusDropdownContainer = styled.div`
  float: right;
  width: 140px;
`;

const TripLegCardSubSection = ({ title, children, md }) => {
  return (
    <Column md={md || 3}>
      <Block className="text-muted">
        <Atom className="label label-default">{title}</Atom>
      </Block>
      {children}
    </Column>
  );
};

TripLegCardSubSection.propTypes = {
  children: PropTypes.node,
  md: PropTypes.number,
  title: PropTypes.node,
};

const TripNumberOfCompanions = ({ trip }) => {
  const isNumSet = trip.numberOfCompanions === null;
  return (
    <Block>
      <Small textMuted>Number of Companions:</Small>
      <br />
      {isNumSet ? '--' : <Strong>{trip.numberOfCompanions}</Strong>}
    </Block>
  );
};

TripNumberOfCompanions.propTypes = {
  trip: tripType,
};

const isNonEmptyString = R.both(RA.isString, RA.isNotEmpty);

const hasDriverDetail = R.compose(
  R.any(isNonEmptyString),
  R.props([
    'customDriverPhone',
    'customDriverName',
    'customDriverCarLicense',
    'customDriverCarDescription',
  ]),
);

const TripLegCard = ({ trip, showStatusTransitionUI }) => {
  const [isTransitionsVisible, setIsTransitionsVisible] = useState(false);
  const toggleTransitionsVisibility = () => setIsTransitionsVisible(R.not);

  const widgetClassName = classnames({
    highlighted: window.location.hash === `#leg-${trip.id}`,
  });

  const tripGroupRefNum = trip.groupReferenceNumber || '--';

  return (
    <Widget className={widgetClassName}>
      <Block pullLeft>
        <Heading level={4} className="widget-title">
          {`Trip #${trip.id}`}

          <TripLegActionButtons memberId={trip.member.id} tripId={trip.id}>
            {({ editButton }) => editButton}
          </TripLegActionButtons>

          {!!trip?.isWillCall && <WillCallBadge />}
        </Heading>

        {`Ref #${tripGroupRefNum}`}
      </Block>

      <StatusDropdownContainer>
        <TripStatusDropdown tripId={trip.id} />
        <TripRequestPickupButton tripId={trip.id} />
      </StatusDropdownContainer>

      <Block clearfix />

      <Row style={{ marginTop: '10px' }}>
        <TripLegCardSubSection title="Pickup">
          {trip.pickup.name && (
            <Block>
              <Block className="text-muted">
                <Small>Location Name:</Small>
              </Block>
              {trip.pickup.name}
            </Block>
          )}
          {trip.pickup.phoneNumber && (
            <Block>
              <Block className="text-muted">
                <Small>Phone Number:</Small>
              </Block>
              {trip.pickup.phoneNumber}
            </Block>
          )}
          <Block className="text-muted">
            <Small>Address:</Small>
          </Block>
          <Address
            location={trip.pickup}
            boldStreet
            breakOnStreet
            data-test-handle="pickup address"
          />
        </TripLegCardSubSection>

        <TripLegCardSubSection title="Dropoff">
          {trip.dropoff.name && (
            <Block>
              <Block className="text-muted">
                <Small>Location Name:</Small>
              </Block>
              {trip.dropoff.name}
            </Block>
          )}
          {trip.dropoff.phoneNumber && (
            <Block>
              <Block className="text-muted">
                <Small>Phone Number:</Small>
              </Block>
              {trip.dropoff.phoneNumber}
            </Block>
          )}
          <Block className="text-muted">
            <Small>Address:</Small>
          </Block>
          <Address
            location={trip.dropoff}
            boldStreet
            breakOnStreet
            data-test-handle="dropoff address"
          />
          {trip.distance && (
            <Block>
              <Distance distance={trip.distance} data-test-handle="trip distance" />
            </Block>
          )}
        </TripLegCardSubSection>

        <TripLegCardSubSection title="Pickup Time" md={2}>
          <DateTime datetime={trip.pickupTime} data-test-handle="pickup time" />
        </TripLegCardSubSection>

        <TripLegCardSubSection title="Appointment Time" md={2}>
          <DateTime datetime={trip.appointmentTime} data-test-handle="appointment time" />
        </TripLegCardSubSection>
      </Row>

      <Row className="margin-top-10">
        <TripLegCardSubSection title="Companion">
          <TripNumberOfCompanions trip={trip} />
          <Companion companion={trip.companion} />
        </TripLegCardSubSection>

        <TripLegCardSubSection title="Details">
          {trip.authCode && (
            <Block>
              <Small textMuted>Authorization Code:</Small>
              <Block data-test-handle="auth code">{trip.authCode}</Block>
            </Block>
          )}
          <TripType trip={trip} />
          <TripPurpose trip={trip} />
          <Block>
            <Small textMuted>Pickup Urbanicity:</Small>
            <Block
              data-test-handle="pickup urbanicity"
              style={{ textTransform: 'capitalize' }}
            >
              {trip.pickupUrbanicity}
            </Block>
          </Block>
        </TripLegCardSubSection>

        {trip.vendor && (
          <TripLegCardSubSection title="Vendor">
            <Vendor trip={trip} />
          </TripLegCardSubSection>
        )}

        {hasDriverDetail(trip) && (
          <TripLegCardSubSection title="Driver">
            <Driver trip={trip} />
          </TripLegCardSubSection>
        )}
      </Row>

      <Row>
        <TripLegCardSubSection title="Notes" md={12}>
          <PreformattedText scrollable wrapped>
            {trip.notes}
          </PreformattedText>
        </TripLegCardSubSection>
      </Row>
      {showStatusTransitionUI && (
        <Row>
          <TripLegCardSubSection
            md={12}
            title={
              <>
                Transitions Table{' '}
                <Button link onClick={toggleTransitionsVisibility}>
                  {isTransitionsVisible ? 'Hide' : 'Show'}{' '}
                  <Glyphicon
                    icon={isTransitionsVisible ? 'chevron-down' : 'chevron-right'}
                  />
                </Button>
              </>
            }
          >
            {isTransitionsVisible && <TripTransitionsTable tripId={trip.id} />}
          </TripLegCardSubSection>
        </Row>
      )}
    </Widget>
  );
};

TripLegCard.propTypes = {
  trip: tripType,
  highlightedTrip: PropTypes.string,
  showStatusTransitionUI: PropTypes.bool.isRequired,
};

export default TripLegCard;
