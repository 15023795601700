import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const headerStyleFixes = {
  margin: 'revert',
  fontSize: 'revert',
  fontWeight: 500,
};

function getStyleProp(style) {
  if (typeof style !== 'object') {
    // no style object passed
    return { ...headerStyleFixes };
  }

  return { ...headerStyleFixes, ...style };
}

const Heading = ({
  level,
  children,
  className,
  pullLeft,
  pullRight,
  textCenter,
  textLeft,
  textRight,
  textMuted,
  pageTitle,
  ...props
}) => {
  const classes = classNames(className, {
    'pull-left': pullLeft,
    'pull-right': pullRight,
    'text-center': textCenter,
    'text-left': textLeft,
    'text-right': textRight,
    'text-muted': textMuted,
    'page-title': pageTitle,
  });

  return React.createElement(
    `h${level}`,
    {
      ...props,
      className: classes,
      style: getStyleProp(props.style),
    },
    children,
  );
};

Heading.propTypes = {
  level: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  pullLeft: PropTypes.bool,
  pullRight: PropTypes.bool,
  textCenter: PropTypes.bool,
  textLeft: PropTypes.bool,
  textRight: PropTypes.bool,
  textMuted: PropTypes.bool,
  pageTitle: PropTypes.bool,
};

Heading.defaultProps = {
  level: 1,
};

export default Heading;
