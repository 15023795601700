import React from 'react';
import PropTypes from 'prop-types';
import { FormikField } from 'rides/components';

const MaskableFormikField = ({ maskValue, ...props }) => {
  if (maskValue !== undefined) {
    // MASKING!
    return (
      <FormikField
        {...{
          ...props,
          placeholder: '',
          field: {
            ...props.field,
            value: maskValue,
            disabled: true,
          },
          form: {
            ...props.form,
            touched: {}, // blocks validation errors
          },
        }}
      />
    );
  }

  // not masking
  return <FormikField {...props} />;
};

MaskableFormikField.propTypes = {
  form: PropTypes.shape({
    touched: PropTypes.object,
    error: PropTypes.object,
  }).isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
  }).isRequired,
  maskValue: PropTypes.any,
};

export default MaskableFormikField;
