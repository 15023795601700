import React from 'react';
// import * as R from 'ramda';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import * as ridesTypes from 'rides/types';
import {
  Column,
  FormikField,
  FormFeedbackBox,
  Row,
  TripWizardFooter,
  TripWizardLocationFields,
  TripWizardStepTitle,
} from 'rides/components';

const numCompanionsMask = createNumberMask({
  prefix: '',
  integerLimit: 2,
});

const TripWizardStepPickup = props => (
  <Formik
    initialValues={props.values}
    validationSchema={Yup.object().shape({
      pickup_isHome: Yup.boolean(),
      pickup_isRoundTrip: Yup.boolean(),
      pickup_name: Yup.string(),
      pickup_phoneNumber: Yup.string(),
      pickup_address1: Yup.string().when(['pickup_isHome'], {
        is: isHome => !isHome,
        then: Yup.string().required('Address is required.'),
      }),
      pickup_address2: Yup.string(),
      pickup_city: Yup.string().when(['pickup_isHome'], {
        is: isHome => !isHome,
        then: Yup.string().required('City required.'),
      }),
      pickup_state: Yup.string().when(['pickup_isHome'], {
        is: isHome => !isHome,
        then: Yup.string().required('State required.'),
      }),
      pickup_postalCode: Yup.string().when(['pickup_isHome'], {
        is: isHome => !isHome,
        then: Yup.string()
          // .required('Zip Code is required.')
          .matches(/^\d{5}(?:[-\s]\d{4})?$/, 'Must be 5 or 9 digits'),
      }),
    })}
    onSubmit={values => {
      props.goToNextStep(values);
    }}
    render={formikProps => {
      const disabled = !!formikProps.isSubmitting || props.formDisabled;

      return (
        <Form>
          <Row>
            <Column xs={12} sm={10} smOffset={1}>
              <TripWizardStepTitle>Pickup Location</TripWizardStepTitle>

              {!disabled && props.stepErrors.pickup && (
                <FormFeedbackBox danger>{props.stepErrors.pickup}</FormFeedbackBox>
              )}

              <Row>
                <Column xs={12}>
                  <TripWizardLocationFields
                    fieldPrefix="pickup_"
                    disabled={disabled}
                    maskMemberHome={formikProps.values.pickup_isHome}
                    member={props.member}
                    showHome
                  />
                </Column>
              </Row>

              <TripWizardStepTitle>Trip Information (Optional)</TripWizardStepTitle>
              <Row>
                <Column xs={12} sm={4}>
                  <Field
                    name="numberOfCompanions"
                    label="Number of Companions"
                    placeholder="0"
                    component={FormikField}
                    disabled={disabled}
                    textMask={numCompanionsMask}
                    type="tel"
                  />
                </Column>
              </Row>

              <TripWizardStepTitle>Companion Information (Optional)</TripWizardStepTitle>
              <Row>
                <Column xs={12} sm={4}>
                  <Field
                    name="companion_name"
                    label="Companion Name"
                    component={FormikField}
                    disabled={disabled}
                  />
                </Column>

                <Column xs={12} sm={4}>
                  <Field
                    name="companion_relationship"
                    label="Relationship"
                    component={FormikField}
                    disabled={disabled}
                  />
                </Column>

                <Column xs={12} sm={4}>
                  <Field
                    name="companion_phone"
                    label="Contact Number"
                    component={FormikField}
                    disabled={disabled}
                  />
                </Column>
              </Row>

              <Row>
                <Column xs={12} sm={8}>
                  <Field
                    name="companion_primaryContact"
                    label="Make Primary Contact for Trip"
                    type="checkbox"
                    component={FormikField}
                    disabled={disabled}
                  />
                </Column>

                <Column xs={12} sm={4}>
                  <Field
                    name="companion_phoneType"
                    label="Contact Number Type"
                    type="inline-radio"
                    component={FormikField}
                    radioOptions={[
                      { label: 'Mobile', value: 'Mobile' },
                      { label: 'Home', value: 'Home' },
                      { label: 'Work', value: 'Work' },
                    ]}
                    disabled={disabled}
                  />
                </Column>
              </Row>
            </Column>
          </Row>

          <TripWizardFooter
            {...props}
            values={formikProps.values}
            disabled={disabled}
            showNext
          />
        </Form>
      );
    }}
  />
);

TripWizardStepPickup.propTypes = {
  currentStep: PropTypes.string,
  formDisabled: PropTypes.bool,
  goToNextStep: PropTypes.func.isRequired,
  goToPrevStep: PropTypes.func.isRequired,
  member: ridesTypes.memberType,
  stepErrors: PropTypes.object.isRequired,
  values: PropTypes.object,
};

export default TripWizardStepPickup;
