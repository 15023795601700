import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Downshift from 'downshift';

import { Box, Flex, Text } from 'rides/nui';
import { tripStatuses, tripActions } from 'rides/utils/trip';
import * as ridesTypes from 'rides/types';
import CreateTripStatusTransitionModal from 'rides/containers/CreateTripStatusTransitionModal';
import { Pane, Badge } from 'evergreen-ui';

export const DropdownContainer = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  background-color: #ffffff;
`;

export const MenuContainer = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 1px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #e5e5e5;
  visibility: ${props => (!props.isOpen ? 'hidden' : 'visible')};
  position: absolute;
  z-index: 10;
  visibility: ${props => (!props.isOpen ? 'hidden' : 'visible')};
`;

export const MenuItemWrapper = styled(Flex)`
  padding: 6px 9px 6px 9px;
  justify-content: stretch;
  user-select: none;
  flex-wrap: nowrap;
  border-radius: 3px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGray};
    cursor: pointer;
  }
`;

const MenuItem = ({ color, label, ...props }) => {
  return (
    <MenuItemWrapper>
      <Box color={color} mr="6px" style={{ height: 16 }}>
        <svg width={14} height={16} fill="currentColor">
          <g shapeRendering="auto">
            <circle cx="50%" cy="7" r="7" />
          </g>
        </svg>
      </Box>
      <Text
        color={color}
        lineHeight="16px"
        fontSize={12}
        fontWeight="bold"
        flex="1 1 auto"
        style={{ whiteSpace: 'nowrap' }}
      >
        {label}
      </Text>
    </MenuItemWrapper>
  );
};

MenuItem.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
};

const VendorNotConfirmedLabel = () => (
  <Pane paddingY="0.5em">
    <Badge color="orange" isSolid marginRight={8} height={33} textTransform="none">
      Vendor Not Confirmed
    </Badge>
  </Pane>
);

export function VendorNotConfirmedNotice({ trip, forceShow = false }) {
  if (!forceShow) {
    if (!trip) return null;

    const canConfirm = trip.permissions && trip.permissions.canConfirmVendor;
    const showNotice = canConfirm && !trip.isVendorConfirmed;
    if (!showNotice) return null;
    return <VendorNotConfirmedLabel />;
  }

  return <VendorNotConfirmedLabel />;
}

function TripStatusDropdown({
  tripId,
  memberId,
  trip,
  actionOptions,
  onChange,
  activeDialog,
  hideVendorNotConfirmed,
  disabled,
}) {
  const tripStatus = tripStatuses[trip.status];

  return (
    <>
      <Downshift
        itemToString={i => (!i || i.label == null ? '' : String(i.label))}
        onChange={item => onChange(item.value)}
      >
        {({ getItemProps, isOpen, getToggleButtonProps, getMenuProps }) => (
          <div style={{ width: '100%' }}>
            <DropdownContainer>
              <Box {...getToggleButtonProps({ disabled })}>
                <MenuItem label={tripStatus.label} color={tripStatus.color} />
              </Box>
            </DropdownContainer>

            <MenuContainer isOpen={isOpen} {...getMenuProps({ disabled })}>
              <Flex flexDirection="column">
                {actionOptions.map(status => {
                  const item = {
                    value: status,
                    ...tripActions[status],
                  };
                  return (
                    <Box key={item.label} {...getItemProps({ item, disabled })}>
                      <MenuItem label={item.label} color={item.color} />
                    </Box>
                  );
                })}
              </Flex>
            </MenuContainer>
          </div>
        )}
      </Downshift>
      {/* {showVendorNotConfirmed && <VendorNotConfirmedLabel />} */}
      {!hideVendorNotConfirmed && <VendorNotConfirmedNotice trip={trip} />}

      <CreateTripStatusTransitionModal
        tripId={tripId}
        memberId={memberId}
        status={activeDialog}
        onClose={() => onChange(null)}
      />
    </>
  );
}

TripStatusDropdown.propTypes = {
  trip: ridesTypes.tripType.isRequired,
  actionOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

TripStatusDropdown.defaultProps = {
  onChange: () => { },
};

export default TripStatusDropdown;
