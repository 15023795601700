import React from 'react';
import PropTypes from 'prop-types';

import { locationType } from 'rides/types';
import { Address, Atom, Distance, Glyphicon, Small } from 'rides/components';

const TripWaypoints = ({ trip, ...props }) => {
  const { dropoff, pickup } = trip;
  return (
    <Atom {...props}>
      <Address location={pickup} /> <Glyphicon icon="arrow-right" small />{' '}
      <Address location={dropoff} />{' '}
      {trip.distance && (
        <Small className="text-muted">
          (<Distance distance={trip.distance} />)
        </Small>
      )}
    </Atom>
  );
};

TripWaypoints.propTypes = {
  trip: PropTypes.shape({
    pickup: locationType.isRequired,
    dropoff: locationType.isRequired,
  }),
};

export default TripWaypoints;
