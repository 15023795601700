import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon as CareCarIcon } from '@carecar/react-icons';
import { Box } from 'rides/nui';

const IconWrapper = styled(Box)({
  display: 'inline-block',
  'vertical-align': 'middle',
});

const Icon = ({ name, ...props }) => {
  // prevent crashing error if no name passed
  if (!name) return null;
  return <IconWrapper {...props}>{name && <CareCarIcon icon={name} />}</IconWrapper>;
};

Icon.displayName = 'Icon';

Icon.propTypes = {
  /* Icon Name */
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default Icon;
