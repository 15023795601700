/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'EncounterNotesXlIcon',
  path: (
    <g fill="currentColor">
      <g clip-path="url(#clip0)">
        <path
          fill-rule="evenodd"
          d="M11.79 0H1.684C.758 0 0 .818 0 1.818v12.728h1.684V1.818H11.79V0zm2.526 3.636H5.053c-.927 0-1.685.819-1.685 1.819v12.727c0 1 .758 1.818 1.685 1.818h9.263c.926 0 1.684-.818 1.684-1.818V5.455c0-1-.758-1.819-1.684-1.819zm0 14.546H5.053V5.455h9.263v12.727z"
          clip-rule="evenodd"
        />
        <path
          stroke-width="1.5"
          d="M23.177 16.738a6.488 6.488 0 10-12.976 0 6.488 6.488 0 0012.976 0z"
        />
        <path d="M18.11 19.206h-2.842V14.27h2.842v.857h-1.795v1.084h1.67v.857h-1.67v1.273h1.795v.865z" />
        <path
          fill-rule="evenodd"
          d="M16.689 10a6.738 6.738 0 110 13.475 6.738 6.738 0 010-13.475zm0 1a5.738 5.738 0 110 11.476 5.738 5.738 0 010-11.476z"
          clip-rule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h23v23H0z" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 23 23',
});
