import PropTypes from 'prop-types';
import * as ridesTypes from 'rides/types';
import * as dt from 'rides/utils/dateTime';
import { isFunc } from 'rides/utils/data';

const DateTime = ({ datetime, format, render }) => {
  const fmt = dt.convertToFormat(datetime, format);
  return isFunc(render) ? render(fmt) : fmt;
};

DateTime.propTypes = {
  datetime: ridesTypes.datetimeType,
  format: PropTypes.string.isRequired,
  render: PropTypes.func,
};

export default DateTime;
