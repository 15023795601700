import { getHours, getMinutes, getSeconds, isDate, set } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { getTimezone } from './dateTime';

function getCurrentTenantTimeZone() {
  const currentTenantTimeZone = getTimezone();
  return currentTenantTimeZone;
}

/**
 * Takes a date object and returns clone with the time to be the current time
 * (i.e. hour:min:sec) while respecting current timezone.
 *
 * @param date {Date|string} Date .toISOString()
 */
function setTimeToNow(date) {
  const now = new Date();

  let jsDate = date;
  if (!isDate(date)) {
    jsDate = toDate(date);
  }

  // NOTE: We need to set the time on the original date object
  //       so the time zone offset used is based on original date.
  //       If we didn't do this it could result incorrect times
  //       from differences between now and original date DST offset.
  const utcDateNowTime = set(jsDate, {
    minutes: getMinutes(now),
    hours: getHours(now),
    seconds: getSeconds(now),
  });

  return utcDateNowTime.toISOString();
}

export { setTimeToNow, getCurrentTenantTimeZone };
