/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'TaskFilledIcon',
  path: (
    <g fill="currentColor">
      <path d="M0 0h16v16H0z" />
    </g>
  ),
  viewBox: '0 0 16 16',
});
