import React from 'react';
import {
  Avatar,
  Button,
  Icon,
  IconButton,
  Menu,
  Pane,
  Popover,
  Position,
  SelectMenu,
  Spinner,
  Strong,
  Table,
  Text,
  minorScale,
} from 'evergreen-ui';
import { useTable, usePagination } from 'react-table';
import { useGetTenantUrl } from 'rides/hooks/use-tenant';
import { Link } from 'rides/nui';

function RowMenu() {
  return (
    <Menu>
      <Menu.Group>
        <Menu.Item>Share...</Menu.Item>
        <Menu.Item>Move...</Menu.Item>
        <Menu.Item secondaryText="⌘R">Rename...</Menu.Item>
      </Menu.Group>
      <Menu.Divider />
      <Menu.Group>
        <Menu.Item intent="danger">Delete...</Menu.Item>
      </Menu.Group>
    </Menu>
  );
}

function AdminUserListTable({
  data,
  fetchData,
  isFetching,
  totalPages,
  totalEntries,
  controlledPageSize,
  controlledPageIndex,
}) {
  const getTenantUrl = useGetTenantUrl();
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ cell: { value, row } }) => (
          <>
            <Avatar name={value} />
            <Text marginLeft={8} size={300} fontWeight={500}>
              <Link to={getTenantUrl(`/admin/user/${row.original.id}`)}>{value}</Link>
            </Text>
          </>
        ),
        textCell: false,
        cellProps: { flexBasis: 100, flexShrink: 3, flexGrow: 1 },
      },
      {
        Header: 'Email',
        accessor: 'email',
        cellProps: { flexBasis: 100, flexShrink: 1, flexGrow: 3 },
      },
      {
        Header: 'Admin',
        accessor: 'isAdmin',
        Cell: ({ cell: { value } }) => (
          <UserFlagIcon icon="take-action" label="Admin" enabled={value} />
        ),
        cellProps: { flexBasis: 50, flexShrink: 0, flexGrow: 0 },
      },
      {
        Header: 'Dev',
        accessor: 'isDeveloper',
        Cell: ({ cell: { value } }) => (
          <UserFlagIcon icon="git-new-branch" label="Developer" enabled={value} />
        ),
        cellProps: { flexBasis: 50, flexShrink: 0, flexGrow: 0 },
      },
      {
        Header: 'Disabled',
        accessor: 'isDisabled',
        Cell: ({ cell: { value } }) => (
          <UserFlagIcon
            icon="disable"
            label="Admin"
            enabled={value}
            color={value ? 'red' : 'disabled'}
          />
        ),
        cellProps: { flexBasis: 50, flexShrink: 0, flexGrow: 0 },
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: controlledPageSize,
        pageIndex: controlledPageIndex,
      }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: totalPages,
      debug: true,
    },
    usePagination,
  );

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [pageIndex, pageSize]);

  React.useEffect(() => {
    gotoPage(controlledPageIndex);
  }, [controlledPageIndex]);

  const paginationProps = {
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageIndex,
    pageSize,
    totalEntries,
    pageOptions,
    isFetching,
    shownEntries: page.length,
  };

  // Render the UI for your table
  const tableHeaders = headerGroups[0].headers;
  return (
    <>
      <Pane display="flex" padding={16} background="tint2" borderRadius={3}>
        <Pane flex={1} alignItems="center" display="flex">
          <Pagination {...paginationProps} />
        </Pane>
      </Pane>
      <Table {...getTableProps()} border>
        <Table.Head>
          {tableHeaders.map(column => {
            const columnCellProps = column.cellProps || {};
            return (
              <Table.TextHeaderCell {...column.getHeaderProps(columnCellProps)}>
                {column.render('Header')}
              </Table.TextHeaderCell>
            );
          })}
          <Table.HeaderCell width={48} flex="none">
            {' '}
          </Table.HeaderCell>
        </Table.Head>
        <Table.Body {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);

            return (
              <Table.Row {...row.getRowProps()}>
                {row.cells.map(cell => {
                  const isTextCell = cell.column.textCell !== false;
                  const columnCellProps = cell.column.cellProps || {};
                  const TabelCell = isTextCell ? Table.TextCell : Table.Cell;
                  const cellProps = cell.getCellProps(columnCellProps);

                  return <TabelCell {...cellProps}>{cell.render('Cell')}</TabelCell>;
                })}

                <Table.Cell width={48} flex="none">
                  <Popover content={<RowMenu />} position={Position.BOTTOM_RIGHT}>
                    <IconButton icon="more" height={24} appearance="minimal" disabled />
                  </Popover>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <Pagination {...paginationProps} />
    </>
  );
}

const Pagination = ({
  canPreviousPage,
  canNextPage,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  pageIndex,
  pageSize,
  shownEntries,
  totalEntries,
  pageOptions,
  isFetching,
}) => {
  // Pagination can be built however you'd like.
  // This is just a very basic UI implementation:
  const pageNumber = !pageCount ? 0 : pageIndex + 1;
  const pageTotal = pageCount || 0;

  return (
    <Pane display="flex" justifyContent="space-between" width="100%" alignItems="center">
      <Pane display="flex" backgroundColor="white" padding={8}>
        <PageButton
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          icon="double-chevron-left"
          marginLeft={0}
        />

        <PageButton
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          icon="chevron-left"
          marginLeft={0}
        />

        <Pane width={220} display="flex" alignItems="center" justifyContent="center">
          {/* <Text>Page </Text> */}
          <Text size={400}>Page</Text>
          <SelectMenu
            hasFilter={false}
            title="Go to page"
            options={pageOptions.map(pageIndex => {
              const value = String(pageIndex + 1);
              return { label: String(value), value, disabled: value === pageNumber };
            })}
            selected={`${pageNumber}`}
            onSelect={item => gotoPage(item.value - 1)}
            closeOnSelect
          >
            <PageButton
              appearance="default"
              iconAfter="caret-down"
              marginLeft={8}
              marginRight={8}
            >
              <Text size={400}>{pageNumber}</Text>
            </PageButton>
          </SelectMenu>
          <Text size={400}> of {pageTotal}</Text>
        </Pane>

        <PageButton
          onClick={() => nextPage()}
          disabled={!canNextPage}
          icon="chevron-right"
          marginRight={0}
        />

        <PageButton
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          icon="double-chevron-right"
          marginRight={0}
        />
      </Pane>

      {isFetching ? (
        <Spinner size={32} />
      ) : (
        <Text size={400}>
          Showing <Strong>{shownEntries}</Strong> of <Strong>{totalEntries}</Strong> rows
        </Text>
      )}

      <SelectMenu
        hasFilter={false}
        title="Number of results shown"
        options={['10', '20', '30', '40', '50'].map(optPageSize => ({
          label: optPageSize,
          value: optPageSize,
          disabled: optPageSize === pageSize,
        }))}
        selected={`${pageSize}`}
        onSelect={item => setPageSize(Number(item.value))}
        closeOnSelect
      >
        <PageButton
          appearance="default"
          iconAfter="caret-down"
          marginLeft={0}
          marginRight={0}
          height={24}
        >
          Show {pageSize}
        </PageButton>
      </SelectMenu>
    </Pane>
  );
};

const UserFlagIcon = ({ enabled = false, label = 'FLAG', ...props }) => (
  <Icon
    size={24}
    title={`${label}: ${enabled ? 'enabled' : 'disabled'}`}
    color={enabled ? 'info' : 'disabled'}
    {...props}
  />
);

const PageButton = ({ icon, children, ...props }) => {
  const marginX = minorScale(3);
  return (
    <Button appearance="primary" marginLeft={marginX} marginRight={marginX} {...props}>
      {children || <Icon icon={icon} />}
    </Button>
  );
  return <IconButton appearance="primary" marginRight={minorScale(3)} {...props} />;
};

export default AdminUserListTable;
