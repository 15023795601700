import * as R from 'ramda';
import { createSelector } from 'reselect';

import * as reduxSagaThunk from 'redux-saga-thunk';

import { fromEntities } from 'rides/store/selectors';

/**
 * MEMBER ID
 */
export const memberIdProp = (state, props) => R.path(['memberId'], props);

export const memberIdRouteParam = (state, props) =>
  R.path(['match', 'params', 'memberId'], props);

export const currentMemberIdRaw = createSelector(
  [memberIdProp, memberIdRouteParam],
  R.defaultTo,
);

export const currentMemberId = createSelector(
  [currentMemberIdRaw],
  // NOTE memberId alwayys coerced to string
  memberId => (memberId ? `${memberId}` : null),
);

/**
 * RESOURCES
 */
export const memberResource = () => 'member';

/**
 * MEMBER
 */

export const currentMember = (state, props) => {
  const memberId = currentMemberId(state, props);
  return fromEntities.getDetail(state, 'member', memberId);
};

/**
 * STATUS
 */

const resourceDetailReadThunkId = resource => `${resource}DetailRead`;
const resourceListReadThunkId = resource => `${resource}ListRead`;

export const isLoadingMemberDetail = (state, props) => {
  const resource = memberResource(state, props);
  const thunkId = resourceDetailReadThunkId(resource);

  return reduxSagaThunk.pending(state, thunkId);
};

export const isLoadingMemberList = (state, props) => {
  const resource = memberResource(state, props);
  const thunkId = resourceListReadThunkId(resource);

  return reduxSagaThunk.pending(state, thunkId);
};
