/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'OfficeDrIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M1.104 12.968v.272c0 .152.123.272.275.272h13.242c.152 0 .276-.12.276-.272v-.272H1.104z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M15.724 12.968H.276A.276.276 0 000 13.24v1.088c0 .152.124.272.276.272h15.448c.152 0 .276-.12.276-.272V13.24a.276.276 0 00-.276-.272zM3.31 5.896h1.656V4.264H3.31v1.632zM11.035 10.248h1.655V8.616h-1.655v1.632zM6.621 12.424H9.38V8.616H6.62v3.808zM3.31 10.248h1.656V8.616H3.31v1.632zM11.035 5.896h1.655V4.264h-1.655v1.632z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M13.241 6.168a.273.273 0 01-.275.272h-2.207a.273.273 0 01-.276-.272V3.992c0-.147.123-.272.276-.272h2.207c.152 0 .275.125.275.272v2.176zm0 4.352a.273.273 0 01-.275.272h-2.207a.273.273 0 01-.276-.272V8.344c0-.147.123-.272.276-.272h2.207c.152 0 .275.125.275.272v2.176zm-3.31-3.808a.273.273 0 01-.276.272.273.273 0 01-.276-.272v-1.36H6.621v1.36a.273.273 0 01-.276.272.273.273 0 01-.276-.272V2.904a.276.276 0 01.552 0v1.904h2.758V2.904a.276.276 0 01.552 0v3.808zm-4.414-.544a.273.273 0 01-.276.272H3.034a.273.273 0 01-.275-.272V3.992c0-.147.123-.272.275-.272h2.207c.153 0 .276.125.276.272v2.176zm0 4.352a.273.273 0 01-.276.272H3.034a.273.273 0 01-.275-.272V8.344c0-.147.123-.272.275-.272h2.207c.153 0 .276.125.276.272v2.176zm9.104-7.888h-3.587v-1.36A.276.276 0 0010.76 1H5.24a.276.276 0 00-.275.272v1.36H1.379a.276.276 0 00-.275.272v9.248c0 .152.123.272.275.272h4.414c.152 0 .276-.12.276-.272V8.344c0-.147.124-.272.276-.272h3.31c.153 0 .276.125.276.272v3.808c0 .152.124.272.276.272h4.414c.152 0 .276-.12.276-.272V2.904a.276.276 0 00-.276-.272z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
