/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'MembersLgIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M0 10c0 1.031.28 1.648.833 1.84v3.874h3.334V11.84c.552-.193.832-.809.833-1.84V6.667H0V10zM15 6.667V10c0 1.031.28 1.648.833 1.84v3.874h3.334V11.84c.552-.193.832-.809.833-1.84V6.667h-5zM6.666 11.905c0 1.15.717 2.112 1.667 2.333V20h3.333v-5.762c.95-.22 1.667-1.183 1.667-2.333V6.667H6.666v5.238zM17.5 5.714c.92 0 1.667-.855 1.667-1.904 0-1.05-.747-1.905-1.666-1.905-.92 0-1.667.855-1.667 1.905 0 1.049.747 1.904 1.667 1.904zM2.5 5.714c.92 0 1.667-.855 1.667-1.904 0-1.05-.747-1.905-1.666-1.905-.92 0-1.667.855-1.667 1.905 0 1.049.747 1.904 1.667 1.904zM10 5.714c1.378 0 2.5-1.282 2.5-2.857C12.5 1.282 11.378 0 10 0S7.5 1.282 7.5 2.857c0 1.575 1.122 2.857 2.5 2.857z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
});
