/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'FileIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M2.5 14.5v-13H9v4.041c0 .345.29.625.648.625l3.852-.023V14.5h-11zM1 1.333C1 .597 1.627 0 2.4 0h8.175a1 1 0 01.69.276l3.425 3.262a1 1 0 01.31.724v10.405c0 .736-.627 1.333-1.4 1.333H2.4c-.773 0-1.4-.597-1.4-1.333V1.333z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
