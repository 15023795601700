import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as thunk } from 'redux-saga-thunk';

import app from './app/reducer';
import auth from './auth/reducer';
import confirm from './confirm/reducer';
import entities from './entities/reducer';
import modal from './modal/reducer';
import resource from './resource/reducer';

const reduxModulesReducers = {
  app,
  auth,
  confirm,
  entities,
  modal,
  resource,
};

const libReducers = {
  thunk,
};

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    ...libReducers,
    ...reduxModulesReducers,
  });

export default createRootReducer;
