import { create } from 'apisauce';

import config from 'rides/config';
import { requestStripAuthToken } from './transformers';
import { monitorSentryStructuredMetadata } from './monitors';

export function createApi(settings = {}) {
  const api = create({ baseURL: config.apiUrl, ...settings });

  /**
   * Custom Header Methods
   **/
  api.setAuthToken = token => {
    api.setHeader('X-Auth-Token', token);
  };

  api.setTenantId = tenantId => {
    api.setHeader('carecar-tenant-id', tenantId);
  };

  // request transforms
  api.addRequestTransform(requestStripAuthToken);

  // Monitory to set structured metadata for Sentry perf tracing
  api.addMonitor(monitorSentryStructuredMetadata);

  /**
   * Dev Tool Stuff
   **/
  if (config.devTools.reactotron) {
    const Reactotron = require('reactotron-react-js').default;
    api.addMonitor(Reactotron.apisauce);
  }

  if (config.devTools.authTokenMakeStale) {
    // TODO remove after some point?
    window.authTokenMakeStale = () => {
      console.log('authTokenMakeStale');
      api.setAuthToken(config.staleAuthToken);
    };
  }

  return api;
}
