import React, { useEffect } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pending } from 'redux-saga-thunk';
import PropTypes from 'prop-types';

import * as ridesTypes from 'rides/types';
import { fromApp } from 'rides/store/selectors';
import { resourceListReadRequest } from 'rides/store/actions';
import TripTransitionsTable from 'rides/nui/TripTransitionsTable';

const transitionListResourceUrl = tripId => `trip/${tripId}/status_transition`;

const mapStateToProps = (state, props) => {
  const tripId = fromApp.trip.currentTripId(state, props);
  const resourceUrl = transitionListResourceUrl(tripId);
  const thunkName = `${resourceUrl}ListRead`;

  return {
    transitionList: fromApp.trip.tripStatusTransitionList(state, props),
    isLoadingTransitionList: pending(state, thunkName),
  };
};

const mapDispatchToProps = dispatch => ({
  loadTransitionList: tripId =>
    dispatch(
      resourceListReadRequest(
        transitionListResourceUrl(tripId),
        null,
        'tripStatusTransition',
      ),
    ),
});

function TripTransitionsTableContainer({
  tripId,
  transitionList,
  isLoadingTransitionList,
  loadTransitionList,
}) {
  console.log('transitionList', transitionList);

  useEffect(() => {
    loadTransitionList(tripId);
  }, [tripId, loadTransitionList]);

  if (isLoadingTransitionList) {
    return <div>Loading...</div>;
  }

  return <TripTransitionsTable tripId={tripId} transitionList={transitionList} />;
}

TripTransitionsTableContainer.propTypes = {
  // Passed props
  // technically tripId, see note below

  // Connected props
  // NB: tripId is connected and could theoretically be grabbed from the URL,
  // but currently the only routes for trips contain a tripGroupId instead of individual tripIds.
  tripId: ridesTypes.tripIdType.isRequired,
  transitionList: PropTypes.arrayOf(ridesTypes.tripStatusTransitionType).isRequired,
  isLoadingTransitionList: PropTypes.bool.isRequired,
  loadTransitionList: PropTypes.func.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(TripTransitionsTableContainer);
