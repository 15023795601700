import { createGlobalStyle } from 'styled-components';
import motion from './motion';

// Styled Design System

export const maxContainerWidth = '1280px';

const space = [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 64, 128, 256];

export const radii = [1, 2, 3, 6, 16];
export const borders = [
  //
  `1px solid`,
  `1px dashed`,
];

// --
// -- TYPOGRAPHY
// --

export const font = 'Muli, system-ui';

export const fontSizes = [12, 14, 16, 20, 24, 32, 48];

export const regular = 400;
export const bold = 600;

export const fontWeights = {
  regular,
  bold,
};

export const letterSpacings = {
  normal: 'normal',
  caps: '0.025em',
};

// --
// -- COLORS
// --

const black = '#000000';
const white = '#ffffff';

const darkGray = '#2D2D2D';
const gray = '#5F6368';
const mediumGray = '#77797a';
const lightGray = '#f5f5f5';

const blue = '#1976d2';
const green = '#00c853';
const magenta = '#d21976';
const orange = '#f9a825';
const orangeDark = '#d27619';
const red = '#b71c1c';
const violet = '#7619d2';

export const colors = {
  black,
  white,
  darkGray,
  gray,
  mediumGray,
  lightGray,
  blue,
  orangeDark,
  green,
  magenta,
  orange,
  red,
  violet,
};

export const boxShadows = [
  `0 2px 4px 0 #E5E5E5`,
  `0 2px 4px 0 #D4D2D2`,
  `0 2px 4px 0 #979797`,
];

const theme = {
  space,
  fontSizes,
  colors,
  radii,

  fonts: {
    sans: 'Muli, system-ui',
  },

  fontWeights,

  shadows: boxShadows,

  // animation
  motion,

  // Variants (Rebass feature only Button & Card support)
  buttons: {
    primary: {
      // NOTE We use the css '&&' selector to override bootstrap's styles loaded for old UI
      //      For more info https://www.styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
      '&&': {
        color: white,
        backgroundColor: blue,
        borderColor: blue,
      },
    },
    outline: {
      '&&': {
        color: blue,
        backgroundColor: white,
        borderColor: blue,
        '&:hover': {
          color: blue,
          backgroundColor: lightGray,
        },
      },
    },
    link: {
      '&&': {
        color: blue,
        backgroundColor: white,
        borderColor: white,
        '&:hover': {
          color: blue,
          backgroundColor: lightGray,
        },
      },
    },
  },

  // Component Specific Styling
  Box: {
    fontFamily: font,
  },

  Button: {
    whiteSpace: 'nowrap',
    textAlign: 'center',
    lineHeight: 'inherit',
    textDecoration: 'none',
    py: '4px',
    px: '18px',
    lineHeight: '20px',
    fontSize: 14,
    fontWeight: 'bold',
    borderRadius: '3px',
    border: '1px solid',

    '&[disabled]': {
      opacity: 0.5,
    },
  },

  Link: {},
};

// Global Styles
export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Muli:400,600,700&subset=latin-ext,vietnamese');

  /**
   * :focus outline fix - relies on https://www.npmjs.com/package/focus-visible
   *
   * This will hide the focus indicator if the element receives focus via the mouse,
   * but it will still show up on keyboard focus.
   */
  #not-evo-root .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }
  #not-evo-root .js-focus-visible .focus-visible {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 105, 180, 0.5);
  }
`;

export default theme;
