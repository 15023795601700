/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'ActivityXlIcon',
  path: (
    <g fill="currentColor">
      <g clip-path="url(#clip0)">
        <path
          fill-rule="evenodd"
          d="M10.026 7.65a.5.5 0 01.499-.463h.042a.5.5 0 01.498.45l.434 4.342 3.102 1.773a.5.5 0 01.252.434v.014a.5.5 0 01-.631.482L9.98 13.525a.5.5 0 01-.367-.52l.412-5.356z"
          clip-rule="evenodd"
        />
        <path
          fill-rule="evenodd"
          d="M6.224.145a.5.5 0 00-.869.205l-1.15 4.821a.5.5 0 00.515.615l4.959-.28a.5.5 0 00.355-.821l-1.217-1.45a8.615 8.615 0 012.79-.461 8.62 8.62 0 11-8.62 8.62c0-.8.108-1.582.32-2.335l-1.953-.547a10.657 10.657 0 00-.395 2.882c0 5.88 4.767 10.648 10.648 10.648 5.88 0 10.648-4.768 10.648-10.648 0-5.881-4.767-10.648-10.648-10.648-1.48 0-2.888.301-4.168.846L6.224.145z"
          clip-rule="evenodd"
        />
        <path d="M5.355.35l.243.058L5.355.35zm.87-.205l.19-.16-.19.16zm-2.02 5.026l-.243-.058.243.058zm.515.615l-.014-.25.014.25zm4.959-.28l-.014-.25.014.25zm.355-.821l.191-.161-.191.16zm-1.217-1.45l-.08-.237-.346.118.234.28.192-.161zm-5.51 5.824l.24.068.068-.24-.241-.068-.068.24zm-1.953-.547l.067-.241-.24-.068-.068.241.24.068zm6.085-6.92l-.192.161.12.142.17-.073-.098-.23zM5.599.408a.25.25 0 01.434-.102l.383-.322a.75.75 0 00-1.304.308l.486.116zM4.448 5.23l1.15-4.82-.487-.117-1.15 4.821.486.116zm.257.308a.25.25 0 01-.258-.308l-.486-.116a.75.75 0 00.772.923l-.028-.5zm4.959-.281l-4.96.28.029.5 4.959-.281-.028-.5zm.177-.41a.25.25 0 01-.177.41l.028.499a.75.75 0 00.532-1.231l-.383.321zm-1.217-1.45l1.217 1.45.383-.322-1.217-1.45-.383.321zm2.982-.872c-.992 0-1.959.163-2.87.474l.16.473a8.365 8.365 0 012.71-.447v-.5zm8.87 8.87a8.87 8.87 0 00-8.87-8.87v.5a8.37 8.37 0 018.37 8.37h.5zm-8.87 8.87a8.87 8.87 0 008.87-8.87h-.5a8.37 8.37 0 01-8.37 8.37v.5zm-8.87-8.87a8.87 8.87 0 008.87 8.87v-.5a8.37 8.37 0 01-8.37-8.37h-.5zm.329-2.402a8.879 8.879 0 00-.329 2.402h.5c0-.777.105-1.537.31-2.267l-.481-.135zm-1.78-.24L3.24 9.3l.135-.481L1.42 8.27l-.135.481zm-.077 2.642c0-.976.134-1.92.385-2.815l-.481-.135a10.907 10.907 0 00-.404 2.95h.5zm10.398 10.398c-5.743 0-10.398-4.656-10.398-10.398h-.5c0 6.018 4.88 10.898 10.898 10.898v-.5zm10.398-10.398c0 5.742-4.655 10.398-10.398 10.398v.5c6.019 0 10.898-4.88 10.898-10.898h-.5zM11.607.996c5.743 0 10.398 4.655 10.398 10.398h.5c0-6.02-4.88-10.898-10.898-10.898v.5zm-4.07.826a10.364 10.364 0 014.07-.826v-.5c-1.513 0-2.955.308-4.266.866l.196.46zM6.033.306l1.214 1.447.383-.321L6.416-.016l-.383.322z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h23v23H0z" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 23 23',
});
