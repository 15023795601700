/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'TriangleDownIcon',
  path: (
    <g fill="currentColor">
      <path fill-rule="evenodd" d="M3 5l4.5 6L12 5H3z" clip-rule="evenodd" />
    </g>
  ),
  viewBox: '0 0 16 16',
});
