/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'ArrowExternalIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M11.021 4.536a1 1 0 011 1v6.07h-1.378V6.889l-6.62 6.62-.974-.974 6.62-6.62H4.95V4.536h6.071z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
