import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fromApp } from 'rides/store/selectors';
import {
  tripWizardClose,
  tripWizardGoToNextStep,
  tripWizardGoToPrevStep,
  tripWizardInitNewTrip,
} from 'rides/store/actions';

import { TripWizardModal } from 'rides/components';

const mapDispatchToProps = {
  tripWizardClose,
  tripWizardGoToNextStep,
  tripWizardGoToPrevStep,
  tripWizardInitNewTrip,
};

const mapStateToProps = (state, props) => ({
  currentStep: fromApp.tripWizard.currentStep(state, props),
  formDisabled: fromApp.tripWizard.formDisabled(state, props),
  member: fromApp.tripWizard.member(state, props),
  memberId: fromApp.tripWizard.memberId(state, props),
  stepErrors: fromApp.tripWizard.stepErrors(state, props),
  tripGroupId: fromApp.tripWizard.tripGroupId(state, props),
  tripId: fromApp.tripWizard.tripId(state, props),
  values: fromApp.tripWizard.values(state, props),
});

const TripWizardModalContainer = props => {
  // console.log('TripWizardModalContainer', { props });
  return <TripWizardModal {...props} />;
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(TripWizardModalContainer);
