import React from 'react';
import * as R from 'ramda';

import { tripType } from 'rides/types';
import { Block, Small } from 'rides/components';

const TripPurpose = ({ trip, ...props }) => {
  if (!trip.purpose) return null;
  const purpose = R.path(['purpose', 'name'], trip);

  return (
    <Block {...props}>
      <Small className="text-muted">Purpose:</Small>
      <br />
      {purpose}
    </Block>
  );
};

TripPurpose.propTypes = {
  trip: tripType,
};

export default TripPurpose;
