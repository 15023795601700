import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { Block, Column, FormikField, Row, Label } from 'rides/components';
import Icon from 'rides/evo/components/Icon/Icon';
import { Collapse } from '@chakra-ui/react';

export function fieldLabelTypoFixes(field) {
  if (field === "I Mild cognitive impairment") {
    return "Mild cognitive impairment"
  }

  return field;
}


const heightFeetMask = createNumberMask({
  prefix: '',
  integerLimit: 1,
  suffix: ` Feet`,
});

const heightInchesMask = createNumberMask({
  prefix: '',
  integerLimit: 2,
  suffix: ` Inches`,
});

const weightMask = createNumberMask({
  prefix: '',
  integerLimit: 4,
  suffix: ` Lbs`,
});

const ambulatoryStatusOptions = [
  //
  'Ambulatory',
  'Ambulatory Dependent',
  'Non-Ambulatory',
];

const devicesAndEquipmentFields = [
  'Cane',
  'Rollator or Walker',
  'Manual Wheelchair',
  'Power Wheelchair',
  'Wheelchair needed',
  'Gurney',
  'Oxygen',
  'Oxygen Needed',
];

const wheelChairSizeOptions = [
  'Standard (21 in or less)',
  'Wide (22-32 in)',
  'Extra Wide (32 in or more)',
];

const systemLimitationsFields = ['Blind or vision impaired', 'Deaf or hearing impaired'];

const cognitionBehaviorFields = [
  `I Mild cognitive impairment`,
  `Dementia or Alzheimer's`,
];

const inlineRadioOptions = R.map(val => ({
  label: val,
  value: val,
}));

const ambulatoryStatusRadioOptions = inlineRadioOptions(ambulatoryStatusOptions);
const manualWheelChairSizeRadioOptions = inlineRadioOptions(wheelChairSizeOptions);
const powerWheelChairSizeRadioOptions = inlineRadioOptions(wheelChairSizeOptions);

const FieldGroup = ({
  icon,
  title,
  children,
  formGroupChildren = false,
  indentChildren = true,
  ...props
}) => (
  <Block {...props}>
    <Label style={{ fontWeight: 700 }}>
      {icon && <Icon w="16px" h="16px" name={icon} color="general.darkBlue" />}
      {` ${title}`}
    </Label>
    <Block
      className={formGroupChildren ? 'form-group' : undefined}
      style={{ marginLeft: indentChildren ? '20px' : undefined }}
    >
      {children}
    </Block>
  </Block>
);

const MemberHealthAttributesFormFields = ({
  manualWheelChairSizeDisabled,
  powerWheelChairSizeDisabled,
  disabled,
}) => (
  <Row>
    <Column xs={12} sm={6}>
      <FieldGroup icon="ambulatory" title="Ambulatory Status" indentChildren={false}>
        <Field
          name="healthAttributes.ambulatoryStatus"
          type="inline-stacked-radio"
          component={FormikField}
          radioOptions={ambulatoryStatusRadioOptions}
          disabled={disabled}
        />
      </FieldGroup>

      <FieldGroup icon="device" title="Devices &amp; Equipment">
        {devicesAndEquipmentFields.map(field => (
          <Block key={field} style={{ marginLeft: '20px' }}>
            <Field
              name={`healthAttributes.devicesAndEquipment.${field}`}
              label={field}
              component={FormikField}
              disabled={disabled}
              type="checkbox"
            />
            {field === 'Manual Wheelchair' && !manualWheelChairSizeDisabled && (
              <Field
                name="healthAttributes.manualWheelchairSize"
                type="inline-stacked-radio"
                component={FormikField}
                radioOptions={manualWheelChairSizeRadioOptions}
                disabled={disabled || manualWheelChairSizeDisabled}
              />
            )}
            {field === 'Power Wheelchair' && !powerWheelChairSizeDisabled && (
              <Field
                name="healthAttributes.powerWheelchairSize"
                type="inline-stacked-radio"
                component={FormikField}
                radioOptions={powerWheelChairSizeRadioOptions}
                disabled={disabled || powerWheelChairSizeDisabled}
              />
            )}
          </Block>
        ))}
      </FieldGroup>
    </Column>

    <Column xs={12} sm={6}>
      <FieldGroup icon="weight" title="Vitals">
        <Row>
          <Column xs={12} sm={4}>
            <Field
              label="Height"
              name={`healthAttributes.vitalsHeightFeet`}
              component={FormikField}
              disabled={disabled}
              textMask={heightFeetMask}
              placeholder="Feet"
            />
          </Column>
          <Column xs={12} sm={4}>
            <Field
              label="&nbsp;"
              name={`healthAttributes.vitalsHeightInches`}
              component={FormikField}
              disabled={disabled}
              textMask={heightInchesMask}
              placeholder="Inches"
            />
          </Column>
          <Column xs={12} sm={4}>
            <Field
              name={`healthAttributes.vitalsWeight`}
              label="Weight"
              component={FormikField}
              disabled={disabled}
              textMask={weightMask}
              placeholder="Weight"
            />
          </Column>
        </Row>
      </FieldGroup>

      <FieldGroup icon="impaired" title="System Limitations" formGroupChildren>
        {systemLimitationsFields.map(field => (
          <Block key={`systemLimitations-${field}`}>
            <Field
              name={`healthAttributes.systemLimitations.${field}`}
              label={field}
              component={FormikField}
              disabled={disabled}
              type="checkbox"
            />
          </Block>
        ))}
      </FieldGroup>

      <FieldGroup icon="cognition" title="Cognition & Behavior" formGroupChildren>
        {cognitionBehaviorFields.map(field => (
          <Block key={`cognitionBehavior-${field}`}>
            <Field
              name={`healthAttributes.cognitionBehavior.${field}`}
              label={fieldLabelTypoFixes(field)}
              component={FormikField}
              disabled={disabled}
              type="checkbox"
            />
          </Block>
        ))}
      </FieldGroup>
    </Column>
  </Row>
);

MemberHealthAttributesFormFields.propTypes = {
  disabled: PropTypes.bool,
};

// MemberHealthAttributesFormFields.ambulatoryStatusOptions = ambulatoryStatusOptions;
// MemberHealthAttributesFormFields.manualWheelChairSizeOptions = wheelChairSizeOptions;
// MemberHealthAttributesFormFields.devicesAndEquipmentFields = devicesAndEquipmentFields;

export default MemberHealthAttributesFormFields;
