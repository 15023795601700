import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import { tripStatusTransitionReasonIdType } from 'rides/types';
import { TripStatusTransitionReasonListLoader } from 'rides/containers';

function TripStatusTransitionReason({ reasonId, children }) {
  if (!reasonId) return null;
  return (
    <TripStatusTransitionReasonListLoader>
      {({ isLoading, reasonList }) => {
        const reason = reasonList.find(r => r.id === reasonId);
        if (isLoading || !reason) return null;
        return children(reason);
      }}
    </TripStatusTransitionReasonListLoader>
  );
}

TripStatusTransitionReason.propTypes = {
  reasonId: tripStatusTransitionReasonIdType,
  children: PropTypes.func.isRequired,
};

export default TripStatusTransitionReason;
