import Reactotron from 'reactotron-react-js';
import sagaPlugin from 'reactotron-redux-saga';
import tronsauce from 'reactotron-apisauce';
import { reactotronRedux } from 'reactotron-redux';

// NOTE toggle reactotron for local dev using yarn config (or npm config)
//
// To enable:
//   $ yarn config set reactotron
//
// To disable:
//   $ yarn config delete reactotron

const reactotronConfig = {
  name: 'Health-E Rides',
  socketIoProperties: {
    reconnection: true,
    reconnectionDelay: 2000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 2,
  },
};

Reactotron.configure(reactotronConfig)
  .use(reactotronRedux())
  .use(sagaPlugin())
  .use(tronsauce())
  .connect();

// clear log on page (re)load
Reactotron.clear();

export default Reactotron;
