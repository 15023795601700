import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fromEntities } from 'rides/store/selectors';

const mapStateToProps = (state, { entityId, entityType }) => {
  const entityDetail = fromEntities.getDetail(state, entityType, entityId);
  return { entityDetail };
};

const EntityDetail = ({ children, entityDetail, ...props }) => children(entityDetail);

EntityDetail.propTypes = {
  children: PropTypes.func,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  entityType: PropTypes.string.isRequired,
  entityDetail: PropTypes.object,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(EntityDetail);
