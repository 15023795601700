export const tripStatus = {
  SCHEDULED: 'scheduled',
  VENDOR_ASSIGNED: 'vendor assigned',
  VENDOR_CONFIRMED: 'vendor confirmed',
  DRIVER_ASSIGNED: 'driver assigned',
  DRIVER_EN_ROUTE: 'driver en route',
  DRIVER_FAILED: 'driver failed',
  DRIVER_ARRIVED: 'driver arrived',
  IN_TRANSIT: 'in transit',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
};

export const tripStatuses = {
  [tripStatus.SCHEDULED]: { label: 'Scheduled', color: 'mediumGray' },
  [tripStatus.VENDOR_ASSIGNED]: { label: 'Vendor Assigned', color: 'violet' },
  [tripStatus.VENDOR_CONFIRMED]: { label: 'Vendor Confirmed', color: 'blue' },
  [tripStatus.DRIVER_ASSIGNED]: { label: 'Driver Assigned', color: 'magenta' },
  [tripStatus.DRIVER_EN_ROUTE]: { label: 'Driver En Route', color: 'orange' },
  [tripStatus.DRIVER_FAILED]: { label: 'Driver Failed', color: 'red' },
  [tripStatus.DRIVER_ARRIVED]: { label: 'Driver Arrived', color: 'magenta' },
  [tripStatus.IN_TRANSIT]: { label: 'In Transit', color: 'magenta' },
  [tripStatus.COMPLETED]: { label: 'Completed', color: 'green' },
  [tripStatus.CANCELLED]: { label: 'Cancelled', color: 'red' },
};

export const tripActions = {
  billable: {
    label: 'Mark Billable',
    color: 'darkGray',
  },
  'non-billable': {
    label: 'Mark Non-Billable',
    color: 'darkGray',
  },
  [tripStatus.DRIVER_ASSIGNED]: {
    label: 'Assign Driver',
    color: tripStatuses[tripStatus.DRIVER_ASSIGNED].color,
  },
  [tripStatus.VENDOR_ASSIGNED]: {
    label: 'Assign Vendor',
    color: tripStatuses[tripStatus.VENDOR_ASSIGNED].color,
  },
  [tripStatus.VENDOR_CONFIRMED]: {
    label: 'Confirm Vendor',
    color: tripStatuses[tripStatus.VENDOR_CONFIRMED].color,
  },
  [tripStatus.DRIVER_EN_ROUTE]: tripStatuses[tripStatus.DRIVER_EN_ROUTE],
  [tripStatus.DRIVER_FAILED]: tripStatuses[tripStatus.DRIVER_FAILED],
  [tripStatus.DRIVER_ARRIVED]: tripStatuses[tripStatus.DRIVER_ARRIVED],
  [tripStatus.IN_TRANSIT]: tripStatuses[tripStatus.IN_TRANSIT],
  'reset status': {
    label: 'Reset Status',
    color: tripStatuses[tripStatus.SCHEDULED].color,
  },
  [tripStatus.CANCELLED]: {
    label: 'Cancel',
    color: tripStatuses[tripStatus.CANCELLED].color,
  },
  [tripStatus.COMPLETED]: {
    label: 'Complete',
    color: tripStatuses[tripStatus.COMPLETED].color,
  },
};
