/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'NotesEditXlIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M12.09 0H1.728C.777 0 0 .777 0 1.727v12.091h1.727V1.728h10.364V0zm2.592 3.455h-9.5c-.95 0-1.728.777-1.728 1.727v12.09c0 .95.778 1.728 1.728 1.728h9.5c.95 0 1.727-.777 1.727-1.727V5.182c0-.95-.777-1.727-1.727-1.727zm0 13.818h-9.5V5.182h9.5v12.09zM21.88 14.205l-2.083-2.083a.417.417 0 00-.59 0l-1.371 1.371 2.672 2.673 1.372-1.372a.417.417 0 000-.589z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M12.34 20.335h.001l-.325 1.134a.415.415 0 00.513.516l2.683-.767-2.43-2.43-.442 1.547zM13.25 18.078l2.672 2.673 3.996-3.995-2.672-2.673-3.996 3.995z"
        clip-rule="evenodd"
      />
      <path stroke-width="3" d="M7.939 21.939l13-13" />
    </g>
  ),
  viewBox: '0 0 23 23',
});
