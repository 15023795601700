import React from 'react';
import { render } from 'react-dom';
import * as Sentry from '@sentry/react';
import ReactErrorBoundary from 'react-error-boundary';

import App from './components/App';
import { initSentry, SentryErrorBoundary } from 'rides/services/sentry';

const isSentryInitialized = initSentry();
const ErrorBoundary = isSentryInitialized ? SentryErrorBoundary : ReactErrorBoundary;

const AppWithProfiler = isSentryInitialized ? Sentry.withProfiler(App) : App;

const renderApp = () => (
  <ErrorBoundary showDialog>
    <AppWithProfiler />
  </ErrorBoundary>
);

// Start MSW request mocking service worker in dev
if (process.env.NODE_ENV === 'development') {
  // const { worker } = require('./mocks/browser');
  // worker.start();
}

const root = document.getElementById('app');
render(renderApp(), root);
