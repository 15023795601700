import * as R from 'ramda';

// Array Utils
export const isArray = subject => Array.isArray(subject);
export const isArrayAndEmpty = R.both(isArray, R.isEmpty);
export const isArrayAndNotEmpty = R.both(isArray, R.complement(R.isEmpty));

// Function Utils
export const isFunc = subject => typeof subject === 'function';
export const noop = () => {};
export const preventDefault = event => event.preventDefault();

// Object Utils
export const isObj = obj => obj === Object(obj);
export const removeEmpty = R.reject(R.either(R.isEmpty, R.isNil));

// String Utils
export const isStr = val => typeof val === 'string';

// Distance
export const metersToMiles = distanceInMeters => {
  const distanceInMiles = distanceInMeters * 0.000621371;
  return Math.round(distanceInMiles * 100) / 100;
};

// Integer Utils
/**
 * Returns coerced int value if source is number or string otherwise, returns
 * original source value.
 */
export const coerceToInt = source => {
  if (R.is(Number, source) || R.is(String, source)) {
    return ~~source;
  }
  return source;
};

/**
 * Returns coerced int value if source is number or string otherwise, returns
 * the defaultValue (defaults to 0)
 */
export const coerceToIntOr = (defaultValue = 0) => source => {
  const int = coerceToInt(source);

  if (!R.is(Number, int)) {
    // was not coerced to int :(
    return defaultValue;
  }

  return int;
};
