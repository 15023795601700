import React from 'react';
import PropTypes from 'prop-types';

import { paginationType, tripGroupType } from 'rides/types';
import {
  Block,
  Column,
  Heading,
  Pagination,
  Row,
  SearchField,
  TripGroupListTable,
  Widget,
  Spinner,
} from 'rides/components';
import { StandardLayout } from 'rides/containers';

const TripGroupListPage = ({
  pageTitle,
  tripGroupList,
  onSubmitSearch,
  keywords,
  pagination,
  isLoading,
}) => (
  <StandardLayout>
    <Row>
      <Column sm={4}>
        <Heading level={2}>{pageTitle}</Heading>
      </Column>
      <Column sm={4}>
        <Block style={{ width: '100%', marginTop: '10px' }}>
          <SearchField
            value={keywords}
            onSubmit={onSubmitSearch}
            inputProps={{ placeholder: 'Search for a trip' }}
          />
        </Block>
      </Column>
    </Row>

    <Widget>
      {isLoading ? (
        <Spinner />
      ) : (
        <Block>
          <Pagination {...pagination} disabled={isLoading} />
          <TripGroupListTable tripGroupList={tripGroupList} />
          <Pagination {...pagination} disabled={isLoading} />
        </Block>
      )}
    </Widget>
  </StandardLayout>
);

TripGroupListPage.propTypes = {
  pageTitle: PropTypes.string,
  tripGroupList: PropTypes.arrayOf(tripGroupType),
  onSubmitSearch: PropTypes.func,
  keywords: PropTypes.string,
  pagination: paginationType,
  isLoading: PropTypes.bool,
};

export default TripGroupListPage;
