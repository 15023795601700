import React from 'react';
import PropTypes from 'prop-types';

import { Link as RouterLink } from 'react-router-dom';
import { Box, Flex, Text, Stack, Link } from '@chakra-ui/react';
import Icon from 'rides/evo/components/Icon/Icon';
import { linkToType } from 'rides/types';
const localNumberFormat = new Intl.NumberFormat('en-US');

const formatNum = val => localNumberFormat.format(val);

const SideNavMenuItemWrapper = ({ children, linkTo, isActive }) => {
  if (linkTo) {
    const fontWeight = isActive ? 'semibold' : 'light';
    const textDecoration = isActive ? 'underline' : 'none';
    const textProps = { fontWeight, textDecoration };

    return (
      <Link to={linkTo} as={RouterLink} textStyle="h6" {...textProps}
        style={isActive ? {
          backgroundColor: "var(--chakra-colors-gray-100)",
          borderRadius: "3px",
          padding: "6px 12px",
          marginLeft: "-12px",
          width: "calc(100% + 64px)",
        } : {}}
      >
        {children}
      </Link>
    );
  }

  return children;
};

SideNavMenuItemWrapper.propTypes = {
  children: PropTypes.node,
  linkTo: linkToType,
  isActive: PropTypes.bool,
};

/**
 *
 */
export const SideNavMenuItem = props => {
  return (
    <Flex justifyContent="space-between" width="100%">
      <SideNavMenuItemWrapper
        linkTo={props.linkTo}
        isActive={props.isActive}
      >
        {props.title}
      </SideNavMenuItemWrapper>
      <Text my="0px">{props.count && formatNum(props.count)}</Text>
    </Flex>
  );
};
SideNavMenuItem.propTypes = {
  children: PropTypes.node,

  /* Router Link to prop */
  linkTo: linkToType,

  /** The value for the component to display */
  title: PropTypes.string,

  /** The count number  to display */
  count: PropTypes.number,

  /** Flag indicating if the item is actively selected */
  isActive: PropTypes.bool,
};

/** A simple component. */
export const SideNavMenuSection = props => {
  const icon = props.icon ? (
    // props.icon //null
    <Icon w="20px" h="20px" name={props.icon} color="general.darkBlue" />
  ) : null;

  const expandContent = props.isActive;
  const linkTo = props.linkTo;

  return (
    <>
      <Link
        to={linkTo}
        as={RouterLink}
        pl={props.isActive ? '10px' : '14px'}
        pr="12px"
        mb="10px"
        borderLeft={props.isActive ? '4px' : '0px'}
        borderLeftColor="general.activeOrange"
        _hover={{
          textDecoration: 'none',
          bgColor: 'gray.100',
          p: { visibility: 'visible' },
        }}
      >
        <Stack isInline spacing="10px" align="center" height="30px">
          {icon}
          <Text
            color="general.darkBlue"
            fontWeight="semibold"
            my="0px"
            textTransform="uppercase"
            textStyle="h6"
          // visibility={props.isActive ? 'visible' : 'hidden'}
          >
            {props.title}
          </Text>
        </Stack>
      </Link>
      <Box pl="14px" pr="12px" mb="25px">
        {expandContent && (
          <Stack spacing="12px" align="center">
            {props.children}
          </Stack>
        )}
      </Box>
    </>
  );
};

SideNavMenuSection.propTypes = {
  /** The value for the component to display */
  title: PropTypes.string,

  /** React Node to render wrapped in theme context */
  children: PropTypes.node,

  /** Icon key for Chakra UI Icon component */
  icon: PropTypes.string,

  /** Flag indicating if the item is actively selected */
  isActive: PropTypes.bool,

  /* Router Link to prop */
  linkTo: linkToType,

};

/** A simple component. */
export const SideNavMenu = props => {
  return <Stack>{props.children || null}</Stack>;
};

SideNavMenu.propTypes = {
  // /** The value for the component to display */
  // value?: string;
  /** React Node to render wrapped in theme context */
  children: PropTypes.node,
};
