import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fromApp, fromAuth } from 'rides/store/selectors';
import { authSetCurrentTenantId } from 'rides/store/actions';

const mapDispatchToProps = {
  changeTenant: tenantId => authSetCurrentTenantId(tenantId),
};

const mapStateToProps = (state, props) => ({
  currentTenantId: fromAuth.currentTenantId(state, props),
  hideForHumana: fromApp.ui.hideForHumana(state, props),
  hideTripList: fromApp.ui.hideTripList(state, props),
  tenantName: fromApp.tenant.currentTenantName(state, props),
});

function TenantRoutesMatcher({
  render,
  changeTenant,
  hideForHumana,
  hideTripList,
  currentTenantId,
  tenantId,
  tenantName,
}) {
  const [initCurrentTenantId] = useState(currentTenantId);

  useEffect(() => {
    if (currentTenantId === null) return;
    if (currentTenantId !== tenantId) {
      changeTenant(tenantId);
    }
  }, [changeTenant, currentTenantId, tenantId]);

  // -- Update <title> to show Tenant Name in browser tab
  useEffect(() => {
    if (tenantName) {
      document.title = `${tenantName} - CareCar HQ`;
    }
  }, [tenantName]);

  // Return empty page until the tenant id in route is set to local storage
  const isInitialLoad = initCurrentTenantId === currentTenantId;
  if (isInitialLoad && currentTenantId !== tenantId) return null;

  return render({ hideForHumana, hideTripList });
}

const TenantRoutesContainer = R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(TenantRoutesMatcher);

export default TenantRoutesContainer;
