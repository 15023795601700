/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'PlusIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M1 8a1 1 0 011-1h12a1 1 0 110 2H2a1 1 0 01-1-1z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M8 1a1 1 0 011 1v12a1 1 0 11-2 0V2a1 1 0 011-1z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
