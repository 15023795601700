// import { services } from 'rides/services';
// https://github.com/diegohaz/arc/wiki/Sagas
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
import { decamelizeKeys } from 'humps';
import { consoleErrorRecovery, safeSaga } from 'rides/store/helpers';
import { all, call, takeEvery } from 'redux-saga/effects';
import {
  invalidateQueryDataForTripStatusTransitionList,
  setQueryDataForTrip,
  setQueryDataForTripStatusTransition,
} from 'rides/evo/utils/trips';
import {
  setQueryDataForMember,
} from 'rides/evo/utils/members';

import * as actions from './actions';

export function invalidateTripStatusTransitionsQueryData(queryClient, entityList) {
  const transition = entityList[0]
  const tripId = transition?.tripId;

  // console.log('invalidateQueryDataForTripStatusTransitionList',{ transition, tripId, queryClient })

  if (tripId) {
    invalidateQueryDataForTripStatusTransitionList(tripId, queryClient);
  }
}

export function* syncEntities(
  queryClient,
  entities,
  setQueryDataFn,
  invalidateQueryFn
) {
  if (entities) {
    const entityList = Object.values(entities)

    // Set Query Data
    yield all(
      entityList.map(entity => {
        const safeEntity = decamelizeKeys(entity);
        return call(setQueryDataFn, safeEntity, queryClient);
      }),
    );

    // Invalidate Query Data
    if (invalidateQueryFn) {
      yield call(invalidateQueryFn, queryClient, entityList);
    }
  }
}

export function* updateQueryClientCache(queryClient, payload) {
  try {
    yield call(
      //
      syncEntities,
      queryClient,
      payload?.trip,
      setQueryDataForTrip,
    );

    yield call(
      syncEntities,
      queryClient,
      payload?.tripStatusTransition,
      setQueryDataForTripStatusTransition,
      invalidateTripStatusTransitionsQueryData,
    );

    yield call(
      //
      syncEntities,
      queryClient,
      payload?.member,
      setQueryDataForMember,
    );
  } catch (e) {
    console.error('updateQueryClientCache::ERROR', { e, payload, queryClient });
  }
}

export function* watchEntitiesReceive(queryClient, { payload, meta }) {
  if (meta?.syncToReactQueryCache) {
    yield call(updateQueryClientCache, queryClient, payload);
  }
}

export default function*({ queryClient }) {
  const safe = safeSaga(consoleErrorRecovery);

  yield takeEvery(actions.ENTITIES_RECEIVE, safe(watchEntitiesReceive, queryClient));
}
