/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'VendorsLgIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M7.579 7.5H4.678a5.715 5.715 0 00-.498 2.083h3.067A7.5 7.5 0 017.58 7.5zM11.552 7.5H8.454a6.61 6.61 0 00-.388 2.083h3.874a6.608 6.608 0 00-.388-2.083zM11.916 10.416H8.092c.066.686.236 1.384.506 2.084h2.812c.268-.7.44-1.397.506-2.084zM8.81 6.666h2.386a8.057 8.057 0 00-1.194-1.712 8.083 8.083 0 00-1.191 1.712zM7.274 10.416h-3.08c.064.743.266 1.444.574 2.084H7.73a8.755 8.755 0 01-.455-2.084zM11.039 13.333h-2.07c.28.564.623 1.128 1.033 1.69a11.58 11.58 0 001.037-1.69zM12.28 12.5h2.974c.308-.64.501-1.342.556-2.084h-3.076a8.746 8.746 0 01-.455 2.084zM9.454 15.687l-.004-.005a12.192 12.192 0 01-1.395-2.349H5.254c.942 1.347 2.414 2.278 4.106 2.463l.094-.109zM10.57 15.661l-.023.028.09.106a5.845 5.845 0 004.133-2.462h-2.817a12.14 12.14 0 01-1.383 2.328zM9.455 4.313l-.09-.107c-1.79.182-3.297 1.103-4.22 2.46h2.753a8.62 8.62 0 011.557-2.353zM10.549 4.31l.005.007a8.574 8.574 0 011.556 2.35h2.675a5.864 5.864 0 00-4.15-2.457l-.086.1zM12.428 7.5c.209.676.318 1.373.332 2.083h3.052a5.728 5.728 0 00-.547-2.083h-2.837zM19.877 9.705l-2.5-2.5a.417.417 0 00-.59.59L18.994 10l-2.205 2.205a.417.417 0 00.589.59l2.5-2.5a.417.417 0 000-.59zM12.206 16.788L10 18.994l-2.206-2.206a.417.417 0 00-.59.59l2.5 2.5a.416.416 0 00.59 0l2.5-2.5a.417.417 0 00-.59-.59zM3.211 7.205a.417.417 0 00-.59 0l-2.5 2.5a.417.417 0 000 .59l2.5 2.5a.415.415 0 00.59 0 .417.417 0 000-.59L1.006 10l2.205-2.206a.417.417 0 000-.589zM7.795 3.211L10 1.005l2.206 2.206a.415.415 0 00.589 0 .417.417 0 000-.59l-2.5-2.5a.417.417 0 00-.59 0l-2.5 2.5a.417.417 0 00.59.59z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
});
