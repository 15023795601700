const radii = {
  none: 'none',
  xs: '2px',
  sm: '3px',
  md: '4px',
  lg: '8px',
  xl: '16px',
  full: '99999px',
};

export default radii;
