import * as R from 'ramda';
import { call } from 'redux-saga/effects';

/**
 *
 * The callApi sagas make API calls resilent by handling API errors
 * intelligently and re-trying requests if possible.
 *
 * For example:
 *   If the request failed due to authentication (e.g. stale token,
 *   not logged in) before returning the failed response an attempt to
 *   authenticate will be made (e.g. use refresh tokens, show login screen) and
 *   if authentication is successful the request will be reattempted and the
 *   re-tried request response will be returned to the calling saga.
 *
 *
 *   NOTE! All API requests should be made using this saga wrapper rather than just
 *         calling the api services directly!
 *
 */

const responseProblemErrorHandlers = {
  CLIENT_ERROR: handleResponseClientError,
  SERVER_ERROR: handleResponseServerError,
  TIMEOUT_ERROR: handleResponseTimeoutError,
  CONNECTION_ERROR: handleResponseConnectionError,
  NETWORK_ERROR: handleResponseNetworkError,
  CANCEL_ERROR: handleResponseCancelError,
};

const getResponseErrorHandler = response =>
  responseProblemErrorHandlers[response.problem];

export function* callApi(apiCall, handleErrors) {
  if (handleErrors) {
    return yield call(callApiWithErrorHandling, apiCall);
  }

  return yield apiCall;
}

export function* callApiWithErrorHandling(apiCall) {
  const response = yield apiCall;

  try {
    return yield call(responseThrowOnError, response);
  } catch (e) {
    return yield call(handleResponseError, apiCall, response);
  }
}

export function* errorNoHandlerReturnFailedResponse(response) {
  return response;
}

export function* errorNotSolvedReturnFailedResponse(response) {
  return yield responseThrowOnError(response);
}

export function* errorSolvedRetryApiCall(apiCall) {
  const response = yield call(callApi, apiCall);
  return yield call(responseThrowOnError, response);
}

export function* handleResponseError(apiCall, response) {
  const errorHandler = getResponseErrorHandler(response);

  if (!errorHandler) {
    return yield errorNoHandlerReturnFailedResponse(response);
  }

  try {
    yield call(errorHandler, apiCall, response);
  } catch (e) {
    return yield errorNotSolvedReturnFailedResponse(response);
  }

  return yield errorSolvedRetryApiCall(apiCall);
}

export const responseThrowOnError = response => {
  if (response.ok) {
    return response;
  }

  const error = new Error(`${response.status} ${response.statusText}`);
  error.response = response;
  throw error;
};

/**
 * API RESPONSE ERROR TYPE HANDLERS
 */

export function* handleResponseClientError(apiCall, response) {
  // status code 400-499 :: Any non-specific 400 series error.
  const error = R.pathOr({}, ['data', 'error'], response);

  if (response.status === 401) {
    if (error === 'expired') {
      // return yield putResolve(authFixExpiredSession());
    }
    if (error === 'invalid') {
      // server can't read the token sent by client
      // return yield putResolve(authFixExpiredSession());
    }
  }

  throw new Error('callApi.handleResponseClientError - NO SOLUTION');
}

export function* handleResponseServerError(apiCall, response) {
  // status code 500-599 :: Any 500 series error.

  // TODO remove after debugging
  console.log('handleResponseServerError', response);
  throw new Error('callApi.handleResponseServerError - NO SOLUTION');
}

export function* handleResponseTimeoutError(apiCall, response) {
  // Server didnt respond in time.

  // TODO remove after debugging
  console.log('handleResponseTimeoutError', response);
  throw new Error('callApi.handleResponseTimeoutError - NO SOLUTION');
}

export function* handleResponseConnectionError(apiCall, response) {
  // Server not available, bad dns.

  // TODO remove after debugging
  console.log('handleResponseConnectionError', response);
  throw new Error('callApi.handleResponseConnectionError - NO SOLUTION');
}

export function* handleResponseNetworkError(apiCall, response) {
  // Network not available.

  // TODO remove after debugging
  console.log('handleResponseNetworkError', response);
  throw new Error('callApi.handleResponseNetworkError - NO SOLUTION');
}

export function* handleResponseCancelError(apiCall, response) {
  // Request has been cancelled. Only possible if `cancelToken` is provided
  // in config, see axios `Cancellation`.

  // TODO remove after debugging
  console.log('handleResponseCancelError', response);
  throw new Error('callApi.handleResponseCancelError - NO SOLUTION');
}

/**
 * API REQUEST TASKS
 */

// GET, HEAD, DELETE, LINK AND UNLINK - data passed as url params
export function* apiDelete(api, url, params, handleErrors = true) {
  const apiCall = call([api, api.delete], url, params);
  return yield call(callApi, apiCall, handleErrors);
}

export function* apiGet(api, url, params, handleErrors = true) {
  const apiCall = call([api, api.get], url, params);
  return yield call(callApi, apiCall, handleErrors);
}

export function* apiHead(api, url, params, handleErrors = true) {
  const apiCall = call([api, api.head], url, params);
  return yield call(callApi, apiCall, handleErrors);
}

export function* apiLink(api, url, params, handleErrors = true) {
  const apiCall = call([api, api.link], url, params);
  return yield call(callApi, apiCall, handleErrors);
}

export function* apiUnlink(api, url, params, handleErrors = true) {
  const apiCall = call([api, api.unlink], url, params);
  return yield call(callApi, apiCall, handleErrors);
}

// PATCH, POST, PUT - data passed json in request body
export function* apiPatch(api, url, data, handleErrors = true) {
  const apiCall = call([api, api.patch], url, data);
  return yield call(callApi, apiCall, handleErrors);
}

export function* apiPost(api, url, data, handleErrors = true) {
  const apiCall = call([api, api.post], url, data);
  return yield call(callApi, apiCall, handleErrors);
}

export function* apiPut(api, url, data, handleErrors = true) {
  const apiCall = call([api, api.put], url, data);
  return yield call(callApi, apiCall, handleErrors);
}

/**
 * API METHOD TASKS
 */

export function* apiSetAuthToken(api, token) {
  return yield call([api, api.setAuthToken], token);
}

export function* apiSetTenantId(api, token) {
  return yield call([api, api.setTenantId], token);
}

/**
 * Watcher Sagas
 **/

/* NONE */

/**
 * Root Sagas
 **/

export default function*() {
  // NOTE: empty default export required for redux-modules autoloading sagas file
}
