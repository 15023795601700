import * as React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as ridesTypes from 'rides/types';
import { fromApp } from 'rides/store/selectors';
import { resourceUpdateRequest } from 'rides/store/actions';
import { tripActions } from 'rides/utils/trip';
import TripStatusTransitionModal from 'rides/components/organisms/TripStatusTransitionModal';

const mapStateToProps = (state, props) => ({
  trip: fromApp.trip.trip(state, props),
});

const mapDispatchToProps = {
  updateTripStatusTransition: (tripId, transitionId, payload) =>
    resourceUpdateRequest(
      `trip/${tripId}/status_transition/`,
      transitionId,
      payload,
      'tripStatusTransition',
    ),
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { tripId, transition } = ownProps;
  const transitionId = transition && transition.id;

  return {
    ...ownProps,
    ...stateProps,
    onSubmit: (values, onSuccess, onError) => {
      return dispatchProps
        .updateTripStatusTransition(tripId, transitionId, values)
        .then(() => onSuccess(), error => onError(error.message));
    },
  };
};

const EditTripStatusTransitionModal = ({ status, transition, ...props }) => {
  const dialogTitle = R.pathOr('', [status, 'label'], tripActions);
  const initialValues = { tripStatusTransition: transition };

  return (
    <TripStatusTransitionModal
      {...props}
      status={status}
      transition={initialValues}
      dialogTitle={`Update ${dialogTitle}`}
      confirmLabel="Update"
      showUpdateForm
    />
  );
};

EditTripStatusTransitionModal.propTypes = {
  // passed props
  tripId: ridesTypes.tripIdType.isRequired,
  transition: ridesTypes.tripStatusTransitionType.isRequired,

  // connected props
  status: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

EditTripStatusTransitionModal.defaultProps = {
  status: null, // Used when the dialog is closed
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
)(EditTripStatusTransitionModal);
