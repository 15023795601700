/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'PlayIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M14.5 8a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zM16 8A8 8 0 110 8a8 8 0 0116 0zm-9.2 2.6a.3.3 0 00.48.24l3.2-2.4a.3.3 0 000-.48l-3.2-2.4a.3.3 0 00-.48.24v4.8z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
