import * as React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import {
  Box,
  Collapse,
  Flex,
  Grid,
  GridItem,
  HStack,
  Button,
  IconButton,
  Link,
  Stack,
  Text,
  Tooltip,
  VStack,
  // useToken,
  Divider,
  useDisclosure,
  Skeleton,
  ScaleFade,
  Avatar,
} from '@chakra-ui/react';
import { Pill, TripCardVendorDriver } from '@carecar-evo/core-ui';

import * as ridesTypes from 'rides/types';
import DateTime from './DateTime';
import Icon from 'rides/evo/components/Icon/Icon';
import PhoneNumber from 'rides/evo/components/PhoneNumber';
import NuiPhoneNumber from 'rides/nui/PhoneNumber';
import Detail from 'rides/nui/Detail';
import { MotionBox } from 'rides/evo/components/Motion';
// import { TripCardActionButton } from './TripCardActionButtons';
import { isValidIsoDateTime } from 'rides/utils/dateTime';
import { useTenantUrl } from 'rides/hooks/use-tenant';
import {
  getTripGroupId,
  getTripGroupReferenceNumber,
  getTripId,
  getTripMemberId,
  useTrip,
  useTripGroup,
} from 'rides/evo/utils/trips';
import { useIsTripActive } from 'rides/evo/store/tripList';
import { removeEmpty } from 'rides/evo/utils/data';
import { metersToMiles } from 'rides/utils/data';
import TripStatusDropdown from 'rides/nui/containers/TripStatusDropdown';
import { VendorNotConfirmedNotice } from 'rides/nui/TripStatusDropdown';
import TripRequestPickupButton from 'rides/nui/containers/TripRequestPickupButton';
import TripListTripMenu from 'rides/nui/containers/TripListTripMenu';
import { Flex as NuiFlex } from 'rides/nui';

import { TripListTripTookanTasks } from 'rides/nui/TripList/TripListTrip';
import { camelizeKeys } from 'humps';
import { TripListRowAssignee } from 'rides/nui/TripList';
import { getMemberName, useMember } from 'rides/evo/utils/members';
import { getProviderDetailText } from 'rides/evo/utils/providers';

// const removeEmpty = R.reject(R.either(R.isEmpty, R.isNil));

// <SlashContainer variants={slashMotion}>

/**
 * A butter box is a element that can do GPU performant animations for a parent
 * component.
 *
 * I came up with the name because I didn't have anyway to refer to it.
 */
function ButterBox({ disabled = false, ...props }) {
  if (disabled) return null;

  return (
    <MotionBox
      rounded="md"
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      zIndex={-1}
      opacity={0}
      {...props}
    />
  );
}

function EvoTripListCard({ children, isActive = false, disabled = false, ...props }) {
  return (
    <MotionBox
      position="relative"
      display="inline-block"
      width="750px"
      paddingX="20px"
      paddingY="15px"
      rounded="md"
      borderWidth="1px"
      borderColor="lightGrey.100"
      bgColor="general.white"
      boxShadow="none"
      initial="inactive"
      animate={isActive ? 'active' : 'inactive'}
      variants={{
        inactive: {},
        active: {},
        hover: {},
        tap: { scale: isActive ? 1 : 0.995 },
      }}
      whileHover="hover"
      whileTap="tap"
      zIndex={0}
      {...props}
      _hover={{
        cursor: !isActive ? 'pointer' : undefined,
      }}
    >
      {children}
      <ButterBox
        data-id="butter-box-tap-shadow"
        boxShadow="md"
        variants={{
          inactive: { opacity: 0 },
          active: { opacity: 0 },
          hover: { opacity: 0 },
          tap: { opacity: isActive ? 0 : 1 },
          // tap: { opacity: 1 },
        }}
      />
      <ButterBox
        data-id="butter-box-hover-shadow"
        boxShadow="lg"
        // boxShadowColor="blue"
        custom={isActive}
        variants={{
          inactive: { opacity: 0 },
          active: { opacity: 1 },
          hover: { opacity: 1 },
          tap: { opacity: 0 },
        }}
      />
      <ButterBox
        data-id="butter-box-active-shadow"
        isActive={isActive}
        boxShadow="xl"
        variants={{
          active: { opacity: 1 },
          inactive: { opacity: 0 },
        }}
      />
    </MotionBox>
  );
}

function EvoTripWillCallTag({ trip }) {
  const isWillCall = !!trip?.is_will_call;

  const tooltipText = <Text>{isWillCall ? 'Will Call' : 'Not Will Call'}</Text>;
  const pillProps = isWillCall
    ? {
        bgColor: '#1070ca',
      }
    : {
        bgColor: '$ccGray100',
        textColor: '$ccGray400',
      };

  return (
    <Tooltip label={tooltipText} placement="top" openDelay={500}>
      <Pill {...pillProps} text="Will Call" />
    </Tooltip>
  );
}

function EvoTripListTripDate({ trip, ...props }) {
  const pickupTime = trip?.pickup_time;

  let pickupAt;
  if (isValidIsoDateTime(pickupTime)) {
    pickupAt = pickupTime;
  }

  // const tripType = R.path(['type', 'name'], trip);
  // TODO: map icons to type
  return (
    <Flex {...props}>
      <Text textStyle="microReg">
        {'Trip Date: '}
        <DateTime
          datetime={pickupAt}
          format="MMMM D, YYYY"
          render={datetime => (
            <Text textStyle="microBold" as="span">
              {datetime}
            </Text>
          )}
        />
      </Text>
    </Flex>
  );
}

function EvoTripListLanguage({ trip, ...props }) {
  const lang = trip?.member?.languages_spoken;
  return (
    <Flex alignItems="center" {...props}>
      <Icon w="16px" h="16px" name="language" color="general.darkBlue" pr="3px" />
      <Text textStyle="microBoldUpper" isTruncated>
        {lang}
      </Text>
    </Flex>
  );
}

function EvoTripListTripType({ trip, ...props }) {
  const tripType = trip?.type?.name;

  return (
    <VStack alignItems="start" spacing="0px" {...props}>
      <Text textStyle="microBoldUpper" lineHeight="12px" padding="2px" rounded="sm">
        {tripType}
      </Text>
    </VStack>
  );
}

function EvoTripListTripId({ trip, isExpanded, ...props }) {
  const memberId = getTripMemberId(trip);
  const groupId = getTripGroupId(trip);
  const tripId = getTripId(trip);
  const linkTo = useTenantUrl(`/member/${memberId}/trip-group/${groupId}#leg-${tripId}`);

  const tripGroupRefNum = getTripGroupReferenceNumber(trip);

  return (
    <Stack {...props}>
      <Flex>
        <Text textStyle="microBoldUpper" pr="3px" sx={{ whiteSpace: 'nowrap' }}>
          {`Trip ID`}
        </Text>
        {!isExpanded && <Text textStyle="microBoldUpper">{tripId}</Text>}
        {isExpanded && (
          <Link to={linkTo} as={RouterLink} textStyle="microBoldUpper">
            {tripId}
          </Link>
        )}
      </Flex>
      <Flex>
        <Text textStyle="microBoldUpper" pr="3px" sx={{ whiteSpace: 'nowrap' }}>
          {`Ref #`}
        </Text>
        <Text textStyle="microBoldUpper">
          {tripGroupRefNum}
          {!tripGroupRefNum && '--'}
        </Text>
      </Flex>
    </Stack>
  );
}

function EvoTripListMemberPhone({ trip, ...props }) {
  const phoneNumberMain = trip?.member?.phone_number;
  const phoneNumberAlt = trip?.member?.phone_number_alternate;

  return (
    <Flex alignItems="center" {...props}>
      <NuiPhoneNumber
        phoneNumber={phoneNumberMain}
        render={({ phoneNumber, iconName }) => (
          <Detail
            icon={iconName}
            text={phoneNumber}
            my={2}
            postfix={<Text title="Main Phone Number">main</Text>}
          />
        )}
      />
      <NuiPhoneNumber
        phoneNumber={phoneNumberAlt}
        render={({ phoneNumber, iconName }) => (
          <Detail
            iconCss={{ visibility: 'hidden' }}
            icon={iconName}
            text={phoneNumber}
            my={2}
            postfix={<Text title="Alternate Phone Number">alt</Text>}
          />
        )}
      />
    </Flex>
  );
}

function EvoTripListAgent({ trip, ...props }) {
  const assignee = trip?.assignee?.name ?? '';

  if (!assignee) {
    return <Flex height="16px" {...props} />;
  }

  return (
    <Flex alignItems="center" {...props}>
      <Icon w="16px" h="16px" name="agent" color="general.darkBlue" pr="3px" />
      <Tooltip label={assignee} placement="top" openDelay={500}>
        <Text textStyle="microBoldUpper" noOfLines={1}>
          {assignee}
        </Text>
      </Tooltip>
    </Flex>
  );
}

function EvoTripListCardPickup({ trip, member, ...props }) {
  const memberId = getTripMemberId(trip);
  const linkToMember = useTenantUrl(`/member/${memberId}`);
  const memberName = getMemberName(member);

  const pickupTime = trip?.pickup_time;
  const tripPickup = trip?.pickup ?? {};
  const safePickup = removeEmpty(tripPickup || {});

  const pickupName = trip?.pickup?.name;
  const pickupAddress = R.compose(
    R.join(', '),
    removeEmpty,
  )([
    safePickup.address1,
    safePickup.address2,
    safePickup.city,
    `${safePickup.state} ${safePickup.postal_code}`,
  ]);

  return (
    <Flex direction="column" bgColor="grey.bg" rounded="md" paddingX="12px" width="375px">
      <Grid
        templateColumns="16px auto"
        templateAreas={`
          "memberIcon      memberName"
          "memberPhoneIcon memberPhone"
          "pickupIcon      pickupName"
          "pickupIcon      pickupAddress"
          "pickupIcon      pickupPhone"
          "timeIcon        timeText"
          "timeConnect     none"
       `}
        columnGap="8px"
        paddingY="12px"
      >
        <GridItem gridArea="memberIcon">
          <Icon h="16px" w="16px" name="member-xl" color="general.darkBlue" />
        </GridItem>
        <GridItem gridArea="memberName">
          <Link to={linkToMember} as={RouterLink} textStyle="tripListCard.memberName">
            {memberName}
          </Link>
        </GridItem>
        <GridItem gridArea="memberPhoneIcon" />
        <GridItem gridArea="memberPhone">
          <EvoTripListMemberPhone trip={trip} />
        </GridItem>

        <GridItem gridArea="pickupIcon">
          <Icon h="16px" w="16px" name="home" color="general.darkBlue" />
        </GridItem>
        <GridItem gridArea="pickupName">
          <Text textStyle="tripListCard.locationTitle">{pickupName}</Text>
        </GridItem>
        <GridItem gridArea="pickupAddress">
          <Text textStyle="tripListCard.locationDetail">{pickupAddress}</Text>
        </GridItem>
        <GridItem gridArea="pickupPhone">
          {safePickup.phone_number && (
            <Text textStyle="tripListCard.locationDetail">
              <PhoneNumber
                phoneNumber={safePickup.phone_number}
                render={({ phoneNumber, iconName }) => (
                  <>
                    {false && <Icon name={iconName} size="16px" />}
                    {phoneNumber}
                  </>
                )}
              />
            </Text>
          )}
        </GridItem>

        <GridItem gridArea="timeIcon" paddingTop="4px">
          <Icon h="16px" w="16px" name="time-lg" color="general.darkBlue" />
        </GridItem>

        <GridItem gridArea="timeText" paddingTop="4px">
          <Text textStyle="tripListCard.locationTime">
            {'Pick up: '}
            <DateTime datetime={pickupTime} format="h:mm A" />
          </Text>
        </GridItem>

        <GridItem gridArea="timeConnect" paddingLeft="7px" height="0" overflow="visible">
          <AnimatePresence initial={false}>
            {props.isActive && (
              <MotionBox
                initial="collapsed"
                animate={['collapsed', 'open']}
                exit="collapsed"
                variants={{ open: { opacity: 1 }, collapsed: { opacity: 0 } }}
                transition={{ type: 'spring', stiffness: 500, damping: 30 }}
                bgColor="general.darkBlue"
                width="1px"
                height="32px"
                zIndex={10000}
                overflow="visible"
                position="relative"
              />
            )}
          </AnimatePresence>
        </GridItem>
      </Grid>
    </Flex>
  );
}

function EvoTripListCardDropoff({ trip, member, ...props }) {
  const dropoffTime = trip?.appointment_time;
  const hasDropoffTime = isValidIsoDateTime(dropoffTime);
  const tripDropoff = trip?.dropoff ?? {};
  const safeDropoff = removeEmpty(tripDropoff || {});

  const dropoffName = trip?.dropoff?.name;

  const dropoffAddress = R.compose(
    R.join(', '),
    removeEmpty,
  )([
    safeDropoff.address1,
    safeDropoff.address2,
    safeDropoff.city,
    `${safeDropoff.state} ${safeDropoff.postal_code}`,
  ]);

  return (
    <Flex direction="column" bgColor="grey.bg" rounded="md" paddingX="12px" width="375px">
      <Grid
        templateColumns="16px auto"
        templateAreas={`
          "dropoffIcon dropoffName"
          "dropoffIcon dropoffAddress"
          "dropoffIcon dropoffPhone"
          "timeIcon    timeText"
       `}
        columnGap="8px"
        paddingY="12px"
      >
        <GridItem gridArea="dropoffIcon">
          <Icon h="16px" w="16px" name="home" color="general.darkBlue" />
        </GridItem>
        <GridItem gridArea="dropoffName">
          <Text textStyle="tripListCard.locationTitle">{dropoffName}</Text>
        </GridItem>
        <GridItem gridArea="dropoffAddress">
          <Text textStyle="tripListCard.locationDetail">{dropoffAddress}</Text>
        </GridItem>
        <GridItem gridArea="dropoffPhone">
          {safeDropoff.phone_number && (
            <Text textStyle="tripListCard.locationDetail">
              <PhoneNumber
                phoneNumber={safeDropoff.phone_number}
                render={({ phoneNumber, iconName }) => (
                  <>
                    {false && <Icon name={iconName} size="16px" />}
                    {phoneNumber}
                  </>
                )}
              />
            </Text>
          )}
        </GridItem>

        {hasDropoffTime && (
          <GridItem gridArea="timeIcon" paddingTop="4px">
            <Icon h="16px" w="16px" name="time-lg" color="general.darkBlue" />
          </GridItem>
        )}

        {hasDropoffTime && (
          <GridItem gridArea="timeText" paddingTop="4px">
            <Text textStyle="tripListCard.locationTime">
              {'Appt Time: '}
              <DateTime datetime={dropoffTime} format="h:mm A" />
            </Text>
          </GridItem>
        )}
      </Grid>
    </Flex>
  );
}

export const tripGroupDepthAlpha = tripGroupDepth => {
  const asciiStart = 65; // => ascii code for 'A'
  return String.fromCharCode(asciiStart + tripGroupDepth);
};

function EvoTripListTripCardTripProgress({ trip, tripGroup, isTripGroupLoading }) {
  const activeStep = trip && trip.group_depth;
  const tripGroupTrips = tripGroup?.trips ?? [];

  const numSteps = tripGroupTrips?.length;
  const steps = R.range(0, ~~numSteps);

  return (
    <Skeleton fadeDuration={2} isLoaded>
      <Flex marginRight="8px" height="40px">
        {steps.map(stepDepth => {
          const stepAlpha = tripGroupDepthAlpha(stepDepth);
          const isActiveStep = stepDepth === activeStep;
          const barBgColor = isActiveStep ? '#2dca73' : '#e2e3e5';

          return (
            <VStack alignItems="center" spacing="0" key={stepDepth}>
              <Flex
                key={stepDepth}
                width="64px"
                overflow="visible"
                height="fit-content"
                alignItems="flex-end"
              >
                <Box
                  minWidth="64px"
                  height="5px"
                  roundedLeft="full"
                  bgColor={barBgColor}
                />
                <Flex direction="column" alignItems="center" marginLeft="-7px">
                  <Text
                    textStyle="tripListCard.tripProgressStepAlpha"
                    paddingBottom="3px"
                  >
                    {stepAlpha}
                  </Text>
                  <Icon h="13px" w="13px" name="place" color="general.darkBlue" />
                </Flex>
              </Flex>
              <Text textStyle="tripListCard.tripProgressStepDistance">
                {isActiveStep && `${metersToMiles(trip.distance)} mi`}
              </Text>
            </VStack>
          );
        })}
      </Flex>
    </Skeleton>
  );
}

EvoTripListTripCardTripProgress.propTypes = {
  trip: ridesTypes.tripType.isRequired,
  tripGroup: PropTypes.shape({
    id: ridesTypes.tripGroupIdType,
    rootTripId: ridesTypes.tripIdType,
    trips: PropTypes.arrayOf(ridesTypes.tripType),
  }),
};

// --

const EvoTripListTrip = ({
  // trip,
  tripId,
  showAssignAgentUI,
  ...props
}) => {
  const {
    trip,
    error: tripError,
    // refetch: refetchTrip,
    // isLoading: isLoadingTrip,
  } = useTrip(tripId, { enabled: false });
  const tripGroupId = trip?.group_id;

  // TODO: consider swapping out with react-tracked + zustand OR xstate
  // valtio proxy state store
  const [isTripActive, setActive] = useIsTripActive(tripId);

  const isTripLoaded = !!trip;

  const memberId = getTripMemberId(trip);
  const { member } = useMember(memberId, { enabled: !!memberId });

  const {
    refetch: refetchTripGroup,
    tripGroup,
    // error,
    isLoading: isLoadingTripGroup,
    isFetching: isFetchingTripGroup,
    // isError,
    // isSuccess,
  } = useTripGroup(tripGroupId);

  const {
    isOpen: relatedTripsIsOpen,
    onClose: relatedTripsOnClose,
    onToggle: relatedTripsOnToggle,
  } = useDisclosure();

  React.useEffect(() => {
    if (isTripActive) {
      refetchTripGroup();
    } else {
      /**
       * Collapse the related trips when card deactivated so they are colapsed
       * when the card is activated again.
       */
      relatedTripsOnClose();
    }
  }, [isTripActive, refetchTripGroup, relatedTripsOnClose]);

  const tripGroupTrips = tripGroup?.trips ?? [];
  const relatedTrips = tripGroupTrips.filter(tgTrip => tgTrip?.id !== tripId);
  const hasRelatedTrips = relatedTrips.length > 0;

  if (!isTripLoaded && tripError) {
    return 'An error has occurred: ' + todos.error.message;
  }

  if (!isTripLoaded) {
    return null;
    // return 'Loading...'
  }

  const relatedTripsLoaded = !isFetchingTripGroup && hasRelatedTrips;

  const expandDirectionText = `${
    relatedTripsIsOpen ? 'Collapse' : 'Expand'
  } related trips`;
  const expandButtonText = hasRelatedTrips ? expandDirectionText : 'no related trips';
  const expandTextColor = relatedTripsLoaded ? 'general.darkBlue' : 'general.blueDormant';

  const expandIcon = (
    <Icon
      h="16px"
      w="16px"
      color="general.blueDormant"
      name={relatedTripsIsOpen ? 'chevron-up' : 'chevron-down'}
    />
  );

  return (
    <NuiFlex>
      {showAssignAgentUI && (
        <Box marginLeft={-46}>
          <TripListRowAssignee size={28} trip={trip} />
        </Box>
      )}
      <EvoTripListCard
        {...props}
        isActive={isTripActive}
        onClick={setActive}
        disabled={false}
      >
        <EvoTripListCardTrip
          trip={trip}
          member={member}
          tripGroup={tripGroup}
          isExpanded={isTripActive}
          isTripGroupLoading={isLoadingTripGroup}
        />
        <Collapse in={isTripActive} animateOpacity>
          <Flex justifyContent="center" width="100%" paddingY="5px">
            <Button
              onClick={relatedTripsOnToggle}
              color={expandTextColor}
              size="xs"
              aria-label={`${relatedTripsIsOpen ? 'Collapse' : 'Expand'} related trips`}
              leftSection={expandIcon}
              rightIcon={expandIcon}
              // loadingText="loading related trips"
              isLoading={isFetchingTripGroup}
              isDisabled={!hasRelatedTrips}
              variant="outline"
              textTransform="lowercase"
              isFullWidth={true}
            >
              {expandButtonText}
            </Button>
          </Flex>

          <Collapse in={relatedTripsIsOpen} animateOpacity>
            {hasRelatedTrips && (
              <Box>
                {relatedTrips.map(relTrip => {
                  return (
                    <Box key={relTrip.id}>
                      <Box paddingY="16px">
                        <Divider />
                      </Box>
                      <EvoTripListCardTrip
                        trip={relTrip}
                        member={member}
                        tripGroup={tripGroup}
                        isTripGroupLoading={isLoadingTripGroup}
                      />
                    </Box>
                  );
                })}
              </Box>
            )}

            <Flex justifyContent="center" width="100%" paddingY="5px">
              <IconButton
                onClick={relatedTripsOnToggle}
                aria-label="Collapse related trips"
                icon={
                  <Icon w="16px" h="16px" name="chevron-up" color="general.darkBlue" />
                }
                isLoading={isLoadingTripGroup}
                variant="ghost"
                width="calc(100% - 5px)"
              />
            </Flex>
          </Collapse>
        </Collapse>
      </EvoTripListCard>
    </NuiFlex>
  );
};

function HoverAvatar({ name, tooltip, isHidden }) {
  return (
    <Flex margin="10px">
      {!isHidden && (
        <Tooltip label={tooltip} placement="top" openDelay={500}>
          <Avatar name={name} />
        </Tooltip>
      )}
    </Flex>
  );
}

function EvoVendorDriverSection({ trip }) {
  const driverName = trip?.custom_driver_name ?? '';
  const driverPhone = trip?.custom_driver_phone ?? '';
  const driverCarLicense = trip?.custom_driver_car_license ?? '';
  const driverCarDescription = trip?.custom_driver_car_description ?? '';
  const vendorName = getProviderDetailText(trip?.vendor);

  return (
    <TripCardVendorDriver
      driverCarLicense={driverCarLicense}
      driverCarDescription={driverCarDescription}
      driverPhoneNumber={driverPhone}
      driverName={driverName}
      vendorName={vendorName}
    />
  );
}

function EvoTripListCardTrip({
  trip,
  member,
  tripGroup,
  isTripGroupLoading,
  isExpanded = true,
}) {
  const memberId = getTripMemberId(trip);

  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: isExpanded });
  React.useEffect(() => {
    if (isExpanded) {
      onOpen();
    } else {
      onClose();
    }
  }, [isExpanded, onClose, onOpen]);

  const canConfirm = trip && trip.permissions && trip.permissions.can_confirm_vendor;
  const forceShowVendorConfirmNotice = canConfirm && !trip.is_vendor_confirmed;

  return (
    <>
      {/* Top row w/ trip date */}
      <Flex direction="row" justifyContent="space-between" height="47px">
        <Flex direction="column" justifyContent="space-between">
          <EvoTripWillCallTag trip={trip} />
          <EvoTripListTripDate trip={trip} />
        </Flex>
        <Flex alignItems="flex-start">
          <Stack direction="row" spacing="12px" alignItems="start">
            <EvoTripListLanguage trip={trip} width="76px" />
            <EvoTripListTripType trip={trip} width="52px" width="82px" />

            <EvoTripListTripId trip={trip} width="110px" isExpanded={isExpanded} />
            <EvoTripListAgent trip={trip} width="56px" />

            <Flex alignItems="center" width="140px" pr="6px">
              <TripStatusDropdown
                tripId={trip?.id}
                hideVendorNotConfirmed={true}
                /* disabled={!isExpanded} */
              />
            </Flex>
          </Stack>

          <ScaleFade initialScale={0.9} in={isOpen}>
            <TripListTripMenu
              memberId={memberId}
              tripId={trip.id}
              disabled={!isExpanded}
            />
          </ScaleFade>
        </Flex>
      </Flex>

      {/* Pickup row w/ action buttons */}
      <Flex direction="row" justifyContent="space-between">
        <EvoTripListCardPickup trip={trip} member={member} isActive={isExpanded} />
        <Flex />
        <TripListTripTookanTasks trip={camelizeKeys(trip)} />
        <Stack spacing="6px" direction="column" width="140px" mr="28px">
          <VendorNotConfirmedNotice
            trip={trip}
            forceShow={forceShowVendorConfirmNotice}
          />
          <ScaleFade initialScale={0.9} in={isOpen}>
            <Stack spacing="6px" direction="column" width="140px">
              <TripRequestPickupButton tripId={trip.id} disabled={!isExpanded} />
            </Stack>
          </ScaleFade>
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        {/* Dropoff row w/ action buttons */}
        <Flex
          direction="row"
          justifyContent="space-between"
          paddingLeft="20px"
          height="6px"
        />
        <Flex direction="row" justifyContent="space-between" alignItems="flex-end">
          <Flex direction="column" alignItems="flex-start">
            <EvoTripListCardDropoff trip={trip} member={member} />
            <EvoVendorDriverSection trip={trip} />
          </Flex>
          <EvoTripListTripCardTripProgress
            trip={trip}
            tripGroup={tripGroup}
            isTripGroupLoading={isTripGroupLoading}
          />
        </Flex>
      </Collapse>
    </>
  );
}

const EvoTripList = ({ tripList, showStaffUI, isLoading }) => {
  const hasResults = !R.isEmpty(tripList);
  const showAssignAgentUI = showStaffUI;

  if (!isLoading && !hasResults) {
    return (
      <EvoTripListCard disabled>
        <Text>No Results</Text>
      </EvoTripListCard>
    );
  }

  return (
    <Stack direction="column" spacing="12px">
      {tripList.map(trip => (
        <EvoTripListTrip
          key={trip.id}
          tripId={trip.id}
          showAssignAgentUI={showAssignAgentUI}
        />
      ))}
    </Stack>
  );
};

EvoTripList.propTypes = {
  isLoading: PropTypes.bool,
  tripList: PropTypes.arrayOf(ridesTypes.tripType),
  showStaffUI: PropTypes.bool.isRequired,
};

EvoTripList.displayName = 'EvoTripList';

export default EvoTripList;
