import React from 'react';
import PropTypes from 'prop-types';

import { Field, Column, Row } from 'rides/components';

const MemberIdFormFields = ({ criteria, handleChange }) => (
  <Row>
    <Column xs={12}>
      <Field
        type="text"
        name="externalId"
        value={criteria.external_id || ''}
        onChange={e => handleChange('external_id', e.target.value)}
        placeholder="Member ID"
        required
        // below props used to disable 1Password extension showing on field
        autocomplete="off"
        id="search-member-id"
      />
    </Column>
    <Column xs={12} sm={6}>
      <Field
        type="text"
        name="spacer-not-used"
        placeholder="Spacer"
        style={{ visibility: 'hidden' }}
      />
    </Column>
  </Row>
);

MemberIdFormFields.propTypes = {
  criteria: PropTypes.object,
  handleChange: PropTypes.func,
};

export default MemberIdFormFields;
