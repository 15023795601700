import { services } from 'rides/services';

const responseCheckStatus = response => {
  if (response.ok) {
    return response;
  }

  if (response.originalError) {
    const error = response.originalError;
    error.response = response;
    throw error;
  }

  const error = new Error(`${response.status} ${response.statusText}`);
  error.response = response;
  throw error;
};

async function client(
  endpoint,
  { data, params, headers: customHeaders, ...customConfig } = {},
) {
  const config = {
    url: endpoint,
    method: data ? 'POST' : 'GET',
    headers: { ...customHeaders },
    ...customConfig,
    data,
    params,
  };

  // TODO: handle 400-500 http status codes & token refresh w/ retry
  // return services.api.any(config).then(responseCheckStatus);

  try {
    const response = await services.api.any(config).then(responseCheckStatus);
    // const response = await services.api.any(config);
    // console.warn('caught nothing', { response });
    return response;
  } catch (error) {
    // console.warn('caught !!!', { error });
    // console.warn('caught !!!', { response: error?.response});

    if (error.response.status === 401) {
      console.warn('caught 401!');
      const response = await services.api.any(config).then(responseCheckStatus);
      return response;
    } else {
      // TODO: handle non-401 errors e.g. 404 error when entity doesn't exist
      throw error;
    }
  }
}

export { client };
