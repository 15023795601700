import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { fromAuth } from 'rides/store/selectors';
import Spinner from '../atoms/Spinner';

const mapStateToProps = state => ({
  isAuthAttempted: fromAuth.isAuthAttempted(state),
});

function RestoreSession({ children, isAuthAttempted }) {
  if (!isAuthAttempted) {
    return (
      <div style={{ textAlign: 'center', padding: '100px' }}>
        <Spinner />
      </div>
    );
  }

  return children;
}

RestoreSession.propTypes = {
  children: PropTypes.node.isRequired,
};

export default connect(mapStateToProps)(RestoreSession);
