import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter, Link as RouterLink } from 'react-router-dom';
// import { entitiesEmptyAllTypes, modalHide, resourceEmptyAll } from 'rides/store/actions';
import { TenantChangeModal } from 'rides/components';
import TenantDetails from 'rides/evo/NavTop/TenantDetails';
// import { go, replace as routerReplace } from 'connected-react-router';
// import { getTenantUrl, useCurrentTenantId } from 'rides/hooks/use-tenant';
import { Flex, Button } from '@chakra-ui/react';
import { authLogOut, tenantChange } from 'rides/store/actions';
import { fromApp, fromAuth } from 'rides/store/selectors';
// import { tenantType } from 'rides/types';

// const RightSlot = styled('div', {
//   marginLeft: 'auto',
//   paddingLeft: 20,
//   color: '$ccGray600',
//   // ':focus > &': { color: 'white' },
//   '[data-disabled] &': { color: '$ccGray400' },
// })
//
// const IconButton = styled('button', {
//   all: 'unset',
//   fontFamily: 'inherit',
//   borderRadius: '100%',
//   height: 35,
//   width: 35,
//   display: 'inline-flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   color: '$ccBlue900',
//   backgroundColor: 'white',
//   boxShadow: '0 2px 10px $color$ccGray800',
//   '&:hover': { backgroundColor: '$colors$ccGray400' },
//   '&:focus': { boxShadow: '0 0 0 2px $colors$ccGray400' },
// })

// const TenantChangeModalContainer = props => {
//   const dispatch = useDispatch();
//   const currentTenantId = useCurrentTenantId();

//   const changeTenantFn = useCallback(
//     tenantId => {
//       window.location.href = getTenantUrl(tenantId);
//       // dispatch(resourceEmptyAll(['tenant']));
//       // dispatch(entitiesEmptyAllTypes(['tenant']));
//       // dispatch(routerReplace(getTenantUrl(tenantId)));
//     },
//     [dispatch],
//   );

//   const modalHideFn = useCallback(
//     modalName => {
//       return dispatch(modalHide(modalName));
//     },
//     [dispatch],
//   );

//   return (
//     <TenantChangeModal
//       {...props}
//       currentTenantId={currentTenantId}
//       changeTenant={changeTenantFn}
//       modalHide={modalHideFn}
//       onSubmit={(tenantId, formikBag, closeModal) => {
//         changeTenantFn(tenantId);
//         /* formikBag.resetForm(); */
//         closeModal();
//       }}
//     />
//   );
// };

// export default TenantChangeModalContainer;

function NavTop({
  logOut,
  onChangeTenant,
  canChangeTenant,
  tenant,
  userDisplayName,
  showAdminUI,
  showStaffUI,
  hideForHumana,
  hideTripList,
}) {
  const passedProps = {
    logOut,
    onChangeTenant,
    canChangeTenant,
    tenant,
    userDisplayName,
    showAdminUI,
    showStaffUI,
    hideForHumana,
    hideTripList,
  };

  return (
    <Flex width="100%" alignItems="center" justifyContent="flex-end" px="20px">
      <TenantDetails
        onChangeTenant={onChangeTenant}
        canChangeTenant={canChangeTenant}
        // tenant
        tenant={tenant}
      />

      <Button
        as={RouterLink}
        to="/logout"
        ml="10px"
      >
        Log Out
      </Button>
    </Flex>
  );
}

NavTop.propTypes = {
  canChangeTenant: PropTypes.bool,
  onChangeTenant: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
  // tenant: tenantType,
  userDisplayName: PropTypes.string.isRequired,
  showAdminUI: PropTypes.bool.isRequired,
  showStaffUI: PropTypes.bool.isRequired,
  hideForHumana: PropTypes.bool.isRequired,
  hideTripList: PropTypes.bool.isRequired,
};

const NavTopContainer = props => <NavTop {...props} />;

const mapDispatchToProps = {
  logOut: authLogOut,
  onChangeTenant: e => {
    if (e) e.preventDefault();
    return tenantChange();
  },
};

const mapStateToProps = (state, props) => ({
  canChangeTenant: fromAuth.hasMultipleTenantIds(state, props),
  tenant: fromApp.tenant.currentTenant(state, props),
  userDisplayName: fromAuth.userDisplayName(state, props),
  showAdminUI: fromApp.ui.showAdminUI(state, props),
  showStaffUI: fromApp.ui.showStaffUI(state, props),
  hideForHumana: fromApp.ui.hideForHumana(state, props),
  hideTripList: fromApp.ui.hideTripList(state, props),
});

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(NavTopContainer);
