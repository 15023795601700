/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'LogOutIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M8.9 15.226c0 .427.347.774.775.774h4.28c.984 0 1.782-.809 1.782-1.806V1.806C15.737.81 14.939 0 13.955 0h-4.28a.774.774 0 000 1.548h4.28c.14 0 .255.116.255.258v12.387a.256.256 0 01-.255.259h-4.28a.774.774 0 00-.774.774zM4.998 4.389L2.48 7.226h8.449v1.548H2.479l2.518 2.837-1.135 1.036-3.665-4.13a.78.78 0 010-1.035l3.665-4.129L4.997 4.39z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
