/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'LabsIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M14.522 1.411L13.188.078a.267.267 0 00-.377 0l-1.144 1.145-.611-.612a.267.267 0 00-.377 0L5.345 5.945a.267.267 0 000 .377l.345.345L4.545 7.81a.267.267 0 000 .377l1.6 1.6a.266.266 0 00.377 0l1.145-1.144.344.344a.266.266 0 00.365.013l.92-.805a.267.267 0 00.007-.394 2.642 2.642 0 01-.836-1.935A2.67 2.67 0 0111.133 3.2c.832 0 1.6.38 2.11 1.042.048.06.119.098.195.103a.272.272 0 00.205-.078l.345-.345a.266.266 0 000-.377l-.61-.612 1.144-1.145a.267.267 0 000-.377z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M13.267 5.867a2.136 2.136 0 00-2.134-2.134A2.136 2.136 0 009 5.867C9 7.043 9.957 8 11.133 8a2.135 2.135 0 002.134-2.133z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M13.933 7.222a.265.265 0 00-.482-.04 2.695 2.695 0 01-1.215 1.11.267.267 0 00-.15.303c.076.325.114.663.114 1.005 0 1.989-1.87 3.733-4 3.733-1.041 0-1.89-.101-2.523-.533h1.99c.147 0 .266-.12.266-.267v-1.6a.267.267 0 00-.266-.266h-6.4a.267.267 0 00-.267.266v1.6c0 .148.12.267.267.267h.892c.298.628.716.917 1.195 1.248.1.069.204.14.313.22v1.465c0 .148.12.267.266.267h9.6c.147 0 .267-.12.267-.267V13.6a.267.267 0 00-.267-.267h-.501c.843-1.111 1.301-2.418 1.301-3.733 0-.855-.134-1.655-.4-2.378z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
