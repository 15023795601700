/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'ImpairedIcon',
  path: (
    <g fill="currentColor">
      <g clip-path="url(#clip0)">
        <path d="M3.383 9.291c-.17 0-.283.113-.283.284 0 .17.113.283.283.283 1.163 0 2.07-.936 2.07-2.07a2.08 2.08 0 00-1.786-2.07 2.752 2.752 0 012.75-2.523c1.503 0 2.75 1.219 2.75 2.75 0 .17.114.284.284.284.17 0 .284-.114.284-.284a3.322 3.322 0 00-3.318-3.317A3.304 3.304 0 003.1 5.945v.057c0 .17.113.283.283.283.822 0 1.503.68 1.503 1.503 0 .823-.652 1.503-1.503 1.503zM15.519 10.765H13.25c-.17 0-.283.114-.283.284 0 .17.113.284.283.284h2.269c.17 0 .283-.114.283-.284 0-.17-.113-.284-.283-.284zM13.449 13.119a.274.274 0 00-.397 0 .274.274 0 000 .397l.992.992a.306.306 0 00.199.085.306.306 0 00.198-.085.274.274 0 000-.397l-.992-.992zM13.25 9.036a.257.257 0 00.199-.085l.992-.993a.274.274 0 000-.397.274.274 0 00-.397 0l-.992.993a.274.274 0 000 .397.257.257 0 00.198.085z" />
        <path
          stroke-width=".442"
          d="M7.068.87H6.53A5.518 5.518 0 001 6.399v.822c0 .17.113.284.284.284.17 0 .283-.114.283-.284V6.4A4.968 4.968 0 016.53 1.437h.54c2.24 0 4.082 1.843 4.082 4.083 0 1.077-.397 2.07-1.162 2.835l-.624.624c-1.305 1.305-2.098 2.098-2.098 4.339v.198c0 .992-.794 1.786-1.787 1.786a1.779 1.779 0 01-1.786-1.786v-2.07c0-.17-.114-.284-.284-.284-.17 0-.283.114-.283.284v2.07A2.348 2.348 0 005.48 15.87a2.348 2.348 0 002.354-2.354v-.198c0-2.014.652-2.666 1.956-3.942l.624-.624a4.592 4.592 0 001.304-3.232c0-2.58-2.098-4.65-4.65-4.65z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 16 16',
});
