/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'DeviceIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M8.132 3.733c1.03 0 1.867-.837 1.867-1.866C9.999.837 9.162 0 8.132 0 7.103 0 6.266.837 6.266 1.867c0 1.029.837 1.866 1.866 1.866zM10.19 12.791a.268.268 0 00-.34.163A3.772 3.772 0 016.3 15.467a3.772 3.772 0 01-3.768-3.769 3.752 3.752 0 013.14-3.71.267.267 0 00-.088-.527A4.285 4.285 0 002 11.698 4.307 4.307 0 006.301 16a4.308 4.308 0 004.052-2.868.268.268 0 00-.163-.34z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M14.515 14.454l-.53-3.71a1.34 1.34 0 00-1.32-1.144h-3.2V7.908l.313.313c.199.198.474.312.754.312h2.133a1.068 1.068 0 000-2.133h-1.691L9.686 5.112a1.005 1.005 0 00-.261-.186.265.265 0 00-.226-.126H7.865c-.588 0-1.066.478-1.066 1.066v4c0 1.324 1.077 2.4 2.4 2.4h2.31l.37 2.59a1.338 1.338 0 001.53 1.127c.714-.112 1.21-.798 1.106-1.53z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
