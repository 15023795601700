import React from 'react';
import PropTypes from 'prop-types';

import { tripGroupType, tripType } from 'rides/types';
import { useTenantUrl } from 'rides/hooks/use-tenant';
import EntityDetail from 'rides/components/utils/EntityDetail';
import {
  Button,
  DateTime,
  Glyphicon,
  Member,
  Table,
  TableCell,
  TableRow,
  TripType,
} from 'rides/components';
import { TripLegList } from 'rides/containers';

function ViewTripLinkButton({ rootTrip, tripGroup }) {
  const linkTo = useTenantUrl(`/member/${rootTrip.member.id}/trip-group/${tripGroup.id}`);
  return (
    <Button to={linkTo} primary sm>
      <Glyphicon icon="search" small /> View
    </Button>
  );
}
ViewTripLinkButton.propTypes = {
  rootTrip: tripType,
  tripGroup: tripGroupType,
};

const TripGroupListTable = ({ tripGroupList }) => (
  <Table
    data-test-handle="trip group list table"
    head={
      <TableRow>
        <TableCell heading xs={1} textCenter>
          Trip Id
        </TableCell>
        <TableCell heading xs={1} textCenter>
          Ref #
        </TableCell>
        <TableCell heading xs={2}>
          Member
        </TableCell>
        <TableCell heading xs={1}>
          Date
        </TableCell>
        <TableCell heading xs={5}>
          Legs
        </TableCell>
        <TableCell heading xs={1}>
          Type
        </TableCell>
        <TableCell heading xs={1}>
          Language
        </TableCell>
        <TableCell heading xs={1} />
      </TableRow>
    }
  >
    {tripGroupList &&
      !!tripGroupList.length &&
      tripGroupList.map(tripGroup => (
        <EntityDetail
          entityType="trip"
          entityId={tripGroup.rootTripId}
          key={tripGroup.rootTripId}
        >
          {rootTrip => (
            <TableRow>
              <TableCell textCenter>{rootTrip.id}</TableCell>
              <TableCell textCenter>{rootTrip.groupReferenceNumber}</TableCell>
              <TableCell>
                <Member member={rootTrip.member} />
              </TableCell>
              <TableCell>
                <DateTime datetime={rootTrip.pickupTime} showDate />
              </TableCell>
              <TableCell>
                <TripLegList tripGroupId={tripGroup.id} />
              </TableCell>
              <TableCell>
                <TripType trip={rootTrip} hideHeading />
              </TableCell>
              <TableCell>{rootTrip.member.languagesSpoken}</TableCell>
              <TableCell textRight>
                <ViewTripLinkButton rootTrip={rootTrip} tripGroup={tripGroup} />
              </TableCell>
            </TableRow>
          )}
        </EntityDetail>
      ))}

    {tripGroupList && !tripGroupList.length && (
      <TableRow>
        <TableCell colSpan={5} className="text-center">
          No trips matching the selected criteria.
        </TableCell>
      </TableRow>
    )}
  </Table>
);

TripGroupListTable.propTypes = {
  tripGroupList: PropTypes.arrayOf(tripGroupType),
};

export default TripGroupListTable;
