import * as R from 'ramda';
import { consoleErrorRecovery, safeSaga } from 'rides/store/helpers';
import { select, takeEvery, takeLatest, put, putResolve, call } from 'redux-saga/effects';
import { push as routerPush } from 'connected-react-router';

import { fromApp, fromEntities } from 'rides/store/selectors';
import { confirmSaga } from 'rides/store/confirm/sagas';
import { handleApiErrorResponse } from 'rides/store/app/trip/sagas';
import { currentMember } from 'rides/store/app/member/selectors';

import * as action from './actions';
import { convertToReadableDate, convertToReadableTime } from 'rides/utils/dateTime';
import {
  modalHide,
  modalShow,
  resourceCreateRequest,
  resourceDetailReadRequest,
  resourceListReadRequest,
  resourceUpdateRequest,
} from 'rides/store/actions';

const removeEmpty = R.reject(R.either(R.isEmpty, R.isNil));

export const safeSortString = R.compose(R.toLower, R.trim, R.defaultTo(''));
export const pickupTimeSortValue = R.compose(safeSortString, R.prop('pickupTime'));
export const sortTripList = R.sortWith([R.ascend(pickupTimeSortValue)]);

const requestTripGroupList = (memberId, tripGroupId) =>
  resourceDetailReadRequest(`member/${memberId}/trip_group_v2`, tripGroupId, 'tripGroup');

const requestCreateTrip = (memberId, trip) =>
  resourceCreateRequest(`member/${memberId}/trip`, { trip }, 'trip');

const requestCreateTripGroupTrip = (memberId, tripGroupId, trip) =>
  resourceCreateRequest(
    `member/${memberId}/trip_group_v2/${tripGroupId}/trip`,
    { trip },
    'trip',
  );

const requestUpdateTrip = (memberId, tripId, trip) =>
  resourceUpdateRequest(`member/${memberId}/trip`, tripId, { trip }, 'trip');

/**
 * Task Sagas
 **/

export function* closeWizard(promtConfirm = true) {
  if (promtConfirm) {
    const confirmed = yield call(confirmSaga, {
      // title: '',
      message: 'Any changes made will be lost, are you sure you want to close?',
      yesButtonText: 'Close',
      danger: true,
    });

    if (confirmed) {
      yield put(modalHide('trip-wizard'));
    }
  } else {
    yield put(modalHide('trip-wizard'));
  }
}

export function* closeWizardAndRedirectToTrip(memberId, tripGroupId, tripId) {
  yield call(closeWizard, false);
  const tripHash = tripId ? `#leg-${tripId}` : '';
  yield put(routerPush(`/member/${memberId}/trip-group/${tripGroupId}${tripHash}`));
}

/**
 * Generator that checks if a member's plan trip limit is exceeded and shows
 * confirmation modal warning user to check member's trip history before
 * proceeding.
 *
 * The member trip limit is considered confirmed (i.e. allowed to create trip)
 * for the following scenarios:
 *   1) Member doesn't have a plan
 *   2) Member hasn't exceeded their plan trip limit
 *   3) Member has exceeded trip limit but user confirmed proceed
 *
 * @yields {boolean} `true` indicates trip CAN be created for member and `false`
 *                   indicates it should NOT.
 **/
export function* confirmMemberTripLimit(member) {
  // Allow Scenario #1 - Member doesn't have a plan
  const memberHasPlan = member?.plan;
  if (!memberHasPlan) return true;

  const numTripsAllowed = member?.plan?.tripsAllowed;
  const numTripsCompleted = member?.completedTrips;
  const numTripsAvailable = numTripsAllowed - numTripsCompleted;

  // Allow Scenario #2 - Member hasn't exceeded their plan trip limit
  const isTripLimitExceeded = numTripsAvailable < 1;
  if (!isTripLimitExceeded) return true;

  const didConfirmExceededTripLimit = yield call(confirmSaga, {
    title: 'Member Trip Limit Exceeded',
    message:
      'Member appears to have used all of their trips. Please check trip history to verify.',
    yesButtonText: 'Proceed',
    noButtonText: 'Cancel',
    danger: true,
  });

  // Allow Scenario #3 - Member has exceeded trip limit but confirmed proceed
  return didConfirmExceededTripLimit;
}

export function* createTrip(memberId, trip) {
  try {
    const { data: tripId } = yield putResolve(requestCreateTrip(memberId, trip));
    const newTrip = yield select(fromApp.trip.trip, { tripId });
    yield call(closeWizardAndRedirectToTrip, memberId, newTrip.groupId, tripId);
  } catch (error) {
    yield call(handleApiError, error);
  }
}

export function* createTripGroupTrip(memberId, tripGroupId, trip) {
  try {
    yield putResolve(requestCreateTripGroupTrip(memberId, tripGroupId, trip));

    // update the trip group entity so pages update with new
    yield putResolve(requestTripGroupList(memberId, tripGroupId));

    yield call(closeWizard, false);
  } catch (error) {
    yield call(handleApiError, error);
  }
}

export function* updateTrip(memberId, tripId, trip) {
  try {
    yield putResolve(requestUpdateTrip(memberId, tripId, trip));
    yield call(closeWizard, false);
  } catch (error) {
    yield call(handleApiError, error);
  }
}

export function* goToNextStep({ values }) {
  yield put(action.tripWizardUpdateValues(values));
  const nextStep = yield select(fromApp.tripWizard.nextStep);

  if (nextStep) {
    yield put(action.tripWizardSetCurrentStep(nextStep));
  } else {
    const tripGroupId = yield select(fromApp.tripWizard.tripGroupId);
    const memberId = yield select(fromApp.tripWizard.memberId);
    const tripId = yield select(fromApp.tripWizard.tripId);
    const trip = yield select(fromApp.tripWizard.apiTripData);

    yield put(action.tripWizardDisableForm());

    if (tripGroupId) {
      // add new trip leg
      yield call(createTripGroupTrip, memberId, tripGroupId, trip);
    } else if (tripId) {
      // edit existing trip
      yield call(updateTrip, memberId, tripId, trip);
    } else {
      // create new trip!
      yield call(createTrip, memberId, trip);
    }
  }
}

export function* loadTripTypeList() {
  try {
    const resp = yield putResolve(resourceListReadRequest('trip/type', null, 'tripType'));
    return resp;
  } catch (error) {
    console.log('sagaError::loadTripTypeList::Not Handled!', error);
  }
}

export function* watchTenantLoadList() {
  yield call(loadTripTypeList);
}

export function* goToPrevStep({ values }) {
  const prevStep = yield select(fromApp.tripWizard.prevStep);

  if (prevStep) {
    yield put(action.tripWizardUpdateValues(values));
    yield put(action.tripWizardSetCurrentStep(prevStep));
  }
}

export function* initEditTrip({ tripId }) {
  const state = yield select();
  const trip = fromEntities.getDetail(state, 'trip', tripId);

  yield put(action.tripWizardSetMember(trip.member));

  const appointmentDateTime = R.path(['appointmentTime'], trip);
  const pickupDateTime = R.path(['pickupTime'], trip);

  const appointmentTime =
    appointmentDateTime && convertToReadableTime(appointmentDateTime);
  const reservationDate = pickupDateTime && convertToReadableDate(pickupDateTime);
  const pickupTime = pickupDateTime && convertToReadableTime(pickupDateTime);

  const tripRootId = R.path(['rootId'], trip);
  const isRootTrip = tripId === tripRootId;
  const hideWillCall = isRootTrip;

  const values = removeEmpty({
    pickup_isHome: false, // TODO figure out if we can determine this on load
    pickup_name: R.path(['pickup', 'name'], trip),
    pickup_phoneNumber: R.path(['pickup', 'phoneNumber'], trip),
    pickup_address1: R.path(['pickup', 'address1'], trip),
    pickup_address2: R.path(['pickup', 'address2'], trip),
    pickup_city: R.path(['pickup', 'city'], trip),
    pickup_state: R.path(['pickup', 'state'], trip),
    pickup_postalCode: R.path(['pickup', 'postalCode'], trip),

    destination_isHome: false, // TODO figure out if we can determine this on load
    destination_name: R.path(['dropoff', 'name'], trip),
    destination_phoneNumber: R.path(['dropoff', 'phoneNumber'], trip),
    destination_address1: R.path(['dropoff', 'address1'], trip),
    destination_address2: R.path(['dropoff', 'address2'], trip),
    destination_city: R.path(['dropoff', 'city'], trip),
    destination_state: R.path(['dropoff', 'state'], trip),
    destination_postalCode: R.path(['dropoff', 'postalCode'], trip),

    dropoffId: R.path(['dropoff', 'id'], trip),
    pickupId: R.path(['pickup', 'id'], trip),

    purposeId: R.path(['purposeId'], trip),
    typeId: R.path(['typeId'], trip),

    notes: R.path(['notes'], trip),

    reservationDate,
    appointmentTime,
    pickupTime,
    isWillCall: R.path(['isWillCall'], trip),

    companionId: R.path(['companion', 'id'], trip),
    companion_name: R.path(['companion', 'name'], trip),
    companion_relationship: R.path(['companion', 'relationship'], trip),
    companion_phone: R.path(['companion', 'phoneNumber'], trip),
    companion_phoneType: R.path(['companion', 'phoneNumberType'], trip),
    companion_primaryContact: R.path(['companion', 'primaryContact'], trip),

    numberOfCompanions: R.path(['numberOfCompanions'], trip),

    authCode: R.path(['authCode'], trip),
    mapUrl: R.path(['mapUrl'], trip),

    hideWillCall,

    groupReferenceNumber: R.path(['groupReferenceNumber'], trip),
  });

  yield put(action.tripWizardUpdateValues(values));

  yield put(modalShow('trip-wizard'));
}

export function* initNewTrip({ memberId }) {
  const member = yield select(currentMember, { memberId });
  yield put(action.tripWizardSetMember(member));

  const canProceed = yield call(confirmMemberTripLimit, member);
  if (!canProceed) return false;

  yield call(loadTripTypeList);
  const defaultTripTypeId = yield select(fromApp.tripWizard.defaultTripTypeLOS);

  yield put(
    action.tripWizardUpdateValues({
      hideWillCall: true,
      typeId: defaultTripTypeId,
    }),
  );
  yield put(modalShow('trip-wizard'));
}

export function* initNewTripLeg({ tripGroupId }) {
  const state = yield select();

  const tripGroup = fromEntities.getDetail(state, 'tripGroup', tripGroupId);
  const tripList = fromEntities.getList(state, 'trip', tripGroup.trips);

  const sortedTripList = sortTripList(tripList);
  const prevTrip = R.last(sortedTripList);

  const pickupDateTime = R.path(['pickupTime'], prevTrip);
  const reservationDate = pickupDateTime && convertToReadableDate(pickupDateTime);

  const values = removeEmpty({
    pickup_isHome: false, // TODO figure out if we can determine this on load
    pickup_name: R.path(['dropoff', 'name'], prevTrip),
    pickup_phoneNumber: R.path(['dropoff', 'phoneNumber'], prevTrip),
    pickup_address1: R.path(['dropoff', 'address1'], prevTrip),
    pickup_address2: R.path(['dropoff', 'address2'], prevTrip),
    pickup_city: R.path(['dropoff', 'city'], prevTrip),
    pickup_state: R.path(['dropoff', 'state'], prevTrip),
    pickup_postalCode: R.path(['dropoff', 'postalCode'], prevTrip),

    purposeId: R.path(['purposeId'], prevTrip),
    typeId: R.path(['typeId'], prevTrip),

    notes: R.path(['notes'], prevTrip),

    reservationDate,

    companion_name: R.path(['companion', 'name'], prevTrip),
    companion_relationship: R.path(['companion', 'relationship'], prevTrip),
    companion_phone: R.path(['companion', 'phoneNumber'], prevTrip),
    companion_phoneType: R.path(['companion', 'phoneNumberType'], prevTrip),
    companion_primaryContact: R.path(['companion', 'primaryContact'], prevTrip),

    hideWillCall: false,

    groupReferenceNumber: R.path(['groupReferenceNumber'], prevTrip),
  });

  const canProceed = yield call(confirmMemberTripLimit, prevTrip.member);
  if (!canProceed) return false;

  yield put(action.tripWizardSetMember(prevTrip.member));
  yield put(action.tripWizardUpdateValues(values));

  yield put(modalShow('trip-wizard'));
}

export function* handleApiError(apiError) {
  const { errorMap } = yield call(handleApiErrorResponse, apiError);

  if (errorMap) {
    let stepErrors = {};
    if (errorMap.dropoff) {
      stepErrors.dropoff = 'Address lookup failed. Please correct address.';
      yield put(action.tripWizardSetCurrentStep('destination'));
    }

    if (errorMap.pickup) {
      stepErrors.pickup = 'Address lookup failed. Please correct address.';
      yield put(action.tripWizardSetCurrentStep('pickup'));
    }

    if (errorMap.referenceNumberId) {
      stepErrors.pickupTime = `Reference Number ${errorMap.referenceNumberId}.`;
      yield put(action.tripWizardSetCurrentStep('pickup-time'));
    }

    yield put(action.tripWizardSetStepErrors(stepErrors));
  }

  yield put(action.tripWizardEnableForm());
}

/**
 * Watcher Sagas
 **/

export function* watchClose(api, { payload }) {
  yield call(closeWizard);
}

export function* watchGoToPrevStep(api, { payload }) {
  yield call(goToPrevStep, payload);
}

export function* watchGoToNextStep(api, { payload }) {
  yield call(goToNextStep, payload);
}

export function* watchInitEditTrip(api, { payload }) {
  yield call(initEditTrip, payload);
}

export function* watchInitNewTrip(api, { payload }) {
  yield call(initNewTrip, payload);
}

export function* watchInitNewTripLeg(api, { payload }) {
  yield call(initNewTripLeg, payload);
}

/**
 * Root Sagas
 **/

export default function*({ api }) {
  const safe = safeSaga(consoleErrorRecovery);
  yield takeLatest(action.TRIP_WIZARD_INIT_NEW_TRIP, safe(watchInitNewTrip, api));
  yield takeLatest(action.TRIP_WIZARD_INIT_EDIT_TRIP, safe(watchInitEditTrip, api));
  yield takeLatest(action.TRIP_WIZARD_INIT_NEW_TRIP_LEG, safe(watchInitNewTripLeg, api));

  yield takeEvery(action.TRIP_WIZARD_CLOSE, safe(watchClose, api));
  yield takeEvery(action.TRIP_WIZARD_GO_TO_NEXT_STEP, safe(watchGoToNextStep, api));
  yield takeEvery(action.TRIP_WIZARD_GO_TO_PREV_STEP, safe(watchGoToPrevStep, api));
}
