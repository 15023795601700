import * as React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Box,
  Badge,
  UnorderedList,
  ListItem,
  Link,
  useToast,
  SimpleGrid,
} from '@chakra-ui/react';

import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Formik, Form } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import { format, parse, isDate, startOfToday } from 'date-fns';
import { useTenantUrl } from 'rides/hooks/use-tenant';
import { useDuplicateTripGroup } from '../api-hooks';
import { DatePicker, maxDate } from './date-picker';

function formatDupeDate(date) {
  return format(date, 'yyyy-MM-dd');
}

function TripLink({ trip, ...props }) {
  const memberId = R.path(['member', 'id'], trip);
  const groupId = R.path(['group_id'], trip);
  const tripId = R.path(['id'], trip);
  const linkTo = useTenantUrl(`/member/${memberId}/trip-group/${groupId}#leg-${tripId}`);

  return (
    <Link to={linkTo} as={RouterLink} textStyle="h6" color="teal.500" isExternal>
      Trip Group {tripId}
      <ExternalLinkIcon mx="2px" />
    </Link>
  );
}

function parseSourceDateString(originalValue) {
  const parsedDate = new Date(originalValue);
  return parsedDate;
}

function parseDateString(value, originalValue) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'yyyy-MM-dd', new Date());

  return parsedDate;
}

const TripGroupDupelicateSchema = Yup.object().shape({
  newDate: Yup.date()
    .transform(parseDateString)
    .required('Date is a required field')
    .min(startOfToday(), 'Date can not be in the past')
    .max(maxDate, 'Date must be within three years'),
});

const useTripGroupDuplicateModal = () => {
  const [createdTripGroups, setCreatedTripGroups] = React.useState([]);
  const [tripId, setTripId] = React.useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  function onOpenModal(tripId) {
    return () => {
      setTripId(tripId);
      onOpen();
    };
  }

  function onCloseModal() {
    onClose();
    setTripId(null);
    emptyCreatedTripGroups();
  }

  function emptyCreatedTripGroups() {
    setCreatedTripGroups([]);
  }

  function onDuplicateTripGroupSuccess(tripGroup) {
    setCreatedTripGroups([...createdTripGroups, tripGroup]);
  }

  return {
    tripId,
    isOpen,
    onCloseModal,
    onOpenModal,

    createdTripGroups,
    emptyCreatedTripGroups,
    onDuplicateTripGroupSuccess,
  };
};

function TripGroupDuplicateModal({
  trip,
  isOpen,
  onCloseModal,
  createdTripGroups,
  onDuplicateTripGroupSuccess,
}) {
  const initialRef = React.useRef();
  const finalRef = React.useRef();

  const toast = useToast();

  const {
    mutateAsync: handleDuplicateTripGroup,
    isLoading: isDupeTripLoading,
    // isError: isDupeTripError,
  } = useDuplicateTripGroup({
    // onError: (error, variables, context) => {
    //   // An error happened!
    // },
    onSuccess: (data, variables, context) => {
      const tripGroup = R.path(['data'], data);
      const dupeParams = variables;
      onDuplicateTripGroupSuccess({ tripGroup, dupeParams });
    },
    // onSettled: (data, error, variables, context) => {
    //   // Error or success... doesn't matter!
    // },
  });

  const [sourceTripDay, setSourceTripDay] = React.useState('');
  React.useEffect(() => {
    const parsedSourceDate = parseSourceDateString(trip.pickupTime);
    const sourceDateStr = formatDupeDate(parsedSourceDate);
    setSourceTripDay(sourceDateStr);
  }, [trip.pickupTime]);

  return (
    <Formik
      initialValues={{ newDate: undefined }}
      onSubmit={async (values, actions) => {
        try {
          const newDate = formatDupeDate(values.newDate);
          await handleDuplicateTripGroup({ tripId: trip.id, newDate });
          actions.resetForm();
          toast({
            title: `Duplicate Trip Group Created`,
            status: 'success',
            isClosable: true,
          });
        } catch (error) {
          console.error(error);
          console.log('error from onSubmit');
          toast({
            title: `Trip Group Duplicate Failed`,
            description: `Failed to duplicate trip group. Please refresh and try again later.`,
            status: 'error',
            isClosable: true,
          });
        }
      }}
      validationSchema={TripGroupDupelicateSchema}
    >
      {props => {
        const showNewDateError = props.errors.newDate && props.touched.newDate;
        return (
          <Modal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={() => {
              onCloseModal();
              props.resetForm();
            }}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size="5xl"
            isCentered
          >
            <ModalOverlay />
            <ModalContent>
              <Form>
                <ModalHeader>Duplicate Trip Group</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6} minHeight={20}>
                  <SimpleGrid columns={2} spacing={8}>
                    <SimpleGrid columns={1} spacing={8}>
                      <FormControl>
                        <FormLabel fontWeight="bold">Source Trip ID</FormLabel>
                        <Badge fontSize="xl" paddingX={2}>
                          {trip.id}
                        </Badge>
                      </FormControl>

                      <FormControl>
                        <FormLabel fontWeight="bold">Source Trip Date</FormLabel>
                        {`${sourceTripDay}`}
                      </FormControl>

                      <FormControl isInvalid={showNewDateError} isRequired>
                        <FormLabel fontWeight="bold" htmlFor="new-date">
                          New Date
                        </FormLabel>
                        <DatePicker
                          id="new-date"
                          name="newDate"
                          selectedDate={props.values.newDate}
                          showPopperArrow={true}
                          onChange={date => {
                            const newValue = date === null ? undefined : date;
                            props.setFieldValue('newDate', newValue);
                          }}
                          dateFormat="MM/dd/yyyy"
                          onBlur={props.handleBlur}
                          value={props.values.newDate}
                          ref={initialRef}
                          isRequired
                        />
                        {!showNewDateError && <FormHelperText>&nbsp;</FormHelperText>}
                        <FormErrorMessage>{props.errors.newDate}</FormErrorMessage>
                      </FormControl>
                    </SimpleGrid>
                    <Box flex="1">
                      <FormLabel fontWeight="bold">Created Trip Groups</FormLabel>
                      <UnorderedList spacing={3}>
                        {createdTripGroups.map(({ tripGroup, dupeParams }) => {
                          const trip = R.path(['trips', 0], tripGroup);
                          return (
                            <ListItem key={trip.id}>
                              <TripLink trip={trip} />
                              {` - ${dupeParams.newDate}`}
                            </ListItem>
                          );
                        })}
                      </UnorderedList>
                    </Box>
                  </SimpleGrid>
                </ModalBody>

                <ModalFooter>
                  {/* <Button onClick={onCloseModal}>Cancel</Button> */}
                  <Button
                    colorScheme="blue"
                    ml={3}
                    type="submit"
                    isLoading={isDupeTripLoading}
                    loadingText="Duplicating"
                  >
                    Create Duplicate
                  </Button>
                </ModalFooter>
              </Form>
            </ModalContent>
          </Modal>
        );
      }}
    </Formik>
  );
}

export { TripGroupDuplicateModal, useTripGroupDuplicateModal };
