import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import { activityLogType } from 'rides/types';
import { Atom, DateTime, Strong } from 'rides/components';
import { actionTarget } from './ActionText';

const FormattedValue = ({ field, value }) => {
  let formattedValue = <Atom>{value}</Atom>;
  if (field && field.match(/(time)$/g)) formattedValue = <DateTime datetime={value} />;

  return <Strong>{formattedValue}</Strong>;
};

FormattedValue.propTypes = {
  field: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const DescriptionText = ({ log }) => {
  const description = R.path(['data', 'description'], log);

  if (!description) {
    return null;
  }

  if (typeof description === 'string') {
    return <Atom>{description}</Atom>;
  }

  const newValue = R.prop('new', description);
  const oldValue = R.prop('old', description);

  const oldValuePrefix = oldValue && newValue ? 'from ' : '';
  const newValuePrefix = oldValue && newValue ? 'to ' : '';

  const field = actionTarget(log.action);

  if (oldValue || newValue) {
    return (
      <Atom>
        {oldValue && (
          <Atom>
            {oldValuePrefix}
            <FormattedValue field={field} value={oldValue} />
          </Atom>
        )}
        {oldValue && newValue && ' '}
        {newValue && (
          <Atom>
            {newValuePrefix}
            <FormattedValue field={field} value={newValue} />
          </Atom>
        )}
      </Atom>
    );
  }

  return null;
};

DescriptionText.propTypes = {
  log: activityLogType,
  // description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default DescriptionText;
