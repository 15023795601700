import * as React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withProps } from 'recompose';

import { modalHide, resourceCreateRequest } from 'rides/store/actions';
import { fromApp } from 'rides/store/selectors';
import { TripAssignVendorModal } from 'rides/components';

const mapDispatchToProps = {
  assignVendor: (tripId, vendorId) =>
    resourceCreateRequest(`trip/${tripId}/assign_vendor`, { trip: { vendorId } }, 'trip'),
  modalHide,
};

const mapStateToProps = (state, props) => ({
  memberId: fromApp.trip.actionFormsMemberId(state, props),
  tripId: fromApp.trip.actionFormsTripId(state, props),
});

const TripAssignVendorModalContainer = props => <TripAssignVendorModal {...props} />;

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withProps(props => ({
    onSubmit: (vendorId, formikBag, resetFormAndCloseModal) => {
      props.assignVendor(props.tripId, vendorId).then(
        // eslint-disable-next-line no-unused-vars
        resp => {
          resetFormAndCloseModal();
        },
        error => {
          formikBag.setStatus({ error: error.message });
          formikBag.setSubmitting(false);
        },
      );
    },
  })),
)(TripAssignVendorModalContainer);
