import React from 'react';
import PropTypes from 'prop-types';

import { isArrayAndNotEmpty } from 'rides/utils/data';
import { activityLogType } from 'rides/types';
import {
  ActivityLogItemText,
  Block,
  DateTime,
  Table,
  TableCell,
  TableRow,
} from 'rides/components';

// // TODO remove after dev and testing appt reminder logs
// import { getApptReminderMockLogs } from './temp-appt-logs';

const ActivityLog = ({ logList, ...props }) =>
  !isArrayAndNotEmpty(logList) ? null : (
    <Block {...props}>
      <Table
        bordered
        head={
          <TableRow>
            <TableCell heading md={2}>
              Timestamp
            </TableCell>
            <TableCell heading md={10}>
              Event
            </TableCell>
          </TableRow>
        }
      >
        {logList.map(log => (
          <TableRow key={log.id}>
            <TableCell>
              <DateTime datetime={log.insertedAt} />
            </TableCell>
            <TableCell>
              <ActivityLogItemText log={log} />
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </Block>
  );

ActivityLog.propTypes = {
  logList: PropTypes.arrayOf(activityLogType).isRequired,
};

export default ActivityLog;

// --
// -- Use this to develop for Reminder Appointments
// --
// export default ActivityLogTemp;

// function ActivityLogTemp({ logList, ...props }) {
//   const baseLog = logList[0];

//   let mockedLogList = [...logList];
//   if (baseLog) {
//     const apptReminderMocks = getApptReminderMockLogs(baseLog);
//     mockedLogList = [
//       //
//       ...apptReminderMocks,
//       ...logList,
//     ];
//   }

//   return <ActivityLog {...props} logList={mockedLogList} />;
// }
