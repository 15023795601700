import React from 'react';

import { Block, Paragraph, Spinner } from 'rides/components';

const AuthLoading = () => (
  <Block>
    <Paragraph textCenter>
      <Spinner inline />
    </Paragraph>

    <Paragraph textCenter textMuted style={{ marginBottom: 0, marginTop: 40 }}>
      Please wait while you are logged in...
    </Paragraph>
  </Block>
);

export default AuthLoading;
