import * as R from 'ramda';
import { createSelector } from 'reselect';
import * as reduxSagaThunk from 'redux-saga-thunk';

import { coerceToIntOr } from 'rides/utils/data';
import { fromEntities, fromResource } from 'rides/store/selectors';

import { currentMemberId } from '../member/selectors';

const coerceToIntOrNull = coerceToIntOr(null);

/**
 * TRIP ID
 */
export const tripIdProp = (state, props) => R.path(['tripId'], props);

export const tripIdRouteParam = (state, props) =>
  R.path(['match', 'params', 'tripId'], props);

export const currentTripIdRaw = createSelector(
  [tripIdProp, tripIdRouteParam],
  R.defaultTo,
);

export const currentTripId = createSelector(
  [currentTripIdRaw],
  // NOTE tripId alwayys coerced to int
  tripId => (tripId ? ~~tripId : null),
);

/**
 * ROOT TRIP ID
 */
export const rootTripIdProp = (state, props) => R.path(['rootTripId'], props);

export const rootTripIdRouteParam = (state, props) =>
  R.path(['match', 'params', 'rootTripId'], props);

export const currentRootTripIdRaw = createSelector(
  [rootTripIdProp, rootTripIdRouteParam],
  R.defaultTo,
);

export const currentRootTripId = createSelector(
  [currentRootTripIdRaw],
  coerceToIntOrNull,
);

/**
 * TRIP GROUP ID
 */
export const tripGroupIdProp = (state, props) => R.path(['tripGroupId'], props);

export const tripGroupIdRouteParam = (state, props) =>
  R.path(['match', 'params', 'tripGroupId'], props);

export const currentTripGroupIdRaw = createSelector(
  [tripGroupIdProp, tripGroupIdRouteParam],
  R.defaultTo,
);

export const currentTripGroupId = createSelector(
  [currentTripGroupIdRaw],
  coerceToIntOrNull,
);

/**
 * RESOURCES
 */

export const globalTripGroupResource = () => 'trip_group_v2';

export const globalTripResource = () => 'trip';

export const memberTripGroupResource = createSelector(
  [currentMemberId],
  memberId => {
    return `member/${memberId}/trip_group_v2`;
  },
);

export const memberTripResource = createSelector(
  [currentMemberId],
  memberId => `member/${memberId}/trip`,
);

export const memberTripCancelResource = createSelector(
  [currentMemberId, currentTripId],
  (memberId, tripId) => `member/${memberId}/trip/${tripId}/cancel`,
);

/**
 * TRIP
 */
export const trip = (state, props) => {
  const tripId = currentTripId(state, props);
  return fromEntities.getDetail(state, 'trip', tripId);
};

/**
 * TRIP GROUP
 */

export const tripGroup = (state, props) => {
  const tripGroupId = currentTripGroupId(state, props);
  return fromEntities.getDetail(state, 'tripGroup', tripGroupId);
  // const rootTripId = currentRootTripId(state, props);
  // return fromEntities.getDetail(state, 'tripGroup', rootTripId);
};

export const tripGroupRootTripId = createSelector(
  [tripGroup],
  R.path(['rootTripId']),

  // (memberId, tripId) => `member/${memberId}/trip/${tripId}/cancel`,
);

export const tripGroupListForMember = (state, props) => {
  const resource = memberTripGroupResource(state, props);
  const tripGroupIds = fromResource.getList(state, resource);
  return fromEntities.getList(state, 'tripGroup', tripGroupIds);
};

export const tripGroupRootTrip = (state, props) => {
  // const rootTripId = currentRootTripId(state, props);
  const rootTripId = tripGroupRootTripId(state, props);
  return fromEntities.getDetail(state, 'trip', rootTripId);
};

export const tripGroupTripList = (state, props) => {
  const group = tripGroup(state, props);
  return group && fromEntities.getList(state, 'trip', group.trips);
};

/**
 * STATUS
 */

const resourceDetailReadThunkId = resource => `${resource}DetailRead`;
const resourceListReadThunkId = resource => `${resource}ListRead`;

export const isLoadingGlobalTripGroupDetail = (state, props) => {
  const resource = globalTripGroupResource(state, props);
  const thunkId = resourceDetailReadThunkId(resource);

  return reduxSagaThunk.pending(state, thunkId);
};

export const isLoadingGlobalTripGroupList = (state, props) => {
  const resource = globalTripGroupResource(state, props);
  const thunkId = resourceListReadThunkId(resource);

  return reduxSagaThunk.pending(state, thunkId);
};

export const isLoadingMemberTripGroupDetail = (state, props) => {
  const resource = memberTripGroupResource(state, props);
  const thunkId = resourceDetailReadThunkId(resource);

  return reduxSagaThunk.pending(state, thunkId);
};

export const isLoadingMemberTripGroupList = (state, props) => {
  const resource = memberTripGroupResource(state, props);
  const thunkId = resourceListReadThunkId(resource);

  return reduxSagaThunk.pending(state, thunkId);
};

/**
 * LOCAL STATE
 */

export const initialState = {
  actionForms: {
    tripAction: null,
    memberId: null,
    tripId: null,
  },
  cancelForm: {
    memberId: null,
    tripId: null,
    disabled: false,
    error: null,
    values: {
      cancelReason: '',
    },
  },
};

export const localState = R.pathOr(initialState, ['app', 'trip']);

export const actionForms = createSelector(
  [localState],
  R.prop('actionForms'),
);
export const actionFormsTripAction = createSelector(
  [actionForms],
  R.prop('tripAction'),
);
export const actionFormsMemberId = createSelector(
  [actionForms],
  R.prop('memberId'),
);
export const actionFormsTripId = createSelector(
  [actionForms],
  R.prop('tripId'),
);

export const cancelForm = createSelector(
  [localState],
  R.prop('cancelForm'),
);
export const cancelFormDisabled = createSelector(
  [cancelForm],
  R.prop('disabled'),
);
export const cancelFormMemberId = createSelector(
  [cancelForm],
  R.prop('memberId'),
);
export const cancelFormTripId = createSelector(
  [cancelForm],
  R.prop('tripId'),
);
export const cancelFormValues = createSelector(
  [cancelForm],
  R.prop('values'),
);
export const cancelFormError = createSelector(
  [cancelForm],
  R.prop('error'),
);

// Trip status transitions
const tripStatusTransitionListEntity = state => {
  return fromEntities.getList(state, 'tripStatusTransition');
};

export const tripStatusTransitionList = createSelector(
  [currentTripId, tripStatusTransitionListEntity],
  (tripId, allTransitions) => R.filter(R.whereEq({ tripId }), allTransitions),
);
