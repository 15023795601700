import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Dialog, Pane } from 'evergreen-ui';

import * as ridesTypes from 'rides/types';
import { tripStatus } from 'rides/utils/trip';
import { fromApp } from 'rides/store/selectors';
import { resourceCreateRequest } from 'rides/store/actions';

const mapDispatchToProps = {
  requestRequestPickup: tripId =>
    resourceCreateRequest(`trip/${tripId}/request_pickup `, {}, 'trip'),
};

const mapStateToProps = (state, props) => ({
  showStaffUI: fromApp.ui.showStaffUI(state, props),
  trip: fromApp.trip.trip(state, props),
});

const PickupRequestedLabel = () => (
  <Pane paddingY="0.5em">
    <Button height={24} disabled>
      Pick-Up Requested
    </Button>
  </Pane>
);

const NuiTripRequestPickupButton = ({ trip, requestRequestPickup, disabled }) => {
  const [isDialogShown, setIsDialogShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const requestPickupHandlerFn = useCallback(async () => {
    setIsLoading(true);
    await requestRequestPickup(trip.id);
    setIsLoading(false);
    setIsDialogShown(false);
  }, [trip]);

  if (!trip) return null;
  const isPickupRequested = !!trip.pickupRequested;
  const hideRequestPickupButton = trip.status === tripStatus.COMPLETED;

  return (
    <Pane paddingY="0.5em">
      <Dialog
        isShown={isDialogShown}
        title="Request Pick-Up"
        onCloseComplete={() => setIsDialogShown(false)}
        isConfirmLoading={isLoading}
        onCancel={close => {
          if (isLoading) return; // don't close modal if request loading
          close();
        }}
        onConfirm={requestPickupHandlerFn}
        confirmLabel="Confirm"
      >
        This trip will be marked as ready for pick-up and a driver will be dispatched to
        the pick-up address.
      </Dialog>

      {isPickupRequested ? (
        <PickupRequestedLabel />
      ) : (
        !hideRequestPickupButton && (
          <Button
            height={24}
            onClick={() => {
              if (!disabled) {
                setIsDialogShown(true);
              }
            }}
            disabled={disabled}
          >
            Request Pick-Up
          </Button>
        )
      )}
    </Pane>
  );
};

NuiTripRequestPickupButton.propTypes = {
  trip: ridesTypes.tripType.isRequired,
  requestRequestPickup: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(NuiTripRequestPickupButton);
