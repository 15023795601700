import React from 'react';
import * as R from 'ramda';

import { isArrayAndNotEmpty } from 'rides/utils/data';
import { activityLogType } from 'rides/types';
import { Block, PreformattedText } from 'rides/components';

const DeltaChanges = ({ log }) => {
  if (!log.action === 'delta') return null;

  const changes = R.path(['data', 'changes'], log);

  if (isArrayAndNotEmpty(changes)) {
    return (
      <Block>
        {changes.map((change, i) => (
          <Block key={i}>
            <PreformattedText text={JSON.stringify(change)} />
          </Block>
        ))}
      </Block>
    );
  }

  return null;
};

DeltaChanges.propTypes = {
  log: activityLogType.isRequired,
};

export default DeltaChanges;
