import { branch, renderComponent } from 'recompose';

// import Nothing from 'rides/components/atoms/Nothing';
// import Spinner from 'rides/components/atoms/Spinner';
import { Nothing, Spinner } from 'rides/components';

export const propTruthyPredicate = propName => props => !!props[propName];

export const propFalsyPredicate = propName => props =>
  !propTruthyPredicate(propName)(props);

export const showWhile = (component, showPredicate) =>
  branch(showPredicate, renderComponent(component));

export const showWhilePropFalsy = (component, propName) =>
  showWhile(component, propFalsyPredicate(propName));

export const showWhilePropTruthy = (component, propName) =>
  showWhile(component, propTruthyPredicate(propName));

export const nothingWhilePropFasly = propName =>
  showWhilePropFalsy(Nothing, propName);

export const nothingWhilePropTruthy = propName =>
  showWhilePropTruthy(Nothing, propName);

export const spinnerWhilePropFasly = propName =>
  showWhilePropFalsy(Spinner, propName);

export const spinnerWhilePropTruthy = propName =>
  showWhilePropTruthy(Spinner, propName);

export const nothingWhileIsLoading = showWhilePropTruthy(Nothing, 'isLoading');

export const spinnerWhileIsLoading = showWhilePropTruthy(Spinner, 'isLoading');

export const nothingUntilIsReady = showWhilePropFalsy(Nothing, 'isReady');

export const spinnerUntilIsReady = showWhilePropFalsy(Spinner, 'isReady');
