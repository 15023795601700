/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'CloseIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M8 16A8 8 0 108 0a8 8 0 000 16zm2.404-4.747L8 8.849l-2.404 2.404-.849-.849L7.152 8 4.747 5.596l.849-.849L8 7.151l2.404-2.404.849.849L8.849 8l2.404 2.404-.849.849z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
