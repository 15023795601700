/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'MobileIcon',
  path: (
    <g fill="currentColor">
      <path stroke-width=".625" d="M3.313 12.354V3.646h8.708v8.708H3.312z" />
      <path
        fill-rule="evenodd"
        d="M7.667 1a.667.667 0 11-.002 1.335A.667.667 0 017.667 1zm4.666 1.667v-1c0-.92-.748-1.667-1.666-1.667h-6C3.748 0 3 .747 3 1.667v1h9.333zM9.333 14.667H6A.333.333 0 016 14h3.333a.333.333 0 010 .667zM3 13.333v1C3 15.253 3.748 16 4.667 16h6c.918 0 1.666-.747 1.666-1.667v-1H3z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
