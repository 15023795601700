import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Badge, Icon } from 'evergreen-ui';

import { removeEmpty, isFunc } from 'rides/utils/data';
import { Box, Flex, Text as NuiText } from 'rides/nui';

function getRenderTags(tagList, nextTagList) {
  const inTagList = R.contains(R.__, tagList);
  const inNextTagList = R.contains(R.__, nextTagList);

  const currentTags = tagList.map(tagKey => {
    const inNext = inNextTagList(tagKey);
    return { tagKey, isPending: !inNext, isRemoved: !inNext };
  });

  const pendingTags = nextTagList.map(tagKey => {
    const inCurrent = inTagList(tagKey);
    if (inCurrent) return null;
    return { tagKey, isPending: true, isRemoved: false };
  });

  return [...currentTags, ...removeEmpty(pendingTags)];
}

const tagListType = PropTypes.arrayOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
);

export function SearchPanelFilterTagBarTag({
  tagKey,
  label,
  icon,
  color,
  isPending,
  isRemoved,
  removeTag,
  disabled,
  pendingOpacity = 0.3,
  pendingColor,
}) {
  const removeIcon = isPending ? 'dot' : 'cross';

  let stateStyleProps = {};
  if (isPending) {
    stateStyleProps = {
      color: pendingColor || color,
      opacity: pendingOpacity,
    };
  }
  if (isRemoved) {
    stateStyleProps = {
      opacity: 0.5,
      color: 'red',
      textDecoration: 'line-through',
    };
  }

  return (
    <Badge
      display="inline-flex"
      alignItems="center"
      textTransform="none"
      size={400}
      fontWeight={400}
      paddingY={10}
      margin={4}
      color={color}
      onClick={() => removeTag(tagKey)}
      {...stateStyleProps}
      isInteractive
      disabled={disabled}
    >
      {icon && <Icon icon={icon} size={11} marginRight={4} />} {label}{' '}
      <Icon icon={removeIcon} color="muted" size={10} marginLeft={4} />
    </Badge>
  );
}

SearchPanelFilterTagBarTag.propTypes = {
  tagKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.node.isRequired,
  icon: PropTypes.string,
  color: PropTypes.string,
  isPending: PropTypes.bool,
  isRemoved: PropTypes.bool,
  disabled: PropTypes.bool,
  removeTag: PropTypes.func.isRequired,
};

SearchPanelFilterTagBarTag.defaultProps = {
  icon: 'flag',
  color: 'blue',
  isPending: false,
  isRemoved: false,
  disabled: false,
};

function SearchPanelFilterTagBar({
  label,
  tagList,
  nextTagList,
  renderTagLabel,
  removeTag,
  tagProps,
  disabled,
}) {
  const [tags, setTags] = React.useState([]);

  React.useEffect(() => {
    const renderTags = getRenderTags(tagList, nextTagList);
    setTags(renderTags);
  }, [tagList, nextTagList]);

  const hasTags = tags.length > 0;
  if (!hasTags) return null;

  const bg = hasTags ? 'white' : undefined;

  return (
    <>
      <Flex
        bg={bg}
        style={{
          borderTopLeftRadius: 3,
          borderBottomLeftRadius: 3,
        }}
      >
        <Flex
          style={{
            height: 28,
            alignItems: 'center',
          }}
        >
          <NuiText lineHeight="1" fontSize={12} color="gray" pl={2}>
            {label}:
          </NuiText>
        </Flex>
      </Flex>
      <Box bg={bg} style={{ borderTopRightRadius: 3, borderBottomRightRadius: 3 }}>
        {tags.map(localProps => {
          const mergedProps = { ...localProps, ...tagProps };
          const label = renderTagLabel(mergedProps);
          return (
            <SearchPanelFilterTagBarTag
              {...mergedProps}
              label={label}
              removeTag={removeTag}
              key={mergedProps.tagKey}
            />
          );
        })}
      </Box>
    </>
  );
}

SearchPanelFilterTagBar.propTypes = {
  label: PropTypes.node.isRequired,
  tagList: tagListType.isRequired,
  nextTagList: tagListType.isRequired,
  renderTagLabel: PropTypes.func.isRequired,
  removeTag: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  tagProps: PropTypes.shape({
    icon: PropTypes.string,
    color: PropTypes.string,
  }),
};

SearchPanelFilterTagBar.defaultProps = {
  label: '[Filter Tag]',
  tagList: [],
  nextTagList: [],
  disabled: false,
};

export default SearchPanelFilterTagBar;
