import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useRouter } from 'rides/hooks/use-router';
import { fromApp, fromAuth } from 'rides/store/selectors';
// import { tenantType } from 'rides/types';

// --
// -- Functions
// --

export function getTenantUrl(tenantId, path = '') {
  return `/t/${tenantId}${path}`;
}

// --

const tenantUrlPrefixPattern = /^\/t\/\d+/;

export function stripTenantUrlPrefix(url) {
  return url.replace(tenantUrlPrefixPattern, '');
}

// --
// -- Hooks
// --

export function useGetCurrentUrl() {
  const router = useRouter();
  return () => {
    const { pathname, search } = router.location;
    return `${pathname}${search}`;
  };
}

/**
 * Change Tenant by setting tenant ID in current URL
 */
export function useChangeTenantFn() {
  const { push } = useRouter();
  return useCallback(tenantId => {
    const newTenantUrl = getTenantUrl(tenantId)
    console.log('newTenantUrl', { newTenantUrl })
    push(newTenantUrl);
    ////  // TODO: replace this crude, slow, and clunky forced browser reload
    ////  //       with router.push/replace and trigger form resets
    ////  window.location.href = newTenantUrl;
  }, []);
}

// --

/**
 * Current Tenant ID from redux auth store
 */
export function useCurrentTenantId() {
  const currentTenantId = useSelector(fromAuth.currentTenantId);
  return currentTenantId;
}

/**
 * Current Tenant ID from react-router path
 */
export function useTenantId() {
  const { params } = useRouter();
  return params.tenantId;
}

/**
 * Current URL w/ Tenant ID prefix url
 */
export function useTenantUrl(path) {
  let tenantId = useTenantId();
  return getTenantUrl(tenantId, path);
}

/**
 * Function to get URL w/ Tenant ID prefix url
 */
export function useGetTenantUrl() {
  let tenantId = useTenantId();
  return path => getTenantUrl(tenantId, path);
}

/**
 * List of tenant objects for all tenants
 */
export function useTenantList() {
  // TODO: replace this with Xstate synced routing
  const tenantList = useSelector(fromApp.tenant.allTenants);
  return tenantList;
}
