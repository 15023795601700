/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'AnalyticsLgIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M19.667 18H2V.333C2 .153 1.85 0 1.667 0a.336.336 0 00-.334.333V18h-1a.336.336 0 00-.333.333c0 .187.15.334.333.334h1v1c0 .186.15.333.334.333A.332.332 0 002 19.667v-1h17.667a.332.332 0 00.333-.334c0-.18-.15-.333-.333-.333z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M4.333 16H7a.332.332 0 00.333-.333v-8c0-.18-.149-.334-.333-.334H4.333A.336.336 0 004 7.667v8c0 .186.15.333.333.333zM15 16h2.666a.332.332 0 00.333-.333V5c0-.18-.149-.333-.333-.333h-2.667a.336.336 0 00-.333.333v10.667c0 .186.15.333.333.333zM12.334 16a.332.332 0 00.333-.333V1c0-.18-.149-.333-.333-.333H9.667A.336.336 0 009.334 1v14.667c0 .186.15.333.333.333h2.667z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
});
