import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { memberIdType, tripIdType } from 'rides/types';
import { fromEntities } from 'rides/store/selectors';
import { tripWizardInitEditTrip } from 'rides/store/actions';
import { Button, Glyphicon } from 'rides/components';

const mapStateToProps = (state, props) => {
  const trip = fromEntities.getDetail(state, 'trip', props.tripId);

  return {
    disableEdit: R.and(
      !R.path(['permissions', 'canEditLocation'], trip),
      !R.path(['permissions', 'canEditTime'], trip),
    ),
  };
};

const mapDispatchToProps = {
  tripWizardInitEditTrip,
};

function TripLegActionButtonsContainer({
  children,
  memberId,
  tripId,
  tripWizardInitEditTrip,
  disableEdit,
}) {
  function onEditTrip(event) {
    if (event) event.preventDefault();
    tripWizardInitEditTrip(memberId, tripId);
  }

  return children({
    editButton: !disableEdit && (
      <Button onClick={onEditTrip} primary xs link>
        <Glyphicon icon="pencil" /> Edit
      </Button>
    ),
  });
}

TripLegActionButtonsContainer.propTypes = {
  // passed props
  children: PropTypes.func.isRequired,

  // connected props
  memberId: memberIdType.isRequired,
  tripId: tripIdType.isRequired,
  disableEdit: PropTypes.bool,
  tripWizardInitEditTrip: PropTypes.func.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(TripLegActionButtonsContainer);
