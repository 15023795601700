import React from 'react';
import PropTypes from 'prop-types';

import { linkToType } from 'rides/types';
import { Atom, Block, Link } from 'rides/components';

const MenubarItemLinkWrapper = ({ children, linkTo }) =>
  linkTo ? <Link to={linkTo}>{children}</Link> : children;

MenubarItemLinkWrapper.propTypes = {
  children: PropTypes.node,
  linkTo: linkToType,
};

const MenubarItemContent = ({ icon, title, ...props }) => (
  <Block {...props}>
    {icon}
    <Atom className="site-menu-title">{title}</Atom>
  </Block>
);

MenubarItemContent.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
};

const MenubarItem = ({ children, icon, linkTo, title, ...props }) => (
  <li {...props} className="site-menu-item">
    <MenubarItemLinkWrapper linkTo={linkTo}>
      {children || <MenubarItemContent icon={icon} title={title} />}
    </MenubarItemLinkWrapper>
  </li>
);

MenubarItem.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  linkTo: linkToType,
  title: PropTypes.string,
};

export default MenubarItem;
