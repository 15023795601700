/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'ErrorIcon',
  path: (
    <g fill="currentColor">
      <g clip-path="url(#clip0)">
        <path
          fill-rule="evenodd"
          d="M8.206 13.741a.672.672 0 11-.002-1.344.672.672 0 01.002 1.344zm-.619-6.725a.616.616 0 111.232 0v4.034a.616.616 0 11-1.232 0V7.016zm8.49 8.332L8.756.689c-.21-.418-.892-.418-1.101 0L.263 15.483a.611.611 0 00.027.6c.112.182.31.293.523.293H15.62a.616.616 0 00.458-1.028z"
          clip-rule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 16 16',
});
