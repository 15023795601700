/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'NotesTabDefaultXlIcon',
  path: (
    <g fill="currentColor">
      <g clip-path="url(#clip0)">
        <path
          fill-rule="evenodd"
          d="M11.79 3H1.684C.758 3 0 3.818 0 4.818v12.728h1.684V4.818H11.79V3zm2.526 3.636H5.053c-.927 0-1.685.819-1.685 1.819v12.727c0 1 .758 1.818 1.685 1.818h9.263c.926 0 1.684-.818 1.684-1.818V8.455c0-1-.758-1.819-1.684-1.819zm0 14.546H5.053V8.455h9.263v12.727z"
          clip-rule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h23v23H0z" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 23 23',
});
