import * as React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push as routerPush } from 'connected-react-router';

import * as queryString from 'rides/utils/queryString';
import { fromApp } from 'rides/store/selectors';
import { resourceListReadRequest } from 'rides/store/actions';
import TripListPage from 'rides/nui/pages/TripListPage';

const mapDispatchToProps = dispatch => ({
  fetchTripList: (options = {}) =>
    dispatch(resourceListReadRequest('trip', options, 'trip')),
  fetchAssigneeList: () => dispatch(resourceListReadRequest(`trip/assignee`, {})),
  fetchTripTypeList: () => dispatch(resourceListReadRequest(`trip/type`, {})),
  routerPush: (...args) => dispatch(routerPush(...args)),
});

const mapStateToProps = (state, props) => ({
  hideForHumana: fromApp.ui.hideForHumana(state, props),
  showAdminUI: fromApp.ui.showAdminUI(state, props),
  showStaffUI: fromApp.ui.showStaffUI(state, props),
});

const tripPickupUrbanicityList = [
  { id: 'urban', name: 'Urban' },
  { id: 'suburban', name: 'Suburban' },
  { id: 'rural', name: 'Rural' },
  { id: 'unknown', name: 'Unknown' },
];

class NuiTripListPageContainer extends React.Component {
  _isMounted = false;

  state = {
    keywords: '',
    pagination: null,
    isLoading: false,
    hasLoadedOnce: false,
    results: {
      searchOptions: {},
      tripIdList: null,
    },
    assigneeList: [],
    tripTypeList: [],
  };

  componentDidMount() {
    this._isMounted = true;

    this.fetchTrips();
    this.fetchAssigneeList();
    this.fetchTripTypeList();
  }

  componentDidUpdate(prevProps) {
    const locationChanged = this.props.location !== prevProps.location;

    if (locationChanged) {
      this.fetchTrips();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  assignFilterOptions(search) {
    const assignedTo = R.propOr([], 'assigned_to', search);
    return {
      assigned_to: R.without(['include_unassigned'], assignedTo),
      include_unassigned: R.contains('include_unassigned', assignedTo),
    };
  }

  dateFilterOptions(search) {
    const from_date = R.path(['from_date'], search);
    const to_date = R.path(['to_date'], search);

    return {
      from_date: from_date,
      to_date: to_date,
    };
  }

  fetchOptions() {
    const search = queryString.parse(this.props.location.search);

    return R.pickAll(
      [
        'keywords',
        'page',
        'page_size',
        'status',
        'status_group',
        'include_unassigned',
        'assigned_to',
        'type',
        'pickup_urbanicity',
        'from_date',
        'to_date',
      ],
      {
        page_size: 20,
        status_group: 'completed',
        ...search,
        ...this.assignFilterOptions(search),
        ...this.dateFilterOptions(search),
      },
    );
  }

  safeSetState = (...args) => {
    if (this._isMounted) {
      this.setState(...args);
    }
  };

  fetchAssigneeList = async () => {
    if (this.props.showStaffUI) {
      const resp = await this.props.fetchAssigneeList();
      this.safeSetState({ assigneeList: resp.data });
    }
  };

  fetchTripTypeList = async () => {
    if (this.props.showStaffUI) {
      const resp = await this.props.fetchTripTypeList();
      this.safeSetState({ tripTypeList: resp.data });
    }
  };

  fetchTrips = () => {
    if (this.props.skipTripSearchRequest) {
      // NOTE: This is added to support the Evo UI version of trip list page
      return;
    }

    this.safeSetState({ isLoading: true });

    const options = this.fetchOptions();
    this.props
      .fetchTripList(options)
      .then(this.handleFetchSuccess(options), this.handleFetchFailure(options));
  };

  handleFetchFailure = options => error => {
    this.safeSetState({ isLoading: false, error, hasLoadedOnce: true });
  };

  handleFetchSuccess = options => resp => {
    const tripIdList = resp.data;
    const currentPage = resp.pageNumber;
    const { totalPages, totalEntries, pageSize } = resp;

    this.safeSetState({
      hasLoadedOnce: true,
      isLoading: false,
      results: {
        searchOptions: options,
        tripIdList,
      },
      pagination: {
        currentPage,
        totalPages,
        totalEntries,
        pageSize,
      },
    });
  };

  changeSearchParams = (searchParams, mergeWithCurrentSearchParams = true) => {
    const { location, routerPush } = this.props;
    const mergeParams = mergeWithCurrentSearchParams
      ? queryString.parse(location.search)
      : {};

    const search = queryString.stringify({
      ...mergeParams,
      ...searchParams,
    });

    routerPush({ ...location, search });
  };

  handlePageChange = page => {
    this.changeSearchParams({ page });
  };

  render() {
    const { hideForHumana, showAdminUI, showStaffUI } = this.props;
    const {
      isLoading,
      hasLoadedOnce,
      results,
      pagination,
      assigneeList,
      tripTypeList,
    } = this.state;
    const { searchOptions, tripIdList } = results;
    const { keywords } = searchOptions;

    return (
      <TripListPage
        tripIdList={tripIdList}
        isLoading={isLoading}
        hasLoadedOnce={hasLoadedOnce}
        keywords={keywords}
        pagination={{
          currentPage: 0,
          totalPages: 0,
          onChange: this.handlePageChange,
          ...pagination,
        }}
        searchOptions={searchOptions}
        changeSearchParams={this.changeSearchParams}
        hideForHumana={hideForHumana}
        showAdminUI={showAdminUI}
        showStaffUI={showStaffUI}
        assigneeList={assigneeList}
        tripTypeList={tripTypeList}
        tripPickupUrbanicityList={tripPickupUrbanicityList}
      />
    );
  }
}

NuiTripListPageContainer.propTypes = {
  fetchTripList: PropTypes.func.isRequired,
  fetchAssigneeList: PropTypes.func.isRequired,
  fetchTripTypeList: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  routerPush: PropTypes.func.isRequired,
  hideForHumana: PropTypes.bool.isRequired,
  showStaffUI: PropTypes.bool.isRequired,
  showAdminUI: PropTypes.bool.isRequired,
  skipTripSearchRequest: PropTypes.bool,
};

const NuiTripListPageContainerOrig = R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(NuiTripListPageContainer);

export default NuiTripListPageContainerOrig
