import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import daggy from 'daggy';
import { connect } from 'react-redux';
import { rejected, done, fulfilled, pending } from 'redux-saga-thunk';
import { withRouter } from 'react-router-dom';

import { fromEntities, fromResource } from 'rides/store/selectors';
import { resourceListCreateRequest, resourceListReadRequest } from 'rides/store/actions';

const RemoteData = daggy.taggedSum('EntityListLoader::RemoteData', {
  NotAsked: [],
  Loading: [],
  Failure: ['error'],
  Success: ['data'],
});

class EntityListLoader extends React.Component {
  state = {
    pagination: null,
  };

  componentWillMount() {
    if (this.props.loadOnMount) {
      this.loadData();
    }
  }

  componentDidUpdate(prevProps) {
    const prevPage = R.path(['requestParams', 'page'], prevProps);
    const page = R.path(['requestParams', 'page'], this.props);
    if (prevPage !== page) {
      this.loadData();
    }
  }

  loadData = async () => {
    const {
      entityType,
      postRequest,
      requestParams,
      resource,
      resourceListCreateRequest,
      resourceListReadRequest,
    } = this.props;

    const queryParams = R.compose(
      R.pick(['page', 'page_size']),
      R.pathOr({}, ['location', 'query']),
    )(this.props);

    const params = R.merge(queryParams, requestParams);

    if (postRequest) {
      resourceListCreateRequest(resource, params, entityType).then(
        this.handleListReadSuccess,
      );
    } else {
      resourceListReadRequest(resource, params, entityType).then(
        this.handleListReadSuccess,
      );
    }
  };

  handleListReadSuccess = resp => {
    const currentPage = resp.pageNumber;
    const { totalPages, totalEntries, pageSize } = resp;

    this.setState({
      pagination: {
        currentPage,
        totalPages,
        totalEntries,
        pageSize,
      },
    });
  };

  getView = items => {
    return items.cata({
      NotAsked: () => this.props.renderNotAsked(),
      Loading: () => this.props.renderLoading(),
      Failure: error => this.props.renderError(error),
      Success: data => this.props.renderSuccess(data),
    });
  };

  render() {
    const { children, items, isLoading } = this.props;
    const { pagination } = this.state;
    const View = this.getView(items);
    return children(View, this.loadData, { pagination, isLoading });
  }
}

const mapDispatchToProps = {
  resourceListCreateRequest,
  resourceListReadRequest,
};

const getResourceThunkId = (resource, postRequest) =>
  postRequest ? `${resource}ListCreate` : `${resource}ListRead`;

const hasObjectItems = R.any(R.is(Object));

const mapStateToProps = (state, { entityType, postRequest, resource }) => {
  const requestThunkId = getResourceThunkId(resource, postRequest);
  const resourceLoading = pending(state, requestThunkId);
  const resourceFailed = rejected(state, requestThunkId);
  const resourceDone = fulfilled(state, requestThunkId);
  const resourceComplete = done(state, requestThunkId);

  const resourceItems = fromResource.getList(state, resource);
  const entityItems = fromEntities.getList(state, entityType, resourceItems);

  // A seeming race condition is causing this component to re-render between
  // resource normalization and the entity store updating with items. This
  // checks if any of the resource list items are objects because they should
  // be id's (Number|String) if the entity store has been populated.
  const hasEntityIds = resourceDone && !hasObjectItems(resourceItems);

  return {
    isLoading: resourceLoading,
    items: resourceFailed
      ? RemoteData.Failure('ERROR: Not Implemented')
      : resourceLoading
      ? RemoteData.Loading
      : resourceDone && hasEntityIds
      ? RemoteData.Success(entityItems)
      : RemoteData.NotAsked,
  };
};

const EntityListLoaderContainer = R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(EntityListLoader);

EntityListLoaderContainer.propTypes = {
  entityType: PropTypes.string.isRequired,
  loadOnMount: PropTypes.bool,
  postRequest: PropTypes.bool,
  requestParams: PropTypes.object,
  resource: PropTypes.string.isRequired,
};

export default EntityListLoaderContainer;
