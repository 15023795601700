import { camelizeKeys } from "humps";

const PROVIDER_PREFIX_IN = 'IN';
const PROVIDER_PREFIX_OON = 'OON';
const PROVIDER_PREFIX_NOT_APPROVED = 'NOT APPROVED';
const PROVIDER_PREFIX_DISABLED = 'DISABLED';

// ---------------------------------------------
// Provider Data Extract & Format Functions
// ---------------------------------------------

export function getProviderDetailPrefix(provider) {
  if (provider?.isDisabled) {
    // If provider.isDisabled is Yes, use DISABLED.
    return PROVIDER_PREFIX_DISABLED;
  } else {
    if (!provider?.isApproved) {
      // If provider.isDisabled is No, and provider.isApproved is No, use NOT APPROVED.
      return PROVIDER_PREFIX_NOT_APPROVED;
    } else {
      if (provider?.networkClassification === 'out_of_network') {
        // If provider.isDisabled is No, and provider.isApproved is Yes, and provider.networkClassification is Out of Network, use OON.
        return PROVIDER_PREFIX_OON;
      } else if (provider?.networkClassification === 'in_network') {
        // If provider.isDisabled is No, and provider.isApproved is Yes, and provider.networkClassification is In Network, use IN.
        return PROVIDER_PREFIX_IN;
      }
    }
  }
}

export function getProviderDetailText(providerRaw) {
  const outputItems = [];

  if (!providerRaw) {
    // provider object not passed in :(
    return '--';
  }

  // NOTE: Old trip page provider has camelized keys but the evo pages don't
  //       so we camelize the keys here to make sure this helper function
  //       works for both versions of the data
  const provider = camelizeKeys(providerRaw);

  const prefix = getProviderDetailPrefix(provider);
  if (prefix) {
    outputItems.push(prefix);
  }

  if (provider?.name) {
    outputItems.push(provider.name);
  }

  if (provider?.phone) {
    outputItems.push(provider.phone);
  }

  return outputItems.join(' - ');
}


/**
 * Sort value for sort functions
 **/
export function providerPrefixSortValue(provider) {
  const prefix = getProviderDetailPrefix(provider);

  if (prefix === PROVIDER_PREFIX_IN) return 0;
  if (prefix === PROVIDER_PREFIX_OON) return 1;
  if (prefix === PROVIDER_PREFIX_NOT_APPROVED) return 2;
  if (prefix === PROVIDER_PREFIX_DISABLED) return 3;

  // IF PREFIX NOT FOUND MAKE IT LAST
  return 100;
}
