import * as R from 'ramda';
import * as reduxSagaThunk from 'redux-saga-thunk';
import { createSelector } from 'reselect';

import { fromAuth, fromEntities, fromResource } from 'rides/store/selectors';

// export const tenantIdProp = (state, props) => R.path(['tenantId'], props);

// export const tenantIdRouteParam = (state, props) => R.path(['match', params', 'tenantId'], props);

// export const currentTenantIdRaw = createSelector(
//   [tenantIdProp, tenantIdRouteParam],
//   R.defaultTo,
// );

// export const currentTenantId = createSelector(
//   [currentTenantIdRaw],
//   // NOTE tenantId alwayys coerced to int
//   tenantId => (tenantId ? ~~tenantId : null),
// );
export const currentTenantId = fromAuth.currentTenantId;

/**
 * RESOURCES
 */
export const tenantResource = () => 'tenant';

/**
 * TENANT
 */

export const allTenantIds = (state, props) => fromResource.getList(state, 'tenant');

export const allTenants = (state, props) => {
  const tenantIds = allTenantIds(state, props);
  return fromEntities.getList(state, 'tenant', tenantIds);
};

export const currentTenant = (state, props) => {
  const tenantId = currentTenantId(state, props);
  return fromEntities.getDetail(state, 'tenant', tenantId);
};

export const currentTenantName = createSelector(
  [currentTenant],
  R.prop('name'),
);

export const currentTenantUsers = createSelector(
  [currentTenant],
  R.prop('users'),
);

export const currentTenantTimezone = createSelector(
  [currentTenant],
  R.prop('timezone'),
);

export const tenant = (state, props) => {
  const tenantId = R.path(['tenantId'], props);
  return fromEntities.getDetail(state, 'tenant', tenantId);
};

export const tenantTimezone = createSelector(
  [tenant],
  R.prop('timezone'),
);

/**
 * STATUS
 */

// TODO move this to a shared central place
const resourceListReadThunkId = resource => `${resource}ListRead`;

export const isLoadingTenantList = (state, props) => {
  const resource = tenantResource(state, props);
  const thunkId = resourceListReadThunkId(resource);

  return reduxSagaThunk.pending(state, thunkId);
};
