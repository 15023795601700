import * as R from 'ramda';
import * as React from 'react';
import { camelizeKeys } from 'humps';
import { useDispatch } from 'react-redux';
import { Button, PhoneNumber, Box, Text } from '@carecar-evo/core-ui';
import { useDisclosure, VStack, Text as ChakraText } from '@chakra-ui/react';
import EditMemberModalEvo from 'rides/containers/EditMemberModalEvo';
import { useShowStaffUI } from 'rides/hooks/use-auth';
import { modalHide, modalShow, resourceUpdateRequest } from 'rides/store/actions';
import {
  PaneSectionHeader,
  PaneSectionStack,
  PaneSectionTable,
} from './PaneSection';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NuiTripQuickEditModal from 'rides/nui/TripQuickEditModal';
// import { useEditTripWizard } from 'rides/evo/utils/trips';


// -- vv ------------------------------------
// TODO Replace this with React Query & Hooks
// -- vv ------------------------------------
//
// DETAILS:
// We are using legacy modals for trip quick edit functionality and that relies
// on the updateTrip function being "await'able" on the request being made. The
// redux-saga based request pattern we used in the past provided this but our new
// React Query pattern doesn't. For now we just use redux's connect(...) to get this
// behavior from dispatch().
//
// vv ------------------------------------

const mapDispatchToProps = {
  requestUpdateTrip: (memberId, tripId, trip) =>
    resourceUpdateRequest(`member/${memberId}/trip`, tripId, { trip }, 'trip'),
};

const TripQuickEditModalBase = ({
  trip,
  requestUpdateTrip,
  ...props
}) => {
  if (!trip) {
    return null;
  }

  const tripId = trip?.id;
  const memberId = trip?.member?.id;
  const updateTrip = trip => requestUpdateTrip(memberId, tripId, trip);

  return (
    <NuiTripQuickEditModal
      {...props}
      trip={trip}
      updateTrip={updateTrip}
    />
  );
};

const TripQuickEditModal = R.compose(
  withRouter,
  connect(
    // mapStateToProps,
    null,
    mapDispatchToProps,
  ),
)(TripQuickEditModalBase);

// -- ^^ ------------------------------------
// TODO replace this with React Query & Hooks
// -- ^^ ------------------------------------

// ---
// --- Member Health Attributes
// ---


function useShowEditMemberModal(memberId) {
  const dispatch = useDispatch();
  const showEditMemberFn = React.useCallback(() => dispatch(modalShow('edit-member')), [dispatch]);
  const hideEditMemberFn = React.useCallback(() => dispatch(modalHide('edit-member')), [dispatch]);

  const updateMemberFn = React.useCallback(
    (member) => dispatch(resourceUpdateRequest('member', memberId, { member }, 'member')),
    [dispatch]
  );

  const onSubmitCallback = React.useCallback(
    (member, formikBag) => {
      dispatch(resourceUpdateRequest('member', memberId, { member }, 'member')).then(
        // updateMemberFn(member).then(
        // eslint-disable-next-line no-unused-vars
        resp => {
          // member is updated automatically in the store
          hideEditMemberFn();
        },
        error => {
          formikBag.setStatus({ error: error.message });
          formikBag.setSubmitting(false);
        },
      );
    },
    [dispatch]
  );

  return {
    hideEditMemberFn,
    showEditMemberFn,
    updateMemberFn,
    onSubmitCallback,
  }
}

const noteLabel = <Text size="mini" weight="bold" uppercase>Note:</Text>;

const NoteText = ({ note = '', ...props }) => (
  <Text css={{ fontSize: '12px', whiteSpace: 'pre-line' }} {...props}>{note}</Text>
);

function NotesPane({ trip, member, isLoaded, ...props }) {
  const tripSection = useDisclosure({ defaultIsOpen: true });
  const memberSection = useDisclosure({ defaultIsOpen: true });

  const memberId = member?.id
  const memberNotes = member?.notes ?? '';

  const tripId = trip?.id
  const tripNotes = trip?.notes ?? '';

  const { showEditMemberFn } = useShowEditMemberModal(memberId)

  const camelizedTrip = camelizeKeys(trip);
  const camelizedMember = camelizeKeys(member);

  const showStaffUI = useShowStaffUI();

  const editMemberLink = showStaffUI ? (
    <Button
      css={{ textTransform: 'uppercase', paddingTop: '0.5rem' }}
      key={`${memberId}-vitals_edit_button`}
      kind="link"
      onClick={showEditMemberFn}
    >
      Edit Member
    </Button>
  ) : null;


  return (
    <>
      <VStack
        px="24px"
        py="16px"
        borderTopWidth="1px"
        borderTopColor="lightGrey.100"
        borderBottomWidth="1px"
        borderBottomColor="lightGrey.100"
        spacing="5px"
        alignItems="stretch"
      >
        <PaneSectionHeader
          title="Member"
          isOpen={memberSection.isOpen}
          onOpen={memberSection.onOpen}
          onClose={memberSection.onClose}
        />
        <PaneSectionTable
          isOpen={memberSection.isOpen}
          rowData={[
            [noteLabel, null],
            [<NoteText note={memberNotes} />, null],
            [editMemberLink, null],
          ]}
          uppercaseField={false}
        />
      </VStack>

      <VStack
        px="24px"
        py="16px"
        borderBottomWidth="1px"
        borderBottomColor="lightGrey.100"
        spacing="5px"
        alignItems="stretch"
      >
        <PaneSectionHeader
          title="Trip"
          isOpen={tripSection.isOpen}
          onOpen={tripSection.onOpen}
          onClose={tripSection.onClose}
        />
        <PaneSectionStack isOpen={tripSection.isOpen}>
          {noteLabel}
          <NoteText note={tripNotes} />
          {showStaffUI && (
            <TripQuickEditModal
              // trip={trip}
              trip={camelizedTrip}
              render={openQuickEditTopic => (
                <ChakraText {...props} textStyle="detailPanel.paneSectionTableField">
                  <Button
                    css={{ textTransform: 'uppercase', paddingTop: '0.5rem' }}
                    kind="link"
                    onClick={() => openQuickEditTopic('notes')}
                    // onClick={showEditMemberFn}
                  >
                    Edit
                  </Button>
                </ChakraText>
              )}
            />
          )}
        </PaneSectionStack >
      </VStack>
      <EditMemberModalEvo
        memberId={memberId}
        member={camelizedMember}
      />
    </>
  );
}

export { NotesPane };
