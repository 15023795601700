/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'CheckIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M1.56 7.82L0 9.167l3.953 4.482c.392.444 1.08.47 1.506.057L16 3.462 14.55 2 4.79 11.483 1.561 7.82z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
