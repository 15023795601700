import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import useLocalStorage from 'rides/hooks/use-localstorage';
import { fromAuth, fromApp } from 'rides/store/selectors';

const mapStateToProps = (state, props) => ({
  roles: fromAuth.roles(state, props),
  currentTenantId: fromAuth.currentTenantId(state, props),
  currentTenantTimezone: fromApp.tenant.currentTenantTimezone(state, props),

  showAdminUI: fromApp.ui.showAdminUI(state, props),
  showStaffUI: fromApp.ui.showStaffUI(state, props),
});

const throwError = () => fnDoesNotExist();

const StackItem = ({ children }) => <div style={{ padding: 10 }}>{children}</div>;

const ErrorDebugger = ({ showStaffUI }) => {
  const [throwBoundary, setThrowBoundary] = useState(false);
  const [showUI, setShowUI] = useLocalStorage('show_error_debugger_ui', false);

  useEffect(() => {
    window.errorDebuggerShow = () => setShowUI(true);
    window.errorDebuggerHide = () => setShowUI(false);

    return () => {
      window.errorDebuggerShow = undefined;
      window.errorDebuggerHide = undefined;
    };
  }, []);

  if (!showUI) return null;

  if (!showStaffUI) return null;

  return (
    <div
      style={{
        zIndex: 10000,
        position: 'fixed',
        bottom: 1,
        background: 'pink',
        float: 'left',
      }}
    >
      <h2 style={{ margin: '0 5px' }}>Error Debugger</h2>
      <StackItem>
        <button onClick={() => throwError()}>Throw Error</button>
      </StackItem>
      <StackItem>
        <button onClick={() => setThrowBoundary(true)}>Trigger ErrorBoundry</button>
        {throwBoundary && throwError()}
      </StackItem>
      <StackItem />
      <StackItem>
        <button onClick={() => setShowUI(false)}>hide</button>
      </StackItem>
    </div>
  );
};

export default R.compose(withRouter, connect(mapStateToProps))(ErrorDebugger);
