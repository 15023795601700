import React from 'react';
import PropTypes from 'prop-types';

import { Block, Column, CopyrightFooter, Heading, Row } from 'rides/components';

const AuthPageHeader = ({ children, ...props }) => (
  <Heading level={3} className="login-title text-center" {...props}>
    {children || 'Health-E Rides'}
  </Heading>
);

AuthPageHeader.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
};

const Wrapper = ({ children, ...props }) => (
  <Block
    className="page-content"
    style={{
      minHeight: '100vh',
      marginTop: '-70px',
      backgroundColor: '#3583ca',
      backgroundImage: 'linear-gradient(to bottom,#62a8ea 0,#3583ca 100%)',
      backgroundRepeat: 'repeat-x',
    }}
    {...props}
  >
    {children}
  </Block>
);

Wrapper.propTypes = {
  children: PropTypes.any.isRequired,
};

const AuthPageTemplate = ({ children, footer, header, ...props }) => {
  return (
    <Wrapper {...props}>
      <Block style={{ marginTop: '20px' }} />
      <Row>
        <Column sm={8} smOffset={2}>
          <Block className="widget widget-shadow">
            <Block className="widget-body" style={{ padding: '50px' }}>
              {header}
              <Block style={{ marginTop: '40px' }} />
              {children}
            </Block>
          </Block>
        </Column>
      </Row>
      <Block style={{ marginTop: '50px' }} />
      {footer}
    </Wrapper>
  );
};

AuthPageTemplate.propTypes = {
  children: PropTypes.any.isRequired,
  footer: PropTypes.node,
  header: PropTypes.node,
};

AuthPageTemplate.defaultProps = {
  footer: <CopyrightFooter />,
  header: <AuthPageHeader />,
};

export default AuthPageTemplate;
