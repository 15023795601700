import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Block, Heading } from 'rides/components';

const Widget = ({ children, className, title, ...props }) => {
  const classes = classNames(className, 'widget', 'widget-shaddow');

  return (
    <Block className={classes} {...props}>
      {title && (
        <Heading level={3} className="widget-title">
          {title}
        </Heading>
      )}

      <Block className="widget-body">{children}</Block>
    </Block>
  );
};

Widget.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.node,
};

export default Widget;
