import * as React from 'react';
import ReactDatePicker from 'react-datepicker';
import { useColorMode, forwardRef, Input } from '@chakra-ui/react';
import add from 'date-fns/add';
import 'react-datepicker/dist/react-datepicker.css';
import './date-picker.css';
import startOfToday from 'date-fns/startOfToday';

// TODO: make standard module with docs that sets max years
export const maxDate = add(startOfToday(), { years: 3 });

const DatePickerInput = forwardRef(({ ...props }, ref) => {
  return <Input size="lg" ref={ref} {...props} placeholder="MM/DD/YYYY" />;
});

const DatePicker = ({
  selectedDate,
  onChange,
  placeholder,
  autoComplete = 'off',
  isClearable = false,
  shouldCloseOnSelect = true,
  showPopperArrow = false,
  inline = false,
  ...props
}) => {
  const isLight = useColorMode().colorMode === 'light'; //you can check what theme you are using right now however you want

  return (
    // if you don't want to use chakra's colors or you just wwant to use the original ones,
    // set className to "light-theme-original" ↓↓↓↓
    <div className={isLight ? 'light-theme' : 'dark-theme'}>
      <ReactDatePicker
        selected={selectedDate}
        onChange={onChange}
        className="react-datapicker__input-text" //input is white by default and there is no already defined class for it so I created a new one
        autoComplete={autoComplete}
        isClearable={isClearable}
        showPopperArrow={showPopperArrow}
        shouldCloseOnSelect={shouldCloseOnSelect}
        inline={inline}
        customInput={<DatePickerInput />}
        minDate={startOfToday()}
        maxDate={maxDate}
        {...props}
      />
    </div>
  );
};

export { DatePicker };
