import React from 'react';
import PropTypes from 'prop-types';
import { memberIdType, tripType } from 'rides/types';

import {
  Block,
  Button,
  CollapsibleMemberInfo,
  Glyphicon,
  Heading,
  TripLegCard,
} from 'rides/components';
import { TripGroupActivityLog, StandardLayout } from 'rides/containers';

const TripGroupDetailPage = ({
  rootTrip,
  tripList,
  onClickCreateTripLeg,
  isLoading,
  showStaffUI,
}) => {
  return (
    <StandardLayout>
      <Block>
        <Heading level={2}>Manage Trip</Heading>
      </Block>

      <CollapsibleMemberInfo member={rootTrip.member} defaultCollapsed={true} />

      <Block pullRight>
        <Button onClick={onClickCreateTripLeg} xs success>
          <Glyphicon icon="plus" small /> Add Trip Leg
        </Button>
      </Block>

      <Heading level={3}>Trip Legs</Heading>

      {tripList &&
        tripList.map(trip => (
          <TripLegCard key={trip.id} trip={trip} showStatusTransitionUI={showStaffUI} />
        ))}

      <TripGroupActivityLog rootTripId={rootTrip.groupId} />
    </StandardLayout>
  );
};

TripGroupDetailPage.propTypes = {
  memberId: memberIdType,
  rootTrip: tripType,
  tripList: PropTypes.arrayOf(tripType),
  isLoading: PropTypes.bool,
  onClickCreateTripLeg: PropTypes.func.isRequired,
  showStaffUI: PropTypes.bool.isRequired,
};

export default TripGroupDetailPage;
