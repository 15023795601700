import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import EntityListLoader from 'rides/components/utils/EntityListLoader';
import { Block, Input, FormFeedbackBox } from 'rides/components';
import { getProviderDetailText, providerPrefixSortValue } from 'rides/evo/utils/providers';

const sortOptions = R.sortWith([
  R.ascend(providerPrefixSortValue),
  R.ascend(R.prop('name'))
]);

const VendorSelectField = props => (
  <EntityListLoader
    resource="vendor"
    entityType="vendor"
    renderNotAsked={() => <Block className="text-muted">Not Loaded</Block>}
    renderLoading={() => (
      <Input {...props} type="select">
        <option value=""> loading... </option>
      </Input>
    )}
    renderError={(/*error*/) => <FormFeedbackBox error="Failed to load Vendors." />}
    renderSuccess={data => (
      <Input {...props} type="select">
        {!props.value && <option value=""> -- Select One -- </option>}
        {sortOptions(data).map(provider => (
          <option key={provider.id} value={provider.id}>
            {getProviderDetailText(provider)}
          </option>
        ))}
      </Input>
    )}
    loadOnMount
  >
    {(View, loadData) => (
      <Block>
        {View}
        {/*
         * NOTE: We can add a reload button like this:
         * <span onClick={loadData}>Load Data</span>
         * */}
      </Block>
    )}
  </EntityListLoader>
);

VendorSelectField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default VendorSelectField;
