/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'DashboardIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M2 1a1 1 0 00-1 1v6a1 1 0 001 1h4a1 1 0 001-1V2a1 1 0 00-1-1H2zm8 0a1 1 0 00-1 1v1a1 1 0 001 1h4a1 1 0 001-1V2a1 1 0 00-1-1h-4zM1 12a1 1 0 011-1h4a1 1 0 011 1v2a1 1 0 01-1 1H2a1 1 0 01-1-1v-2zm9-6a1 1 0 00-1 1v7a1 1 0 001 1h4a1 1 0 001-1V7a1 1 0 00-1-1h-4z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
