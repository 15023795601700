// Converts distance from meters (from the api) to miles rounded to two decimal places

import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import { Atom } from 'rides/components';

const convertToMiles = distanceInMeters => {
  const distanceInMiles = distanceInMeters * 0.000621371;
  return Math.round(distanceInMiles * 100) / 100;
};

const TripDistance = ({ distance, ...props }) => {
  const isDistanceBlank = R.either(R.isEmpty, R.isNil)(distance);
  if (isDistanceBlank) return null;

  return <Atom {...props}>{`${convertToMiles(distance)} miles`}</Atom>;
};

TripDistance.propTypes = {
  distance: PropTypes.number, // in meters
};

export default TripDistance;
