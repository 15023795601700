import React from 'react';
import PropTypes from 'prop-types';

import { Heading, MemberSearchResults } from 'rides/components';
import { MemberSearch, StandardLayout } from 'rides/containers';

const MemberSearchPage = ({
  memberList,
  memberListDone,
  memberListPending,
  memberListFailed,
  search,
  showCreateModal,
}) => (
  <StandardLayout>
    <Heading level={3} pageTitle>
      SEARCH FOR A MEMBER
    </Heading>
    <MemberSearch search={search} />
    <MemberSearchResults
      isSearchDone={memberListDone}
      isSearchFailed={memberListFailed}
      isSearching={memberListPending}
      memberList={memberList}
      showCreateModal={showCreateModal}
    />
  </StandardLayout>
);

MemberSearchPage.propTypes = {
  memberList: PropTypes.array,
  memberListDone: PropTypes.bool,
  memberListFailed: PropTypes.bool,
  memberListPending: PropTypes.bool,
  search: PropTypes.func,
  showCreateModal: PropTypes.func,
};

export default MemberSearchPage;
