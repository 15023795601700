import * as R from 'ramda';

import { CONFIRM_SHOW, CONFIRM_HIDE } from './actions';
import { initialState } from './selectors';

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case CONFIRM_SHOW: {
      const {
        title,
        message,
        noButtonText,
        yesButtonText,
        hideNoButton,
        danger,
      } = payload;

      return R.merge(initialState, {
        title,
        message,
        noButtonText,
        yesButtonText,
        isShowing: true,
        hideNoButton,
        danger,
      });
    }

    case CONFIRM_HIDE: {
      return R.assoc('isShowing', false, state);
    }

    default:
      return state;
  }
};
