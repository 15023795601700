import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { parsePhoneNumber } from 'libphonenumber-js';
import * as ridesTypes from 'rides/types';
import { Link } from 'rides/nui';
import Icon from 'rides/nui/Icon';

const phoneNumberTypeToIconMap = {
  MOBILE: 'mobile',
  FIXED_LINE: 'phone',
  FIXED_LINE_OR_MOBILE: 'phone',
  PREMIUM_RATE: 'phone',
  TOLL_FREE: 'phone',
  SHARED_COST: 'phone',
  VOIP: 'phone',
  PERSONAL_NUMBER: 'phone',
  PAGER: 'phone',
  UAN: 'phone',
  VOICEMAIL: 'phone',
};

const getIconName = phoneNumberType =>
  R.pathOr('phone', [phoneNumberType], phoneNumberTypeToIconMap);

// --
// -- PhoneNumber
// --

const PhoneNumber = ({ phoneNumber, render }) => {
  try {
    const phoneParsed = parsePhoneNumber(phoneNumber, 'US');
    const phoneNumberFormatted = phoneParsed.format('NATIONAL');
    const phoneNumberUri = phoneParsed.format('RFC3966');
    const phoneNumberType = phoneParsed.getType();
    const iconName = getIconName(phoneNumberType);

    return render({
      phoneNumber: (
        <Link href={phoneNumberUri} variant="tel">
          {phoneNumberFormatted}
        </Link>
      ),
      phoneNumberType,
      iconName,
      icon: <Icon name={iconName} />,
    });
  } catch (error) {
    return render({
      error,
      phoneNumber: phoneNumber, // show original phone number on error
      phoneNumberType: null,
      iconName: 'phone',
      icon: null,
    });
  }
};

PhoneNumber.propTypes = {
  phoneNumber: ridesTypes.phoneNumberType,
  render: PropTypes.func.isRequired,
};

export default PhoneNumber;
