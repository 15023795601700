import { consoleErrorRecovery, safeSaga } from 'rides/store/helpers';
import { select, takeLatest, put, putResolve, call } from 'redux-saga/effects';

import { setTimezone } from 'rides/utils/dateTime';
import { fromApp } from 'rides/store/selectors';
import { resourceListReadRequest, modalShow } from 'rides/store/actions';

import * as action from './actions';

/**
 * Task Sagas
 **/

export function* initTenantChange() {
  yield put(modalShow('tenant-change'));
}

export function* loadTenantList() {
  try {
    const resource = yield select(fromApp.tenant.tenantResource);
    yield putResolve(resourceListReadRequest(resource, null, 'tenant'));
  } catch (error) {
    console.log('sagaError::loadTenantList::Not Handled!', error);
  }
}

export function* useTenantTimezone(tenantId) {
  try {
    const tenantTimezone = yield select(fromApp.tenant.tenantTimezone, { tenantId });
    setTimezone(tenantTimezone);
  } catch (error) {
    console.log('sagaError::useTenantTimezone::Not Handled!', error);
  }
}

/**
 * Watcher Sagas
 **/

// Init Trip Action Forms Modal
export function* watchInitTenantChange() {
  yield call(initTenantChange);
}

export function* watchTenantLoadList() {
  yield call(loadTenantList);
}

export function* watchTenantUseTimezone({ payload }) {
  const { tenantId } = payload;
  yield call(useTenantTimezone, tenantId);
}

/**
 * Root Sagas
 **/

export default function*({ api }) {
  const safe = safeSaga(consoleErrorRecovery);

  yield takeLatest(action.TENANT_USE_TIME_ZONE, safe(watchTenantUseTimezone));
  yield takeLatest(action.TENANT_INIT_CHANGE, safe(watchInitTenantChange, api));
  yield takeLatest(action.TENANT_LOAD_LIST_REQUEST, safe(watchTenantLoadList, api));
}
