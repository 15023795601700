import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Th = ({ children, ...props }) => {
  return <th {...props}>{children}</th>;
};

Th.propTypes = {
  children: PropTypes.node,
};

const TableCell = ({
  heading,
  children,
  className,
  xs,
  sm,
  md,
  lg,
  textCenter,
  textRight,
  ...props
}) => {
  const classes = classNames(className, {
    [`col-xs-${xs}`]: xs,
    [`col-sm-${sm}`]: sm,
    [`col-md-${md}`]: md,
    [`col-lg-${lg}`]: lg,
    'text-center': textCenter,
    'text-right': textRight,
  });

  if (heading) {
    return (
      <Th {...props} className={classes}>
        {children}
      </Th>
    );
  }

  return (
    <td {...props} className={classes}>
      {children}
    </td>
  );
};

TableCell.propTypes = {
  heading: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  textCenter: PropTypes.bool,
  textRight: PropTypes.bool,
};

export default TableCell;
