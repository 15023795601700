import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import {
  Nothing,
  TripWizardStepCancelTrip,
  TripWizardStepDestination,
  TripWizardStepPickup,
  TripWizardStepPickupTime,
} from 'rides/components';

const getCurrentStepView = R.propOr(Nothing, R.__, {
  pickup: TripWizardStepPickup,
  destination: TripWizardStepDestination,
  'pickup-time': TripWizardStepPickupTime,
  'cancel-trip': TripWizardStepCancelTrip,
});

const TripWizardSteps = props => {
  const CurrentStepView = getCurrentStepView(props.currentStep);
  return <CurrentStepView {...props} />;
};

TripWizardSteps.propTypes = {
  currentStep: PropTypes.string.isRequired,
  formDisabled: PropTypes.bool.isRequired,
  stepErrors: PropTypes.object.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  goToPrevStep: PropTypes.func.isRequired,
  member: PropTypes.object,
  values: PropTypes.object.isRequired,
};

export default TripWizardSteps;
