import { call, put } from 'redux-saga/effects';

export const consoleErrorRecovery = (err, ...args) => {
  console.error('caught', err);
  console.log({ err, args });
};

// export function* reportErrorRecovery(err, ...args) {
//   yield put(
//     actions.core.report.error({
//       error: err,
//       severity: 'error',
//     }),
//   );
// }

export const safeSaga = recovery => (saga, ...args) =>
  function*(action) {
    try {
      yield call(saga, ...args, action);
    } catch (err) {
      yield call(recovery, err, ...args);
    }
  };
