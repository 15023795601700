import { consoleErrorRecovery, safeSaga } from 'rides/store/helpers';
import { select, takeLatest, putResolve, call } from 'redux-saga/effects';

import { fromApp } from 'rides/store/selectors';
import { resourceDetailReadRequest } from 'rides/store/actions';
import * as action from './actions';

/**
 * Task Sagas
 **/

export function* loadMemberDetail(memberId) {
  try {
    const resource = yield select(fromApp.member.memberResource);
    yield putResolve(resourceDetailReadRequest(resource, memberId, 'member'));
  } catch (error) {
    console.log('loadDetail::Not Handled!', error);
  }
}

/**
 * Watcher Sagas
 **/

export function* watchMemberLoadDetail(api, { payload }) {
  const { memberId } = payload;
  yield call(loadMemberDetail, memberId);
}

/**
 * Root Sagas
 **/

export default function*({ api }) {
  const safe = safeSaga(consoleErrorRecovery);

  yield takeLatest(action.MEMBER_LOAD_DETAIL_REQUEST, safe(watchMemberLoadDetail, api));
}
