import React from 'react';
import PropTypes from 'prop-types';

import { Block, Small, Strong } from 'rides/components';

const MemberDetailItem = ({ label, value, ...props }) => (
  <Block {...props}>
    <Small textMuted>{label}: </Small>
    <Strong data-test-handle={label}>{value}</Strong>
  </Block>
);

MemberDetailItem.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.node,
};

export default MemberDetailItem;
