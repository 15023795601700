import React from 'react';

import { ErrorPageTemplate, Atom, Paragraph, Link } from 'rides/components';

const NotFoundPage = () => (
  <ErrorPageTemplate heading={<Atom className="wb-help-circle" />}>
    <Paragraph>
      The resource could not be found.<br />
      Please go back or return to <Link to="/">the dashboard</Link>.
    </Paragraph>
  </ErrorPageTemplate>
);

export default NotFoundPage;
