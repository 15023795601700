import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { Box } from 'rides/nui';
import {
  Avatar,
  Badge,
  Button,
  Dialog,
  Icon,
  Table,
  Pane,
  Spinner,
  Pill,
} from 'evergreen-ui';
import useHover from 'react-use/lib/useHover';
import Component from '@reach/component-component';
import * as ridesTypes from 'rides/types';

const HoverableAvatar = ({ solidOnHover = false, ...props }) => {
  const [hoverAvatar] = useHover(isHovering => {
    return <Avatar isSolid={isHovering && solidOnHover} {...props} />;
  });

  return hoverAvatar;
};

HoverableAvatar.propTypes = {
  solidOnHover: PropTypes.bool,
};

const NoAgentAvatar = ({ ...props }) => (
  <HoverableAvatar
    name="No Agent Assigned"
    color="neutral"
    getInitials={() => <Icon icon="plus" />}
    solidOnHover
    {...props}
  />
);

const AssignAvatar = ({ trip, ...props }) => {
  const baseProps = { size: 32, marginTop: 10, ...props };
  const assignee = R.path(['assignee'], trip);

  if (assignee) {
    const name = R.path(['name'], assignee);
    return <HoverableAvatar {...baseProps} name={name} isSolid />;
  }

  return <NoAgentAvatar {...baseProps} />;
};

AssignAvatar.propTypes = {
  trip: ridesTypes.tripType.isRequired,
};

const AssignAgentButton = ({
  isAssigned,
  tripId,
  userId,
  assignAgent,
  unassignAgent,
}) => {
  const intent = !isAssigned ? 'none' : 'warning';
  const buttonText = !isAssigned ? 'Assign To' : 'Unassign';
  const clickAction = isAssigned ? unassignAgent : assignAgent;
  return (
    <Component initialState={{ isLoading: false }}>
      {({ state, setState }) => {
        const handleClick = async () => {
          setState({ isLoading: true });
          await clickAction(tripId, userId);
          setState({ isLoading: false });
        };

        const icon = !isAssigned ? 'add' : 'tick-circle';

        return (
          <Button
            iconBefore={state.isLoading ? undefined : icon}
            appearance="default"
            height={32}
            onClick={handleClick}
            isLoading={state.isLoading}
            intent={intent}
          >
            {buttonText}
          </Button>
        );
      }}
    </Component>
  );
};

AssignAgentButton.propTypes = {
  isAssigned: PropTypes.bool.isRequired,
  tripId: ridesTypes.tripIdType.isRequired,
  userId: ridesTypes.userIdType.isRequired,
  assignAgent: PropTypes.bool.isRequired,
  unassignAgent: PropTypes.bool.isRequired,
};

const TripAssignAvatar = ({
  size,
  trip,
  assignAgent,
  unassignAgent,
  loadSuggestions,
  isLoading,
  suggestedAssigneeList,
}) => {
  const assignedUserId = R.path(['assignee', 'id'], trip);
  return (
    <Component initialState={{ isShown: false }}>
      {({ state, setState }) => (
        <Box css={{ '&:hover': { cursor: 'pointer' } }}>
          <Dialog
            isShown={state.isShown}
            onCloseComplete={() => setState({ isShown: false })}
            preventBodyScrolling
            hasFooter={false}
            hasClose={false}
            title="Assign Agent"
            width={900}
          >
            {isLoading && !suggestedAssigneeList ? (
              <Pane>
                <Spinner marginX="auto" marginY={120} />
              </Pane>
            ) : (
              <Table>
                <Table.Head>
                  <Table.TextHeaderCell> </Table.TextHeaderCell>
                  <Table.TextHeaderCell>Assignee Name</Table.TextHeaderCell>
                  <Table.TextHeaderCell>
                    # trips assigned within 15 mins
                  </Table.TextHeaderCell>
                  <Table.TextHeaderCell> </Table.TextHeaderCell>
                </Table.Head>
                <Table.Body dheight={240}>
                  {suggestedAssigneeList &&
                    suggestedAssigneeList.map(suggestedAssignee => {
                      const isAssigned = assignedUserId === suggestedAssignee.id;
                      return (
                        <Table.Row
                          key={suggestedAssignee.id}
                          isHighlighted={isAssigned}
                          intent={isAssigned ? 'success' : 'none'}
                        >
                          <Table.TextCell textProps={{ size: 500 }} flexShrink={1}>
                            <AssignAgentButton
                              assignAgent={assignAgent}
                              unassignAgent={unassignAgent}
                              isAssigned={isAssigned}
                              userId={suggestedAssignee.id}
                              tripId={trip.id}
                            />
                          </Table.TextCell>
                          <Table.TextCell textProps={{ size: 500 }}>
                            {suggestedAssignee.name}
                          </Table.TextCell>
                          <Table.TextCell textProps={{ size: 600, textAlign: 'center' }}>
                            {suggestedAssignee.assigned}
                          </Table.TextCell>
                          <Table.TextCell
                            textProps={{
                              color: 'muted',
                              textAlign: 'right',
                              fontFamily: 'mono',
                              size: 300,
                            }}
                          >
                            {isAssigned && (
                              <Badge color="green" isSolid>
                                Assigned
                              </Badge>
                            )}
                          </Table.TextCell>
                        </Table.Row>
                      );
                    })}
                </Table.Body>
              </Table>
            )}
          </Dialog>
          <AssignAvatar
            size={size}
            trip={trip}
            onClick={async () => {
              setState({ isShown: true });
              await loadSuggestions();
            }}
          />
        </Box>
      )}
    </Component>
  );
};

TripAssignAvatar.propTypes = {
  trip: ridesTypes.tripType.isRequired,
  assignAgent: PropTypes.func.isRequired,
  unassignAgent: PropTypes.func.isRequired,

  loadSuggestions: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  suggestedAssigneeList: ridesTypes.agentAssigneeSuggestionListType,
};

export default TripAssignAvatar;
