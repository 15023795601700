import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { LocationAutocomplete } from 'rides/containers';
import { Field } from 'rides/components';

class TripWizardLocationAutocompleteFieldContainer extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    locationPrefix: PropTypes.string.isRequired, // pickup/dropoff
    type: PropTypes.func.isRequired,
    updateValues: PropTypes.func.isRequired,
  };

  handlePlaceClick = (place, details) => {
    const { locationPrefix, updateValues } = this.props;

    const getValue = field => {
      const value = R.propOr('', field, details);
      return value === null ? '' : value;
    };

    updateValues({
      [`${locationPrefix}locationAutocomplete`]: place.address,
      [`${locationPrefix}isHome`]: false,
      [`${locationPrefix}name`]: getValue('name'),
      [`${locationPrefix}phoneNumber`]: getValue('phoneNumber'),
      [`${locationPrefix}address1`]: getValue('address1'),
      [`${locationPrefix}address2`]: getValue('address2'),
      [`${locationPrefix}city`]: getValue('city'),
      [`${locationPrefix}state`]: getValue('state'),
      [`${locationPrefix}postalCode`]: getValue('postalCode'),
    });
  };

  render() {
    const { locationPrefix, type, ...fieldProps } = this.props;
    const { value } = this.props;

    return (
      <LocationAutocomplete value={value} onPlaceClick={this.handlePlaceClick}>
        <Field
          {...fieldProps}
          placeholder="Search for a business or address"
          autoComplete="off"
        />
      </LocationAutocomplete>
    );
  }
}

export default TripWizardLocationAutocompleteFieldContainer;
