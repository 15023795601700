import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';

import { tripStatusTransitionReasonListType } from 'rides/types';
import { FormikField } from 'rides/components';
import { TripTransitionReasonSelectField } from 'rides/containers';
import OccurredAtField from './OccurredAtField';

const TripTransitionForm = ({
  children,
  disabled,
  reasonList,
  showReason,
  showUpdateForm,
}) => (
  <>
    <Field
      name="tripStatusTransition.occurredAt"
      label="Occurred At"
      component={OccurredAtField}
      disabled={disabled}
      updateMode={showUpdateForm}
    />

    <div style={{ marginTop: '20px' }} />

    {!showUpdateForm && children}

    {showReason && (
      <Field
        name="tripStatusTransition.reasonId"
        label="Reason"
        component={FormikField}
        type={TripTransitionReasonSelectField}
        disabled={disabled}
        reasonList={reasonList}
      />
    )}

    <Field
      name="tripStatusTransition.notes"
      label="Notes"
      component={FormikField}
      disabled={disabled}
      type="textarea"
    />

    {showUpdateForm && (
      <Field
        name="tripStatusTransition.updatedBecause"
        label="Updated Because"
        component={FormikField}
        disabled={disabled}
        type="textarea"
      />
    )}
  </>
);

TripTransitionForm.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  reasonList: tripStatusTransitionReasonListType.isRequired,
  showReason: PropTypes.bool,
  showUpdateForm: PropTypes.bool,
};

TripTransitionForm.defaultProps = {
  children: null,
  disabled: false,
};

export default TripTransitionForm;
