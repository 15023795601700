/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'GymIcon',
  path: (
    <g fill="currentColor">
      <g fill-rule="evenodd" clip-path="url(#clip0)" clip-rule="evenodd">
        <path d="M2.333 5.206h-.666c-.552 0-1 .449-1 1V7.54H.333a.333.333 0 000 .667h.334V9.54c0 .552.448 1 1 1h.666c.184 0 .334-.149.334-.333V5.54a.334.334 0 00-.334-.333zM11.667 3.206h-1.333c-.551 0-1 .449-1 1v3.667H6.667V4.206c0-.551-.449-1-1-1H4.334c-.551 0-1 .449-1 1v7.333c0 .552.449 1 1 1h1.333c.551 0 1-.448 1-1v-3h2.667v3c0 .552.449 1 1 1h1.333c.551 0 1-.448 1-1V4.206c0-.551-.449-1-1-1zM15.667 7.54h-.333V6.205c0-.551-.45-1-1-1h-.667a.333.333 0 00-.333.333v4.667c0 .184.15.333.333.333h.667c.55 0 1-.448 1-1V8.206h.333a.334.334 0 000-.667z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 16 16',
});
