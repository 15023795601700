import React from 'react';

import { tripType } from 'rides/types';
import { Block, Small } from 'rides/components';

const Driver = ({ trip, ...props }) => (
  <Block {...props} data-test-handle="driver details container">
    {trip.customDriverName && (
      <Block>
        <Small textMuted>Name:</Small>
        <Block data-test-handle="driver name">{trip.customDriverName}</Block>
      </Block>
    )}
    {trip.customDriverPhone && (
      <Block>
        <Small textMuted>Phone:</Small>
        <Block data-test-handle="driver phone">{trip.customDriverPhone}</Block>
      </Block>
    )}
    {trip.customDriverCarDescription && (
      <Block>
        <Small textMuted>Car Description:</Small>
        <Block data-test-handle="driver car description">
          {trip.customDriverCarDescription}
        </Block>
      </Block>
    )}
    {trip.customDriverCarLicense && (
      <Block>
        <Small textMuted>Car License:</Small>
        <Block data-test-handle="driver car license">{trip.customDriverCarLicense}</Block>
      </Block>
    )}
  </Block>
);

Driver.propTypes = {
  trip: tripType,
};

export default Driver;
