import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { Pane, Spinner } from 'evergreen-ui';
import * as ridesTypes from 'rides/types';
import { Button, Box, Flex, Text } from 'rides/nui';
import Card from 'rides/nui/Card';
import TripListRow from './TripListRow';
import TripListTrip from './TripListTrip';
import TripListTripConnectedTrips from 'rides/nui/containers/TripListTripConnectedTrips';
import TripAssignAvatar from 'rides/nui/containers/TripAssignAvatar';

const TripListRowCard = ({ ...props }) => (
  <Card py={3} mb={4} boxShadow={1} bg="white" {...props} />
);

const ConnectedTripSeperator = ({}) => (
  <Box borderBottom="1px solid" color="black" mx={5} mt={3} mb={5} />
);

const TripListRowAssigneeWrapper = ({ ...props }) => (
  <Pane
    display="flex"
    justifyContent="center"
    minWidth={46}
    paddingTop={0}
    paddingLeft={14}
    {...props}
  />
);

export const TripListRowAssignee = ({ trip, size }) => (
  <TripListRowAssigneeWrapper>
    <TripAssignAvatar trip={trip} size={size} />
  </TripListRowAssigneeWrapper>
);

TripListRowAssignee.propTypes = {
  trip: ridesTypes.tripType.isRequired,
};

class TripListRowResult extends React.Component {
  state = {
    isExpanded: false,
  };

  onCollapse = event => {
    event.preventDefault();
    this.setState({ isExpanded: false });
  };

  onExpand = event => {
    event.preventDefault();
    this.setState({ isExpanded: true });
  };

  render() {
    const { trip, hideForHumana, showAssignAgentUI, showStatusTransitionUI } = this.props;
    const { isExpanded } = this.state;

    return (
      <Flex width={1}>
        {showAssignAgentUI && <TripListRowAssignee trip={trip} />}
        <TripListRowCard>
          <TripListTrip
            trip={trip}
            isExpanded={isExpanded}
            hideForHumana={hideForHumana}
            showStatusTransitionUI={showStatusTransitionUI}
          />

          <TripListTripConnectedTrips
            trip={trip}
            render={ct => {
              return (
                <React.Fragment>
                  <TripListRow
                    mt={!isExpanded ? -6 : 4}
                    renderColumns={() => (
                      <Flex justifyContent="flex-end" width={1}>
                        {isExpanded && (
                          <Flex flex="1 1 auto" justifyContent="center">
                            <Button
                              onClick={ct.isShown ? ct.onHide : ct.onShow}
                              icon="keyboard-arrow-down"
                              variant="link"
                              css={{
                                '& .icon': {
                                  transform: ct.isShown ? 'rotate(180deg)' : null,
                                  transition:
                                    'transform 150ms cubic-bezier(0.5, 0, 0.25, 1)',
                                },
                              }}
                            >
                              {ct.isShown
                                ? 'HIDE CONNECTED TRIPS'
                                : 'SHOW CONNECTED TRIPS'}
                            </Button>
                          </Flex>
                        )}

                        <Box>
                          <Button
                            onClick={isExpanded ? this.onCollapse : this.onExpand}
                            icon="keyboard-arrow-down"
                            css={{
                              transform: isExpanded ? 'rotate(180deg)' : null,
                              transition: 'transform 150ms cubic-bezier(0.5, 0, 0.25, 1)',
                            }}
                          />
                        </Box>
                      </Flex>
                    )}
                  />

                  {isExpanded &&
                  ct.isShown && ( // Connected Trips
                      <React.Fragment>
                        {ct.isLoading && (
                          <React.Fragment>
                            <ConnectedTripSeperator />
                            <Text width={1} textAlign="center" mb={5}>
                              Loading...
                            </Text>
                          </React.Fragment>
                        )}

                        {ct.isLoaded &&
                          (ct.tripList.length < 1 ? (
                            <React.Fragment>
                              <ConnectedTripSeperator />
                              <Text width={1} textAlign="center" mb={5}>
                                No Connected Trips
                              </Text>
                            </React.Fragment>
                          ) : (
                            ct.tripList.map(ctTrip => (
                              <React.Fragment key={ctTrip.id}>
                                <ConnectedTripSeperator />
                                <TripListTrip
                                  trip={ctTrip}
                                  showStatusTransitionUI={showStatusTransitionUI}
                                  isExpanded
                                />
                              </React.Fragment>
                            ))
                          ))}
                      </React.Fragment>
                    )}
                </React.Fragment>
              );
            }}
          />
        </TripListRowCard>
      </Flex>
    );
  }
}

TripListRowResult.propTypes = {
  trip: ridesTypes.tripType.isRequired,
  hideForHumana: PropTypes.bool.isRequired,
  showAssignAgentUI: PropTypes.bool.isRequired,
  showStatusTransitionUI: PropTypes.bool.isRequired,
};

const TripList = ({
  // eslint-disable-next-line no-unused-vars
  // searchOptions,
  tripList,
  isLoading,
  hideForHumana,
  showStaffUI,
  ...props
}) => {
  const hasResults = !R.isEmpty(tripList);
  const showAssignAgentUI = showStaffUI;
  const showStatusTransitionUI = !!showStaffUI;
  const padLeftForAssignAgent = showAssignAgentUI ? 46 : 0;

  return (
    <Box {...props} py={4} bg="lightGray" width={1060}>
      {/* Header  */}
      <Box pb={2} pl={padLeftForAssignAgent}>
        <TripListRow
          colTripId={'TRIP ID'}
          colMember={'MEMBER'}
          colTripLeg={'TRIP LEG'}
          colTripType={'TYPE'}
          colTags={'TAGS'}
          colStatus={'STATUS'}
        />
      </Box>

      {isLoading ? (
        <Box pb={2} pl={padLeftForAssignAgent}>
          <TripListRowCard>
            <Pane display="flex" alignItems="center" justifyContent="center" height={115}>
              <Spinner />
            </Pane>
          </TripListRowCard>
        </Box>
      ) : !hasResults ? (
        <TripListRowCard>
          <Text textAlign="center" fontSize={3} color="mediumGray">
            No Results
          </Text>
        </TripListRowCard>
      ) : (
        tripList.map(trip => (
          <TripListRowResult
            key={trip.id}
            trip={trip}
            hideForHumana={hideForHumana}
            showAssignAgentUI={showAssignAgentUI}
            showStatusTransitionUI={showStatusTransitionUI}
          />
        ))
      )}
    </Box>
  );
};

TripList.propTypes = {
  isLoading: PropTypes.bool,
  tripList: PropTypes.arrayOf(ridesTypes.tripType),
  hideForHumana: PropTypes.bool.isRequired,
  showStaffUI: PropTypes.bool.isRequired,
};

TripList.displayName = 'TripList';

export default TripList;
