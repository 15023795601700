import React from 'react';

import { companionType } from 'rides/types';
import { Block, Small, Strong } from 'rides/components';

const Companion = ({ companion, ...props }) => {
  if (!companion) return null;

  return (
    <Block {...props}>
      <Block>
        <Small textMuted>Name:</Small> <Strong>{companion.name}</Strong>
      </Block>
      <Block>
        <Small textMuted>Relationship:</Small> <Strong>{companion.relationship}</Strong>
      </Block>
      <Block>
        <Small textMuted>Phone Number Type:</Small>{' '}
        <Strong>{companion.phoneNumberType}</Strong>
      </Block>
      <Block>
        <Small textMuted>Primary Contact:</Small>{' '}
        <Strong>{companion.primaryContact ? 'Yes' : 'No'}</Strong>
      </Block>
      <Block>
        <Small textMuted>Phone Number:</Small> <Strong>{companion.phoneNumber}</Strong>
      </Block>
    </Block>
  );
};

Companion.propTypes = {
  companion: companionType,
};

export default Companion;
