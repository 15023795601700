/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'ActivityLogXlIcon',
  path: (
    <g fill="currentColor">
      <g clip-path="url(#clip0)">
        <path
          fill-rule="evenodd"
          d="M11.481 5.463A.5.5 0 0111.98 5h.178a.5.5 0 01.498.452l.505 5.257 3.595 2.139a.5.5 0 01.244.43v.195a.5.5 0 01-.637.481l-5.004-1.42a.5.5 0 01-.362-.518l.484-6.553z"
          clip-rule="evenodd"
        />
        <path
          fill-rule="evenodd"
          d="M6.224.145a.5.5 0 00-.869.205l-1.15 4.821a.5.5 0 00.515.615l4.959-.28a.5.5 0 00.355-.821l-1.217-1.45a8.615 8.615 0 012.79-.461 8.62 8.62 0 11-8.62 8.62c0-.8.108-1.582.32-2.335l-1.953-.547a10.657 10.657 0 00-.395 2.882c0 5.88 4.767 10.648 10.648 10.648 5.88 0 10.648-4.768 10.648-10.648 0-5.881-4.767-10.648-10.648-10.648-1.48 0-2.888.301-4.168.846L6.224.145z"
          clip-rule="evenodd"
        />
        <rect
          width="13.214"
          height="13.214"
          x="10.143"
          y="10.143"
          stroke-width="1.714"
          rx="6.607"
        />
        <path d="M18.295 19h-2.878v-4.997h2.878v.868h-1.818v1.097h1.692v.868h-1.692v1.289h1.818V19z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h23v23H0z" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 23 23',
});
