import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Link } from 'rides/components';
import { linkToType } from 'rides/types';

const Button = ({
  className,
  style,
  type,
  danger,
  info,
  primary,
  success,
  warning,
  link,
  block,
  xs,
  sm,
  small,
  lg,
  large,
  pullLeft,
  pullRight,
  textMuted,
  ...props
}) => {
  const classes = classNames(
    className,
    {
      'pull-left': pullLeft,
      'pull-right': pullRight,
      'btn-block': block,
      'btn-xs': xs,
      'btn-sm': sm || small,
      'btn-lg': lg || large,
    },
    link
      ? {
          'btn-link': link,
          'text-danger': danger,
          'text-info': info,
          'text-primary': primary,
          'text-primary': primary,
          'text-success': success,
          'text-warning': warning,
          'text-muted': textMuted,
        }
      : {
          btn: true,
          'btn-default': !(danger || info || primary || success || warning),
          'btn-danger': danger,
          'btn-info': info,
          'btn-primary': primary,
          'btn-primary': primary,
          'btn-success': success,
          'btn-warning': warning,
        },
  );

  if (props.to) {
    return <Link className={classes} {...{ ...props, style }} />;
  } else if (props.href) {
    return <a className={classes} {...{ ...props, style }} />;
  }

  const btnStyle = { margin: 2, ...style };

  return <button className={classes} {...props} style={btnStyle} type={type} />;
};

Button.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  to: linkToType,
  type: PropTypes.string,

  pullLeft: PropTypes.bool,
  pullRight: PropTypes.bool,

  link: PropTypes.bool,
  block: PropTypes.bool,
  xs: PropTypes.bool,
  sm: PropTypes.bool,
  small: PropTypes.bool,
  lg: PropTypes.bool,
  large: PropTypes.bool,

  textMuted: PropTypes.bool,

  // contexts
  danger: PropTypes.bool,
  info: PropTypes.bool,
  primary: PropTypes.bool,
  success: PropTypes.bool,
  warning: PropTypes.bool,
};

Button.defaultProps = {
  type: 'button',
};

export default Button;
