/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'HomeIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M12 11.146c0 .175-.15.317-.334.317H8.333A.326.326 0 018 11.146V9.244c0-.175.15-.317.333-.317h3.333c.184 0 .334.142.334.317v1.902zm-4.667 2.22c0 .175-.15.317-.333.317H3.667a.326.326 0 01-.334-.317v-.317c0-.175.15-.317.333-.317H7c.183 0 .333.142.333.317v.317zM4 8.61c0-.175.15-.317.333-.317h2c.183 0 .333.142.333.317v3.17c0 .176-.15.318-.333.318h-2A.326.326 0 014 11.78V8.61zm11.666 4.756H14v-5.39a.326.326 0 00-.333-.317H2.333A.326.326 0 002 7.976v5.39H.333a.326.326 0 00-.333.317c0 .175.15.317.333.317h15.333a.326.326 0 00.333-.317.326.326 0 00-.333-.317zM.334 7.024h15.333a.333.333 0 00.316-.218.308.308 0 00-.118-.354L14 5.142V2.584a.326.326 0 00-.333-.317h-2a.326.326 0 00-.333.318v.68L8.198 1.063a.347.347 0 00-.396 0L.135 6.452a.309.309 0 00-.118.354c.045.13.173.218.317.218z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
