import React from 'react';
import PropTypes from 'prop-types';

import { Modal, MemberForm } from 'rides/containers';
import { Block, Button } from 'rides/components';

const confirmAbandonMsg = `Are you sure you want to close the edit member form?
Any changes you made without saving will be discarded.`;

function confirmAbandonForm() {
  const shouldClose = confirm(confirmAbandonMsg);
  return shouldClose;
}

const EditMemberModal = ({ member, onSubmitCallback }) => (
  <Modal
    title="Edit Member"
    name="edit-member"
    onClose={confirmAbandonForm}
    backdrop="static"
    closeable
  >
    <MemberForm
      member={member}
      onSubmitCallback={onSubmitCallback}
      render={({ formikProps, formFields }) => (
        <Block>
          {formFields}
          <Block textCenter>
            <Button type="submit" disabled={formikProps.isSubmitting} success data-test-id="save-member-modal-button">
              Save Member
            </Button>
          </Block>
        </Block>
      )}
    />
  </Modal>
);

EditMemberModal.propTypes = {
  member: PropTypes.object.isRequired, // todo replace this with whatever is defined in type file
  onSubmitCallback: PropTypes.func.isRequired,
};

export default EditMemberModal;
