/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'GroceryIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M15.333 7.667H.667V7h14.666v.667zM12 13.333a.334.334 0 01-.667 0v-4a.334.334 0 01.667 0v4zm-2.667 0a.334.334 0 01-.666 0v-4a.333.333 0 01.666 0v4zm-2 0a.334.334 0 01-.666 0v-4a.333.333 0 01.666 0v4zm-2.666 0a.334.334 0 01-.667 0v-4a.333.333 0 01.667 0v4zm11-7H.333A.333.333 0 000 6.667V8c0 .184.15.333.333.333h.388l1.284 7.06a.333.333 0 00.328.274h11.334c.16 0 .298-.116.327-.274l1.284-7.06h.389c.183 0 .333-.15.333-.333V6.667a.334.334 0 00-.333-.334zM2.333 5.667c.085 0 .171-.033.236-.098l4-4a.334.334 0 00-.471-.472l-4 4a.334.334 0 00.236.57zM13.431 5.569a.331.331 0 00.471 0 .334.334 0 000-.471l-4-4a.334.334 0 00-.47.47l4 4z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
