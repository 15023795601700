/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'EllipsisVerticalIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M8 15a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm0-5.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm1.5-7a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
