import React from 'react';

import { ErrorPageTemplate, Atom, Paragraph, Link } from 'rides/components';

const NotFoundPage = () => (
  <ErrorPageTemplate heading={<Atom className="wb-help-circle" />}>
    <Paragraph>
      This page requires authentication.<br />
      If you were logged in, your session has expired. Please{' '}
      <Link to="/logout">log in</Link> to continue.
    </Paragraph>
  </ErrorPageTemplate>
);

export default NotFoundPage;
