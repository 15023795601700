/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'PlaceIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M7.5 2.667c1.588 0 2.881 1.317 2.881 2.933 0 1.621-1.293 2.933-2.881 2.933-1.588 0-2.881-1.312-2.881-2.933 0-1.616 1.293-2.933 2.881-2.933zM7.5 0C4.467 0 2 2.512 2 5.6c0 2.581 1.69 4.784 4.129 5.424l1.116 4.773A.264.264 0 007.5 16h.006c.123 0 .228-.09.251-.213l.881-4.71C11.177 10.533 13 8.261 13 5.6 13 2.512 10.533 0 7.5 0z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
