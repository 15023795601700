/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'EncounterSuccessXlIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M5.28 14.401a.48.48 0 000 .96h2.881a.48.48 0 000-.96h-2.88zM10.561 12.96a.48.48 0 00-.48-.48h-4.8a.48.48 0 000 .961h4.8a.48.48 0 00.48-.48zM13.441 4.8h.48a.48.48 0 01.48.48v4.74a7.143 7.143 0 011.92-.394V3.36a.482.482 0 00-.48-.48h-2.4V4.8zM5.28 11.52h4.801a.48.48 0 000-.96h-4.8a.48.48 0 000 .96z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M10.021 19.201h-7.62a.48.48 0 01-.48-.48V5.281a.48.48 0 01.48-.48h.48V2.88H.48a.48.48 0 00-.48.48v15.36c0 1.323 1.077 2.4 2.401 2.4h8.654a7.156 7.156 0 01-1.034-1.92z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M3.84 6.24c0 .265.215.48.48.48H12a.48.48 0 00.48-.48V2.4a.48.48 0 00-.48-.48h-1.483C10.336.925 9.581 0 8.16 0 6.74 0 5.985.925 5.803 1.92H4.32a.48.48 0 00-.48.48v3.84zM5.28 9.6h4.801a.48.48 0 000-.96h-4.8a.48.48 0 000 .96z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M20.276 15.565L16.3 19.922l-2.952-2.95a.475.475 0 11.673-.675l2.248 2.246 3.302-3.62a.477.477 0 01.705.642zm-3.471-4.956a6.201 6.201 0 00-6.196 6.195A6.202 6.202 0 0016.805 23 6.203 6.203 0 0023 16.804a6.202 6.202 0 00-6.195-6.195z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 23 23',
});
