import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Atom from 'rides/components/atoms/Atom';
import Paragraph from 'rides/components/atoms/Paragraph';
import ErrorPageTemplate from 'rides/components/templates/ErrorPageTemplate';
import { apiUrl } from 'rides/config';
import { useQueryString } from 'rides/hooks/use-query-string';
import { fromAuth } from 'rides/store/selectors';

const mapStateToProps = (state, props) => ({
  isAuthenticated: fromAuth.isAuthenticated(state, props),
});

function AuthLoginErrorPage({ isAuthenticated }) {
  const { query } = useQueryString();

  const isMissingRole = query.code === 'missing_role';

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <ErrorPageTemplate heading={<Atom className="wb-warning" />}>
      <Paragraph>
        {isMissingRole
          ? `Your profile hasn't been fully configured yet. Please contact an administrator for help provisioning your account.`
          : 'There was a problem logging you in. Please contact an administrator for help.'}
      </Paragraph>

      <Paragraph style={{ marginTop: '20px' }}>
        <a href={`${apiUrl}/auth/logout`} className="btn btn-primary">
          Log Out
        </a>
      </Paragraph>
    </ErrorPageTemplate>
  );
}

AuthLoginErrorPage.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(AuthLoginErrorPage);
