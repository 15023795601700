import { useQuery, useQueryClient } from 'react-query';
import { useSyncToReduxEntities } from './redux-interop'
// import { useDispatch, useSelector } from 'react-redux';
import { client } from 'rides/evo/utils/api-client';
// import { useRouter } from 'rides/hooks/use-router';
// import { formatDateStr } from 'rides/nui/pages/TripListPage/SearchBox';
// import { tripWizardInitEditTrip } from 'rides/store/actions';
// import { fromEntities } from 'rides/store/selectors';
// import * as queryString from 'rides/utils/queryString';

// ---------------------------------------------
// Trip Data Extract & Format Functions
// ---------------------------------------------

function getMemberName(member) {
  const firstName = member?.first_name ?? '';
  const lastName = member?.last_name ?? '';

  return `${firstName} ${lastName}`.trim();
}

//--

// ---------------------------------------------
// React Query Functions & Hooks
// ---------------------------------------------

const memberDetailKey = memberId => ['member', memberId];

function setQueryDataForMember(member, queryClient) {
  const queryKey = memberDetailKey(member.id);
  queryClient.setQueryData(queryKey, member);
}

function invalidateQueryDataForMember(memberId, queryClient) {
  const queryKey = memberDetailKey(memberId);
  queryClient.invalidateQueries(queryKey);
}

function useInvalidateMember() {
  const queryClient = useQueryClient();
  return (memberId, refetchActive = true) =>
    invalidateQueryDataForMember(memberId, queryClient, refetchActive);
}
// --
// -- Member
// --

function useMember(memberId, { enabled, ...useQueryArgs } = {}) {
  const syncToReduxEntities = useSyncToReduxEntities();
  const queryKey = memberDetailKey(memberId);

  const { data, ...result } = useQuery({
    queryKey: queryKey,
    queryFn: () =>
      client(`member/${memberId}`)
        .then(resp => resp.data)
        .then(data => {
          const member = data?.data;
          syncToReduxEntities('member', member);
          return member;
        }),
    keepPreviousData: true,
    notifyOnChangeProps: ['data', 'error'],
    staleTime: 1000 * 20, // 20 secs
    cacheTime: 1000 * 20, // 20 secs
    enabled,
    ...useQueryArgs,
  });

  return { ...result, member: data }; //member: data?.data };
}

export {
  getMemberName,
  invalidateQueryDataForMember,
  setQueryDataForMember,
  useInvalidateMember,
  useMember,
};
