import React from 'react';
import * as R from 'ramda';

import { activityLogType } from 'rides/types';
import { ucwords } from 'rides/utils/format';
import { Block, Strong } from 'rides/components';
import { ActionIdLink, actionName, ActionText } from './ActionText';
import DescriptionText from './DescriptionText';
import DeltaChanges from './DeltaChanges';
import ByUserText from './ByUserText';
import TransitionChanges from './TransitionChanges';
import { VendorNames } from 'rides/evo/components/VendorNames/VendorNames';
import AppointmentReminderLogItem from './AppointmentReminderLogItem';

const ActivityLogItemText = ({ log }) => {
  const { type: actionType, action } = log;

  // member - created
  if (actionType === 'member' && action === 'created') {
    return (
      <Block>
        Member <DescriptionText log={log} />
        {actionName(log.action)} <ByUserText user={log.user} />
      </Block>
    );
  }

  // member - updated.health_attributes
  if (actionType === 'member' && action === 'updated.health_attributes') {
    return (
      <Block>
        <ActionText log={log} /> <ByUserText user={log.user} />
      </Block>
    );
  }

  // trip - in_transit
  if (actionType === 'trip' && action === 'in_transit') {
    return (
      <Block>
        Trip <ActionIdLink log={log} /> <Strong>member in transit</Strong>{' '}
        <ByUserText user={log.user} />
      </Block>
    );
  }

  // trip - status_reset
  if (actionType === 'trip' && action === 'status_reset') {
    return (
      <Block>
        Trip <ActionIdLink log={log} /> status reset to scheduled{' '}
        <ByUserText user={log.user} />
      </Block>
    );
  }

  // trip - status_reset
  if (actionType === 'trip' && action === 'updated.is_will_call') {
    const branchWords = !!log?.data?.description?.new ? 'true' : 'false';
    return (
      <Block>
        Trip <ActionIdLink log={log} /> Updated <Strong>is will call</Strong> to{' '}
        <Strong>{branchWords}</Strong> <ByUserText user={log.user} />
      </Block>
    );
  }

  // trip - cancelled
  if (actionType === 'trip' && action === 'cancelled') {
    const reason = R.path(['data', 'description', 'new'], log);

    return (
      <Block>
        Trip <ActionIdLink log={log} /> <Strong>cancelled</Strong>{' '}
        {reason && `(${reason})`} <ByUserText user={log.user} />
      </Block>
    );
  }

  // trip_status_transition.updated
  if (actionType === 'trip_status_transition' && action === 'updated') {
    return (
      <Block>
        Trip <ActionIdLink log={log} /> {ucwords(log.data.statusTo)} Status Change Updated{' '}
        <ByUserText user={log.user} />
        <Block style={{ marginLeft: '20px' }}>
          <TransitionChanges changes={log.data.changes} />
        </Block>
        {log.data.updatedBecause && (
          <Block>
            <Block style={{ marginTop: '10px', color: '#abb3ba' }}>Update Reason</Block>
            {log.data.updatedBecause}
          </Block>
        )}
      </Block>
    );
  }

  // trip - tookan.pushed_initial
  if (actionType === 'trip' && action === 'tookan.pushed_initial') {
    // console.log('log:', log);
    const pickupJobId = R.path(['data', 'pickupJobId'], log);
    const deliveryJobId = R.path(['data', 'deliveryJobId'], log);
    return (
      <Block>
        Trip <ActionIdLink log={log} /> Tookan tasks created
        <Block style={{ marginLeft: '20px' }}>
          Pickup task: {pickupJobId}
          <br />
          Dropoff task: {deliveryJobId}
        </Block>
      </Block>
    );
  }

  // trip - vendor.updated
  if (actionType === 'trip' && action === 'vendor.updated') {
    const newVendorId = R.path(['data', 'description', 'new'], log);
    const oldVendorId = R.path(['data', 'description', 'old'], log);

    return (
      <VendorNames
        vendorIds={[newVendorId, oldVendorId]}
        render={({ vendorNames }) => {
          const [newVendorName, oldVendorName] = vendorNames;
          const logWithVendorNames = R.pipe(
            R.assocPath(['data', 'description', 'new'], newVendorName),
            R.assocPath(['data', 'description', 'old'], oldVendorName),
          )(log);

          return (
            <Block>
              <ActionText log={log} /> <DescriptionText log={logWithVendorNames} />{' '}
              <DeltaChanges log={log} />
              <ByUserText user={log.user} />
            </Block>
          );
        }}
      />
    );
  }

  // trip - appointment_reminder
  if (actionType === 'trip' && action.startsWith('appointment_reminder.')) {
    return <AppointmentReminderLogItem log={log} />;
  }

  return (
    <Block>
      <ActionText log={log} /> <DescriptionText log={log} /> <DeltaChanges log={log} />
      <ByUserText user={log.user} />
    </Block>
  );
};

ActivityLogItemText.propTypes = {
  log: activityLogType,
};

export default ActivityLogItemText;
