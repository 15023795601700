/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'InsuranceLgIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M7.242 20H7.23a.338.338 0 01-.333-.34c.033-.967.418-1.753 1.03-2.113a.353.353 0 01.472.119.324.324 0 01-.118.454c-.397.233-.67.847-.695 1.56a.337.337 0 01-.345.32zM10.001 20a.338.338 0 01-.345-.334V6.333c0-.18.155-.333.345-.333.19 0 .345.153.345.333v13.333A.338.338 0 0110 20z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M11.38 16.666a.335.335 0 01-.285-.146.323.323 0 01.092-.46c1.107-.727 1.66-1.38 1.597-1.9-.096-.807-1.628-1.367-2.451-1.674a7.455 7.455 0 01-.47-.18.33.33 0 01-.178-.44.344.344 0 01.455-.166c.114.047.265.1.438.167 1.028.38 2.747 1.013 2.89 2.213.097.807-.523 1.633-1.894 2.533a.337.337 0 01-.193.053z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M12.758 20h-.028a.337.337 0 01-.316-.36c.004-.06.067-1.58-2.513-2.32-2.524-.72-3.805-1.533-3.915-2.487-.083-.726.514-1.44 1.776-2.12a.348.348 0 01.47.127c.092.16.033.36-.133.453-.97.526-1.476 1.047-1.427 1.467.042.36.542 1.1 3.425 1.92 3.142.9 3.012 2.927 3.005 3.013a.342.342 0 01-.344.307zM10.14 11.7c-.981-.42-2.428-1.36-2.572-2.147-.043-.234.039-.44.258-.646.469-.434.389-.921.102-1.214-.427-.44-1.314-.513-1.956.067-.582.527-.602 1.073-.517 1.44.345 1.473 3.026 2.74 4.27 3.12.034.013.07.013.104.013.029 0 .059 0 .086-.007.162.04.333-.04.402-.193a.323.323 0 00-.177-.433zM13.693 7.767a1.508 1.508 0 00-1.052-.434c-.487 0-.918.24-1.074.607-.137.32-.043.673.258.967.216.206.32.386.32.56-.002.34-.406.726-.835 1.133l-.173.167a.322.322 0 00-.052.406c.065.107.177.16.295.16.034 0 .07 0 .104-.014 1.515-.466 2.541-1.232 2.748-2.046.09-.36.072-.92-.539-1.506zM10 4.666c1.33 0 2.414-1.046 2.414-2.333C12.414 1.047 11.33 0 10 0 8.669 0 7.586 1.047 7.586 2.333c0 1.287 1.083 2.333 2.414 2.333z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M19.655 4.666c-.8 0-1.792-.506-2.752-1-.994-.513-1.933-1-2.765-1-.254 0-.542.04-.881.127a.345.345 0 00-.25.247C12.67 4.393 11.434 5.333 10 5.333c-1.362 0-2.548-.854-2.952-2.12a.334.334 0 00-.224-.22c-.695-.22-1.236-.327-1.652-.327-.847 0-1.668.514-2.461 1.014-.777.486-1.58.986-2.366.986-.17 0-.315.12-.34.287-.027.16.074.32.236.366.045.014 4.487 1.347 9.759 1.347s9.715-1.333 9.759-1.347a.329.329 0 00.237-.366.341.341 0 00-.34-.287z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
});
