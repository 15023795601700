import React from 'react';

import { activityLogUserType } from 'rides/types';
import { Atom, Link } from 'rides/components';

import { useTenantUrl } from 'rides/hooks/use-tenant';
import { useShowAdminUI } from 'rides/hooks/use-auth';

const ActivityLogUserEmail = ({ user }) => <Link mailTo={user.email}>{user.email}</Link>;

ActivityLogUserEmail.propTypes = {
  user: activityLogUserType,
};

const ActivityLogUserName = ({ user }) => <Atom>{user.name}</Atom>;

ActivityLogUserName.propTypes = {
  user: activityLogUserType,
};

const ByUserText = ({ user }) => {
  if (!user) return null;
  if (!user.email && !user.name) return null;

  const userUrl = useTenantUrl(`/admin/user/${user.id}`);
  const showAdminUI = useShowAdminUI();

  const linkContent = user.name ? (
    <ActivityLogUserName user={user} />
  ) : (
    <ActivityLogUserEmail user={user} />
  );

  return (
    <>
      by {showAdminUI && <Link to={userUrl}>{linkContent}</Link>}
      {!showAdminUI && linkContent}
    </>
  );
};

ByUserText.propTypes = {
  user: activityLogUserType,
};

export default ByUserText;
