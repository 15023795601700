import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { removeEmpty } from 'rides/utils/data';
import { Block } from 'rides/components';

const Well = ({ children, className, style, small, xs, ...props }) => {
  const classes = classNames(className, 'well', {
    wellSm: small,
  });

  const styles = removeEmpty({ ...style, padding: xs ? 2 : null });

  return (
    <Block className={classes} style={styles} {...props}>
      {children}
    </Block>
  );
};

Well.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  small: PropTypes.bool,
  xs: PropTypes.bool,
};

export default Well;
