import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import * as Yup from 'yup';
import moment from 'moment-timezone';
import { Formik, Form } from 'formik';

import { useCurrentTenantId } from 'rides/hooks/use-tenant';
import { MemberFormFields } from 'rides/components';

const tenantIdToExternalIdFormat = {
  // tenant id: { helpText, pattern }
  // BND
  85425: {
    helpText: 'Member ID format for Brand New Day is "1234567"',
    pattern: /\d{7}/,
    example: 'H12345678',
  },
  // Humana
  80121: {
    helpText: 'Member ID format for Humana is "H12345678"',
    pattern: /H\d{8}/,
    example: 'H12345678',
  },
  // Central
  95875581: {
    helpText: 'Member ID format for Central is "123456789012"',
    pattern: /\d{12}/,
    example: '123456789012',
  },
};

const getExternalIdFormat = tenantId => {
  const format = tenantIdToExternalIdFormat[tenantId];

  // no validation if none available for current tenant id
  if (!format) {
    return {
      validation: Yup.string(),
      helpText: null,
    };
  }

  return {
    validation: Yup.string()
      .required('Member ID is required!')
      .matches(
        format.pattern,
        `Member ID must be formated correctly (e.g. ${format.example})`,
      ),
    helpText: format.helpText,
  };
};

const serializeCheckboxValues = (obj = {}) => {
  return Object.entries(obj)
    .filter(([key, value]) => !!value)
    .map(([key]) => key);
};

const deserializeCheckboxValues = arr => {
  if (typeof arr?.map !== 'function') {
    // when non-array passed default to empty obj
    return {};
  }

  const entries = arr.map(field => [field, true]);
  return Object.fromEntries(entries);
};

const MemberFormFormik = ({ render, locked, member, onSubmitCallback }) => {
  const tenantId = useCurrentTenantId();
  const externalIdFormat = getExternalIdFormat(tenantId);

  const haData = R.pathOr({}, ['healthAttributes', 'data'], member);
  const healthAttributes = {
    ...haData,
    devicesAndEquipment: deserializeCheckboxValues(haData.devicesAndEquipment),
    systemLimitations: deserializeCheckboxValues(haData.systemLimitations),
    cognitionBehavior: deserializeCheckboxValues(haData.cognitionBehavior),
  };

  return (
    <Formik
      initialValues={{
        externalId: R.pathOr('', ['externalId'], member),
        firstName: R.pathOr('', ['firstName'], member),
        lastName: R.pathOr('', ['lastName'], member),
        middleName: R.pathOr('', ['middleName'], member),
        dateOfBirth: R.pathOr('', ['dateOfBirth'], member),
        phoneNumber: R.pathOr('', ['phoneNumber'], member),
        phoneNumberAlternate: R.pathOr('', ['phoneNumberAlternate'], member),
        location_id: R.pathOr(undefined, ['location', 'id'], member),
        location_autocomplete: R.pathOr('', ['location', 'autocomplete'], member),
        location_address1: R.pathOr('', ['location', 'address1'], member),
        location_address2: R.pathOr('', ['location', 'address2'], member),
        location_city: R.pathOr('', ['location', 'city'], member),
        location_state: R.pathOr('', ['location', 'state'], member),
        location_postalCode: R.pathOr('', ['location', 'postalCode'], member),
        planId: R.pathOr('', ['plan', 'id'], member),
        email: R.pathOr('', ['email'], member),
        languagesSpoken: R.pathOr('', ['languagesSpoken'], member),
        gender: R.pathOr('', ['gender'], member),
        notes: R.pathOr('', ['notes'], member),
        // Health Attributes
        healthAttributes,
      }}
      validationSchema={Yup.object().shape({
        externalId: externalIdFormat.validation,
        firstName: Yup.string().required('First name is required!'),
        lastName: Yup.string().required('Last name is required!'),
        middleName: Yup.string(),
        dateOfBirth: Yup.string()
          .required('Date of Birth is required!')
          .matches(
            /\d{4}-\d{2}-\d{2}/,
            'Date of Birth must be formated: YYYY-MM-DD (e.g. 1990-01-31)',
          )
          .test('is-valid date', '${value} is not a valid date.', value =>
            moment(value, 'YYYY-MM-DD', undefined, true).isValid(),
          ),
        // TODO setup validation for this
        phoneNumber: Yup.string().required('Contact number is required!'),
        phoneNumberAlternate: Yup.string(),
        location_id: Yup.string(),
        location_autocomplete: Yup.string(),
        location_address1: Yup.string(),
        location_address2: Yup.string(),
        location_city: Yup.string(),
        location_state: Yup.string(),
        // TODO setup validation for this
        location_postalCode: Yup.string(),
        planId: Yup.string(),
        email: Yup.string().email('Invalid email address.'),
        languagesSpoken: Yup.string(),
        gender: Yup.string(),
        notes: Yup.string(),
      })}
      onSubmit={(values, formikBag) => {
        const member = {
          externalId: values.externalId,
          firstName: values.firstName,
          lastName: values.lastName,
          middleName: values.middleName,
          dateOfBirth: values.dateOfBirth,
          phoneNumber: values.phoneNumber,
          phoneNumberAlternate: values.phoneNumberAlternate,
          location: {
            id: values.location_id,
            autocomplete: values.location_autocomplete,
            address1: values.location_address1,
            address2: values.location_address2,
            city: values.location_city,
            state: values.location_state,
            postalCode: values.location_postalCode,
          },
          planId: values.planId,
          email: values.email,
          languagesSpoken: values.languagesSpoken,
          gender: values.gender,
          notes: values.notes,
          healthAttributes: {
            version: 1,
            data: {
              ...values.healthAttributes,
              devicesAndEquipment: serializeCheckboxValues(
                values.healthAttributes.devicesAndEquipment,
              ),
              systemLimitations: serializeCheckboxValues(
                values.healthAttributes.systemLimitations,
              ),
              cognitionBehavior: serializeCheckboxValues(
                values.healthAttributes.cognitionBehavior,
              ),
            },
          },
        };
        onSubmitCallback && onSubmitCallback(member, formikBag);
      }}
      render={formikProps => {
        const disabled = !!formikProps.isSubmitting || locked;

        const manualWheelChair = R.path(
          ['values', 'healthAttributes', 'devicesAndEquipment', 'Manual Wheelchair'],
          formikProps,
        );
        const powerWheelChair = R.path(
          ['values', 'healthAttributes', 'devicesAndEquipment', 'Power Wheelchair'],
          formikProps,
        );

        return (
          <Form>
            {render({
              formFields: (
                <MemberFormFields
                  {...formikProps}
                  externalIdHelpText={externalIdFormat.helpText}
                  disabled={disabled}
                  manualWheelChairSizeDisabled={!manualWheelChair}
                  powerWheelChairSizeDisabled={!powerWheelChair}
                />
              ),
              formikProps,
            })}
          </Form>
        );
      }}
    />
  );
};

MemberFormFormik.propTypes = {
  locked: PropTypes.bool,
  member: PropTypes.object,
  render: PropTypes.func.isRequired,
  onSubmitCallback: PropTypes.func,
};

const MemberFormContainer = props => <MemberFormFormik {...props} />;

MemberFormContainer.propTypes = {
  locked: PropTypes.bool,
  member: PropTypes.object,
  render: PropTypes.func.isRequired,
  onSubmitCallback: PropTypes.func,
};

export default MemberFormContainer;
