import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Glyphicon, Input } from 'rides/components';

class SearchField extends Component {
  static propTypes = {
    value: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    inputProps: PropTypes.object,
  };

  static defaultProps = {
    inputProps: {},
    value: '',
  };

  state = {
    searchTerm: this.props.value,
  };

  handleChange = event => {
    this.setState({ searchTerm: event.target.value });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.onSubmit(this.state.searchTerm);
  };

  render() {
    const { searchTerm } = this.state;
    const { inputProps } = this.props;

    return (
      <form onSubmit={this.handleSubmit} className="input-group">
        <Input
          value={searchTerm}
          onChange={this.handleChange}
          className="form-control input-lg"
          {...inputProps}
        />
        <span className="input-group-addon">
          <Button type="submit" primary xs link>
            <Glyphicon icon="search" /> Search
          </Button>
        </span>
      </form>
    );
  }
}

export default SearchField;
