/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'JoinIcon',
  path: (
    <g fill="currentColor">
      <g clip-path="url(#clip0)">
        <path
          fill-rule="evenodd"
          d="M9.164 15.226c0 .427.346.774.774.774h4.28c.984 0 1.782-.809 1.782-1.806V1.806C16 .81 15.202 0 14.218 0h-4.28a.774.774 0 100 1.548h4.28c.14 0 .255.116.255.258v12.388a.256.256 0 01-.255.258h-4.28a.774.774 0 00-.774.774zM5.93 4.389l2.518 2.837H0v1.548h8.449l-2.518 2.837 1.135 1.036 3.665-4.13a.78.78 0 000-1.035L7.066 3.353 5.93 4.39z"
          clip-rule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 16 16',
});
