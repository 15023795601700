// CHANGE TENANT
export const TENANT_INIT_CHANGE = 'TENANT_INIT_CHANGE';

export const tenantChange = () => ({
  type: TENANT_INIT_CHANGE,
  meta: { thunk: true },
});

// LOAD TENANT LIST
export const TENANT_LOAD_LIST_REQUEST = 'TENANT_LOAD_LIST_REQUEST';

export const tenantLoadList = () => ({
  type: TENANT_LOAD_LIST_REQUEST,
  meta: { thunk: true },
});

// SET TENANT TIME_ZONE
export const TENANT_USE_TIME_ZONE = 'TENANT_USE_TIME_ZONE';

export const tenantUseTimezone = tenantId => ({
  type: TENANT_USE_TIME_ZONE,
  payload: { tenantId },
});
