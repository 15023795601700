import WeightIcon from './Weight/Weight';
import Vitals2Icon from './Vitals2/Vitals2';
import VendorsLgIcon from './VendorsLg/VendorsLg';
import VehicleIcon from './Vehicle/Vehicle';
import VanIcon from './Van/Van';
import UsersLgIcon from './UsersLg/UsersLg';
import UploadIcon from './Upload/Upload';
import UpdateIcon from './Update/Update';
import UnlockIcon from './Unlock/Unlock';
import TriangleDownIcon from './TriangleDown/TriangleDown';
import TimeLgIcon from './TimeLg/TimeLg';
import TasksIcon from './Tasks/Tasks';
import TaskFilledIcon from './TaskFilled/TaskFilled';
import TaskIcon from './Task/Task';
import StatusCircleIcon from './StatusCircle/StatusCircle';
import StarIcon from './Star/Star';
import SpinnerLgIcon from './SpinnerLg/SpinnerLg';
import SortIcon from './Sort/Sort';
import ShareIcon from './Share/Share';
import SettingsLgIcon from './SettingsLg/SettingsLg';
import ServiceRequestsXlIcon from './ServiceRequestsXl/ServiceRequestsXl';
import SearchIcon from './Search/Search';
import RetrivalIcon from './Retrival/Retrival';
import ProvidersLgIcon from './ProvidersLg/ProvidersLg';
import PlusIcon from './Plus/Plus';
import PlayIcon from './Play/Play';
import PlaceIcon from './Place/Place';
import PharmacyIcon from './Pharmacy/Pharmacy';
import OxygenIcon from './Oxygen/Oxygen';
import OfficeDrIcon from './OfficeDr/OfficeDr';
import NotesTabXlIcon from './NotesTabXl/NotesTabXl';
import NotesTabDefaultXlIcon from './NotesTabDefaultXl/NotesTabDefaultXl';
import NotesNumberXlIcon from './NotesNumberXl/NotesNumberXl';
import NotesEncounterXlIcon from './NotesEncounterXl/NotesEncounterXl';
import NotesEditXlIcon from './NotesEditXl/NotesEditXl';
import NotesActiveXlIcon from './NotesActiveXl/NotesActiveXl';
import NotesActiveLgIcon from './NotesActiveLg/NotesActiveLg';
import MobileIcon from './Mobile/Mobile';
import MenuLgIcon from './MenuLg/MenuLg';
import MembersLgIcon from './MembersLg/MembersLg';
import MemberXlIcon from './MemberXl/MemberXl';
import MedicalLgIcon from './MedicalLg/MedicalLg';
import MedicalIcon from './Medical/Medical';
import LogOutIcon from './LogOut/LogOut';
import LockIcon from './Lock/Lock';
import LiveLgIcon from './LiveLg/LiveLg';
import ListView3Icon from './ListView3/ListView3';
import ListView1Icon from './ListView1/ListView1';
import LessIcon from './Less/Less';
import LanguageIcon from './Language/Language';
import LandlineIcon from './Landline/Landline';
import LabsIcon from './Labs/Labs';
import JoinIcon from './Join/Join';
import InsuranceLgIcon from './InsuranceLg/InsuranceLg';
import IncomingCallIcon from './IncomingCall/IncomingCall';
import InboxXlIcon from './InboxXl/InboxXl';
import InboxNumberXlIcon from './InboxNumberXl/InboxNumberXl';
import ImpairedIcon from './Impaired/Impaired';
import HomeIcon from './Home/Home';
import HideIcon from './Hide/Hide';
import HeartOutlineIcon from './HeartOutline/HeartOutline';
import HeartIcon from './Heart/Heart';
import GymIcon from './Gym/Gym';
import GuerneyIcon from './Guerney/Guerney';
import GroceryIcon from './Grocery/Grocery';
import GridViewIcon from './GridView/GridView';
import FilterIcon from './Filter/Filter';
import FilesIcon from './Files/Files';
import FileIcon from './File/File';
import ExtendIcon from './Extend/Extend';
import ExpandTwoIcon from './ExpandTwo/ExpandTwo';
import ExpandIcon from './Expand/Expand';
import ErrorOutlineIcon from './ErrorOutline/ErrorOutline';
import ErrorDiscIcon from './ErrorDisc/ErrorDisc';
import ErrorIcon from './Error/Error';
import EncountersLgIcon from './EncountersLg/EncountersLg';
import EncounterTabDefaultXlIcon from './EncounterTabDefaultXl/EncounterTabDefaultXl';
import EncounterSuccessXlIcon from './EncounterSuccessXl/EncounterSuccessXl';
import EncounterNotesXlIcon from './EncounterNotesXl/EncounterNotesXl';
import EncounterDetailsXlIcon from './EncounterDetailsXl/EncounterDetailsXl';
import EncounterAlertXlIcon from './EncounterAlertXl/EncounterAlertXl';
import EllipsisVerticalFatIcon from './EllipsisVerticalFat/EllipsisVerticalFat';
import EllipsisVerticalIcon from './EllipsisVertical/EllipsisVertical';
import EllipsisHorizontalFatIcon from './EllipsisHorizontalFat/EllipsisHorizontalFat';
import EllipsisHorizontalIcon from './EllipsisHorizontal/EllipsisHorizontal';
import EditIcon from './Edit/Edit';
import DragAndDropIcon from './DragAndDrop/DragAndDrop';
import DownloadIcon from './Download/Download';
import DeviceIcon from './Device/Device';
import DeleteIcon from './Delete/Delete';
import DateRetrievalIcon from './DateRetrieval/DateRetrieval';
import DashboardIcon from './Dashboard/Dashboard';
import ConversationOutlineIcon from './ConversationOutline/ConversationOutline';
import ConversationIcon from './Conversation/Conversation';
import CognitionIcon from './Cognition/Cognition';
import CloseXIcon from './CloseX/CloseX';
import CloseCircleIcon from './CloseCircle/CloseCircle';
import CloseIcon from './Close/Close';
import ChevronUpIcon from './ChevronUp/ChevronUp';
import ChevronRightIcon from './ChevronRight/ChevronRight';
import ChevronLeftIcon from './ChevronLeft/ChevronLeft';
import ChevronDownIcon from './ChevronDown/ChevronDown';
import CheckCircleIcon from './CheckCircle/CheckCircle';
import CheckIcon from './Check/Check';
import CareCarIcon from './CareCar/CareCar';
import CalendarRecurringTripTabXlIcon from './CalendarRecurringTripTabXl/CalendarRecurringTripTabXl';
import CalendarLgIcon from './CalendarLg/CalendarLg';
import CalendarIcon from './Calendar/Calendar';
import BadgeIcon from './Badge/Badge';
import AvatarLgIcon from './AvatarLg/AvatarLg';
import AttachmentIcon from './Attachment/Attachment';
import ArrowUpIcon from './ArrowUp/ArrowUp';
import ArrowRightIcon from './ArrowRight/ArrowRight';
import ArrowLeftIcon from './ArrowLeft/ArrowLeft';
import ArrowExternalIcon from './ArrowExternal/ArrowExternal';
import ArrowDownIcon from './ArrowDown/ArrowDown';
import ArrowCircleUpIcon from './ArrowCircleUp/ArrowCircleUp';
import ArrowCircleRightIcon from './ArrowCircleRight/ArrowCircleRight';
import ArrowCircleLeftIcon from './ArrowCircleLeft/ArrowCircleLeft';
import AnalyticsLgIcon from './AnalyticsLg/AnalyticsLg';
import AmbulatoryIcon from './Ambulatory/Ambulatory';
import AgentIcon from './Agent/Agent';
import ActivityXlIcon from './ActivityXl/ActivityXl';
import ActivityLogXlIcon from './ActivityLogXl/ActivityLogXl';


const icons = {
  "weight": WeightIcon,
  "vitals-2": Vitals2Icon,
  "vendors-lg": VendorsLgIcon,
  "vehicle": VehicleIcon,
  "van": VanIcon,
  "users-lg": UsersLgIcon,
  "upload": UploadIcon,
  "update": UpdateIcon,
  "unlock": UnlockIcon,
  "triangle-down": TriangleDownIcon,
  "time-lg": TimeLgIcon,
  "tasks": TasksIcon,
  "task-filled": TaskFilledIcon,
  "task": TaskIcon,
  "status-circle": StatusCircleIcon,
  "star": StarIcon,
  "spinner-lg": SpinnerLgIcon,
  "sort": SortIcon,
  "share": ShareIcon,
  "settings-lg": SettingsLgIcon,
  "service-requests-xl": ServiceRequestsXlIcon,
  "search": SearchIcon,
  "retrival": RetrivalIcon,
  "providers-lg": ProvidersLgIcon,
  "plus": PlusIcon,
  "play": PlayIcon,
  "place": PlaceIcon,
  "pharmacy": PharmacyIcon,
  "oxygen": OxygenIcon,
  "office-dr": OfficeDrIcon,
  "notes-tab-xl": NotesTabXlIcon,
  "notes-tab-default-xl": NotesTabDefaultXlIcon,
  "notes-number-xl": NotesNumberXlIcon,
  "notes-encounter-xl": NotesEncounterXlIcon,
  "notes-edit-xl": NotesEditXlIcon,
  "notes-active-xl": NotesActiveXlIcon,
  "notes-active-lg": NotesActiveLgIcon,
  "mobile": MobileIcon,
  "menu-lg": MenuLgIcon,
  "members-lg": MembersLgIcon,
  "member-xl": MemberXlIcon,
  "medical-lg": MedicalLgIcon,
  "medical": MedicalIcon,
  "log-out": LogOutIcon,
  "lock": LockIcon,
  "live-lg": LiveLgIcon,
  "list-view-3": ListView3Icon,
  "list-view-1": ListView1Icon,
  "less": LessIcon,
  "language": LanguageIcon,
  "landline": LandlineIcon,
  "labs": LabsIcon,
  "join": JoinIcon,
  "insurance-lg": InsuranceLgIcon,
  "incoming-call": IncomingCallIcon,
  "inbox-xl": InboxXlIcon,
  "inbox-number-xl": InboxNumberXlIcon,
  "impaired": ImpairedIcon,
  "home": HomeIcon,
  "hide": HideIcon,
  "heart-outline": HeartOutlineIcon,
  "heart": HeartIcon,
  "gym": GymIcon,
  "guerney": GuerneyIcon,
  "grocery": GroceryIcon,
  "grid-view": GridViewIcon,
  "filter": FilterIcon,
  "files": FilesIcon,
  "file": FileIcon,
  "extend": ExtendIcon,
  "expand-two": ExpandTwoIcon,
  "expand": ExpandIcon,
  "error-outline": ErrorOutlineIcon,
  "error-disc": ErrorDiscIcon,
  "error": ErrorIcon,
  "encounters-lg": EncountersLgIcon,
  "encounter-tab-default-xl": EncounterTabDefaultXlIcon,
  "encounter-success-xl": EncounterSuccessXlIcon,
  "encounter-notes-xl": EncounterNotesXlIcon,
  "encounter-details-xl": EncounterDetailsXlIcon,
  "encounter-alert-xl": EncounterAlertXlIcon,
  "ellipsis-vertical-fat": EllipsisVerticalFatIcon,
  "ellipsis-vertical": EllipsisVerticalIcon,
  "ellipsis-horizontal-fat": EllipsisHorizontalFatIcon,
  "ellipsis-horizontal": EllipsisHorizontalIcon,
  "edit": EditIcon,
  "drag-and-drop": DragAndDropIcon,
  "download": DownloadIcon,
  "device": DeviceIcon,
  "delete": DeleteIcon,
  "date-retrieval": DateRetrievalIcon,
  "dashboard": DashboardIcon,
  "conversation-outline": ConversationOutlineIcon,
  "conversation": ConversationIcon,
  "cognition": CognitionIcon,
  "close-x": CloseXIcon,
  "close-circle": CloseCircleIcon,
  "close": CloseIcon,
  "chevron-up": ChevronUpIcon,
  "chevron-right": ChevronRightIcon,
  "chevron-left": ChevronLeftIcon,
  "chevron-down": ChevronDownIcon,
  "check-circle": CheckCircleIcon,
  "check": CheckIcon,
  "care-car": CareCarIcon,
  "calendar-recurring-trip-tab-xl": CalendarRecurringTripTabXlIcon,
  "calendar-lg": CalendarLgIcon,
  "calendar": CalendarIcon,
  "badge": BadgeIcon,
  "avatar-lg": AvatarLgIcon,
  "attachment": AttachmentIcon,
  "arrow-up": ArrowUpIcon,
  "arrow-right": ArrowRightIcon,
  "arrow-left": ArrowLeftIcon,
  "arrow-external": ArrowExternalIcon,
  "arrow-down": ArrowDownIcon,
  "arrow-circle-up": ArrowCircleUpIcon,
  "arrow-circle-right": ArrowCircleRightIcon,
  "arrow-circle-left": ArrowCircleLeftIcon,
  "analytics-lg": AnalyticsLgIcon,
  "ambulatory": AmbulatoryIcon,
  "agent": AgentIcon,
  "activity-xl": ActivityXlIcon,
  "activity-log-xl": ActivityLogXlIcon,
};


export default icons;