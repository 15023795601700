import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withProps } from 'recompose';

import { modalHide, resourceCreateRequest } from 'rides/store/actions';
import { fromApp } from 'rides/store/selectors';
import { TripConfirmVendorModal } from 'rides/components';

const mapDispatchToProps = {
  confirmVendor: tripId =>
    resourceCreateRequest(`trip/${tripId}/confirm_vendor`, null, 'trip'),
  modalHide,
};

const mapStateToProps = (state, props) => ({
  tripAction: fromApp.trip.actionFormsTripAction(state, props),
  memberId: fromApp.trip.actionFormsMemberId(state, props),
  tripId: fromApp.trip.actionFormsTripId(state, props),
});

const TripConfirmVendorModalContainer = props => <TripConfirmVendorModal {...props} />;

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withProps(props => ({
    onSubmit: (formikBag, closeModal) => {
      props.confirmVendor(props.tripId).then(
        // eslint-disable-next-line no-unused-vars
        resp => {
          closeModal();
        },
        error => {
          formikBag.setStatus({ error: error.message });
          formikBag.setSubmitting(false);
        },
      );
    },
  })),
)(TripConfirmVendorModalContainer);
