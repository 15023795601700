/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'ExpandIcon',
  path: (
    <g fill="currentColor">
      <g fill-rule="evenodd" clip-path="url(#clip0)" clip-rule="evenodd">
        <path d="M6.499 5.557L2.908 1.966h3.104a.666.666 0 100-1.333H1.298a.67.67 0 00-.667.667v4.713a.665.665 0 00.667.667.666.666 0 00.667-.667V2.91L5.556 6.5a.666.666 0 10.943-.943zM9.83 5.557l3.59-3.591h-3.104a.666.666 0 110-1.333h4.714a.67.67 0 01.667.667v4.713a.665.665 0 01-.667.667.666.666 0 01-.667-.667V2.91L10.772 6.5a.666.666 0 11-.943-.943zM5.557 9.829l-3.591 3.59v-3.104a.666.666 0 10-1.333 0v4.714a.67.67 0 00.667.667h4.713a.665.665 0 00.667-.667.666.666 0 00-.667-.666H2.91L6.5 10.77a.666.666 0 10-.943-.942zM10.77 9.829l3.592 3.59v-3.104a.666.666 0 111.333 0v4.714a.67.67 0 01-.667.667h-4.713a.665.665 0 01-.667-.667c0-.368.298-.666.667-.666h3.104L9.83 10.77a.666.666 0 11.942-.942z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h16v16H0z" transform="rotate(-180 8 8)" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 16 16',
});
