import * as React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as ridesTypes from 'rides/types';
import { fromAuth } from 'rides/store/selectors';
import TripAssignFilter from 'rides/nui/TripAssignFilter';

const mapStateToProps = (state, props) => ({
  currentUserId: fromAuth.userId(state, props),
});

const TripAssignFilterContainer = ({ ...props }) => {
  if (!props.showStaffUI) return null;

  return <TripAssignFilter {...props} />;
};

TripAssignFilterContainer.propTypes = {
  currentUserId: ridesTypes.userIdType.isRequired,
  showStaffUI: PropTypes.bool.isRequired,
  assigneeList: ridesTypes.tripAssigneeListType,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(TripAssignFilterContainer);
