/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'LockIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M8 1C5.929 1 4.25 2.71 4.25 4.818v2.037H4c-1.105 0-2 .911-2 2.036v4.073C2 14.088 2.895 15 4 15h8c1.105 0 2-.912 2-2.036V8.89c0-1.125-.895-2.036-2-2.036h-.25V4.818C11.75 2.71 10.071 1 8 1zm2.25 5.855V4.818c0-1.265-1.007-2.29-2.25-2.29s-2.25 1.025-2.25 2.29v2.037h4.5zM3.5 8.89c0-.281.224-.51.5-.51h8c.276 0 .5.229.5.51v4.073c0 .28-.224.509-.5.509H4a.505.505 0 01-.5-.51V8.892z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
