import React from 'react';
import { connect } from 'react-redux';

import { fromEntities } from 'rides/store/selectors';
import { tripGroupIdType } from 'rides/types';
import { TripLegList } from 'rides/components';

const TripLegListContainer = props => <TripLegList {...props} />;

TripLegListContainer.propTypes = {
  tripGroupId: tripGroupIdType.isRequired,
};

const mapStateToProps = (state, props) => {
  const tripGroupId = props.tripGroupId;
  const tripGroup = fromEntities.getDetail(state, 'tripGroup', tripGroupId);
  const tripList = fromEntities.getList(state, 'trip', tripGroup.trips);

  return {
    tripGroup,
    tripList,
  };
};

export default connect(mapStateToProps)(TripLegListContainer);
