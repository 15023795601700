/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'ChevronLeftIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M5.215 8.52a.735.735 0 010-1.04l6.263-6.264a.736.736 0 111.042 1.042L6.778 8l5.742 5.742a.736.736 0 11-1.042 1.042L5.215 8.52z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
