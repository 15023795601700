import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pending, rejected, fulfilled } from 'redux-saga-thunk';
import { withRouter } from 'react-router-dom';

import { fromEntities, fromResource } from 'rides/store/selectors';
import { modalShow, resourceListCreateRequest } from 'rides/store/actions';
import { MemberSearchPage } from 'rides/components';

const mapDispatchToProps = {
  search: searchParams =>
    resourceListCreateRequest(`member/search`, searchParams, 'memberSearch'),
  showCreateModal: () => modalShow('create-member'),
};

const mapStateToProps = state => ({
  memberListDone: fulfilled(state, `member/searchListCreate`),
  memberListFailed: rejected(state, 'member/searchListCreate'),
  memberListPending: pending(state, 'member/searchListCreate'),
  memberList: fromEntities.getList(
    state,
    'memberSearch', // <-- entityType
    fromResource.getList(state, 'member/search'),
  ),
});

class MemberSearchPageContainer extends Component {
  static propTypes = {
    memberList: PropTypes.array,
    memberListDone: PropTypes.bool,
    memberListFailed: PropTypes.bool,
    memberListPending: PropTypes.bool,
    search: PropTypes.func,
    setPageTitle: PropTypes.func,
    showCreateModal: PropTypes.func,
  };

  render() {
    const {
      memberList,
      memberListDone,
      memberListFailed,
      memberListPending,
      search,
      showCreateModal,
    } = this.props;

    return (
      <MemberSearchPage
        {...{
          memberList,
          memberListDone,
          memberListFailed,
          memberListPending,
          search,
          showCreateModal,
        }}
      />
    );
  }
}

export default R.compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(
  MemberSearchPageContainer,
);
