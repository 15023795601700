import * as R from 'ramda';
import * as React from 'react';
import { camelizeKeys } from 'humps';
import { useDispatch } from 'react-redux';
import { Button, PhoneNumber, Box } from '@carecar-evo/core-ui';
import { useDisclosure, VStack } from '@chakra-ui/react';
import { removeEmpty } from 'rides/evo/utils/data';
import { getMemberName } from 'rides/evo/utils/members';
import { modalHide, modalShow, resourceUpdateRequest } from 'rides/store/actions';
import { convertToReadableMonthDay } from 'rides/utils/dateTime';
import { PaneSectionHeader, PaneSectionTable, PaneSectionTableValueList } from './PaneSection';
import { fieldLabelTypoFixes } from 'rides/components/organisms/MemberHealthAttributesFormFields'

// ---
// --- Member Health Attributes
// ---


function useShowEditMemberModal(memberId) {
  const dispatch = useDispatch();
  const showEditMemberFn = React.useCallback(() => dispatch(modalShow('edit-member')), [dispatch]);
  const hideEditMemberFn = React.useCallback(() => dispatch(modalHide('edit-member')), [dispatch]);

  const updateMemberFn = React.useCallback(
    (member) => dispatch(resourceUpdateRequest('member', memberId, { member }, 'member')),
    [dispatch]
  );

  const onSubmitCallback = React.useCallback(
    (member, formikBag) => {
      dispatch(resourceUpdateRequest('member', memberId, { member }, 'member')).then(
        // updateMemberFn(member).then(
        // eslint-disable-next-line no-unused-vars
        resp => {
          // member is updated automatically in the store
          hideEditMemberFn();
        },
        error => {
          formikBag.setStatus({ error: error.message });
          formikBag.setSubmitting(false);
        },
      );
    },
    [dispatch]
  );

  return {
    hideEditMemberFn,
    showEditMemberFn,
    updateMemberFn,
    onSubmitCallback,
  }
}

function MemberPane({ trip, member, isLoaded }) {
  const insuranceDetailsSection = useDisclosure({ defaultIsOpen: true });
  const memberDetailsSection = useDisclosure({ defaultIsOpen: true });

  const memberId = member?.id
  const { showEditMemberFn } = useShowEditMemberModal(memberId)

  const camelizedMember = camelizeKeys(member);

  const memberName = getMemberName(member);
  const memberExternalId = member?.external_id ?? '';
  const memberDateOfBirth = member?.date_of_birth ?? '';
  const memberGender = member?.gender ?? '';

  const memberPhoneNumber = member?.phone_number ?? '';
  const memberPhoneNumberAlternate = member?.phone_number_alternate ?? '';
  // const memberLocation_id = member?.location?.id ?? '';
  // const memberLocation_autocomplete = member?.location?.autocomplete ?? '';
  const memberLocation_address1 = member?.location?.address1 ?? '';
  const memberLocation_address2 = member?.location?.address2 ?? '';
  const memberLocation_city = member?.location?.city ?? '';
  const memberLocation_state = member?.location?.state ?? '';
  const memberLocation_postalCode = member?.location?.postal_code ?? '';
  // const memberPlanId = member?.plan?.id ?? '';
  const memberPlanName = member?.plan?.name ?? '';
  // const memberEmail = member?.email ?? '';
  const memberLanguagesSpoken = member?.languages_spoken ?? '';
  // const memberNotes = member?.notes ?? '';

  const memberAddress = R.compose(
    R.join(', '),
    removeEmpty,
  )([
    memberLocation_address1,
    memberLocation_address2,
    memberLocation_city,
    `${memberLocation_state} ${memberLocation_postalCode}`,
  ]);

  const memberBenefitStartDate = convertToReadableMonthDay(
    member?.plan?.start_time ?? '',
  );

  const memberTripsAllowed = `${~~(member?.plan?.trips_allowed ?? 0)}`;
  const memberTripsCompleted = `${~~(member?.completed_trips ?? 0)}`;
  const memberTripsScheduled = `${~~(member?.scheduled_trips ?? 0)}`;

  // TODO replace ramda w/ ? ?? nullish coalise
  const healthAttributes = R.pathOr({}, ['health_attributes', 'data'], member);
  const haAmbulatoryStatus = R.pathOr('', ['ambulatory_status'], healthAttributes);
  const haDevicesAndEquipment = R.pathOr([], ['devices_and_equipment'], healthAttributes);
  const haManualWheelchairSize = R.pathOr('', ['manual_wheelchair_size'], healthAttributes);
  const haPowerWheelchairSize = R.pathOr('', ['power_wheelchair_size'], healthAttributes);
  const haVitalsHeightFeet = R.pathOr('', ['vitals_height_feet'], healthAttributes);
  const haVitalsHeightInches = R.pathOr('', ['vitals_height_inches'], healthAttributes);
  const haVitalsWeight = R.pathOr('', ['vitals_weight'], healthAttributes);
  const haSystemLimitations = R.pathOr([], ['system_limitations'], healthAttributes);
  const haCognitionBehavior = R.pathOr([], ['cognition_behavior'], healthAttributes);
  const haCognitionBehaviorFixed = haCognitionBehavior.map(fieldLabelTypoFixes);

  const hasManualWheelChair = R.contains('Manual Wheelchair', haDevicesAndEquipment);
  const manualWheelchairSize = hasManualWheelChair ? haManualWheelchairSize : '';

  const hasPowerWheelChair = R.contains('Power Wheelchair', haDevicesAndEquipment);
  const powerWheelchairSize = hasPowerWheelChair ? haPowerWheelchairSize : '';

  const memberHealthAttributes = [
    ["Ambulatory Status", `${haAmbulatoryStatus}`, !isLoaded],
    [
      "Devices & Equipment",
      <PaneSectionTableValueList values={haDevicesAndEquipment} />,
      !isLoaded,
    ],
    ["Manual Wheelchair Size", `${manualWheelchairSize}`, !isLoaded],
    ["Power Wheelchair Size", powerWheelchairSize ?? '', !isLoaded],
    //
    ['VITALS', '', !isLoaded],
    ["Height", `${haVitalsHeightFeet} ${haVitalsHeightInches}`, !isLoaded],
    ["Weight", `${haVitalsWeight}`, !isLoaded],
    [
      'System Limitations',
      <PaneSectionTableValueList values={haSystemLimitations} />,
      !isLoaded
    ],
    [
      "Cognition & Behavior",
      <PaneSectionTableValueList values={haCognitionBehaviorFixed} />,
      !isLoaded
    ],
    //
    [
      <Button
        key={`${memberId}-vitals_edit_button`}
        onClick={showEditMemberFn}
        css={{
          textTransform: "uppercase",
          // paddingBottom: '2rem'
        }}
        kind="link"
      >
        Edit Member
      </Button>,
      null,
      !isLoaded,
    ],
  ]

  return (
    <>
      <VStack
        px="24px"
        py="16px"
        borderTopWidth="1px"
        borderTopColor="lightGrey.100"
        borderBottomWidth="1px"
        borderBottomColor="lightGrey.100"
        spacing="5px"
        alignItems="stretch"
      >
        <PaneSectionHeader
          title="Member Details"
          isOpen={memberDetailsSection.isOpen}
          onOpen={memberDetailsSection.onOpen}
          onClose={memberDetailsSection.onClose}
        />
        <PaneSectionTable
          isOpen={memberDetailsSection.isOpen}
          rowData={[
            ['MEMBER NAME', memberName, !isLoaded],
            ['MEMBER ID', memberExternalId, !isLoaded],
            ['DOB', memberDateOfBirth, !isLoaded],
            [
              'GENDER',
              <Box key={`${memberId}-gender`} css={{ textTransform: 'capitalize' }}>
                {memberGender}
              </Box>,
              !isLoaded,
            ],
            ['HOME ADDRESS', memberAddress, !isLoaded],
            [
              'CONTACT NUMBER',
              <PhoneNumber
                key={`${memberId}-phone-alt`}
                phoneNumber={memberPhoneNumber}
              />,
              !isLoaded,
            ],
            [
              'CONTACT NUMBER (ALT)',
              <PhoneNumber
                key={`${memberId}-phone-alt`}
                phoneNumber={memberPhoneNumberAlternate}
              />,
              !isLoaded,
            ],
            [
              'LANGUAGE',
              <Box key={`${memberId}-languages_spoken`} css={{ textTransform: 'capitalize' }}>
                {memberLanguagesSpoken}
              </Box>,
              !isLoaded,
            ],
            [
              '',
              <Box
                key={`${memberId}-details_spacer_attrs`}
                css={{ height: '1rem' }}
              >
                {''}
              </Box>,
              !isLoaded,
            ],
          ]}
        />

        <PaneSectionHeader
          title="Health Attributes"
          isOpen={memberDetailsSection.isOpen}
          onOpen={memberDetailsSection.onOpen}
          onClose={memberDetailsSection.onClose}
          hideCollapseToggle
        />
        <PaneSectionTable
          isOpen={memberDetailsSection.isOpen}
          rowData={memberHealthAttributes}
          rightCss={{ minWidth: '48%' }}
          showRowBg
        />

        <PaneSectionHeader
          title="Insurance Details"
          isOpen={insuranceDetailsSection.isOpen}
          onOpen={insuranceDetailsSection.onOpen}
          onClose={insuranceDetailsSection.onClose}
        />
        <PaneSectionTable
          isOpen={insuranceDetailsSection.isOpen}
          rowData={[
            ['PLAN', memberPlanName, !isLoaded],
            ['BENEFIT PERIOD START DATE', memberBenefitStartDate, !isLoaded],
            ['TOTAL TRIPS THIS PERIOD (ONE-WAY)', memberTripsAllowed, !isLoaded],
            ['TRIPS USED SO FAR (ONE-WAY)', memberTripsCompleted, !isLoaded],
            ['TRIPS SCHEDULED (ONE-WAY)', memberTripsScheduled, !isLoaded],
          ]}
        />
      </VStack>
    </>
  );
}

export { MemberPane };
