import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fromConfirm } from 'rides/store/selectors';
import { confirmAnswerNo, confirmAnswerYes, confirmDismiss } from 'rides/store/actions';
import { Confirm } from 'rides/components';

const ConfirmContainer = props => <Confirm {...props} />;

const mapStateToProps = (state, props) => ({
  isShowing: fromConfirm.isShowing(state, props),
  title: fromConfirm.title(state, props),
  message: fromConfirm.message(state, props),
  noButtonText: fromConfirm.noButtonText(state, props),
  yesButtonText: fromConfirm.yesButtonText(state, props),
  danger: fromConfirm.danger(state, props),
  hideNoButton: fromConfirm.hideNoButton(state, props),
});

const mapDispatchToProps = dispatch => ({
  onAnswerNo: e => {
    if (e && e.preventDefault) e.preventDefault();
    dispatch(confirmAnswerNo());
  },
  onAnswerYes: e => {
    if (e && e.preventDefault) e.preventDefault();
    dispatch(confirmAnswerYes());
  },
  onDismiss: e => {
    if (e && e.preventDefault) e.preventDefault();
    dispatch(confirmDismiss());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmContainer);
