import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateTimePicker from 'react-datetime';

import {
  convertToReadableDate,
  createAware,
  getDate,
  updateDate,
} from 'rides/utils/dateTime';

class DatePicker extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    name: PropTypes.string,
    // handleChange: PropTypes.func.isRequired,
    // onChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    id: 'date',
    value: '',
    placeholder: '',
  };

  handleChange = value => {
    const correctedTz = updateDate(value, value);
    const dateValue = convertToReadableDate(correctedTz);
    // this.props.onChange(dateValue);
    // Formik specific
    this.props.setFieldValue(this.props.name, dateValue);
  };

  getNaiveValue = value => {
    if (!value) {
      return '';
    }

    return convertToReadableDate(value);
  };

  render() {
    const { id, value, placeholder } = this.props;

    return (
      <DateTimePicker
        dateFormat="M/D/YYYY"
        timeFormat={false}
        value={value}
        // value={this.getNaiveValue(value)}
        onChange={this.handleChange}
        inputProps={{ id, placeholder }}
        closeOnSelect={true}
      />
    );
  }
}

export default DatePicker;
