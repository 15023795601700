/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'ChevronUpIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        stroke-width=".5"
        d="M8.52 4.216a.736.736 0 00-1.04 0L1.215 10.48a.736.736 0 101.042 1.042L8 5.779l5.742 5.742a.735.735 0 001.042 0 .736.736 0 000-1.042L8.52 4.216z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
