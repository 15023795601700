import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Position, Popover } from 'evergreen-ui';

import * as ridesTypes from 'rides/types';
import { getEligibilityColor, getEligibilityIconName } from './utils';
import MemberEligibilityStatusPanel from './StatusPanel';

const NuiMemberEligibilityBadge = ({
  member,
  checkEligibility,
  isChecking,
  showStaffUI,
  showMemberEligibilityCheckUI,
}) => {
  if (!member) return null;

  const iconName = getEligibilityIconName(member.currentEligibility);
  const iconColor = getEligibilityColor(member.currentEligibility);

  return (
    <Popover
      content={({ close }) => (
        <MemberEligibilityStatusPanel
          member={member}
          checkEligibility={checkEligibility}
          isChecking={isChecking}
          close={close}
          showStaffUI={showStaffUI}
          showMemberEligibilityCheckUI={showMemberEligibilityCheckUI}
        />
      )}
      position={Position.BOTTOM_LEFT}
    >
      <IconButton appearance="minimal" icon={iconName} intent={iconColor} iconSize={20} />
    </Popover>
  );
};

NuiMemberEligibilityBadge.propTypes = {
  member: ridesTypes.memberType.isRequired,
  checkEligibility: PropTypes.func.isRequired,
  isChecking: PropTypes.bool.isRequired,
  showStaffUI: PropTypes.bool.isRequired,
  showMemberEligibilityCheckUI: PropTypes.bool.isRequired,
};

export default NuiMemberEligibilityBadge;
