import * as React from 'react';
import {
  chakra,
  Flex,
  SkeletonCircle,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useStyles,
  useTab,
  VisuallyHidden,
} from '@chakra-ui/react';
import Icon from 'rides/evo/components/Icon/Icon';

const tabStyles = {
  disabled: {
    bgColor: 'unset',
    color: '#e2e3e5',
  },
  hover: {
    bgColor: 'main.hoverLight',
  },
  // active: {
  //   bgColor: 'lightGrey.100',
  // },
  selected: {
    bgColor: 'main.hoverLight',
    color: 'general.darkBlue',
  },
};

// 1. Reuse the styles for the Tab
const StyledTab = chakra('button', { themeKey: 'Tabs.Tab' });

const PanelTab = React.forwardRef(({ label, icon, ...props }, ref) => {
  // 2. Reuse the `useTab` hook
  const tabProps = useTab(props);
  // const isSelected = !!tabProps['aria-selected'];
  // const isDisabled = !!tabProps['disabled'];

  // 3. Hook into the Tabs `size`, `variant`, props
  const styles = useStyles();

  if (!icon || typeof icon !== 'string') {
    throw new Error('DetailPanelTab requires an `icon` prop with a string value.');
  }

  return (
    <StyledTab
      ref={ref}
      __css={styles.tab}
      {...tabProps}
      rounded="full"
      marginRight="57px"
      padding="0"
      _disabled={{ '& > div': tabStyles.disabled }}
      _hover={{ '& > div': tabStyles.hover }}
      _selected={{ '& > div': tabStyles.selected, outline: 'none' }}
      color="general.blueDormant"
    >
      <Flex rounded="full" justifyContent="center" alignItems="center" boxSize="40px">
        <Icon width="23px" height="23px" name={icon} />
      </Flex>
      <VisuallyHidden>{label}</VisuallyHidden>
    </StyledTab>
  );
});
PanelTab.displayName = 'DisplayPanelTab';

function PanelTabList({ children, isLoaded, ...props }) {
  return (
    <TabList pt="7px" px="13px" pb="9px" {...props}>
      {React.Children.map(children, (child, i) => {
        return (
          <SkeletonCircle size="40px" isLoaded={isLoaded} marginRight="57px">
            {child}
          </SkeletonCircle>
        );
      })}
    </TabList>
  );
}
PanelTabList.displayName = 'DisplayPanelTabList';

const PanelTabPanels = props => <TabPanels {...props} pt="6px" />;
PanelTabPanels.displayName = 'DisplayPanelTabPanels';

const PanelTabPanel = props => <TabPanel {...props} />;
PanelTabPanel.displayName = 'DisplayPanelTabPanel';

const PanelTabs = props => <Tabs variant="unstyled" {...props} />;
PanelTabs.displayName = 'DisplayPanelTabs';

export { PanelTabs, PanelTabList, PanelTabPanels, PanelTabPanel, PanelTab };
