/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'MenuLgIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M0 19a1 1 0 011-1h18a1 1 0 110 2H1a1 1 0 01-1-1zM0 7a1 1 0 011-1h18a1 1 0 110 2H1a1 1 0 01-1-1zM0 13a1 1 0 011-1h11.636a1 1 0 010 2H1a1 1 0 01-1-1zM0 1a1 1 0 011-1h11.636a1 1 0 010 2H1a1 1 0 01-1-1z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
});
