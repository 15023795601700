/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'StatusCircleIcon',
  path: (
    <g fill="currentColor">
      <path d="M8 4a4 4 0 100 8 4 4 0 000-8z" />
    </g>
  ),
  viewBox: '0 0 16 16',
});
