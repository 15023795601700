/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'LanguageIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M9.527 10.737c.259-.831.412-1.675.457-2.51H6.018c.045.835.198 1.679.457 2.51h3.052zM9.664 5.215H6.338a9.187 9.187 0 00-.336 2.51h3.999a9.206 9.206 0 00-.337-2.51zM6.645 11.239c.327.89.778 1.761 1.355 2.582a11.216 11.216 0 001.355-2.582h-2.71zM9.507 4.713A8.135 8.135 0 008.002 2.08a8.127 8.127 0 00-1.506 2.632h3.01zM13.037 4.713a6.005 6.005 0 00-4.453-2.71 8.682 8.682 0 011.45 2.71h3.002zM5.819 5.215H2.613A5.912 5.912 0 002 7.725h3.502a9.716 9.716 0 01.317-2.51zM7.418 2c-1.944.176-3.573 1.206-4.523 2.713h3.07A8.667 8.667 0 017.419 2zM10.05 10.737h3.288c.39-.758.624-1.61.661-2.51h-3.514a10.801 10.801 0 01-.434 2.51zM10.5 7.725H14a5.966 5.966 0 00-.669-2.51h-3.147c.213.809.319 1.653.316 2.51zM5.518 8.227h-3.51c.05.902.29 1.752.681 2.51h3.263a10.804 10.804 0 01-.434-2.51zM2.977 11.239c.99 1.546 2.64 2.61 4.536 2.761a11.685 11.685 0 01-1.4-2.761H2.977zM8.488 13.998a6.02 6.02 0 004.56-2.76H9.887a11.696 11.696 0 01-1.399 2.76z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
