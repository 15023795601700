// theme.js
import { extendTheme } from '@chakra-ui/react';

// Global style overrides
import styles from './styles';

// Foundational style overrides
import borders from './foundations/borders';
import breakpoints from './foundations/breakpoints';
import colors from './foundations/colors';
import opacity from './foundations/opacity';
import radii from './foundations/radii';
import shadows from './foundations/shadows';
import sizes from './foundations/sizes';
import typography from './foundations/typography';
import zIndices from './foundations/z-indices';

// Component style overrides
// import Button from "./components/button"

const defaultTextColor = 'general.darkBlue';

const overrides = {
  // Global Styles
  styles,

  // Foundational Styles
  borders,
  breakpoints,
  colors,
  opacity,
  radii,
  shadows,
  sizes,
  zIndices,
  ...typography,

  textStyles: {
    h1: {
      fontSize: '32px',
      lineHeight: '40px',
      fontWeight: 'semibold',
      color: defaultTextColor,
    },
    h2: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 'normal',
      color: defaultTextColor,
    },
    h3: {
      fontSize: '20px',
      lineHeight: '32px',
      fontWeight: 'normal',
      color: defaultTextColor,
    },
    h4: {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: 'normal',
      color: defaultTextColor,
    },
    h5: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 'normal',
      color: defaultTextColor,
    },
    h6: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 'normal',
      color: defaultTextColor,
    },
    h7: {
      fontFamily: 'heading',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 'semibold',
      color: defaultTextColor,
    },

    microReg: {
      fontFamily: 'body',
      fontSize: '8px',
      lineHeight: '16px',
      fontWeight: 'normal',
      color: defaultTextColor,
    },
    microBold: {
      fontFamily: 'body',
      fontSize: '8px',
      lineHeight: '16px',
      fontWeight: 'semibold',
      color: defaultTextColor,
    },
    microBoldUpper: {
      fontFamily: 'body',
      fontSize: '8px',
      lineHeight: '16px',
      fontWeight: 'semibold',
      color: defaultTextColor,
      letterSpacing: '0.04em',
      textTransform: 'uppercase',
    },

    tripListCard: {
      memberName: {
        fontFamily: 'heading',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 'semibold',
        color: defaultTextColor,
      },
      locationTitle: {
        fontFamily: 'heading',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 'semibold',
        color: defaultTextColor,
      },
      locationDetail: {
        fontFamily: 'heading',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 'normal',
        color: defaultTextColor,
      },
      locationTime: {
        fontFamily: 'heading',
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: 'normal',
        color: defaultTextColor,
      },

      actionButton: {
        fontFamily: 'heading',
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 'semibold',
        color: defaultTextColor,
      },

      tripProgressStepAlpha: {
        fontFamily: 'body',
        fontSize: '8px',
        lineHeight: '8px',
        fontWeight: 'semibold',
        color: 'black',
        letterSpacing: '0.04em',
        textTransform: 'uppercase',
      },
      tripProgressStepDistance: {
        fontFamily: 'body',
        fontSize: '10px',
        lineHeight: '16px',
        fontWeight: 'semibold',
        color: 'black',
        letterSpacing: '0.04em',
        color: '#757575',
      },
    },
    detailPanel: {
      headerTitleText: {
        fontFamily: 'heading',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '32px',
        color: defaultTextColor,
      },
      headerSubTitleText: {
        fontFamily: 'heading',
        fontStyle: 'normal',
        fontWeight: 'light',
        fontSize: '14px',
        lineHeight: '14px',
        color: defaultTextColor,
        textTransform: 'uppercase',
      },
      headerSubTitleTextBolder: {
        fontFamily: 'heading',
        fontStyle: 'normal',
        fontWeight: 'semibold',
        fontSize: '14px',
        lineHeight: '14px',
        color: defaultTextColor,
        textTransform: 'uppercase',
      },

      paneSectionTitle: {
        fontFamily: 'heading',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '16px',
        color: defaultTextColor,
      },
      paneSectionTableField: {
        fontFamily: 'body',
        fontStyle: 'normal',
        fontWeight: 'semibold',
        fontSize: '10px',
        lineHeight: '24px',
        textTransform: 'uppercase',
        color: defaultTextColor,
      },
      paneSectionTableFieldNoUpper: {
        fontFamily: 'body',
        fontStyle: 'normal',
        fontWeight: 'semibold',
        fontSize: '10px',
        lineHeight: '24px',
        // textTransform: 'uppercase',
        color: defaultTextColor,
      },
      paneSectionTableValue: {
        fontFamily: 'body',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '18px',
        color: defaultTextColor,
      },
    },
  },

  // components: {
  //   Button,
  //   // Other components go here
  // },
};

export default extendTheme(overrides);
