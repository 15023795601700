import React from 'react';
import * as R from 'ramda';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ucwords } from 'rides/utils/format';
import { tripType } from 'rides/types';
import { Atom } from 'rides/components';

const TripStatus = ({ trip, showStaffUI, ...props }) => {
  const status = R.propOr('', 'status', trip);
  const statusText = ucwords(status);
  const statusClasses = classNames('label', {
    'label-success': status == 'completed',
    'label-primary':
      status == 'scheduled' ||
      status == 'vendor assigned' ||
      status == 'driver assigned' ||
      status == 'driver en route' ||
      status == 'driver arrived' ||
      status == 'in transit',
    'label-danger': status == 'cancelled',
  });

  return (
    <Atom {...props}>
      <Atom className={statusClasses} data-test-handle="trip status label">
        {statusText}
      </Atom>{' '}
      {showStaffUI && (
        <Atom>
          {trip.permissions.canConfirmVendor && !trip.isVendorConfirmed && (
            <Atom className="label label-warning">Vendor Not Confirmed</Atom>
          )}
        </Atom>
      )}{' '}
      {showStaffUI && !trip.isBillable && (
        <Atom className="label label-info" data-test-handle="non-billable label">
          Non-Billable
        </Atom>
      )}
    </Atom>
  );
};

TripStatus.propTypes = {
  trip: tripType.isRequired,
  showStaffUI: PropTypes.bool.isRequired,
};

export default TripStatus;
