import React from 'react';
import PropTypes from 'prop-types';

import { Modal, MemberForm } from 'rides/containers';
import { Block, Button } from 'rides/components';

const confirmAbandonMsg = `Are you sure you want to close the create member form?
Any data you entered will be discarded.`;

function confirmAbandonForm() {
  const shouldClose = confirm(confirmAbandonMsg);
  return shouldClose;
}

const CreateMemberModal = ({ onSubmitCallback }) => (
  <Modal
    title="Create Member"
    name="create-member"
    onClose={confirmAbandonForm}
    backdrop="static"
    closeable
  >
    <MemberForm
      onSubmitCallback={onSubmitCallback}
      render={({ formikProps, formFields }) => (
        <Block>
          {formFields}
          <Block textCenter>
            <Button type="submit" disabled={formikProps.isSubmitting} success>
              Save Member
            </Button>
          </Block>
        </Block>
      )}
    />
  </Modal>
);

CreateMemberModal.propTypes = {
  onSubmitCallback: PropTypes.func.isRequired,
};

export default CreateMemberModal;
