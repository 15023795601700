/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'MedicalIcon',
  path: (
    <g fill="currentColor">
      <g fill-rule="evenodd" clip-path="url(#clip0)" clip-rule="evenodd">
        <path d="M6.437 3.202h4.101V1.88a.442.442 0 00-.44-.44H6.878c-.242-.001-.44.199-.44.44v1.323zm4.394.588H6.144a.292.292 0 01-.292-.294V1.88c0-.564.46-1.029 1.025-1.029h3.222a1.03 1.03 0 011.025 1.029v1.617a.292.292 0 01-.293.294z" />
        <path d="M3.508 9.67c0-2.593 2.103-4.704 4.687-4.704S12.88 7.077 12.88 9.67c0 2.593-2.102 4.704-4.686 4.704s-4.687-2.11-4.687-4.704zm13.2-6.468H.292A.295.295 0 000 3.496v12.36c0 .165.13.294.293.294h16.414c.162 0 .293-.13.293-.294V3.496a.295.295 0 00-.293-.294z" />
        <path d="M10.83 10.846H9.367v1.47a.292.292 0 01-.293.294H7.316a.292.292 0 01-.293-.294v-1.47H5.558a.292.292 0 01-.293-.294V8.788c0-.159.132-.294.293-.294h1.465v-1.47c0-.159.131-.294.293-.294h1.757c.162 0 .293.135.293.294v1.47h1.465c.161 0 .292.135.292.294v1.764a.292.292 0 01-.292.294zM8.195 5.554c-2.26 0-4.1 1.846-4.1 4.116 0 2.27 1.84 4.116 4.1 4.116 2.261 0 4.101-1.846 4.101-4.116 0-2.27-1.84-4.116-4.1-4.116z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 16 16',
});
