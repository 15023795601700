import React from 'react';
import { path } from 'ramda';
import PropTypes from 'prop-types';

import { Field } from 'rides/components';

const FormikField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, setFieldValue, setValues }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  type,
  ...props
}) => {
  const fieldErrors = path(field.name.split('.'), errors);
  const fieldProps = {
    ...props,
    ...field,
    invalid: touched && !!fieldErrors,
    error: fieldErrors,
    type,
    setFieldValue,
    setValues,
    updateValues: updateFields => setValues({ ...values, ...updateFields }),
  };

  if (type === 'checkbox') {
    const valuePath = field?.name?.split('.');
    return <Field {...fieldProps} checked={!!path(valuePath, values)} />;
  }

  return <Field {...fieldProps} />;
};

FormikField.propTypes = {
  form: PropTypes.shape({
    touched: PropTypes.object,
    error: PropTypes.object,
    values: PropTypes.object,
  }).isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
  }).isRequired,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
};

export default FormikField;
