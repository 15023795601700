/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'CalendarRecurringTripTabXlIcon',
  path: (
    <g fill="currentColor">
      <mask id="a">
        <path
          fill-rule="evenodd"
          d="M22.233 20.317c0 1.057-.86 1.916-1.916 1.916H2.683a1.919 1.919 0 01-1.916-1.916V7.667h21.466v12.65zM20.317 2.3h-3.45V.383a.384.384 0 00-.767 0v3.834a.383.383 0 01-.767 0V2.3H6.9V.383a.383.383 0 00-.767 0v3.834a.383.383 0 01-.766 0V2.3H2.683A2.686 2.686 0 000 4.983v15.334A2.686 2.686 0 002.683 23h17.634A2.686 2.686 0 0023 20.317V4.983A2.686 2.686 0 0020.317 2.3z"
          clip-rule="evenodd"
        />
      </mask>
      <path
        fill-rule="evenodd"
        d="M22.233 20.317c0 1.057-.86 1.916-1.916 1.916H2.683a1.919 1.919 0 01-1.916-1.916V7.667h21.466v12.65zM20.317 2.3h-3.45V.383a.384.384 0 00-.767 0v3.834a.383.383 0 01-.767 0V2.3H6.9V.383a.383.383 0 00-.767 0v3.834a.383.383 0 01-.766 0V2.3H2.683A2.686 2.686 0 000 4.983v15.334A2.686 2.686 0 002.683 23h17.634A2.686 2.686 0 0023 20.317V4.983A2.686 2.686 0 0020.317 2.3z"
        clip-rule="evenodd"
      />
      <path
        d="M.767 7.667v-1h-1v1h1zm21.466 0h1v-1h-1v1zM16.867 2.3h-1v1h1v-1zm-1.534 0h1v-1h-1v1zM6.9 2.3h-1v1h1v-1zm-1.533 0h1v-1h-1v1zm15.866 18.017a.918.918 0 01-.916.916v2a2.918 2.918 0 002.916-2.916h-2zm-.916.916H2.683v2h17.634v-2zm-17.634 0a.919.919 0 01-.916-.916h-2a2.919 2.919 0 002.916 2.916v-2zm-.916-.916V7.667h-2v12.65h2zm-1-11.65h21.466v-2H.767v2zm20.466-1v12.65h2V7.667h-2zM20.317 1.3h-3.45v2h3.45v-2zm-2.45 1V.383h-2V2.3h2zm0-1.917c0-.764-.62-1.383-1.384-1.383v2a.616.616 0 01-.616-.617h2zM16.483-1C15.72-1 15.1-.38 15.1.383h2c0 .341-.276.617-.617.617v-2zM15.1.383V2.3h2V.383h-2zm0 1.917v1.917h2V2.3h-2zm0 1.917c0-.341.276-.617.617-.617v2c.764 0 1.383-.62 1.383-1.383h-2zm.617-.617c.34 0 .616.276.616.617h-2c0 .764.62 1.383 1.384 1.383v-2zm.616.617V2.3h-2v1.917h2zm-1-2.917H6.9v2h8.433v-2zM7.9 2.3V.383h-2V2.3h2zm0-1.917C7.9-.38 7.28-1 6.517-1v2A.617.617 0 015.9.383h2zM6.517-1C5.753-1 5.133-.38 5.133.383h2c0 .341-.276.617-.616.617v-2zM5.133.383V2.3h2V.383h-2zm0 1.917v1.917h2V2.3h-2zm0 1.917c0-.341.276-.617.617-.617v2c.764 0 1.383-.62 1.383-1.383h-2zM5.75 3.6c.34 0 .617.276.617.617h-2c0 .764.62 1.383 1.383 1.383v-2zm.617.617V2.3h-2v1.917h2zm-1-2.917H2.683v2h2.684v-2zm-2.684 0A3.686 3.686 0 00-1 4.983h2C1 4.056 1.756 3.3 2.683 3.3v-2zM-1 4.983v15.334h2V4.983h-2zm0 15.334A3.686 3.686 0 002.683 24v-2A1.686 1.686 0 011 20.317h-2zM2.683 24h17.634v-2H2.683v2zm17.634 0A3.686 3.686 0 0024 20.317h-2c0 .927-.756 1.683-1.683 1.683v2zM24 20.317V4.983h-2v15.334h2zm0-15.334A3.686 3.686 0 0020.317 1.3v2c.927 0 1.683.756 1.683 1.683h2z"
        mask="url(#a)"
      />
      <path
        fill-rule="evenodd"
        d="M16.938 15.377l-1.11-1.919a.455.455 0 00-.064-.087.458.458 0 00-.69.012l-1.471 1.757a.458.458 0 10.701.589l.668-.796c-.254 1.299-1.292 2.448-2.66 2.769a3.422 3.422 0 01-3.205-.906.457.457 0 10-.645.652 4.335 4.335 0 004.06 1.146c1.653-.387 2.923-1.742 3.306-3.305l.316.546a.459.459 0 00.794-.459zM9.398 13.39a.458.458 0 00-.645.056l-.79.942a3.462 3.462 0 01.498-1.695 3.66 3.66 0 012.284-1.684 3.434 3.434 0 013.365 1.074.458.458 0 10.687-.606 4.349 4.349 0 00-4.261-1.361 4.585 4.585 0 00-2.862 2.106c-.286.477-.472.99-.563 1.518l-.26-.433a.458.458 0 10-.786.472l1.174 1.954a.458.458 0 00.744.058l1.472-1.756a.458.458 0 00-.057-.646z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 23 23',
});
