import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ReactQueryDevtoolsPanel } from 'react-query/devtools';

import { StandardLayout } from 'rides/containers';
import { NotFoundPage } from 'rides/components';
import UserListPage from 'rides/admin-ui/pages/user-list';
import UserDetailPage from 'rides/admin-ui/pages/user-detail';

function AdminRoutes({ match }) {
  const { path } = match;

  return (
    <StandardLayout>
      <Switch>
        <Route path={`${path}/user`} component={UserListPage} exact />
        <Route path={`${path}/user/:userId`} component={UserDetailPage} exact />

        <Route component={NotFoundPage} />
      </Switch>
      <ReactQueryDevtoolsPanel />
    </StandardLayout>
  );
}

export default AdminRoutes;
