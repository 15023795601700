/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'UsersLgIcon',
  path: (
    <g fill="currentColor">
      <g fill-rule="evenodd" clip-path="url(#clip0)" clip-rule="evenodd">
        <path d="M18.897 6.87l-1.813-.518v-.634c.754-.568 1.25-1.51 1.25-2.576-.772.196-1.94.154-2.556-.348-.89.55-2.287.502-3.264.062-.008.095-.013.19-.013.286 0 1.066.495 2.008 1.25 2.576v.634l-1.814.519c-.619.176-1.103.819-1.103 1.462v.833h9.167v-.833c0-.644-.485-1.286-1.104-1.462zM15.54 1.93a.418.418 0 01.661.098c.253.469 1.513.432 2.025.279C17.886.978 16.757 0 15.418 0c-1.243 0-2.303.843-2.723 2.023.826.47 2.29.461 2.845-.092zM7.277 12.857c.826.468 2.291.46 2.844-.092a.415.415 0 01.662.096c.253.469 1.514.434 2.025.28-.34-1.329-1.469-2.308-2.808-2.308-1.243 0-2.303.843-2.723 2.024zM13.479 17.704l-1.813-.518v-.634c.754-.569 1.25-1.51 1.25-2.576-.772.196-1.94.154-2.556-.349-.89.551-2.287.503-3.264.063-.008.094-.013.19-.013.286 0 1.066.495 2.007 1.25 2.576v.634l-1.814.518c-.619.177-1.103.82-1.103 1.463V20h9.167v-.833c0-.645-.485-1.286-1.104-1.463zM9.167 8.333c0-.644-.485-1.286-1.104-1.462L6.25 6.352v-.634c.755-.568 1.25-1.51 1.25-2.576-.77.196-1.938.154-2.555-.347-.89.55-2.287.501-3.265.061-.007.095-.013.19-.013.286 0 1.066.496 2.008 1.25 2.576v.634l-1.814.519C.484 7.047 0 7.69 0 8.333v.833h9.167v-.833zM4.706 1.93a.417.417 0 01.661.098c.253.469 1.513.432 2.025.279C7.052.978 5.923 0 4.584 0 3.34 0 2.28.843 1.86 2.023c.826.47 2.29.461 2.845-.092zM5.71 12.794a.417.417 0 000-.589l-2.083-2.083a.417.417 0 00-.59.59l2.084 2.082a.414.414 0 00.59 0zM16.373 10.122l-2.083 2.083a.417.417 0 00.589.59l2.083-2.084a.417.417 0 00-.589-.59z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 20 20',
});
