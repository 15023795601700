import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import * as ridesTypes from 'rides/types';
import { Block, Column, FormikField, MaskableFormikField, Row } from 'rides/components';
import { TripWizardLocationAutocompleteField } from 'rides/containers';

const LocationAutofill = ({ name, label, disabled }) => {
  return (
    <Row>
      <Column xs={12}>
        <Field
          name={name}
          label={label}
          component={FormikField}
          disabled={disabled}
          type="checkbox"
        />
      </Column>
    </Row>
  );
};

LocationAutofill.propTypes = {
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
};

const TripWizardLocationFields = ({
  disabled,
  fieldPrefix = '',
  maskMemberHome,
  member,
  showHome,
  showRoundTrip,
}) => {
  const maskValues = maskMemberHome
    ? {
        name: 'Home',
        phoneNumber: R.pathOr('', ['location', 'phoneNumber'], member),
        address1: R.pathOr('', ['location', 'address1'], member),
        address2: R.pathOr('', ['location', 'address2'], member),
        city: R.pathOr('', ['location', 'city'], member),
        state: R.pathOr('', ['location', 'state'], member),
        postalCode: R.pathOr('', ['location', 'postalCode'], member),
      }
    : {};

  return (
    <Block>
      {showRoundTrip && (
        <LocationAutofill
          label="Original, Pick-Up Location (Round Trip)"
          name={`${fieldPrefix}isRoundTrip`}
          disabled={disabled}
        />
      )}

      {showHome && (
        <LocationAutofill
          label="Home Address"
          name={`${fieldPrefix}isHome`}
          disabled={disabled}
        />
      )}

      <Field
        locationPrefix={fieldPrefix}
        name={`${fieldPrefix}locationAutocomplete`}
        component={FormikField}
        type={TripWizardLocationAutocompleteField}
        disabled={disabled}
      />

      <Row>
        <Column xs={12} sm={6}>
          <Field
            name={`${fieldPrefix}name`}
            label="Location Name (Optional)"
            placeholder="name"
            component={MaskableFormikField}
            disabled={disabled}
            maskValue={maskValues.name}
          />
        </Column>
        <Column xs={12} sm={6}>
          <Field
            name={`${fieldPrefix}phoneNumber`}
            label="Location Phone Number (Optional)"
            placeholder="XXX-XXX-XXXX"
            component={MaskableFormikField}
            disabled={disabled}
            maskValue={maskValues.phoneNumber}
          />
        </Column>
      </Row>

      <Row>
        <Column xs={12} sm={6}>
          <Field
            name={`${fieldPrefix}address1`}
            label="Address 1"
            placeholder="street"
            component={MaskableFormikField}
            disabled={disabled}
            maskValue={maskValues.address1}
          />
        </Column>

        <Column xs={12} sm={6}>
          <Field
            name={`${fieldPrefix}address2`}
            label="Address 2"
            placeholder="apt #"
            component={MaskableFormikField}
            disabled={disabled}
            maskValue={maskValues.address2}
          />
        </Column>
      </Row>

      <Row>
        <Column xs={12} sm={6}>
          <Field
            name={`${fieldPrefix}city`}
            label="City"
            placeholder="city"
            component={MaskableFormikField}
            disabled={disabled}
            maskValue={maskValues.city}
          />
        </Column>

        <Column xs={3} sm={2}>
          <Field
            name={`${fieldPrefix}state`}
            label="State"
            placeholder="state"
            component={MaskableFormikField}
            disabled={disabled}
            maskValue={maskValues.state}
          />
        </Column>

        <Column xs={9} sm={4}>
          <Field
            name={`${fieldPrefix}postalCode`}
            label="Zip Code"
            placeholder="XXXXX"
            component={MaskableFormikField}
            disabled={disabled}
            maskValue={maskValues.postalCode}
          />
        </Column>
      </Row>
    </Block>
  );
};

TripWizardLocationFields.propTypes = {
  disabled: PropTypes.bool.isRequired,
  fieldPrefix: PropTypes.string,
  maskMemberHome: PropTypes.bool,
  member: ridesTypes.memberType,
  showHome: PropTypes.bool,
  showRoundTrip: PropTypes.bool,
};

TripWizardLocationFields.autofilledFieldNames = [
  'name',
  'phoneNumber',
  'address1',
  'address2',
  'city',
  'state',
  'postalCode',
];

export default TripWizardLocationFields;
