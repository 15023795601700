/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'InboxXlIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M17.625 18.5a1.877 1.877 0 01-1.875-1.875V13.25c0-2.73-1.68-5.213-4.202-6.244A2.607 2.607 0 009 5a2.644 2.644 0 00-2.55 2.006 6.777 6.777 0 00-4.2 6.244v3.375A1.877 1.877 0 01.375 18.5a.375.375 0 000 .75h17.25a.375.375 0 100-.75zM11.25 20h-4.5a.375.375 0 00-.375.375A2.628 2.628 0 009 23a2.628 2.628 0 002.625-2.625.375.375 0 00-.375-.375z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M17.625 18.5a1.877 1.877 0 01-1.875-1.875V13.25c0-2.73-1.68-5.213-4.202-6.244A2.607 2.607 0 009 5a2.644 2.644 0 00-2.55 2.006 6.777 6.777 0 00-4.2 6.244v3.375A1.877 1.877 0 01.375 18.5a.375.375 0 000 .75h17.25a.375.375 0 100-.75zM11.25 20h-4.5a.375.375 0 00-.375.375A2.628 2.628 0 009 23a2.628 2.628 0 002.625-2.625.375.375 0 00-.375-.375z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 23 23',
});
