import React from 'react';
import PropTypes from 'prop-types';

import { datetimeType, locationType } from 'rides/types';
import { useTenantUrl } from 'rides/hooks/use-tenant';
import {
  Atom,
  Block,
  DateTime,
  Emphasis,
  Link,
  Strong,
  TripWaypoints,
} from 'rides/components';
import { TripStatus } from 'rides/containers';

const TripLeg = ({ trip, ...props }) => {
  const { appointmentTime, pickupTime } = trip;
  const linkTo = useTenantUrl(
    `/member/${trip.member.id}/trip-group/${trip.groupId}#leg-${trip.id}`,
  );

  return (
    <Block {...props}>
      <Link to={linkTo}>Trip {trip.id}</Link>:
      <Strong>
        <DateTime datetime={pickupTime} showTime />

        {' pick-up '}
      </Strong>
      {appointmentTime && (
        <Atom>
          {' for '}
          <Emphasis>
            <DateTime datetime={appointmentTime} showTime />
          </Emphasis>
          {' appointment'}
        </Atom>
      )}{' '}
      <TripStatus trip={trip} />
      <Block>
        <TripWaypoints trip={trip} />
      </Block>
      <Block style={{ marginTop: '5px' }} />
    </Block>
  );
};

TripLeg.propTypes = {
  trip: PropTypes.shape({
    pickupTime: datetimeType.isRequired,
    pickup: locationType.isRequired,
    dropoff: locationType.isRequired,
  }),
};

export default TripLeg;
