import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';

import * as ridesTypes from 'rides/types';
import CurrentTimezone from 'rides/nui/containers/CurrentTimezone';
import {
  Block,
  Column,
  FormFeedbackBox,
  FormikField,
  HorizontalRule,
  Row,
  TripWizardFooter,
  TripWizardStepTitle,
  TripWizardPickupTimePicker,
} from 'rides/components';

/**
 * Build up the mask for the text-mask based input field. For more details see:
 * https://text-mask.github.io/text-mask/
 **/
const refNumTextMaskChar = /[ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxy23456789]/;
const refNumTextMask = R.repeat(refNumTextMaskChar, 7);

const TripWizardStepPickupTime = props => (
  <Formik
    initialValues={props.values}
    validationSchema={Yup.object().shape({
      pickupTime: Yup.string()
        .required('Requested Pick-Up Time is required.')
        .matches(/^\d{1,2}:\d{2} a|pm{1}$/, 'Must be (hh:mm am) format.'),
      groupReferenceNumber: Yup.string()
        .matches(
          /^[ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxy23456789]+$/,
          `Must use these characters: ABCDEFGHJKMNPQRSTUVWXYZ 23456789`,
        )
        .min(7, 'Must be exactly 7 characters')
        .max(7, 'Must be exactly 7 characters'),
    })}
    onSubmit={(values, formikBag) => {
      props.goToNextStep(values);
      formikBag.setSubmitting(false);
    }}
    render={formikProps => {
      const disabled = !!formikProps.isSubmitting || props.formDisabled;
      const isGroupRefNumFieldDisabled = !props.createMode || disabled;

      return (
        <Form>
          <Row>
            <Column xs={12} sm={10} smOffset={1}>
              <TripWizardStepTitle>Pickup Time</TripWizardStepTitle>

              {!disabled && props.stepErrors.pickupTime && (
                <FormFeedbackBox danger>{props.stepErrors.pickupTime}</FormFeedbackBox>
              )}

              <Row>
                <Column xs={12} sm={7} smOffset={3}>
                  <Field
                    name="pickupTime"
                    label="Requested Pick-Up Time"
                    component={FormikField}
                    disabled={disabled}
                    type={TripWizardPickupTimePicker}
                  />
                </Column>
              </Row>

              <Row>
                <Column xs={12} sm={6} smOffset={3}>
                  <Block textCenter>
                    Times are local to time zone{' '}
                    <i>
                      <CurrentTimezone />
                    </i>
                  </Block>
                </Column>
              </Row>

              <HorizontalRule style={{ marginBottom: 30, marginTop: 30 }} />

              <TripWizardStepTitle>Misc. Trip Details </TripWizardStepTitle>
              <Row>
                <Column xs={12}>
                  <Field
                    name="notes"
                    label="Notes"
                    component={FormikField}
                    disabled={disabled}
                    type="textarea"
                  />
                </Column>
              </Row>

              <Row>
                <Column xs={12}>
                  <Field
                    name="mapUrl"
                    label="Trip Map URL"
                    component={FormikField}
                    disabled={disabled}
                  />
                </Column>
              </Row>

              <Row>
                <Column xs={5}>
                  <Field
                    name="authCode"
                    label="Authorization Code (optional)"
                    component={FormikField}
                    disabled={disabled}
                  />
                </Column>
                <Column xs={5} xsOffset={2}>
                  <Field
                    name="groupReferenceNumber"
                    label="Reference Number (optional)"
                    component={FormikField}
                    disabled={isGroupRefNumFieldDisabled}
                    textMask={refNumTextMask}
                    guide={false}
                    pipe={conformedValue => `${conformedValue}`.toUpperCase()}
                  />
                </Column>
              </Row>
            </Column>
          </Row>

          <TripWizardFooter
            {...props}
            values={formikProps.values}
            disabled={disabled}
            showPrev
            showSubmit
          />
        </Form>
      );
    }}
  />
);

TripWizardStepPickupTime.propTypes = {
  currentStep: PropTypes.string,
  formDisabled: PropTypes.bool,
  goToNextStep: PropTypes.func.isRequired,
  goToPrevStep: PropTypes.func.isRequired,
  member: ridesTypes.memberType,
  stepErrors: PropTypes.object.isRequired,
  values: PropTypes.object,
  createMode: PropTypes.bool,
};

export default TripWizardStepPickupTime;
