import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { lifecycle, withProps } from 'recompose';

import { memberIdType, memberType, tripGroupType } from 'rides/types';
import { fromApp } from 'rides/store/selectors';
import { MemberDetailPage } from 'rides/components';
import {
  nothingUntilIsReady,
  spinnerWhileIsLoading,
} from 'rides/components/utils/showWhile';
import { memberLoadDetail, tripWizardInitNewTrip, modalShow } from 'rides/store/actions';

const mapDispatchToProps = {
  createNewTrip: memberId => tripWizardInitNewTrip(memberId),
  fetchMember: memberId => memberLoadDetail(memberId),
  editMember: memberId => modalShow('edit-member'),
};

const mapStateToProps = (state, props) => ({
  memberId: fromApp.member.currentMemberId(state, props),
  member: fromApp.member.currentMember(state, props),
  isFetchingMember: fromApp.member.isLoadingMemberDetail(state, props),
});

const MemberDetailPageContainer = props => <MemberDetailPage {...props} />;

MemberDetailPageContainer.propTypes = {
  onCreateTrip: PropTypes.func.isRequired,
  onEditMember: PropTypes.func.isRequired,
  memberId: memberIdType.isRequired,
  member: memberType.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withProps(props => ({
    isReady: props.member && !props.isFetchingMember && !props.isFetchingTripGroupList,
    isLoading: !props.member || props.isFetchingMember, // ||
    fetchData: memberId => {
      props.fetchMember(memberId);
    },
    onCreateTrip: e => {
      e.preventDefault();
      const { createNewTrip, memberId } = props;
      createNewTrip(memberId);
    },
    onEditMember: e => {
      e.preventDefault();
      const { editMember, memberId } = props;
      editMember(memberId);
    },
  })),
  lifecycle({
    componentWillMount() {
      const { fetchData, memberId } = this.props;
      fetchData(memberId);
    },
    componentWillReceiveProps(nextProps) {
      const { fetchData, memberId } = this.props;
      const nextMemberId = nextProps.memberId;

      if (nextMemberId !== memberId) {
        fetchData(nextMemberId);
      }
    },
  }),
  nothingUntilIsReady,
  spinnerWhileIsLoading,
)(MemberDetailPageContainer);
