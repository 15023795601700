import React from 'react';

import { userIdType } from 'rides/types';
import useEntityListLoaderPagination from 'rides/hooks/use-entity-list-loader-pagination';
import EntityListLoader from 'rides/components/utils/EntityListLoader';
import {
  ActivityLog,
  Button,
  FormFeedbackBox,
  Glyphicon,
  Heading,
  Well,
  Pagination,
} from 'rides/components';

const UserAdminActivityLog = ({ userId }) => {
  const [page, pageSize, getPaginationProps] = useEntityListLoaderPagination();

  return (
    <EntityListLoader
      loadOnMount
      resource={`user/${userId}/activity_log`}
      entityType="activityLog"
      requestParams={{ page_size: pageSize, page }}
      renderNotAsked={() => null}
      renderLoading={() => <FormFeedbackBox error="Loading..." warning />}
      renderError={() => <FormFeedbackBox error="Failed to load activity log" />}
      renderSuccess={items => <ActivityLog logList={items} />}
      /* key={page} */
    >
      {(View, loadData, { pagination, isLoading }) => {
        const paginationProps = getPaginationProps(pagination, isLoading);

        return (
          <Well>
            <Heading level={3} style={{ marginTop: 0 }}>
              User Activity{' '}
              <Button sm success onClick={loadData}>
                <Glyphicon icon="refresh" />
              </Button>
            </Heading>
            <Pagination {...paginationProps} />
            {View}
            <Pagination {...paginationProps} />
          </Well>
        );
      }}
    </EntityListLoader>
  );
};

UserAdminActivityLog.propTypes = {
  userId: userIdType.isRequired,
};

export default UserAdminActivityLog;
