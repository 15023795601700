import * as R from 'ramda';
import { createSelector } from 'reselect';

import { fromAuth } from 'rides/store/selectors';
import { currentTenant } from '../tenant/selectors';

export const showAdminUI = createSelector(
  [fromAuth.roles],
  R.contains('admin'),
);

export const showStaffUI = createSelector(
  [fromAuth.roles],
  R.contains('staff'),
);

export const hideForHumana = createSelector(
  [fromAuth.currentTenantId, showAdminUI, showStaffUI, fromAuth.roles],
  (tenantId, showAdmin, showStaff) => {
    const isHumana = tenantId === 80121;

    if (showStaff || showAdmin) {
      // never hide UI based on humana for staff and admin
      return false;
    }

    return isHumana;
  },
);

export const hideTripList = createSelector(
  [fromAuth.metadata],
  metadata => {
    return !!R.path(['hide_trip_list'], metadata);
  },
);

export const showMemberEligibilityUI = createSelector(
  [currentTenant],
  tenant => {
    return !!R.path(['showMemberEligibility'], tenant);
  },
);

export const showMemberEligibilityCheckUI = createSelector(
  [currentTenant],
  tenant => {
    const changeHealthcareSupport = !!R.path(['supportsChangeHealthcareEligibility'], tenant);
    return changeHealthcareSupport;
  },
);
