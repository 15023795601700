/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'ServiceRequestsXlIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M20.757 4.435L16.591.245a.83.83 0 00-1.179 0 .84.84 0 000 1.185l2.745 2.76H1.833A.835.835 0 001 5.027c0 .464.373.838.833.838h16.324l-2.745 2.76a.84.84 0 00.59 1.43.829.829 0 00.589-.246l4.166-4.188a.845.845 0 000-1.186zM1.243 18.565l4.166 4.19a.83.83 0 001.179 0 .84.84 0 000-1.185l-2.745-2.76h16.324c.46 0 .833-.375.833-.837a.835.835 0 00-.833-.838H3.843l2.745-2.76a.84.84 0 00-.59-1.43.829.829 0 00-.589.246l-4.166 4.188a.845.845 0 000 1.186z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 23 23',
});
