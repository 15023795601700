import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

const ModalComponent = ({
  children,
  isOpen,
  title,
  closeable,
  onHide,
  backdrop,
  keyboard,
}) => {
  const hasHeader = title || closeable;

  return (
    <Modal
      show={isOpen}
      onHide={onHide}
      bsSize="lg"
      backdrop={backdrop}
      keyboard={keyboard}
    >
      {hasHeader && (
        <Modal.Header closeButton={closeable}>
          {title && <Modal.Title>{title}</Modal.Title>}
        </Modal.Header>
      )}
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

ModalComponent.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.node,
  closeable: PropTypes.bool,
  onHide: PropTypes.func,
};

ModalComponent.defaultProps = {
  onHide: () => true,
};

export default ModalComponent;
