import * as R from 'ramda';

const statusGroups = {
  scheduled: [
    //
    'scheduled',
    'vendor assigned',
    'driver assigned',
  ],
  active: [
    //
    'driver en route',
    'driver arrived',
    'in transit',
    'driver failed',
  ],
  completed: ['completed', 'cancelled'],
};

const statusGroupMap = {
  ...statusGroups,
  active_or_scheduled: [...statusGroups.active, ...statusGroups.scheduled],
};

const tripStatusList = [
  { id: 'driver failed', name: 'Driver Failed' },
  { id: 'scheduled', name: 'Scheduled' },
  { id: 'vendor assigned', name: 'Vendor Assigned' },
  { id: 'driver assigned', name: 'Driver Assigned' },
  { id: 'driver en route', name: 'Driver En Route' },
  { id: 'driver arrived', name: 'Driver Arrived' },
  { id: 'in transit', name: 'In Transit' },
  { id: 'completed', name: 'Completed' },
  { id: 'cancelled', name: 'Cancelled' },
];

export function useTripStatusList(statusGroup = '') {
  const groupStatusList = statusGroupMap[statusGroup];
  const isValidStatusGroup = !!groupStatusList;

  if (!isValidStatusGroup) {
    // all statuses allowed
    return tripStatusList;
  }

  const filteredTripStatusList = tripStatusList.filter(item => {
    const isAllowed = R.contains(item.id, groupStatusList);
    return isAllowed;
  });

  return filteredTripStatusList;
}
