import * as R from 'ramda';

import { initialState } from './selectors';
import {
  TRIP_WIZARD_INIT_CANCEL_TRIP,
  TRIP_WIZARD_INIT_EDIT_TRIP,
  TRIP_WIZARD_INIT_NEW_TRIP,
  TRIP_WIZARD_INIT_NEW_TRIP_LEG,
  TRIP_WIZARD_SET_CURRENT_STEP,
  TRIP_WIZARD_SET_FORM_DISABLED,
  TRIP_WIZARD_SET_FORM_NOT_DISABLED,
  TRIP_WIZARD_SET_MEMBER,
  TRIP_WIZARD_SET_STEP_ERRORS,
  TRIP_WIZARD_UPDATE_VALUES,
} from './actions';

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TRIP_WIZARD_INIT_NEW_TRIP: {
      return {
        ...initialState,
        memberId: payload.memberId,
      };
    }

    case TRIP_WIZARD_INIT_NEW_TRIP_LEG: {
      return {
        ...initialState,
        memberId: payload.memberId,
        tripGroupId: payload.tripGroupId,
      };
    }

    case TRIP_WIZARD_INIT_EDIT_TRIP: {
      return {
        ...initialState,
        memberId: payload.memberId,
        tripId: payload.tripId,
      };
    }

    case TRIP_WIZARD_INIT_CANCEL_TRIP: {
      return {
        ...initialState,
        currentStep: 'cancel-trip',
        memberId: payload.memberId,
        tripId: payload.tripId,
      };
    }

    case TRIP_WIZARD_SET_FORM_NOT_DISABLED: {
      return R.assoc('formDisabled', false, state);
    }

    case TRIP_WIZARD_SET_FORM_DISABLED: {
      return R.assoc('formDisabled', true, state);
    }

    case TRIP_WIZARD_SET_CURRENT_STEP: {
      return R.assoc('currentStep', payload.step, state);
    }

    case TRIP_WIZARD_SET_MEMBER: {
      return R.assoc('member', payload.member, state);
    }
    case TRIP_WIZARD_SET_STEP_ERRORS: {
      return R.assoc('stepErrors', payload.stepErrors, state);
    }

    case TRIP_WIZARD_UPDATE_VALUES: {
      return {
        ...state,
        values: R.merge(state.values, payload.values),
      };
    }

    default:
      return state;
  }
};
