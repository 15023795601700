import { useMutation, useQuery, useQueryClient } from 'react-query';

import { client } from 'rides/evo/utils/api-client';

function useDuplicateTripGroup(options) {
  // const client = useClient();
  // const queryClient = useQueryClient();

  return useMutation(
    ({ tripId, newDate }) =>
      client('trip_group/duplicate', {
        data: { trip_id: tripId, new_date: newDate },
      }).then(resp => {
        console.log('resp', { resp });
        return resp.data;
      }),
    {
      // onSettled: () => queryClient.invalidateQueries('list-items'),
      enabled: false,
      // notifyOnChangeProps: ['data', 'error'],
      ...options,
    },
  );
}

export { useDuplicateTripGroup };
