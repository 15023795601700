import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pending } from 'redux-saga-thunk';
import { withRouter } from 'react-router-dom';

import { tripType } from 'rides/types';
import { fromEntities, fromResource } from 'rides/store/selectors';
import { resourceListReadRequest } from 'rides/store/actions';

import { ActiveTripListPage, Spinner } from 'rides/components';

const mapDispatchToProps = dispatch => ({
  fetchActiveTripList: (options = {}) =>
    dispatch(resourceListReadRequest('trip', options, 'trip')),
});

const mapStateToProps = state => {
  const tripResource = 'trip';
  const tripThunkId = `${tripResource}ListRead`;

  const rootTripIds = fromResource.getList(state, tripResource);
  const tripList = fromEntities.getList(state, 'trip', rootTripIds);

  return {
    isLoading: !tripList || pending(state, tripThunkId),
    tripList,
  };
};

class ActiveTripListPageContainer extends React.Component {
  state = { keywords: '' };

  componentWillMount() {
    this.fetchTrips();
  }

  fetchTrips(opts) {
    const options = {
      page_size: 2000,
      filter: 'active',
      ...opts,
    };

    this.props.fetchActiveTripList(options);
  }

  handleSearch = keywords => {
    this.fetchTrips({ keywords });
    this.setState({ keywords });
  };

  setSearchResults = (searchTerm, searchResults) => {
    // ignore results for old terms, i.e. ignore out of order ajax results
    if (searchTerm === this.state.searchTerm) {
      this.setState({ searchResults, searchLoading: false });
    }
  };

  resetSearchState = () => {
    this.setState({
      searchTerm: null,
      searchResults: null,
      searchLoading: false,
    });
  };

  // loadPlaceList = debounce(searchTerm => {
  //   if (searchTerm) {
  //     this.props
  //       .fetchPlaceList(searchTerm)
  //       .then(response => this.setSearchResults(searchTerm, response));
  //   }
  // }, 500);

  render() {
    const { isLoading, tripList } = this.props;
    const { keywords } = this.state;

    if (isLoading) return <Spinner />;

    return (
      <ActiveTripListPage
        tripList={tripList}
        keywords={keywords}
        onSubmitSearch={this.handleSearch}
      />
    );
  }
}

ActiveTripListPageContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  fetchActiveTripList: PropTypes.func.isRequired,
  tripList: PropTypes.arrayOf(tripType),
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ActiveTripListPageContainer);
