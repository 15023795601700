import * as React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Button, Pane, SearchInput, SelectMenu } from 'evergreen-ui';

// import * as ridesTypes from 'rides/types';
import { Box, Flex, Text } from 'rides/nui';
import FilterTagBar from './filter-tag-bar';

const paramKeywords = R.pathOr('', ['keywords']);
const anyHasPending = R.any(R.propEq('hasPending', true));

const filterSelectMenuOptionType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
});

const filterSelectMenuOptionListType = PropTypes.arrayOf(filterSelectMenuOptionType);

const indexOptionsByValue = R.indexBy(R.prop('value'));

export function useSearchPanelSelectFilter({
  // shared search params props
  params,
  nextParams,
  setNextParams,
  // applyNextParams,

  // filter specific props
  label = '[Filter]',
  selectLabel = label,
  tagBarLabel = label,
  paramKey = undefined,
  getFilterValueFn = (key, defaultValue) => R.pathOr(defaultValue, [key]),
  defaultValue = [],
  options = [],
  disabled = false,
  tagProps,
}) {
  const getFilterValue = getFilterValueFn(paramKey, defaultValue);

  const selected = getFilterValue(params);
  const nextSelected = getFilterValue(nextParams);
  const pendingSelected = R.symmetricDifference(selected, nextSelected);
  const numPendingChanges = pendingSelected.length;
  const hasPending = numPendingChanges > 0;

  // --
  // -- Select Menu Props
  // --

  const selectMenuOnSelect = React.useCallback(
    item => setNextParams({ [paramKey]: [...nextSelected, item.value] }),
    [setNextParams, paramKey, nextSelected],
  );

  const selectMenuOnDeselect = React.useCallback(
    ({ value }) => setNextParams({ [paramKey]: R.without([value], nextSelected) }),
    [setNextParams, paramKey, nextSelected],
  );

  const selectMenuProps = React.useMemo(
    () => ({
      label: selectLabel,
      options,
      selected: nextSelected,
      onDeselect: selectMenuOnDeselect,
      onSelect: selectMenuOnSelect,
      disabled,
    }),
    [
      options,
      nextSelected,
      selectLabel,
      selectMenuOnDeselect,
      selectMenuOnSelect,
      disabled,
    ],
  );

  // --
  // -- Tag Bar Props
  // --

  const optionsMap = React.useMemo(() => {
    return indexOptionsByValue(options);
  }, [options]);

  const renderTagLabel = React.useCallback(
    ({ tagKey }) => {
      return optionsMap[tagKey].label;
    },
    [optionsMap],
  );

  const removeTag = React.useCallback(
    tagKey => setNextParams({ [paramKey]: R.without([tagKey], nextSelected) }),
    [setNextParams, paramKey, nextSelected],
  );

  const tagBarProps = React.useMemo(
    () => ({
      label: tagBarLabel,
      tagList: selected,
      nextTagList: nextSelected,
      renderTagLabel,
      removeTag,
      disabled,
      tagProps,
    }),
    [tagBarLabel, selected, nextSelected, renderTagLabel, removeTag, disabled, tagProps],
  );

  return {
    paramKey,
    options,
    optionsMap,
    selected,
    nextSelected,
    pendingSelected,
    hasPending,
    // component prop objects
    selectMenuProps,
    tagBarProps,
  };
}

const AdminToolsSearchPanel = ({
  params,
  nextParams,
  setNextParams,
  applyNextParams,
  searchInputParamKey = 'keywords',
  selectFilters = [],
}) => {
  const handleChange = React.useCallback(
    event => setNextParams({ [searchInputParamKey]: `${event.target.value}` }),
    [setNextParams, searchInputParamKey],
  );

  const handleApplyParams = React.useCallback(
    event => {
      if (event) event.preventDefault();
      applyNextParams();
      return false;
    },
    [applyNextParams],
  );

  // Keywords
  const nextKeywords = paramKeywords(nextParams);
  const keywords = paramKeywords(params);

  const hasPending = anyHasPending(selectFilters);

  return (
    <Pane
      padding={4}
      marginBottom={14}
      borderRadius={3}
      tint="blueTint"
      elevation={1}
      background="tint1"
    >
      <Box px="2px" className=".block-focus-outline-fix">
        <Pane
          display="flex"
          flexWrap="wrap"
          padding={16}
          background="tint2"
          borderRadius={3}
        >
          <Pane display="flex">
            <Pane alignItems="center" display="flex">
              <form onSubmit={handleApplyParams}>
                <SearchInput
                  placeholder="Search"
                  width={600}
                  height={40}
                  value={nextKeywords}
                  onChange={handleChange}
                />
              </form>
            </Pane>
            <Pane marginLeft={8} display="flex" alignItems="center">
              <Button
                appearance="primary"
                height={36}
                marginLeft={8}
                type="submit"
                onClick={handleApplyParams}
              >
                Search
              </Button>
            </Pane>
          </Pane>
          <Pane width="100%">
            <SearchBoxFilters
              setParams={setNextParams}
              onApplyFilters={handleApplyParams}
              disabled={!hasPending}
            >
              {selectFilters.map(({ selectMenuProps }, i) => {
                return (
                  <React.Fragment key={i}>
                    <Pane>
                      <FilterSelectMenu {...selectMenuProps} />
                    </Pane>
                    <Box width={6} />
                  </React.Fragment>
                );
              })}
            </SearchBoxFilters>
          </Pane>
        </Pane>
        <Flex
          width="800px"
          borderRadius="3px"
          alignItems="center"
          justifyContent="stretch"
          color="mediumGray"
        />
        <Pane paddingY={4} paddingLeft={15} borderRadius={3} tint="blueTint">
          {true && (
            <Text lineHeight="15px" fontSize={13} style={{ minHeight: 23 }}>
              Search results for {keywords && `"${keywords}”`}
            </Text>
          )}
          <Pane>
            <Box
              style={{
                display: 'grid',
                gridTemplateColumns: `max-content 1fr`,
                gridRowGap: '4px',
              }}
            >
              {selectFilters.map(({ tagBarProps }, i) => (
                <FilterTagBar {...tagBarProps} key={i} />
              ))}
            </Box>
          </Pane>
        </Pane>
      </Box>
    </Pane>
  );
};
AdminToolsSearchPanel.propTypes = {
  // tenantOptions: filterSelectMenuOptionListType,
  params: PropTypes.object,
  nextParams: PropTypes.object,
  setNextParams: PropTypes.func.isRequired,
  applyNextParams: PropTypes.func.isRequired,
  searchInputParamKey: PropTypes.string,
  selectFilters: PropTypes.arrayOf(PropTypes.object),
};
AdminToolsSearchPanel.defaultProps = {
  params: {},
  nextParams: {},
  // tenantOptions: [],
};

export default AdminToolsSearchPanel;

const SearchBoxFilters = ({ children, onApplyFilters, disabled }) => {
  return (
    <Pane display="flex" alignItems="center">
      {children}
      <Pane>
        <Button
          marginTop={8}
          paddingX={8}
          marginX={4}
          height={24}
          appearance="primary"
          onClick={onApplyFilters}
          disabled={disabled}
        >
          Apply
        </Button>
      </Pane>
    </Pane>
  );
};
SearchBoxFilters.propTypes = {
  children: PropTypes.node,
  onApplyFilters: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
SearchBoxFilters.defaultProps = {
  children: null,
  onApplyFilters: () => {},
  disabled: false,
};

const FilterSelectMenu = ({
  label,
  options,
  selected,
  onSelect,
  onDeselect,
  disabled,
}) => {
  return (
    <SelectMenu
      options={options}
      selected={selected}
      onSelect={onSelect}
      onDeselect={onDeselect}
      disabled={disabled}
      marginRight={4}
      hasLabel={false}
      isMultiSelect
    >
      <Button height={28} marginTop={8} iconAfter="caret-down">
        {label}
      </Button>
    </SelectMenu>
  );
};
FilterSelectMenu.propTypes = {
  label: PropTypes.string.isRequired,
  options: filterSelectMenuOptionListType,
  selected: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func.isRequired,
  onDeselect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
FilterSelectMenu.defaultProps = {
  options: [],
  selected: [],
  disabled: false,
};
