/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'CareCarIcon',
  path: (
    <g fill="currentColor">
      <path d="M15.825 11.605a.54.54 0 00-.379-.348l-.029.097.028-.098a.566.566 0 00-.625.233c-1.388 2.2-3.935 3.53-6.648 3.47-3.254-.07-6.07-2.083-7.015-5.01a.656.656 0 01.032-.498.695.695 0 01.478-.364c.365-.08.732.118.838.451.758 2.388 3.049 4.032 5.7 4.09 1.83.04 3.566-.686 4.764-1.993a1.63 1.63 0 00.208-1.962 1.713 1.713 0 00-.723-.66c-.776-.381-1.697-.204-2.221.38-.05.056-.435.543-1.306.761a2.213 2.213 0 01-.206.024.53.53 0 00-.498.514c0 .295.264.53.578.515l.065-.003c.865-.102 1.382-.463 1.382-.463h-.003c.296-.176.568-.391.802-.646a.748.748 0 01.963-.123.647.647 0 01.224.246c.13.25.095.52-.087.72-.98 1.083-2.408 1.685-3.916 1.652-2.142-.047-4.019-1.397-4.67-3.36l-.009-.025c-.271-.834-1.186-1.335-2.083-1.14l-.025.005c-.486.106-.922.376-1.182.776a1.563 1.563 0 00-.19 1.337c1.063 3.395 4.308 5.732 8.074 5.815 3.107.068 6.023-1.45 7.617-3.965a.505.505 0 00.063-.428zM15.733 3.917C14.17 1.489 11.437.024 8.425 0 4.615-.03 1.193 2.34.095 5.765a.506.506 0 00.013.359.544.544 0 00.37.306.558.558 0 00.673-.355c.953-2.992 3.94-5.063 7.265-5.036 2.635.02 5.022 1.305 6.383 3.434.114.178.13.391.045.585a.7.7 0 01-.522.402.742.742 0 01-.752-.321c-1.106-1.716-3.036-2.751-5.166-2.768-2.316-.019-4.48 1.23-5.514 3.183a1.596 1.596 0 00.06 1.612c.243.39.659.659 1.124.776l.013.003a1.848 1.848 0 002.098-.95 2.323 2.323 0 01.204-.3c.236-.29.544-.523.89-.68.175-.073.345-.117.49-.144.258-.048.447-.255.447-.501v-.037c0-.326-.32-.56-.66-.498a3.33 3.33 0 00-.995.362l.002.003a3.468 3.468 0 00-1.39 1.368.744.744 0 01-.74.382.708.708 0 01-.554-.39.618.618 0 01.013-.563c.862-1.611 2.587-2.603 4.502-2.588 1.752.014 3.338.868 4.242 2.285.435.681 1.31.987 2.136.726.44-.14.813-.44 1.024-.829a1.614 1.614 0 00-.063-1.674z" />
    </g>
  ),
  viewBox: '0 0 16 16',
});
