/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'CognitionIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M11.333 6h-.794l-.574 1.15a.33.33 0 01-.348.18.332.332 0 01-.279-.275l-.403-2.42-.945 3.779a.334.334 0 01-.647 0l-.74-2.961-.624 1.664a.334.334 0 01-.295.216c-.127.016-.256-.066-.315-.184L4.794 6h-1.46a.333.333 0 010-.667H5c.126 0 .241.072.298.184l.325.65.732-1.951c.051-.136.19-.233.33-.215.146.008.27.11.305.252l.677 2.706 1.01-4.04a.329.329 0 01.336-.252c.158.006.29.123.316.278l.498 2.989.208-.417a.334.334 0 01.298-.184h1a.334.334 0 010 .667zM15 9.667c0-.473-.974-2.216-1.46-3.057.045-.24.127-.789.127-1.61 0-1.423-1.884-5-6.334-5C2.965 0 1 3.314 1 5.667c0 1.664.983 3.369 1.773 4.738.46.798.894 1.551.894 1.928 0 .849-1.38 2.531-1.912 3.108A.333.333 0 002 16h7.333c.184 0 .324-.097.324-.281.118-.238 1.302-.878 2.397-1.057.82-.134 1.946-.318 1.946-1.329 0-.439-.152-.666-.253-.816-.067-.1-.08-.125-.122-.186a.332.332 0 00.335-.487l.276-.275a.335.335 0 00.08-.341l-.223-.668c.384-.133.907-.405.907-.893z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
