/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'CloseCircleIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M8 14.5a6.5 6.5 0 100-13 6.5 6.5 0 000 13zM8 16A8 8 0 108 0a8 8 0 000 16zm0-9.197L5.667 4.47 4.47 5.667 6.803 8 4.47 10.333l1.197 1.197L8 9.197l2.333 2.333 1.197-1.197L9.197 8l2.333-2.333-1.197-1.197L8 6.803z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
