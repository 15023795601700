import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fromModal } from 'rides/store/selectors';
import { modalHide } from 'rides/store/actions';
import { Modal } from 'rides/components';

const ModalContainer = props => <Modal {...props} />;

ModalContainer.propTypes = {
  name: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

const mapStateToProps = (state, { name, isOpen }) => ({
  isOpen: isOpen || fromModal.isOpen(state, name),
});

const mapDispatchToProps = (dispatch, { name, onClose }) => ({
  onHide: () => {
    // NOTE: only close if onClose not pass OR returns truthy
    if (!onClose || onClose()) {
      dispatch(modalHide(name));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
