import * as React from 'react';
import { Spinner } from '@chakra-ui/react';

import { useVendorNames } from './api-hooks';

function VendorNames({ vendorIds = [], render }) {
  const vendorNames = useVendorNames();

  if (typeof render !== 'function') {
    throw new Error('VendorName component must be passed `render` function');
  }

  if (vendorNames.data) {
    const vendorNameList = vendorIds.map(
      vendorId => vendorNames.data[vendorId] || vendorId,
    );

    return render({ vendorNames: vendorNameList });
  }

  if (vendorNames.error) {
    console.error(
      'An error has occurred loading vendor names: ' + vendorNames.error.message,
    );
  }

  return <Spinner size="xs" />;
}

export { VendorNames };
