import { useLocation } from 'react-router-dom';
import qs from 'qs';

export function useQueryString() {
  const location = useLocation();

  // Strip leading ? in search string if exists
  const queryString = location.search.replace(/^\?/, '');

  const query = qs.parse(queryString);

  const stringify = qs.stringify;

  return { queryString, query, stringify };
}
