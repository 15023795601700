import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  convertToReadableDate,
  convertToReadableTime,
  isValidIsoDateTime,
} from 'rides/utils/dateTime';
import { datetimeType } from 'rides/types';
import { Atom, Emphasis, Small } from 'rides/components';

const DateTime = ({ className, datetime, showDate, showTime, ...props }) => {
  const invalidDateTime = datetime && !isValidIsoDateTime(datetime);
  const classes = classNames(className, 'date-time-atom', {
    dateTimeNull: !datetime,
    dateTimeInvalid: invalidDateTime,
    textMuted: !datetime || invalidDateTime,
  });

  if (!datetime || invalidDateTime) {
    return (
      <Small className={classes} {...props}>
        <Emphasis>
          {!datetime && ' -- '}
          {invalidDateTime && 'invalid datetime'}
        </Emphasis>
      </Small>
    );
  }

  const showDateAndTime = !showDate && !showTime;
  const date = (showDateAndTime || !showTime) && (
    <Atom className="date">{convertToReadableDate(datetime)}</Atom>
  );
  const time = (showDateAndTime || !showDate) && (
    <Atom className="time">{convertToReadableTime(datetime)}</Atom>
  );
  return (
    <Atom className={classes} {...props}>
      {date} {time}
    </Atom>
  );
};

DateTime.propTypes = {
  className: PropTypes.string,
  datetime: datetimeType,
  showDate: PropTypes.bool,
  showTime: PropTypes.bool,
};

export default DateTime;
