import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { linkToType } from 'rides/types';

const handleClickDisabler = e => e.preventDefault();

const Link = ({ mailTo, ...props }) => {
  if (props.disabled) {
    return <RouterLink onClick={handleClickDisabler} {...props} />;
  }

  if (mailTo) {
    return <a href={`mailto:${mailTo}`}>{props.children}</a>;
  }

  return <RouterLink {...props} />;
};

Link.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  mailTo: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  to: linkToType,
};

export default Link;
