import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ActivityLog,
  Button,
  FormFeedbackBox,
  Glyphicon,
  Heading,
  Well,
  Pagination,
} from 'rides/components';
import { tripIdType } from 'rides/types'; // Corrected import path
import useEntityListLoaderPagination from 'rides/hooks/use-entity-list-loader-pagination';
import EntityListLoader from 'rides/components/utils/EntityListLoader';

const TripGroupActivityLog = ({ rootTripId }) => {
  const [page, pageSize, getPaginationProps] = useEntityListLoaderPagination();
  const [allPages, setAllPages] = useState(false);

  const handleAllPagesToggle = () => {
    setAllPages(!allPages);
  };

  return (
    <EntityListLoader
      loadOnMount
      resource={`trip_group_v2/${rootTripId}/activity_log`}
      entityType="activityLog"
      requestParams={{ page_size: allPages ? 10000 : pageSize, page: allPages ? 1 : page }}
      renderNotAsked={() => null}
      renderLoading={() => <FormFeedbackBox error="Loading..." warning />}
      renderError={() => <FormFeedbackBox error="Failed to load activity log" />}
      renderSuccess={items => <ActivityLog logList={items} />}
    >
      {(View, loadData, { pagination, isLoading }) => {
        const paginationProps = getPaginationProps(pagination, isLoading);

        return (
          <Well>
            <Heading level={3} style={{ marginTop: 0 }}>
              Trip Activity{' '}
              <Button sm success onClick={loadData}>
                <Glyphicon icon="refresh" />
              </Button>
            </Heading>
            {!allPages && <Pagination {...paginationProps} />}
            {View}
            {!allPages && <Pagination {...paginationProps} />}
            <Button sm onClick={handleAllPagesToggle}>
              {allPages ? 'Paginate' : 'Show All'}
            </Button>
          </Well>
        );
      }}
    </EntityListLoader>
  );
};

TripGroupActivityLog.propTypes = {
  rootTripId: tripIdType.isRequired,
};

export default TripGroupActivityLog;
