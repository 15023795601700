import { Pane, Paragraph, Strong, Text } from 'evergreen-ui';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AdminUserActivityLog } from 'rides/containers';
import { useRouter } from 'rides/hooks/use-router';
import { resourceDetailReadRequest } from 'rides/store/actions';

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = dispatch => ({
  fetchUser: userId => {
    return dispatch(resourceDetailReadRequest(`manage/user`, userId));
  },
});

function UserDetails({ user }) {
  const fields = [
    ['id', 'ID'],
    ['name', 'Name'],
    ['email', 'Email'],
  ];

  function val(field) {
    return user[field];
  }

  return fields.map(([key, label]) => (
    <Paragraph key={key}>
      <Text size={500}>
        <Strong>{label}</Strong> {val(key)}
      </Text>
    </Paragraph>
  ));
}

function UserDetailPage({ fetchUser }) {
  const { params } = useRouter();
  const userId = params.userId;

  // React-Query: Fetching from API
  const { data, status, error } = useQuery('adminUserDetail', () => fetchUser(userId), {
    refetchOnWindowFocus: false,
    retry: false,
    onError: err => {
      toaster.danger('Something went wrong trying to load data.', {
        description: 'If this persists try refreshing the browser.',
      });
    },
  });
  if (status !== 'success') {
    return null;
  }

  return (
    <div>
      <h1>ADMIN TOOLS</h1>
      <h2>USER DETAIL</h2>
      <Pane backgroundColor="white">
        <UserDetails user={data.data} />
      </Pane>
      <Pane backgroundColor="white">
        {status === 'success' && <AdminUserActivityLog userId={userId} />}
      </Pane>
    </div>
  );
}
UserDetailPage.propTypes = {
  fetchUser: PropTypes.func.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(UserDetailPage);
