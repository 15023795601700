import * as React from 'react'
import { QueryClient, QueryCache, QueryClientProvider } from 'react-query';
import { useToast } from '@chakra-ui/react'

export const QueryProvider = ({ children }) => {
  const toast = useToast()

  const [queryCache] = React.useState(() => new QueryCache({
    onError: (error, query) => {
      console.info('queryCache.onError(args)', { error, query })
      toast({
        status: 'error',
        variant: 'subtle',
        title: 'Error communicating with backend',
        description: `${error.message}`,
        duration: 4000,
        isClosable: true,
      })
    }
  }));

  const [queryClient] = React.useState(() => new QueryClient({
    queryCache,
    defaultOptions: {
      queries: {
        // 🚀 only server errors will go to the Error Boundary
        useErrorBoundary: (error) => error.response?.status >= 500,
        refetchOnWindowFocus: false,
        retry(failureCount, error) {
          console.info(
            'QueryClient.defaultOptions.queries.retry(args)',
            { failureCount, error },
          );

          if (error.status === 404) return false;
          else if (failureCount < 2) return true;
          else return false;
        },
      },
      mutations: {
        // mutation options
      },
    },
  }));

  return (
    <QueryClientProvider client={queryClient}>
      {children}
    </QueryClientProvider>
  )
}
