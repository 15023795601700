/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'OxygenIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M5.774 5.334h-.788c-.109 0-.197-.096-.197-.217 0-.117.088-.217.197-.217h.788c.109 0 .197.1.197.217 0 .121-.088.217-.197.217zM5.774 7.067h-.788c-.109 0-.197-.096-.197-.217 0-.117.088-.217.197-.217h.788c.109 0 .197.1.197.217 0 .121-.088.217-.197.217zM5.774 6.2h-.788c-.109 0-.197-.095-.197-.216 0-.117.088-.217.197-.217h.788c.109 0 .197.1.197.217 0 .12-.088.216-.197.216zM5.774 7.934h-.788c-.109 0-.197-.096-.197-.217 0-.117.088-.217.197-.217h.788c.109 0 .197.1.197.217 0 .121-.088.217-.197.217zM5.774 8.8h-.788c-.109 0-.197-.095-.197-.216 0-.117.088-.217.197-.217h.788c.109 0 .197.1.197.216 0 .122-.088.217-.197.217zM5.774 9.667h-.788c-.109 0-.197-.096-.197-.217 0-.117.088-.217.197-.217h.788c.109 0 .197.1.197.217 0 .121-.088.217-.197.217zM8.53 5.334H6.45a.2.2 0 00-.17.108.235.235 0 000 .217.685.685 0 010 .65.234.234 0 000 .216.685.685 0 010 .65.234.234 0 000 .217.685.685 0 010 .65.234.234 0 000 .216.685.685 0 010 .65.234.234 0 000 .217.685.685 0 010 .65.234.234 0 000 .217.698.698 0 01.082.325c0 .121.089.216.197.216h1.97c.57 0 .985-.455.985-1.083V6.417c0-.628-.415-1.083-.985-1.083z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M4.476 9.775a.685.685 0 010-.65.234.234 0 000-.216.685.685 0 010-.65.234.234 0 000-.217.685.685 0 010-.65.234.234 0 000-.217.685.685 0 010-.65.234.234 0 000-.216.685.685 0 010-.65.234.234 0 000-.217.685.685 0 01-.082-.325c0-.355.265-.65.59-.65h.788c.21 0 .401.121.509.325.035.07.1.108.17.108H8.53c.786 0 1.379.655 1.379 1.517V9.45c0 .867-.593 1.517-1.38 1.517H6.167c-.054 0-.106-.01-.194-.022-.024 0-.046 0-.069.005a.482.482 0 01-.13.017h-.789c-.325 0-.59-.29-.59-.65 0-.108.027-.221.082-.325a.234.234 0 000-.217zm5.236-7.041H4.985c-.543 0-.985.485-.985 1.083v6.933c0 .598.442 1.083.985 1.083h4.727c.543 0 .985-.485.985-1.083V3.817c0-.598-.442-1.083-.985-1.083z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M5.774 10.534h-.788c-.109 0-.197-.096-.197-.217 0-.117.088-.217.197-.217h.788c.109 0 .197.1.197.217 0 .121-.088.216-.197.216zM8.925 14c-1.645 0-1.773-.992-1.773-1.3 0-.117.089-.217.197-.217.109 0 .197.1.197.217 0 .55.503.867 1.38.867 1.158 0 1.378-.304 1.378-.867 0-.594.376-1.3 2.166-1.3.11 0 .197.1.197.217 0 .121-.088.216-.197.216-.808 0-1.772.152-1.772.867 0 1.148-.896 1.3-1.773 1.3zM8.333 11.833v-.216c0-.117-.088-.217-.197-.217H6.56c-.108 0-.197.1-.197.217v.216h1.97z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M6.363 12.267v.216c0 .122.089.217.197.217h1.576c.109 0 .197-.095.197-.217v-.216h-1.97zM9.18 2.3L7.457 1.04a.171.171 0 00-.218 0L5.516 2.3H9.18zM9.793 2.751l-.02-.017H4.927l-.05.039a.227.227 0 00-.08.242.199.199 0 00.19.152H9.72c.109 0 .197-.095.197-.217a.213.213 0 00-.125-.199z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
