import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, Popover, Position } from 'evergreen-ui';

import * as ridesTypes from 'rides/types';
import TripQuickEditModal from 'rides/nui/TripQuickEditModal';
import {
  useTripGroupDuplicateModal,
  TripGroupDuplicateModal,
} from 'rides/evo/feature/trip-group-duplicate';
import { useTripEditDisabled, useEditTripWizard } from 'rides/evo/utils/trips';

// --
// -- TripListTripMenu Component
// --

const NuiTripListTripMenu = ({ trip, updateTrip, disabled }) => {
  const {
    isOpen,
    onCloseModal,
    onOpenModal: openTripGroupDupe,
    createdTripGroups,
    onDuplicateTripGroupSuccess,
  } = useTripGroupDuplicateModal();

  const tripId = trip?.id;
  const memberId = trip?.member?.id;

  const isTripEditDisabled = useTripEditDisabled(tripId);
  const onEditTrip = useEditTripWizard(memberId, tripId);

  return (
    <>
      <TripGroupDuplicateModal
        tripId={trip.id}
        trip={trip}
        isOpen={isOpen}
        onCloseModal={onCloseModal}
        createdTripGroups={createdTripGroups}
        onDuplicateTripGroupSuccess={onDuplicateTripGroupSuccess}
      />
      {disabled && <IconButton appearance="minimal" icon="more" />}
      {!disabled && (
        <TripQuickEditModal
          trip={trip}
          updateTrip={updateTrip}
          render={openQuickEditTopic => (
            <Popover
              position={Position.BOTTOM_RIGHT}
              content={
                <Menu>
                  <Menu.Group>
                    {!isTripEditDisabled && (
                      <Menu.Item onSelect={() => onEditTrip()}>Edit Trip</Menu.Item>
                    )}
                    <Menu.Item disabled onSelect={() => openQuickEditTopic('notes')}>
                      Edit Trip Notes
                    </Menu.Item>
                    <Menu.Item disabled onSelect={() => openQuickEditTopic('mapUrl')}>
                      Edit Trip Map URL
                    </Menu.Item>
                    <Menu.Item disabled onSelect={() => openQuickEditTopic('pickupTime')}>
                      Edit Scheduled Pickup Time
                    </Menu.Item>
                    {trip.isDriverAssigned && (
                      <Menu.Item
                        disabled
                        onSelect={() => openQuickEditTopic('driverDetails')}
                      >
                        Edit Driver Details
                      </Menu.Item>
                    )}
                    <Menu.Item disabled onSelect={openTripGroupDupe(trip.id)}>
                      Duplicate Trip Group
                    </Menu.Item>
                  </Menu.Group>
                </Menu>
              }
            >
              <IconButton appearance="minimal" icon="more" />
            </Popover>
          )}
        />
      )}
    </>
  );
};

NuiTripListTripMenu.propTypes = {
  trip: ridesTypes.tripType.isRequired,
  updateTrip: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default NuiTripListTripMenu;
