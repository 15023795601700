import React from 'react';
import PropTypes from 'prop-types';

import {
  Block,
  Button,
  Column,
  HorizontalRule,
  ConfirmModal,
  Row,
} from 'rides/components';

const Confirm = ({
  isShowing,
  title,
  message,
  noButtonText,
  yesButtonText,
  onAnswerNo,
  onAnswerYes,
  onDismiss,
  danger,
  hideNoButton,
}) => (
  <ConfirmModal isOpen={isShowing} onHide={onDismiss} title={title || 'Health E-Rides'}>
    <Row>
      <Column xs={12}>{message}</Column>
    </Row>
    <HorizontalRule style={{ marginTop: 40, marginBottom: 20 }} />
    <Row>
      <Column xs={12} className="text-right">
        <Block>
          {!hideNoButton && (
            <Button onClick={onAnswerNo} default>
              {noButtonText || 'Cancel'}
            </Button>
          )}{' '}
          <Button onClick={onAnswerYes} primary={!danger} danger={danger}>
            {yesButtonText || 'Ok'}
          </Button>
        </Block>
      </Column>
    </Row>
  </ConfirmModal>
);

Confirm.propTypes = {
  isShowing: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.node,
  noButtonText: PropTypes.string,
  yesButtonText: PropTypes.string,
  onAnswerNo: PropTypes.func.isRequired,
  onAnswerYes: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  hideNoButton: PropTypes.bool,
  danger: PropTypes.bool,
};

export default Confirm;
