export const requestStripAuthToken = request => {
  if (request.headers['X-Auth-Token']) {
    if (request.url === '/user/sync') {
      console.log('stripping auth-token from sync');
      delete request.headers['X-Auth-Token'];
    }
  }

  return request;
};

export const responseCheckStatus = response => {
  if (response.ok) {
    return response;
  }

  const error = new Error(`${response.status} ${response.statusText}`);
  error.response = response;
  throw error;
};
