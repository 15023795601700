import moment from 'moment-timezone';
import { appTimezone } from 'rides/config';

// NOTE: This is hacky and we should replace this with REAL timezone support
//       when we have time and resources
let currentTimezone = appTimezone;

export function setTimezone(timezone) {
  // console.log('setTimezone::start', { timezone, appTimezone, currentTimezone });
  if (timezone) {
    currentTimezone = timezone;
  } else {
    currentTimezone = appTimezone;
  }

  // console.log('setTimezone::end', { timezone, appTimezone, currentTimezone });
  return currentTimezone;
}

export function getTimezone() {
  return currentTimezone;
}

// --

export const isoDateTime = 'YYYY-MM-DDTHH:mm:ssZ';
export const isoDate = 'YYYY-MM-DD';
export const readableDate = 'M/D/YYYY';
export const monthDayDate = 'M/D';
export const readableTime = 'h:mm a';
export const readableDateTime = `${readableDate} ${readableTime}`;

export function isValidIsoDateTime(dateTime) {
  return moment.utc(dateTime, isoDateTime).isValid();
}

export function createAware() {
  return moment()
    .tz(currentTimezone)
    .set({ hour: 0, minute: 0, second: 0 })
    .format(isoDateTime);
}

export function convertFromApi(dateTime) {
  return moment
    .utc(dateTime, isoDateTime)
    .tz(currentTimezone)
    .format(isoDateTime);
}

export function convertFromReadableDateTime(dateTime) {
  return moment
    .tz(dateTime, readableDateTime, currentTimezone)
    .utc()
    .format(isoDateTime);
}

export function getDateTime(dateTime) {
  return moment(dateTime, isoDateTime)
    .tz(currentTimezone)
    .format(isoDateTime);
}

export function getDateTimeTzOffset(dateTime) {
  return moment(dateTime)
    .tz(currentTimezone)
    .format();
}

export function getDate(dateTime) {
  return moment(dateTime, isoDateTime)
    .tz(currentTimezone)
    .format(isoDate);
}

export function getHour(dateTime) {
  return moment(dateTime, isoDateTime)
    .tz(currentTimezone)
    .format('HH');
}

export function getMinute(dateTime) {
  return moment(dateTime, isoDateTime)
    .tz(currentTimezone)
    .format('mm');
}

export function getHourAndMinute(dateTime) {
  return moment(dateTime, isoDateTime)
    .tz(currentTimezone)
    .format('H:mm');
}

export function getPeriod(dateTime) {
  return moment(dateTime, isoDateTime)
    .tz(currentTimezone)
    .format('a');
}

/**
 * NOTE: Date objects always use local timezone. When using the Date object
 *       returned by this function we need to make sure we ignoring timezone
 *       information when converting to moment object.
 */
export function convertToNativeDateObject(dateTime) {
  const stripZoneFormat = 'YYYY-MM-DDTHH:mm:ss.SSS';
  const localDateTime = moment(dateTime, isoDateTime).format(stripZoneFormat);
  return new Date(localDateTime);
}

export function convertToReadableDateTime(dateTime) {
  return moment
    .utc(dateTime, isoDateTime)
    .tz(currentTimezone)
    .format(readableDateTime);
}

export function convertToReadableDate(dateTime) {
  return moment
    .utc(dateTime, isoDateTime)
    .tz(currentTimezone)
    .format(readableDate);
}

export function convertToReadableMonthDay(dateTime) {
  return moment
    .utc(dateTime, isoDateTime)
    .tz(currentTimezone)
    .format(monthDayDate);
}

export function convertToReadableTime(dateTime) {
  return moment
    .utc(dateTime, isoDateTime)
    .tz(currentTimezone)
    .format(readableTime);
}

export function convertToFormat(dateTime, format) {
  return moment
    .utc(dateTime, isoDateTime)
    .tz(currentTimezone)
    .format(format);
}

export function updateTime(dateTime, field, value) {
  return moment(dateTime, isoDateTime)
    .tz(currentTimezone)
    .set(field, value)
    .format(isoDateTime);
}

export function updateDate(dateTime, to) {
  let newDateTime = moment(dateTime, isoDateTime).tz(currentTimezone);
  const parsedTo = moment(to, isoDate);

  newDateTime = newDateTime.set({
    year: parsedTo.get('year'),
    month: parsedTo.get('month'),
    date: parsedTo.get('date'),
  });

  return newDateTime.format(isoDateTime);
}

export function hourOptions() {
  const hours = [];
  for (let i = 0; i < 24; i++) {
    hours.push(i < 10 ? `0${i}` : i);
  }

  return hours;
}

export function minuteOptions() {
  const minutes = [];
  for (let i = 0; i < 60; i += 1) {
    minutes.push(i < 10 ? `0${i}` : i);
  }

  return minutes;
}

// --
// -- Native JS Date Functions
// --

export function convertDateTimeToJSDate(dateTime) {
  const stripZoneDateTime = convertToReadableDateTime(dateTime);
  return new Date(stripZoneDateTime);
}

export function convertReadableDateToJSDate(date) {
  return moment(date, readableDate).toDate();
}

/**
 * NOTE: Native JS Date objects set the timezone based on the user's timezone.
 *       These functions strips the local timezone from the native JS Date
 *       object, which makes it useful for converting a JS Date object to
 *       other timezones.
 */
export function convertJSDateToReadableDateTime(dateObj) {
  return moment(dateObj).format(readableDateTime);
}
export function convertJSDateToReadableDate(dateObj) {
  return moment(dateObj).format(readableDate);
}

/**
 * Returns a timestamp string formated for use with API.
 *
 *   Conversion process:
 *     1. Get date and time without local timezone from JS Date object
 *     2. Create moment datetime object from that w/ tenant tz
 *     3. Convert to UTC datetime and return isoDateTime format
 */
export function convertJSDateToDateTime(dateObj) {
  const localTime = convertJSDateToReadableDateTime(dateObj);
  return convertFromReadableDateTime(localTime);
}

/**
 * Returns JS Date object with browser's time zone and current time
 * and the year / month / day of the passed in date obj.
 */
export function convertJSDateToBrowserTime(dateObj) {
  if (dateObj) {
    const localDate = new Date();
    localDate.setFullYear(
      //
      dateObj.getFullYear(),
      dateObj.getMonth(),
      dateObj.getDate(),
    );

    return localDate;
  }
}
