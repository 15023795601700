import { useState, useRef, useEffect } from 'react';

/**
 * React hook similar setState but for state objects that merges the existing
 * state values with the object passed in.
 */
export function useMergeState(initialState) {
  const [state, setState] = useState({ ...initialState });
  const resetState = newState => setState({ ...newState });
  const mergeState = newState => setState(prevState => ({ ...prevState, ...newState }));
  return [state, mergeState, resetState];
}

/**
 * Prevent setting state when component is unmounted by detecting if component
 * is mounted before setting state
 */
function useSafeMergeState(initialState) {
  const [state, mergeState, resetState] = useMergeState(initialState);

  const mountedRef = useRef(false);
  useEffect(() => {
    mountedRef.current = true;
    return () => (mountedRef.current = false);
  }, []);

  const safeMergeState = (...args) => mountedRef.current && mergeState(...args);
  const safeResetState = (...args) => mountedRef.current && resetState(...args);

  return [state, safeMergeState, safeResetState];
}

export default useSafeMergeState;
