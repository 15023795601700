import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';

import {
  Button,
  Block,
  Column,
  HorizontalRule,
  FormFeedbackBox,
  FormikField,
  Row,
} from 'rides/components';
import { Modal } from 'rides/containers';

class TripCancelModal extends React.Component {
  onClose = () => {
    this.props.onClose();
    return false;
  };
  render() {
    const { cancelTrip, formDisabled, values, errorMessage, ...props } = this.props;

    return (
      <Modal
        {...props}
        title="Cancel Trip"
        name="trip-cancel"
        onClose={this.onClose}
        closeable
      >
        <Formik
          initialValues={values}
          onSubmit={(values, formikBag) => {
            const { cancelReason } = values;
            cancelTrip(cancelReason);
            formikBag.setSubmitting(false);
          }}
          render={formikProps => {
            const disabled = !!formikProps.isSubmitting || formDisabled;

            return (
              <Form>
                <Row>
                  <Column xs={12} sm={10} smOffset={1}>
                    {!disabled &&
                      errorMessage && (
                        <FormFeedbackBox danger>{errorMessage}</FormFeedbackBox>
                      )}

                    <Row>
                      <Column xs={12}>
                        <Row>
                          <Field
                            name="cancelReason"
                            label="Cancel Reason"
                            component={FormikField}
                            disabled={disabled}
                            type="textarea"
                          />
                        </Row>
                      </Column>
                    </Row>
                  </Column>
                </Row>

                <Row>
                  <Column xs={12}>
                    <HorizontalRule />

                    <Block clearfix>
                      <Button type="submit" disabled={disabled} danger pullRight>
                        Cancel Trip
                      </Button>
                    </Block>
                  </Column>
                </Row>
              </Form>
            );
          }}
        />
      </Modal>
    );
  }
}

TripCancelModal.propTypes = {
  cancelTrip: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  formDisabled: PropTypes.bool,
  values: PropTypes.object.isRequired,
};
export default TripCancelModal;
