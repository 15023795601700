// @flow
import React from 'react';
import type { Children } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  active?: boolean,
  button?: boolean,
  children?: Children,
  className?: string,
  disabled?: boolean,
  href?: string,
  onClick?: Function,
  to?: string,

  // contexts
  success?: boolean,
  info?: boolean,
  warning?: boolean,
  danger?: boolean,
};

const BSListGroupItem = ({
  active,
  button,
  className,
  disabled,
  success,
  info,
  warning,
  danger,
  ...props
}: Props) => {
  let cssClasses = ['list-group-item'];

  // contexts
  if (success) cssClasses[1] = 'list-group-item-success';
  if (info) cssClasses[1] = 'list-group-item-info';
  if (warning) cssClasses[1] = 'list-group-item-warning';
  if (danger) cssClasses[1] = 'list-group-item-danger';

  if (active) cssClasses.push('active');
  if (disabled) cssClasses.push('disabled');
  if (className) cssClasses.push(className);

  const cssClassNames = cssClasses.join(' ');

  if (props.to) {
    return <Link {...props} className={cssClassNames} />;
  } else if (props.href) {
    return <a {...props} className={cssClassNames} />;
  } else if (button) {
    return <button type="button" {...props} className={cssClassNames} />;
  }

  return <li {...props} className={cssClassNames} />;
};

export default BSListGroupItem;
