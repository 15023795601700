import React from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  Dialog,
  TextInputField,
  Label,
  Textarea,
  Pane,
  majorScale,
} from 'evergreen-ui';

import * as ridesTypes from 'rides/types';
import useSafeMergeState from 'rides/hooks/use-safe-merge-state';
import { Position } from '@blueprintjs/core';
import { TimePicker, DateInput } from '@blueprintjs/datetime';

import { setTimeToNow } from 'rides/utils/dateFns';
import {
  convertDateTimeToJSDate,
  convertJSDateToDateTime,
  convertJSDateToReadableDate,
  convertReadableDateToJSDate,
  readableDate,
} from 'rides/utils/dateTime';
import CurrentTimezone from 'rides/nui/containers/CurrentTimezone';
import { Flex, Box } from '@chakra-ui/react';

function getMaxDate() {
  const maxDate = new Date();
  maxDate.setFullYear(2030, 11, 31);
  maxDate.setHours(23, 59, 59);
  return maxDate;
}

// --
// Trip Quick Edit Topic Form Field Components
// --

const MapUrlFormFields = ({ values, setValues, disabled }) => {
  const { mapUrl } = values;

  return (
    <>
      <TextInputField
        label="Trip Map URL"
        value={mapUrl}
        onChange={e => setValues({ mapUrl: e.target.value })}
        disabled={disabled}
      />
    </>
  );
};

MapUrlFormFields.propTypes = {
  disabled: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
};

// --

const NotesFormFields = ({ values, setValues, disabled }) => {
  const { notes } = values;

  return (
    <>
      <Label htmlFor="trip-notes" marginBottom={4} display="block">
        Trip Notes
      </Label>
      <Textarea
        value={notes}
        onChange={e => setValues({ notes: e.target.value })}
        disabled={disabled}
      />
    </>
  );
};

NotesFormFields.propTypes = {
  disabled: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
};

// --

const dateInputProps = {
  className: 'occurred-at-field--date-input',
  canClearSelection: false,
  highlightCurrentDay: true,
  popoverProps: { position: Position.BOTTOM, boundary: 'window' },
  // vv-- Date format and parsing --vv
  formatDate: convertJSDateToReadableDate,
  parseDate: convertReadableDateToJSDate,
  placeholder: readableDate,
  maxDate: getMaxDate(),
};

const timeInputProps = {
  className: 'occurred-at-field--time-input',
  selectAllOnFocus: true,
  useAmPm: true,
};

const PickupTimeFormFields = ({ values, setValues, disabled }) => {
  const { pickupTime } = values;

  const jsDateValue = convertDateTimeToJSDate(pickupTime);

  function handleDateChange(newValue) {
    const updateDate = new Date(jsDateValue).setFullYear(
      newValue.getFullYear(),
      newValue.getMonth(),
      newValue.getDate(),
    );
    const newFieldValue = convertJSDateToDateTime(updateDate);
    setValues({ pickupTime: newFieldValue });
  }

  function handleTimeChange(newValue) {
    const updateDate = new Date(jsDateValue).setHours(
      newValue.getHours(),
      newValue.getMinutes(),
    );
    const newFieldValue = convertJSDateToDateTime(updateDate);
    setValues({ pickupTime: newFieldValue });
  }

  return (
    <>
      <Label htmlFor="pickup-time" marginBottom={4} display="block">
        Scheduled Pickup Time
      </Label>

      <Flex alignItems="center">
        <Box
          maxWidth="7em"
          css={{
            '& input': {
              textAlign: 'center',
            },
          }}
        >
          <DateInput
            {...dateInputProps}
            value={jsDateValue}
            onChange={handleDateChange}
            disabled={disabled}
          />
        </Box>
        <Text size={400} paddingX="0.5em">
          at
        </Text>
        <Box>
          <TimePicker
            {...timeInputProps}
            value={jsDateValue}
            onChange={handleTimeChange}
            disabled={disabled}
          />
        </Box>
      </Flex>
      <Text size={300} color="muted">
        Times are local to <CurrentTimezone />
      </Text>
    </>
  );
};

PickupTimeFormFields.propTypes = {
  disabled: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
};

// --

const DriverDetailsFormFields = ({ values, setValues, disabled }) => {
  const {
    customDriverName,
    customDriverPhone,
    customDriverCarLicense,
    customDriverCarDescription,
  } = values;

  return (
    <>
      <Pane display="flex">
        <Pane flex={1} paddingRight={majorScale(1)}>
          <TextInputField
            label="Driver Name"
            value={customDriverName}
            onChange={e => setValues({ customDriverName: e.target.value })}
            disabled={disabled}
          />
        </Pane>
        <Pane flex={1} paddingLeft={majorScale(1)}>
          <TextInputField
            label="Driver Phone Number"
            value={customDriverPhone}
            onChange={e => setValues({ customDriverPhone: e.target.value })}
            disabled={disabled}
          />
        </Pane>
      </Pane>
      <TextInputField
        label="Driver Car License"
        value={customDriverCarLicense}
        onChange={e => setValues({ customDriverCarLicense: e.target.value })}
        disabled={disabled}
      />

      <Label htmlFor="custom-driver-car-description" marginBottom={4} display="block">
        Driver Car Description
      </Label>
      <Textarea
        value={customDriverCarDescription}
        onChange={e => setValues({ customDriverCarDescription: e.target.value })}
        disabled={disabled}
      />
    </>
  );
};

DriverDetailsFormFields.propTypes = {
  disabled: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
};

// --
// -- TripQuickEditModal Component
// --

const TripQuickEditModal = ({ trip = {}, updateTrip, render }) => {
  const [state, mergeState] = useSafeMergeState({
    isShown: false,
    isLoading: false,
    topic: null,
    tripUpdate: {},
  });
  const { tripUpdate } = state;

  async function resetFormValues() {
    mergeState({ tripUpdate: {} });
  }

  // this merges object with current form state object
  async function setTripUpdateState(newState) {
    mergeState({ tripUpdate: { ...tripUpdate, ...newState } });
  }

  async function closeDialog() {
    mergeState({ isShown: false, isLoading: false, topic: null });
  }

  async function closeDialogAndResetForm() {
    closeDialog();
    resetFormValues();
  }

  function openQuickEdit(topic) {
    if (topic === 'mapUrl') {
      setTripUpdateState({ mapUrl: trip.mapUrl });
    }

    if (topic === 'notes') {
      setTripUpdateState({ notes: trip.notes });
    }

    if (topic === 'pickupTime') {
      const initialPickupTime = setTimeToNow(trip.pickupTime);
      setTripUpdateState({
        pickupTime: initialPickupTime,
      });
    }

    if (topic === 'driverDetails') {
      setTripUpdateState({
        customDriverCarLicense: trip.customDriverCarLicense,
        customDriverPhone: trip.customDriverPhone,
        customDriverCarDescription: trip.customDriverCarDescription,
        customDriverName: trip.customDriverName,
      });
    }

    mergeState({ topic, isShown: true });
  }

  async function doUpdateTrip() {
    mergeState({ isLoading: true });

    try {
      await updateTrip(tripUpdate);
      closeDialog();
    } catch (err) {
      mergeState({ isLoading: false });
    }
  }

  function getTopicTitle(topic) {
    if (topic === 'pickupTime') return 'Edit Scheduled Pickup Time';
    if (topic === 'driverDetails') return 'Edit Driver Details';
    if (topic === 'mapUrl') return 'Edit Trip Map Url';
    if (topic === 'notes') return 'Edit Trip Notes';
    return 'Edit Trip';
  }

  return (
    <React.Fragment>
      {render(openQuickEdit)}
      {state.isShown && (
        <Dialog
          isShown={state.isShown}
          title={getTopicTitle(state.topic)}
          onCloseComplete={closeDialogAndResetForm}
          isConfirmLoading={state.isLoading}
          onConfirm={doUpdateTrip}
          confirmLabel={state.isLoading ? 'Saving...' : 'Save'}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEscapePress={false}
        >
          {state.topic === 'mapUrl' && (
            <MapUrlFormFields
              values={tripUpdate}
              setValues={setTripUpdateState}
              disabled={state.isLoading}
            />
          )}
          {state.topic === 'notes' && (
            <NotesFormFields
              values={tripUpdate}
              setValues={setTripUpdateState}
              disabled={state.isLoading}
            />
          )}
          {state.topic === 'driverDetails' && (
            <DriverDetailsFormFields
              values={tripUpdate}
              setValues={setTripUpdateState}
              disabled={state.isLoading}
            />
          )}
          {state.topic === 'pickupTime' && (
            <PickupTimeFormFields
              values={tripUpdate}
              setValues={setTripUpdateState}
              disabled={state.isLoading}
            />
          )}
        </Dialog>
      )}
    </React.Fragment>
  );
};

TripQuickEditModal.propTypes = {
  trip: ridesTypes.tripType.isRequired,
  updateTrip: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
};

export default TripQuickEditModal;
