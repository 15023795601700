import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Row = ({ children, className, ...props }) => {
  const classes = classNames(className, 'row');

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};

Row.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Row;
