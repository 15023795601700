/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'TimeLgIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0zm5.295 15.295a.417.417 0 01-.589 0l-3.865-3.864a1.644 1.644 0 01-.84.236 1.661 1.661 0 01-.833-3.102V5.417a.417.417 0 01.832 0v2.916c.92 0 1.667.748 1.667 1.667 0 .308-.09.594-.237.842l3.865 3.864a.417.417 0 010 .589z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
});
