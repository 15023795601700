import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const WebIcon = ({ className, icon, ...props }) => {
  const classes = classNames(className, `wb-${icon}`);

  return <i {...props} className={classes} />;
};

WebIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export default WebIcon;
