import React from 'react';
import PropTypes from 'prop-types';

const Caption = ({ children, ...props }) => <caption {...props}>{children}</caption>;

Caption.propTypes = {
  children: PropTypes.any,
};

export default Caption;
