import React, { useState } from 'react';
import { Popover, Position, Menu, IconButton } from 'evergreen-ui';

import * as ridesTypes from 'rides/types';
import EditTripStatusTransitionModal from 'rides/containers/EditTripStatusTransitionModal';

function TripTransitionActionsMenu({ tripId, transition }) {
  const [isEditDialogShown, setIsEditDialogShown] = useState(false);

  return (
    <>
      <Popover
        position={Position.BOTTOM_RIGHT}
        content={
          <Menu>
            <Menu.Group>
              <Menu.Item icon="edit" onSelect={() => setIsEditDialogShown(true)}>
                Update Status Transition
              </Menu.Item>
            </Menu.Group>
          </Menu>
        }
      >
        <IconButton appearance="minimal" icon="more" margin={3} />
      </Popover>

      {isEditDialogShown && (
        <EditTripStatusTransitionModal
          tripId={tripId}
          transition={transition}
          status={transition.statusTo}
          onClose={() => setIsEditDialogShown(false)}
        />
      )}
    </>
  );
}

TripTransitionActionsMenu.propTypes = {
  tripId: ridesTypes.tripIdType.isRequired,
  transition: ridesTypes.tripStatusTransitionType.isRequired,
};

export default TripTransitionActionsMenu;
