/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'PharmacyIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M15.724 6.4H.276A.274.274 0 000 6.667c0 4.33 3.813 8.266 8 8.266 4.187 0 8-3.936 8-8.266a.274.274 0 00-.276-.267zM8 5.867h3.31c.09 0 .175-.043.226-.112l1.854-2.56c.844-.075 1.507-.758 1.507-1.595 0-.88-.743-1.6-1.655-1.6-.875 0-1.594.661-1.651 1.493L7.799 5.42a.261.261 0 00-.052.288.28.28 0 00.254.16z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M12.413 14.4H3.585c-.456 0-.827.363-.827.8 0 .443.371.8.827.8h8.828c.456 0 .828-.357.828-.8 0-.437-.372-.8-.828-.8z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
