/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'HeartOutlineIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M8 2.625C9.706.566 12.926.438 14.8 2.387a4.231 4.231 0 010 5.905l-6.213 6.46A.815.815 0 018 15a.815.815 0 01-.587-.247L1.2 8.293a4.231 4.231 0 010-5.906C3.074.438 6.294.567 8 2.625zm5.625.815c-1.227-1.276-3.35-1.169-4.432.224L8 5.201 6.807 3.664c-1.082-1.393-3.205-1.5-4.432-.224a2.722 2.722 0 000 3.8L8 13.09l5.625-5.85a2.722 2.722 0 000-3.8z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
