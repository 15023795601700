import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { authLogOut } from 'rides/store/actions';
import { Link } from 'rides/components';

const mapDispatchToProps = {
  logOut: authLogOut,
};

class LogoutButton extends Component {
  static propTypes = {
    logOut: PropTypes.func,
  };

  handleClick = event => {
    event.preventDefault();
    this.props.logOut();
  };

  render() {
    return (
      <Link to="" onClick={this.handleClick} title="Log Out" id="logoutButton">
        <span className="icon wb-power" aria-hidden="true" style={{ color: '#ddd' }} />
      </Link>
    );
  }
}

export default connect(null, mapDispatchToProps)(LogoutButton);
