import React from 'react';
import PropTypes from 'prop-types';

import { tripStatusTransitionReasonListType } from 'rides/types';
import { Input } from 'rides/components';

const TripTransitionReasonSelectField = ({ disabled, reasonList, ...props }) => (
  <Input {...props} type="select" disabled={disabled}>
    <option value="" />
    {reasonList.map(r => (
      <option key={r.id} value={r.id}>
        {r.name}
      </option>
    ))}
  </Input>
);

TripTransitionReasonSelectField.propTypes = {
  disabled: PropTypes.bool,
  reasonList: tripStatusTransitionReasonListType.isRequired,
};

TripTransitionReasonSelectField.defaultProps = {
  disabled: false,
};

export default TripTransitionReasonSelectField;
