import React from 'react';
import * as R from 'ramda';

import { activityLogType } from 'rides/types';
import { ucwords } from 'rides/utils/format';
import { Atom, Link, Strong } from 'rides/components';
import { getTenantUrl } from 'rides/hooks/use-tenant';
// import { Text } from 'rides/nui';

export const ActionTripGroupReferenceNumber = ({ log }) => {
  const groupReferenceNumber = R.path(['data', 'groupReferenceNumber'], log);
  if (typeof groupReferenceNumber !== 'string') return null;

  return <Atom>{` (Ref #${groupReferenceNumber})`}</Atom>;
};

export const ActionIdLink = ({ log }) => {
  const logType = R.path(['type'], log);
  const memberId = R.path(['member', 'id'], log);
  const tenantId = R.path(['tenantId'], log);

  if (logType === 'member') {
    const { member } = log;
    const hasMemberName = member.firstName && member.lastName;
    const memberExternalId = member.externalId;
    const memberName = `${member.firstName} ${member.lastName}`;

    return (
      <Link to={getTenantUrl(tenantId, `/member/${memberId}`)}>
        {hasMemberName ? `${memberName} (${memberExternalId})` : memberExternalId}
      </Link>
    );
  }

  const tripTypes = ['trip', 'trip_status_transition'];
  if (R.contains(logType, tripTypes)) {
    return (
      <>
        <Link to={getTenantUrl(tenantId, `/member/${memberId}/trip/${log.tripId}`)}>
          <Atom>{log.tripId}</Atom>
        </Link>
        <ActionTripGroupReferenceNumber log={log} />
      </>
    );
  }

  return null;
};

export const actionName = (action = '') => {
  const name = action.split('.')[0];
  if (!name) return null;
  return R.pipe(R.replace('_', ' '), ucwords)(name);
};

export const actionTarget = (action = '') => {
  const target = action.split('.')[1];
  if (!target) return null;
  return target.replace('_', ' ');
};

export const ActionText = ({ log }) => {
  return (
    <Atom>
      {ucwords(log.type)} <ActionIdLink log={log} /> {actionName(log.action)}{' '}
      <Strong>{actionTarget(log.action)}</Strong>
    </Atom>
  );
};

ActionText.propTypes = {
  log: activityLogType.isRequired,
};

export default ActionText;
