/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'AvatarLgIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        stroke-width=".909"
        d="M10 19a9 9 0 100-18 9 9 0 000 18z"
        clip-rule="evenodd"
      />
      <mask id="a" width="20" height="20" x="0" y="0" maskUnits="userSpaceOnUse">
        <path
          fill-rule="evenodd"
          stroke-width=".909"
          d="M10 19a9 9 0 100-18 9 9 0 000 18z"
          clip-rule="evenodd"
        />
      </mask>
      <g fill-rule="evenodd" clip-rule="evenodd" mask="url(#a)">
        <path d="M9.881 11.631c.98 0 1.815-.348 2.507-1.045a3.445 3.445 0 001.039-2.52c0-.985-.346-1.825-1.039-2.521A3.406 3.406 0 009.881 4.5c-.978 0-1.814.348-2.506 1.045a3.445 3.445 0 00-1.04 2.52c0 .985.347 1.825 1.04 2.521a3.406 3.406 0 002.506 1.045z" />
        <path d="M16.468 15.582a9.849 9.849 0 00-.13-1.027 8.125 8.125 0 00-.245-1.023 4.897 4.897 0 00-.397-.92 3.306 3.306 0 00-.572-.763 2.401 2.401 0 00-.79-.505 2.723 2.723 0 00-1.029-.188c-.056 0-.185.067-.388.202-.203.136-.432.287-.688.453a4.318 4.318 0 01-.997.452c-.409.136-.82.203-1.232.203-.413 0-.824-.067-1.233-.203a4.318 4.318 0 01-.997-.452 91.396 91.396 0 01-.688-.453c-.203-.135-.332-.202-.388-.202-.375 0-.718.063-1.029.188-.31.126-.574.294-.79.505-.215.21-.406.465-.572.763a4.91 4.91 0 00-.397.92 9.77 9.77 0 00-.374 2.05c-.021.318-.032.643-.032.976 0 .754.225 1.35.674 1.787.45.437 1.046.655 1.791.655h8.07c.745 0 1.341-.218 1.791-.655.45-.437.674-1.032.674-1.787 0-.333-.01-.658-.032-.976z" />
      </g>
    </g>
  ),
  viewBox: '0 0 20 20',
});
