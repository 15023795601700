// @flow
import React from 'react';
import type { Children } from 'react';

type Props = {
  children?: Children,
  className: string,
  linked?: boolean,
};

const BSListGroup = ({ className, children, linked }: Props) => {
  const cssClasses = `list-group ${className}`;

  return linked ? (
    <div className={cssClasses}>{children}</div>
  ) : (
    <ul className={cssClasses}>{children}</ul>
  );
};

export default BSListGroup;
