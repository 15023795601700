import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { lifecycle, setDisplayName, withProps } from 'recompose';

import { tenantLoadList } from 'rides/store/actions';
import { fromApp } from 'rides/store/selectors';
// import { spinnerWhileIsLoading } from 'rides/components/utils/showWhile';
// import { StandardLayout } from 'rides/components';

import EvoStandardLayout from './template';

const mapDispatchToProps = {
  tenantLoadList,
};

const mapStateToProps = (state, props) => ({
  isLoadingTenantList: fromApp.tenant.isLoadingTenantList(state, props),
});

export default R.compose(
  setDisplayName('StandardLayoutContainer'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withProps(props => ({
    isLoading: props.isLoadingTenantList,
    fetchData: () => props.tenantLoadList(),
  })),
  lifecycle({
    componentDidMount() {
      this.props.fetchData();
    },
  }),
  // spinnerWhileIsLoading,
)(EvoStandardLayout);
