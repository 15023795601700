import React from 'react';
import PropTypes from 'prop-types';

import { tenantType } from 'rides/types';
import {
  Block,
  Button,
  HorizontalRule,
  MenubarCategory,
  MenubarItem,
} from 'rides/components';
import CurrentTimezone from 'rides/nui/containers/CurrentTimezone';
import { Text } from 'rides/nui/atoms';

const menubarCategoryStyle = {
  paddingLeft: 0,
  marginTop: 0,
  backgroundColor: '#21292e',
};

const seperatorStyle = {
  borderColor: '#666666',
  width: '100%',
  marginBottom: '15px',
  marginTop: '15px',
};

const tenantNameStyle = canChangeTenant => ({
  lineHeight: 2,
  paddingTop: 10,
  paddingBottom: canChangeTenant ? 0 : 10,
  lineHeight: 1,
  fontSize: '1.6em',
});

const tenantChangeButtonStyle = { marginTop: 0, marginBottom: 0 };

const MenubarTenant = ({ canChangeTenant, onChangeTenant, tenant }) => (
  <Block id="menubar-tenant">
    <MenubarItem>
      <HorizontalRule style={{ ...seperatorStyle, marginBottom: 0 }} />
    </MenubarItem>

    <MenubarCategory style={menubarCategoryStyle}>
      <Block
        style={tenantNameStyle(canChangeTenant)}
        textCenter
        data-test-handle="sidebar tenant name"
      >
        {tenant && tenant.name}
      </Block>

      <Text textTransform="none" lineHeight="24px" fontSize={12} textAlign="center">
        {'Time zone: '}
        <Text display="inline" fontWeight={700}>
          <CurrentTimezone />
        </Text>
      </Text>

      {canChangeTenant && (
        <Block style={{ lineHeight: 1 }} textCenter>
          <Button
            onClick={canChangeTenant ? onChangeTenant : null}
            style={tenantChangeButtonStyle}
            disabled={!canChangeTenant}
            textMuted={!canChangeTenant}
            link
            xs
            data-test-handle="change tenant button"
          >
            Change Tenant
          </Button>
        </Block>
      )}
    </MenubarCategory>

    <MenubarItem>
      <HorizontalRule style={{ ...seperatorStyle, marginTop: 0 }} />
    </MenubarItem>
  </Block>
);

MenubarTenant.propTypes = {
  canChangeTenant: PropTypes.bool,
  onChangeTenant: PropTypes.func.isRequired,
  tenant: tenantType,
};

export default MenubarTenant;
