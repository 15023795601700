import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import config, {
  isDeployLocalDev,
  isDeployAcceptance,
  isDeploySandbox,
  isDeployProd,
} from 'rides/config';
import browserHistory from 'rides/browserHistory';

function getInitConfig(appConfig) {
  const apiOrigin = appConfig.apiRootUrl;
  const baseConfig = {
    dsn: appConfig.sentry.dataSourceName,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(browserHistory),
        tracingOrigins: [apiOrigin],
      }),
    ],
    tracesSampleRate: 1.0,
  };

  // LOCAL DEV
  if (isDeployLocalDev) {
    return { ...baseConfig, environment: 'localdev' };
  }

  // ACCEPTANCE
  if (isDeployAcceptance) {
    return { ...baseConfig, environment: 'acceptance' };
  }

  // SANDBOX
  if (isDeploySandbox) {
    return { ...baseConfig, environment: 'sandbox' };
  }

  // PRODUCTION
  if (isDeployProd) {
    return {
      ...baseConfig,
      environment: 'production',
      tracesSampleRate: 0.25,
    };
  }

  // no custom config for current deploy target
  return { ...baseConfig, environment: 'unmatched-deploy-target' };
}

export function initSentry() {
  const allowedEnv =
    isDeployLocalDev || isDeployAcceptance || isDeploySandbox || isDeployProd;

  if (allowedEnv) {
    const initConfig = getInitConfig(config);
    Sentry.init(initConfig);

    // Sentry IS initiated
    return true;
  }

  // Sentry is NOT initiated
  return false;
}

export const SentryErrorBoundary = Sentry.ErrorBoundary;
