import PropTypes from 'prop-types';
import { tripStatus } from 'rides/utils/trip';

export const dateOfBirthType = PropTypes.string;
export const datetimeType = PropTypes.string;
export const emailType = PropTypes.string;
export const phoneNumberType = PropTypes.string;

// react-router: https://reacttraining.com/react-router/web/api/Link/to-string
export const linkToType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object,
  PropTypes.func,
]);
export const routePathType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.string),
]);

export const paginationType = PropTypes.shape({
  pageNumber: PropTypes.number,
  totalPages: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  boundaryPagesRange: PropTypes.number,
  siblingPagesRange: PropTypes.number,
  hideEllipsis: PropTypes.bool,
  hidePreviousAndNextPageLinks: PropTypes.bool,
  hideFirstAndLastPageLinks: PropTypes.bool,
});

export const userIdType = PropTypes.string;
export const userType = PropTypes.shape({
  id: userIdType.isRequired,
  email: emailType,
  name: PropTypes.string,
  // TODO add more properties when have time
});
export const userListType = PropTypes.arrayOf(userType);

export const roleType = PropTypes.string;
export const roleListType = PropTypes.arrayOf(roleType);

export const tenantIdType = PropTypes.number;
export const tenantType = PropTypes.shape({
  id: tenantIdType.isRequired,
  name: PropTypes.string.isRequired,
  users: userListType,
});

export const agentAssigneeType = PropTypes.shape({
  id: userIdType.isRequired,
  email: emailType,
  name: PropTypes.string,
});

export const agentAssigneeSuggestionType = PropTypes.shape({
  assigned: PropTypes.number.isRequired,
  id: userIdType.isRequired,
  name: PropTypes.string.isRequired,
});

export const agentAssigneeSuggestionListType = PropTypes.arrayOf(
  agentAssigneeSuggestionType,
);

export const agentAssignSuggestionsRespType = PropTypes.shape({
  languagesSpoken: PropTypes.string.isRequired,
  suggestedAssignees: agentAssigneeSuggestionListType.isRequired,
});

export const tripAssigneeType = PropTypes.shape({
  id: userIdType.isRequired,
  name: PropTypes.string,
});

export const tripAssigneeListType = PropTypes.arrayOf(tripAssigneeType);

export const locationIdType = PropTypes.string;
export const locationType = PropTypes.shape({
  id: locationIdType,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postalCode: PropTypes.string,
  country: PropTypes.string,
  phoneNumber: phoneNumberType,
});

export const memberPlanIdType = PropTypes.number;
export const memberPlanType = PropTypes.shape({
  id: memberPlanIdType,
  name: PropTypes.string,
  timezone: PropTypes.string,
  startTime: PropTypes.string,
  tripsAllowed: PropTypes.number,
});

export const memberIdType = PropTypes.string;
export const memberExternalIdType = PropTypes.string;
export const memberType = PropTypes.shape({
  id: memberIdType,
  externalId: memberExternalIdType,
  email: emailType,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  provisional: PropTypes.bool,
  phoneNumber: phoneNumberType,
  plan: memberPlanType,
  location: locationType,
  dateOfBirth: dateOfBirthType,
  completedTrips: PropTypes.number,
  scheduledTrips: PropTypes.number,
  languagesSpoken: PropTypes.string,
});

export const companionIdType = PropTypes.string;
export const companionType = PropTypes.shape({
  id: companionIdType,
  name: PropTypes.string,
  relationship: PropTypes.string,
  primaryContact: PropTypes.bool,
  phoneNumberType: PropTypes.oneOf(['Mobile', 'Home', 'Work']),
  phoneNumber: phoneNumberType,
});

export const tripPurposeIdType = PropTypes.number;
export const tripPurposeType = PropTypes.shape({
  name: PropTypes.string,
  id: tripPurposeIdType,
});

export const tripTypeIdType = PropTypes.number;
export const tripTypeType = PropTypes.shape({
  name: PropTypes.string,
  id: tripTypeIdType,
});
export const tripTypeListType = PropTypes.arrayOf(tripTypeType);

export const tripStatusIdStatus = PropTypes.number;
export const tripStatusStatus = PropTypes.shape({
  name: PropTypes.string,
  id: tripStatusIdStatus,
});
export const tripStatusListStatus = PropTypes.arrayOf(tripStatusStatus);
// tripStatusListStatus

export const tripPickupUrbanicityType = PropTypes.string;
export const tripPickupUrbanicityOptionType = PropTypes.shape({
  name: PropTypes.string,
  id: tripPickupUrbanicityType,
});
export const tripPickupUrbanicityOptionListType = PropTypes.arrayOf(
  tripPickupUrbanicityOptionType,
);

export const tripIdType = PropTypes.number;
export const tripType = PropTypes.shape({
  id: tripIdType,
  parentId: tripIdType,
  typeId: tripTypeIdType,
  type: tripTypeType,
  purposeId: tripPurposeIdType,
  purpose: tripPurposeType,
  status: PropTypes.string,
  startTime: datetimeType,
  pickupTime: datetimeType,
  pickup: locationType.isRequired,
  notes: PropTypes.string,
  memberNumber: phoneNumberType,
  member: memberType,
  estimate: PropTypes.number,
  dropoff: locationType.isRequired,
  driverNumber: phoneNumberType,
  customDriverPhone: phoneNumberType,
  customDriverName: PropTypes.string,
  customDriverCarLicense: PropTypes.string,
  customDriverCarDescription: PropTypes.string,
  companion: companionType,
  numberOfCompanions: PropTypes.number,
  authCode: PropTypes.string,
  appointmentTime: datetimeType,
  assignee: agentAssigneeType,
  assignedAt: datetimeType,
});

export const tripGroupIdType = PropTypes.number;
export const tripGroupType = PropTypes.shape({
  id: tripGroupIdType,
  rootTripId: tripIdType,
  trips: PropTypes.arrayOf(tripIdType),
});

export const tripEstimateType = PropTypes.shape({
  estimate: PropTypes.number,
  distance: PropTypes.number,
  pickupTime: datetimeType,
});

export const tripStatusTransitionReasonIdType = PropTypes.number;
export const tripStatusTransitionReasonType = PropTypes.shape({
  id: tripStatusTransitionReasonIdType,
  name: PropTypes.string,
  status: PropTypes.string,
});
export const tripStatusTransitionReasonListType = PropTypes.arrayOf(
  tripStatusTransitionReasonType,
);

export const tripStatusType = PropTypes.oneOf(Object.values(tripStatus));

export const tripStatusTransitionIdType = PropTypes.number;
export const tripStatusTransitionType = PropTypes.shape({
  id: tripStatusTransitionIdType,
  tripId: tripIdType,
  statusFrom: tripStatusType,
  status_to: tripStatusType,
  occurred_at: datetimeType,
  reason_id: tripStatusTransitionReasonIdType,
  notes: PropTypes.string,
  updated_because: PropTypes.string,
  inserted_at: datetimeType,
  updated_at: datetimeType,
});

/**
 * Activity Log
 */

export const activityLogUserType = userType;
export const activityLogMemberType = PropTypes.shape({
  id: memberIdType,
  externalId: memberExternalIdType,
});

export const activityLogIdType = PropTypes.number;
export const activityLogType = PropTypes.shape({
  id: activityLogIdType,
  action: PropTypes.string,
  data: PropTypes.object,
  insertedAt: datetimeType.isRequired,
  tripId: PropTypes.number,
  type: PropTypes.string.isRequired,
  member: activityLogUserType,
  user: activityLogUserType,
});

/**
 * Member Eligibility
 **/

export const memberEligibilityType = PropTypes.shape({
  isMemberKnown: PropTypes.bool.isRequired,
  isPlanKnown: PropTypes.bool.isRequired,
  planStartDate: datetimeType,
  planEndDate: datetimeType,
  insertedAt: datetimeType.isRequired,
});

export const memberEligibilityStatusType = PropTypes.oneOf([
  'notfound',
  'ineligible',
  'eligible',
  'unchecked',
]);

/**
 * Tookan
 **/

export const tookanTasksType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  isPushed: PropTypes.bool.isRequired,
  deliveryJobId: PropTypes.number,
  deliveryTrackingLink: PropTypes.string,
  pickupJobId: PropTypes.number,
  pickupTrackingLink: PropTypes.string,
});
