const styles = {
  global: {
    /**
     * :focus outline fix - relies on https://www.npmjs.com/package/focus-visible
     *
     * This will hide the focus indicator if the element receives focus via the mouse,
     * but it will still show up on keyboard focus.
     *
     * NOTE: this fixes the focus issue for chakra (that's why it's duplicated here)
     */
    '.js-focus-visible :focus:not(.focus-visible)': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
};

export default styles;
