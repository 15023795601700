/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'AmbulatoryIcon',
  path: (
    <g fill="currentColor">
      <mask id="a">
        <path
          fill-rule="evenodd"
          d="M10.667 11.419V12H5.333v-.581C2.991 11.564 0 11.954 0 13c0 1.62 7.181 1.667 8 1.667s8-.047 8-1.667c0-1.046-2.991-1.436-5.333-1.581z"
          clip-rule="evenodd"
        />
      </mask>
      <path
        fill-rule="evenodd"
        d="M10.667 11.419V12H5.333v-.581C2.991 11.564 0 11.954 0 13c0 1.62 7.181 1.667 8 1.667s8-.047 8-1.667c0-1.046-2.991-1.436-5.333-1.581z"
        clip-rule="evenodd"
      />
      <path
        d="M10.667 12v.5h.5V12h-.5zm-5.334 0h-.5v.5h.5V12zm0-.581h.5v-.532l-.53.033.03.499zm4.834 0V12h1v-.581h-1zm.5.081H5.333v1h5.334v-1zm-4.834.5v-.581h-1V12h1zm-.53-1.08c-1.179.073-2.546.209-3.63.465-.537.128-1.041.293-1.422.516-.366.215-.751.566-.751 1.1h1c0-.003-.002-.018.028-.056a.811.811 0 01.229-.181c.253-.149.644-.287 1.147-.406.998-.236 2.296-.368 3.46-.44l-.062-.998zM-.5 13c0 .412.234.72.5.93.263.207.608.364.975.488.737.25 1.703.412 2.662.52 1.926.217 3.95.229 4.363.229v-1c-.405 0-2.382-.012-4.25-.223-.94-.106-1.82-.26-2.455-.474-.32-.108-.542-.22-.675-.326a.446.446 0 01-.109-.113C.5 13.012.5 13.004.5 13.001h-1zM8 15.167c.414 0 2.437-.012 4.363-.23.959-.107 1.925-.27 2.662-.52.367-.123.712-.28.975-.488.266-.21.5-.517.5-.929h-1c0 .004 0 .012-.011.031a.447.447 0 01-.109.113c-.133.106-.356.218-.675.326-.635.215-1.515.368-2.454.474-1.869.211-3.846.223-4.251.223v1zM16.5 13c0-.533-.385-.884-.751-1.099-.38-.223-.885-.388-1.422-.516-1.084-.256-2.451-.392-3.63-.465l-.061.998c1.164.072 2.463.204 3.46.44.504.12.894.257 1.147.406a.812.812 0 01.23.18c.029.04.027.054.027.056h1z"
        mask="url(#a)"
      />
      <mask id="b">
        <path
          fill-rule="evenodd"
          d="M8 4c1.103 0 2-.897 2-2s-.897-2-2-2-2 .897-2 2 .897 2 2 2z"
          clip-rule="evenodd"
        />
      </mask>
      <path
        fill-rule="evenodd"
        d="M8 4c1.103 0 2-.897 2-2s-.897-2-2-2-2 .897-2 2 .897 2 2 2z"
        clip-rule="evenodd"
      />
      <path
        d="M8 4.5c1.379 0 2.5-1.121 2.5-2.5h-1c0 .827-.673 1.5-1.5 1.5v1zM10.5 2C10.5.621 9.379-.5 8-.5v1c.827 0 1.5.673 1.5 1.5h1zM8-.5A2.502 2.502 0 005.5 2h1c0-.827.673-1.5 1.5-1.5v-1zM5.5 2c0 1.379 1.121 2.5 2.5 2.5v-1c-.827 0-1.5-.673-1.5-1.5h-1z"
        mask="url(#b)"
      />
      <mask id="c">
        <path
          fill-rule="evenodd"
          d="M8 4.667c-1.103 0-2 .897-2 2v4.666h4V6.667c0-1.103-.897-2-2-2z"
          clip-rule="evenodd"
        />
      </mask>
      <path
        fill-rule="evenodd"
        d="M8 4.667c-1.103 0-2 .897-2 2v4.666h4V6.667c0-1.103-.897-2-2-2z"
        clip-rule="evenodd"
      />
      <path
        d="M6 11.333h-.5v.5H6v-.5zm4 0v.5h.5v-.5H10zM8 4.167a2.502 2.502 0 00-2.5 2.5h1c0-.827.673-1.5 1.5-1.5v-1zm-2.5 2.5v4.666h1V6.667h-1zm.5 5.166h4v-1H6v1zm4.5-.5V6.667h-1v4.666h1zm0-4.666c0-1.38-1.121-2.5-2.5-2.5v1c.827 0 1.5.673 1.5 1.5h1z"
        mask="url(#c)"
      />
      <mask id="d">
        <path
          fill-rule="evenodd"
          d="M12.667 4a1.334 1.334 0 10-1.333-1.333c0 .734.598 1.333 1.333 1.333z"
          clip-rule="evenodd"
        />
      </mask>
      <path
        fill-rule="evenodd"
        d="M12.667 4a1.334 1.334 0 10-1.333-1.333c0 .734.598 1.333 1.333 1.333z"
        clip-rule="evenodd"
      />
      <path
        d="M12.667 4.5a1.835 1.835 0 001.834-1.833h-1a.835.835 0 01-.834.833v1zm1.834-1.833A1.834 1.834 0 0012.667.833v1c.46 0 .834.374.834.834h1zM12.667.833a1.834 1.834 0 00-1.833 1.834h1c0-.46.374-.834.833-.834v-1zm-1.833 1.834c0 1.01.822 1.833 1.833 1.833v-1a.835.835 0 01-.833-.833h-1z"
        mask="url(#d)"
      />
      <mask id="e">
        <path
          fill-rule="evenodd"
          d="M12.667 4.667c-.735 0-1.333.597-1.333 1.333v4h2.667V6c0-.736-.598-1.333-1.334-1.333z"
          clip-rule="evenodd"
        />
      </mask>
      <path
        fill-rule="evenodd"
        d="M12.667 4.667c-.735 0-1.333.597-1.333 1.333v4h2.667V6c0-.736-.598-1.333-1.334-1.333z"
        clip-rule="evenodd"
      />
      <path
        d="M11.334 10h-.5v.5h.5V10zm2.667 0v.5h.5V10H14zm-1.334-5.833A1.834 1.834 0 0010.834 6h1c0-.46.374-.833.833-.833v-1zM10.834 6v4h1V6h-1zm.5 4.5h2.667v-1h-2.667v1zm3.167-.5V6h-1v4h1zm0-4a1.834 1.834 0 00-1.834-1.833v1c.46 0 .834.373.834.833h1z"
        mask="url(#e)"
      />
      <mask id="f">
        <path
          fill-rule="evenodd"
          d="M3.333 4A1.334 1.334 0 102 2.667C2 3.4 2.598 4 3.333 4z"
          clip-rule="evenodd"
        />
      </mask>
      <path
        fill-rule="evenodd"
        d="M3.333 4A1.334 1.334 0 102 2.667C2 3.4 2.598 4 3.333 4z"
        clip-rule="evenodd"
      />
      <path
        d="M3.333 4.5a1.835 1.835 0 001.834-1.833h-1a.835.835 0 01-.834.833v1zm1.834-1.833A1.834 1.834 0 003.333.833v1c.46 0 .834.374.834.834h1zM3.333.833A1.834 1.834 0 001.5 2.667h1c0-.46.374-.834.833-.834v-1zM1.5 2.667c0 1.01.822 1.833 1.833 1.833v-1a.835.835 0 01-.833-.833h-1z"
        mask="url(#f)"
      />
      <mask id="g">
        <path
          fill-rule="evenodd"
          d="M3.333 4.667C2.598 4.667 2 5.264 2 6v4h2.667V6c0-.736-.598-1.333-1.334-1.333z"
          clip-rule="evenodd"
        />
      </mask>
      <path
        fill-rule="evenodd"
        d="M3.333 4.667C2.598 4.667 2 5.264 2 6v4h2.667V6c0-.736-.598-1.333-1.334-1.333z"
        clip-rule="evenodd"
      />
      <path
        d="M2 10h-.5v.5H2V10zm2.667 0v.5h.5V10h-.5zM3.333 4.167A1.834 1.834 0 001.5 6h1c0-.46.374-.833.833-.833v-1zM1.5 6v4h1V6h-1zm.5 4.5h2.667v-1H2v1zm3.167-.5V6h-1v4h1zm0-4a1.834 1.834 0 00-1.834-1.833v1c.46 0 .834.373.834.833h1z"
        mask="url(#g)"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
