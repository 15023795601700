import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal } from 'react-overlays';

import { Block, Heading } from 'rides/components';

const modalStyle = {
  position: 'fixed',
  zIndex: 1720,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  outline: null,
};

const backdropStyle = {
  ...modalStyle,
  zIndex: 'auto',
  backgroundColor: '#000',
  opacity: 0.5,
};

// const dialogStyleDefaults = {
//   position: 'absolute',
//   width: 400,
//   top: '50%',
//   left: '50%',
//   transform: `translate(-50%, -50%)`,
//   border: '1px solid #e5e5e5',
//   backgroundColor: 'white',
//   boxShadow: '0 5px 15px rgba(0,0,0,.5)',
//   padding: 20,
// };

const headerStyleFixesOverride = {
  margin: undefined,
};

const ConfirmModal = ({ children, isOpen, closeable, title, onHide }) => {
  const modalDialogClasses = classNames('modal-dialog', 'modal-md');
  const modalDialogStyle = { marginTop: 100 };

  return (
    <Modal
      aria-labelledby="modal-confirm-label"
      style={modalStyle}
      backdropStyle={backdropStyle}
      show={isOpen}
      enforceFocus={false}
    >
      <Block className="modal" style={{ display: 'block' }}>
        <Block className={modalDialogClasses} style={modalDialogStyle}>
          <Block className="modal-content" role="document">
            {title && (
              <Block className="modal-header">
                <Heading level={4} className="modal-title" style={headerStyleFixesOverride}>
                  {title}
                </Heading>
              </Block>
            )}

            <Block className="modal-body">{children}</Block>
          </Block>
        </Block>
      </Block>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.node,
  closeable: PropTypes.bool,
  onHide: PropTypes.func,
};

ConfirmModal.defaultProps = {
  // onHide: () => true,
};

export default ConfirmModal;
