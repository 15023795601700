/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'HeartIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M8 3.046c.1-.146.206-.286.319-.42a4.823 4.823 0 011.43-1.177A3.721 3.721 0 0111.5 1c.621 0 1.213.13 1.752.367a4.505 4.505 0 011.43 1c.406.422.736.925.964 1.483.228.558.354 1.173.354 1.817 0 .644-.126 1.258-.354 1.816a4.676 4.676 0 01-.965 1.484c-.336.349-4.316 4.084-5.989 5.5-.427.362-.957.362-1.384 0-1.673-1.416-5.653-5.151-5.99-5.5a4.693 4.693 0 01-.965-1.484A4.804 4.804 0 010 5.667c0-.644.126-1.259.353-1.817a4.684 4.684 0 01.965-1.483 4.507 4.507 0 011.43-1A4.35 4.35 0 014.5 1a3.73 3.73 0 011.752.447 4.842 4.842 0 011.43 1.179c.112.134.218.274.318.42z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 16 16',
});
