/**
 * Do not change! File was auto generate.
 *
 */
import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'ErrorDiscIcon',
  path: (
    <g fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M14 7A7 7 0 110 7a7 7 0 0114 0zM7 2.625a.84.84 0 00-.84.874l.158 3.939a.683.683 0 001.364 0l.158-3.939A.84.84 0 007 2.625zm-1.006 7.547a.984.984 0 111.969 0 .984.984 0 01-1.97 0z"
        clip-rule="evenodd"
      />
    </g>
  ),
  viewBox: '0 0 14 14',
});
