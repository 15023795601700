import * as Sentry from '@sentry/react';
import { replace as routerReplace } from 'connected-react-router';
import * as R from 'ramda';
import { call, put, takeEvery } from 'redux-saga/effects';
import { consoleErrorRecovery, safeSaga } from 'rides/store/helpers';

import { apiGet, apiSetTenantId } from 'rides/store/callApi/sagas';
import { confirmSaga } from 'rides/store/confirm/sagas';

import * as action from './actions';
import { redirectToRemixApp } from 'rides/routes';

/**
 * Task Sagas
 **/

export function* fetchMetadata(api) {
  const resp = yield call(apiGet, api, `/auth/metadata`, {}, false);
  // console.log('Metadata response successful:', resp.ok, resp);

  const returnTo = encodeURIComponent(window.location.pathname + window.location.search);

  const hasRoles = !!resp.data?.data?.roles?.length;
  const isInTenant = !!resp.data?.data?.metadata?.tenant_id.length;

  if (resp.ok && (!hasRoles || !isInTenant)) {
    // If the user doesn't have any roles assigned, or doesn't belong to a tenant,
    // redirect to missing role page
    redirectToRemixApp();
    return null;
  }

  if (!resp.ok) {
    // console.log('Metadata request error');
    if (resp.status === 401) {
      // console.log('Received a 401, redirecting to login route');
      // On 401, the user is not logged in - push to login route
      yield put(routerReplace(`/login?returnTo=${returnTo}`));
      return null;
    } else {
      // console.log('Something else went wrong, redirecting to error page');
      // Something else went wrong loading metadata
      yield put(routerReplace(`/login-error?returnTo=${returnTo}`));
      return null;
    }
  }

  return R.path(['data', 'data'], resp);
}

const getDefaultTenantId = userData => {
  const tenantIds = R.path(['metadata', 'tenant_id'], userData);
  const savedTenantId = ~~localStorage.getItem('current_tenant_id');

  if (R.contains(savedTenantId, tenantIds)) {
    return savedTenantId;
  }

  // defaults to first tenant in list
  return R.head(tenantIds);
};

export function* loadUserDataFromApi(api) {
  // get user metadata from backend
  const userData = yield call(fetchMetadata, api);

  // set current tenant id for api's request headers
  const tenantId = yield call(getDefaultTenantId, userData);
  yield call(setCurrentTenantId, api, tenantId);

  return userData;
}

export function* loginToApi(api) {
  try {
    const userData = yield call(loadUserDataFromApi, api);
    yield put(action.authLoginSuccess(userData));

    try {
      yield call(setSentryScopeUser, userData);
    } catch (e) {
      console.error(e);
    }
  } catch (e) {
    yield put(action.authLoginFailure(e));
  }
}

export function* logOut(api, { thunk }) {
  try {
    yield call(unsetSentryScopeUser);
    yield put(action.authLogOutSuccess(thunk));
  } catch (e) {
    yield put(action.authLogOutFailure(e, thunk));
    // redirect to root route after logout (even on failed logout)
    yield put(routerReplace('/'));
  }
}

export const storeCurrentTenantId = tenantId =>
  localStorage.setItem('current_tenant_id', tenantId);

export function* setCurrentTenantId(api, tenantId) {
  yield call(apiSetTenantId, api, tenantId);
  yield call(storeCurrentTenantId, tenantId);
  yield put(action.authSetCurrentTenantIdSuccess(tenantId));
}

export function* setSentryScopeUser(userData) {
  const id = R.path(['user_id'], userData);
  const anonymous = R.path(['anonymous'], userData);
  const metadata = R.path(['metadata'], userData);
  const roles = R.path(['roles'], userData);
  const name = R.path(['name'], metadata);
  const email = R.path(['username'], metadata);
  const tenantIds = R.path(['tenant_id'], metadata);

  const setScope = scope => {
    scope.setUser({ id, email, name });
    scope.setExtra('user_object', { anonymous, metadata, roles, tenantIds });
  };

  yield call(Sentry.configureScope, setScope);
}

export function* unsetSentryScopeUser() {
  const unsetUser = scope => {
    scope.setUser(null);
    scope.setExtra('user_object', null);
  };

  yield call(Sentry.configureScope, unsetUser);
}

/**
 * Watcher Sagas
 **/

export function* watchAuthLogOut(api, { meta, payload }) {
  if (payload.showConfirm) {
    const confirmed = yield call(confirmSaga, {
      title: 'Log Out',
      message: 'Are you sure you want to log out?',
      yesButtonText: 'Log Out',
      noButtonText: 'Cancel',
    });
    if (confirmed) {
      yield call(logOut, api, meta);
    }
  } else {
    yield call(logOut, api, meta);
  }
}

export function* watchAuthSetCurrentTenantId(api, { payload }) {
  const { tenantId } = payload;
  yield call(setCurrentTenantId, api, tenantId);
}

/**
 * Root Sagas
 **/

export default function*({ api }) {
  const safe = safeSaga(consoleErrorRecovery);

  yield takeEvery(action.AUTH_LOG_OUT, safe(watchAuthLogOut, api));

  yield takeEvery(
    action.AUTH_SET_CURRENT_TENANT_ID,
    safe(watchAuthSetCurrentTenantId, api),
  );

  yield call(loginToApi, api);
}
