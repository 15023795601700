import * as R from 'ramda';
import { createSelector } from 'reselect';
import { fromEntities } from 'rides/store/selectors';
// import { currentMember } from 'rides/store/app/member/selectors';

import {
  convertFromReadableDateTime,
  // convertToReadableDate,
} from 'rides/utils/dateTime';

const removeEmpty = R.reject(R.either(R.isEmpty, R.isNil));

export const linearStepOrder = [
  // --
  'pickup',
  'destination',
  'pickup-time',
  // 'companion',
];

export const nonLinearSteps = [
  // --
  'trip-created',
  'cancel-trip',
];

export const initialState = {
  currentStep: 'pickup',
  formDisabled: false,
  member: null,
  tripGroupId: null,
  tripId: null,

  values: {
    pickup_locationAutocomplete: '',
    pickup_isHome: false,
    pickup_name: '',
    pickup_phoneNumber: '',
    pickup_address1: '',
    pickup_address2: '',
    pickup_city: '',
    pickup_state: '',
    pickup_postalCode: '',

    destination_locationAutocomplete: '',
    destination_isHome: false,
    destination_name: '',
    destination_phoneNumber: '',
    destination_address1: '',
    destination_address2: '',
    destination_city: '',
    destination_state: '',
    destination_postalCode: '',

    dropoffId: '',
    pickupId: '',
    companionId: '',

    purposeId: '',
    typeId: '',

    reservationDate: '',
    appointmentTime: '',
    pickupTime: '',
    isWillCall: false,

    companion_name: '',
    companion_relationship: '',
    companion_phone: '',
    companion_phoneType: '',
    companion_primaryContact: false,
    // vendor: 'Uber',

    numberOfCompanions: '',

    notes: '',

    authCode: '',
    mapUrl: '',

    groupReferenceNumber: '',

    // NOTE Cancel Trip Step!
    cancelReason: '',

    // Extra
    hideWillCall: false,
  },

  errors: {},
  stepErrors: {},
};

export const localState = R.pathOr(initialState, ['app', 'tripWizard']);

export const currentStep = createSelector(
  [localState],
  R.prop('currentStep'),
);
export const errors = createSelector(
  [localState],
  R.prop('errors'),
);
export const stepErrors = createSelector(
  [localState],
  R.prop('stepErrors'),
);
export const formDisabled = createSelector(
  [localState],
  R.prop('formDisabled'),
);
export const member = createSelector(
  [localState],
  R.prop('member'),
);
export const tripGroupId = createSelector(
  [localState],
  R.prop('tripGroupId'),
);
export const tripId = createSelector(
  [localState],
  R.prop('tripId'),
);
export const values = createSelector(
  [localState],
  R.prop('values'),
);

/**
 * Step Based
 */
export const nextStep = createSelector(
  [currentStep],
  currentStep => {
    const idx = R.indexOf(currentStep, linearStepOrder);
    // current step not found
    if (idx === -1) return false;
    return R.path([idx + 1], linearStepOrder);
  },
);

export const prevStep = createSelector(
  [currentStep],
  currentStep => {
    const idx = R.indexOf(currentStep, linearStepOrder);
    // current step not found
    if (idx === -1) return false;
    return R.path([idx - 1], linearStepOrder);
  },
);

/**
 * Member Based
 */
export const memberId = createSelector(
  [member],
  R.path(['id']),
);

/**
 * Values Based
 */

export const reservationDate = createSelector(
  [values],
  values => {
    const reservationDateValue = R.prop('reservationDate', values);
    return reservationDateValue;

    // // TODO update this to convert to date if needed after new date select field implemented
    // const reservationDate = R.compose(convertToReadableDate, reservationDateValue);
    // console.log('appointmentDateTime::reservationDate', reservationDate);
    // return reservationDate;
  },
);

export const appointmentDateTime = createSelector(
  [values, reservationDate],
  (values, reservationDate) => {
    if (R.isEmpty(reservationDate) || R.isEmpty(values.appointmentTime)) return '';
    return convertFromReadableDateTime(`${reservationDate} ${values.appointmentTime}`);
  },
);

export const pickupDateTime = createSelector(
  [values, reservationDate],
  (values, reservationDate) => {
    if (R.isEmpty(reservationDate) || R.isEmpty(values.pickupTime)) return '';

    const pickupDateTime = convertFromReadableDateTime(
      `${reservationDate} ${values.pickupTime}`,
    );
    return pickupDateTime;
  },
);

export const pickupLocationMaskValues = createSelector(
  [values, member],
  (values, member) => {
    if (!values.pickup_isHome) return {};

    return {
      name: 'Home',
      phoneNumber: R.pathOr('', ['location', 'phoneNumber'], member),
      address1: R.pathOr('', ['location', 'address1'], member),
      address2: R.pathOr('', ['location', 'address2'], member),
      city: R.pathOr('', ['location', 'city'], member),
      state: R.pathOr('', ['location', 'state'], member),
      postalCode: R.pathOr('', ['location', 'postalCode'], member),
    };
  },
);

export const destinationLocationMaskValues = createSelector(
  [values, member],
  (values, member) => {
    if (!values.destination_isHome) return {};

    return {
      name: 'Home',
      phoneNumber: R.pathOr('', ['location', 'phoneNumber'], member),
      address1: R.pathOr('', ['location', 'address1'], member),
      address2: R.pathOr('', ['location', 'address2'], member),
      city: R.pathOr('', ['location', 'city'], member),
      state: R.pathOr('', ['location', 'state'], member),
      postalCode: R.pathOr('', ['location', 'postalCode'], member),
    };
  },
);

export const valuesWithMaskedValues = createSelector(
  [values, destinationLocationMaskValues, pickupLocationMaskValues],
  (values, destinationLocationMask, pickupLocationMask) => {
    return R.merge(
      values,
      removeEmpty({
        destination_name: destinationLocationMask.name,
        destination_phoneNumber: destinationLocationMask.phoneNumber,
        destination_address1: destinationLocationMask.address1,
        destination_address2: destinationLocationMask.address2,
        destination_city: destinationLocationMask.city,
        destination_state: destinationLocationMask.state,
        destination_postalCode: destinationLocationMask.postalCode,
        pickup_name: pickupLocationMask.name,
        pickup_phoneNumber: pickupLocationMask.phoneNumber,
        pickup_address1: pickupLocationMask.address1,
        pickup_address2: pickupLocationMask.address2,
        pickup_city: pickupLocationMask.city,
        pickup_state: pickupLocationMask.state,
        pickup_postalCode: pickupLocationMask.postalCode,
      }),
    );
  },
);

export const tripTypeList = (state, props) => {
  return fromEntities.getList(state, 'tripType');
};

export const tripTypeOptionMap = createSelector(
  [tripTypeList],
  (tripTypes) => {
    const labelIdMap = Object.fromEntries(tripTypes.map(e => [e.name, e.id]));
    return labelIdMap;
  }
);


/**
 * Get default value of trip type id i.e. Level of Service based on member
 * attributes.
 */
export const defaultTripTypeLOS = createSelector(
  [values, member, tripTypeOptionMap],
  (values, member, tripTypeOptions) => {
    const healthAttributes = R.pathOr({}, ['healthAttributes', 'data'], member);
    const haAmbulatoryStatus = R.pathOr('', ['ambulatoryStatus'], healthAttributes);
    const haDevicesAndEquipment = R.pathOr([], ['devicesAndEquipment'], healthAttributes);

    const hasManualWheelChair = R.contains('Manual Wheelchair', haDevicesAndEquipment);
    const hasPowerWheelChair = R.contains('Power Wheelchair', haDevicesAndEquipment);
    const hasRollatorOrWalker = R.contains('Rollator or Walker', haDevicesAndEquipment);
    const hasGurney = R.contains('Gurney', haDevicesAndEquipment);

    const hasAmbulatory = R.equals('Ambulatory', haAmbulatoryStatus);

    if (hasPowerWheelChair) {
      return tripTypeOptions['Power Wheelchair']
    }
    if (hasRollatorOrWalker) {
      return tripTypeOptions['Rollator/Assist']
    }
    if (hasManualWheelChair) {
      return tripTypeOptions['Standard Wheelchair']
    }
    if (hasGurney) {
      return tripTypeOptions['Gurney']
    }
    if (hasAmbulatory) {
      return tripTypeOptions['Ambulatory Curb']
    }

    return undefined
  },
);



export const apiTripData = createSelector(
  [valuesWithMaskedValues, appointmentDateTime, pickupDateTime],
  (values, appointmentTime, pickupTime) => {
    const tripData = {
      pickup: {
        name: values.pickup_name,
        phoneNumber: values.pickup_phoneNumber,
        id: values.pickupId,
        address1: values.pickup_address1,
        address2: values.pickup_address2,
        city: values.pickup_city,
        state: values.pickup_state,
        postalCode: values.pickup_postalCode,
      },
      dropoff: {
        name: values.destination_name,
        phoneNumber: values.destination_phoneNumber,
        id: values.dropoffId,
        address1: values.destination_address1,
        address2: values.destination_address2,
        city: values.destination_city,
        state: values.destination_state,
        postalCode: values.destination_postalCode,
      },
      companion: {
        id: values.companionId,
        name: values.companion_name,
        phoneNumber: values.companion_phone,
        phoneNumberType: values.companion_phoneType,
        primaryContact: values.companion_primaryContact,
        relationship: values.companion_relationship,
      },
      numberOfCompanions: values.numberOfCompanions,
      notes: values.notes,
      appointmentTime,
      pickupTime,
      isWillCall: values.isWillCall,

      // vendor: null,
      purposeId: values.purposeId,
      typeId: values.typeId,
      authCode: values.authCode,
      mapUrl: values.mapUrl,

      groupReferenceNumber: values.groupReferenceNumber,
    };

    return tripData;
  },
);

export const hideWillCall = createSelector([values], R.prop('hideWillCall'));
