import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Alert } from 'evergreen-ui';

import { fromApp } from 'rides/store/selectors';

const mapStateToProps = (state, props) => ({
  tenantName: fromApp.tenant.currentTenantName(state, props),
  showStaffUI: fromApp.ui.showStaffUI(state, props),
});

const TenantInfoHeaderContainer = ({ tenantName, showStaffUI }) => {
  if (!showStaffUI) return null;
  if (!tenantName) return null;

  return (
    <Alert
      intent="none"
      title={`You are viewing ${tenantName}`}
      marginX={-16}
      marginBottom={14}
    >
      {`Don't forget to schedule a return trip leg!`}
    </Alert>
  );
};

TenantInfoHeaderContainer.propTypes = {
  tenantName: PropTypes.string,
  showStaffUI: PropTypes.bool.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(TenantInfoHeaderContainer);
