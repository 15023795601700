import { all, fork } from 'redux-saga/effects';

import authSagas from './auth/sagas';
import callApiSagas from './callApi/sagas';
import confirmSagas from './confirm/sagas';
import resourceSagas from './resource/sagas';
import entitesSagas from './entities/sagas';

import memberSagas from './app/member/sagas';
import tenantSagas from './app/tenant/sagas';
import tripSagas from './app/trip/sagas';
import tripWizardSagas from './app/tripWizard/sagas';

const reduxModulesSagas = [
  authSagas,
  callApiSagas,
  confirmSagas,
  resourceSagas,
  entitesSagas,
  memberSagas,
  tenantSagas,
  tripSagas,
  tripWizardSagas,
];

function* rootReduxModulesSagas(services = {}) {
  try {
    yield all(reduxModulesSagas.map(saga => fork(saga, services)));
  } catch (error) {
    console.error('reduxModulesSagas - ROOT SAGA ERROR!!!', error);
    console.trace();
  }
}

// single entry point to start all Sagas at once
export default function*(services = {}) {
  try {
    yield all([
      rootReduxModulesSagas(services),

      // // using new redux-modules and services
      // memberSagas(services),
      // tripSagas(services),
    ]);
  } catch (error) {
    console.error('ROOT SAGA ERROR!!!', error);
    console.trace();
  }
}
