import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import { memberType, memberIdType } from 'rides/types';
import { useTenantUrl } from 'rides/hooks/use-tenant';
import { MemberForm } from 'rides/containers';
import {
  Atom,
  Block,
  Button,
  Glyphicon,
  Heading,
  HorizontalRule,
  Member,
  Paragraph,
  Small,
  Strong,
} from 'rides/components';
import { Flex } from 'rides/nui';
import NuiMemberEligibilityBadge from 'rides/nui/containers/MemberEligibilityBadge';

function MemberDetailsPageLinkButton({ memberId }) {
  const linkTo = useTenantUrl(`/member/${memberId}`);
  return (
    <Button to={linkTo} primary xs pullRight>
      Member Details Page
      <Glyphicon icon="chevron-right" small />
    </Button>
  );
}
MemberDetailsPageLinkButton.propTypes = {
  memberId: memberIdType,
};

class CollapsibleMemberInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = { collapsed: props.defaultCollapsed };
  }

  toggleCollapse = event => {
    if (event) event.preventDefault();
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    const { collapsed } = this.state;
    const { member } = this.props;

    const tripsAllowed = ~~R.pathOr(0, ['plan', 'tripsAllowed'], member);
    const tripsCompleted = ~~R.pathOr(0, ['completedTrips'], member);
    const tripsScheduled = ~~R.pathOr(0, ['scheduledTrips'], member);
    let triplegsAvailable = tripsAllowed < 0 ? 0 : tripsAllowed - tripsCompleted;

    const triplegsUsedText = member.plan && (
      <Block>
        <Small>
          <Atom className={!triplegsAvailable ? 'text-danger' : ''}>
            {!triplegsAvailable ? <Glyphicon icon="exclamation-sign" /> : null}
            <Strong>{triplegsAvailable}</Strong>
          </Atom>{' '}
          of <Strong>{tripsAllowed}</Strong> trip legs available for this member benefit
          period.{' '}
          {!triplegsAvailable &&
            'An authorization code may be required to create new trips or add trip legs.'}
        </Small>
      </Block>
    );

    return (
      <Block className="widget widget-shadow">
        <Block className="widget-body">
          <MemberDetailsPageLinkButton memberId={member.id} />
          <Heading
            level={4}
            className="widget-title margin-bottom-0"
            style={{ cursor: 'pointer' }}
          >
            <Block className="text-muted" style={{ fontSize: '8pt', marginTop: '-5px' }}>
              Member Info:
            </Block>
            <Flex alignItems="baseline">
              <Member member={member} />
              <NuiMemberEligibilityBadge memberId={member.id} />
              <Glyphicon
                icon={collapsed ? 'chevron-right' : 'chevron-down'}
                onClick={this.toggleCollapse}
                textInfo
              />
            </Flex>
          </Heading>

          {triplegsUsedText}

          {!collapsed ? (
            <Block className="animation-fade margin-top-10 margin-bottom-0">
              <MemberForm
                member={member}
                locked={true}
                render={({ formFields }) => {
                  return <Block>{formFields}</Block>;
                }}
              />

              {!member.provisional && (
                <Block>
                  <HorizontalRule />
                  <Heading level={4}>Trip Benefits Information</Heading>
                  {/* TODO update this when issue solved: https://github.com/omt-tech/aecs/issues/18 */}
                  <Paragraph>
                    Total trips this period (one-way): <Strong>{tripsAllowed}</Strong>
                    <br />
                    Trips used so far (one-way): <Strong>{tripsCompleted}</Strong>
                    <br />
                    Trips scheduled (one-way): <Strong>{tripsScheduled}</Strong>
                  </Paragraph>
                </Block>
              )}
            </Block>
          ) : null}
        </Block>
      </Block>
    );
  }
}

CollapsibleMemberInfo.propTypes = {
  defaultCollapsed: PropTypes.bool,
  member: memberType,
};

CollapsibleMemberInfo.defaultProps = {
  defaultCollapsed: true,
};

export default CollapsibleMemberInfo;
